const paymentsTranslations = {
  es_CL: {
    // banner: 'Puedes obtener un descuento adicional de 5% pagando el total de las cuotas',
    banners: {
      no_discount:'Sin Descuento',
      extraBenefit:'Obtén un {percentage} % de beneficio extra',
      title: 'Puedes obtener un descuento adicional de {percentage}% pagando el total de las cuotas',
      no_subjects_quota: 'Aún no cumplís con el cupo requerido de asignaturas. Debés tener al menos 7 asignaturas aprobadas para poder reinscribirte',
      debt: {
        title: '¡Tienes una deuda pendiente!',
        subtitle: 'Es importante que saldes tu deuda para reinscribirte a tu próximo periodo',
      },
    },
    gatewayMPFeedback: {
      approved: {
        accredited:{
          title: '¡Listo! Se acreditó tu pago.',
          description: 'En tu resumen verás el cargo de ${amount} como {description}.',
          message: 'Ahora puedes cerrar este mensaje y continuar en la plataforma.'
        }
      },
      in_process: {
        pending_contingency: {
          title: 'Tu pago está siendo procesado',
          description: '¡No te preocupes!',
          message: 'En menos de 2 días hábiles te avisaremos por mail si se acreditó. '
        },
        pending_review_manual: {
          title: 'Tu pago está siendo procesado',
          description: '¡No te preocupes!',
          message: 'En menos de 2 días hábiles te avisaremos por mail si se acreditó o si necesitamos más información.'
        },
      },
      rejected:{
        cc_rejected_bad_filled_card_number:{
          title: 'El pago no fue procesado',
          description: 'Revisa el número de tarjeta.',
          message: 'Verifica que ingresaste bien los números de tu tarjeta e intenta nuevamente el pago.'
        },
        cc_rejected_bad_filled_date: {
          title: 'El pago no fue procesado',
          description: 'Revisa la fecha de vencimiento.',
          message: 'Verifica la fecha de vencimiento de tu tarjeta'
        },
        cc_rejected_bad_filled_other: {
          title: 'El pago no fue procesado',
          description: 'Revisa los datos',
          message: 'Verifica y vuelve a ingresar los datos de tu tarjeta'
        },
        cc_rejected_bad_filled_security_code: {
          title: 'El pago no fue procesado',
          description: 'Revisa el código de seguridad de la tarjeta.',
          message: 'Verifica y vuelve a ingresar los 3 dígitos de seguridad.'
        },
        cc_rejected_blacklist: {
          title: 'No pudimos procesar tu pago.',
          description: '',
          message: 'Código de notificación: #{statusDetailCode}'
        },
        cc_rejected_call_for_authorize: {
          title: 'Es necesario autorizar',
          description: 'ante {payment_method_id} el pago de ${amount}.',
          message: 'Código de notificación: #{statusDetailCode}'
        },
        cc_amount_rate_limit_exceeded: {
          title: 'No pudimos procesar tu pago.',
          description: 'El pago fue rechazado porque superó el límite del medio de pago.',
          message: 'Código de notificación: #{statusDetailCode}'
        },
        cc_rejected_card_disabled: {
          title: 'Comunícate con {payment_method_id} para activar tu tarjeta',
          description: 'O usar otro medio de pago',
          message: 'El teléfono está al dorso de tu tarjeta'
        },
        cc_rejected_card_error: {
          title: 'No pudimos procesar tu pago. Comunícate con tu banco al teléfono que está al dorso de tu tarjeta.',
          description: '',
          message:''
        },
        cc_rejected_duplicated_payment: {
          title: 'Ya hiciste un pago con este valor',
          description: 'O usar otro medio de pago',
          message: 'Si necesitas volver a pagar, utiliza otra tarjeta o medio de pago.'
        },
        cc_rejected_high_risk: {
          title: 'Tu pago fue rechazado',
          description: 'comunícate con tu banco para más información',
          message: 'Código de notificación: #{statusDetailCode}'
        },
        cc_rejected_insufficient_amount: {
          title: 'Tu banco rechazó la operación por fondos insuficientes. Por favor, comunícate con tu banco al teléfono que está al dorso de tu tarjeta.',
          description: '',
          message:''
        },
        cc_rejected_invalid_installments: {
          title: 'Tu banco no procesa pagos en ese número de cuotas. Revisa el número de cuotas y volver a intentarlo.',
          description: '',
          message:''
        },
        cc_rejected_max_attempts: {
          title: 'Llegaste al límite de intentos permitidos. Elige otra tarjeta u otro medio de pago.',
          description: '',
          message:''
        },
        cc_rejected_other_reason: {
          title: 'No pudimos procesar tu pago. Por favor, comunícate con tu banco al teléfono que está al dorso de tu tarjeta.',
          description: '',
          message:''
        }
      },
    },
    view: {
      labels: {
        idoncard: 'Número de RUT',
        selectPaymentHelp: 'Si no encuentras tu medio de pago, comunícate con Sofía',
        no_cash_method: 'Si deseas pagar en efectivo, lo puedes realizar en cualquier caja <strong>Servipag</strong> a lo largo del país, indicando solo tu <strong>RUT</strong>.',
        zipCode: 'Código postal',
        reinscription_subtitle: 'La información desplegada más abajo es de uso informativo y se disponibiliza para que estés al tanto de los valores de tu carrera en tu próximo periodo académico. No constituye una deuda ni aceptación de contrato hasta que decidas realizar el pago correspondiente.',
      },
      coupons: {
          info: 'Ingresa a <a href="{service}" target="_blank">{service}</a> y encuentra tu sucursal más cercana para realizar el pago.',
      },
      getnet: {
        promotion:'En 6 cuotas sin interes.'
      },
      discount:{
        popups:{
          error:{
            title:'Hubo un error en el proceso de pago. Por favor, vuelva a intentarlo',
          }
        }
      },
      contract:{
          breadcrumbs:
          {
            title:'Contratos',
            description:'Acá podrás ver tus contratos vigentes.'
          },
          title:'Contratos',
          popups:{
            error:{
            title:'Hubo un error al descargar el contrato. Por favor, inténtalo de nuevo.',
          }
        }
      },
      feedbackVerification:{
        title: '¡Se acreditó tu pago!',
        subtitle:'Verás los importes en tu Estado de Cuentas.',
        description: 'Ahora, vamos a verificar tu identidad por única vez.',
        message: 'Te estamos redireccionando en 5 segundos',
      }
    },
  },
  es_PE: {
      banners: {
          no_discount:'Sin Descuento',
          extraBenefit:'Obtén un {percentage} % de beneficio extra',
          title: 'Puedes obtener un descuento adicional de {percentage}% pagando el total de las cuotas',
          no_subjects_quota: 'Aún no cumples con el cupo requerido de cursos. Debés tener al menos 7 cursos aprobados para poder reinscribirte',
      },
      view: {
          coupons: {
              nextSteps: 'Con este cupón debes acercarte a una sucursal habilitada y pagar',
              print: 'Imprimir cupón',
              numberTitle: 'Este es tu código de pago CIP',
              amountTitle: 'Vas a pagar',
              typeService: 'Al acercarte a la sucursal puedes hacer el pago en la practicaja, banca en línea o un cajero indicando que es un <strong>Pago por Servicios</strong>',
              expirationDateTitle: 'Tienes tiempo hasta el',
              info: 'Los horarios de atención y montos de pago dependen de cada establecimiento.',
              infoLink: 'Para más información, haz click <a href="{service}" target="_blank">aquí</a>',
              codeReminder: 'Recuerda que los códigos tienen un tiempo de vigencia. Una vez expirado, el código ya no es válido',
              paymentReminder: 'Este cupón no constituye pago.',
              disclaimer: 'El pago no se verá reflejado hasta que se acredite. Esto es ajeno a {org}.',
          },
          labels: {
              selectPaymentHelp: 'Si no encuentras tu medio de pago, comunicate con Albert',
              creditcardSingle: 'Selecciona como lo quieres pagar',
              city: 'Distrito',
              state: 'Departamento',
              zipCode: 'Código postal',
          },
          discount:{
            popups:{
              error:{
                title:'"Hubo un error en el proceso de pago. Por favor, vuelva a intentarlo"',
              }
            }
          }
      }
  },
  es_MX: {
    banners: {
        no_discount:'Sin Descuento',
        extraBenefit:'Obtén un {percentage} % de beneficio extra',
        title: 'Puedes obtener un descuento adicional de {percentage}% pagando el total de las cuotas',
        no_subjects_quota: 'Aún no cumples con el cupo requerido de materias. Debés tener al menos 7 materias aprobados para poder reinscribirte',
    },
    view: {
        coupons: {
            nextSteps: 'Con este cupón debes acercarte a una sucursal habilitada de {nombre} y pagar',
            print: 'Imprimir cupón',
            numberTitle: 'Número de referencia:',
            numberAccount: 'Número de convenio:',
            amountTitle: 'Vas a pagar',
            typeService: 'Al acercarte a la sucursal puedes hacer el pago en la practicaja, banca en línea o un cajero indicando que es un <strong>Pago por Servicios</strong>',
            expirationDateTitle: 'Tienes tiempo hasta el',
            info: 'Los horarios de atención y montos de pago dependen de cada establecimiento.',
            infoLink: 'Para más información, haz click <a href="{service}" target="_blank">aquí</a>',
            codeReminder: 'Recuerda que los códigos tienen un tiempo de vigencia. Una vez expirado, el código ya no es válido',
            paymentReminder: 'Este cupón no constituye pago.',
            disclaimer: 'El pago no se verá reflejado hasta que se acredite. Esto es ajeno a {org}.',
            payCash:'Podes acercarte a abonar este cupón en 7-Eleven, Calimax, Circle K, Extra, Santander, Soriana'
        },
        labels: {
            selectPaymentHelp: 'Si no encuentras tu medio de pago, comunícate con Sofia',
            creditcardSingle: 'Selecciona como lo quieres pagar',
            city: 'Municipio',
            state: 'Estado',
            name: 'Nombre fiscal completo',
            zipCode: 'Código postal fiscal',
            idoncard: 'RFC',
            streetfloor: 'Interior',
            streetaddress: 'Dirección fiscal',
        } ,
        breadcrumbs:{
          description:'Aquí podrás hacer tus pagos y ver las promociones vigentes.'
        },
        payCash: {
          places:'7-Eleven, Calimax, Circle K, Extra, Santander, Soriana'
        },
        discount:{
          popups:{
            error:{
              title:'"Hubo un error en el proceso de pago. Por favor, vuelva a intentarlo"',
            }
          }
        },
        feedbackVerification:{
          title: '¡Se acreditó tu pago!',
          subtitle:'Verás los importes en tu Estado de Cuentas.',
          description: 'Ahora, vamos a verificar tu identidad por única vez.',
          message: 'Te estamos redireccionando en 5 segundos',
        }
    }
},
  es: {
    banners: {
      no_discount:'Sin Descuento',
      extraBenefit:'Obtené un {percentage} % de beneficio extra',
      title: 'Si abonás con tarjeta Visa, Mastercard, o Amex ¡Tenés cuotas sin interés!',
      installments: '¡Cuotas sin interés!',
      available_banks: 'Unicamente en bancos habilitados',
      scholarship_title: 'Tu beca se ha renovado automáticamente',
      no_subjects_quota: 'Aún no cumplís con el cupo requerido de materias. Debés tener al menos 7 materias aprobadas para poder reinscribirte',
      debt: {
        title: '¡Tenés una deuda pendiente!',
        subtitle: 'Es importante que saldes tu deuda para reinscribirte a tu próximo periodo',
      },
      benefit: {
        renewed: 'Tu beneficio ha sido renovado correctamente',
        want_renew: '¿Querés renovar tu Beneficio {benefit}?',
        must_approve: 'La documentación que enviaste debe ser aprobada para que se aplique el beneficio',
        no_renew_benefit: 'No pudimos renovar tu beneficio',
        no_renew: 'No pudimos renovar tu beca, tu promedio es menor al requerido. Revisá los requisitos.',
        upload_button: 'Subir documentación',
        upload_title: 'Beneficio',
        upload_description: 'Subir documentación',
        see_regulation: 'Ver reglamento',
        upload_view: {
          title: 'Adjunte la siguiente documentación',
          no_files: 'No se detectaron archivos para enviar',
          pending_files: 'Hay archivos pendientes de enviar. ¿Desea salir?',
          send_confirmation: '¿Desea enviar los siguientes archivos? No se podrán modificar',
          file_without_type: 'Se encontraron uno o mas archivos sin el tipo de documentación seleccionado',
          success_message: 'La documentación se envió correctamente',
          error_message: 'Ocurrió un error enviando la documentación',
        }
      }
    },
    inputErrors: {
      required: 'Este campo es obligatorio',
      length: 'El formato es inválido',
      validateName: 'Debe ingresar Nombre y Apellido',
      invalidBin: 'La tarjeta ingresada no corresponde con el metodo de pago seleccionado',
    },
    breadcrumbs: {
      title: 'Pagos',
      description: 'Acá podrás hacer tus pagos y ver las promociones vigentes.'
    },
    finalView: {
      successMessage: {
        title: '¡Listo, se acreditó tu pago!',
        description: 'En tu resúmen verás el cargo de {total} como MERCADO PAGO.',
      },
      successSuscriptionMessage: {
        title: '¡Listo, se genero tu suscripcion.!',
      },
      processMessage: {
        title: 'Tu pago está siendo revisado.',
        description: 'En el plazo de 72 horas habiles confirmaremos si tu pago fue aprobado o rechazado.'
      },
      rejectedMessage: {
        title: 'Hubo un error al procesar tu pago.',
        description: ''
      },
      successDebitMessage: {
        title: '¡Listo!',
        description: 'Serás redireccionado para realizar el pago.',
      },
      rejectedDebitMessage: {
        title: 'Hubo un error al procesar tu pago.',
        description: ''
      },
      subscriptionErrorMessage: {
        title: 'Hubo un error al generar tu suscripcion.',
        description: ''
      },
    },
    view: {
      discount:{
        popups:{
          error:{
            title:'Lo sentimos hubo un problema con el servidor! Intente nuevamente!',
          }
        }
      },
      getnet: {
        promotion:'MIERCOLES 31 DE JULIO <br/> 6 CUOTAS <br/> sin interés'
      },
      reenrollment: {
        error_creating: 'Ocurrió un error generando los cupones'
      },
      repayment: {
        banner: 'Recuerda que solo podrás repactar tu deuda una vez por semestre',
        debt_title: 'Deuda a repactar',
        title: 'Selecciona una de las opciones de repactación disponibles',
        subtitle: 'El primer vencimiento será el día 15 del siguiente mes a la fecha del pago del pie.',
        basicPayment: 'Pie',
        totalInstallments: 'Saldo',
        option_installments: ' cuotas de ',
        installment: 'Cuota | Cuotas',
      },
      summary: {
        title: 'Tu resumen',
        validity: '* Promoción válida hasta {todayDate}',
        total: 'Total',
        dues: 'Con {cardName} abonarás \n {installments} cuotas de {installment_amount} por mes'
      },
      contract:{
        title:'Acepta los términos y condiciones',
        preview:'Previsualización de contrato',
        close: 'Cerrar',
        terms:'He leído y aceptado el contrato en todos sus términos.',
        seeContract:'Ver contrato.',
        subtitle:'El pago implica consentimiento expreso del contrato'
      },
      stepper: {
        selection: 'Selección',
        payment: 'Pago',
        confirmation: 'Confirmación'
      },

      coupons: {
        print: 'Imprimir cupón de pago',
        reminder: 'Recuerda que este cupón no constituye pago.',
        instructions: 'Díctale estos números al cajero',
        nextSteps: 'Ahora debes acercarte a una sucursal de {service} y pagar',
        nextSteps1: 'Ahora debes acercarte a una sucursal de',
        nextSteps2: 'y pagar',
        sentOn: 'Enviado el',
        couponNumber: 'Número de referencia:',
        accountNumber: 'Número de cuenta:',
        info: 'Ingresa a {service} y encuentra tu sucursal más cercana para realizar el pago.',
        disclaimer: 'El pago no se verá reflejado hasta que se acredite. Ten en cuenta que el cupón tiene una validez de 72 hs. y pueden pasar hasta 2 días hábiles hasta que se acredite. Esto es ajeno a {org}.',

      },

      history: {
        title: 'Historial de pagos',
        description: 'Aquí encontrarás los pagos que has realizado a lo largo de tu carrera.',
        headers: {
          number: 'Número',
          description: 'Descripción',
          term: 'Periodo',
          date: 'Fecha de pago',
          amount: 'Monto',
          status: 'Estado',
          bills: 'Comprobante',
        }
      },

      labels: {
        how_many_installments: '¿En cuantas cuotas quieres pagarlo?',
        no_pending_payments: 'No tienes pagos pendientes',
        cardTitle: 'Datos de Tarjeta',
        billingOptions: 'Si requieres factura fiscal, marca la casilla y completa los campos de datos fiscales.',
        billingGeneric: 'Queremos informarte que nuestras facturas son emitidas por defecto a una persona genérica. En caso de que necesites que la factura sea a nombre de una persona física o moral, por favor envía un correo electrónico a pagos@onmex.mx, con asunto: "solicitud  factura + tu curp", con los datos necesarios para la emision de la factura.',
        billingTitle: 'Datos de Facturación',
        billingReminder: 'Es <strong>importante</strong> que ingreses tus datos fiscales de forma precisa para que podamos iniciar el proceso de facturación.',
        billingTitlePrisma: 'Datos titular tarjeta',
        selectPayment: '¿Cómo prefieres pagar?',
        selectPaymentHelp: 'Si no encontrás tu medio de pago, comunicate con Albert',
        creditcardPayment: 'Pago con tarjeta de crédito 2',
        creditcardBilling: 'Ingresa los datos de Facturación',
        creditcardMultiple: '¿Cuánto vas a pagar con cada tarjeta?',
        creditcardSingle: 'Seleccioná como lo querés pagar',
        no_interest: 'Sin interés',
        selectBank: 'Seleccioná tu entidad crediticia',
        selectCard: 'Seleccioná tu tarjeta',
        creditcardMultipleDescription: 'Ingresa los montos asociados a cada tarjeta',
        creditcardPaymentsDone: 'Mis Pagos Realizados',
        paymentsPending: 'Pendientes de pago',
        paymentsCash: '¿Dónde prefieres pagar?',
        paymentsExpiration: 'Vencimiento',
        creditcardPaid: '¿Qué vas a pagar?',
        paymentInvoice: 'Comprobante de Pago',
        downloadInvoice: 'Descargar Comprobante',
        paymentDate: 'Fecha',
        seeMyPayments: 'Ver mis pagos',
        seePendingPayments: 'Ver deuda no vencida',
        futurePayments: {
          title: 'Deuda no vencida',
          subtitle: 'Monto final sin descuento de pronto pago reflejado',
          description: 'Descripción',
          dueDate: 'Vencimiento',
          amount: 'Monto final',
          termSubtitle: 'Cursada de {startMonth} a {endMonth}',
        },
        type: 'Tipo',
        details: 'Detalle',
        paymentMethod: 'Método de Pago',
        paymentLink: 'Pagar',
        back: 'Volver',
        repayment: 'Repactar',
        reinscription: 'Reinscripción',
        inscription: 'Inscripción',
        nextMonthsPayments: 'En los próximos meses',
        promotionsLink: 'ver promociones',
        promotions: 'Promociones',
        creditCard: 'Tarjeta N°',
        tea: 'TEA',
        cft: 'CFT',
        cancel: 'Cancelar',
        confirm: 'Confirmar',
        continue: 'Continuar',
        seePaymentMethods: 'Ver formas de pago',
        moreDiscountsNextStep: 'Obtén hasta {discount}% de beneficio extra en las formas de pago',
        applyAdaptationInsurance: 'Aplicar Seguro de Adaptación',
        nameoncard: 'Titular de la Tarjeta',
        idoncard: 'Número de DNI',
        cardnumber: 'Número de la Tarjeta',
        cardvfcode: 'Código de Seguridad',
        cardexpiration: 'Vencimiento',
        taxSituation: 'Situación fiscal',
        name: 'Nombre y Apellido',
        businessName: 'Razón social',
        state: 'Provincia',
        city: 'Localidad',
        zipCode: 'Código Postal',
        phone: 'Teléfono',
        streetaddress: 'Dirección',
        streetnumber: 'Número',
        streetfloor: 'Piso',
        streetapartment: 'Dpto.',
        amount: 'Monto',
        date: 'Fecha de Pago',
        concept: 'Concepto',
        time: 'Período 2',
        period: 'Período',
        no_cash_method: 'Pronto tendrás opciones de pago en efectivo.',
        due: 'Cuota',
      },
      subscription: {
        payment: 'Financiación {org}',
        paymentMethod: 'Medio de pago',
        installments: 'cuotas',
        installment: 'Cuota',
        description: 'Descripción',
        expiration: 'Vencimiento',
        amount: 'Monto',
        adminExpenses: 'Gastos administrativos',
        term: 'Periodos: ',
        info: 'Aplicable a {installments} cuotas de ${mount}'
      },
      creditCard:{
        labels:{
          cardName: 'Nombre y Apellido',
          cardHolder: 'Titular de la tarjeta',
          cardNameLegen: 'Tal cual esté impreso en la tarjeta.',
          cardExpires: 'Venc.'
        }
      },
      benefit: {
        type: {
          uniqueDiscount: 'Descuento único ',
          referedFriend: 'Amigo referido',
        }
      },
    },
    gatewayMPFeedback: {
      approved: {
        accredited:{
          title: '¡Listo! Se acreditó tu pago.',
          description: 'En tu resumen verás el cargo de ${amount} como {description}.',
          message: 'Ahora podés cerrar este mensaje y continuar en la plataforma.'
        },
        accredited_multiple:{
          title: '¡Listo! Se acreditó tu pago.',
          description: 'En tus resúmenes verás los cargo de ${amount} y ${lastAmount} como {description}.',
          message: 'Ahora puedes cerrar este mensaje y continuar en la plataforma.'
        },
      },
      in_process: {
        pending_contingency: {
          title: 'Tu pago está siendo procesado',
          description: '¡No te preocupes!',
          message: 'En menos de 2 días hábiles te avisaremos por mail si se acreditó. '
        },
        pending_review_manual: {
          title: 'Tu pago está siendo procesado',
          description: '¡No te preocupes!',
          message: 'En menos de 2 días hábiles te avisaremos por mail si se acreditó o si necesitamos más información.'
        },
      },
      rejected:{
        cc_rejected_bad_filled_card_number:{
          title: 'El pago no fue procesado',
          description: 'Revisá el número de tarjeta.',
          message: 'Verificá que ingresaste bien los números e intentá nuevamente.'
        },
        cc_rejected_bad_filled_date: {
          title: 'El pago no fue procesado',
          description: 'Revisá la fecha de vencimiento.',
          message: 'Verificá la fecha de vencimiento de tu tarjeta'
        },
        cc_rejected_bad_filled_other: {
          title: 'El pago no fue procesado',
          description: 'Revisá los datos',
          message: 'Verificá y volvé a ingresar los datos de tu tarjeta'
        },
        cc_rejected_bad_filled_security_code: {
          title: 'El pago no fue procesado',
          description: 'Revisá el código de seguridad de la tarjeta.',
          message: 'Verificá y volvé a ingresar los 3 dígitos de seguridad.'
        },
        cc_rejected_blacklist: {
          title: 'No pudimos procesar tu pago.',
          description: '',
          message: 'Código de notificación: #{statusDetailCode}'
        },
        cc_rejected_call_for_authorize: {
          title: 'Es necesario autorizar',
          description: 'ante {payment_method_id} el pago de ${amount}.',
          message: 'Código de notificación: #{statusDetailCode}'
        },
        cc_amount_rate_limit_exceeded: {
          title: 'No pudimos procesar tu pago.',
          description: 'El pago fue rechazado porque superó el límite del medio de pago.',
          message: 'Código de notificación: #{statusDetailCode}'
        },
        cc_rejected_card_disabled: {
          title: 'Llamá a {payment_method_id} para activar tu tarjeta',
          description: 'O usar otro medio de pago',
          message: 'El teléfono está al dorso de tu tarjeta'
        },
        cc_rejected_card_error: {
          title: 'No pudimos procesar tu pago. Comunicate con tu banco al teléfono que está al dorso de tu tarjeta.',
          description: '',
          message:''
        },
        cc_rejected_duplicated_payment: {
          title: 'Ya hiciste un pago con este valor',
          description: 'O usa otro medio de pago',
          message: 'Si necesitas volver a pagar, utilizá otra tarjeta o medio de pago.'
        },
        cc_rejected_high_risk: {
          title: 'Tu pago fue rechazado',
          description: 'comunicate con tu banco para más información',
          message: 'Código de notificación: #{statusDetailCode}'
        },
        cc_rejected_insufficient_amount: {
          title: 'Tu banco rechazó la operación por fondos insuficientes. Por favor, comunicate con tu banco al teléfono que está al dorso de tu tarjeta.',
          description: '',
          message:''
        },
        cc_rejected_invalid_installments: {
          title: 'Tu banco no procesa pagos en ese número de cuotas. Revisá el número de cuotas y volvé a intentarlo.',
          description: '',
          message:''
        },
        cc_rejected_max_attempts: {
          title: 'Llegaste al límite de intentos permitidos. Elegí otra tarjeta o medio de pago.',
          description: '',
          message:''
        },
        cc_rejected_other_reason: {
          title: 'No pudimos procesar tu pago. Por favor, comunicate con tu banco al teléfono que está al dorso de tu tarjeta.',
          description: '',
          message:''
        }
      },
    },
    errorsPrisma: {
      invalid_number: 'Tu tarjeta no está habilitada. Para habilitarla comunicate al teléfono que está al dorso.',
      security_code_error: 'Código de seguridad incorrecto, revisa y volve a intentarlo.',
      expired_card: 'Fecha de vencimiento incorrecta, revisa y volve a intentarlo.',
      insufficient_amount: 'Tu banco rechazo la operación por fondos insuficientes. Por favor comunicate con tu banco al teléfono que está al dorso de tu tarjeta.',
      request_authorization_card: 'Tu banco solicita que te comuniques para pedir autorización, comunicate al teléfono que está al dorso de tu tarjeta.',
      cybersource_error: 'No pudimos procesar tu pago con esta tarjeta. Elige otra tarjeta u otro medio de pago.',
      default_error: 'No pudimos procesar tu pago. Por favor comunicate con tu banco al teléfono que está al dorso de tu tarjeta.',

    },
    errorDefault: {
      data_invalid: 'Datos Invalidos',
      error_processing_payment: 'Se generó un error en el proceso de pago. Por favor, vuelva a intentarlo',
    },
    checkingAccount: {
      account: {
        breadcrumb: {
          title: 'Cuenta corriente'
        }
      },
      details: {
        breadcrumb: {
          title: 'Detalle de cuenta corriente'
        }
      },
      teclabCredits: {
        breadcrub: {
          title: 'Creditos TECLAB'
        }
      }
    },
    popups: {
      methods: {
        debitCard: {
          message: 'Ha ocurrido un error con el medio de pago seleccionado'
        }
      }
    }
  },
  en: {
    view: {
      summary: {
        title: 'Summary',
        total: 'Total'
      },
      stepper: {
        selection: 'Selection',
        payment: 'Payment',
        confirmation: 'Confirmation'
      },

      coupons: {
        print: 'Print payment coupon',
        reminder: 'Remember that this coupon does not represent a payment.',
        instructions: 'Tell the following numbers to the cashier',
        nextSteps1: 'Now you should go to a branch of',
        nextSteps2: 'and pay',
        sentOn: 'Sent on',
        couponNumber: 'Coupon #:',
        info: 'Please enter Servipag.com and find the closest branch to yur location and make the peyment.',
        disclaimer: 'Payment will not be visible until authorized. Please have in mind that coupons are valid for 72 hours and it may take up to 2 working for them to be authorized. This issue is external to {org}.',

      },

      labels: {
        selectPayment: 'How would you like to pay?',
        creditcardPayment: '2 credir cards payment',
        creditcardBilling: 'Please enter your billing information',
        creditcardMultiple: 'How much are you going to pay with each card?',
        creditcardSingle: 'Which card are you going to pay with?',
        creditcardMultipleDescription: 'Please enter the amounts below',
        creditcardPaymentsDone: 'My previous payments',
        paymentsPending: 'Pending payments',
        paymentsCash: 'Where would you like to pay?',
        paymentsExpiration: 'Expiration date',
        creditcardPaid: 'What are you going to pay?',
        paymentInvoice: 'Payment invoice',
        downloadInvoice: 'Download invoice',
        paymentDate: 'Date',
        seeMyPayments: 'See my payments',
        type: 'Type',
        details: 'Details',
        paymentMethod: 'payment Method',
        paymentLink: 'pay',
        nextMonthsPayments: 'Next months´ payments',
        promotionsLink: 'see promotions',
        creditCard: 'Card #',
        tea: 'TEA',
        cft: 'CFT',
        cancel: 'Cancel',
        confirm: 'Confirm',
        nameoncard: 'Name on Card',
        idoncard: 'ID. Number',
        cardnumber: 'Card Number',
        cardvfcode: 'CVC',
        cardexpiration: 'Expiration',
        financialstatus: 'Financial status',
        name: 'Name',
        state: 'State',
        city: 'City',
        streetaddress: 'Street name',
        streetnumber: 'Street number',
        streetfloor: 'Floor',
        streetapartment: 'Dept.',
        amount: 'Amount',
        date: 'Payment Date',
        concept: 'In behalf of',
        time: 'Period 2',
        period: 'Period',
        zipCode: 'Zip code',
      },
      subscription: {
        payment: '{org} financing',
        paymentMethod: 'Payment method',
        installments: 'installments',
        installment: 'Installment',
        description: 'Description',
        expiration: 'Expiration',
        amount: 'Amount',
        adminExpenses: 'Setup fee'
      }
    },
    popups: {
      methods: {
        debitCard: {
          message: 'An error has occurred with the selected payment method'
        }
      }
    }
  }
};


export default paymentsTranslations
