<template>
    <div>
      <v-container fluid class="d-flex pa-0 fullHeight">
        <v-container class="container-custom ">
          <Breadcrumbs
          :title="$t('modules.academic.processings.processingsView.breadcrumbs.title')"
          :description="$t('modules.academic.processings.processingsView.breadcrumbs.description')"
          >
           <Button 
              :disabled="!canCreate" 
              icon="mdi-plus"
              @clicked="newProcessings"
              :text="$t('actions.new')"
              depressed></Button>
          </Breadcrumbs>
          <v-row justify="center">
            <v-col sm="12" class="viewSpaces ">
              <SuperTable
                  :headers="headers"
                  :data="processings"
                  :show-select="false"
                  :page.sync="currentPage"
                  :pageCount="pagesAmount"
                  :itemsPerPage.sync="itemsPerPage"
                  :totalItems="totalItems"
                  :permissions="{update: canUpdate}"
                  @click-row="showSelectedProcessing"
                  @searchAction="searchAction"
                  @inputPagination="onInputPagination"
                  @lengthPaginationChange="onLengthPaginationChange"
                  @updateData="updateArea"
                  />
            </v-col>
        </v-row>
        </v-container>
      </v-container>
        
    </div>
  </template>
  
  <script>
  import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
  import SuperTable from '../Components/SuperTable'
  import { $processings } from '../Services/index'
  import Button from '@/components/Button/Button';
  
  export default {
  name:'ProcessingsView',
  components: {
    SuperTable,
    Breadcrumbs,
    Button,
  },
  data() {
    return {
      processings: [],
      loading: false,
      page: 1,
      dialog:false,
      itemsPerPage: 20,
      totalItems: 0,
      pagesAmount: 0,
      search: null,
      clearSelected:'',
      sort: {
        sortBy: 'name',
        sortDesc: false,
      }, 
      pagination: {
          limit: 20,
          page: 1,
          pagesAmount: 1,
          currentPage: 1
        },
      
      headers:[
        {
          text: this.$t('modules.academic.processings.processingsView.headers.name'),
          value: 'name',
        },
        {
          text: this.$t('modules.academic.processings.processingsView.headers.description'),
          value: 'description',
        },
        {
          text: 'Estado',
          value: 'status',
          sortable: false,
        },
      
      ],
      dataArea:null
    }
    },
    mounted () {
      this.fetchData();
    },
    methods: {
      async fetchData() {
        if (this.loading) return false;
        this.clearSelected = new Date().getMilliseconds().toString();
        this.loading = true;
        try {
          const params = {
            page: this.pagination.page - 1,
            length: this.itemsPerPage,
            orderBy: this.sort.sortBy,
            orientation: this.sort.sortDesc ? 'desc' : 'asc',
            search: this.search
           };
  
  
          const processings = await $processings.find('all', null, { params })
          this.totalItems = processings?.data?.totalElements;
          this.pagesAmount = processings.data.totalPages;
          this.processings = processings.data.content.sort((a, b) => a.name.localeCompare(b.name));
        } catch (error) {
          this.processings = []
          this.pagination.page = 1
          this.pagination.pagesAmount = 1
          this.totalItems = 0;
          throw error;
        }finally{
          this.loading = false;
        }
      },
    showSelectedProcessing(id) {
      this.$router.push({path: `processings/update/${id}`}) 
    },
    searchAction(data) {
      this.search = data;
      this.fetchData();
    },
    onInputPagination(event) {      
        this.pagination.page = event;
        this.pagination.currentPage = event;
        this.fetchData();
    },
    onLengthPaginationChange(event) {
          this.pagination.page = 1
          this.itemsPerPage = event
          this.pagesAmount = 1
          this.currentPage = 1
          this.fetchData();
    },
    newProcessings() {
      if (this.canCreate) this.$router.push('/academic/processings/create')
    },
    hideArea() {
      this.dialog = false
      setTimeout(() => {
        this.dataArea = null
        }, 1000);
    },
    updateArea(item){
      this.dataArea = item
      this.showArea()
    },
    },
    computed: {
        canCreate() {
          return this.$permissions.portaladministrativo.academic.processings.CREATE
        },
        canUpdate() {
          return this.$permissions.portaladministrativo.academic.processings.UPDATE
        },
        canSendMessage() {
          return this.$permissions.portaladministrativo.academic.processings.SEND
        },
      },
      watch: {
        sort: {
          handler() {
            this.fetchData();
        },
      deep: true
    },
  }
  }
  </script>
  
  <style lang="scss" scoped/>
  