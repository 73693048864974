<template>
	<div>
		<v-container fluid class="d-flex pa-0 fullHeight">
			<v-container fluid class="px-0">

				<h4 class="primary--text mt-0">{{ $t('modules.portalalumno.payments.paymentsStudents.view.labels.creditcardPaid') }}</h4>

          <v-row class="center mb-0 pl-3 pr-3">
            <v-col  class="d-flex align-center py-0" cols="2" lg="1">
              <v-checkbox v-if="!isAdminPayment?.selected === true "  color="primary" v-model="selectedall"
              :disabled="hasAdaptationInsurance || generatePaymentIds || resumen.total == 0" @change="selectAll " ></v-checkbox>
            </v-col>
            <v-col class="d-flex align-center py-0" cols="5" lg="5">
              <label class="payments-label color-pending text-left">
                {{$t('modules.portalalumno.payments.paymentsStudents.view.labels.paymentsPending')}}
              </label>
            </v-col>
            <v-col class="d-flex align-center hidden-md-and-down py-0" lg="3">
              <label class="payments-label color-pending text-center">
                {{$t('modules.portalalumno.payments.paymentsStudents.view.labels.paymentsExpiration')}}
              </label>
            </v-col>
            <v-col class="d-flex align-center text-right py-0" cols="5" lg="3">
              <label class="payments-label color-pending text-right" style="margin-right: 12px;">
                {{$t('modules.portalalumno.payments.paymentsStudents.view.labels.amount')}}
              </label>
            </v-col>
          </v-row>

          <v-row v-if="payments.length == 0">
            <v-col class="caption color-text py-0 text-center">
              <b>{{ $t('modules.portalalumno.payments.paymentsStudents.view.labels.no_pending_payments') }}</b>
            </v-col>
          </v-row>

          <div v-for="(payment, index) in payments" :key="payment.id">
            <PaymentComplex
              v-if="$isMexico ? payment.billingProduct != 80 : true"
              :systemConfig="systemConfig"
              :canReprogram="canReprogram"
              :payment="payment"
              :seleccionDisabled="generatePaymentIds  || index !== 0 && showAllPayments || index === 0 && blockAdmin || index === 0 && blockAdminNext || payment.options.off == 100"
              @updatepaymentmethod="updatepaymentmethod($event, index)"
              :historyDiscount="getHistoryDiscount(payment)"
              @disableAmountZero="disabledSelectedPayments($event, index)"
            ></PaymentComplex>
          </div>

        <div class="d-flex my-10" v-if="futurePayments.length">
          <Button
            class="ml-4"
            :text="$t('modules.portalalumno.payments.paymentsStudents.view.labels.seePendingPayments')"
            @clicked="pendingPaymentsOpen = true"
            outlined
          ></Button>
        </div>

				<v-row>
					<v-col v-if="hasReinscription && !$isMexico" class="text-h5 primary--text mt-2 mb-2">
            <b v-if="studentData && studentData.studentType && studentData.studentType.value == 7">{{$t('modules.portalalumno.payments.paymentsStudents.view.labels.inscription')}}</b>
            <b v-else>{{$t('modules.portalalumno.payments.paymentsStudents.view.labels.reinscription')}}</b>
					</v-col>
					<v-col v-if="hasReinscription" cols="12" class="subtitle-1 pt-0 mb-2">
						<p v-if="$isChile">{{$t('modules.portalalumno.payments.paymentsStudents.view.labels.reinscription_subtitle')}}</p>
            <div v-if="$isChile && extraBenefitDiscount > 0" class="extraBenefitBanner d-flex align-center px-6 py-4">
              <v-icon color="#2C9200" class="icon pa-2 mr-3">mdi-bell</v-icon>
              <strong>{{ $t('modules.portalalumno.payments.paymentsStudents.view.labels.moreDiscountsNextStep', { discount: extraBenefitDiscount }) }}</strong>
            </div>
					</v-col>
					<v-col v-else cols="12" class="subtitle-1 primary--text mt-2 mb-2">
						<b>{{$t('modules.portalalumno.payments.paymentsStudents.view.labels.nextMonthsPayments')}}</b>
					</v-col>
				</v-row>

				<v-row v-if="!paymentsNext.length">
					<v-col class="caption py-0 text-center">
						<b>{{ $t('modules.portalalumno.payments.paymentsStudents.view.labels.no_pending_payments') }}</b>
					</v-col>
				</v-row>

          <div v-for="(payment, index) in paymentsNext" :key="payment.id">
            <PaymentComplex
              v-if="$isMexico ? payment.billingProduct != 80 : true"
              :payment="payment"
              :seleccionDisabled="generatePaymentIds || payment.options.off == 100"
              @updatepaymentmethod="updatepaymentmethodNext($event, index)"
              :historyDiscount="getHistoryDiscount(payment)"
              @disableAmountZero="disabledSelectedPayments($event, index)"
            ></PaymentComplex>
          </div>

          <div class="d-flex mt-14">
            <Button
              v-if="$isIPP"
              class="ml-4"
              :text="$t('modules.portalalumno.payments.paymentsStudents.view.labels.seeMyPayments')"
              @clicked="goToHistory"
              outlined
            ></Button>
          </div>

          <v-dialog v-if="futurePayments.length" v-model="pendingPaymentsOpen" width="700">
            <v-card>
              <v-card-title class="d-flex justify-space-between pb-0">
                {{ $t('modules.portalalumno.payments.paymentsStudents.view.labels.futurePayments.title') }}
                <v-btn icon class="close-dialog" @click="pendingPaymentsOpen = false"><v-icon>mdi-close</v-icon></v-btn>
              </v-card-title>
              <v-card-subtitle class="mt-0">
                {{ $t('modules.portalalumno.payments.paymentsStudents.view.labels.futurePayments.subtitle') }}
              </v-card-subtitle>
              <v-card-text>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">{{ $t('modules.portalalumno.payments.paymentsStudents.view.labels.futurePayments.description') }}</th>
                        <th class="text-center">{{ $t('modules.portalalumno.payments.paymentsStudents.view.labels.futurePayments.dueDate') }}</th>
                        <th class="text-center">{{ $t('modules.portalalumno.payments.paymentsStudents.view.labels.futurePayments.amount') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="payment in futurePayments" :key="`future-payments-${payment.id}`">
                        <td class="py-3">
                          <strong class="d-block">{{ payment.title }}</strong>
                          <small class="d-block">{{ payment.options.term.code }}</small>
                          <small>{{ $t('modules.portalalumno.payments.paymentsStudents.view.labels.futurePayments.termSubtitle', { startMonth: getFormattedDate(payment.options.term.classStartDate, 'MMMM'), endMonth: getFormattedDate(payment.options.term.classEndDate, 'MMMM')}) }}</small>
                        </td>
                        <td class="py-3 text-center">{{ getFormattedDate(payment.options.dueDate) }}</td>
                        <td class="py-3 text-center">{{ payment.options.amount | $currency }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
              <v-card-text class="text-right">
                <v-btn outlined rounded color="primary" @click="pendingPaymentsOpen = false">{{ $t('actions.close') }}</v-btn>
              </v-card-text>
            </v-card>
          </v-dialog>
			</v-container>
		</v-container>
	</div>
</template>

<script>
import PaymentComplex from '../Components/PaymentComplex/PaymentComplex'
import Button from '@/components/Button/Button'
import moment from 'moment'
import { mapGetters } from 'vuex'

  export default {
    props: {
      payments: Array,
      paymentsNext: Array,
      repayment: Object,
      systemConfig: Object,
      generatePaymentIds: { type: Boolean, default: false },
      historyDiscounts: Array,
      futurePayments: Array,
      resumen: Object,
      extraBenefitDiscount: { type: Number, default: 0 },
    },
    components: {
      PaymentComplex,
      Button,
    },
    data() {
      return {
        selectedall: false,
        blockAdminNext:false,
        blockAdmin:false,
        showAllPayments:false,
        pendingPaymentsOpen: false,
      };
    },
    computed: {
      ...mapGetters({
        studentData: 'commons/getStudentData',
      }),
      isAdminPayment() {
        return this.payments.find(item => item.billingProduct === this.$sisConstants('modules.products.gasto_administrativo'))
      },
      anySelected(){
        return this.payments.filter(item => item?.selected === true)
      },
      anyNextSelected(){
        return this.paymentsNext.filter(item => item?.selected === true)
      },
      canReprogram() {
        return this.repayment.available && this.payments.filter(payment => payment.repaymentAvailable === true).length >= this.repayment.installments
      },
      hasAdaptationInsurance() {
        return [...this.payments, ...this.paymentsNext].some(payment => payment.options.adaptationInsurance) && ![...this.payments, ...this.paymentsNext].every(payment => payment.options.adaptationInsurance)
      },
      hasReinscription() {
        return this.paymentsNext.some(payment => payment.billingProduct == this.$sisConstants('modules.products.arancel'))
      }
    },
    methods: {
      getFormattedDate(date, format = 'DD-MM-YYYY') {
        return moment(date).format(format)
      },
      goToHistory(){
				if(this.$isIPP)this.$trackEvent('Pago', 'Clk_BtnVerPag_Pagos', 'PagosHistoricos') //Google Analytics - ver mis pagos IPP
				this.$router.push('/payments/history')
			},
      disabledSelectedPayments() {
        this.seleccionDisabled = true
      },
      getHistoryDiscount({ id, reenrollmentId}){
        const discount = this.historyDiscounts
          .find(discount=>id && id === discount.accountId || reenrollmentId && reenrollmentId === discount.reenrollmentId)
        return discount
      },
      selectAll (event) {
        this.payments.forEach(payment => {
          if(payment.billingProduct !== this.$sisConstants('modules.products.gasto_administrativo') )
          payment.selected = event
        })
        this.paymentsNext.forEach(nextPayment => {

            nextPayment.selected = event

        })
        this.emitSelectedPayments()
      },
      emitSelectedPayments () {
        this.checkAllSelected()
         if(this.isAdminPayment && this.isAdminPayment?.selected === true) {
           this.showAllPayments = true
         } else {
           this.showAllPayments = false
         }

         if(this.anySelected.length >= 1 &&  !this.isAdminPayment?.selected === true){
         this.blockAdmin = true
         } else {
         this.blockAdmin = false
         }
         if(this.anyNextSelected.length >= 1 &&  !this.isAdminPayment?.selected === true){
         this.blockAdminNext = true
         } else {
         this.blockAdminNext = false
         }

        const selectedPayments = [...this.payments.filter(payment => payment.selected), ...this.paymentsNext.filter(payment => payment.selected)]
        const allPayments = [...this.payments, ...this.paymentsNext]
        this.$emit('billingchange', {payments: allPayments, itemsToUpdate: 'all'})
        this.$emit('billingchange', {payments: selectedPayments})
      },
      updatepaymentmethod(value, index) {
        if(!this.isAdminPayment){
        for ( const [i,p] of this.payments.entries() ) {
          if ( new Date().toISOString().split('T')[0] > p.options.dueDate && this.payments[index].options.dueDate > p.options.dueDate )
            this.payments[i].selected = true;
        }
      }
        if (this.payments[index].independent) {
          this.payments[index].selected = value
        } else {
          if (value === true) {
            for (let i = index; i >= 0; i--) {
              if (!this.payments[i].independent && this.payments[i].groupId == this.payments[index].groupId) this.payments[i].selected = true
            }
          } else {
            for (let i = index; i <= this.payments.length-1; i++) {
              if (!this.payments[i].independent && this.payments[i].groupId == this.payments[index].groupId) this.payments[i].selected = false
            }
            this.paymentsNext.forEach(payment => {if (!payment.independent) payment.selected = false})
          }
        }
        this.emitSelectedPayments()
      },
      updatepaymentmethodNext (value, index) {
        for ( const [i,p] of this.payments.entries() ) {
          if ( new Date().toISOString().split('T')[0] > p.options.dueDate && this.paymentsNext[index].options.dueDate > p.options.dueDate )
            this.payments[i].selected = true;
        }
        for ( const [i,p] of this.paymentsNext.entries() ) {
          if ( new Date().toISOString().split('T')[0] > p.options.dueDate && this.paymentsNext[index].options.dueDate > p.options.dueDate )
          this.paymentsNext[i].selected = true;
        }
        if (this.paymentsNext[index].independent) {
          this.paymentsNext[index].selected = value
        } else {
          if (value === true) {
            for (let i = index; i >= 0; i--) {
              if (!this.paymentsNext[i].independent && this.paymentsNext[i].groupId == this.paymentsNext[index].groupId) this.paymentsNext[i].selected = true
            }
            this.payments.forEach(payment => { if (!payment.independent && payment.groupId == this.paymentsNext[index].groupId) payment.selected = true })
          } else {
            for (let i = index; i <= this.paymentsNext.length-1; i++) {
              if (!this.paymentsNext[i].independent && this.paymentsNext[i].groupId == this.paymentsNext[index].groupId) this.paymentsNext[i].selected = false
            }
          }
        }
        this.emitSelectedPayments()
      },
      checkAllSelected() {
        let selectedall = true
        let extraOffCount = 0
        let pendingCount = 0

        this.payments.forEach(payment => {
          if (!payment.selected) selectedall = false
          if (payment.options.benefitExtra || payment.options.reenrollmentExtraOff) {
          if (((payment.options.reenrollmentExtraOff || payment.groupId == 4) && this.$isTeclab) || this.$isIPP) pendingCount++
          if (payment.selected) extraOffCount++   // Grupo 1 = arancel
          }
        })
        this.paymentsNext.forEach(payment => {
          if (!payment.selected) selectedall = false
          if (payment.options.benefitExtra || payment.options.reenrollmentExtraOff) {
            if (((payment.options.reenrollmentExtraOff || payment.groupId == 4) && this.$isTeclab) || this.$isIPP) pendingCount++
            if (payment.selected) extraOffCount++   // Grupo 1 = arancel
          }
        })

        // Se define porcentaje extra de descuento solo para el pago de mas de 4 cuotas del arancel correspondiente
        this.payments.map(payment => {
          if(this.$isIPP && payment.options.reenrollmentExtraOff){
            if(payment.options.reenrollmentExtraOff){
              payment.options.addedExtraOff = payment.options.reenrollmentExtraOff
            }
            return payment
          }

          if (payment.options.reenrollmentExtraOff) {
            payment.options.addedExtraOff = (extraOffCount >= 2 /*&& extraOffCount == pendingCount*/) ? payment.options.reenrollmentExtraOff : 0
          } else {
            payment.options.extraOff = (extraOffCount >= 5 && extraOffCount == pendingCount ) ? payment.options.benefitExtra : 0
          }
          return payment
        })
        this.paymentsNext.map(payment => {
          if(this.$isIPP){
            if(payment.options.reenrollmentExtraOff){
              payment.options.addedExtraOff = payment.options.reenrollmentExtraOff
            }
            return payment
          }

          if (payment.options.reenrollmentExtraOff) {
            payment.options.addedExtraOff = (extraOffCount >= 2 /*&& extraOffCount == pendingCount*/) ? payment.options.reenrollmentExtraOff : 0
          } else {
            payment.options.extraOff = (extraOffCount >= 5 && extraOffCount == pendingCount ) ? payment.options.benefitExtra : 0
          }
          return payment
        })
        this.selectedall = selectedall
      }
    },
    watch: {
      generatePaymentIds(val) {
        if (val) {
          this.$nextTick(() => {
            this.selectedall = true
            this.selectAll(true)
          })
        }
      }
    },
  };
</script>

<style scoped lang="scss">

  .color-pending { color: var(--v-primary-lighten2); }
  .color-text { color: var(--v-primary-lighten3); }
  .payments-label { font-size: 11px; opacity: 0.8; width: 100%; }

  .extraBenefitBanner {
    background-color: #D7FFBE;
    color: #2C9200;
    border: 1px solid #2C9200;
    border-radius: 10px;
    .icon {
      background-color: #B2F799;
      border-radius: 50%;
    }
  }


</style>
