import api from '@/api'

const { $core, $email } = api;
const $hs = api.$hubspot
const $st = api.$student

export const { $calendarDates, $materias, $studentSubject, $studentAccount, $student } = $st;
export const { $banners } = $core
export const { $referir } = $email
export const { $hubspot } = $hs
