<template>
  <v-container fluid class="d-flex pa-0 fullHeight">
    <v-container fluid class="px-0">
      <div class="px-md-10">

        <transition name="fade">
          <div>
            <div class="d-flex">
              <v-btn icon @click="goBack"><v-icon>mdi-chevron-left</v-icon></v-btn>
              <h4 class="d-flex align-center primary--text ml-2">
                {{ availablePaymentMethods.length ? $t('modules.portalalumno.payments.paymentsStudents.view.labels.selectBank') : $t('modules.portalalumno.payments.paymentsStudents.view.labels.creditcardSingle') }}
              </h4>
            </div>

            <v-row v-if="availablePaymentMethods.length" class="mt-3">
              <v-col cols="6" md="4" lg="3" v-for="(method, i) in availablePaymentMethods" :key="`banco-${i}`">
                <v-card
                  class="bank-card d-flex align-center justify-center pa-6"
                  :class="{'selected': selectedBank && selectedBank.id == method.id}"
                  @click="selectBank(method)"
                  outlined
                >
                  <v-img class="mx-2" height="65" :src="`${assetsUrl}${method.logo}`" contain :alt="method.name"></v-img>
                  <v-chip v-if="!method.interest && method.bankId == 6" class="sin-interes" color="primary">3 cuotas sin interés</v-chip>
                  <v-chip v-else-if="!method.interest" class="sin-interes" color="primary">{{ $t('modules.portalalumno.payments.paymentsStudents.view.labels.no_interest') }}</v-chip>
                </v-card>
              </v-col>
              <v-col   cols="6" md="4" lg="3" @click="selectBank(otherBank)">
                <v-card
                  class="bank-card pa-6 d-flex align-center justify-center fill-height"
                  :class="{'selected': selectedBank && selectedBank.id == 'otro'}"
                  outlined
                >
                  <h2 class="primary--text">Otro</h2>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="selectedBank && selectedBank.cards" class="my-3">
              <v-col cols="12">
                <h4 class="primary--text">{{ $t('modules.portalalumno.payments.paymentsStudents.view.labels.selectCard') }}</h4>
              </v-col>
              <v-col cols="6" md="4" lg="3" v-for="(card, i) in selectedBank.cards" :key="`tarjeta-${i}`">
                <v-card
                  class="bank-card d-flex align-center justify-center pa-6"
                  :class="{'selected': selectedCard && selectedCard.id == card.id}"
                  @click="selectCard(card)"
                  outlined
                >
                  <v-img class="mx-2" height="65" :src="`${assetsUrl}${card.logo}`" contain :alt="card.name"></v-img>
                  <v-chip v-if="!card.interest && card.bankId == 6" class="sin-interes" color="primary">3 cuotas sin interés</v-chip>
                  <v-chip v-else-if="!card.interest" class="sin-interes" color="primary">{{ $t('modules.portalalumno.payments.paymentsStudents.view.labels.no_interest') }}</v-chip>
                </v-card>
              </v-col>
              <v-col   v-if="showOthers" cols="6" md="4" lg="3" @click="selectCard(otherBank)">
                <v-card
                  class="bank-card pa-6 d-flex align-center justify-center fill-height"
                  :class="{'selected': selectedCard && selectedCard.id == 'otro'}"
                  outlined
                >
                  <h2 class="primary--text">Otro</h2>
                </v-card>
              </v-col>
            </v-row>

            <h5 v-if="cardIndex != null" class="mb-0 mt-8 pl-3">{{ `${$t('modules.portalalumno.payments.paymentsStudents.view.labels.creditCard')} ${cardIndex + 1}` }}</h5>

            <Prisma
              v-if="selectedAccount.accountKey == 'PRISMA'"
              ref="gateway"
              :cardIndex="cardIndex"
              :selectedMethod="selectedMethod"
              :pendingPayment="pendingPayment"
              :summaryButtons="summaryButtons"
              :selectedAccount="selectedAccount"
              :subscription="subscription"
              :hideInstallments="hideInstallments"
              :resumen="resumen"
              :selectedSubscriptionPaymentType="selectedSubscriptionPaymentType"
              @paymentError="$emit('paymentError')"
              @changeStep="$emit('changeStep', $event)"
              @paymentProcessed="$emit('paymentProcessed', $event)"
              @setDue="$emit('setDue', $event)"
              @resetDue="$emit('setDue')"
            ></Prisma>

            <Macro
              v-if="selectedAccount.accountKey == 'MACROCLICK'"
              ref="gateway"
              :resumen="resumen"
              :selectedAccount="selectedAccount"
              :subscription="subscription"
              :summaryButtons="summaryButtons"
            ></Macro>

            <MercadoPago
              v-else-if="selectedAccount.accountKey == 'MERCADOPAGO'"
              ref="gateway"
              :cardIndex="cardIndex"
              :resumen="resumen"
              :selectedAccount="selectedAccount"
              :summaryButtons="summaryButtons"
              :subscription="subscription"
              :pendingPayment="pendingPayment"
              :selectedMethod="selectedMethod"
              :unselectedReenrollmentsAccountIds="unselectedReenrollmentsAccountIds"
              :hideInstallments="hideInstallments"
              :selectedSubscriptionPaymentType="selectedSubscriptionPaymentType"
              @paymentError="$emit('paymentError')"
              @changeStep="(...args)=> $emit('changeStep', ...args)"
              @paymentProcessed="$emit('paymentProcessed', $event)"
              @setDue="$emit('setDue', $event)"
              @resetDue="$emit('setDue')"
            ></MercadoPago>

            <v-row class="my-10"></v-row>
          </div>
        </transition>
      </div>
    </v-container>
  </v-container>
</template>

<script>
  import Prisma from '../Components/Gateways/Prisma';
  import MercadoPago from '../Components/Gateways/MercadoPago'
  import Macro from '../Components/Gateways/Macro'
  import { $configurations } from '../Services'
  import { mapGetters } from 'vuex';

  export default {
    name: 'PaymentsSingleCardView',
    props: {
      resumen: Object,
      selectedMethod: Object,
      summaryButtons: Object,
      cardIndex: {type: Number, default: null},
      pendingPayment: {type: Boolean, default: false},
      subscription: {type: Object, default: null},
      hideInstallments: {type: Boolean, default: false},
      unselectedReenrollmentsAccountIds: { type: Array, default() { return [] } },
      selectedSubscriptionPaymentType: {type: Object, default: null},
    },
    components: {
      MercadoPago,
      Prisma,
      Macro
    },
    data() {
      return {
        selectedBank: null,
        selectedCard: null,
        availablePaymentMethods: [],
        showOthers:true,
      }
    },
    computed: {
      ...mapGetters({
        assetsUrl: 'commons/getAssetsURL',
        $mpConfig: 'commons/getMpConfig'
      }),
      defualtAccount(){
        return this.$mpConfig.find(account => account.default)
      },
      otherBank(){
        return {id: 'otro', account_id: this.defualtAccount?.id}
      },
      selectedAccount(){
        const prismaAccount = this.$mpConfig.find(account => account.accountKey == 'PRISMA' && account.status)
        if (this.selectedMethod && this.selectedMethod.type == 'bank_transfer' && prismaAccount) {
          // Todo debito a prisma
          return {
              account_id: prismaAccount?.id,
              accountKey: prismaAccount?.accountKey,
              bankId: this.selectedBank && this.selectedBank?.id != 'otro' ? this.selectedBank.bankId : null,
              cardId: null,
              id: 'debvisa'
          }
        }

        if (this.selectedCard?.id == 'otro' || this.selectedBank?.id == 'otro') {
          // Otro seleccionado, mostramos cuenta por defecto
          return {
            account_id: this.defualtAccount?.id,
            accountKey: this.defualtAccount?.accountKey,
            bankId: this.selectedBank?.id != 'otro' ? this.selectedBank.bankId : null,
            cardId: null,
          }
        }

        if (this.selectedBank && this.selectedCard) {
          // Excepcion seleccionada
          return {
            account_id: this.selectedCard?.account_id,
            accountKey: this.selectedCard?.accountKey,
            bankId: this.selectedCard?.bankId,
            cardId: this.selectedCard?.cardId,
            id: this.selectedCard?.id
          }
        }

        return {
          account_id: null,
          accountKey: null,
          bankId: null,
          cardId: null,
        }
      }
    },
    created() {
      this.getAvailablePaymentMethods()
    },
    methods: {
      async getAvailablePaymentMethods() {
        try {
          const banks = []
          if(this.cardIndex === null){
            // const { data } = await $configurations.find(null,null,{params: {'card_type': 'credit_card'}})
            const CARD_DICTIONARY_TYPE = {
              'DÉBITO': 'debit_card',
              'CRÉDITO': 'credit_card',
            }
            const cardType = this.selectedSubscriptionPaymentType ? CARD_DICTIONARY_TYPE[this.selectedSubscriptionPaymentType.meaning.toUpperCase()] : this.selectedMethod.type
            const { data } = await $configurations.find(null,null,{params: {'card_type': cardType}})
            data.forEach(config => {
              const account = this.$mpConfig.find(account => account.id == config.accountId)
              const index = banks.findIndex(bank => bank.id == config.bank.issuer)
              const card = {
                id: config.card.paymentMethodId,
                name: config.card.name,
                logo: config.card.logo,
                interest: config.interest,
                account_id: config.accountId,
                bankId: config.bank.id,
                cardId: config.card.id,
                accountKey: account.accountKey
              }

              if (index >= 0) {
                if (!config.interest) banks[index].interest = false
                banks[index].cards.push(card)
              } else {
                banks.push({
                  id: config.bank.issuer,
                  logo: config.bank.logo,
                  interest: config.interest,
                  cards: [ card ],
                  bankId: config.bank.id
                })
              }
            });
          }

          this.availablePaymentMethods = banks

          if (!banks.length){
            this.selectedBank = this.otherBank
          }

        } catch (error) {
          this.availablePaymentMethods = []
          this.selectedBank = null
          this.selectedCard = null

          this.$store.dispatch('commons/openPopUp', {
            title: this.$t('alert'),
            content: [{ value: this.$t('modules.portalalumno.payments.paymentsStudents.popups.methods.debitCard.message') }],
            actionPrimary: { text: this.$t('actions.accept'), callback: ()=>this.goBack()},
            icon: { color: 'warning', name: 'mdi-alert' },
            color: 'primary',
          });
          throw error
        }
      },
      selectBank(method) {
        this.selectedBank = method
        this.selectedCard = null
      },
      selectCard(card) {
        this.selectedCard = card
      },
      saveBillingData() {
        this.$refs.gateway.saveBillingData()
      },
      goBack() {
        this.summaryButtons.primary.disabled = true
        if (!this.summaryButtons.primary.loading) this.$emit('goBackPayment')
      },
    },
    watch:{
      selectedBank(){
        this.summaryButtons.primary.disabled = true
      },
      selectedCard(){
        this.summaryButtons.primary.disabled = true
      }
    }
  };
</script>

<style scoped lang='sass'>
  .fade
    &-enter-active
      transition: opacity 0.5s
    &-enter,
    &-leave-active
      opacity: 0

  .bank-card
    min-height: 120px

    .sin-interes
      position: absolute
      top: 0
      right: 10px
      transform: translateY(-50%)

    &:hover,
    &.selected
      cursor: pointer
      border: solid 1px var(--v-primary-lighten1)
      background-color: var(--v-primary-lighten5)
</style>
