<template>
  <v-container fluid class="pa-0 pb-8">
    <v-container class="container-custom" v-if="!createNewSubscriptionStatus">
      <Breadcrumbs
        :title="$t('modules.pricing.subscription_rules.create.breadcrumbs.title')"
        :description="$t('modules.pricing.subscription_rules.create.breadcrumbs.description')"
      ></Breadcrumbs>
      <v-row justify="center">
        <v-col class="viewSpaces" sm="12">
          <OutlinedCard
            :title="$t('modules.pricing.subscription_rules.create.codeSection.title')"
            :subtitle="$t('modules.pricing.subscription_rules.create.codeSection.subtitle')"
            :switch-label="(status) ? $t('enabled') : $t('disabled')"
            :switch-value="status"
            @switchChange="(val) => {this.status = val}"
            switcher
            class="mb-6"
          >
            <v-row>
              <v-col sm="6">
                <v-text-field
                  :error-messages="($v.description.$dirty && $v.description.$invalid) ?
                  ((!$v.description.required) ? $t('modules.pricing.subscription_rules.validations.nameRequired') :
                  (!$v.description.alphaNumWithSpaces) ? $t('modules.pricing.subscription_rules.validations.nameAlphanumericWithSpaces') :
                  $t('modules.pricing.subscription_rules.validations.nameMaxLength')) :
                  ''"
                  :label="`${$t('modules.pricing.subscription_rules.create.codeSection.labels.name')} *`"
                  ref="description"
                  outlined
                  @blur="$v.description.$touch()"
                  @keypress="$validateAlphanumeric($event, $v.description.$model,60, true)"
                  v-model="$v.description.$model"
                ></v-text-field>
              </v-col>
              <v-col sm="3">
                <v-text-field
                  :loading="loadingCode"
                  :error-messages="($v.code.$dirty && $v.code.$invalid) ?
                  ((!$v.code.required) ? $t('modules.pricing.subscription_rules.validations.codeRequired') :
                  (!$v.code.alphaNum) ? $t('modules.pricing.subscription_rules.validations.codeAlphanumeric') :
                  (!$v.code.maxLength) ? $t('modules.pricing.subscription_rules.validations.codeMaxLength') :
                  $t('modules.pricing.subscription_rules.validations.codeUnique')) :
                  ''"
                  :label="`${$t('modules.pricing.subscription_rules.create.codeSection.labels.code')} *`"
                  ref="code"
                  outlined
                  @blur="validateCode"
                  @keypress="$validateAlphanumeric($event, $v.code.$model,10)"
                  v-model="$v.code.$model"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="3">
                <v-text-field
                  :error-messages="($v.installments.$invalid && $v.installments.$dirty) ?
                    ((!$v.installments.required) ? $t('modules.pricing.subscription_rules.validations.installmentsRequired') :
                    $t('modules.pricing.subscription_rules.validations.installmentsBetweenValues'))
                    : '' "
                  :label="`${$t('modules.pricing.subscription_rules.create.codeSection.labels.installments')} *`"
                  ref="installments"
                  outlined
                  @blur="$v.installments.$touch()"
                  @keypress="$validateIntegerInputNumber($event, $v.installments.$model, 48,null,1)"
                  v-model="$v.installments.$model"
                ></v-text-field>
              </v-col>
              <v-col sm="3">
                <v-text-field
                  :error-messages="($v.adminExpenses.$invalid && $v.adminExpenses.$dirty) ?
                    ((!$v.adminExpenses.required) ? $t('modules.pricing.subscription_rules.validations.adminExpensesRequired') :
                    $t('modules.pricing.subscription_rules.validations.adminExpensesBetweenValues'))
                    :''"
                  outlined
                  :label="`${$t('modules.pricing.subscription_rules.create.codeSection.labels.adminExpenses')} *`"
                  ref="adminExpenses"
                  append-icon="mdi-percent"
                  @keypress="$validateIntegerInputNumber($event, $v.adminExpenses.$model, 100, null, 0)"
                  @blur="$v.adminExpenses.$touch()"
                  v-model="$v.adminExpenses.$model"
                ></v-text-field>
              </v-col>
              <v-col sm="3">
                <v-text-field
                  :error-messages="($v.payDay.$invalid && $v.payDay.$dirty) ?
                    ((!$v.payDay.required) ? $t('modules.pricing.subscription_rules.validations.payDayRequired') :
                    $t('modules.pricing.subscription_rules.validations.payDayBetweenValues'))
                    : '' "
                  :label="$t('modules.pricing.subscription_rules.create.codeSection.labels.payDay')"
                  outlined
                  @keypress="$validateIntegerInputNumber($event, $v.payDay.$model, 30, null, 1)"
                  @blur="$v.payDay.$touch()"
                  ref="payDay"
                  v-model="$v.payDay.$model"
                ></v-text-field>
              </v-col>
              <v-col sm="6">
                <v-select
                  :loading="loadingPaymentMethods"
                  :error-messages="($v.paymentMethod.$invalid && $v.paymentMethod.$dirty) ?
                    $t('modules.pricing.subscription_rules.validations.paymentMethodRequired') : '' "
                  :label="$t('modules.pricing.subscription_rules.create.codeSection.labels.paymentMethod')"
                  ref="paymentMethod"
                  outlined
                  :items="paymentMethods"
                  item-text="meaning"
                  @blur="$v.paymentMethod.$touch()"
                  v-model="$v.paymentMethod.$model"
                >
                </v-select>
              </v-col>
            </v-row>
          </OutlinedCard>
          <OutlinedCard :title="$t('modules.prepaids.create.studentSection.title')" class="mb-6">
            <v-radio-group class="mt-0" v-model="$v.selectedStudentType.$model" row>
              <v-radio
                v-for="(studentType, index) in studentTypes"
                color="primary"
                ref="studentType"
                :key="index"
                :label="studentType.meaning"
                :value="studentType.value"
              ></v-radio>
              <v-alert
                text
                type="error"
                v-if="$v.selectedStudentType.$anyError"
              >{{ $t('modules.prepaids.validations.studentType') }}
              </v-alert>
            </v-radio-group>
          </OutlinedCard>
          <OutlinedCard
            :title="$t('modules.pricing.subscription_rules.create.billingProductSection.title')"
            :subtitle="$t('modules.pricing.subscription_rules.create.billingProductSection.subtitle')"
            class="mb-6"
          >
            <TransferList
              :loading="loadingSearchProducts"
              :firstListTitle="$t('modules.pricing.subscription_rules.create.billingProductSection.transferList.firstTitle')"
              :secondListTitle="$t('modules.pricing.subscription_rules.create.billingProductSection.transferList.secondTitle')"
              :avatar="false"
              ref="productsAdded"
              :availableItems.sync="billingProducts"
              :added-items.sync="$v.productsAdded.$model"
              areEquals="id"
            ></TransferList>
            <v-alert
              text
              type="error"
              class="mt-4"
              v-if="$v.productsAdded.$dirty && $v.productsAdded.$anyError"
            >{{ $t('modules.pricing.subscription_rules.validations.noSelectedBillingProducts') }}
            </v-alert>
          </OutlinedCard>
          <div class="d-flex justify-end">
            <Button
              :text="$t('actions.cancel')"
              class="mr-4"
              white
              @clicked="$router.replace('/pricing/subscription-rules')"
              @end="resetButtonValues"
            ></Button>
            <Button
              :loading="createButton.loading"
              :disabled="!canCreate"
              :text="$t('actions.create')"
              :success="createButton.success"
              :successText="$t('actions.created')"
              :error="createButton.error"
              :errorText="$t('actions.error')"
              @clicked="validateSubscription"
              @end="resetButtonValues"
            ></Button>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <SuccessMessage
      v-else
      :type="successMessage.type"
      :title="successMessage.title"
      :actionPrimary="successMessage.actionPrimary"
      :actionSecondary="successMessage.actionSecondary"
    />
  </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import Button from '@/components/Button/Button';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import TransferList from '@/components/TransferList/TransferList';
import {required, between, alphaNum, maxLength, helpers} from 'vuelidate/lib/validators';
import {$suscriptions, $types, $products} from '../Services';
import { mapGetters } from 'vuex';

export default {
  name: 'SuscriptionRulesCreate',
  components: {
    Breadcrumbs,
    OutlinedCard,
    Button,
    SuccessMessage,
    TransferList
  },
  data() {
    return {
      description: '',
      code: null,
      status: true,
      studentTypes: [],
      selectedStudentType: 7,
      codeUnique: null,
      installments: null,
      adminExpenses: null,
      payDay: null,
      paymentMethod: null,
      paymentMethods: [],
      billingProducts: [],
      productsAdded: [],
      loadingCode: false,
      loadingSearchProducts: false,
      loadingPaymentMethods: false,
      createNewSubscriptionStatus: false,
      createButton: {
        loading: false,
        success: false,
        error: false
      },
      successMessage: {
        type: null,
        title: null,
        actionPrimary: null,
        actionSecondary: null
      }
    };
  },
  computed: {
    canCreate() {
      return this.$permissions.portaladministrativo.prices.subscription_rules.CREATE;
    },
    ...mapGetters({userId: 'commons/getUserID',})
  },
  mounted() {
    this.getPaymentTypes();
    this.getBillingProducts();
    this.getStudentType();
  },
  methods: {
    async getStudentType() {
      try {
        const { data } = await $types.find(null, null, {params: {type: 'STUDENT_TYPE'}});
        this.studentTypes = data
      } catch (err) {
        this.studentTypes = [];
        throw err;
      }
    },
    async getPaymentTypes() {
      this.loadingPaymentMethods = true;
      try {
        const {data} = await $types.find(null, null, {params: {type: 'SUSCRIPTION_PAYMENT_TYPE'}});
        this.paymentMethods = data;
      } catch (err) {
        this.paymentMethod = [];
        throw err;
      } finally {
        this.loadingPaymentMethods = false;
      }
    },
    async getBillingProducts() {
      this.loadingSearchProducts = true;
      try {
        const {data} = await $products.find();
        this.billingProducts = data.content;
      } catch (err) {
        this.billingProducts = [];
        throw err;
      } finally {
        this.loadingSearchProducts = false;
      }
    },
    async validateCode() {
      this.$v.code.$touch();
      if (this.$v.code.$model) {
        this.codeUnique = null;
        this.loadingCode = true;
        try {
          const {data} = await $suscriptions.find('exists', null, {params: {code: this.$v.code.$model}});
          this.codeUnique = !data.exists;
          this.loadingCode = false;
        } catch (error) {
          this.codeUnique = null;
          throw error;
        } finally {
          this.$v.code.$touch();
        }
      }
    },
    validateSubscription() {
      if (this.$v.$invalid || !this.codeUnique) {
        this.$v.$touch();
        if (!this.codeUnique) {
          this.$refs.code.$el.scrollIntoView({block: 'center', behavior: 'smooth'});
        } else {
          Object.keys(this.$v).some(input => {
            if (input.includes('$')) return false;
            if (this.$v[input].$error) {
              this.$refs[input][0]
                ? this.$refs[input][0].$el.scrollIntoView({block: 'center', behavior: 'smooth'})
                : this.$refs[input].$el.scrollIntoView({block: 'center', behavior: 'smooth'});
              return true;
            }
          });
        }
      } else {
        this.createSubscriptionPopUp()
      }
    },
    createSubscriptionPopUp () {
      if (!this.status) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('alert'),
          content: [{ value: this.$t('disabledMessage') }],
          actionPrimary: { text: this.$t('actions.accept'), callback: () => this.createSubscription() },
          actionSecondary: { text: this.$t('actions.cancel'), callback () { } },
          icon: { color: 'warning', name: 'mdi-alert' },
          color: 'primary',
        });
      } else {
        this.createSubscription();
      }
    },
    async createSubscription() {
      this.createButton.loading = true;
      try {
        const newSubscription = {
          description: this.description.trim(),
          code: this.code,
          status: this.status,
          installments: this.installments,
          payDay: this.payDay,
          adminExpenses: this.adminExpenses,
          suscriptionPaymentType: {
            value: this.paymentMethod
          },
          studentType: {value: this.selectedStudentType},
          suscriptionDetail: this.productsAdded.map(product => {
            return {
              billingProduct: {
                id: product.id
              }
            };
          }),
          userId: this.userId,
        };
        await $suscriptions.create(newSubscription);
        this.successMessage.type = 'success';
        this.successMessage.title = this.$t('modules.pricing.subscription_rules.create.successMessage.title');
        this.successMessage.actionPrimary = {
          text: this.$t('actions.close'), callback: () => {
            this.$router.push('/pricing/subscription-rules');
          }
        };
        this.successMessage.actionSecondary = {
          text: this.$t('actions.create_another'), callback: () => {
            this.createNewSubscriptionStatus = false;
          }
        };
        this.createNewSubscriptionStatus = true;
        this.createButton.success = true;
        this.createButton.loading = false;
        this.clearData();
      } catch (error) {
        this.successMessage.type = 'error';
        this.successMessage.title = (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.pricing.subscription_rules.create.errorMessage.title');
        this.successMessage.actionPrimary = {
          text: this.$t('actions.return'), callback: () => {
            this.createNewSubscriptionStatus = false;
          }
        };
        this.successMessage.actionSecondary = null;
        this.createNewSubscriptionStatus = true;
        this.createButton.loading = false;
        this.createButton.error = true;
        throw error;
      }
    },
    clearData() {
      this.resetButtonValues();
      this.description = null;
      this.code = null;
      this.status = true;
      this.installments = null;
      this.payDay = null;
      this.adminExpenses = null;
      this.paymentMethod = null;
      this.billingProducts.push(...this.productsAdded);
      this.productsAdded = [];
      this.$v.$reset();
    },
    resetButtonValues() {
      this.createButton.loading = false;
      this.createButton.success = false;
      this.createButton.error = false;
    },
  },
  validations: {
    description: {
      required,
      maxLength: maxLength(60),
      alphaNumWithSpaces: helpers.regex('withSpace', /^[a-z0-9]+(?:[a-z0-9]+\s[a-z0-9]*)*$/i)
    },
    code: {
      required,
      alphaNum,
      maxLength: maxLength(10),
      unique() {
        return this.codeUnique != false;
      },
    },
    installments: {
      required,
      between: between(1, 48),
    },
    adminExpenses: {
      required,
      between: between(0, 100),
    },
    payDay: {
      required,
      between: between(1, 30),
    },
    paymentMethod: {required},
    productsAdded: {required},
    selectedStudentType: {required},
  }
};
</script>
