<template>
  <v-item-group active-class="selected" v-model="selectedCardValue">
    <v-container>
      <v-row v-for="(item, index) in subjects" :key="index" cols="12" md="4"><v-col>
          <v-item v-slot="{ toggle }" :value="item.alumnoMateriaId">
            <v-card class="d-flex flex-column pa-4" :disabled="subjectExpired(item)==true || subjectValidity(item)==false" @click="toggle" v-on:click="$emit('cardSelection', { id: item.alumnoMateriaId, attemptsNumber: item.cantIntentos })"
            :style="(subjectExpired(item)==true || subjectValidity(item)==false) ? 'background-color: #ECECEC' : 'background-color: #f5f5f5'">
              <div>
                <div class="font-weight-medium text-h6 py-2 card-subject" :style="(subjectExpired(item)==true || subjectValidity(item)==false) ? 'color: grey;':'color:;'">{{ item.materia }}</div>
                <div v-if="subjectValidity(item)==true && subjectExpired(item)==false" class="text-caption py-2 card-caption">{{ item.estado }} - {{ $t('modules.portalalumno.exams.subjectStep.validityMessage', { date: subjectValidityDate(item) }) }}</div>
                <div v-if="subjectExpired(item)==true" class="text-caption py-2 card-caption red--text">
                  <v-icon color="error" size="20px">mdi-information-outline</v-icon>
                  {{ $t('modules.portalalumno.exams.subjectStep.errorValidityMessage', { date: subjectValidityDate(item) }) }}</div>
                <div v-if="subjectValidity(item)==false && subjectExpired(item)==false" class="text-caption py-2 card-caption red--text">
                  <v-icon color="error" size="20px">mdi-information-outline</v-icon>
                  {{ $t('modules.portalalumno.exams.subjectStep.warningValidityMessage', { date: subjectValidityDate(item) }) }}</div>
                <v-divider></v-divider>
                <div v-if="subjectExpired(item)==false" class="text-caption py-2 card-caption">{{ $t('modules.portalalumno.exams.subjectStep.card.attempts', { attemptsNumber: item.cantIntentos }) }}</div>
                <div v-if="subjectExpired(item)==true" class="text-caption py-2 card-caption">{{ $t('modules.portalalumno.exams.subjectStep.card.noMoreAttempts') }}</div>
              </div>
            </v-card>
          </v-item>
        </v-col>
      </v-row>
    </v-container>
  </v-item-group>
</template>

<script>
import moment from 'moment';

export default {
  name: 'ExamsSubjectCardList',
  props: {
    subjects: Array,
    subjectButton: Object,
    selectedDate: Date,
  },
  data() {
    return {
      selectedCardValue: null,
    };
  },
  watch: {
    selectedCardValue(newValue){
      this.$emit('selectionChanged', !!newValue)
    },
    subjectButton: {
      handler(val) {
        if (val.error && !this.selectedCardValue) this.selectedCardValue = null;
      },
      deep: true
    }
  },
  methods: {
    subjectExpired(subject) {
      const validityDate = this.subjectValidityDate(subject)
      const validity = moment().isAfter(moment(validityDate, 'DD/MM/YYYY'));
      return validity;
    },
    subjectValidity(subject) {
      const validityDate = this.subjectValidityDate(subject)
      const validity = moment(moment(this.selectedDate,'DD/MM/YYYY')).isBefore(moment(validityDate, 'DD/MM/YYYY').add(1, 'day'));
      return validity;
    },
    subjectValidityEndsSoon(subject) {
      const validityDate = this.subjectValidityDate(subject)
      const validity = moment().isBefore(moment(validityDate, 'DD/MM/YYYY')) && moment(moment().add(14, 'days')).isSameOrAfter(moment(validityDate, 'DD/MM/YYYY'));
      return validity;
    },
    subjectValidityDate(subject) {
      const validityDate = moment(subject.dueSubject, 'DD/MM/YYYY').format('DD/MM/YYYY')
      return validityDate;
    }
  }
};
</script>

<style lang="sass" scoped>

.card-caption
  color: #777

.card-subject
  color: #0088cc

.selected
  background-color: #61BCCC !important
  color: white

.selected .card-caption
  color: white

.selected .card-subject
  color: white

</style>

