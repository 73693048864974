<template>
  <v-container fluid class="d-flex pa-0 fullHeight">
    <AsideBar :items="asidebar.items" :title="asidebar.title" @changed="() => false"></AsideBar>
    <v-container fluid class="scrollableContent pa-0">
      <v-container class="container-custom px-6">
        <Breadcrumbs
        :title="$t('modules.extraBenefit.table.breadcrumbs.title')"
          :description="$t('modules.extraBenefit.table.breadcrumbs.description')">
        <Button
          v-if="canCreate"
          @clicked="$router.push('/extra-benefits/create')"
          :icon="'mdi-plus'" :text="$t('actions.new')"
          depressed/>
        </Breadcrumbs>
        <v-row justify="center">
          <v-col sm="12" class="viewSpaces">
            <SuperTable
              :headers="headers"
              :data="extraBenefits"
              :clear-selected="clearSelected"
              :page-count="pagination.pagesAmount"
              :page="pagination.page"
              :total-items="totalItems"
              :deleteTitle="$t('modules.extraBenefit.table.actions.bulkDelete')"
              :permissions="{update: canUpdate, delete: canDelete}"
              :sortBy.sync="sort.sortBy"
              :sortDesc.sync="sort.sortDesc"
              disableCheckboxs
              @searchAction="searchAction"
              @input-pagination="onInputPagination"
              @length-pagination-change="onLengthPaginationChange"
              @updateData="updateExtraBenefit"
              @deleteData="deletePopUp"
              @bulk-delete="bulkDeletePopUp"
              />
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </v-container>
</template>
<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
import Button from '@/components/Button/Button'
import AsideBar from '@/components/AsideBar/AsideBar';
import SuperTable from '../Components/SuperTable.vue'
import { $extraBenefit, $zones, $careers, } from '../Services';

export default {
  name: 'ExtraBenefitTable',
  components: {
    Breadcrumbs,
    Button,
    AsideBar,
    SuperTable
  },
  props: {},
  data() {
    return {
      loading: false,
      search: null,
      page: 1,
      totalItems: null,
      clearSelected: null,
      extraBenefits: [],
      sort: {
        sortBy: 'code',
        sortDesc: false,
      },
      headers: [
        {
          text: this.$t('modules.extraBenefit.table.headers.code'),
          value: 'code'
        }, {
          text: this.$t('modules.extraBenefit.table.headers.description'),
          value: 'description'
        },{
          text: this.$t('modules.extraBenefit.table.headers.percentage'),
          value: 'percentage',
          sortable: false,
        }, {
          text: this.$t('modules.extraBenefit.table.headers.studentType.title'),
          value: 'studentType',
        }, {
          text: this.$t('modules.extraBenefit.table.headers.scholarship'),
          value: 'scholarship',
        }, {
          text: this.$t('modules.extraBenefit.table.headers.average'),
          value: 'average',
        }, {
          text: this.$t('modules.extraBenefit.table.headers.status.title'),
          value: 'status'
        }, {
          text: '',
          value: 'action',
          sortable: false,
        },
      ],
      asidebar: {
        title: 'Refinar búsqueda',
        pagination: {
          limit: 20,
          page: 1,
          currentPage: 1,
          pagesAmount: 1
        },
        items: [
          {
            name: this.$t('modules.extraBenefit.table.headers.zones'),
            filters: null
          },
          {
            name: this.$t('modules.extraBenefit.table.headers.status.title'),
            filters: [
              {
                name: this.$t('modules.extraBenefit.table.headers.status.actives'),
                type: 'check',
                value: true,
                dataFiltered: ''
              },
              {
                name: this.$t('modules.extraBenefit.table.headers.status.inactives'),
                type: 'check',
                value: false,
                dataFiltered: ''
              },
            ]
          },
          {
            name: this.$t('modules.extraBenefit.table.headers.nameCareer'),
            filters: null
          }, {
            name: this.$t('modules.extraBenefit.table.headers.studentType.title'),
            filters: [
              {
                name: this.$t('modules.extraBenefit.table.headers.studentType.ni'),
                type: 'check',
                value: true,
                dataFiltered: ''
              },
              {
                name: this.$t('modules.extraBenefit.table.headers.studentType.ri'),
                type: 'check',
                value: true,
                dataFiltered: ''
              },
            ]
          },

        ]
      },
      pagination: {
        limit: 20,
        page: 1,
        pagesAmount: 1
      },
    };
  },
  computed:{
    canCreate() {
      return this.$permissions.portaladministrativo.prices.extraBenefits.CREATE
    },
    canUpdate() {
      return this.$permissions.portaladministrativo.prices.extraBenefits.UPDATE
    },
    canDelete() {
      return this.$permissions.portaladministrativo.prices.extraBenefits.DELETE
    },
    selectedZones() {
      if (this.asidebar.items[0].filters) {
        const filtered = this.asidebar.items[0].filters.filter(item => item.value == true);
        filtered.forEach(item => item.disabled = false);
        return filtered.map(item => item.id);
      }
      return [];
    },
    selectedStatus() {
      const ret = [];
      if (this.asidebar.items[1].filters) {
        const filtered = this.asidebar.items[1].filters.filter(item => item.value == true);

        if (filtered.length == 1) filtered[0].disabled = true;
        else filtered.forEach(item => item.disabled = false);

        if (this.asidebar.items[1].filters[0].value) ret.push('active');
        if (this.asidebar.items[1].filters[1].value) ret.push('inactive');
      }
      return ret;
    },
    selectedCareers() {
      if (this.asidebar.items[2].filters) {
        const filtered = this.asidebar.items[2].filters.filter(item => item.value == true);
        filtered.forEach(item => item.disabled = false);
        return filtered.map(item => item.id);
      }
      return [];
    },
    selectedStudentType() {
      const ret = [];
      if (this.asidebar.items[3].filters) {
        const filtered = this.asidebar.items[3].filters.filter(item => item.value == true);

        if (filtered.length == 1) filtered[0].disabled = true;
        else filtered.forEach(item => item.disabled = false);

        if (this.asidebar.items[3].filters[0].value) ret.push(7);
        if (this.asidebar.items[3].filters[1].value) ret.push(8);
      }
      return ret;
    },
  },
  mounted() {
    this.fetchData(),
      this.getZones();
    this.getCareers();
  },
  methods: {
    async getZones() {
      const zones = (await $zones.find(null, null, { params: { page: 0, length: 500 } })).data.content;
      zones.sort((a, b) => a.zoneDescription.localeCompare(b.zoneDescription));
      const zonesId = this.$route.query.rol
      this.asidebar.items[0].filters = zones.map(item => ({
        id: item.id,
        name: item.zoneDescription,
        type: 'check',
        value: item.id == zonesId,
        dataFiltered: ''
      }));
    },
    async getCareers() {
      const careers = (await $careers.find(null, null, { params: { page: 0, length: 500 } })).data.content;
      careers.sort((a, b) => a.description.localeCompare(b.description));
      const careerId = this.$route.query.rol
      this.asidebar.items[2].filters = careers.map(item => ({
        id: item.id,
        name: item.description,
        type: 'check',
        value: item.id == careerId,
        dataFiltered: ''
      }));
    },
    async fetchData() {
      if (this.loading) return false
      this.clearSelected = new Date().getMilliseconds().toString()
      this.loading = true
      try {
        const params = {
          page: this.pagination.page - 1,
          length: this.pagination.limit,
          orderBy: this.sort.sortBy,
          orientation: this.sort.sortDesc ? 'desc' : 'asc',
          search: this.search
        };

        const reqBody = {
          studentTypes: this.selectedStudentType,
          status: this.selectedStatus,
          zoneIds: this.selectedZones,
          careerIds: this.selectedCareers,
          search: this.search || ''
        }

        const { data } = await $extraBenefit.findFiltered('list', null, reqBody, { params })

        this.extraBenefits = data.content.map(item => {
          item.percentage = item.details ? item.details.filter(e => e.percentage) : []
          return item
        })
        this.totalItems = data.totalElements
        this.pagination.pagesAmount = data.totalPages
      } catch(error){
        this.extraBenefits = [];
        this.pagination.page = 1
        this.pagination.pagesAmount = 1
        this.totalItems = 0
        throw error
      }finally{
        this.loading = false
      }
    },
    deletePopUp(id) {
      const extraBenefit = this.extraBenefits.find(extraBenefits => extraBenefits.id == id);
      this.$store.dispatch('commons/openPopUp', {
        title: this.$t('delete_popup_title'),
        content: [{value: extraBenefit.description}],
        actionPrimary: {text: this.$t('actions.delete'), callback: () => this.deleteExtraBenefit(id)},
        actionSecondary: {
          text: this.$t('actions.cancel'), callback() {
          }
        },
        icon: {color: 'error', name: 'mdi-delete'},
        color: 'primary',
      });
    },
    async deleteExtraBenefit(id) {
      try {
        await $extraBenefit.delete(id);
        this.extraBenefits = this.extraBenefits.filter(extraBenefit => extraBenefit.id !== id);
      } catch (error) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('actions.error'),
          content: [{value: (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.commissions.ranges.table.messages.deleteError')}],
          actionPrimary: {
            text: this.$t('actions.accept'), callback() {
            }
          },
          icon: {color: 'error', name: 'mdi-close'},
          color: 'primary',
        });
        throw error;
      }
    },
    bulkDeletePopUp(extraBenefits) {
      this.$store.dispatch('commons/openPopUp', {
        title: this.$t('delete_popup_title'),
        content: extraBenefits.map(extraBenefit => ({value: extraBenefit.description})),
        actionPrimary: {text: this.$t('actions.accept'), callback: () => this.bulkDelete(extraBenefits)},
        actionSecondary: {
          text: this.$t('actions.cancel'), callback() {
          }
        },
        icon: {color: 'error', name: 'mdi-delete'},
        color: 'primary',
      });
    },
    async bulkDelete(extraBenefits) {
      const itemsForDelete = {
        ids: extraBenefits.map((extraBenefit) => {
          return extraBenefit.id
        })
      };
      try {
        await $extraBenefit.bulkDelete(itemsForDelete);
        this.fetchData();
      } catch (err) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('actions.error'),
          content: [{value: this.$t('modules.extraBenefit.table.messages.bulkDeleteError')}],
          actionPrimary: {
            text: this.$t('actions.accept'), callback() {
            }
          },
          icon: {color: 'error', name: 'mdi-close'},
          color: 'primary',
        });
        throw err;
      }
    },
    searchAction(data) {
      this.pagination.page = 1;
      this.search = data;
      this.fetchData();
    },
    onInputPagination(event) {
      this.pagination.page = event;
      this.fetchData();
    },
    onLengthPaginationChange(event) {
      this.pagination = {
        page: 1,
        limit: event,
        pagesAmount: 1
      };
      this.fetchData();
    },
    updateExtraBenefit(id) {
      if (this.canUpdate) this.$router.push(`/extra-benefits/update/${id}`);
    },
  },
  watch: {
    sort: {
      handler() {
        this.fetchData();
      },
      deep: true
    },
    selectedZones() {
      this.fetchData();
    },
    selectedCareers() {
      this.fetchData();
    },
    selectedStatus() {
      this.fetchData();
    },
    selectedStudentType() {
      this.fetchData();
    }
  }
}
</script>
<style scoped></style>
