<!-- eslint-disable vue/valid-v-on -->
<template>
  <div>
      <div v-if="banner.type.meaning.toUpperCase() == 'CREADOR DE BANNER MANUAL'" class="banner pa-5" :style="`background: ${banner.color}; backgroundImage: url(${banner.bgImg})`">
          <div class="banner__content d-flex align-center" :class="{'right flex-row-reverse': banner.image.position == 1}">
              <img :src="`${banner.image.src}`" :class="['banner__content__img', {'img-corner': banner.image.corner}]">
              <div class="banner__content__text d-flex flex-column" v-if="banner.text[1]">
                  <div class="banner__content__text__title">{{ banner.text[0] }}</div>
                  <div class="banner__content__text__comment">{{ banner.text[1] }}</div>
              </div>
              <p class="text-center mb-0" v-else>{{ banner.text[0] }}</p>
          </div>
          <div class="d-flex">
              <v-btn class="banner__button primary--text" v-for="button in banner.buttons" :key="button.id" @click="[redirect(path, button, banner.code)]" color="white" rounded>{{ button.text }}</v-btn>
          </div>
      </div>
      <v-img class="gif" v-else :src="`${banner.image.src}`" @click="[redirect(path, banner.buttons[0], banner.code)]"></v-img>
    </div>
</template>

<script>
    export default {
        name: 'GenericBanner',
        props: {
            banner: Object,
        },
        computed: {
            path() {
                return this.banner.buttons[0].link
            }
        },
        methods: {
            redirect(url, button , bannerType) {
                if(button != undefined && bannerType != undefined) {
                    this.trackEvent(button , bannerType);
                }
                
                if (url && url.includes('http')) window.open(url, '_blank')
                else if(url && url.includes('action')){
                  const emit = url.split('-').pop();
                  this.$emit(emit);
                }else this.$router.push(url)
            },
            trackEvent(button , bannerType) {
                if(bannerType.match(/calendario23/gi)){
                    this.$trackEvent('calendario_academico', 'descarga calendario', 'descarga_calendario');
                    return
                } 
                if(bannerType.match(/amigoReferido/g)){
                    this.$trackEvent('amigo_referido', 'amigo referido', 'amigo_referido');
                    return
                }
                if(button.link == '/academic-charge/subjects') {
                    this.$trackEvent('Home', 'Clk_BnrGestAsig_Home', 'Banner_home');
                }
                if(button.link == '/reglamento') {
                    this.$trackEvent('Home', 'Clk_BnrRegla_Home', 'Banner_home');
                }
                const {category, action, label} = button || {}
                if(!category) return
                this.$trackEvent(category, action, label);
            }
        }
    }
</script>

<style lang="sass" scoped>

    .banner
        border-radius: 5px
        background-repeat: no-repeat !important
        background-size: contain !important
        background-position: center !important

        &__content
            p
                width: 70%
                font-size: 14px
                color: #fff
                word-break: break-word
            &__text
                color: #ffffff
                text-shadow: 1.5px 1.5px rgba(0, 0, 0, 0.4)
                word-break: break-word
                &__title
                    font-weight: bold
                    font-size: 19px
                &__comment
                    font-size: 12px
                    line-height: 14px
                    margin-top: 1em
            &__img
                max-width: 50%
                margin-right: 10px
                object-fit: contain

        &__button
            flex: 1
            font-size: 12px
            margin: 1.5em 10px auto

        .img-corner
            margin-top: -20px
            margin-left: -20px
            margin-bottom: -1.5rem !important
            align-self: flex-start

        .right
            > .img-corner
                margin-right: -20px !important
            > .banner__content__img
                margin-left: 10px !important
                margin-right: 0

    .gif
        cursor: pointer

</style>
