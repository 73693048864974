import { render, staticRenderFns } from "./RequirementsPP.vue?vue&type=template&id=7df88915&scoped=true&"
import script from "./RequirementsPP.vue?vue&type=script&lang=js&"
export * from "./RequirementsPP.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7df88915",
  null
  
)

export default component.exports