<template>
  <v-container fluid class="pa-0 pb-8">
    <v-container class="container-custom">
      <Breadcrumbs
        :title="$t('modules.portalalumno.procedures.careerFreeze.title')"
        :description="!createCareerFreeze ? $t('modules.portalalumno.procedures.careerFreeze.description') : ''"
        isDescriptionFullLength
        :callback="goBack"
      ></Breadcrumbs>
      <v-row v-if="!createCareerFreeze" class="pl-12 pt-2 pb-10">
          <v-icon class="pr-2 noteIcon">mdi-alert-circle-outline</v-icon>
          <h5 class="pt-1 noteText" v-text="note"></h5>
      </v-row>
      <v-row justify="center" v-if="!createCareerFreeze">
        <v-col class="viewSpaces" cols="10">
          <OutlinedCard
            :title="$t('modules.portalalumno.procedures.careerFreeze.dataSection.title')"
            :subtitle="$t('modules.portalalumno.procedures.careerFreeze.dataSection.subtitle')"
            class="mb-6">
            <v-row>
              <v-col sm="5">
                <v-autocomplete
                  outlined
                  :label="$t('modules.portalalumno.procedures.careerFreeze.dataSection.reasonType')"
                  v-model="$v.careerFreeze.processingsReasonType.value.$model"
                  :items="reasons"
                  :error="$v.careerFreeze.processingsReasonType.value.$anyError"
                  @blur="$v.careerFreeze.processingsReasonType.value.$touch()"
                  item-text="meaning"
                  hide-details
                ></v-autocomplete>
              </v-col>
              <v-col sm="12" v-if="showField">
                <v-textarea
                  outlined
                  :error="$v.careerFreeze.reason.$anyError"
                  :label="$t('modules.portalalumno.procedures.careerFreeze.dataSection.reason')"
                  :placeholder="$t('modules.portalalumno.procedures.careerFreeze.dataSection.reasonPlaceholder')"
                  v-model.trim="$v.careerFreeze.reason.$model"
                  @blur="$v.careerFreeze.reason.$touch()"
                  required
                  hide-details
                ></v-textarea>
              </v-col>
            </v-row>
          </OutlinedCard>
          <div class="d-flex justify-start mb-12">
            <Button
              white
              :text="$t('actions.cancel')"
              @clicked="goBack"
              @end="resetButtonValues"
              class="mr-4 margin-bot"
            ></Button>
            <Button
              :loading="createButton.loading"
              :success="createButton.success"
              :error="createButton.error"
              :disabled="!$v.careerFreeze.processingsReasonType.value.$anyDirty || $v.careerFreeze.processingsReasonType.value.$anyError || (showField && (!$v.careerFreeze.reason.$anyDirty || $v.careerFreeze.reason.$anyError))"
              :text="$t('actions.apply')"
              :successText="$t('actions.created')"
              :errorText="'Error'"
              @end="resetButtonValues"
              @clicked="confirmFreeze"
            ></Button>
          </div>
          <ModalRulesPopup
            v-if="confirmationPopup"
            :modalOpen="confirmationPopup"
            :title="$t('modules.portalalumno.procedures.careerFreeze.confirmation.title')"
            :subtitle="$t('modules.portalalumno.procedures.careerFreeze.confirmation.subtitle')"
            :rules="[
              $t('modules.portalalumno.procedures.careerFreeze.confirmation.rule_1'),
              $t('modules.portalalumno.procedures.careerFreeze.confirmation.rule_2'),
              $t('modules.portalalumno.procedures.careerFreeze.confirmation.rule_3'),
            ]"
            :warning="$t('modules.portalalumno.procedures.retirement.warning.warning')"
            @cancel="cancelPopup"
            @confirm="create"
          ></ModalRulesPopup>
        </v-col>
      </v-row>
      <SuccessMessage
        v-else
        :type="successMessage.type"
        :title="successMessage.title"
        :actionPrimary="successMessage.actionPrimary"
        :noteSuccess="$t('modules.portalalumno.procedures.alert.noteSuccess')"
      ></SuccessMessage>
    </v-container>
  </v-container>
</template>

<script>
  import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
  import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
  import { required } from 'vuelidate/lib/validators';
  import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
  import Button from '@/components/Button/Button';
  import ModalRulesPopup from '../Components/ModalRulesPopup/ModalRulesPopup';
  import { $studentProcessing, $types } from '../services';
  import { mapGetters } from 'vuex'

  export default {
    name: 'CareerFreeze',
    components: {
      Breadcrumbs,
      OutlinedCard,
      Button,
      SuccessMessage,
      ModalRulesPopup,
    },
    computed: {
      showField() {
        return this.careerFreeze.processingsReasonType.value === 108
      },
      ...mapGetters({
        studentRegister: 'commons/getStudentActualRegister',
        studentId: 'commons/getStudentId',
        studentUserData: 'commons/getStudentUserData',
        userName: 'commons/getUserName',
        studentUserId: 'commons/getStudentUserID',
      }),
    },
    data () {
      return {
        reasons: [],
        confirmationPopup: false,
        note: this.$t('modules.portalalumno.procedures.alert.note'),
        careerFreeze: {
          reason: null,
          processingsReasonType: {
            value: null
          }
        },
        createButton: {
          loading: false,
          success: false,
          error: false
        },
        createCareerFreeze: false,
        successMessage: {
          type: null,
          title: null,
          actionPrimary: null
        },
      }
    },
    mounted(){
      this.getReasonTypes()
    },
    methods: {
      acceptPopup(){
        if(this.$isIPP)this.$trackEvent('Tramites', 'Clk_BtnSolciCongeCread_Tram', 'Retiro definitivo')  //Google Analytic - Acepto freeze SuccessMess
      },
      cancelPopup(){
        this.confirmationPopup = false
        if(this.$isIPP)this.$trackEvent('Tramites', 'Clk_BtnBackConfSolciConge_Tram', 'Retiro definitivo')  //Google Analytic - Cancelo popup freeze
      },
      confirmFreeze(){
        this.confirmationPopup = true
        if(this.$isIPP)this.$trackEvent('Tramites', 'Clk_BtnSolciConge_Tram', 'Retiro definitivo')  //Google Analytic - Solicito Freeze - motivo
      },
      async getReasonTypes() {
        const resp = await $types.find(null, null, {params: { type: 'PROCESSINGS_REASONS' }});
        resp.data.sort((a, b) => (a.meaning > b.meaning) ? 1 : -1)
        this.reasons = resp.data
      },
      resetButtonValues () {
        this.createButton.loading = false;
        this.createButton.success = false;
        this.createButton.error = false;
      },
      goBack () {
        if(this.$isIPP)this.$trackEvent('Tramites', 'Clk_BtnBackSolciConge_Tram', 'Retiro definitivo')  //Google Analytic - Cancelo Freeze - Motivo
        this.$router.push('/procedures')
      },
      async create() {
        this.confirmationPopup = false
        this.createButton.loading = true
        if(this.$isIPP)this.$trackEvent('Tramites', 'Clk_BtnConfSolciConge_Tram', 'Retiro definitivo')  //Google Analytic - Aceptar popup freeze
        try {
          await $studentProcessing.create({...this.careerFreeze, ...{
            student: { id: this.studentId, user: {name: this.studentUserData.email} },
            studentRecord: this.studentRegister,
            processing: { id: 19, key: 'TEMPORARY_DEREGISTRATION'},
            userName: this.userName,
            userUpdateId: this.studentUserId ? this.studentUserId : null
          }})
          this.successMessage.type = 'success';
          this.successMessage.title = this.$t('modules.portalalumno.procedures.success_message.title');
          this.successMessage.actionPrimary = { text: this.$t('modules.portalalumno.procedures.success_message.actions.accept'), callback: () => {this.$router.push('/procedures'); this.acceptPopup() }}
          this.createButton.success = true;
        } catch (error) {
          this.successMessage.type = 'error';
          this.successMessage.title = (error.codeMeaning != '') ? error.codeMeaning : 'error';
          this.successMessage.actionPrimary = { text: this.$t('actions.return'), callback: () => {this.createCareerFreeze = false} }
          this.createButton.error = true;
        } finally {
          this.createCareerFreeze = true
          this.createButton.loading = false;
        }
      },
    },
    validations: {
      careerFreeze: {
        reason: {required},
        processingsReasonType: {
          value: {required},
        }
      }
    },
  }
</script>

<style lang="sass" scoped>
  .noteIcon
    font-size: 20px
    color: v-bind('$vuetify.theme.defaults.light.primary.accent4')
  .noteText
    color: v-bind('$vuetify.theme.defaults.light.primary.accent4')
    font-weight: 410
</style>
