<template>
  <v-container fluid class="d-flex pa-0 fullHeight">
    <v-container fluid class="px-0">
      <div class="px-md-10">
        <div v-if="!coupon.payment">

          <div class="d-flex">
            <v-btn icon class="btn-back" @click="goBack"><v-icon>mdi-chevron-left</v-icon></v-btn>
            <h4 class="d-flex align-center primary--text ml-2" style="flex: 1">
              <span style="flex: 1">{{ $t('modules.portalalumno.payments.paymentsStudents.view.labels.paymentsCash') }}</span>
              <span class="promotions">
                <a v-if="$country !== 'peru'&& $country !== 'mexico'" href="javascript:void(0)" @click="showPromotions = true">{{ $t('modules.portalalumno.payments.paymentsStudents.view.labels.promotionsLink') }}</a>
                <PaymentPromotionsModal v-if="showPromotions" :modalOpen="showPromotions" @close="showPromotions = !showPromotions" />
              </span>
            </h4>
          </div>

          <div class="mt-6 d-flex flex-column">
            <v-progress-circular v-if="loadingPaymentMethods" color="primary" indeterminate class="mx-auto" />
            <div class="pt-0 pl-3 pr-3" v-for="paymentMethod in paymentMethods" :key="paymentMethod.id">
              <PaymentSelection
                @paymentSelected="paymentSelected"
                :payment="paymentMethod"
              ></PaymentSelection>
            </div>
            <div v-if="!loadingPaymentMethods && !paymentMethods.length" class="py-8 px-4 text-center" style="background-color: #efecec; border-radius: 5px; border: solid 1px #d9d9d9">
              <span class="body-1 px-3" v-html="$t('modules.portalalumno.payments.paymentsStudents.view.labels.no_cash_method')"></span>
            </div>
          </div>
        </div>


        <div v-if="coupon.payment">
          <h4 class="d-flex align-center primary--text"> 
            <v-btn icon class="btn-back mr-2" @click="backToCashMethods"><v-icon>mdi-chevron-left</v-icon></v-btn> 
            <p icon class="mr-2 mt-4">{{ $t('modules.portalalumno.payments.paymentsStudents.view.labels.billingGeneric')}} </p>
          </h4>

          <!-- <v-row>
              <v-col cols="12">
                  <p style="margin-right: 20px;">{{ $t('modules.portalalumno.payments.paymentsStudents.view.labels.billingGeneric')}} </p>
              </v-col>
          </v-row> -->

          <div class="mt-6">
            <PaymentBillingDataTeclab
              v-if="$isTeclab && showTeclabBillingData"
              ref="billingData"
              :title="true"
              @update="(data) => { billingData = data }"
              @validate="validateData"
            ></PaymentBillingDataTeclab>
            <PaymentBillingData
              v-if="!$isTeclab"
              ref="billingData"
              :title="false"
              @update="(data) => { billingData = data }"
              @validate="validateData"
            ></PaymentBillingData>
          </div>
        </div>

      </div>
    </v-container>
  </v-container>
</template>

<script>
import PaymentSelection from '../Components/PaymentSelection/PaymentSelection';
import PaymentBillingData from '../Components/PaymentBillingData/PaymentBillingData'
import PaymentBillingDataTeclab from '../Components/PaymentBillingData/PaymentBillingDataTeclab';
import PaymentPromotionsModal from '../Components/PaymentPromotionsModal/PaymentPromotionsModal'
import { $paymentMethod, $payments, $process } from '../Services';
import { mapGetters } from 'vuex'
import commons from '../Mixins/commons';

export default {
  props: {
    resumen: Object,
    selectedMethod: Object,
    summaryButtons: Object,
    coupon: Object,
    isSubscription: {type:Boolean, default: false},
    unselectedReenrollmentsAccountIds: { type: Array, default() { return [] } }
  },
  mixins: [ commons ],
  components: {
    PaymentSelection,
    PaymentBillingData,
    PaymentBillingDataTeclab,
    PaymentPromotionsModal,
  },
  data() {
    return {
      showTeclabBillingData: false,
      loadingPaymentMethods: false,
      showPromotions: false,
      paymentMethods: [],
      billingDataValid: true,
      billingData: null,
      secondAmount: null,
      firstAmount: null,
      percentageSubscription: null,
    };
  },
  computed: {
    ...mapGetters({
      studentRegister: 'commons/getStudentActualRegister',
      studentUserData: 'commons/getStudentUserData',
      studentId: 'commons/getStudentId',
      loggedUsername: 'commons/getUserName',
      $mpConfig: 'commons/getMpConfig'
    }),
    defaultAccount(){
      return this.$mpConfig.find(account => account.default)
    },
    havePaymentMethodSelected(){
      return !!this.coupon?.payment?.id
    },
  },
  mounted() {
    if (this.resumen.total) {
      this.fetchMethods()
    }
  },
  methods: {
    validateData (val){
      if(this.showTeclabBillingData){
        this.billingDataValid = val
      }else{
        this.billingDataValid = true
      }
    },
    backToCashMethods() {
      if (!this.resumen.total) {
        this.$emit('goBackPayment')
      }
      this.coupon.payment = null
      this.billingData = null
      this.billingDataValid = false
    },
    async fetchMethods() {
      try {
        this.loadingPaymentMethods = true
        const response = await $paymentMethod.find(null, null, {params: { payment_type_id: this.selectedMethod.type, accountId: this.defaultAccount?.id, cash_all : this.selectedMethod.cash_all || false }})
        response.data.payment_methods.sort((a, b) => {
          if (a.name === 'PayCash') {
            return -1; 
          } else if (b.name === 'PayCash') {
            return 1; 
          } else if (a.name === 'OXXO') {
            return 1;
          } else if (b.name === 'OXXO') {
            return -1;
          } else {
            return a.name.localeCompare(b.name); 
          }
        });


        this.paymentMethods = response.data.payment_methods
      } finally {
        this.loadingPaymentMethods = false
      }
    },
    async generateCoupon() {
      this.summaryButtons.primary.loading = true

      if(this.showTeclabBillingData){
        await this.$refs.billingData.save()
      }
      const nameArray = !this.$showTeclabBillingData ? ' ' : this.billingData.name.trim().split(' ');
      let desc = '';
      const conceptos = this.resumen.items.subtotal.items;

      conceptos.forEach((concepto,index) => {
          if(index === 0) desc = concepto.title;
          else desc += ` - ${concepto.title}`
      });
    
      this.firstAmount = this.isSubscription ? this.resumen.items.adminExpenses.monto : this.$isTeclab ? this.resumen.total.toFixed(2) : this.resumen.total.toFixed(0)
      this.firstAmount = this.$encrypter(this.firstAmount);
      if(this.secondAmount!==null){this.secondAmount = this.$encrypter(parseFloat(this.secondAmount).toFixed(2));}
      this.percentageSubscription = parseFloat(this.resumen.items.adminExpenses.percentage).toFixed(2)
      if(this.percentageSubscription!==null){this.percentageSubscription = this.$encrypter(this.percentageSubscription)} 
      const paymentData = {
        legajo: this.studentRegister,
        amount: this.firstAmount,
        description: this.isSubscription ? 'GASTO_ADMINISTRATIVO' : desc,
        payment_method_id: this.coupon.payment?.id,
        token: '',
        installments: 1,
        payer_email: this.studentUserData.email,
        pago_una_tarjeta:false,
        issuer_id: null,
        usr_portal: this.loggedUsername,
        discount_intent: this.resumen.paymentIntent,
        intencion_de_pago_id: this.$paymentsIdToArray(this.resumen.items.subtotal.items),
        user_type: this.$isGestor ? 'ADVISOR' : 'STUDENT',
        firstName: nameArray[0],
        lastName: nameArray[nameArray.length - 1],
        secondAmount: this.secondAmount,
        percentage:this.percentageSubscription,
        idsPending: this.unselectedReenrollmentsAccountIds,
        bin: '123456',
        accountId: 1,
        city: !this.$showTeclabBillingData ? '': this.$isTeclab ? this.billingData.city : this.billingData.city.cityState, 
        phone: !this.$showTeclabBillingData ? '' : this.$isTeclab ? this.billingData.phone : '',
        postal_code: !this.$showTeclabBillingData ? '': this.$isTeclab ? this.billingData.zipCode : '',
        state: !this.$showTeclabBillingData ? '' : this.$isTeclab ? this.billingData.state : '',
        address: !this.$showTeclabBillingData ? '' : `${this.billingData.street}-${this.billingData.number}`,
        generic: this.$isMexico && this.showTeclabBillingData ? false : true,
        device_unique_identifier: 0
      }

      try {
        const response = await $payments.create(paymentData)
        const full_response = JSON.parse(response.data.full_response)
        if(response.data?.payment_type_id === 'atm') {
          this.coupon.barcode.type = null
          this.coupon.barcode.codeNumber = null
          this.coupon.number = full_response?.transaction_details?.payment_method_reference_id
          this.coupon.manualOpNumber = full_response?.transaction_details?.payment_method_reference_id
          this.coupon.financial_institution = full_response?.transaction_details?.financial_institution
          this.coupon.dateOfExpiration =full_response.date_of_expiration
          this.coupon.externalResourceUrl =full_response.transaction_details.external_resource_url
        } else {
          this.coupon.barcode.type = full_response.barcode.type === 'Code128C' ? 'Code128' : full_response.barcode.type
          this.coupon.barcode.codeNumber = full_response.barcode.content
          this.coupon.number = full_response.id
          this.coupon.manualOpNumber = full_response.id
        }
        this.coupon.creationDate = new Date().toISOString().substr(0, 10)
        this.summaryButtons.primary.success = true
        if (this.isSubscription) {
          this.$emit('paymentProcessed', { efectivo: 'true' })
        } 
        this.$emit('changeStep', response.data.status);
        
      } catch (error) {
        this.summaryButtons.primary.error = true
        throw error
      } finally {
        this.summaryButtons.primary.loading = false
      }
    },
    async oneCentReceipt() {
      this.summaryButtons.primary.loading = true
      await this.$refs.billingData.save()
      try {
        await $process.create({
          intentId : this.resumen.paymentIntent,
          studentId : this.studentId
        })
        this.coupon.payment = null
        this.$emit('changeStep', 'approved');
      } catch {
        this.coupon.payment = null
        this.$emit('changeStep', 'rejected');
      }
    },
    goBack() {
      this.$emit('goBackPayment')
    },
    paymentSelected (payment) {
      this.coupon.payment = payment;
      if(payment.id == 'pagofacil'){
          this.$trackEvent('Pago', 'Clk_BtnEfecPagFac_Pagos', 'PagoArancel') // Google Analytics Select Pago facil
          this.$emit('functionSelectPayFT', 'pagofacil')
      }else if(payment.id == 'rapipago') {
        this.$trackEvent('Pago', 'Clk_BtnEfecRapPag_Pagos', 'PagoArancel') // Google Analytics Select Rapi Pago
        this.$emit('functionSelectPayFT', 'rapipago')
      }
      this.$forceUpdate()
    },
  },
  watch: {
    havePaymentMethodSelected(value){
      this.summaryButtons.primary.disabled = !value
    },
    billingDataValid(value) {
      if (this.coupon.payment) this.summaryButtons.primary.disabled = !value
    }
  }
};
</script>

<style scoped lang="sass">

  .promotions
    float: right
    font-size: 11px
    line-height: 24px
    vertical-align: middle
    font-weight: bold

</style>
