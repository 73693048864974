<template>
  <div>
    <div v-if="$isMexico">
      <h4 class="d-flex align-center primary--text">
        <v-btn icon class="btn-back mr-2" @click="backToCashMethods">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <p icon class="mr-2 mt-4">
          {{ $t('modules.portalalumno.payments.paymentsStudents.view.labels.billingGeneric') }}
        </p>
      </h4>
    </div>

    <PaymentBillingData v-else
      ref="billingData"
      @update="billingData = $event"
      @validate="billingDataValid = $event">
    </PaymentBillingData>

  </div>
</template>

<script>
import PaymentBillingData from '../Components/PaymentBillingData/PaymentBillingData.vue';
import { $payments } from '../Services';
import { mapGetters } from 'vuex'
import commons from '../Mixins/commons';

export default {
    name: 'PaymentZeroAmount',
    mixins: [commons],
    components: {
        PaymentBillingData
    },
    props: {
        resumen: Object,
        subscription: { type: Object, default: null },
        summaryButtons: Object,
        step: { type: Number, default: null },
            },
    data() {
        return {
            showTeclabBillingData: false,
            billingDataValid: false,
        }
    },
    methods: {
          backToCashMethods() {
            if (!this.resumen.total) {
              this.$emit('goBackPayment')
            }
            this.billingData = null
            this.billingDataValid = false
          },
        async createPayment() {

            try {
                const paymentData = {
                    'legajo': this.studentRegister,
                    'amount': this.$encrypter(parseFloat(this.resumen.total).toFixed(2)),
                    'description': this.subscription ? 'GASTO_ADMINISTRATIVO' : this.description,
                    'payment_method_id': 'zero_amount',
                    'installments': this.selectedInstallment?.installments || 1,
                    'payer_email': this.studentUserData.email,
                    'usr_portal': this.loggedUsername,
                    'discount_intent': this.resumen.paymentIntent,
                    'intencion_de_pago_id': this.$paymentsIdToArray(this.resumen.items.subtotal.items),
                    'user_type': this.$isGestor ? 'ADVISOR' : 'STUDENT',
                    'doc_number': this.studentData.student.user.userData.username,
                    'doc_type': 'RUT',
                    'firstName': this.studentData.student.user.userData.name,
                    'lastName': this.studentData.student.user.userData.lastname,
                    'capture': true,
                    'pago_una_tarjeta': true,
                    'credit_card_fee': 0,
                    'idsPending': [],
                    'accountId': 1,
                    'city': this.billingData?.city.cityState || null ,
                    'phone': this.billingData?.phone || null ,
                    'postal_code': this.billingData?.zipCode || null ,
                    'state': this.billingData?.state || null,
                    'address': `${this.billingData?.street}-${this.billingData?.number}` || null,
                    'device_unique_identifier': 0
                }

                const { data } = await $payments.create(paymentData);

                if (data && data.status === 'approved' ) {
                    this.$emit('changeStep', data, data );
                }
            } catch (error) {
                this.summaryButtons.primary.loading = false
                this.summaryButtons.primary.error = true
                throw error
            }
        }
    },
    computed: {
        description() {
            let description = ''
            this.resumen.items.subtotal.items.forEach((concepto, index) => {
                if (index === 0) description = concepto.title
                else description += ` - ${concepto.title}`
            })
            return description
        },
        ...mapGetters({
            studentRegister: 'commons/getStudentActualRegister',
            studentUserData: 'commons/getStudentUserData',
            studentId: 'commons/getStudentId',
            studentData: 'commons/getStudentData',
            loggedUsername: 'commons/getUserName',
        })
    },
    watch: {
        billingDataValid(value) {
            this.summaryButtons.primary.disabled = !value
        }
    }
}
</script>
