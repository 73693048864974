<template>
  <div>
    <v-container fluid class="d-flex pa-0 fullHeight">
      <v-container fluid class="px-0">
        <div class="px-md-10">
          <template v-if="!$isTeclab && showTeclabBillingData">
            <PaymentBillingData ref="billingData" @update="billingData = $event" @validate="billingDataValid = $event">
            </PaymentBillingData>
          </template>
        </div>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import PaymentBillingData from '../Components/PaymentBillingData/PaymentBillingData'
import { $payments } from '../Services';
import { mapGetters } from 'vuex'
import commons from '../Mixins/commons';


export default {
  name: 'PaymentsFintoc',
  mixins: [commons],
  props: {
    resumen: Object,
    selectedMethod: Object,
    summaryButtons: Object,
    unselectedReenrollmentsAccountIds: { type: Array, default() { return [] } }
  },
  components: {
    PaymentBillingData
  },
  data() {
    return {
      showTeclabBillingData: true,
      billingDataValid: false,
      billingData: null,
      secondAmount: null,
      firstAmount: null,
    };
  },
  computed: {
    ...mapGetters({
      studentRegister: 'commons/getStudentActualRegister',
      studentUserData: 'commons/getStudentUserData',
      loggedUsername: 'commons/getUserName',
    }),
  },
  mounted() {
    this.showTeclabBillingData = this.$country == 'mexico' ? false : true;
    if (!this.showTeclabBillingData) {
      this.summaryButtons.primary.disabled = false
    }
  },
  methods: {
    updateSummaryButton() {
      if (this.showTeclabBillingData) {
        this.billingDataValid = true;
      } else {
        this.billingDataValid = false;
      }
    },
    async redirectPay() {
      this.summaryButtons.primary.loading = true
      let nameArray = []
      if (this.showTeclabBillingData) {
        await this.$refs.billingData.save()
        nameArray = this.billingData.name.split(' ');
      }

      let desc = '';
      const conceptos = this.resumen.items.subtotal.items;

      conceptos.forEach((concepto, index) => {
        if (index === 0) desc = concepto.title;
        else desc += ` - ${concepto.title}`
      });

      this.firstAmount = this.$isTeclab ? this.resumen.total.toFixed(2) : this.resumen.total.toFixed(0)
      this.firstAmount = this.$encrypter(this.firstAmount);

      if (this.secondAmount !== null) { this.secondAmount = this.$encrypter(parseFloat(this.secondAmount).toFixed(2)); }

      const paymentData = {
        legajo: this.studentRegister,
        amount: this.firstAmount,
        description: this.isSubscription ? 'GASTO_ADMINISTRATIVO' : desc,
        payment_method_id: 'fintoc',
        token: '',
        installments: 1,
        payer_email: this.studentUserData.email,
        pago_una_tarjeta: false,
        issuer_id: null,
        usr_portal: this.loggedUsername,
        discount_intent: this.resumen.paymentIntent,
        idsPending: this.unselectedReenrollmentsAccountIds,
        intencion_de_pago_id: this.$paymentsIdToArray(this.resumen.items.subtotal.items),
        user_type: this.$isGestor ? 'ADVISOR' : 'STUDENT',
        firstName: Array.isArray(nameArray) && nameArray.length > 0 ? nameArray[0] : ' ',
        lastName: Array.isArray(nameArray) && nameArray.length > 0 ? nameArray[nameArray.length - 1] : ' ',
        secondAmount: this.secondAmount,
        percentage: this.percentageSubscription,
        bin: '123456',
        accountId: 1,
        city: !this.$showTeclabBillingData ? '' : this.$isTeclab ? this.billingData.city : this.billingData.city.cityState,
        phone: !this.$showTeclabBillingData ? '' : this.$isTeclab ? this.billingData.phone : '',
        postal_code: !this.$showTeclabBillingData ? '' : this.$isTeclab ? this.billingData.zipCode : '',
        state: !this.$showTeclabBillingData ? '' : this.$isTeclab ? this.billingData.state : '',
        address: !this.$showTeclabBillingData ? '' : `${this.billingData.street}-${this.billingData.number}`,
        generic: this.$isMexico && this.showTeclabBillingData ? false : true,
        device_unique_identifier: 0
      }

      try {
        const response = await $payments.create(paymentData)
        const win = window.open(response.data, '_blank');
        win.focus();
        this.summaryButtons.primary.success = true
        this.$emit('changeStep', 'debitOk');
      } catch (error) {
        this.summaryButtons.primary.error = true
        this.$emit('changeStep', 'debitError');
        throw error
      } finally {
        this.summaryButtons.primary.loading = false
      }
    },
  },
  watch: {
    billingDataValid(value) {
      if (this.showTeclabBillingData) {
        this.summaryButtons.primary.disabled = !value
      } else {
        this.summaryButtons.primary.disabled = value
      }
    },
  }
};
</script>
