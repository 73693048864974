<template>
  <div>
    <div border-0 permanent width="100%" height="100%" style="flex: 1">
      <v-divider class="mx-5"></v-divider>
      <div class="px-3 py-2">
        <div style="font-family: 'DM Sans'; font-weight: 500;">
          <v-alert outlined color="primary lighten-2">
            <div>{{ $t('modules.portalalumno.payments.paymentsStudents.view.contract.subtitle') }}</div>
          </v-alert>
        </div>

        <div class="d-flex justify-center align-center">
          <v-checkbox @change="checkAcceptTerms"></v-checkbox>
          <template>
            <span>
              {{ $t('modules.portalalumno.payments.paymentsStudents.view.contract.terms') }}
              <a @click="openPopup" style="cursor: pointer; text-decoration:underline; margin-left:0.3rem ">
                {{ $t('modules.portalalumno.payments.paymentsStudents.view.contract.seeContract') }}
              </a>
            </span>
          </template>
        </div>
      </div>
    </div>

    <v-dialog v-model="popupVisible" max-width="dynamicMaxWidth" scrollable transition="">
      <v-card :style="{ height: '90vh' }">
        <div class="headline d-flex justify-space-between flex-wrap px-4 pt-2" style="white-space: normal">
          <v-row align="center" class="d-flex justify-space-between w-100">
            <v-col cols="10" sm="11" class="d-flex align-center">
              <span>{{ $t('modules.portalalumno.payments.paymentsStudents.view.contract.preview') }}</span>
            </v-col>
            <v-col cols="2" sm="1" class="d-flex justify-end">
              <v-icon @click="popupVisible = false" color="secondary" style="cursor: pointer;">
                mdi-close
              </v-icon>
            </v-col>
          </v-row>
        </div>

        <v-card-text style="max-height: 75vh; overflow-y: auto;">
          <DocumentPreview :title="'Contrato'" :src="pdfSource" />
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn  @click="popupVisible = false" rounded dense outlined class="my-5 primary white--text px-4 py-5" >
            {{ $t('modules.portalalumno.payments.paymentsStudents.view.contract.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DocumentPreview from '@/components/DocumentPreview/DocumentPreview';
import { $studentContract } from '../../Services/index';
import { mapGetters } from 'vuex'

export default {
  components: {
    DocumentPreview,
  },
  props: {
    resumen: Object,
    intentodepagoid: {type: Number, default:null},
  },
  data() {
    return {
      popupVisible: false,
      pdfSource: '',
      loading: false,
      error: false,


    };
  },
  computed: {
    ...mapGetters({
      studentRegister: 'commons/getStudentActualRegister',
    }),
  },
  methods: {
    openPopup() {
      this.popupVisible = true;
      this.fetchContract();
    },

    async fetchContract() {
      try {
        this.loading = true;
        const paymentIntent = this.intentodepagoid || this.resumen.paymentIntent
        const response = await $studentContract.findById(this.studentRegister, 'generate', { params: { intent: paymentIntent }, });
        this.pdfSource = response.data;
        this.loading = false;
      } catch (err) {
        this.loading = true;
      }
    },

    checkAcceptTerms(value) {
      this.$store.dispatch('commons/updateCheckTerms', value);
    },
  },
};
</script>
