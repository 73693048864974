import Settings from '../Settings'
import SettingsGeneral from '../Views/SettingsGeneral'
import SettingsEmail from '../Views/SettingsEmail'
import SettingsSms from '../Views/SettingsSms'
import SettingsAppearance from '../Views/SettingsAppearance';
import SettingsPrivacyPolicy from '../Views/SettingsPrivacyPolicy';
import SettingsTerms from '../Views/SettingsTerms';
import SettingsLiquidations from '../Views/SettingsLiquidations';
import SettingsBilling from '../Views/SettingsBilling.vue';
import SettingsStudent from '../Views/SettingsStudent.vue';
import SettingsStudentUpdate from '../Views/SettingsStudentUpdate.vue';
import SettingStudentView from '../Views/SettingStudentView.vue';
import SettingsPricing from '../Views/SettingsPricing.vue';

const SettingsRoute =
{
  path: '/settings',
  meta: {
    name: {
      es: 'Configuración del sistema',
      en: 'Settings',
    }
  },
  component: Settings,
  children: [
    {
      path: '/',
      component: SettingsGeneral,
      meta: { name: 'Index', group:'settings', path: 'system', permission: 'READ' }
    },
    {
      path: 'appearance',
      component: SettingsAppearance,
      meta: { name: 'Appearance', group:'settings', path: 'system', permission: 'READ' }
    },
    {
      path: 'email',
      component: SettingsEmail,
      meta: { name: 'Email', group:'settings', path: 'communication', permission: 'READ' }
    },
    {
      path: 'sms',
      component: SettingsSms,
      meta: { name: 'Sms', group:'settings', path: 'communication', permission: 'READ' }
    },
    {
      path: 'privacy',
      component: SettingsPrivacyPolicy,
      meta: { name: 'Privacy', group:'settings' ,path: 'legales', permission: 'READ' }
    },
    {
      path: 'terms',
      component: SettingsTerms,
      meta: { name: 'Terms', group:'settings' ,path: 'legales', permission: 'READ' }
    },
    {
      path: 'liquidations',
      component: SettingsLiquidations,
      meta: { name: 'Liquidations', group:'settings', path: 'billing', permission: 'READ' }
    },
    {
      path: 'billings',
      component: SettingsBilling,
      meta: { name: 'Billings', group:'settings', path: 'billing', permission: 'READ' }
    },
    {
      path: 'pricing',
      component: SettingsPricing,
        meta: { name: 'Pricing', group:'settings', path: 'pricing' }
    },

    {
      component: SettingStudentView,
      path: 'students',
      meta: {
        name: {
          es: 'Estudiantes',
          en: 'Students',
        }
      },
      children: [
        {
          path: '',
          component: SettingsStudent,
          meta: { name: 'Student', group: 'settings', path: 'students', permissions: 'READ' }
        },
        {
          path: 'update/:id',
          component: SettingsStudentUpdate,
          meta: { name: 'StudentUpdate', group: 'settings', path: 'students', permissions: 'UPDATE' }
        },
      ]
    }
  ]
};

export default SettingsRoute
