import Constants from '@/plugins/constants'
import userService from '@/plugins/user.service'
import app from '@/main'
import jwt from 'jsonwebtoken';


export default {

  // common actions

  async initData ({ state, commit, dispatch }) {
    try {
      const userData = await userService.getUserData();
      userData.userData.avatar = `${Constants.URL_DATA_SERVER}/users/${userData.id}/avatar`;

      await commit('SET_USER_PERMISSIONS', userData);
      commit('SET_HAS_EXPIRED', JSON.parse(localStorage.getItem('hasExpired')))
      const userId = JSON.parse(localStorage.getItem('user_id'))
      const student = JSON.parse(localStorage.getItem('alumnoSeleccionado'))
      state.isGestor = jwt.decode(JSON.parse(localStorage.getItem('access_token'))).isAdvisor;

      if (state.isGestor && student){
        commit('SET_USERDATA', student);
        dispatch('setContractPendingValidation', student.contractPendingValidation)
      }
      else if (!state.isGestor) {
        const studentData = await userService.getStudentData();
        dispatch('setContractPendingValidation', studentData.contractPendingValidation)

        studentData.userData = studentData.student.user.userData;
        studentData.userData.username = studentData.student.user.name;
        studentData.userData.userId = userId;
        studentData.userData.avatar = `${Constants.URL_DATA_SERVER}/users/${studentData.userData.userId}/avatar`;
        try {
          const regulationUserData = await userService.regulationUser(studentData.student.id, 'GENERAL');
          studentData.regulationAccepted = regulationUserData.accepted
        } catch{
          studentData.regulationAccepted = true
        }
        commit('SET_USERDATA', studentData);
        window.localStorage.setItem('alumnoSeleccionado', JSON.stringify(studentData));
          try {
            const regulationUserData = await userService.regulationUser(studentData.student.id, 'GENERAL');
            studentData.regulationAccepted = regulationUserData.accepted
          } catch{
            studentData.regulationAccepted = true
          }
          try {
            const modulesblocked = await userService.modulesBlocked(studentData.student.id);
            studentData.modulesblocked = modulesblocked
          } catch{
            studentData.modulesblocked = []
          }
      }

      commit('SET_LOGGED_USERDATA', userData);
    } catch (error) {
      app.$router.replace('/logout');
    }
  },
  async setStudentData({ commit , dispatch }, userName) {
    if (userName) {
      try {
        commit('INCREASE_LOADING')
        const userId = JSON.parse(localStorage.getItem('user_id'))
        const userData = await userService.getStudentData(userName);
        dispatch('setContractPendingValidation', userData.contractPendingValidation)

        userData.userData = userData.student.user.userData;
        userData.userData.username = userData.student.user.name;
        userData.userData.userId = userId;
        userData.userData.avatar = `${Constants.URL_DATA_SERVER}/users/${userData.userData.userId}/avatar`;
        window.localStorage.setItem('alumnoSeleccionado', JSON.stringify(userData));
        commit('SET_USERDATA', userData);
      } catch (error) {
        window.localStorage.setItem('alumnoSeleccionado', null);
        commit('SET_USERDATA', null);
        throw error
      } finally {
        commit('DECREASE_LOADING')
      }
    } else {
      window.localStorage.setItem('alumnoSeleccionado', null);
      commit('SET_USERDATA', null);
    }
  },
  setMpConfig({ commit }, data) {
    commit('SET_MP_CONFIG', data)
  },
  setStudentInProgressSubjects({ commit }, subjects) {
    commit('SET_INPROGRESS_SUBJECTS', subjects)
  },
  setStudentRegularisedSubjects({ commit }, subjects) {
    commit('SET_REGULARISED_SUBJECTS', subjects)
  },
  setStudentApprovedSubjects({ commit }, subjects) {
    commit('SET_APPROVED_SUBJECTS', subjects)
  },
  setUserData ({ commit }, userData) {
    if (userData.userData) userData.userData.avatar = `${Constants.URL_DATA_SERVER}/users/${userData.id}/avatar`
    commit('SET_USERDATA', userData)
  },
  closePopUp ({ commit }) {
    commit('CLOSE_POPUP');
  },
  openPopUp ({ commit }, data) {
    commit('OPEN_POPUP', data);
  },
  closePasswordPopUp ({ commit }) {
    commit('CLOSE_PASSWORD_POPUP');
  },
  openPasswordPopUp ({ commit }) {
    commit('OPEN_PASSWORD_POPUP');
  },
  updateCheckTerms({ commit }, data) {
    commit('UPDATE_CHECK_TERMS', data);
  },
  setHasExpired ({ commit }, data) {
    window.localStorage.setItem('hasExpired', JSON.stringify(data));
    commit('SET_HAS_EXPIRED', data);
  },
  refreshNotifications({ commit }) {
    commit('REFRESH_NOTIFICATIONS');
  },
  updateAvatar ({ commit }) {
    commit('UPDATE_AVATAR');
  },
  clearUserData ({ commit }) {
    commit('CLEAR_USERDATA');
  },

  setLoadingDrawer ({commit}, data) {
    commit('SET_LOADING_DRAWER', data)
  },
  increaseLoading({commit}) {
    commit('INCREASE_LOADING')
  },
  decreaseLoading({commit}) {
    commit('DECREASE_LOADING')
  },


  updateCurrentUserRoles ({ commit, state }, data) {
    // Validate user have the modified role.
    state.loggedUserData.roles.forEach((role, i) => {
      if (role.id === data.id) {
        data.roleToDelete = i;
        commit('UPDATE_CURRENT_USER_ROLES', data);
      }
    })
  },

  userPermissions({state}) {
    return state.commons.permissions
  },
  setContractPendingValidation({ commit }, payload){
    commit('SET_CONTRACT_PENDING_VALIDATION', payload)
  }
}
