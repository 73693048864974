<template>
  <v-container class="container-custom">
    <div v-if="loading.route" class="d-flex align-center justify-center">
      <v-progress-circular indeterminate color="primary" />
    </div>
    <div v-else>
      <v-row v-if="studentUserData">
        <v-col cols="9">
          <TitleComponent id="title-component"
            :title="$t('modules.portalalumno.admission.title', { name: studentUserData.name })"
            :descriptionBold="$t('modules.portalalumno.admission.subtitle')" />
        </v-col>
        <v-col cols="3" class="d-flex justify-end align-center">
          <v-btn :disabled="loading.identity" @click="validateIdentity" color="primary" rounded large>
            {{ $t('modules.portalalumno.admission.contract.validate') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-card min-height="300" class="d-flex align-center justify-center">
        <DocumentPreview v-if="pdfSource && !loading.contract" class="flex-grow-1" title="'Contrato'"
          :src="pdfSource" />
        <v-progress-circular v-else indeterminate color="primary" />
      </v-card>
    </div>
  </v-container>
</template>
<script>
import TitleComponent from '@/components/TitleComponent/TitleComponent'
import DocumentPreview from '@/components/DocumentPreview/DocumentPreview'
import { mapGetters } from 'vuex'
import { $students, $studentContractPendingValidation } from '../services'
export default {
  name: 'AdmissionStatus',
  components: {
    TitleComponent,
    DocumentPreview
  },
  data() {
    return {
      pdfSource: null,
      loading: {
        route: true,
        contract: false,
        identity: false
      },
      loadingRoute: true,
      error: false,
    }
  },
  computed: {
    ...mapGetters({
      studentUserData: 'commons/getStudentUserData',
      studentRegister: 'commons/getStudentActualRegister',
      haveContractPendingValidation: 'commons/getContractPendingValidation'
    })
  },
  methods: {
    async validateIdentity() {
      this.loading.identity = true
      try {
        const { data: { url, token } } = await $students.signContract('ecert/validateUserData', {
          rut: this.studentUserData.identification,
          legajo: this.studentRegister
        })
        if (!(url && token)) throw new Error(this.$t('modules.portalalumno.admission.validateIdentity.error.title'))
        window.open(`https://serviciovalidacionremota.ecertchile.cl/${url}?token=${token}`, '_blank')
      } catch (error) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.portalalumno.admission.validateIdentity.error.titleEcert'),
          actionPrimary: { text: this.$t('actions.close'), callback() {} },
          icon: { color: 'error', name: 'mdi-close' },
          color: 'primary',
        })
      } finally {
        this.loading.identity = false
      }
    },
    async fetchContract() {
      try {
        this.loading.contract = true
        $studentContractPendingValidation.findById(this.studentRegister)
          .then(({ data }) => this.pdfSource = data)
      } catch {
        this.error = true
      } finally {
        this.loading.contract = false
      }
    },
  },
  mounted() {
    if (this.haveContractPendingValidation) {
      this.fetchContract().finally(() => this.loading.route = false)
    }
    else this.$router.replace('/dashboard')
  }
}
</script>
<style>
#title-component:first-child .mx-auto {
  margin-left: 0 !important;
}
</style>
