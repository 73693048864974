<template>
  <v-container fluid class="pa-0 pb-8">
    <v-container class="container-custom">
      <Breadcrumbs
      :title="$t('modules.portalalumno.procedures.careerChange.title')"
      :callback="goBack"
      ></Breadcrumbs>
      <v-row justify="center">
        <v-col class="viewSpaces" cols="12">

          <CareerSelection
          v-if="step == 1"
          :careerChange="careerChange"
          @goBack="goBack"
          @nextStep="step++"
          ></CareerSelection>

          <SubjectsHomologation
            v-else-if="step == 2"
            :careerChange="careerChange"
            @goBack="step--; careerChange.price = null"
            @nextStep="step++"
          ></SubjectsHomologation>

          <ContractSign
            v-else-if="$isMexico && step == 3"
            :careerChange="careerChange"
            @goBack="step--"
            @nextStep="step++"
          ></ContractSign>

          <SuccessMessage
            v-else-if="!systemConfig.contract || (!$isMexico && step == 3) || (!$isTeclab && step == 4)"
            type="success"
            :title="$t('modules.portalalumno.procedures.careerChangeStep4.dataSection.header')"
            :subtitle="$t('modules.portalalumno.procedures.careerChangeStep4.dataSection.description')"
            :actionPrimary="{ text: $t('actions.accept'), callback: () => {this.$router.push('/procedures')} }"
            :noteSuccess="$t('modules.portalalumno.procedures.alert.noteSuccess')"
            />
          <v-col cols="6">
          <div class="margin-bot">
            <h5>{{ $t(`modules.portalalumno.procedures.procedureScholarship.OutlineCard.information.title`) }}</h5>
            <Button
              class="mt-4 "
              :text="$t(`modules.portalalumno.procedures.procedureScholarship.OutlineCard.information.buttonText`)"
              @clicked="$router.push('/reglamento/general')"
              outlined
              size="sm"
            ></Button>
          </div>
        </v-col>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
  import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
  import CareerSelection from './CareerSelection'
  import SubjectsHomologation from './SubjectsHomologation'
  import ContractSign from './ContractSign'
  import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
  import Button from '@/components/Button/Button';


  export default {
    name: 'CareerChange',
    components: {
      Breadcrumbs,
      CareerSelection,
      SubjectsHomologation,
      ContractSign,
      SuccessMessage,
      Button,
    },
    data () {
      return {
        step: 1,
        careerChange: {
          reason: null,
          career: null,
          studyPlan: null,
          processingsReasonType: null,
          price: null,
        },
      }
    },
    computed: {
      canCreate() {
        return this.$permissions.portalalumno.procedures.CAREER_CHANGE
      },
    },
    methods: {
      goBack() {
        this.$trackEvent('Tramites', 'Cancelar', 'Cambio de carrera')
        this.$router.push('/procedures')
      },
    },
  }
</script>
