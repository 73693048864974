
const sections = {
    title: 'Secciones',
    description: 'Este reporte muestra todas las secciones activas, con cantidad de inscriptos a ellas, su cupo máximo y los datos del tutor, profesor y corrector de cada una.',
    detail: [
        { label: 'Archivo', value: 'Reporte genereteSectionsReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'El estado de la asignatura debe ser "En curso" o la cantidad de inscriptos debe ser 0.' },
        { label: 'Criterios', value: 'Muestra las secciones para el período seleccionado.' },
    ],
    example: {
        header: [
            'NOMBRE',
            'PERIODO',
            'CUPO',
            'ESTADO',
            'R19',
            'INSCRIPTOS',
            'NOMBRE PROFESOR',
            'APELLIDO PROFESOR',
            'RUT PROFESOR',
            'MAIL PROFESOR',
            'NOMBRE TUTOR',
            'APELLIDO TUTOR',
            'RUT TUTOR',
            'MAIL TUTOR',
            'NOMBRE CORRECTOR',
            'APELLIDO CORRECTOR',
            'RUT CORRECTOR',
            'MAIL CORRECTOR',
        ],
        data: [
            [
                '1234A - NOMBRE PRUEBA',
                '1A/2000',
                '140',
                'true',
                'false',
                '90',
                'ALUMNO',
                'PRUEBA',
                '12345678',
                'prueba@gmail.com',
                'TUTOR',
                'PRUEBA',
                '12345678',
                'tutor@academia.com',
                'CORRECTOR',
                'PRUEBA',
                '12345678',
                'corrector@gmail.com',
            ],
            [
                '1234A - NOMBRE PROBANDO',
                '1A/2222',
                '100',
                'true',
                'false',
                '10',
                'ALUMNO',
                'PROBANDO',
                '99999999',
                'probando@gmail.com',
                'TUTOR',
                'PROBANDO',
                '99999999',
                'tutor@academia.com',
                'CORRECTOR',
                'PROBANDO',
                '99999999',
                'corrector@gmail.com',
            ],
        ],
    },
}

const mesh = {
    title: 'Malla',
    description: 'Este reporte muestra todas las materias de todas las carreras con el plan de estudio, el semestre, las horas y los créditos',
    detail: [
        { label: 'Archivo', value: 'Reporte genereteMeshReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip argentina">Argentina</span> <span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'Muestra todas las carreras, materias y planes de estudio creados.' },
        { label: 'Criterios', value: 'Los semestres van del 1 al 17 y los semestres C se muestran como X.' },
    ],
    example: {
        header: [
            'Código Carrera',
            'Nombre Carrera',
            'Código Plan',
            'Plan de Estudio',
            'Estado Plan',
            'Código Materia',
            'Nombre Materia',
            'Orden Asignatura',
            'Nivel Asignatura',
            'Semestre Asignatura',
            'Período Asignatura',
            'Horas Asignatura',
            'Créditos Asignatura',
        ],
        data: [
            [
                'ABDC',
                'Nombre prueba',
                '2000',
                'PLAN 2000',
                'Habilitado',
                'ABDC',
                'NOMBRE PRUEBA',
                '5',
                '6',
                '3',
                'B',
                '120',
                '120',
            ],
            [
                'CDBA',
                'Nombre Probando',
                '2222',
                'PLAN 2222',
                'Deshabilitado',
                'CDBA',
                'NOMBRE PROBANDO',
                '4',
                '3',
                '2',
                'A',
                '100',
                '100',
            ],
        ],
    },
}

// documentations[this.reports.service][this.reports.url]


export default { sections, mesh };
