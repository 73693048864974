const admissionTranslations = {
  es_CL: {
    title: '{name} ¡Ya casi! ',
    subtitle: 'Para finalizar el proceso de Admisión es necesario validar tu identidad, haciendo click en el botón:',
    documentationInfoCard:{
      title:'Documentación obligatoria',
      documentation:{
        rut: 'Frente y dorso de tu cédula',
        lem:'Licencia de Enseñanza Media (LEM)'
      },
      action:{
        text:'Subir documentación'
      },
      status:{
        pending:'Primero la documentación debe ser aceptada.',
        rejected:'Intentar Nuevamente',
        perceived:'Primero debes cargar la documentación obligatoria',
        approved:'Confirmar inscripción',
        signContract:'Firmar contrato'
      }
    },
    contractInfoCard:{
      documentation:'Revisa el documento y firma.',
      title:'Contrato de Servicios'
    },
    inscriptionInfoCard:{
      title:'Inscripción'
    },
    validateIdentity:{
      error:{
        title:'Ha ocurrido un error en el proceso, no se logró validar la identidad del usuario',
        titleEcert:'Ecert no logró validar la identidad del usuario'
      }
    }
},
  es_PE: {
      ecert: {
          approved:{
              description:'Ha Finalizado con éxito al proceso de Matrícula. Ya eres estudiante de IPP',
          },
          rejected:{
              title:'Ocurrio un error.',
              description:'Ha ocurrido un error en el proceso, favor comunícate con tu asesor para que pueda ayudarte'
          }
      },
  },
  es: {
    alert: {
      note: 'Una vez solicitado, podrás revisar el estado de tu trámite en 7 días hábiles.',
      noteDoc: 'Asegúrate de cargar correctamente toda la documentación necesaria. En caso contrario, podría haber demoras en el proceso de trámites hasta que verifiquemos su validez.',
      noteSuccess: 'Vuelve a revisar el estado de tu trámite en 7 días hábiles.'
    },
    title: '¡Hola, {name}!',
    subtitle: 'Para continuar con la admisión necesitamos lo siguiente:',
    breadcrumbs: {
      title: 'Trámites',
    },
    documentation: {
      title: 'Documentación obligatoria',
      cardTitle1: 'Cédula de identidad',
      cardSubtitle1: 'Frente y dorso de tu cédula',
      cardTitle2: 'Licencia de Enseñanza Media',
      cardSubtitle2: 'Frente y dorso de tu licencia',
      cardTitle3: 'Documentación obligatoria de becas',
      cardSubtitle3: 'Frente y dorso de tu',
      cardTitle4: 'Documentación obligatoria de beneficios',
      cardSubtitle4: 'Frente y dorso de tu',
      sendDocumentation: 'Enviar Documentación',
      help: '¿Tienes dudas sobre la documentación a presentar?',
      rules: 'Ver reglamento',
      status: 'Estado:',
      observation: 'Observación:',
      requiredDocumentation: 'Documentación obligatoria',
      requiredFields: 'Los documentos marcados con un * son obligatorios',
      scholarshipDocumentation: 'Documentación relacionada a tu beca',
      benefitDocumentation: 'Documentación relacionada a tu beneficio',
      revisedDocumentation: 'Documentación revisada:',
      replaceDocumentation: 'Debes reemplazar {n} documentos',
    },
    careerChangeStep3: {
      dataSection: {
        header: 'Contrato de Servicios Educacionales'
      }
    },
    contract: {
      title: 'Contrato de Servicios Educacionales',
      subtitle: 'Lee el documento y firma a continuación. Ten en cuenta que se abrirá en una pestaña nueva para continuar el proceso.',
      sign: 'Firmar contrato',
      validate: 'Validar identidad'
    },
    ecert: {
       approved:{
        title:'¡BIENVENIDO!',
        description:'Ha Finalizado con éxito al proceso de Matrícula. Ya eres alumno de IPP',
      },
      rejected:{
        title:'Ocurrio un error.',
        description:'Ha ocurrido un error en el proceso, favor comunícate con tu asesor para que pueda ayudarte'
      }
    }
  },
  en: {

  }
}

export default admissionTranslations;
