const subscriptionRulesTranslations = {
  es: {
    table: {
      breadcrumbs: {
        title: 'Reglas de suscripción',
        description:'Administración de reglas de suscripción',
      },
      headers: {
        description: 'Nombre',
        code: 'Código',
        status: 'Estado',
        adminExpenses: 'Gastos de administración',
        installments: 'Cuotas',
        payDay: 'Día de Pago',
        paymentMethod: 'Método de Pago',
        studentType: 'Tipo de Estudiante'
      },
      actions: {
        errorDelete: 'Error al eliminar',
      },
      messages: {
        deleteError: 'No se pudo eliminar la regla de suscripción seleccionado',
      }
    },
    create: {
      breadcrumbs: {
        title: 'Nueva regla de suscripción',
        description:'Crea una nueva regla de suscripción',
      },
      codeSection: {
        title: 'Datos de la regla de suscripción',
        subtitle: 'Asigna la información básica de la regla de suscripción que estás creando. Recuerda que estos datos deben ser lo más descriptivos posible ya que serán utilizados para futuros filtros de búsqueda.',
        labels: {
          name: 'Nombre',
          code: 'Código',
          installments: 'Cuotas',
          adminExpenses: 'Gastos de administración',
          payDay: 'Día de Pago',
          paymentMethod: 'Método de Pago'
        },
      },
      billingProductSection: {
        title: 'Producto de facturación',
        subtitle: 'Selecciona en esta sección los productos de facturación donde esta regla de suscripción puede ser aplicado',
        transferList: {
          firstTitle: 'Disponibles',
          secondTitle: 'Seleccionados'
        }
      },
      successMessage: {
        title: 'Regla de suscripción creada correctamente',
      },
      errorMessage: {
        title: 'Ocurrió un error al crear la regla de suscripción',
      }
    },
    update: {
      breadcrumbs: {
        title: 'Editar regla de suscripción',
        description:'Modifica la regla de suscripción seleccionado',
      },
      codeSection: {
        title: 'Datos de la regla de suscripción',
        subtitle: 'Modifica la información básica de la regla de suscripción que estás modificando. Recuerda que estos datos deben ser lo más descriptivos posible ya que serán utilizados para futuros filtros de búsqueda.',
        labels: {
          name: 'Nombre',
          code: 'Código',
          installments: 'Cuotas',
          adminExpenses: 'Gastos de administración',
          payDay: 'Día de Pago',
          paymentMethod: 'Método de Pago',
        },
      },
      billingProductSection: {
        title: 'Producto de facturación',
        subtitle: 'Selecciona en esta sección los productos de facturación donde esta regla de suscripción puede ser aplicado',
        transferList: {
          firstTitle: 'Disponibles',
          secondTitle: 'Seleccionados'
        }
      },
      successMessage: {
        title: 'Regla de suscripción modificada correctamente',
      },
      errorMessage: {
        title: 'Ocurrió un error al modificar la regla de suscripción',
      }
    },
    validations: {
      nameRequired: 'Ingresa el nombre de la regla de suscripción',
      nameAlphanumericWithSpaces: 'Solo alfanuméricos',
      nameMaxLength: 'Máximo 60 caracteres',
      codeRequired: 'Ingresa el código de la regla de suscripción',
      codeAlphanumeric: 'Solo alfanuméricos',
      codeMaxLength: 'Máximo 10 caracteres',
      codeUnique: 'Código ya existente',
      installmentsRequired: 'Debe ingresar cantidad de cuotas',
      installmentsBetweenValues: 'Debe ser entre 1 y ?',
      adminExpensesRequired: 'Debe ingresar un porcentaje',
      adminExpensesBetweenValues: 'Debe ser entre 1 y 100',
      payDayRequired: 'Debe ingresar un día',
      payDayBetweenValues: 'Debe ser entre 1 y 30',
      paymentMethodRequired: 'Debe ingresar un método de pago',
      noSelectedBillingProducts: 'Debes seleccionar al menos un producto',
    },
  },
  en: {

  }
};

export default subscriptionRulesTranslations
