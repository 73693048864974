const exceptionsTranslations = {
    es_CL: {
        table: {
            headers: {
                identification: 'RUT',
            },
        }
    },
    es: {
        table: {
            breadcrumbs: {
                title: 'Excepciones',
                description: 'Gestión de excepciones'
            },
            headers: {
                identification: 'DNI',
                name: 'Nombre',
                lastname: 'Apellido',
                career: 'Carrera',
                career_status: 'Estado de carrera',
                term: 'Periodo',
            },
            actions: {
                success: 'La excepción se realizó correctamente',
                error: 'Ocurrió un error al realizar la excepción',
                confirmation_title: '¿Desea confirmar la excepción para el siguiente estudiante?',
                debt_inscription: 'Habilitar inscripción a asignaturas con deuda',
                confirm_preregistration: 'Firma de contrato presencial',
                revert_enrollment: 'Revertir rematricula',
                change_modality: 'Cambiar a modalidad 2019',
                payment_adjustment: 'Cuenta corriente',
                allow_reenrollment: 'Habilitar rematricula',
                identity_validation: 'Validación de identidad presencial'
            },
            noFiles: 'No hay archivos seleccionados',
            add_file: 'Agregar archivo',
            max_size:'Tamaño máximo excedido (2MB)'
        },
        payments: {
            breadcrumbs: {
                title: 'Cuenta corriente',
                description: 'Gestión de cuenta corriente'
            },
            headers: {
                id: '#',
                description: 'Descripción',
                amount: 'Monto',
                expiration: 'Fecha de vencimiento',
                term: 'Periodo',
                status: 'Estado',
                billingStatus: 'Facturado',
                checkBox:'Pagar por transferencia',
                checkBoxBill:'Generar factura',
                comments: 'Observaciones'
            },
            popup: {
                amount: 'Monto',
                expiration: 'Fecha de vencimiento',
                paymentDate: 'Fecha de pago',
                status: 'Estado',
                amount_required: 'El monto es requerido',
                date_required: 'La fecha es requerida',
                confirmation_title: '¿Desea confirmar la excepción para el pago seleccionado?',
                error: 'Ocurrió un error actualizando el pago',
                administrative_expense_exception: 'Generar excepción para gasto administrativo',
                tittle_card: 'Gasto Administrativo',
                comments: 'Observaciones'
            },
            labels: {
                student: 'Estudiante',
                rut: 'RUT',
                curp: 'CURP',
                dni: 'DNI',
                career: 'Carrera',
                studyPlan: 'Plan de estudio',
                term: 'Periodo',
            },
            bankTransferPanel: {
                title: 'Pagar por transferencia',
                subtitle: 'Generación de pago por transferencia',
                titleBilling: 'Generar Factura',
                subtitleBilling: 'Generar factura para los items seleccionados',
                id: '#',
                description: 'Descripción',
                amount: 'Monto',
                breakDown: 'Desglose',
                dateTransfer: 'Fecha de transferencia',
                voucherNumber:'Número de comprobante',
                successMessage: 'Se generó el pago por transferencia correctamente',
                errorMessage:'Hubo un error con el pago por transferencia',
                successBillingMessage: 'Se generó el recibo correctamente',
                errorBillingMessage:'Hubo un error al generar el recibo',
                errorLoadingTittle: '¡Ups!',
                errorLoadingMessage: 'Se genero un error en la validación de datos. Por favor, vuelva a intentarlo',
                errorAdministrativeExpenseAmount: 'La cantidad ingresada no concuerda con el total a abonar'
            },
        },
    },

    es_MX: {
        table: {
            headers: {
                identification: 'CURP',
            },
        }
    },


    en: {

    }
};

export default exceptionsTranslations
