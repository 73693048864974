<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <h5 class="primary--text">{{ $t('modules.portalalumno.payments.paymentsStudents.view.labels.cardTitle')
                    }}</h5>
            </v-col>

            <v-col class="d-flex align-center mb-8" cols="12">
                <VuePaycard :has-random-backgrounds="false" :is-card-number-masked="false" :value-fields="valueFields"
                    :labels="labels" :inputFields="{ cardName: 'v-card-name', cardNumber: 'v-card-number' }" />
            </v-col>
            <v-col cols="12" sm="6">
                <div id="form-checkout__cardNumber" class="mp_container"
                    :class="{ 'is-invalid': !cardNumberValid || !validBin }"></div>
                <small class="px-3 red--text" v-show="!cardNumberValid">{{
                    $t('modules.portalalumno.payments.paymentsStudents.inputErrors.required') }}</small>
            </v-col>

            <v-col cols="12" sm="3">
                <div id="form-checkout__expirationDate" class="mp_container"
                    :class="{ 'is-invalid': !expirationDateValid }"></div>
                <small class="px-3 red--text" v-show="!expirationDateValid">{{
                    $t('modules.portalalumno.payments.paymentsStudents.inputErrors.required') }}</small>
            </v-col>

            <v-col cols="12" sm="3">
                <div id="form-checkout__securityCode" class="mp_container" :class="{ 'is-invalid': !securityCodeValid }">
                </div>
                <small class="px-3 red--text" v-show="!securityCodeValid">{{
                    $t('modules.portalalumno.payments.paymentsStudents.inputErrors.required') }}</small>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="6">
                <v-text-field
                    :label="$t('modules.portalalumno.payments.paymentsStudents.view.labels.nameoncard')"
                    v-model="cardName"
                    data-card-field
                    outlined
                    maxlength="30"
                    @keypress="$validateOnlyLetter($event)"
                    :error-messages="$v.cardName.$error ?
                    ((!$v.cardName.validateNameAndLastName) ? $t('modules.portalalumno.payments.paymentsStudents.inputErrors.validateName')
                    : !$v.cardName.required ? $t('modules.portalalumno.payments.paymentsStudents.inputErrors.required')
                    : ''):''"
                    @blur="$v.cardName.$touch()"
                ></v-text-field>
                <p style="vertical-align: middle;">
                    <v-icon>mdi-information</v-icon>
                    <span style="color: #999;">{{ $t('modules.portalalumno.payments.paymentsStudents.view.creditCard.labels.cardNameLegen') }}</span>
                </p>
            </v-col>

            <v-col cols="12" sm="6" v-if="!$isMexico">
                <IdentificationInput
                    v-model="cardId"
                    :label="$t('modules.portalalumno.payments.paymentsStudents.view.labels.idoncard') + ' *'"
                    :id-type="$isIPP ? 'RUT' : 'DNI'"
                    :error-messages="$v.cardId.$error ? $t('modules.portalalumno.payments.paymentsStudents.inputErrors.required') : ''"
                    @blur="$v.cardId.$touch()" @validateRut="validCardRut = $event"></IdentificationInput>
                <input type="hidden" id="deviceId" name="deviceId" v-model="deviceId" />
            </v-col>
        </v-row>
        <v-row v-if="showInstallments">
            <v-col cols="12" class="pb-0">
                <h5 class="primary--text">{{
                    $t('modules.portalalumno.payments.paymentsStudents.view.labels.how_many_installments') }}</h5>
            </v-col>
            <v-col cols="12" md="8">
                <v-select v-model="$v.selectedInstallment.$model" @change="setDue" label="Cantidad de cuotas"
                    :items="installments" item-text="recommended_message" return-object
                    :error-messages="$v.selectedInstallment.$anyError ? $t('modules.portalalumno.payments.paymentsStudents.inputErrors.required') : ''"
                    @blur="$v.selectedInstallment.$touch()" outlined></v-select>
            </v-col>
            <v-col cols="12" md="4" v-if="resumen_interes">
                <p v-if="resumen_interes.tea.length" class="my-0 caption">{{ `TEA: ${resumen_interes.tea[1]}` }}</p>
                <p class="my-0" v-if="resumen_interes.tea.length">{{ `CFT: ${resumen_interes.cft[1]}` }}</p>
            </v-col>
        </v-row>
        <v-row>
            <v-col v-if="$isMexico" cols="12">
                <h4 class="primary--text mr-2 mt-4">{{ $t('modules.portalalumno.payments.paymentsStudents.view.labels.billingGeneric')}} </h4>
            </v-col>
        </v-row>
        <template v-if="$isTeclab && showTeclabBillingData">
            <PaymentBillingDataTeclab
                v-if="showBillingData"
                ref="billingData"
                @update="billingData = $event"
                @validate="billingDataValid = $event"
            ></PaymentBillingDataTeclab>
        </template>
        <template v-if="!$isTeclab">
            <PaymentBillingData
                v-if="showBillingData"
                ref="billingData"
                @update="billingData = $event"
                @validate="billingDataValid = $event"
            ></PaymentBillingData>
        </template>
        <Feedback v-model="feedbackPopup.show" popup status="error" :title="feedbackPopup.title"
            :description="feedbackPopup.description" :message="feedbackPopup.message" :actions="feedbackPopup.actions">
        </Feedback>
    </v-container>
</template>

<style lang="sass" scoped>
    .mp_container
        height: 44px
        padding: 12px
        color: rgba(0, 0, 0, 0.87)
        line-height: 16px
        border: thin solid rgba(0, 0, 0, 0.38)
        border-radius: 4px

        &.is-invalid
            border: thin solid red

</style>

<script>

    import IdentificationInput from '@/components/IdentificationInput/IdentificationInput'
    import PaymentBillingData from '../PaymentBillingData/PaymentBillingData'
    import PaymentBillingDataTeclab from '../PaymentBillingData/PaymentBillingDataTeclab';
    import { $payments, $paymentMethod } from '../../Services';
    import { mapGetters } from 'vuex'
    import { maxLength, required } from 'vuelidate/lib/validators'
    import { VuePaycard } from 'vue-paycard';
    import commons from '../../Mixins/commons';
    import Feedback from '@/components/Feedback/Feedback'

export default {
    name: 'GatewayMercadoPago',
    mixins: [commons],
    components: {
        IdentificationInput,
        PaymentBillingData,
        PaymentBillingDataTeclab,
        VuePaycard,
        Feedback
    },
    props: {
        pendingPayment: { type: Boolean, default: false },
        selectedMethod: { type: Object, default: null },
        summaryButtons: { type: Object, default: null },
        subscription: { type: Object, default: null },
        hideInstallments: { type: Boolean, default: false },
        cardIndex: { type: Number, default: null },
        resumen: { type: Object },
        unselectedReenrollmentsAccountIds: { type: Array, default() { return [] } },
        selectedAccount: { type: Object },
    },
    data() {
      return {
          showTeclabBillingData: false,
          deviceId: null,
          token: null,
          mp: null,
          cardId: null,
          validCardRut: false,
          billingDataValid: false,
          billingData: null,
          cardName: null,
          cardNumberValid: true,
          expirationDateValid: true,
          securityCodeValid: true,
          cardNumberElement: null,
          expirationDateElement: null,
          securityCodeElement: null,
          validBin: true,
          currentBin: null,
          selectedPaymentMethod: null,
          selectedInstallment: null,
          resumen_interes: null,
          installments: [],
          issuers: [],
          selectedIssuer: null,
          labels: {
              cardName: this.$t('modules.portalalumno.payments.paymentsStudents.view.creditCard.labels.cardName'),
              cardHolder: this.$t('modules.portalalumno.payments.paymentsStudents.view.creditCard.labels.cardHolder'),
              cardMonth: 'MM',
              cardYear: 'YY',
              cardExpires: this.$t('modules.portalalumno.payments.paymentsStudents.view.creditCard.labels.cardExpires'),
              cardCvv: 'CVV'
          },
          feedbackPopup: {
            show: false,
            title: '',
            description: '',
            message: '',
            actions: {
              primary: {
                label: this.$t('actions.goBack'),
                callback() { }
              }
            }
          }
        }
      },

        computed: {
            subscriptionChange() {
                const [firstItem] = this.resumen.items.subtotal.items;
                if (firstItem && typeof firstItem === 'object') {
                    return firstItem.billingProduct === 89 || firstItem.billingProduct === 87;
                }
                return false;
            },
            showableInstallments() {
                const arancelesCount = this.resumen.items.subtotal.items.reduce((acc, item) => {
                    return acc + (item.billingProduct == 81 ? 1 : 0)
                }, 0)
                const cuotasAmount = this.resumen.items.subtotal.items.reduce((acc, item) => {
                    return acc + (![ 80, 81 ].includes(item.billingProduct) ? item.monto : 0)
                }, 0)

                if (arancelesCount >= 2) return [ 1, 3, 6, 9 ]
                else if (cuotasAmount >= 350000) return [ 1, 3, 6 ]
                else if (arancelesCount == 1 || cuotasAmount >= 100000) return [ 1, 3 ]
                
                return [ 1 ]
            },
            dataInvalid() {
                // const cardholderName = this.cardName;
                // const array = this.cardName.split(' ');
                if(!this.showTeclabBillingData){
                    const installmentValidation = this.hideInstallments ? false : !this.selectedInstallment
                    //const isValidCardData = this.$isMexico ? !this.validBin : !this.validCardRut || !this.validBin
                    return  installmentValidation || !this.validBin || this.$v.cardName.$invalid
                    || !this.cardNumberValid || !this.securityCodeValid || !this.expirationDateValid
                }
                if(this.hideInstallments){
                    return this.$v.cardName.$invalid || !this.billingDataValid || !this.validBin
                    || !this.cardNumberValid || !this.securityCodeValid || !this.expirationDateValid
                }else{
                    return !this.selectedInstallment || !this.validCardRut ||!this.billingDataValid || !this.validBin
                    || !this.cardNumberValid || !this.securityCodeValid || !this.expirationDateValid
                }
            },
            showBillingData(){
                return this.selectedInstallment || this.hideInstallments
            },
            valueFields(){
                return {
                    cardName: this.cardName ? this.cardName : '',
                    cardNumber: '' ,
                    cardMonth:  '',
                    cardYear: '',
                    cardCvv: '',
                }
            },
            showInstallments(){
                return this.cardNumberValid && !this.$v.cardName.$invalid && this.securityCodeValid 
                && this.expirationDateValid && !this.hideInstallments 
                && this.$isMexico ? true : !this.$v.cardId.$invalid && this.selectedMethod.type != 'bank_transfer'
            },
            totalAmount() {
                if (this.cardIndex != null) return this.resumen.cards[this.cardIndex].amount
                else return this.resumen.total
            },
            ...mapGetters({
                studentRegister: 'commons/getStudentActualRegister',
                studentUserData: 'commons/getStudentUserData',
                loggedUsername: 'commons/getUserName',
                studentData: 'commons/getStudentData',
                $mpConfig: 'commons/getMpConfig'
            }),
        },
        created() {
            if (this.$isMexico) this.validCardRut = true
            this.initForm()
        },
    beforeDestroy() {
        this.cardNumberElement.unmount()
        this.expirationDateElement.unmount()
        this.securityCodeElement.unmount()
    },
    methods: {
        async validateBin(bin) {
            try {
                if (bin && bin.length >= 6 && this.selectedMethod.id != 'TC2') {
                    await $paymentMethod.find('bin/validator', null, {
                        params: {
                            bankId: this.selectedAccount?.bankId,
                            cardId: this.selectedAccount?.cardId,
                            bin: bin.slice(0, 6)
                        }
                    })
                    this.validBin = true
                }
                if (bin.slice(0, 6) == '589562' && this.selectedMethod.id == 'TC2') {
                    this.$store.dispatch('commons/openPopUp', {
                        title: this.$t('alert'),
                        content: [{ value: this.$t('modules.portalalumno.payments.paymentsStudents.inputErrors.invalidBin') }],
                        actionPrimary: { text: this.$t('actions.accept'), callback: () => { this.validBin = false } },
                        icon: { color: 'warning', name: 'mdi-alert' },
                        color: 'primary',
                    });
                } else {
                    this.validBin = true
                }
            } catch {
                this.$store.dispatch('commons/openPopUp', {
                    title: this.$t('alert'),
                    content: [{ value: this.$t('modules.portalalumno.payments.paymentsStudents.inputErrors.invalidBin') }],
                    actionPrimary: { text: this.$t('actions.accept'), callback() { } },
                    icon: { color: 'warning', name: 'mdi-alert' },
                    color: 'primary',
                });
                this.validBin = false
            }
        },
        async initForm() {
            const style = {
                'placeholder-color': 'rgba(0, 0, 0, 0.6)',
                'color': 'rgba(0, 0, 0, 0.87)',
            }
            this.mp = await new window.MercadoPago(this.$getAccountKey(this.selectedAccount?.account_id))
            this.cardNumberElement = this.mp.fields.create('cardNumber', { placeholder: 'Número de tarjeta', style }).mount('form-checkout__cardNumber');
            this.expirationDateElement = this.mp.fields.create('expirationDate', { placeholder: 'MM/YY', style }).mount('form-checkout__expirationDate');
            this.securityCodeElement = this.mp.fields.create('securityCode', { placeholder: 'CVV', style }).mount('form-checkout__securityCode');
            this.cardNumberElement.on('validityChange', (e) => { this.cardNumberValid = e.errorMessages ? !e.errorMessages.length : false })
            this.expirationDateElement.on('validityChange', (e) => { this.expirationDateValid = e.errorMessages ? !e.errorMessages.length : false })
            this.securityCodeElement.on('validityChange', (e) => { this.securityCodeValid = e.errorMessages ? !e.errorMessages.length : false })
            this.cardNumberElement.on('binChange', this.binChange)
        },
        async binChange(data) {
            const { bin } = data;
            try {
                if (!bin && this.selectedPaymentMethod?.id) {
                    this.selectedPaymentMethod = null;
                    this.resetDue()
                    this.validateBin(bin)

                }

                if (bin && bin !== this.currentBin) {
                    const { results } = await this.mp.getPaymentMethods({ bin });
                    this.resetDue()
                    this.validateBin(bin)
                    if (results && results.length) {
                        // eslint-disable-next-line prefer-destructuring
                        const paymentMethod = results[0];

                        this.selectedPaymentMethod = paymentMethod;
                        this.updatePCIFieldsSettings(paymentMethod);
                        await this.updateIssuer(paymentMethod, bin);
                        this.updateInstallments(paymentMethod, bin);
                    } else {
                        this.selectedPaymentMethod = null
                    }
                }

                this.currentBin = bin;

            } catch (e) {
                this.resetDue()
            }
        },
        updatePCIFieldsSettings(paymentMethod) {
            const { settings } = paymentMethod;

            const cardNumberSettings = settings[0].card_number;
            this.cardNumberElement.update({ settings: cardNumberSettings });

            const securityCodeSettings = settings[0].security_code;
            this.securityCodeElement.update({ settings: securityCodeSettings });
        },
        async updateIssuer(paymentMethod, bin) {
                const { additional_info_needed, issuer } = paymentMethod;
                let issuerOptions = [issuer];

                if (additional_info_needed.includes('issuer_id')) {
                    try {
                        issuerOptions = await this.mp.getIssuers({ id: paymentMethod.id, bin });
                    } catch (e) {
                        this.resetDue()
                    }
                }

                this.issuers = issuerOptions
                if (issuerOptions.length == 1) this.selectedIssuer = issuer
                else this.selectedIssuer = null
            },
            async updateInstallments(paymentMethod, bin) {
                try {
                    const params = {
                        payment_method_id: paymentMethod.id,
                        amount: this.subscription ? this.resumen.items.adminExpenses.monto : this.totalAmount,
                        careerId: this.studentData.career.id,
                        studentType: this.studentData.studentType.value,
                        bin: bin.slice(0,6),
                        accountId: this.selectedAccount?.account_id,
                        userType: this.$isGestor ? 'ADVISOR' : 'STUDENT'
                    }
                    if (this.selectedIssuer) params.card_issuer = this.selectedIssuer.id

                    const { data } = await $paymentMethod.find('installments', null, { params })
                    const installments = data.installments[0].payer_costs

                    // this.installments = this.subscriptionChange ? installments.slice(0,1) : installments
                    this.installments = installments.filter(item => this.showableInstallments.includes(item.installments))
                } catch (e) {
                    this.installments = []
                }
            },
            resetDue() {
                this.selectedInstallment = null
                this.$v.selectedInstallment.$reset()
                this.resumen_interes = null

                if (this.cardIndex != null) {
                    this.resumen.cards[this.cardIndex].total = this.resumen.cards[this.cardIndex].amount
                    this.resumen.cards[this.cardIndex].cft = '0,00%'
                    this.resumen.cards[this.cardIndex].tea = '0,00%'
                    this.resumen.cards[this.cardIndex].accountId = this.selectedAccount?.account_id
                } else {
                    this.resumen.cft = '0,00%'
                    this.resumen.tea = '0,00%'
                }

                this.$emit('resetDue')
            },
            setDue(value) {
                let cft = ['CFT', null]
                let tea = ['TEA', null]

                if (this.$countryConstants.payment.isCftEnabled) {
                    const tax = value.labels.find(label => label.includes('CFT') && label.includes('TEA'))?.split('|') ?? ('CFT_0,0%|TEA_0,0%').split('|')
                    cft = (tax.length) ? tax[0].split('_') : ['CFT', '0,0%']
                    tea = (tax.length) ? tax[1].split('_') : ['TEA', '0,0%']
                    this.resumen_interes = { cft, tea }
                }

                if (this.cardIndex != null) {
                    this.resumen.cards[this.cardIndex].total = value.total_amount
                    this.resumen.cards[this.cardIndex].cft = cft[1] // eslint-disable-line prefer-destructuring
                    this.resumen.cards[this.cardIndex].tea = tea[1] // eslint-disable-line prefer-destructuring
                } else {
                    this.resumen.cft = cft[1] // eslint-disable-line prefer-destructuring
                    this.resumen.tea = tea[1] // eslint-disable-line prefer-destructuring
                }
                this.$emit('setDue', {
                    installments: this.selectedInstallment.installments,
                    installment_amount: this.selectedInstallment.installment_amount,
                    total_amount: this.selectedInstallment.total_amount,
                    credit_card_fee: this.selectedInstallment?.credit_card_fee ?? null,
                    name: this.selectedPaymentMethod.name
                })
            },
            async saveBillingData() {
                this.summaryButtons.primary.loading = true

                try {
                    if(this.showTeclabBillingData){
                        await this.$refs.billingData.save()
                    }
                    this.createToken()
                } catch(error) {
                    this.errorLoading('¡Ups!', 'Se genero un error en la validación de datos. Por favor, vuelva a intentarlo')
                    this.summaryButtons.primary.loading = false
                    this.summaryButtons.primary.error = true

                    throw error;
                }
            },
            async createToken() {

                try {
                    const token = await this.mp.fields.createCardToken({
                        cardholderName: this.cardName,
                        identificationType: this.$isMexico ? null : this.$countryConstants.identificationType,
                        identificationNumber: this.$isMexico ? null : this.cardId.replace('-',''),
                    });
                    this.token = token.id;
                    this.createPayment()
                } catch (error) {
                    this.token = null
                    throw error
                }
            },
            async createPayment() {
                let desc = '';
                const conceptos = this.resumen.items.subtotal.items;
                let firstAmount = null
                let secondAmount = null

                conceptos.forEach((concepto,index) => {
                    if(index === 0) desc = concepto.title;
                    else desc += ` - ${concepto.title}`
                });

                const cardholderName = this.cardName;
                const array = cardholderName.trim().split(' ');
                const firstName = array[0]; // eslint-disable-line
                const lastName = array[array.length - 1]; // eslint-disable-line

                if (this.cardIndex === null){
                    firstAmount = this.subscription ? parseFloat(this.resumen.items.adminExpenses.monto).toFixed(2) :
                                    (this.$isTeclab ?  this.totalAmount.toFixed(2) : this.totalAmount.toFixed(0))
                } else {
                    if (this.resumen.cards.length > 1 && this.cardIndex === 0) {
                        firstAmount = this.resumen.cards[0].amount
                        secondAmount = parseFloat(this.resumen.cards[1].amount)
                    } else {
                        firstAmount = parseFloat(this.resumen.cards[1].amount)
                        secondAmount = this.resumen.cards[0].amount;
                    }
                }

                firstAmount = this.$encrypter(parseFloat(firstAmount).toFixed(2));
                if (secondAmount !== null) secondAmount = this.$encrypter(parseFloat(secondAmount).toFixed(2))
                let percentageSubscription = parseFloat(this.resumen.items.adminExpenses.percentage).toFixed(2)
                if (percentageSubscription && !isNaN(percentageSubscription)) percentageSubscription = this.$encrypter(percentageSubscription)
                const paymentData = {
                    'legajo': this.studentRegister,
                    'amount': firstAmount,
                    'description': this.subscription ? 'GASTO_ADMINISTRATIVO' : desc,
                    'payment_method_id': this.selectedPaymentMethod.id,
                    'token': this.token,
                    'installments': this.selectedInstallment?.installments || 1,
                    // 'installments': this.subscription ? this.subscription.installments : (this.selectedInstallment?.installments || 1),
                    'payer_email': this.studentUserData.email,
                    'issuer_id': this.selectedIssuer.id,
                    'usr_portal': this.loggedUsername,
                    'discount_intent': this.resumen.paymentIntent,
                    'intencion_de_pago_id': this.$paymentsIdToArray(this.resumen.items.subtotal.items),
                    'user_type': this.$isGestor ? 'ADVISOR' : 'STUDENT',
                    'doc_number': this.cardId,
                    'doc_type': this.$countryConstants.identificationType,
                    'device_id': this.deviceId,
                    'firstName': firstName,
                    'lastName': lastName,
                    'capture': !!this.subscription || !this.pendingPayment && !this.subscription,
                    // 'capture': !this.pendingPayment && !this.subscription,
                    'pago_una_tarjeta': this.selectedMethod.id != 'TC2',
                    'credit_card_fee': this.selectedInstallment?.credit_card_fee ?? null,
                    'secondAmount': secondAmount,
                    'percentage': percentageSubscription,
                    'idsPending': this.unselectedReenrollmentsAccountIds ,
                    'bin': parseInt(this.currentBin.slice(0,6)),
                    'accountId': this.selectedAccount?.account_id,
                    'city': !this.$showTeclabBillingData ? '': this.$isTeclab ? this.billingData.city : this.billingData.city.cityState,
                    'phone':!this.$showTeclabBillingData ? '' : this.$isTeclab ? this.billingData.phone : '',
                    'postal_code': !this.$showTeclabBillingData ? '': this.$isTeclab ? this.billingData.zipCode : '',
                    'state': !this.$showTeclabBillingData ? '' : this.$isTeclab ? this.billingData.state : '',
                    'address':!this.$showTeclabBillingData ? '' : `${this.billingData.street}-${this.billingData.number}`,
                    'generic': this.$isMexico && this.showTeclabBillingData ? false : true,
                    'device_unique_identifier': 0
                };
                try {
                    const { data } = await $payments.create(paymentData);

                    if (data && data.status === 'rejected'){
                      const { status, status_detail } = data
                      if (this.isPopupCodeError(status_detail)) {
                          this.feedbackPopup.show = true
                          this.feedbackPopup.title = this.$t(`modules.portalalumno.payments.paymentsStudents.gatewayMPFeedback.${status}.${status_detail}.title`, data)
                          this.feedbackPopup.description = this.$t(`modules.portalalumno.payments.paymentsStudents.gatewayMPFeedback.${status}.${status_detail}.description`, data)
                          this.feedbackPopup.message = this.$t(`modules.portalalumno.payments.paymentsStudents.gatewayMPFeedback.${status}.${status_detail}.message`, data)
                          this.feedbackPopup.actions.primary.callback = (close) => {
                            this.summaryButtons.primary.disabled = false
                            close()
                          }
                      } else {
                        this.$emit('changeStep', { ...data, accountKey: 'MERCADOPAGO' }, data);
                      }
                        this.summaryButtons.primary.error = true
                        this.summaryButtons.primary.disabled = true
                    } else if (data.status === 'approved' || data.status === 'in_process') {
                        if(data.payment_type_id === 'credit_card' || data.payment_type_id === 'debit_card') {
                            this.$emit('paymentProcessed',{ cardName: this.cardName, token: this.token, data, efectivo: false, accountId: this.selectedAccount?.account_id })
                        }
                        this.$emit('changeStep', { ...data, accountKey: 'MERCADOPAGO' }, data);
                        this.successLoading()
                    } else if (data.status === 'authorized') {
                        if (this.pendingPayment) {
                            //Pago con dos tajetas
                            this.$emit('paymentProcessed', { index: this.cardIndex, paymentId: JSON.parse(data.full_response).id })
                        } else {
                            //Pago con suscripción
                            this.$emit('paymentProcessed',{ cardName: this.cardName, token: this.token, data, efectivo: false })
                        }
                    }
                } catch (response) {
                    this.errorLoading('¡Ups!', 'Se genero un error en el proceso de pago. Por favor, vuelva a intentarlo')
                    this.summaryButtons.primary.error = true
                    throw response
                } finally {
                    this.summaryButtons.primary.loading = false
                }
            },
            successLoading() {
                this.summaryButtons.primary.loading = false
                this.summaryButtons.primary.success = true
                setTimeout(() => {
                    this.summaryButtons.primary.success = false
                    this.summaryButtons.primary.disabled = true
                }, 2500)
            },
            errorLoading(title = null, description = null) {
            this.summaryButtons.primary.loading = false
            this.summaryButtons.primary.error = true
            this.summaryButtons.primary.error = false
            if (title && description) {
                this.feedbackPopup.show = true
                this.feedbackPopup.title = title
                this.feedbackPopup.description = description
                this.feedbackPopup.message = ''
                this.feedbackPopup.actions.primary.callback = (close) => {
                    if (this.cardIndex !== null) this.$router.go()
                    else close()
                }
            }
            if (this.cardIndex) this.$emit('paymentError')  // Si paga con varias tarjetas y el error fue en otro que no sea en el primero, vuelve a iniciar
        },
        isPopupCodeError(status_detail) {
            const codes = [
                'cc_rejected_bad_filled_card_number',
                'cc_rejected_bad_filled_date',
                'cc_rejected_bad_filled_other',
                'cc_rejected_bad_filled_security_code',
            ]
            return codes.includes(status_detail);
        },
          errorMessage(error){
            const codes = {
                cc_rejected_bad_filled_card_number: this.$t('modules.portalalumno.payments.paymentsStudents.gatewayMPFeedback.rejected.cc_rejected_bad_filled_card_number.title') ,
                cc_rejected_bad_filled_date: this.$t('modules.portalalumno.payments.paymentsStudents.gatewayMPFeedback.rejected.cc_rejected_bad_filled_date.title'),
                cc_rejected_bad_filled_other:  this.$t('modules.portalalumno.payments.paymentsStudents.gatewayMPFeedback.rejected.cc_rejected_bad_filled_other.title'),
                cc_rejected_bad_filled_security_code: this.$t('modules.portalalumno.payments.paymentsStudents.gatewayMPFeedback.rejected.cc_rejected_bad_filled_security_code.title'),
                cc_rejected_blacklist:  this.$t('modules.portalalumno.payments.paymentsStudents.gatewayMPFeedback.rejected.cc_rejected_blacklist.title'),
                cc_rejected_call_for_authorize:  this.$t('modules.portalalumno.payments.paymentsStudents.gatewayMPFeedback.rejected.cc_rejected_call_for_authorize.title'),
                cc_rejected_card_disabled:  this.$t('modules.portalalumno.payments.paymentsStudents.gatewayMPFeedback.rejected.cc_rejected_card_disabled.title'),
                cc_rejected_card_error:  this.$t('modules.portalalumno.payments.paymentsStudents.gatewayMPFeedback.rejected.cc_rejected_card_error.title'),
                cc_rejected_duplicated_payment:  this.$t('modules.portalalumno.payments.paymentsStudents.gatewayMPFeedback.rejected.cc_rejected_duplicated_payment.title'),
                cc_rejected_high_risk:  this.$t('modules.portalalumno.payments.paymentsStudents.gatewayMPFeedback.rejected.cc_rejected_high_risk.title'),
                cc_amount_rate_limit_exceeded:  this.$t('modules.portalalumno.payments.paymentsStudents.gatewayMPFeedback.rejected.cc_amount_rate_limit_exceeded.title'),
                cc_rejected_insufficient_amount:  this.$t('modules.portalalumno.payments.paymentsStudents.gatewayMPFeedback.rejected.cc_rejected_insufficient_amount.title'),
                cc_rejected_invalid_installments:  this.$t('modules.portalalumno.payments.paymentsStudents.gatewayMPFeedback.rejected.cc_rejected_invalid_installments.title'),
                cc_rejected_max_attempts:  this.$t('modules.portalalumno.payments.paymentsStudents.gatewayMPFeedback.rejected.cc_rejected_max_attempts.title'),
                cc_rejected_other_reason:  this.$t('modules.portalalumno.payments.paymentsStudents.gatewayMPFeedback.rejected.cc_rejected_other_reason.title')
            }
            return codes[error]
          },
        },
        watch: {
            billingDataValid() {
                if (this.selectedInstallment) this.setDue(this.selectedInstallment)
            },
            dataInvalid(value) {
                this.summaryButtons.primary.disabled = value
            },
        },
        validations: {
            securityCodeValid: {required},
            expirationDateValid: { required },
            cardNumberValid: { required },
            cardId: { required },
            cardName: {
                required,
                maxLength: maxLength(30),
                validateNameAndLastName (val) {
                    const arrayName = val?.split(' ')
                    return arrayName?.length >= 2 && arrayName[1] != ''
                },
            },
            selectedInstallment: { required },
            validBin: { required }
        },
    }
</script>
