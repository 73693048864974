<template>
    <v-row class="transferList">
        <v-col class="transferList__list-left py-0">
            <h4 class="title mb-4">{{ firstListTitle }}</h4>
            <v-card flat outlined>
                <v-data-iterator
                    :items="leftFilteredItems"
                    disable-pagination
                    disable-sort
                    disable-filtering
                    hide-default-footer
                    :search="search[0]"
                >
                    <template v-slot:header>
                        <!-- :loading="loading" -->
                        <v-text-field
                            v-model="search[0]"
                            clearable
                            flat
                            solo
                            hide-details
                            prepend-inner-icon="mdi-magnify"
                            :placeholder="$t('components.transferlistTranslations.search')"
                            class="transferList__list__search"
                        ></v-text-field>
                    </template>
                    
                    <template v-slot:default="{ items }">
                        <v-list class="py-0">
                            <v-subheader class="transferList__list__header d-flex justify-space-between">
                                <v-checkbox
                                    color="primary"
                                    :label="$t('components.transferlistTranslations.select')"
                                    :value="leftSelected.length == leftFilteredItems.length"
                                    :indeterminate="!!leftSelected.length && leftSelected.length < leftFilteredItems.length"
                                    @change="selectAll('leftSelected', leftFilteredItems, $event)"
                                ></v-checkbox>
                                <span class="caption">{{`${leftSelected.length} ${$t('components.transferlistTranslations.selecteds')}`}}</span>
                            </v-subheader>

                            <v-list-item-group
                                v-model="leftSelected"
                                multiple
                                class="transferList__list__content"
                            >
                                <!-- v-infinite-scroll="loadMore"
                                infinite-scroll-disabled="busy"
                                infinite-scroll-throttle-delay="700" -->
                                <draggable
                                    :value="leftFilteredItems"
                                    :sort="false"
                                    :group="tableKey"
                                    @start="isDragging = true"
                                    @end="isDragging = false"
                                    @remove="removeDragItem($event)"
                                >
                                    <v-list-item
                                        v-for="(item, i) in items"
                                        :key="`left-item-${item.id}-${i}`"
                                        :value="item"
                                        :disabled="!isDragging && item.disabled"
                                    >
                                        <template v-slot:default="{ active }">
                                            <v-list-item-action class="mr-2">
                                                <v-icon>mdi-drag-vertical</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-action class="mr-2">
                                                <v-checkbox
                                                    color="primary"
                                                    :input-value="active"
                                                    :true-value="item"
                                                    :disabled="!isDragging && item.disabled"
                                                ></v-checkbox>
                                            </v-list-item-action>
                                            <v-avatar v-if="item.avatar" size="32" class="mr-4">
                                                <img :src="item.avatar" />
                                            </v-avatar>
                                            <v-list-item-content class="d-flex">
                                                <span v-if="itemText" class="body-2">{{ item[itemText] }}</span>
                                                <span v-else class="body-2">-</span>
                                                <!-- <v-chip
                                                    v-if="chips"
                                                    x-small
                                                    :color="!item[valueDisable] ? 'success' : 'grey lighten-2'"
                                                    :dark="!!item[valueDisable]"
                                                >{{ !item[valueDisable] ? 'Disponible' : item[valueDisable][showInChip] }}</v-chip> -->
                                            </v-list-item-content>
                                        </template>
                                    </v-list-item>
                                </draggable>
                            </v-list-item-group>
                        </v-list>
                    </template>

                    <template v-slot:no-data>
                        <draggable
                            :value="leftFilteredItems"
                            :sort="false"
                            :group="tableKey"
                            @start="isDragging = true"
                            @end="isDragging = false"
                        >
                            <p class="overline pt-4 pl-4 grey--text">{{ $t('components.transferlistTranslations.empty_list') }}</p>
                        </draggable>
                    </template>

                    <template v-slot:no-results>
                        <draggable
                            :value="leftFilteredItems"
                            :sort="false"
                            :group="tableKey"
                            @start="isDragging = true"
                            @end="isDragging = false"
                        >
                            <p class="overline pt-4 pl-4 grey--text">{{ $t('components.transferlistTranslations.no_records') }}</p>
                        </draggable>
                    </template>
                </v-data-iterator>
            </v-card>
        </v-col>

        <v-col class="transferList__actions">
            <v-btn :disabled="!leftSelected.length" @click="addItem" class="transferList__actions__btn" small fab elevation="2">
                <v-icon color="grey darken-1">mdi-chevron-right</v-icon>
            </v-btn>
            <v-btn :disabled="!rightSelected.length" @click="removeItem" class="transferList__actions__btn" small fab elevation="2">
                <v-icon color="grey darken-1">mdi-chevron-left</v-icon>
            </v-btn>
        </v-col>

        <v-col class="transferList__list-right py-0">
            <h4 class="title mb-4">{{ secondListTitle }}</h4>
            <v-card flat outlined>
                <v-data-iterator
                    :items="rightFilteredItems"
                    disable-pagination
                    disable-sort
                    disable-filtering
                    hide-default-footer
                    :search="search[1]"
                >
                    <template v-slot:header>
                        <v-text-field
                            v-model="search[1]"
                            clearable
                            flat
                            solo
                            hide-details
                            prepend-inner-icon="mdi-magnify"
                            :placeholder="$t('components.transferlistTranslations.search')"
                            class="transferList__list__search"
                        ></v-text-field>
                    </template>

                    <template v-slot:default="{ items }">
                        <v-list class="py-0">
                            <v-subheader class="transferList__list__header d-flex justify-space-between">
                                <v-checkbox
                                    color="primary"
                                    :label="$t('components.transferlistTranslations.select')"
                                    :value="rightSelected.length == rightFilteredItems.length"
                                    :indeterminate="!!rightSelected.length && rightSelected.length < rightFilteredItems.length"
                                    @change="selectAll('rightSelected', rightFilteredItems, $event)"
                                ></v-checkbox>
                                <span class="caption">{{`${rightSelected.length} ${$t('components.transferlistTranslations.selecteds')}`}}</span>
                            </v-subheader>

                            <v-list-item-group
                                v-model="rightSelected"
                                multiple
                                class="transferList__list__content"
                            >
                                <draggable
                                    :value="rightFilteredItems"
                                    :sort="false"
                                    :group="tableKey"
                                    @start="isDragging = true"
                                    @end="isDragging = false"
                                    @remove="removeDragItem($event, false)"
                                >
                                    <v-list-item
                                        v-for="(item, i) in items"
                                        :key="`right-item-${item.id}-${i}`"
                                        :value="item"
                                        :disabled="!isDragging && item.disabled"
                                    >
                                        <template v-slot:default="{ active }">
                                            <v-list-item-action class="mr-2">
                                                <v-icon>mdi-drag-vertical</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-action class="mr-2">
                                                <v-checkbox
                                                    color="primary"
                                                    :input-value="active"
                                                    :true-value="item"
                                                    :disabled="!isDragging && item.disabled"
                                                ></v-checkbox>
                                            </v-list-item-action>
                                            <v-avatar v-if="item.avatar" size="32" class="mr-4">
                                                <img :src="item.avatar" />
                                            </v-avatar>
                                            <v-list-item-content class="d-flex">
                                                <span v-if="itemText" class="body-2">{{ item[itemText] }}</span>
                                                <span v-else class="body-2">-</span>
                                                <!-- <v-chip
                                                    v-if="chips"
                                                    x-small
                                                    :color="!item[valueDisable] ? 'success' : 'grey lighten-2'"
                                                    :dark="!!item[valueDisable]"
                                                >{{ !item[valueDisable] ? 'Disponible' : item[valueDisable][showInChip] }}</v-chip> -->
                                            </v-list-item-content>
                                        </template>
                                    </v-list-item>
                                </draggable>
                            </v-list-item-group>
                        </v-list>
                    </template>

                    <template v-slot:no-data>
                        <draggable
                            :value="rightFilteredItems"
                            :sort="false"
                            :group="tableKey"
                            @start="isDragging = true"
                            @end="isDragging = false"
                        >
                            <p class="overline pt-4 pl-4 grey--text">{{ $t('components.transferlistTranslations.empty_list') }}</p>
                        </draggable>
                    </template>
                    <template v-slot:no-results>
                        <draggable
                            :value="rightFilteredItems"
                            :sort="false"
                            :group="tableKey"
                            @start="isDragging = true"
                            @end="isDragging = false"
                        >
                            <p class="overline pt-4 pl-4 grey--text">{{ $t('components.transferlistTranslations.no_records') }}</p>
                        </draggable>
                    </template>
                </v-data-iterator>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import draggable from 'vuedraggable'
import { nanoid } from 'nanoid'

export default {
    name: 'TransferList',
    props: {
        firstListTitle: { type: String, default: '' },
        secondListTitle: { type: String, default: '' },
        availableItems: { type: Array, default: () => [] },
        addedItems: { type: Array, default: () => [] },
        itemText: { type: String, default: 'name' },
        // chips: { type: Boolean, default: false },
        // loading: { type: Boolean, default: false },
    },
    components: {
        draggable,
    },
    data() {
        return {
            leftItems: [],
            leftSelected: [],
            rightItems: [],
            rightSelected: [],
            search: [ '', '' ],
            isDragging: false,
            tableKey: null,
        }
    },
    computed: {
        leftFilteredItems() {
            if (!this.search[0] || this.search[0] == '') return this.leftItems

            const regEx = new RegExp(this.search[0], 'i')
            return this.leftItems.filter(item => regEx.test(item[this.itemText]))
        },
        rightFilteredItems() {
            if (!this.search[1] || this.search[1] == '') return this.rightItems

            const regEx = new RegExp(this.search[1], 'i')
            return this.rightItems.filter(item => regEx.test(item[this.itemText]))
        },
    },
    created() {
        this.tableKey =  nanoid()
    },
    methods: {
        removeDragItem(evt, isLeft = true) {
            let index = -1

            if (isLeft) {
                if (this.search[0] && this.search[0] != '') index = this.leftItems.findIndex(item => item.id == this.leftFilteredItems[evt.oldIndex].id)
                else index = evt.oldIndex
            } else {
                if (this.search[1] && this.search[1] != '') index = this.rightItems.findIndex(item => item.id == this.rightFilteredItems[evt.oldIndex].id)
                else index = evt.oldIndex
            }

            if (index < 0) return

            if (isLeft) {
                const item = this.leftItems.splice(index, 1)
                this.rightItems = [ ...this.rightItems, ...item ]
                this.leftSelected = []
            } else {
                const item = this.rightItems.splice(index, 1)
                this.leftItems = [ ...this.leftItems, ...item ]
                this.rightSelected = []
            }

            this.$emit('update:availableItems', [ ...this.leftItems ]);
            this.$emit('update:addedItems', [ ...this.rightItems ]);
            this.isDragging = false
        },
        addItem() {
            this.rightItems = [ ...this.rightItems, ...this.leftSelected ]
            this.leftItems = this.leftItems.filter(item => !this.leftSelected.some(selectedItem => selectedItem.id == item.id))
            this.leftSelected = []
            this.rightSelected = []
            this.$emit('update:availableItems', [ ...this.leftItems ]);
            this.$emit('update:addedItems', [ ...this.rightItems ]);
        },
        removeItem() {
            this.leftItems = [ ...this.leftItems, ...this.rightSelected ]
            this.rightItems = this.rightItems.filter(item => !this.rightSelected.some(selectedItem => selectedItem.id == item.id))
            this.leftSelected = []
            this.rightSelected = []
            this.$emit('update:availableItems', [ ...this.leftItems ]);
            this.$emit('update:addedItems', [ ...this.rightItems ]);
        },
        selectAll(object, filteredObject, selectedAll) {
            if (selectedAll) this[object] = [ ...filteredObject ]
            else this[object] = []
        },
        filterLeftItems() {
            this.leftItems = this.leftItems.filter(item => !this.rightItems.some(addedItem => addedItem.id == item.id))
        },
    },
    watch: {
        availableItems: {
            handler(items) {
                this.leftItems = [ ...items ]
                this.filterLeftItems()
            }, deep: true
        },
        addedItems: {
            handler(items) {
                this.rightItems = [ ...items ]
                this.filterLeftItems()
            }, deep: true
        }
    }
}
</script>

<style lang="scss" scoped>

    .transferList {
        &__list {
            &-right, &-left {
                flex-grow: 4;
            }
            &__search {
                border-bottom: thin solid rgba(0, 0, 0, 0.12);
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;

                ::v-deep .v-input__control { min-height: 0 !important; }
            }
            &__header {
                background-color: #fafafa;
                border-bottom: thin solid rgba(0, 0, 0, 0.12);

                ::v-deep .v-input {
                    &--checkbox {
                        .v-label { font-size: 14px; }
                        .v-input--selection-controls__input { opacity: 0.2; transition: opacity 300ms ease; }
                        &:hover,
                        &.v-input--indeterminate, &.v-input--is-label-active {
                            .v-input--selection-controls__input { opacity: 1; }
                        }
                    }
                }

            }

            &__content {
                max-height: 300px;
                overflow: auto;

                .v-list-item {
                    min-height: unset !important;

                    &__content {
                        justify-content: space-between;
                        * { flex: initial; }
                    }
                    &__icon, &__action {
                        transition: opacity 300ms ease;
                        opacity: 0.2;
                    }
                    &__icon { margin: 8px 0; }
                    &__action { margin: 8px 0; }
                    &__content { padding: 8px 0; }
                    &:hover {
                        .v-list-item__icon, .v-list-item__action { opacity: 1; }
                    }
                    &--active {
                        &:before { opacity: 0.05; }
                        .v-list-item__icon, .v-list-item__action { opacity: 1; }
                    }
                }
            }
        }
        &__actions {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            justify-content: center;
            align-items: center;
            
            &__btn {
                margin: 0.5rem;
                
                &:hover, &:active, &:focus {
                    transition: all 300ms ease;
                    background-color: var(--v-primary-base);
                    .v-icon { color: #FFFFFF !important; }
                }
            }
        }
    }

</style>