<template>
  <div>
    <v-container fluid class="pa-0 pb-8">
      <v-container class="container-custom" v-if="!createStatus">
        <template v-if="!insideDrawer">
          <Breadcrumbs :title="$t('modules.communities.create.breadcrumbs.title')" :description="$t('modules.communities.create.breadcrumbs.subtitle')">
          </Breadcrumbs>
        </template>
        <template>
          <v-row justify="center">
            <v-col class="viewSpaces" sm="12">
              <OutlinedCard
                :title="$t('modules.communities.create.card.title')"
                :subtitle="$t('modules.communities.create.card.subtitle')"
                :switchLabel="community.status? $t('modules.communities.create.card.enabled') : $t('modules.communities.create.card.disabled')"
                switcher
                :switchValue="community.status"
                @switchChange="val => community.status = val"
                class="mb-6"
              >
                <v-row>
                  <v-col sm="6">
                    <v-text-field
                      :error-messages="$v.community.name.$anyError ? $t('modules.communities.create.formErrors.bussinessname') : ''"
                      @blur="$v.community.name.$touch()"
                      v-model="$v.community.name.$model"
                      :label="`${$t('modules.communities.create.card.businessname')} *`"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col sm="6">
                    <v-text-field
                      :error-messages="$v.community.activity.$anyError ? $t('modules.communities.create.formErrors.activity') : ''"
                      v-model="$v.community.activity.$model"
                      @blur="$v.community.activity.$touch()"
                      :label="`${$t('modules.communities.create.card.activity')} *`"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="6" class="d-flex align-center">
                    <IdentificationInput
                      v-model="$v.community.identification.$model"
                      :loading="loadingCode"
                      @preTimer="() => {this.codeUnique = null}"
                      @posTimer="() => {this.validateCode()}"
                      :label="$t('modules.users.create.personalForm.identificationNumber') + ' *'"
                      :error-messages="($v.community.identification.$dirty && $v.community.identification.$invalid) ? (!$v.community.identification.unique ? $t('modules.users.create.validation_errors.personalForm.identification_unique') : $t('modules.users.create.validation_errors.personalForm.identification')) : ''"
                      @blur="$v.community.identification.$touch()"
                      @validateRut="(status) => {this.validRut = status}"
                      :idType="$isIPP ? 'RUT' : 'CUIT'"
                    ></IdentificationInput>
                  </v-col>
                  <v-col sm="6">
                    <v-select
                      :error="$v.community.benefit.id.$anyError"
                      v-model="$v.community.benefit.id.$model"
                      @blur="$v.community.benefit.id.$touch()"
                      :label="`${$t('modules.communities.create.card.benefit')} *`"
                      :items="benefits"
                      item-text="description"
                      item-value="id"
                      outlined
                    ></v-select>
                  </v-col>
                  <v-col sm="6">
                    <v-text-field
                      :error-messages="$v.community.token.$anyError ? $t('modules.communities.create.formErrors.token') : ''"
                      v-model="$v.community.token.$model"
                      @blur="$v.community.token.$touch()"
                      :label="`${$t('modules.communities.create.card.token')}`"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="3">
                    <v-switch
                      v-model="community.commision"
                      :label="$t('modules.communities.create.card.comission')"
                      switcher
                      @change="() => {community.amount = ''; community.percentage = '';}"
                      color="primary"
                      class="ma-0"
                    ></v-switch>
                  </v-col>
                  <v-col sm="6" >
                    <v-row no-gutters>
                      <v-col sm="4">
                        <div @click="() => {community.amount = ''; $nextTick(() => $refs.percentage.focus());}">
                          <v-text-field
                            type="number"
                            :label="$t('modules.communities.create.card.percent')"
                            outlined
                            ref="percentage"
                            min="0"
                            max="100"
                            prepend-inner-icon="mdi-percent"
                            :error="$v.community.percentage.$anyError"
                            v-model.number="$v.community.percentage.$model"
                            @keypress="$validateDecimalInputNumber($event, $v.community.percentage.$model, 100)"
                            @blur="$v.community.percentage.$touch()"
                            @focus="() => community.amount = ''"
                            :disabled="community.amount > 0 || !community.commision"
                          ></v-text-field>
                        </div>
                      </v-col>
                      <v-subheader :or="$t('modules.communities.create.card.or')" class="mx-1">
                        {{$t('modules.communities.create.card.or')}}
                      </v-subheader>
                      <v-col sm="4">
                        <div @click="() => {community.percentage = ''; $nextTick(() => $refs.amount.focus());}">
                          <v-text-field
                            type="number"
                            :label="$t('modules.communities.create.card.amount')"
                            outlined
                            ref="amount"
                            min="0"
                            :error="$v.community.amount.$anyError"
                            v-model.number="$v.community.amount.$model"
                            @keypress="$validateDecimalInputNumber($event, $v.community.amount.$model)"
                            @blur="$v.community.amount.$touch()"
                            :disabled="community.percentage > 0 || !community.commision"
                          >
                            <template v-slot:prepend>
                              <span>{{ $countryConstants.currency }}</span>
                            </template>
                          </v-text-field>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </OutlinedCard>
              <div class="d-flex justify-end">
                <Button
                  white
                  :text="$t('actions.cancel')"
                  @clicked="$router.push('/communities')"
                  @end="resetButtonValues()"
                  class="mr-4"
                >
                </Button>

                <Button
                  :loading="createButton.loading"
                  :success="createButton.success"
                  :error="createButton.error"
                  :text="$t('actions.create')"
                  :successText="$t('actions.created')"
                  :errorText="'Error'"
                  :disabled="!canCreate"
                  @end="resetButtonValues()"
                  @clicked="checkStatus()"
                >
                </Button>
              </div>
            </v-col>
          </v-row>
        </template>
      </v-container>
      <SuccessMessage
        v-else
        :type="successMessage.type"
        :title="successMessage.title"
        :actionPrimary="successMessage.actionPrimary"
        :actionSecondary="successMessage.actionSecondary"
      />
    </v-container>
  </div>
</template>

<script>
  import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
  import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
  import {required} from 'vuelidate/lib/validators';
  import Button from '@/components/Button/Button';
  import IdentificationInput from '@/components/IdentificationInput/IdentificationInput'
  import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
  import { $communities, $benefits, $users } from '../Services'

  export default {
    name: 'CommunitiesCreate',
    components: {
      OutlinedCard,
      Breadcrumbs,
      Button,
      IdentificationInput,
      SuccessMessage
    },
    data: () => ({
      timeout: null,
      loadingCode: false,
      validRut: false,
      codeUnique: null,
      createStatus: false,
      insideDrawer: false,
      community: {
        name: '',
        identification: '',
        activity: '',
        status: true,
        commision: false,
        percentage: '',
        amount: '',
        token: '',
        benefit: { id: '' }
      },
      benefits: [],
      createButton: {
        loading: false,
        success: false,
        error: false
      },
      successMessage: {
        type: 'error',
        title: '',
        actionPrimary: null,
        actionSecondary: null
      },
    }),
    computed: {
      identificationMask () {
          return ['#######-X', '########-X'];
      },
      canCreateCommunity() {
        return this.$permissions.portaladministrativo.prices.communities.CREATE
      },
      canCreate() {
        let invalidFields = false;
        let validCommision = true;
        let unusedFields = false;

        const fields = [
          'name',
          'identification',
          'activity',
          'benefit'
        ];

        fields.forEach(item => {
          if(item === 'benefit'){
            if(!this.$v.community[item].id.$dirty) unusedFields = true;
            if(this.$v.community[item].id.$anyError) invalidFields = true;
          }
          else {
            if(!this.$v.community[item].$dirty) unusedFields = true;
            if(this.$v.community[item].$anyError) invalidFields = true;
          }
        });

        if(this.community.commision) {
          if(this.community.percentage <= 0 && this.community.amount <= 0)
            validCommision = false
        }

        return this.canCreateCommunity && !invalidFields && !unusedFields && validCommision && this.validRut && this.codeUnique;
      },
    },
    methods: {
      checkStatus() {
        if(this.community.status){
          this.createCommunity();
        }else{
          this.$store.dispatch('commons/openPopUp', {
            title: this.$t('modules.communities.create.warning_popup.title'),
            content: this.$t('modules.communities.create.warning_popup.content'),
            actionPrimary: {
              text: this.$t('modules.communities.create.warning_popup.create'),
              callback: this.createCommunity
            },
            actionSecondary: {
              text: this.$t('modules.communities.create.warning_popup.cancel'),
              callback() {}
            },
            icon: { color: 'warning', name: 'mdi-eye-off-outline' },
            color: 'primary',
          })
        }
      },
      async validateCode() {
          this.$v.community.identification.$touch();
          if (this.$v.community.identification.$model) {
              this.codeUnique = null;
              this.loadingCode = true;
              try {
                  const response = await $users.find('exists', null, {params: {identification: this.$v.community.identification.$model}});
                  this.codeUnique = !response.data.exists;
                  this.loadingCode = false;
              } catch (error) {
                  this.codeUnique = null;
                  throw error
              } finally {
                  this.$v.community.identification.$touch();
              }
          }
      },
      createCommunity() {
        if (!this.canCreateCommunity) return false
        this.createButton.loading = true;

        $communities.create(this.community)
          .then(() => {
            this.successMessage.type = 'success';
            this.successMessage.title = this.$t('modules.communities.create.success_message.title');
            this.successMessage.actionPrimary = { text: this.$t('modules.communities.create.success_message.actions.primary_text'), callback: () => { this.$router.push('/communities') } }
            this.successMessage.actionSecondary = { text: this.$t('modules.communities.create.success_message.actions.secondary_text'), callback: () => { this.createStatus = false } };

            this.createStatus = true;
            this.clearData();
            this.createButton.success = true;
          })
          .catch(() => {
            this.successMessage.type = 'error';
            this.successMessage.title = this.$t('modules.communities.create.error_message.title');
            this.successMessage.actionPrimary = { text: this.$t('modules.communities.create.success_message.actions.back'), callback: () => { this.resetButtonValues(); this.createStatus = false } }
            this.successMessage.actionSecondary = null
            this.createStatus = true;
            this.createButton.error = true;
          })
      },

      async getBenefits() {
        this.benefits = (await $benefits.find(null, null, {params: {page:0, length: 500, orderBy: 'id', orientation: 'desc'}})).data.content;
      },

      clearData() {
        this.resetButtonValues();
        this.$v.$reset();
        this.community = {
          name: '',
          identification: '',
          activity: '',
          status: true,
          commision: false,
          percentage: '',
          amount: '',
          benefit: { id: '' }
        };
      },

      resetButtonValues(){
        this.createButton.loading = false;
        this.createButton.success = false;
        this.createButton.error = false;
      }
    },
    validations: {
      community: {
        name: {required},
        identification: {
          required,
          unique () {
            return this.codeUnique !== false;
          },
        },
        activity: {required},
        percentage: {required},
        token: {}, 
        amount: {required},
        benefit: { id: {required} }
      },
    },
    mounted() {
      this.getBenefits();
    }
  }
</script>
