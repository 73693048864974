<template>
  <div class="payment-container" fluid @click="$emit('paymentSelected', payment)">
    <v-row class="payment-single mb-3">
      <v-col class="d-flex align-center" cols="12" sm="1">
        <img
          class="payment-logo"
          v-if="payment?.thumbnail"
          :alt="payment?.payment_type_id"
          :src="payment.thumbnail"
        >
        <v-icon v-else>{{ payment.icon || 'mdi-cash-usd-outline' }}</v-icon>
      </v-col>
      <v-col v-if="payment.id !== 'CHECK' && payment.id !== 'GETNET' && payment.id !== 'FINTOC'" class="d-flex align-center" cols="12" sm="3">
        <span class="payment-name">{{ payment.name }}</span>
      </v-col>
      <v-col v-if="payment.id === 'CHECK'" class="d-none d-sm-flex align-center" cols="12" sm="3">
        <img width="auto" height="40" :src="require('@/assets/mercadopagologo.png')" />
      </v-col>
      <v-col v-if="payment.id === 'FINTOC'" class="d-none d-sm-flex align-center" cols="12" sm="3">
        <img width="auto" height="25" :src="require('@/assets/fintoclogo.png')" />
      </v-col>
      <v-col v-if="payment.id !== 'GETNET' && payment.id !== 'SUS' " class="d-flex align-center" cols="12" sm="7">
        <div v-if="validateExtraBenefit && (validateExtraBenefitSemester ? extraBenefit?.percentageSemester > 0 : extraBenefit?.percentageBimester > 0) " class="benefits-extra-container d-flex align-center">
          {{ $t('modules.portalalumno.payments.paymentsStudents.banners.extraBenefit', {percentage: validateExtraBenefitSemester ? extraBenefit?.percentageSemester : extraBenefit?.percentageBimester}) }}
        </div>
      </v-col>
      <v-col v-if="payment.id === 'SUS'" class="d-flex align-center" cols="12" sm="7">
        <div class="benefits-extra-container no_discount-container d-flex align-center ">
          {{ $t('modules.portalalumno.payments.paymentsStudents.banners.no_discount') }}
        </div>
      </v-col>
      <v-col v-if="payment.id === 'GETNET'" class="d-none d-sm-flex align-center flex-wrap" cols="12" sm="10">
        <img width="auto" height="20" :src="require('@/assets/santanderlogo.png')" />
        <div v-if = "validateExtraBenefit && (validateExtraBenefitSemester ? extraBenefit?.percentageSemester > 0 : extraBenefit?.percentageBimester > 0)" class="benefits-extra-container d-flex align-center ml-2">
          {{ $t('modules.portalalumno.payments.paymentsStudents.banners.extraBenefit', {percentage: validateExtraBenefitSemester ? extraBenefit?.percentageSemester : extraBenefit?.percentageBimester}) }}
        </div>
        <div v-if="promotions.length" class="promotion-container d-flex flex-wrap">
          <div
            v-for="(promotion, index) in formattedPromotions"
            :key="index"
            class="promotion-text"
            v-html="promotion"
            :class="{ 'last-promotion': index === promotions.length - 1 }">
          </div>
        </div>
      </v-col>
      <v-col class="d-flex justify-end align-center" cols="12" sm="1">
        <v-icon class="chevron-icon">mdi-chevron-right</v-icon>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    payment: Object,
    extraBenefit: Object,
    validateExtraBenefit: Boolean,
    validateExtraBenefitSemester: Boolean,
  },
  computed: {
    formattedPromotions() {
      return this.promotions.map(promotion =>
        promotion
          .replace('3 CUOTAS', '<strong style="font-size: 14px">3 CUOTAS</strong>')
          .replace('6 CUOTAS', '<strong style="font-size: 14px">6 CUOTAS</strong>')
          .replace('12 CUOTAS', '<strong style="font-size: 14px">12 CUOTAS</strong>')
      );
    },
  },

  data() {
    return {
      promotions: [
        'JUEVES DE NOVIEMBRE\n3 CUOTAS \nsin interés',
        // 'SÁBADO 31 DE AGOSTO\n6 CUOTAS \nsin interés'
      ]
    }
  }
}
</script>

<style scoped lang="sass">
.payment-single
  min-height: 90px
  border-radius: 8px
  border: solid 1px #e0e0e0
  background-color: white
  margin-bottom: 16px
  padding: 16px
  transition: all 0.3s ease

  &:hover
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1)
    border-color: #0036b1
    cursor: pointer

.payment-logo
  max-width: 100%
  height: auto
  max-height: 32px

.payment-name
  font-family: 'DM Sans', sans-serif
  font-size: 1rem
  font-weight: bold
  color: #212121
  margin-right: 5px

.benefits-extra-container
  color: #4CAF50
  padding: 4px 8px
  background: #E8F5E9
  border-radius: 4px
  font-family: 'Roboto', sans-serif
  font-size: 0.80rem
  font-weight: 400
  white-space: nowrap

.no_discount-container
  color: #fe0808
  background: #ebb0b0

.chevron-icon
  color: #9E9E9E
  font-size: 1.25rem

.promotion-container
  display: flex
  justify-content: flex-start
  align-items: center
  text-align: center
  margin-left: auto

.promotion-text
  padding: 7px
  background: #EA1D25
  border-radius: 4px
  color: white
  font-family: Roboto
  margin-right: 2px
  font-size: 0.625rem
  font-weight: 400
  line-height: 1.2
  white-space: pre-line
  overflow: hidden
  width: 100%
  max-width: 160px
  height: 60px

.payment-container
  width: 100%
  padding: 0 10px

.arrow-container
  font-family: 'DM Sans'
  font-size: 20px
  font-weight: bold
  letter-spacing: -0.6px
  color: #212121

</style>
