<template>
    <v-container fluid class="d-flex pa-0 fullHeight">
        <AsideBar  :items="asidebar.items" :title="asidebar.title"  @changed="changed"></AsideBar>
        <v-container fluid class="scrollableContent pa-0">
            <v-container class="container-custom px-6">
            <Breadcrumbs
                :title="$t('modules.pricing.interest.table.breadcrumbs.title')"
                :description="$t('modules.pricing.interest.table.breadcrumbs.subtitle')"
            >
                <Button
                    v-if="canCreate"
                    @clicked="newInterest"
                    :icon="'mdi-plus'"
                    :text="$t('actions.new')"
                    depressed
                ></Button>
            </Breadcrumbs>
                <v-row justify="center">
                    <v-col sm="12" class="viewSpaces">
                        <SuperTable
                        :headers="headers"
                        :data="installments"
                        :clear-selected="clearSelected"
                        :total-items="totalItems"
                        :sortBy.sync="sort.sortBy"
                        :sortDesc.sync="sort.sortDesc"
                        @searchAction="searchAction"
                        @updateData="updateInterest"
                        :permissions="{update: canUpdate,  delete: canDelete}"
                        :page-count="pagination.pagesAmount"
                        :page="pagination.page"

                        :deleteTitle="$t('modules.pricing.interest.table.message.bulkDelete')"
                        @bulk-delete="bulkDeletePopUp"
                        @deleteData="deletePopUp"
                        @input-pagination="onInputPagination"
                        @length-pagination-change="onLengthPaginationChange"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </v-container>
    </v-container>
</template>

<script>
import SuperTable from '../Components/SuperTable';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import Button from '@/components/Button/Button';
import {$installments, $careers} from '../Services';
import AsideBar from '@/components/AsideBar/AsideBar'; 


const ASIDEBAR = {
  NOTES: {
    ID: 0
  },
  FROM_DATE: {
    ID: 1
  },
  TO_DATE: {
    ID: 2
  }
}

export default {
    name: 'CreditCardInterestTable',
    components: {
        SuperTable,
        Breadcrumbs,
        Button,
        AsideBar
    },
    data() {
        return {
            loading: false,
            search: null,
            page: 1,
            totalItems: null,
            clearSelected: null,
            installments: [],
            termsAdded: [],
            sort: {
                sortBy: 'id',
                sortDesc: false,
            },
            headers: [
                {
                text: this.$t('modules.pricing.interest.table.headers.code'),
                value: 'code',
                align: 'center'
                }, {
                text: this.$t('modules.pricing.interest.table.headers.installments'),
                value: 'installment',
                align: 'center'
                }, {
                text: this.$t('modules.pricing.interest.table.headers.percentage'),
                value: 'percentage',
                sortable: false,
                align: 'center'
                },
                {
                text: this.$t('modules.pricing.interest.table.headers.tea'),
                value: 'tea',
                sortable: false,
                align: 'center'
                },
                {
                text: this.$t('modules.pricing.interest.table.headers.cft'),
                value: 'cft',
                sortable: false,
                align: 'center'
                },
                {
                text: this.$t('modules.pricing.interest.table.headers.from'),
                value: 'from',
                align: 'center'
                },
                {
                text: this.$t('modules.pricing.interest.table.headers.to'),
                value: 'to',
                align: 'center'
                }, {
                text: this.$t('modules.pricing.interest.table.headers.status'),
                value: 'status',
                align: 'center'
                }, {
                text: '',
                value: 'action',
                sortable: false,
                align: 'center'
                },
            ],
            pagination: {
                limit: 20,
                page: 1,
                pagesAmount: 1
            },
            asidebar: {
                title: this.$t('modules.pricing.interest.table.filters.title'),
                items: [
                {
                    id: 'careerId',
                    name: this.$t('modules.pricing.interest.table.filters.career'),
                    filters: [
                    {
                        name: 'Carrera',
                        type: 'autocomplete',
                        clearable: true,
                        value: null,
                        items: [],
                    }
                    ],
                },
                {
                    name: this.$t('modules.pricing.interest.table.filters.student_type'),
                    filters: [
                    {
                        name: this.$t('modules.pricing.interest.table.filters.entrant'),
                        type: 'check',
                        value: true,
                        dataFiltered: '',
                       
                    },
                    {
                        name: this.$t('modules.pricing.interest.table.filters.reentrant'),
                        type: 'check',
                        value: true,
                        dataFiltered: '',
                        
                    },
                    ],
                },
                {
                    name: this.$t('modules.pricing.interest.table.filters.number_of_cuotes'),
                    filters: [
                    {
                        name: '3',
                        type: 'check',
                        value: true,
                        dataFiltered: '',
                        
                    },
                    {
                        name: '6',
                        type: 'check',
                        value: true,
                        dataFiltered: '',
                    
                    },
                    ]
                },
                {
                    id: 1,
                    name: this.$t('modules.pricing.interest.table.filters.from'),
                    filters: [
                    {
                        type: 'date',
                        name: this.$t('modules.pricing.interest.table.filters.from'),
                        value: null,
                        isCalendarOpen: false,
                    }]
                },
                {
                    id:2,
                    name: this.$t('modules.pricing.interest.table.filters.to'),
                    filters:[{
                        type: 'date',
                        name: this.$t('modules.pricing.interest.table.filters.to'),
                        value: null,
                        isCalendarOpen: false,
                    }]
                       
                }  
                ]
            }
        };
    },
    watch: {
        sort: {
            handler() {
                this.fetchData();
            },
            deep: true
        }
    },
    mounted() {
        this.fetchData();
        this.getFiltersData();
    },
    computed: {
        canCreate() {
            return this.$permissions.portaladministrativo.prices.interest.CREATE;
        },
        canUpdate() {
            return this.$permissions.portaladministrativo.prices.interest.READ || this.$permissions.portaladministrativo.prices.interest.UPDATE;
            },
        canDelete() {
            return this.$permissions.portaladministrativo.prices.interest.DELETE;
        },
        
    },
    methods: {
        bulkDeletePopUp(installments) {
            this.$store.dispatch('commons/openPopUp', {
                title: this.$t('delete_popup_title'),
                content: installments.map(installment => ({value: installment.code})),
                actionPrimary: {text: this.$t('actions.accept'), callback: () => this.bulkDelete(installments)},
                actionSecondary: {
                text: this.$t('actions.cancel'), callback() {
                }
                },
                icon: {color: 'error', name: 'mdi-delete'},
                color: 'primary',
            });
        },
        async bulkDelete(installments) {
            const itemsForDelete = {
                ids: installments.map((installment) => {
                return installment.id;
                })
            };
            try {
                await $installments.bulkDelete(itemsForDelete);
                this.fetchData();
            } catch (err) {
                this.$store.dispatch('commons/openPopUp', {
                title: this.$t('actions.error'),
                content: [{value: this.$t('modules.pricing.interest.table.errorMessage.bulkDeleteError')}],
                actionPrimary: {
                    text: this.$t('actions.accept'), callback() {
                    }
                },
                icon: {color: 'error', name: 'mdi-close'},
                color: 'primary',
                });
                throw err;
            }
            },
            deletePopUp(id) {
                    const installment = this.installments.find(installment => installment.id == id);
                    this.$store.dispatch('commons/openPopUp', {
                    title: this.$t('delete_popup_title'),
                    content: [{value: installment.code}],
                    actionPrimary: {text: this.$t('actions.delete'), callback: () => this.deleteInstallment(id)},
                    actionSecondary: {
                    text: this.$t('actions.cancel'), callback() {
                    }
                    },
                    icon: {color: 'error', name: 'mdi-delete'},
                    color: 'primary',
                });
            },
            async deleteInstallment(id) {
                try {
                    await $installments.delete(id);
                    this.installments = this.installments.filter(installment => installment.id !== id);
                } catch (error) {
                    this.$store.dispatch('commons/openPopUp', {
                    title: this.$t('actions.error'),
                    content: [{value: (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.pricing.interest.table.errorMessage.deleteError')}],
                    actionPrimary: {
                        text: this.$t('actions.accept'), callback() {
                        }
                    },
                    icon: {color: 'error', name: 'mdi-close'},
                    color: 'primary',
                    });
                    throw error;
                }
            },
            searchAction(data) {
                this.pagination.page = 1;
                this.search = data;
                this.fetchData();
            },
            newInterest() {
                if (this.canCreate) this.$router.push('/pricing/interest/create');
            },
            updateInterest(id) {
                if (this.canUpdate) this.$router.push(`/pricing/interest/update/${id}`);
            },
            
            onInputPagination(event) {
                this.pagination.page = event;
                this.fetchData();
            },
            onLengthPaginationChange(event) {
            this.pagination = {
                page: 1,
                limit: event,
                pagesAmount: 1
            };
            this.fetchData();
        },
        async fetchData() {
                if (this.loading) return false;
                this.clearSelected = new Date().getMilliseconds().toString();
                this.loading = true;
                try {
                    const params = {
                        page: this.pagination.page - 1,
                        length: this.pagination.limit,
                        orderBy: this.sort.sortBy,
                        orientation: this.sort.sortDesc ? 'desc' : 'asc',
                        search: this.search
                    };
                    const filterParams = {}
                    //careers
                    if (this.asidebar.items[0].filters[0].value) {
                        filterParams.careers = [this.asidebar.items[0].filters[0].value];
                    }

                    //studentType
                    if (this.asidebar.items[1].filters[1].value && this.asidebar.items[1].filters[0].value) {
                        filterParams.studentTypes = [{value:7},{value:8}];
                    }else{
                        if (this.asidebar.items[1].filters[0].value) {
                            if (!this.asidebar.items[1].filters[1].value) {
                                filterParams.studentTypes = [{value:7}];
                            }
                        }
            
                        if (this.asidebar.items[1].filters[1].value) {
                            if (!this.asidebar.items[1].filters[0].value) {
                                filterParams.studentTypes = [{value:8}];
                            }
                        }
                    }
                    //installments
                    if (this.asidebar.items[2].filters[1].value && this.asidebar.items[2].filters[0].value) {
                        filterParams.installment = [3,6];
                    }else{
                        if (this.asidebar.items[2].filters[0].value) {
                            if (!this.asidebar.items[2].filters[1].value) {
                                filterParams.installment = [3];
                            }
                        }
            
                        if (this.asidebar.items[2].filters[1].value) {
                            if (!this.asidebar.items[2].filters[0].value) {
                                filterParams.installment = [6];
                            }
                        }
                    }

                    //date from
                    const from = this.asidebar.items
                        .find((item) => item.id === ASIDEBAR.FROM_DATE.ID);
                    filterParams.from = from.filters[0].value ? from.filters[0].value : null;

                    //date to
                    const to = this.asidebar.items
                        .find((item) =>item.id === ASIDEBAR.TO_DATE.ID);
                    filterParams.to = to?.filters[0]?.value ? to?.filters[0].value : null;
                    
                    const installments = await $installments.filter(filterParams,  {params},'filter');
                    //para filtrar duplicados
                    const uniqueInstallments = Array.from(
                        new Set(installments.data.content.map(item => item.id))
                    ).map(id => {
                        return installments.data.content.find(item => item.id === id);
                    });
                    this.installments = uniqueInstallments;
                    this.totalItems = this.installments.length 
                    const totalPages = Math.ceil(this.totalItems / this.pagination.limit);
                    this.pagination.pagesAmount = Math.ceil(totalPages / this.pagination.limit);
                } catch (err) {
                    this.installments = [];
                    this.pagination.page = 1
                    this.pagination.pagesAmount = 1
                    this.totalItems = 0;
                    throw err;
                } finally {
                    this.loading = false;
                }
        },
        async getFiltersData(){
            const careers = await $careers.find(null, null, { params: { page: 0, length: 500, orderBy: 'id', orientation: 'desc', excludeStudyPlan: true } });
            this.asidebar.items[0].filters[0].items = careers.data.content.map(item => {
                return { value: item.id, text: item.description}
            })
            
            
        },
        changed(parent, filter, value) {
            this.asidebar.items[parent].filters[filter].value = value;
            this.fetchData();
        },
        
    }
    
}

</script>