<template>
  <v-dialog max-width="500"
    v-model="this.isActive"
    persistent>
    <v-card title="Dialog" class="py-10 px-5">
      <v-row justify="center" style="width: 100%;">
        <v-col class="circle mb-10" v-if="this.icon">
          <v-icon class="primary--text text--darken-2" large>{{ this.icon }}</v-icon>
        </v-col>
      </v-row>

      <v-card-text class="text-h6 font-weight-black black--text">
        {{ this.text }}
      </v-card-text>

      <v-card-text class="pl-10 pt-4 pb-10 body-1">
        {{ this.secondaryText }}
      </v-card-text>

      <v-card-actions style="width: 100%;" class="d-flex justify-space-around">
        <v-btn
          class="px-8"
          rounded
          text
          @click="buttonClicked('changeIsActive', false)"
        >{{ btnCancel }}</v-btn>

        <v-btn
          class="px-8"
          rounded
          color="primary darken-2"
          @click="buttonClicked('accept')"
        >{{ btnConfirm }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

    export default {
        name: 'BannerAlertMessage',
        props: {
            icon: { type: String, default: null },
            title: { type: String, default: null },
            text: { type: String, default: null },
            secondaryText: { type: String, default: null },
            btnConfirm: { type: String },
            btnCancel: { type: String },
            isActive: {type: Boolean, default: false}
        },
        methods: {
          buttonClicked(action, value) {
            this.$emit(action, value);
          },
        }
    }
</script>
<style lang="sass" scoped>
.circle
  padding: 5px
  border: 3px solid var(--v-primary-darken1)
  border-radius: 50%
  height: auto
  max-width: fit-content

</style>
