<template>
  <div>
    <v-row class="pl-8 pb-10">
        <v-icon class="pr-2 noteIcon">mdi-alert-circle-outline</v-icon>
        <h5 class="pt-1 noteText" v-text="note"></h5>
    </v-row>
    <p class="body-2 mb-10">{{ $t('modules.portalalumno.procedures.careerChangeStep3.description') }}</p>
    <div v-if="fileSource == ''" class="d-flex justify-center align-center mt-16">
      <v-progress-circular class="mx-auto" indeterminate color="primary"></v-progress-circular>
    </div>
    <DocumentPreview v-else :title="$t('modules.portalalumno.procedures.careerChangeStep3.dataSection.header')" :src="fileSource"></DocumentPreview>
    <div class="d-flex justify-end my-16">
      <Button
        white
        :text="$t('actions.return')"
        @clicked="$emit('goBack')"
        class="mr-4"
      ></Button>
      <Button
        :loading="createButton.loading"
        :success="createButton.success"
        :error="createButton.error"
        :text="$t('actions.confirm')"
        :successText="$t('actions.created')"
        :disabled="fileSource == ''"
        :errorText="'Error'"
        @end="resetButtonValues"
        @clicked="confirmContract"
      ></Button>
    </div>
  </div>
</template>

<script>
  import DocumentPreview from '@/components/DocumentPreview/DocumentPreview';
  import Button from '@/components/Button/Button';
  import { $studentProcessing, $students } from '../../services'
  import { mapGetters } from 'vuex'

  export default {
    name: 'ContractSign',
    props: {
      careerChange: Object,
    },
    components: {
      DocumentPreview,
      Button,
    },
    data () {
      return {
        fileSource: '',
        systemConfig: null,
        createButton: {
          loading: false,
          success: false,
          error: false
        },
        note: this.$t('modules.portalalumno.procedures.alert.note'),
      }
    },
    computed: {
      ...mapGetters({
        studentRegister: 'commons/getStudentActualRegister',
        studentData: 'commons/getStudentData',
        studentCareer: 'commons/getStudentCareer',
        studentId: 'commons/getStudentId',
        studentUserData: 'commons/getStudentUserData',
        userName: 'commons/getUserName',
        studentUserId: 'commons/getStudentUserID',
      }),
    },
    mounted () {
      this.fetchContract()
    },
    methods: {
      resetButtonValues () {
        this.createButton.loading = false;
        this.createButton.success = false;
        this.createButton.error = false;
      },
      async fetchContract() {
        try {
          if(this.systemConfig.contract){
              const params = {}
          if (this.studentData.benefit) params.benefit = this.studentData.benefit.id
          if (this.studentData.scholarship) params.scholarship = this.studentData.scholarship.id

          const findObject = {
            career: { id: this.careerChange.career.id, studyPlan: {id: this.careerChange.studyPlan.id} },
            paymentAmount: this.careerChange.price,
          }

          const file = await $students.create(findObject, { params }, `getContractChangeCareer/${this.studentRegister}`)
          this.fileSource = file.data
          }

        } catch (error) {
          this.fileSource = ''
          throw error
        }
      },
      async confirmContract () {
        try {
          this.createButton.loading = true
          await $studentProcessing.create({
            reason: this.careerChange.reason,
            userUpdateId: this.studentUserId ? this.studentUserId : null,
            careers: { value: this.careerChange.career },
            processingsReasonType: { value: this.careerChange.processingsReasonType },
            origin: this.studentCareer.id,
            destination: this.careerChange.career.id,
            studentRecord: this.studentRegister,
            student: { id: this.studentId, user: {name: this.studentUserData.email} },
            processing: { id: 15, key: 'CAREER_SWITCH'},
            studyPlan:{id: this.careerChange.studyPlan.id},
            userName: this.userName,
          })
          this.createButton.success = true
          this.$emit('nextStep')
        } catch (error) {
          this.createButton.error = true
          throw error
        } finally {
          this.createButton.loading = false
        }
      }
    },
  }
</script>

<style lang="sass" scoped>
  .noteIcon
    font-size: 20px
    color: v-bind('$vuetify.theme.defaults.light.primary.accent4')
  .noteText
    color: v-bind('$vuetify.theme.defaults.light.primary.accent4')
    font-weight: 410
</style>
