<template>
  <div>
    <v-container fluid class="d-flex pa-0 fullHeight">
      <v-container fluid class="px-0">
        <div class="px-md-10">
          <h4 class="primary--text mt-0" style="position: relative">
            {{ $t('modules.portalalumno.payments.paymentsStudents.view.labels.selectPayment') }}
            <span class="promotions">
              <a v-if="$country !== 'peru' && $country !== 'mexico'" href="javascript:void(0)" @click="showPromotionsClick">{{$t('modules.portalalumno.payments.paymentsStudents.view.labels.promotionsLink')}}</a>
              <PaymentPromotionsModal v-if="showPromotions" :modalOpen="showPromotions" @close="showPromotions = !showPromotions" />
            </span>
          </h4>

          <div class="mt-10">
            <div class="pt-0 pl-3 pr-3" v-for="(paymentMethod, i) in enableMethod" :key="`${paymentMethod.id}-${i}`">
              <div v-if="paymentMethod.id !== 'SUS' || showSubcription">
                <PaymentSelection
                :extraBenefit="extraBenefit"
                @paymentSelected="paymentSelected"
                :payment="paymentMethod"
                :validateExtraBenefit= "validateExtraBenefit"
                :validateExtraBenefitSemester = "validateExtraBenefitSemester"
              ></PaymentSelection>
              </div>
            </div>

            <div class="py-8 px-4 text-center" style="background-color: #efecec; border-radius: 5px;">
              <span class="body-1 font-weight-bold primary--text">{{ $t('modules.portalalumno.payments.paymentsStudents.view.labels.selectPaymentHelp') }}</span>
            </div>
          </div>
        </div>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import PaymentSelection from '../Components/PaymentSelection/PaymentSelection';
import PaymentPromotionsModal from '../Components/PaymentPromotionsModal/PaymentPromotionsModal'

export default {
  components: {
    PaymentSelection,
    PaymentPromotionsModal,
  },
  props: {
    isCheckoutPro: { type: Boolean, default: false },
    isGetnet: { type: Boolean, default: false },
    isFintoc: { type: Boolean, default: false },
    extraBenefit: Object,
    showSubcription: Boolean,
    validateExtraBenefit: Boolean,
    validateExtraBenefitSemester: Boolean,
  },

  computed: {
      enableMethod() {
        let filteredMethods = this.isCheckoutPro ? this.paymentMethods : this.paymentMethods.filter(method => method.type !== 'checkout');
        if(this.$country !== 'mexico') {
          filteredMethods = filteredMethods.filter(method => method.id !== 'ATM');
        }
        if (!this.isFintoc) {
          filteredMethods = filteredMethods.filter(method => method.id !== 'FINTOC');
        }
        if (!this.isGetnet) {
          filteredMethods = filteredMethods.filter(method => method.id !== 'GETNET');
        }
        return filteredMethods;
      },
  },

  data() {
    return {
      showPromotions: false,
      paymentMethods: [
        {
          id: 'GETNET',
          type: 'checkout',
          name: 'Getnet',
          icon: 'mdi-credit-card-multiple-outline',
          off: 0,
        },{
          id: 'TC1',
          type: 'credit_card',
          color: 'green',
          name: 'Tarjeta de crédito',
          icon: 'mdi-credit-card-outline',
          description: '',
          off: 0,
        }, {
          id: 'TC2',
          type: 'credit_card',
          color: 'green',
          name: '2 Tarjetas de crédito',
          icon: 'mdi-credit-card-multiple-outline',
          description: '',
          off: 0,
        }, {
          id: 'TD',
          type: 'bank_transfer',
          color: 'green',
          name: 'Tarjeta de débito',
          icon: 'mdi-credit-card-outline',
          description: '',
          off: 0,
        }, {
          id: 'FT',
          type: 'ticket',
          name: 'Efectivo',
          icon: 'mdi-cash-usd-outline',
          off: 0,
        },
        {
          id: 'ATM',
          type: 'atm',
          name: 'Pago de Servicios en Banca',
          icon: 'mdi-cash-usd-outline',
          off: 0,
        }, {
          id: 'CHECK',
          type: 'checkout',
          name: 'Mercado Pago',
          icon: 'mdi-credit-card-multiple-outline',
        }, {
          id: 'FINTOC',
          type: 'checkout',
          name: 'Fintoc',
          icon: 'mdi-credit-card-multiple-outline',
        },
        {
          id: 'GETNET',
          type: 'checkout',
          name: 'Getnet',
          icon: 'mdi-credit-card-multiple-outline',
          off: 0,
        }
      ],
    };
  },

  created() {
    if(this.$country === 'peru') {
      const peruCashMethod = {
        id: 'FT',
        type: 'atm',
        name: 'Efectivo',
        icon: 'mdi-cash-usd-outline',
        off: 0,
      }
      this.$set(this.paymentMethods,3, peruCashMethod)
    }
  },
    methods: {
    showPromotionsClick() {
      this.showPromotions = true
      this.$trackEvent('Pagos', 'Ver promociones', 'Método de pago')
    },
    paymentSelected(payment) {
      this.$emit('paymentSelected', payment);
      this.$trackEvent('Pagos', `Selección: ${payment.name}`, 'Método de pago')
    },
  },
};
</script>

<style scoped lang="sass">
  .promotions
    float: right
    font-size: 11px
    line-height: 24px
    vertical-align: middle
    font-weight: bold
</style>
