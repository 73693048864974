<template>
	<div>
		<v-container fluid class="d-flex pa-0 fullHeight" v-if="!selectedCards">
			<v-container fluid class="px-0">
				<div class="px-md-10">
					<div class="d-flex">
						<v-btn icon @click="$emit('goBackPayment')"><v-icon>mdi-chevron-left</v-icon></v-btn>
						<h4 class="d-flex align-center primary--text ml-2">{{
			$t('modules.portalalumno.payments.paymentsStudents.view.labels.creditcardMultiple') }}</h4>
					</div>
					<p class="body-2">{{
			$t('modules.portalalumno.payments.paymentsStudents.view.labels.creditcardMultipleDescription')
		}}</p>
					<v-container>
						<v-row v-for="(card, index) in resumen.cards" :key="index">
							<v-col cols="12" sm="4" lg="3">
								<span class="d-block subtitle-2 mt-2">{{
			$t('modules.portalalumno.payments.paymentsStudents.view.labels.creditCard') }} {{
			index + 1 }}</span>
							</v-col>
							<v-col cols="12" sm="5" md="6" lg="4">
								<v-text-field type="number" v-model.number="card.amount"
									:label="$t('modules.portalalumno.payments.paymentsStudents.view.labels.amount')"
									prepend-inner-icon="mdi-currency-usd"
									@keypress="$validateDecimalInputNumber($event, card.amount, total)"
									@keyup="adjustValue(index)" outlined></v-text-field>
							</v-col>
						</v-row>
					</v-container>
				</div>
			</v-container>
		</v-container>
		<div v-if="selectedCards">
			<div v-for="(card, index) in resumen.cards" :key="index" :id="index">
				<PaymentsSingleCardView v-if="index == activeCardIndex" :ref="index" :resumen="resumen"
					:cardIndex="index" :summaryButtons="summaryButtons" :selectedMethod="selectedMethod"
          :unselectedReenrollmentsAccountIds="unselectedReenrollmentsAccountIds"
					:pendingPayment="(index + 1) != resumen.cards.length" @paymentError="paymentError"

					@paymentProcessed="paymentProcessed" @cancelPaymentMethod="onCancelPaymentClick"
					@changeStep="nextStepChange" @goBackPayment="goBackToAmounts" @setDue="setDue">
				</PaymentsSingleCardView>
			</div>
		</div>
		<Feedback v-model="feedbackPopup.show" popup status="error" :title="feedbackPopup.title"
			:description="feedbackPopup.description" :message="feedbackPopup.message" :actions="feedbackPopup.actions">
		</Feedback>
	</div>
</template>

<style lang="sass" scoped>
  .input-amount ::v-deep .v-input__prepend-inner
    margin-top: 3px !important
    margin-right: 5px
</style>

<script>
import PaymentsSingleCardView from './PaymentsSingleCardView';
import Feedback from '@/components/Feedback/Feedback.vue'
import { $payments } from '../Services';

export default {
	props: {
		resumen: Object,
		summaryButtons: Object,
		selectedMethod: Object,
		isRepayment: [],
		countAnalytics: [],
    unselectedReenrollmentsAccountIds: { type: Array, default() { return [] } },
	},
	components: {
		PaymentsSingleCardView,
		Feedback
	},
	data() {
		return {
			selectedCards: false,
			creditCards: [],
			activeCardIndex: 0,
			total: 0,
			feedbackPopup: {
				show: false,
				title: '',
				description: '',
				message: '',
				actions: {
					primary: {
						label: this.$t('actions.goBack'),
						callback() { }
					}
				}
			}
		};
	},
	computed: {
		canContinue() {
			let subTotal = 0
			let empty = false
			this.resumen.cards.forEach(card => {
				if (card.amount) subTotal += this.$isTeclab ? parseFloat(card.amount) : card.amount
				else empty = true
			})
			return !empty && subTotal == this.total
		},
	},
	mounted() {
		this.total = this.$$isTeclab ? this.resumen.total.toFixed(2) : this.resumen.total.toFixed(0)
	},
	methods: {
		paymentProcessed(response) {
			this.resumen.cards[response.index].paymentId = response.paymentId
			if (++this.activeCardIndex == this.resumen.cards.length - 1) this.summaryButtons.primary.text = this.$t('modules.portalalumno.payments.paymentsStudents.view.labels.paymentLink')
			else this.summaryButtons.primary.text = this.$t('modules.portalalumno.payments.paymentsStudents.view.labels.continue')
		},
		paymentError() {
			if (this.activeCardIndex) {
				this.resumen.cards.forEach(async card => {
					if (card.paymentId) {
						await $payments.updatePayment(card.paymentId, 'cancel', { accountId: card.accountId, user_type: this.$isGestor ? 'ADVISOR' : 'STUDENT'})
						card.paymentId = null
					}
				})
				this.activeCardIndex = 0
			}
		},
		goBackToAmounts() {
			this.summaryButtons.primary.text = this.$t('modules.portalalumno.payments.paymentsStudents.view.labels.continue')
			this.summaryButtons.primary.disabled = !this.canContinue
			this.selectedCards = false
			this.$emit('setDue')
		},
		paymentCardsInfo() {
			if (this.selectedCards) {
				this.$refs[`${this.activeCardIndex}`][0].saveBillingData()

				// Google Analytics - BTN Pagar de pago con 2 Tarj.
				if (this.$isIPP) {
					if (this.isRepayment) {										//REPAYMENT
						if (this.countAnalytics == 1) {		//Intento 1° Tarj.
							this.$trackEvent('Pago', 'Clk_BtnNext2CR2_repac', 'Pago repactación')
							this.$emit('incrementCountAnalytics')
						} else if (this.countAnalytics == 2) {		//Intento 2° Tarj.
							this.$trackEvent('Pago', 'Clc_BtnPay2CR_repac', 'Pago repactación')
							this.$emit('resetCountAnalytics')
						}
					} else if (!this.isRepayment) {									// COMÚN
						if (this.countAnalytics == 1) {		//Intento 1° Tarj.
							this.$trackEvent('Pago', 'Clk_BtnNext2CR2_Pagos', 'Pago cuotas')
							this.$emit('incrementCountAnalytics')
						} else if (this.countAnalytics == 2) {		//Intento 2° Tarj.
							this.$trackEvent('Pago', 'Clc_BtnPay2CR_Pagos', 'Pago cuotas')
							this.$emit('resetCountAnalytics')
						}
					}
				} else if (this.$isTeclab) {
					if (this.countAnalytics == 1) {		//Intento 1° Tarj.
						this.$trackEvent('Pago', 'Clk_BtnNext2CR2_Pagos', 'PagoArancel')
						this.$emit('incrementCountAnalytics')
					} else if (this.countAnalytics == 2) {		//Intento 2° Tarj.
						this.$trackEvent('Pago', 'Clc_BtnPay2CR_Pagos', 'PagoArancel')
						this.$emit('resetCountAnalytics')
					}
				}
			} else {
				// Acaba de elegir el monto de cada tarjeta
				this.selectedCards = true;
				this.summaryButtons.primary.disabled = true

				// Google Analytics - BTN continuar al seleccionar monto para cada tarjeta
				if (this.$isIPP) {
					if (this.isRepayment) this.$trackEvent('Pago', 'Clk_BtnNext2CR1_repac', 'Pago repactación')
					if (!this.isRepayment) this.$trackEvent('Pago', 'Clk_BtnNext2CR1_Pagos', 'Pago cuotas')
				} else if (this.$isTeclab) {
					this.$trackEvent('Pago', 'Clk_BtnNext2CR1_Pagos', 'PagoArancel')
				}
				this.$emit('incrementCountAnalytics')
			}
		},
		nextStepChange(event) {
			// Entra aca cuando genero el segundo pago correctamente
			// Proceso con exito todos los pagos pendientes y emito changeStep
			this.resumen.cards.forEach(async card => {
				if (card.paymentId) {
					try {
						const { data } = await $payments.updatePayment(card.paymentId, 'capture', { accountId: card.accountId , user_type: this.$isGestor ? 'ADVISOR' : 'STUDENT'});
						// approved, in_process
						if (data && data.status === 'rejected') {
							const { status, status_detail } = data
							if (this.isPopupCodeError(status_detail)) {
								this.feedbackPopup.show = true
								this.feedbackPopup.title = this.$t(`modules.portalalumno.payments.paymentsStudents.gatewayMPFeedback.${status}.${status_detail}.title`, data)
								this.feedbackPopup.description = this.$t(`modules.portalalumno.payments.paymentsStudents.gatewayMPFeedback.${status}.${status_detail}.description`, data)
								this.feedbackPopup.message = this.$t(`modules.portalalumno.payments.paymentsStudents.gatewayMPFeedback.${status}.${status_detail}.message`, data)
								this.feedbackPopup.actions.primary.callback = (close) => {
									this.summaryButtons.primary.disabled = false
									close()
								}
							} else {
                if(event.accountKey) data.accountKey = event.accountKey
								this.$emit('changeStep', data);
							}
							this.summaryButtons.primary.error = true
							this.summaryButtons.primary.disabled = true
						}
            else {
              try {
                data.accountKey = event.accountKey || null
                if(!(data.status === 'approved' && event.status === 'approved')) {
                  throw null
                }
                data.status_detail = 'accredited_multiple'
                data.lastAmount = event.amount
              } catch {
                if(event.status === 'in_process'){
                  data.status = event.status
                  data.status_detail = event.status_detail
                }
              }
							this.$emit('changeStep', data, event)
							this.successLoading()
						}
					} catch (response) {
						this.errorLoading('¡Ups!', 'Se genero un error en el proceso de pago. Por favor, vuelva a intentarlo')
						this.summaryButtons.primary.error = true
						throw response
					} finally {
						this.summaryButtons.primary.loading = false
					}
				}
			})
		},
		successLoading() {
			this.summaryButtons.primary.loading = false
			this.summaryButtons.primary.success = true
			setTimeout(() => {
				this.summaryButtons.primary.success = false
				this.summaryButtons.primary.disabled = true
			}, 2500)
		},
		onCancelPaymentClick() {
			this.$emit('cancelPaymentMethod')
		},
		adjustValue(index) {
			let { total } = this
			this.resumen.cards.forEach((card, i) => {
				if (i == index && card.amount) total -= this.$isTeclab ? parseFloat(card.amount).toFixed(2) : card.amount
			});
			if (index == 0) this.resumen.cards[1].amount = this.$isTeclab ? parseFloat(total).toFixed(2) : total
			else this.resumen.cards[0].amount = this.$isTeclab ? parseFloat(total).toFixed(2) : total
		},
		setDue(due) {
			this.creditCards?.[this.activeCardIndex] ? this.creditCards[this.activeCardIndex] = due : this.creditCards.push(due)
			this.$emit('setDue', ...this.creditCards)
		},
		isPopupCodeError(status_detail) {
			const codes = [
				'cc_rejected_bad_filled_card_number',
				'cc_rejected_bad_filled_date',
				'cc_rejected_bad_filled_other',
				'cc_rejected_bad_filled_security_code',
        'cc_rejected_other_reason'
			]
			return codes.includes(status_detail);
		},
		errorLoading(title = null, description = null) {
			this.summaryButtons.primary.loading = false
			this.summaryButtons.primary.error = true
			if (title && description) {
				this.feedbackPopup.show = true
				this.feedbackPopup.title = title
				this.feedbackPopup.description = description
				this.feedbackPopup.message = ''
				this.feedbackPopup.actions.primary.callback = (close) => { this.$router.go(); close() }
			}
		},
	},
	watch: {
		canContinue(value) {
			this.summaryButtons.primary.disabled = !value
		},
		activeCardIndex :{
			handler( newValue){
				this.$emit('activeCardIndex', newValue)
			},
			immediate : true
		},
	}
}
</script>
