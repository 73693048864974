
const cartera = {
    title: 'Cartera',
    description: 'Este reporte muestra los pagos faltantes para determinado período sin contar las anulaciones.',
    detail: [
        { label: 'Archivo', value: 'Reporte cartera.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'El pago no debe estar ni cancelado ni anulado. El pago debe corresponder a un arancel, una repactación de deuda o un cobro administrativo por retiro.' },
        { label: 'Criterios', value: 'Muestra el porcentaje de pronto pago en el caso de que corresponda.' },
    ],
    example: {
        header: [
            'Año Deuda',
            'Año Deuda Descripción',
            'RUT',
            'Dig',
            'Apellido',
            'Nombre',
            'Per. Ingreso',
            'Per. Ingreso Descripción',
            'Fecha Ultimo Contrato (ex FEC_MAT)',
            'SCE',
            'SCE Descripción',
            'Código Carrera',
            'Nombre Carrera',
            'Estado Académico',
            'Estado Académico Descripción',
            'Estado Carrera',
            'Estado Carrera Descripción',
            'N Documento',
            'N Cuota',
            'Monto',
            'Estado Cuota',
            'Estado Cuota Descripción',
            'Fecha vencimiento',
            'diasvencido',
            'Porcentaje Beca',
            'Numero de Becas',
            'TERM_ID',
            'TERM_VALID_FROM',
            'Uso de ProntoPago',
            'Porcentaje de ProntoPago',
            'Legajo',
            'CELULAR',
            'TELEFONO',
            'EMAIL',
            'Dirección',
            'Ciudad',
            'Region',
        ],
        data: [
            [
                '123',
                'PRIMER BIMESTRE ACADÉMICO 2000',
                '12345678',
                '2',
                'NOMBRE',
                'PRUEBA',
                '210',
                'TERCER BIMESTRE ACADÉMICO 2000',
                '12345',
                '123',
                'CENTRO PRUEBA',
                '686',
                'CARRERA DE PRUEBA',
                '99',
                'Congelado',
                '57',
                'Anulado',
                '1234567',
                '2 de 5',
                '12345',
                '117',
                'Vencido',
                '44681',
                '180.0',
                '20',
                '1',
                '210',
                '308',
                'Vencido',
                '0',
                '2222LEGAJO',
                '123456789',
                '123456789',
                'prueba@gmail.com',
                'PRUEBA DIRECCIÓN 123',
                'Santiago',
                'Santiago',
            ],
            [
                '555',
                'PRIMER BIMESTRE ACADÉMICO 2000',
                '987654',
                '2',
                'NOMBRE',
                'PRUEBA',
                '210',
                'TERCER BIMESTRE ACADÉMICO 2000',
                '99999',
                '123',
                'CENTRO PRUEBA',
                '686',
                'CARRERA DE PRUEBA',
                '99',
                'Congelado',
                '57',
                'Anulado',
                '999999',
                '2 de 5',
                '12345',
                '117',
                'Vencido',
                '99999',
                '180.0',
                '20',
                '1',
                '210',
                '308',
                'Vencido',
                '0',
                '9999LEGAJO',
                '+5423456789',
                '',
                'probando@gmail.com',
                'PROBANDO DIRECCIÓN 123',
                'Santiago',
                'Santiago',
            ],
        ],
    },
}

const historialSubscripcionesCuotasEstados = {
    title: 'Detalle Pagos Suscriptores',
    description: 'Este reporte muestra los pagos realizados en cuotas.',
    detail: [
        { label: 'Archivo', value: 'Reporte Suscripciones.xlsx' },
        { label: 'Países', value: '<span class="country-chip argentina">Argentina</span>' },
        { label: 'Filtros', value: 'Pagos registrados como suscripción.' },
        { label: 'Criterios', value: 'Muestra gastos administrativos y pagos desdoblados.' },
    ],
    example: {
        header: [
            'Nombre',
            'Apellido',
            'Nro Doc',
            'Telefono',
            'Email',
            'Legajo',
            'Id Suscripcion',
            'Vencimiento',
            'Tipo cuota',
            'Estado cuota',
            'Monto cuota',
            'Cant cuotas',
            'Numero factura',
            'Forma de pago',
            'Tipo',
            'Cant Tickets',
            'Periodo',
            'Zona comercial',
            'Estado alumno',
            'Estado Suscripcion',
        ],
        data: [
            [
                'Alumno',
                'Prueba',
                '12345678',
                '+54 11 55555555',
                'prueba@hotmail.com',
                '1234LEGAJO',
                '12345',
                '2000-01-01',
                'cuota 1',
                'Cancelado',
                '1234.45789',
                '6',
                '12345',
                'pagofacil',
                'Reingresante',
                '2',
                '1A 2000',
                'Zona A',
                'Cursando',
                'Activo',
            ],
            [
                'Alumno',
                'Probando',
                '999999',
                '+54 11 545454',
                'probando@hotmail.com',
                '9999LEGAJO',
                '5555',
                '2000-01-01',
                'cuota 1',
                'Cancelado',
                '1234.45789',
                '6',
                '4444',
                'pagofacil',
                'Reingresante',
                '2',
                '1A 9999',
                'Zona A',
                'Cursando',
                'Activo',
            ],
        ],
    },
}

const pagosSinSubscripcion = {
    title: 'Detalle Pagos Sin Suscripción',
    description: 'Este reporte muestra los pagos realizados sin cuotas.',
    detail: [
        { label: 'Archivo', value: 'Reporte pagossinSubscripciones.xlsx' },
        { label: 'Países', value: '<span class="country-chip argentina">Argentina</span>' },
        { label: 'Filtros', value: 'Pagos registrados sin cuotas.' },
        { label: 'Criterios', value: 'Muestra medios de pago y fecha de aprobación.' },
    ],
    example: {
        header: [
            'Nombre',
            'Apellido',
            'Nro Doc',
            'Teléfono',
            'Email',
            'Legajo',
            'Monto',
            'Descuento',
            'Periodo',
            'Tipo',
            'Numero factura',
            'Forma de pago',
            'Articulo',
            'Cant tickets',
            'Tipo comprobante',
            'Zona comercial',
            'Fecha aprobacion',
        ],
        data: [
            [
                'ALUMNO',
                'PRUEBA',
                '12345678',
                '54354199999',
                'prueba@gmail.com',
                '1234LEGAJO',
                '12345.6',
                '12345.6',
                '1A 2000',
                'RI',
                '12345',
                'pago',
                'Arancel ',
                '1',
                'FB',
                'Zona C',
                '2000-01-01 00:32:26.0',
            ],
            [
                'ALUMNO',
                'PROBANDO',
                '87654321',
                '+543541111111',
                'probando@gmail.com',
                '5555LEGAJO',
                '12345.6',
                '12345.6',
                '1A 9999',
                'RI',
                '9999',
                'pago',
                'Arancel ',
                '1',
                'FB',
                'Zona C',
                '2000-01-01 00:32:26.0',
            ],
        ],
    },
}

const alumnoHistorialPagoMP = {
    title: 'Historial pagos Mercado Pago',
    description: 'Este reporte muestra el estado de cada pago realizado vía Mercado Pago.',
    detail: [
        { label: 'Archivo', value: 'Reporte alumnosHistorialPagoMP.xlsx' },
        { label: 'Países', value: '<span class="country-chip argentina">Argentina</span>' },
        { label: 'Filtros', value: 'La fecha de pago en Mercado Pago no debe tener más de 5 semanas de antiguedad.' },
        { label: 'Criterios', value: 'Muestra detalle de estado de pago, método de pago y monto.' },
    ],
    example: {
        header: [
            'Nombre',
            'Apellido',
            'Nro Doc',
            'Legajo',
            'Email',
            'Estado',
            'Estado detalle',
            'Fecha',
            'Método de pago',
            'Monto',
        ],
        data: [
            [
                'Alumno',
                'Prueba',
                '11111111',
                '0000LEGAJO',
                'prueba@gmail.com',
                'pending',
                'pending',
                '2000-11-10 00:52:53.881',
                'pago',
                '12345',
            ],
            [
                'Alumno',
                'Probando',
                '87654321',
                '0000LEGAJO',
                'probando@gmail.com',
                'pago',
                'pago',
                '2000-11-10 00:52:53.881',
                'pago',
                '8888',
            ],
        ],
    },
}

const dueAccounts = {
    title: 'Oficina Cobranzas',
    description: 'Este reporte muestra un listado de estudiantes que tienen mora por algún concepto para la Oficina de Cobranzas.',
    detail: [
        { label: 'Archivo', value: 'Reporte ganereteDueAccountsReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'Este reporte detalla los datos de los estudiantes en mora para cada período seleccionado.' },
        { label: 'Criterios', value: 'Muestra detalle de estado de pago, método de pago y monto.' },
    ],
    example: {
        header: [
            'RemoteId',
            'NOMBRE',
            'NationalId',
            'Email',
            'Celular',
            'ID',
            'Descripcion',
            'Moneda',
            'Remote-Id',
            'Descripcion',
            'Monto Capital',
            'Interes mora',
            'Dias de mora',
            'Detalle mora',
            'Vencimiento',
        ],
        data: [
            [
                '1234567-K',
                'ALUMNO PRUEBA',
                '1234567-K',
                'prueba@gmail.com',
                '+56 9 1234 1234',
                '123456',
                'Matricula ',
                'CLP',
                '1234567-K',
                'Cuota 1',
                '123456',
                '0',
                '11',
                '',
                '2000-09-30',
            ],
            [
                '55555-K',
                'ALUMNO PROBANDO',
                '55555-K',
                'probando@gmail.com',
                '+56 9 1234 1234',
                '55555',
                'Matricula ',
                'CLP',
                '555555-K',
                'Cuota 1',
                '555555',
                '0',
                '09',
                '',
                '9888-09-30',
            ],
        ],
    },
}

const facturacion = {
    title: 'Facturación',
    description: 'Este reporte muestra todas las facturas realizadas en un semestre. Incluye matrículas, aranceles, exámenes, etc.',
    detail: [
        { label: 'Archivo', value: 'Reporte facturacion.xlsx' },
        { label: 'Países', value: '<span class="country-chip argentina">Argentina</span><span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'El período debe ser de cursada y el pago no debe estar anulado.' },
        { label: 'Criterios', value: 'Si bien este reporte solicita un período al ejecutarse, busca los dos períodos del correspondiente cuatrimestre. La zona de facturación es actual, puede no corresponder a la que tenía el estudiante a la hora de matricularse.' },
    ],
    example: {
        header: [
            'DNI',
            'Tipo de alumno',
            'legajo',
            'Periodo Ingreso',
            'Zona Fact.',
            'carrera',
            'R19',
            'Facha de Pago',
            'Estado de Pago',
            'Fecha Venc Pago',
            'Cant cuotas',
            'N Cuota',
            'Periodo de Pago',
            'Articulo',
            'Monto Bruto',
            'Monto_Beca',
            'Monto Beneficio',
            'Factura',
            'Tipo factura',
            'Metodo de Pago',
        ],
        data: [
            [
                '1234567',
                'RI',
                'ABCDLEGAJO',
                '1A 2000',
                'Zona D',
                'Carrera Prueba',
                '',
                '2000-11-14 18:12:06.583',
                'Cancelado',
                '12345',
                '5',
                '1',
                '1A 2022',
                'Cuota',
                '12345',
                '0.0',
                '0.0',
                '12345',
                'FB',
                'pago',
            ],
            [
                '555555',
                'NI',
                '555LEGAJO',
                '1A 1955',
                'Zona A',
                'Carrera Probando',
                '',
                '2000-11-14 18:12:06.583',
                'Cancelado',
                '7777',
                '5',
                '1',
                '1A 2000',
                'Cuota',
                '7777',
                '0.0',
                '0.0',
                '9999',
                'FB',
                'pago',
            ],
        ],
    },
}

const discounts = {
    title: 'Descuentos',
    description: 'Este reporte muestra todas las becas y los beneficios aplicados en este período',
    detail: [
        { label: 'Archivo', value: 'Reporte generateDiscountsReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip argentina">Argentina</span>' },
        { label: 'Filtros', value: 'El estado del pago debe ser "cancelado".' },
        { label: 'Criterios', value: 'No se muestra el tipo de beca o beneficio aplicado.' },
    ],
    example: {
        header: [
            'Legajo',
            'Nombre',
            'Apellido',
            'DNI',
            'email',
            'Producto',
            'Periodo de pago',
            'Estado de pago',
            'Fecha de pago',
            'Monto a pagar',
            'Monto descuentos',
            'Monto Beca',
            'Monto Beneficio',
            'N de doc de pago',
        ],
        data: [
            [
                '1234LEGAJO',
                'Alumno',
                'Prueba',
                '1234567',
                'prueba@gmail.com',
                'Pago ',
                '1A 2000',
                'Cancelado',
                '30/11/2000',
                '12345.21',
                '13245.21',
                '0.0',
                '1234.21',
                '12345.21',
            ],
            [
                'ABCDLEGAJO',
                'Alumno',
                'PruebaProbando',
                '7654321',
                'probando@gmail.com',
                'Pago ',
                '1A 2000',
                'Cancelado',
                '30/11/2000',
                '54321.21',
                '54321.21',
                '0.0',
                '4321.21',
                '54321.21',
            ],
        ],
    },
}

const cancellations = {
    title: 'Anulaciones',
    description: 'Este reporte muestra los pagos faltantes anulados.',
    detail: [
        { label: 'Archivo', value: 'Reporte generateCancellationsReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'El estado del pago debe ser "anulado".' },
        { label: 'Criterios', value: 'Muestra el porcentaje de pronto pago en el caso de que corresponda.' },
    ],
    example: {
        header: [
            'Año Deuda',
            'Año Deuda Descripción',
            'RUT',
            'Dig',
            'Apellido',
            'Nombre',
            'Per. Ingreso',
            'Per. Ingreso Descripción',
            'Fecha Ultimo Contrato (ex FEC_MAT)',
            'SCE',
            'SCE Descripción',
            'Código Carrera',
            'Nombre Carrera',
            'Estado Académico',
            'Estado Académico Descripción',
            'Estado Carrera',
            'Estado Carrera Descripción',
            'N Documento de pago',
            'producto',
            'N Cuota',
            'Monto',
            'Estado Cuota',
            'Estado Cuota Descripción',
            'Fecha vencimiento',
            'diasvencido',
            'Porcentaje Beca',
            'Numero de Becas',
            'Uso de ProntoPago',
            'Porcentaje de ProntoPago',
            'Legajo',
            'CELULAR',
            'TELEFONO',
            'EMAIL',
            'Dirección',
            'Ciudad',
            'Region',
        ],
        data: [
            [
                '200',
                'PRIMER BIMESTRE ACADÉMICO 2000',
                '12345678',
                '1',
                'ALUMNO',
                'PRUEBA',
                '200',
                'TERCER BIMESTRE ACADÉMICO 2000',
                '01/1/2020',
                '200',
                'CENTRO PRUEBA',
                '200',
                'CARRERA PRUEBA',
                '200',
                'Anulado',
                '01',
                'Anulado',
                '123456',
                'Pago ',
                '1 de 1',
                '123456',
                '200',
                'Anulado',
                '23/1/2000',
                '1234',
                '01',
                '1',
                '',
                '0',
                '1234LEGAJO',
                '1234567',
                '',
                'prueba@hotmail.com',
                '',
                '',
                '                ',
            ],
            [
                '200',
                'PRIMER BIMESTRE ACADÉMICO 2000',
                '55555666',
                '1',
                'ALUMNO',
                'PROBANDO',
                '200',
                'TERCER BIMESTRE ACADÉMICO 2000',
                '01/1/2020',
                '200',
                'CENTRO PROBANDO',
                '200',
                'CARRERA PROBANDO',
                '200',
                'Anulado',
                '01',
                'Anulado',
                '55555',
                'Pago ',
                '1 de 1',
                '55555',
                '200',
                'Anulado',
                '23/1/2000',
                '55555',
                '01',
                '1',
                '',
                '0',
                '1234LEGAJO',
                '55555',
                '',
                'probando@hotmail.com',
                '',
                '',
                '                ',
            ],
        ],
    },
}

const payments = {
    title: 'Pagos completo',
    description: 'Este reporte muestra todos los pagos aprobados.',
    detail: [
        { label: 'Archivo', value: 'Reporte generatePaymentsReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip argentina">Argentina</span>' },
        { label: 'Filtros', value: 'El estado del pago debe ser "cancelado" y el estado de Mercado Pago debe ser "Aprobado".' },
        { label: 'Criterios', value: 'El tipo de alumno (RI/NI) depende si es el primer pago realizado o no.' },
    ],
    example: {
        header: [
            'Nombre',
            'Apellido',
            'DNI',
            'Legajo',
            'Tipo',
            'Intento de pago',
            'Periodo de pago',
            'Método de pago',
            'Tipo de Pago',
            'Mercado Pago ID',
            'Fecha de aprobación',
            'Mercado Pago Monto',
            'Monto de Cuenta',
            'Monto de Beca',
            'Monto de Beneficio',
            'Producto',
            'Monto de pago',
            'Fecha de pago',
            'Número de cuota',
            'Cantidad de cuotas',
            'N de doc de pago',
        ],
        data: [
            [
                'Alumno ',
                'Prueba',
                '1234567',
                '1234LEGAJO',
                'NI',
                '12345',
                '1B 2000',
                'PAGO',
                'credito',
                '123456789',
                '30/11/2000 12:54',
                '12345',
                '1234,6',
                '0',
                '0',
                'Pago',
                '12345',
                '30/11/2000 12:53',
                '1',
                '1',
                '123456',
            ],
            [
                'Alumno ',
                'Probando',
                '555555',
                '1234LEGAJO',
                'NI',
                '555555',
                '1B 2000',
                'PAGO',
                'credito',
                '555555444',
                '30/11/2000 12:54',
                '12345',
                '1234,6',
                '0',
                '0',
                'Pago',
                '555555',
                '30/11/2000 12:53',
                '1',
                '1',
                '555555',
            ],
        ],
    },
}

const tne = {
    title: 'TNE',
    description: 'Este reporte retorna un listado de alumnos que pagaron la Tarjeta Nacional de Estudiante (TNE) para presentar en la Junta Nacional de Auxilio Escolar y Becas JUNAEB.',
    detail: [
        { label: 'Archivo', value: 'Reporte generateTneReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'Todos los estudiantes que hayan pagado la TNE.' },
        { label: 'Criterios', value: 'Las primeras columnas de este reporte coinciden con el formato requerido por la JUNAEB en Chile para el envío de las TNE.' },
    ],
    example: {
        header: [
            'REGION',
            'SEDE',
            'CARRERA',
            'JORNADA',
            'RBD',
            'RUN',
            'DGV_RUN',
            'AP_PATERNO',
            'AP_MATERNO',
            'PRIMER NOMBRE',
            'SEGUNDO NOMBRE',
            'DIA_NACIMIENTO',
            'MES_NACIMIENTO',
            'ANO_NACIMIENTO',
            'EMAIL',
            'COD_AREA',
            'TELEFONO',
            'LEGAJO',
            'ESTADO_CARRERA',
            'MONTO',
            'FECHA_PAGO',
        ],
        data: [
            [
                '01',
                'PRUEBA',
                'CARRERA PRUEBA ',
                'PRUEBA',
                '123',
                '123456',
                '3',
                'PRUEBA',
                'PRUEBA',
                'ALUMNO',
                'ALUMNO',
                '3',
                '3',
                '1234',
                'prueba@hotmail.com',
                '',
                '+54123456789',
                '1234LEGAJO',
                'Cursando',
                '1234',
                '09/02/2000',
            ],
            [
                '01',
                'PROBANDO',
                'CARRERA PROBANDO ',
                'PROBANDO',
                '55555',
                '55555',
                '3',
                'PROBANDO',
                'PROBANDO',
                'ALUMNO',
                'ALUMNO',
                '3',
                '3',
                '55555',
                'probando@hotmail.com',
                '',
                '+5499999999',
                '55555',
                'Cursando',
                '55555',
                '09/02/2000',
            ],
        ],
    },
}

const studentAccount = {
    title: 'Pagos',
    description: 'Este reporte muestra los pagos vigentes de aranceles y exámenes de título de cada estudiante.',
    detail: [
        { label: 'Archivo', value: 'Reporte generateStudentAccountReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'El pago no tiene que estar ni "cancelado" ni "anulado" y el producto no debe ser ni Matricula ni TNE.' },
        { label: 'Criterios', value: 'El período es el que figura en el pago.' },
    ],
    example: {
        header: [
            'PERIODO DEUDA',
            'PERIODO COD DEUDA',
            'RUT',
            'DIG',
            'APELLIDOS',
            'NOMBRES',
            'PERIODO INGRESO',
            'PERIODO COD INGRESO',
            'FECHA MAT',
            'SCE',
            'SCE NOMBRE',
            'COD CARR',
            'NOMBRE CARRERA',
            'ESTADO ACADEMICO',
            'EST ACADEMICO',
            'ESTADO CARRERA',
            'EST CARRERA',
            'NUM_DOCUM',
            'NUM_CUOTA',
            'MONTO',
            'ESTADO CUOTA',
            'MEANING CUOTA',
            'FEC VENCIMIENTO',
            'DIAS VENCIDO',
            'PORCENTAJE BECA',
            'NUMERO BECAS',
            'TERM_ID',
            'TERM_VALID_FROM',
            'PRONTO_PAGO',
            'ID',
            'USER_DATA_CELLPHONE',
            'USER_DATA_PHONE',
            'USER_DATA_EMAIL',
            'ADDRESS',
            'CITY',
            'REGION',
        ],
        data: [
            [
                '123',
                'PRIMER BIMESTRE ACADÉMICO 2000',
                '12345678',
                '1',
                'PRUEBA',
                'ALUMNO ',
                '123',
                'PRIMER BIMESTRE ACADÉMICO 2000',
                '2000-12-26',
                '123',
                'CENTRO PRUEBA',
                '1234',
                'CARRERA PRUEBA',
                '01',
                'Vigente',
                '01',
                'Cursando',
                '123456',
                '1',
                '1234.5',
                '123',
                'Vigente',
                '2000-08-30',
                '-123',
                'null',
                'null',
                '123',
                '123',
                'NO',
                '1234LEGAJO',
                '+56 9 1234 1234',
                '+56 9 1234 1234',
                'prueba@gmail.com',
                'prueba  510',
                'null',
                '1234 - PRUEBA',
            ],
            [
                '999',
                'PRIMER BIMESTRE ACADÉMICO 2000',
                '999955555',
                '1',
                'PROBANDO',
                'ALUMNO ',
                '999',
                'PRIMER BIMESTRE ACADÉMICO 2000',
                '2000-12-26',
                '999',
                'CENTRO PROBANDO',
                '444',
                'CARRERA PROBANDO',
                '01',
                'Vigente',
                '01',
                'Cursando',
                '123456',
                '1',
                '9999.5',
                '999',
                'Vigente',
                '2000-08-30',
                '-123',
                'null',
                'null',
                '999',
                '999',
                'NO',
                '1234LEGAJO',
                '+56 9 1234 1234',
                '+56 9 1234 1234',
                'probando@gmail.com',
                'probando  510',
                'null',
                '1234 - PROBANDO',
            ],
        ],
    },
}

const scholarshipsAndBenefits = {
    title: 'Becas y Beneficios',
    description: 'Este reporte muestra las becas y los beneficios asignados a cada estudiante.',
    detail: [
        { label: 'Archivo', value: 'Reporte generateScholarshipsAndBenefitsReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip argentina">Argentina</span> <span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'Se muestran todas las becas y los beneficios asociados a cada alumno.' },
        { label: 'Criterios', value: 'Según si es beca o beneficio se muestran datos como fecha y período.' },
    ],
    example: {
        header: [
            'Nombre',
            'Apellido',
            'DNI / RUT',
            'Descuento',
            'Descripción',
            'Tipo',
            'Código',
            'Fecha otorgada',
            'Válida hasta',
            'Período',
            'Estado',
        ],
        data: [
            [
                'Alumno',
                'Prueba',
                '1234567',
                'beneficio',
                'Empresa prueba',
                'Empresa prueba',
                'ABCD',
                '29/10/2000',
                '',
                '',
                'Verdadero',
            ],
            [
                'Alumno',
                'Probando',
                '99999999',
                'beca',
                'Empresa probando',
                'Empresa probando',
                '1234',
                '29/10/2000',
                '',
                '',
                'Falso',
            ],
        ],
    },
}
// documentations[this.reports.service][this.reports.url]


export default { discounts, cartera, historialSubscripcionesCuotasEstados, pagosSinSubscripcion, alumnoHistorialPagoMP, dueAccounts, facturacion, cancellations, payments, tne, studentAccount, scholarshipsAndBenefits};
