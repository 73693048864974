<template>
    <div>
        <Banner v-if="$isTeclab && reenrollment.subjectQuota === false" type="warning" :title="$t('modules.portalalumno.payments.paymentsStudents.banners.no_subjects_quota')">
            <template v-slot:action>
                <v-btn outlined rounded class="mr-2" color="primary" @click="$router.push('/reglamento')">
                    {{ $t('modules.portalalumno.payments.paymentsStudents.banners.benefit.see_regulation') }}
                </v-btn>
            </template>
        </Banner>
        <template v-else>
            <TextBanner
                v-if="hasDebt"
                :title="$t('modules.portalalumno.payments.paymentsStudents.banners.debt.title')"
                :subtitle="$t('modules.portalalumno.payments.paymentsStudents.banners.debt.subtitle')"
            ></TextBanner>
            <template v-if="reenrollment.scholarship">
                <Banner v-if="reenrollment.scholarship.renew.status.value == 77" type="success" :title="$t('modules.portalalumno.payments.paymentsStudents.banners.scholarship_title')"></Banner>
                <Banner v-else type="warning" :title="$t('modules.portalalumno.payments.paymentsStudents.banners.benefit.no_renew')">
                    <template v-slot:action>
                        <v-btn outlined rounded class="mr-2" color="primary" @click="$router.push('/reglamento')">
                            {{ $t('modules.portalalumno.payments.paymentsStudents.banners.benefit.see_regulation') }}
                        </v-btn>
                    </template>
                </Banner>
            </template>
            <template v-if="reenrollment.benefit">
                <Banner
                    type="info"
                    v-if="!benefitStatus"
                    :title="$t('modules.portalalumno.payments.paymentsStudents.banners.benefit.want_renew', { benefit: reenrollment.benefit.description })">
                    <template v-slot:action>
                        <v-btn text rounded class="mr-2" color="#0088cc" @click="$emit('open-documentation')">
                            <v-icon class="mr-2" size="30px">mdi-upload</v-icon>
                            {{ $t('modules.portalalumno.payments.paymentsStudents.banners.benefit.upload_button') }}
                        </v-btn>
                    </template>
                </Banner>
                <Banner v-else-if="benefitStatus.pending" type="info" :title="$t('modules.portalalumno.payments.paymentsStudents.banners.benefit.must_approve')"></Banner>
                <Banner v-else-if="benefitStatus.denied" type="warning" :title="$t('modules.portalalumno.payments.paymentsStudents.banners.benefit.no_renew_benefit')"></Banner>
                <Banner v-else-if="benefitStatus.approved" type="success" :title="$t('modules.portalalumno.payments.paymentsStudents.banners.benefit.renewed')"></Banner>
            </template>
        </template>
    </div>
</template>

<script>
    import Banner from '@/components/Banner/Banner'
    import TextBanner from '@/components/Banner/TextBanner'

    export default {
        name: 'TeclabReenrollmentBanners',
        props: {
            reenrollment: { type: Object, default: null },
            hasDebt: { type: Boolean, default: false },
        },
        components: {
            Banner,
            TextBanner,
        },
        computed: {
            benefitStatus() {
                if (!this.reenrollment.benefit.statusProcessing && !!this.reenrollment.benefit.documentation.length) return null
                const statusValue = this.reenrollment.benefit.statusProcessing?.status.value
                if(statusValue){
                    return {
                        approved: statusValue == 93,
                        pending: statusValue == 91,
                        denied: statusValue == 92,
                    }
                }else{
                    //porque si el tramite viene nulo y sin documentacion se aplica el beneficio 
                    return {
                        approved: true,
                        pending: false,
                        denied: false,
                    }
                }
            }
        }
    }
</script>
