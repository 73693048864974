<template>
	<div>
		<v-navigation-drawer v-if="shouldShowDrawer" v-model="navigationDrawerApps.open" absolute right
			class="fill-height pa-4 navigationDrawer" temporary>
			<div class="d-flex justify-space-between pr-2">
				<v-btn tabindex="-1" icon @click.stop="openNavigationDrawer">
					<v-icon>mdi-close</v-icon>
				</v-btn>
				<div class="headline">{{ navigationDrawerApps.title }}</div>
			</div>
			<v-container fluid class="pa-2 mt-2">
				<v-row dense>
					<v-col sm="6" v-for="item in navigationDrawerApps.items" :key="item.title" v-show="showPanelIcon(item.title)">
						<v-card outlined class="text-center navigationDrawer__app" >
							<!-- <a :href="item.path" target="_blank"> -->
								<v-card-text class="navigationCardText__app" @click="redirectToPortal(item.path)">
								<v-avatar dark color="grey lighten-3" size="48">
									<v-icon>{{ item.imagen }}</v-icon>
									<!--<span class="white&#45;&#45;text headline"></span>-->
								</v-avatar>
								<div class="caption font-weight-bold pt-2">{{ item.title }}</div>
							</v-card-text>
							<!-- </a> -->
						</v-card>
					</v-col>
				</v-row>
			</v-container>
		</v-navigation-drawer>
	</div>
</template>


<script>
import jwt from 'jsonwebtoken';

export default {
	name: 'NavigationDrawer',
	props: {
		navigationDrawerApps: {

			type: Object,
			default: () => ({
				open: false,
				title: 'Applications',
				items: []
			})
		},
		userDataRoles: Array,
	},
	data() {
		return {
			user: null,
			shouldShowDrawer: false,
		};
	},
	methods: {
		openNavigationDrawer() {
			this.navigationDrawerApps.open = !this.navigationDrawerApps.open;
		},
		fetchUserData() {
			try {
				const token = JSON.parse(localStorage.getItem('access_token'));
				const decodedToken = jwt.decode(token);
				const isAdviser = decodedToken?.isAdvisor;

				this.processUserData(isAdviser);
			} catch (e) {
				return e;
			}
		},
		processUserData(isAdviser) {
			if (!isAdviser) {
				this.shouldShowDrawer = false;
				this.$emit('update:shouldShowDrawer', false);
				return;
			}
			if (!this.userDataRoles || !this.userDataRoles.length) {				
				this.shouldShowDrawer = false;
				this.$emit('update:shouldShowDrawer', false);
				return;
			}
			const accessibleAppsIds = new Set();
			const accessibleApps = [];

			if (this.userDataRoles.length > 0) {
				this.userDataRoles.forEach(role => {
					if (role.application && Array.isArray(role.application)) {
						role.application.forEach(app => {
							if (!accessibleAppsIds.has(app.id) && !app.name.normalize('NFD').replace(/[\u0300-\u036f]/g,'').toLowerCase().includes('examenes')) {
								accessibleAppsIds.add(app.id);
								accessibleApps.push(app);
							}
						})
					}});
				}

			if (accessibleApps.length <= 1) {
				this.shouldShowDrawer = false;
				return;
			}

			this.shouldShowDrawer = true;
			this.$emit('update:shouldShowDrawer', true);

			this.navigationDrawerApps.items = Array.from(accessibleApps).map(app => {
				return {
					title: app.name,
					imagen: app.icon || 'mdi-folder',
					path: app.domain
				};
			});
		},
		openApplication(url) {
			window.open(url, '_blank');
		},
		initializeData() {
			this.fetchUserData();
		},
		redirectToPortal(redirect) {
			redirect = `${redirect}/login-sso`;
			let redirect_path = null;
			try {
				const params = {
					access_token: null, 
					refresh_token: null, 
					expires_in: null,
					url: null
				};
				params.access_token = JSON.parse(localStorage.getItem('access_token'));
				params.refresh_token = (JSON.parse(localStorage.getItem('refresh_data'))).token;
				params.expires_in = (JSON.parse(localStorage.getItem('refresh_data'))).expires;

				if (redirect.includes('localhost')) {
					redirect_path = new URL(`http://${redirect}`);
				} else {
					redirect_path = new URL(`https://${redirect}`);
				}

				Object.keys(params).forEach(key => {
					redirect_path.searchParams.append(key, params[key]);
				});
				
				window.open(redirect_path, '_blank');
				
			} catch (e) {
				return e;
			}
		},
		showPanelIcon(portal){
			const [portalName] = portal.split(' ', 1);
			const showIcon = !this.$APP.toLowerCase().replaceAll(' ','').includes(portalName.toLowerCase());
			return showIcon
		}
	},
	mounted() {
		this.initializeData();
	}
};
</script>



<style scoped lang="sass">
.navigationDrawer
  border-radius: 0 !important
  height: 100vh !important
  width: 304px !important

.navigationCardText
  cursor: pointer
  &__app
    cursor: pointer
    &:hover
      background-color: #0056b3

</style>
