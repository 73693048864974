<template>
  <div>
    <v-container fluid class="pa-0 pb-8">
      <v-container class="container-custom" v-if="!updateDiscountStatus">
        <Breadcrumbs
          :title="$t('modules.discounts.update.breadcrumbs.title')"
          :description="$t('modules.discounts.update.breadcrumbs.description')"
        ></Breadcrumbs>
        <template>
          <v-row justify="center">
            <v-col class="viewSpaces" sm="12">
              <OutlineCard
                :title="$t('modules.discounts.update.section_name.title')"
                :switchLabel="($v.status.$model) ? $t('modules.discounts.enabled') : $t('modules.discounts.disabled')"
                :switchValue="$v.status.$model"
                @switchChange="() => {this.$v.status.$model = !this.$v.status.$model; this.$v.status.$touch()}"
                switcher
                class="mb-6"
              >
                <v-row>
                  <v-col sm="3" class="py-0">
                    <span
                      class="caption text-uppercase"
                    >{{ `${$t('modules.discounts.update.labels.name')} *` }}</span>
                  </v-col>
                  <v-col sm="6" class="py-0">
                    <v-text-field
                      :error-messages="($v.description.$dirty && $v.description.$invalid) ? $t('modules.discounts.update.validation_errors.name') : ''"
                      @blur="$v.description.$touch()"
                      v-model="$v.description.$model"
                      :placeholder="`${$t('modules.discounts.update.labels.name')} *`"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="3" class="py-4">
                    <span
                      class="caption text-uppercase"
                    >{{ $t('modules.discounts.update.labels.code') }}</span>
                  </v-col>
                  <v-col sm="6" class="py-4">
                    <span>{{ code }}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="3" class="py-4">
                    <span
                      class="caption text-uppercase"
                    >{{ $t('modules.discounts.update.labels.studentType') }}</span>
                  </v-col>
                  <v-col sm="6" class="py-4">
                    <span>{{ selectedStudentTypes }}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="3" class="py-4">
                    <span
                      class="caption text-uppercase"
                    >{{ $t('modules.discounts.update.labels.modality') }}</span>
                  </v-col>
                  <v-col sm="6" class="py-4">
                    <span>{{ selectedModalityTypes }}</span>
                  </v-col>
                </v-row>
              </OutlineCard>
              <OutlineCard
                :title="$t('modules.discounts.update.section_career.title')"
                :subtitle="$t('modules.discounts.update.section_career.subtitle')"
                class="mb-6"
              >
                <TransferList
                  v-if="filteredCareers.length || $v.selectedCareers.$model.length"
                  :addedItems="$v.selectedCareers.$model"
                  areEquals="id"
                  :availableItems="filteredCareers"
                  @addItems="(arr) => { this.$v.selectedCareers.$model = arr }"
                  @removeItems="(arr) => { this.filteredCareers = arr }"
                  :firstListTitle="$t('modules.discounts.update.section_career.transferList.firstTitle')"
                  :secondListTitle="$t('modules.discounts.update.section_career.transferList.secondTitle')"
                ></TransferList>
                <v-alert
                  text
                  type="warning"
                  class="mt-4"
                  v-if="$v.selectedCareers.$dirty && $v.selectedCareers.$anyError"
                >{{ $t('modules.discounts.update.section_career.no_selected_warning') }}</v-alert>
              </OutlineCard>
              <OutlineCard :title="$t('modules.discounts.update.section_zone.title')" class="mb-6">
                <MultipleFormGroup
                  :items="$v.groups.$each.$iter"
                  :length="$v.groups.$model.length"
                  @removeGroup="removeGroup"
                  @addGroup="addGroup"
                >
                  <template v-slot:default="{item, index}">
                    <v-container fluid>
                      <v-row>
                        <v-col sm="3">
                          <v-select
                            :error-messages="(item.product.$dirty && item.product.$invalid) ? 'Error' : ''"
                            :label="`${$t('modules.discounts.update.section_zone.items.product')} *`"
                            :items="billingProducts"
                            item-text="name"
                            @blur="item.product.$touch()"
                            outlined
                            return-object
                            class="no-message"
                            v-model="item.product.$model"
                          ></v-select>
                        </v-col>
                        <v-col sm="6">
                          <v-select
                            :error-messages="(item.zone.$dirty && item.zone.$invalid) ? 'Error' : ''"
                            :label="`${$t('modules.discounts.update.section_zone.items.zone')} *`"
                            :items="zones"
                            item-text="zoneDescription"
                            outlined
                            return-object
                            class="no-message"
                            v-model="item.zone.$model"
                            @blur="item.zone.$touch()"
                          ></v-select>
                        </v-col>
                        <v-col sm="3">
                          <v-menu
                            :ref="`${index}-menu_from`"
                            v-model="item.$model.menu_from"
                            :close-on-content-click="false"
                            :return-value.sync="item.from.$model"
                            transition="scale-transition"
                            offset-y
                            bottom
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                :error-messages="item.from.$invalid && item.from.$dirty ? 'Error' : ''"
                                v-model="item.from.$model"
                                :label="`${$t('modules.discounts.update.section_zone.items.from')} *`"
                                append-icon="mdi-calendar"
                                readonly
                                outlined
                                v-on="on"
                                class="no-message"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="item.from.$model"
                              no-title
                              color="primary"
                              scrollable
                              :min="new Date().toISOString().substr(0, 10)"
                              :max="item.to.$model"
                            >
                              <v-spacer></v-spacer>
                              <v-btn text color="primary" @click="item.$model.menu_from = false">Cancelar</v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="$refs[`${index}-menu_from`].save(item.from.$model)"
                              >OK</v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col sm="3" class="pb-0">
                          <v-select
                            :error-messages="(item.discountType.$dirty && item.discountType.$invalid) ? 'Error' : ''"
                            :label="`${$t('modules.discounts.update.section_zone.items.discountType')} *`"
                            :items="$t('modules.discounts.update.section_zone.discountTypes')"
                            outlined
                            class="no-message"
                            return-object
                            v-model.number="item.discountType.$model"
                            @blur="item.discountType.$touch()"
                          ></v-select>
                        </v-col>
                        <v-col sm="3" class="pb-0">
                          <v-text-field
                            :error-messages="(item.semester.$invalid && item.semester.$dirty) ? 'Error' : ''"
                            :label="`${$t('modules.discounts.update.section_zone.items.semester')} *`"
                            :input="item.semester"
                            :prepend-inner-icon="(item.discountType.$model.value === 1) ? 'mdi-currency-usd' : ((item.discountType.$model.value === 2) ? 'mdi-percent' : '')"
                            type="number"
                            outlined
                            :min="0"
                            :max="(item.discountType.$model.value === 1) ? null : 100"
                            @keypress="$validateDecimalInputNumber($event, item.semester.$model, (item.discountType.$model.value === 1) ? null : 100)"
                            v-model.number="item.semester.$model"
                            @blur="item.semester.$touch()"
                            class="no-message"
                          ></v-text-field>
                        </v-col>
                        <v-col sm="3" class="pb-0">
                          <v-text-field
                            :error-messages="(item.bimester.$invalid && item.bimester.$dirty) ? 'Error' : ''"
                            :label="`${$t('modules.discounts.update.section_zone.items.bimester')}`"
                            :input="item.bimester"
                            :prepend-inner-icon="(item.discountType.$model.value === 1) ? 'mdi-currency-usd' : ((item.discountType.$model.value === 2) ? 'mdi-percent' : '')"
                            type="number"
                            outlined
                            :min="0"
                            :max="(item.discountType.$model.value === 1) ? null : 100"
                            @keypress="$validateDecimalInputNumber($event, item.bimester.$model, (item.discountType.$model.value === 1) ? null : 100)"
                            class="no-message"
                            v-model.number="item.bimester.$model"
                            @blur="item.bimester.$touch()"
                          ></v-text-field>
                        </v-col>
                        <v-col sm="3">
                          <v-menu
                            :ref="`${index}-menu_to`"
                            v-model="item.$model.menu_to"
                            :close-on-content-click="false"
                            :return-value.sync="item.to.$model"
                            transition="scale-transition"
                            offset-y
                            bottom
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                :error-messages="item.to.$invalid && item.to.$dirty ? 'Error' : ''"
                                v-model="item.to.$model"
                                :label="`${$t('modules.discounts.update.section_zone.items.to')} *`"
                                append-icon="mdi-calendar"
                                readonly
                                outlined
                                v-on="on"
                                class="no-message"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="item.to.$model"
                              no-title
                              color="primary"
                              scrollable
                              :min="item.from.$model"
                            >
                              <v-spacer></v-spacer>
                              <v-btn text color="primary" @click="item.$model.menu_to = false">Cancelar</v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="$refs[`${index}-menu_to`].save(item.to.$model)"
                              >OK</v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-container>
                  </template>
                </MultipleFormGroup>
                <v-divider class="mb-4"></v-divider>
                <div class="d-flex justify-end" v-if="canCreateZones">
                  <Button
                    class="mt-4"
                    outlined
                    depressed
                    :text="$t('modules.discounts.update.section_zone.button')"
                    @clicked="tmpDrawerOpen"
                  ></Button>
                </div>
              </OutlineCard>
              <OutlineCard
                :title="$t('modules.discounts.update.section_restriction.title')"
                class="mb-6"
              >
                <v-radio-group v-model="limited" class="no-message ma-0">
                  <v-radio
                    :label="$t('modules.discounts.update.section_restriction.items.radioFirst')"
                    color="primary"
                    :value="0"
                  ></v-radio>
                  <div class="d-flex">
                    <v-radio
                      :label="$t('modules.discounts.update.section_restriction.items.radioSecond')"
                      color="primary"
                      :value="1"
                    ></v-radio>
                    <v-text-field
                      :error-messages="($v.limit.$invalid && $v.limit.$dirty) ? 'Error' : ''"
                      outlined
                      v-model.number="$v.limit.$model"
                      type="number"
                      placeholder="0"
                      min="0"
                      max="999"
                      @blur="$v.limit.$touch()"
                      @keypress="$validateIntegerInputNumber($event, $v.limit.$model, null, 3)"
                      class="no-message"
                      :disabled="!limited"
                    ></v-text-field>
                  </div>
                </v-radio-group>
              </OutlineCard>
              <div class="d-flex justify-end">
                <Button
                  white
                  :text="$t('actions.cancel')"
                  @clicked="$router.replace('/discounts')"
                  @end="resetButtonValues"
                  class="mr-4"
                ></Button>

                <Button
                  :loading="saveButton.loading"
                  :success="saveButton.success"
                  :error="saveButton.error"
                  :text="$t('actions.save')"
                  :successText="$t('actions.saved')"
                  :errorText="'Error'"
                  :disabled="!canUpdate || $v.formValid.$anyError"
                  @end="resetButtonValues"
                  @clicked="updateDiscountPopUp"
                ></Button>
              </div>
            </v-col>
          </v-row>
        </template>
      </v-container>
      <SuccessMessage
        v-else
        :type="successMessage.type"
        :title="successMessage.title"
        :actionPrimary="successMessage.actionPrimary"
      />
      <TemporaryRightDrawer
        v-if="canCreateZones"
        @tmpDrawerOpen="tmpDrawerOpen"
        :open="tmpDrawer.open"
        :title="tmpDrawer.title"
        :description="tmpDrawer.description"
      >
        <ZonesCreate @closeDrawer="onCloseDrawer" :insideDrawer="true"></ZonesCreate>
      </TemporaryRightDrawer>
    </v-container>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import OutlineCard from '@/components/OutlinedCard/OutlinedCard';
import TransferList from '@/components/TransferList/TransferList';
import MultipleFormGroup from '@/components/MultipleFormGroup/MultipleFormGroup';
import Button from '@/components/Button/Button';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import TemporaryRightDrawer from '@/components/TemporaryRightDrawer/TemporaryRightDrawer'
import ZonesCreate from '../../Zones/views/ZonesCreate'
import { required, minValue, decimal, requiredIf } from 'vuelidate/lib/validators';
import { $discounts } from '../Services';
import commons from '../mixins/commons';
import { mapGetters } from 'vuex';

const arrayNotEmpty = (array) => {
  return array.length
};
export default {
  props: {
    systemConfig: Object,
  },
  name: 'DiscountsUpdate',
  mixins: [commons],
  components: {
    Breadcrumbs,
    OutlineCard,
    TransferList,
    MultipleFormGroup,
    Button,
    SuccessMessage,
    TemporaryRightDrawer,
    ZonesCreate
  },
  data () {
    return {
      updateDiscountStatus: false,
      saveButton: {
        loading: false,
        success: false,
        error: false
      },
      successMessage: {
        type: 'success',
        title: this.$t('modules.discounts.update.success_message.title'),
        actionPrimary: { text: this.$t('modules.discounts.update.success_message.actions.primary_text'), callback: () => { this.$router.push('/discounts') } }
      },
      limited: 0,
      limit: null,
      groups: [
        {
          product: {},
          zone: {},
          discountType: { value: 1 },
          semester: '',
          bimester: '',
          menu_from: false,
          menu_to: false,
          from: new Date().toISOString().substr(0, 10),
          to: null,
        }
      ],
      // SELECTED DATA
      description: null,
      code: null,
      status: true,
      studentType: [],
      modalityType: [],
      selectedModalityTypes: '',
      selectedStudentTypes: '',
      selectedCareers: [],
      selectedBillingProduct: [],
      selectedZone: [],
    }
  },
  validations: {
    status: { required },
    description: { required },
    selectedCareers: { arrayNotEmpty },
    limit: {
      decimal,
      minValue: minValue(0),
      required: requiredIf(function () {
        return this.limited
      })
    },
    groups: {
      $each:
      {
        product: { required },
        zone: { required },
        discountType: { required },
        from: { required },
        to: { required },
        semester: {
          required,
          decimal,
          minValue: minValue(0),
        },
        bimester: {
          decimal,
          minValue: minValue(0),
        },
      }
    },
    formValid: ['status', 'description', 'selectedCareers', 'limit', 'groups']
  },
  computed: {
    canUpdate () {
      return this.$permissions.portaladministrativo.prices.discounts.UPDATE
    },
    canCreateZones() {
      return this.$permissions.portaladministrativo.prices.zones.CREATE
    },
    ...mapGetters({userId: 'commons/getUserID'})
  },
  methods: {
    tmpDrawerOpen () {
      this.tmpDrawer.open = !this.tmpDrawer.open;
      if (!this.tmpDrawer.open) this.fetchZones();
    },
    removeGroup (index) {
      this.groups.splice(index, 1)
    },
    addGroup () {
      const mockup = {
        product: {},
        zone: {},
        discountType: { value: 1 },
        semester: '',
        bimester: '',
        menu_from: false,
        menu_to: false,
        from: new Date().toISOString().substr(0, 10),
        to: null,
      };
      this.groups.push({
        ...mockup
      });
    },
    updateDiscountPopUp () {
      if (!this.status) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('alert'),
          content: [{ value: this.$t('disabledMessage') }],
          actionPrimary: { text: this.$t('actions.accept'), callback: () => this.updateDiscount() },
          actionSecondary: { text: this.$t('actions.cancel'), callback () { } },
          icon: { color: 'warning', name: 'mdi-alert' },
          color: 'primary',
        });
      } else {
        this.updateDiscount();
      }
    },
    async updateDiscount () {
      if (!this.canUpdate) return false
      this.saveButton.loading = true;
      const details = [];

      this.groups.forEach(group => {
        let biannualPercentage = null;
        let bimesterPercentage = null;
        let biannualAmount = null;
        let bimesterAmount = null;

        if (group.discountType.value === 1) {
          biannualAmount = parseFloat(group.semester, 10);
          bimesterAmount = parseFloat(group.bimester, 10);
        } else {
          biannualPercentage = parseFloat(group.semester, 10);
          bimesterPercentage = parseFloat(group.bimester, 10);
        }

        details.push({
          billingProduct: { id: group.product.id },
          zone: { id: group.zone.id },
          biannualPercentage,
          bimesterPercentage,
          biannualAmount,
          bimesterAmount,
          from: group.from,
          to: group.to,
        });
      });

      const careers = [];
      this.selectedCareers.forEach(career => {
        careers.push({ id: career.id });
      });

      const updatedDiscount = {
        description: this.description,
        status: this.status,
        limit: (this.limited) ? this.limit : null,
        code: this.code,
        studentType: this.studentType,
        modalityType: this.modalityType,
        careers,
        details,
        userId: this.userId,
      };

      try {
        await $discounts.update(this.$route.params.id, updatedDiscount)
        this.successMessage.type = 'success',
        this.successMessage.title = this.$t('modules.discounts.update.success_message.title'),
        this.successMessage.actionPrimary = { text: this.$t('modules.discounts.update.success_message.actions.primary_text'), callback: () => { this.$router.push('/discounts') } }
        this.updateDiscountStatus = true;
        this.saveButton.success = true;
      } catch (error) {
        this.successMessage.type = 'error';
        this.successMessage.title = this.$t('modules.discounts.update.error_message.title');
        this.successMessage.actionPrimary = { text: this.$t('modules.discounts.update.success_message.actions.back'), callback: () => { this.resetButtonValues(); this.updateDiscountStatus = false } }
        this.updateDiscountStatus = true;
        this.saveButton.loading = false;
        this.saveButton.error = true;
      }
    },
    resetButtonValues () {
      this.saveButton.loading = false;
      this.saveButton.success = false;
      this.saveButton.error = false;
    },
    async fetchDiscount () {
      const discount = await $discounts.findById(this.$route.params.id);
      let studentType = discount.data.studentType.reduce((acc, student) => acc += `${student.meaning}, `, '').trim();
      let modalityType = discount.data.modalityType.reduce((acc, modality) => acc += `${modality.meaning}, `, '').trim();
      this.studentType = discount.data.studentType
      this.modalityType = discount.data.modalityType

      studentType = studentType.substring(0, studentType.length - 1);
      modalityType = modalityType.substring(0, modalityType.length - 1);

      this.description = discount.data.description;
      this.code = discount.data.code;
      this.limit = discount.data.limit;
      this.limited = (discount.data.limit !== null) ? 1 : 0;
      this.selectedStudentTypes = studentType;
      this.selectedModalityTypes = modalityType;
      this.status = discount.data.status;

      this.selectedCareers = discount.data.careers.map(item => {
        item.name = item.description;
        return item;
      });

      const careerIds = discount.data.careers.map((career) => career.id);
      this.filteredCareers = this.filteredCareers.filter((career) => !careerIds.some(career.id));

      this.groups = [];
      discount.data.details.forEach(detail => {
        this.groups.push({
          product: detail.billingProduct,
          zone: detail.zone,
          discountType: { value: (detail.biannualAmount) ? 1 : 2 },
          semester: (detail.biannualAmount) ? detail.biannualAmount : detail.biannualPercentage,
          bimester: (detail.bimesterAmount) ? detail.bimesterAmount : detail.bimesterPercentage,
          menu_from: false,
          menu_to: false,
          from: new Date(detail.from).toISOString().substr(0, 10),
          to: new Date(detail.to).toISOString().substr(0, 10),
        })
      });
    }
  },
  mounted () {
    this.fetchData();
    this.fetchDiscount();
  }
}
</script>
