<template>
    <v-container v-if="user" fluid class="pa-0 pb-8 dashboard-bg fullHeight d-flex align-center" :style="`background-image: url(${require('@/assets/dashboard-background.svg')})`">
        <v-container class="container-custom viewSpaces d-flex align-center">
            <v-row>
                <v-col sm="5">
                    <h1 class="display-2 primary--text my-4 text-capitalize">
                        {{ `${$t("modules.home.welcome_message.title") + user.name}!` }}
                    </h1>
                    <h2 class="body-1">
                        {{ $t("modules.home.welcome_message.description") }}
                    </h2>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'HomeView',
        computed: {
            ...mapGetters([
                'commons/getUserData',
            ]),
            user() {
                return this['commons/getUserData']
            }
        },
    }
</script>

<style scoped lang="sass">
  .dashboard-bg
    background-color: unset !important
    background-size: contain
    background-repeat: no-repeat
    background-position: right top
</style>
