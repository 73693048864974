<template>
  <v-container>
      <v-row class="d-flex align-center justify-center">
      <template v-if="popup">
          <v-dialog :value="modelValue || value" max-width="550" widht="550" persistent>
              <div class="card card--position">
                  <div :class="['card__header', `card__header--${status}`]">
                      <div class="card__header__icon">
                          <div>
                              <v-icon dark :color="colors[status].color" size="30">
                                  {{ icons[status] }}
                              </v-icon>
                          </div>
                      </div>
                      <h1>{{ title }}</h1>
                      <p>{{ description }}</p>
                  </div>
                  <div v-if="message" class="card__content">
                      <p> {{ message }}</p>
                  </div>
                  <div class="card__actions">
                      <v-btn v-if="actions && actions.secondary" class="mx-2" min-width="180" plain @click="()=> actions.secondary.callback(close)">
                          {{ actions.secondary.label }}
                      </v-btn>
                      <v-btn v-if="actions && actions.primary" class="mx-2 white--text" min-width="180" rounded color="primary" @click="()=> actions.primary.callback(close)">
                          {{ actions.primary.label }}
                      </v-btn>
                  </div>
              </div>
          </v-dialog>
      </template>
      <template v-else>
        <template v-if="!haveContractPendingValidation || !systemConfig?.contract" >
            <v-col>
              <v-card class="py-10 widht--full">
              <div class="card card--position">
              <div :class="['card__header']">
                  <div class="card__header__icon">
                      <div :style="{background: colors[status].background}">
                          <v-icon dark color="white" size="30">
                              {{ icons[status] }}
                          </v-icon>
                      </div>
                  </div>
                  <h1>{{ title }}</h1>
                  <p>{{ description }}</p>
              </div>
              <div v-if="message" class="card__content card__content--dense">
                  <p> {{ message }}</p>
              </div>
              <div class="card__actions card__actions--dense">
                  <v-btn v-if="actions && actions.secondary && actions.secondary.visible" class="mx-2 white--text" min-width="180" rounded color="primary"  @click="actions.secondary.callback">
                      {{ actions.secondary.label }}
                  </v-btn>
                  <v-btn v-if="actions && actions.primary && actions.primary.visible" class="mx-2 white--text" min-width="180" rounded color="primary" @click="actions.primary.callback">
                      {{ actions.primary.label }}
                  </v-btn>
              </div>
          </div>
          </v-card>
          </v-col>
        </template>
        <template v-else>
        <v-col>
              <v-card class="py-10 widht--full">
              <div class="card card--position">
              <div :class="['card__header']">
                  <div class="card__header__icon">
                      <div :style="{background: colors[status].background}">
                          <v-icon dark color="white" size="30">
                              {{ icons[status] }}
                          </v-icon>
                      </div>
                  </div>
                  <h1 style="font-size: 30px; color: #4caf50;">{{ titleVerification }}</h1>
                  <p>{{ subtitleVerification }}</p>
              </div>
              <h4 class="text-center"> {{ descriptionVerification }}</h4>
              <div  class="card__content card__content--dense mt-3 pa-2" style="border: 1px solid #b3ccff ; border-radius:25px; background-color: white;">
                <div class="d-flex align-center justify-center">
                  <v-icon color="primary" size="30" class="mr-2 pa-2" style="background-color: #e3f2fd;border-radius:25px">mdi-autorenew</v-icon> <!-- Espacio entre el ícono y el texto -->
                  <p> {{ messageVerification }}</p>
                </div>
              </div>
              <div class="card__actions card__actions--dense" rounded>
                  <v-btn class="mx-2 white--text pa-6" min-width="180" rounded color="primary"  @click="actions.primary.callback">
                      {{ actionsVerification.primary.label }}
                  </v-btn>

              </div>
          </div>
          </v-card>
          </v-col>
        </template>
      </template>

  </v-row>
  </v-container>
</template>
<script>
export default {
    name: 'FeedbackPopup',
    components: {},
    model: {
        prop: 'modelValue',
        event: 'update:modelValue'
    },
    props: {
      systemConfig:Object,
        modelValue: {
            type: Boolean,
            default: false
        },
        value: {
            type: Boolean,
            default: false
        },
        popup: {
            type: Boolean,
            default: false
        },
        status: {
            type: String,
            required: true,
            validator(status) {
                const statuses = ['success', 'error', 'warning', 'info']
                return typeof status === 'string' && statuses.includes(status)
            }
        },
        title: { type: String, default: '' },
        subtitle: { type: String, default: '' },
        description: { type: String, default: '' },
        message: { type: String, default: '' },
        titleVerification: { type: String, default: '' },
        subtitleVerification: { type: String, default: '' },
        descriptionVerification: { type: String, default: '' },
        messageVerification: { type: String, default: '' },
        actionsVerification:{ type: Object },
        haveContractPendingValidation:{ type: Boolean },
        actions:{ type: Object }

    },
    data: () => ({
        icons: {
            success: 'mdi-check',
            error: 'mdi-close',
            warning: 'mdi-clock-outline',
            info: 'mdi-clock-outline',
        },
        colors: {
            success: {
                color: '#4caf50',
                background: '#4caf50'
            },
            error: {
                color: '#5a0d0d',
                background: '#ffb5b5'
            },
            warning: {
                color: '#8c3000',
                background: '#f7b390'
            },
            info: {
                color: '#111a29',
                background: '#b3ccff'
            }
        }
    }),
    methods: {
        close() {
            this.$emit('change', false)
            this.$emit('update:modelValue', false)
            this.$emit('close')
        }
    }
}
</script>

<style lang="sass" scoped>
    .v-dialog.v-dialog--active
        border-radius: 16px !important
        background: transparent !important
        box-shadow: none
        max-width: 550px !important
        min-height: 400px
        overflow: visible !important

    .card
        font: Popins
        display:flex
        flex-direction: column
        background: #ffff
        border-radius: 16px
    .card--position
        position: relative

    .card__header
        display:flex
        flex-direction: column
        alig-items: center
        justify-content: center
        border-radius: 12px 12px 0 0
        padding: 30px 36px
        & > *
            display:flex
            alig-items: center
            justify-content: center
            text-align: center
        h1,h2,h3,h4,h5,h6
            font-size: 16px
            font-weight:600
        p
            margin: 0
            font-weight:400
            font-size: 14px
        .card__header__icon
            height: 64px !important
            cursor: default
            div
                display: flex
                alig-items: center
                justify-content: center
                width: 48px
                height: 48px
                margin: 0 auto
                border-radius: 50%
                background: #fff
    .card__header--success
        background: #cbffb5
        color: #215e06

    .card__header--error
        background: #ffb5b5
        color: #5a0d0d

    .card__header--warning
        background: #f7b390
        color: #8c3000

    .card__header--info
        background: #b3ccff
        color: #111a29


    .card__content
        display: flex
        alig-items: center
        justify-content: center
        background: #f2f2f2
        min-height: 60px
        padding: 20px 30px
        p
            color: #888d94
            text-align: center
            font-size: 14px
            margin: 0

    .card__content--dense
        max-width: 370px
        margin: 0 auto

    .card__actions
        display: flex
        align-items: center
        justify-content: center
        column-gap: 24px
        padding: 60px 30px

    .card__actions--dense
        padding: 30px
    .close
        position: absolute !important
        z-index: -1 !important
        top:0
        left:100%
        margin-left: 6px

    .close--icon
        display: flex
        alig-items: center
        justify-content: center
        width: 32px
        height: 32px
        border-radius: 50%
        background: #d9d9d9
        cursor: pointer
        &:hover
            background: #e2e2e2
    .feedbackVerification-title
        color: #215e06

</style>
