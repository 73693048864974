<template>
  <v-container fluid>
    <v-card width="560px" height="550px" class=" body ml-auto mr-auto pa-4">
      <v-card-title>
        {{$t('modules.portalalumno.payments.checkingAccount.ticket.title')}} {{details.receiptType}}
      </v-card-title>
      <v-card-text class="mt-5">
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col cols="5" sm="4">
                <h5>{{$t('modules.portalalumno.payments.checkingAccount.ticket.reference')}}</h5>
              </v-col>
              <v-col cols="6" >
                <p class="m-0 caption">{{details.receiptDetail.reference}}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <h5>{{$t('modules.portalalumno.payments.checkingAccount.ticket.date')}}</h5>
              </v-col>
              <v-col cols="6">
                <p>{{details.receiptDetail.date}}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <h5>{{$t('modules.portalalumno.payments.checkingAccount.ticket.paymentMethod')}}</h5>
              </v-col>
              <v-col cols="6">
                <p>{{details.receiptDetail.paymentType}}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" height="70px">
                <h5>{{$t('modules.portalalumno.payments.checkingAccount.ticket.detail')}}</h5>
              </v-col>
              <v-col cols="6" class="mb-0">
                <p class="mb-0">{{details.receiptDetail.detail}}</p>
              </v-col>
            </v-row>
             <v-row class="totalAmount ml-auto mr-auto mt-10">
              <v-col cols="12" sm="4" class="text-center">
                <h2>{{$t('modules.portalalumno.payments.checkingAccount.ticket.total')}}</h2>
              </v-col>
              <v-col cols="12" sm="4" class="text-center ">
                <h2>{{ details.receiptDetail.total | $currency }}</h2>
              </v-col>
            </v-row>
            <v-row class="mt-2 justify-end" v-show="!(details.receiptDetail.fileUrl === null)">
              <v-col cols="4" v-if="$isMexico">
                <Button :text="$t('modules.portalalumno.payments.checkingAccount.ticket.btnDownloadXML')" @clicked="downloadXmlFile" />
              </v-col>
              <v-col cols="4">
                <Button :text="$t('modules.portalalumno.payments.checkingAccount.ticket.btnDownload')" @clicked="downloadTicket" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Button from '@/components/Button/Button.vue';
import download from 'downloadjs';
import { $receipt } from '../../Services';
export default {
  name: 'PaymentDetail',
  components: {
    Button
  },
  data() {
    return{}
  },
  props: {
    details: {
      type: Object
    }
  },
  methods: {
    async downloadXmlFile() {
      try {
        const { data } = await $receipt.find('payment-receipt-xml', null, {params: { receiptId: this.details.receiptId }});
        const blob = new Blob([data], { type: 'application/xml' });
        download(blob, `${this.details.receiptId}.xml`, 'application/xml');

      } catch (error) {
          await this.$store.dispatch('commons/openPopUp', {
            title: this.$t('modules.portalalumno.payments.checkingAccount.ticket.error.btnDownloadXMLError'),
            actionPrimary: {
              text: this.$t('actions.accept'),
              callback() {
              },
            },
            icon: {color: 'error', name: 'mdi-close'},
            color: 'primary',
          });
        return error
      }
    },
    base64ToFile(base64data, fileName) {
      const bs = atob(base64data);
      const buffer = new ArrayBuffer(bs.length);
      const ba = new Uint8Array(buffer);
      for (let i = 0; i < bs.length; i++) {
        ba[i] = bs.charCodeAt(i);
      }
      return new File([ba], fileName);
    },
    async downloadFile() {
      try {
        const transform = this.base64ToFile(this.details.receiptDetail.fileUrl, 'Recibo.pdf');
        await download(transform, 'Recibo.pdf');
      } catch (e) {
        await this.$store.dispatch('commons/openPopUp', {
          title: this.$t('responseCodeMeaning.E208'),
          actionPrimary: {
            text: this.$t('actions.accept'),
            callback() {
            },
          },
          icon: {color: 'error', name: 'mdi-close'},
          color: 'primary',
        });
        return e;
      }
    },
    redirectToTicketUrl() {
      window.open(this.details.receiptDetail.fileUrl);
    },
    downloadTicket() {
      const isUrl = this.details?.receiptDetail?.fileUrl.includes('https://') || this.details?.receiptDetail?.fileUrl.includes('http://')
      if(isUrl) {
        this.redirectToTicketUrl()
      } else {
        this.downloadFile()
      }
    }
  },
  watch: {

  }
}
</script>

<style lang="sass" scoped>
.body
  background-image: linear-gradient(135deg, white 50%, transparent 50%),linear-gradient(225deg, white 50%, transparent 50%),linear-gradient(45deg, white 50%, transparent 50%),linear-gradient(-45deg, white 50%, transparent 50%),
  background-position: top left, top left,bottom left, bottom left
  background-size: 12px 12px
  background-repeat: repeat-x
.totalAmount
  width: 100%
  border-top: 1px solid #dadada
  border-bottom: 1px solid #dadada
  justify-content: space-between
  color: var(--v-primary-darken1)

</style>
