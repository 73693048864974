import paymentsStudentsTraslation from './PaymentsStudents/Translations';
import checkingAccountTraslation from './CheckingAccount/Translations';
import paymentsContractTranslations from './PaymentsContracts/Translations';

const paymentTranslations = {
  es_MX : {
    paymentsStudents: {...paymentsStudentsTraslation.es_MX},
    checkingAccount: {...checkingAccountTraslation.es_MX},
    paymentsContracts: {...paymentsContractTranslations.es_MX},
  },
  es_CL : {
    paymentsStudents: {...paymentsStudentsTraslation.es_CL},
    checkingAccount: {...checkingAccountTraslation.es_CL},
    paymentsContracts: {...paymentsContractTranslations.es_CL},
  },
  es_PE: {
      paymentsStudents: {...paymentsStudentsTraslation.es_PE },
      checkingAccount: {...checkingAccountTraslation.es_PE},
      paymentsContract: {...paymentsContractTranslations.es_PE},
  },
  es: {
    paymentsStudents: {...paymentsStudentsTraslation.es},
    checkingAccount: {...checkingAccountTraslation.es},
    paymentsContract: {...paymentsContractTranslations.es},
  },
  en: {
    paymentsStudents: {...paymentsStudentsTraslation.en},
    checkingAccount: {...checkingAccountTraslation.en},
    paymentsContract: {...paymentsContractTranslations.en},
  }
};

export default paymentTranslations;
