<template>
    <div>
        <v-container fluid class="pa-0 pb-8">
            <template>
                <v-container class="container-custom">
                    <Breadcrumbs :title="$t('modules.benefits.update.breadcrumbs.title')" :description="$t('modules.benefits.update.breadcrumbs.description')"></Breadcrumbs>
                </v-container>
            </template>
            <v-container class="container-custom px-8">
                <template v-if="!updateBenefitStatus">
                    <v-row justify="center">
                        <v-col class="py-0" sm="12" style="padding-left: 32px; padding-right: 32px;">
                            <OutlinedCard
                                :title="$t('modules.benefits.update.title')"
                                :subtitle="$t('modules.benefits.update.subtitle')"
                                :switch-label="(status) ? $t('enabled') : $t('disabled')"
                                :switch-value="status"
                                @switchChange="(val) => {this.status = val;}"
                                switcher
                                class="mb-6"
                            >
                                <v-container fluid>
                                    <v-row>
                                        <v-col sm="4" class="d-flex align-start pb-0 pt-4">
                                            <span class="caption text-uppercase">{{ $t('modules.benefits.update.labels.name') }}</span>
                                        </v-col>
                                        <v-col sm="4" class="align-start pb-4">
                                            <v-text-field
                                                :error-messages="($v.description.$dirty && $v.description.$invalid) ?  ((!$v.description.required) ? $t('modules.benefits.update.validation_errors.name') : (!$v.description.alphaNumWithSpaces) ? $t('modules.benefits.update.validation_errors.descriptionAlphanumeric') :
                                                (!$v.description.maxLength) ? $t('modules.benefits.update.validation_errors.descriptionMaxLength') : (!$v.description.minLength) ? $t('modules.benefits.update.validation_errors.codeMinLength') :
                                                $t('modules.benefits.update.validation_errors.name')) :
                                                ''"
                                                @blur="$v.description.$touch()"
                                                v-model="$v.description.$model"
                                                :label="`${$t('modules.benefits.update.labels.name')} *`"
                                                @keypress="$validateAlphanumeric($event, $v.description.$model,40, true)"
                                                outlined
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col sm="4" class="d-flex align-start pb-0 pt-4">
                                            <span class="caption text-uppercase">{{ $t('modules.benefits.update.labels.code') }}</span>
                                        </v-col>
                                        <v-col sm="4" class="align-start pb-4">
                                            <v-text-field
                                                :error-messages="($v.code.$dirty && $v.code.$invalid) ? ((!$v.code.required) ? $t('modules.benefits.update.validation_errors.code') : (!$v.code.alphaNum) ? $t('modules.benefits.update.validation_errors.codeAlphanumeric') :
                                                (!$v.code.maxLength) ? $t('modules.benefits.update.validation_errors.codeMaxLength') :(!$v.code.minLength) ? $t('modules.benefits.update.validation_errors.codeMinLength'): $t('modules.benefits.update.validation_errors.code_unique')) :
                                                 ''"
                                                @blur="validateCode"
                                                :loading="loadingCode"
                                                v-model="$v.code.$model"
                                                :label="`${$t('modules.benefits.update.labels.code')} *`"
                                                outlined
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col sm="4" class="d-flex align-start pb-0 pt-4">
                                            <span class="caption text-uppercase">{{ $t('modules.benefits.update.labels.from') }}</span>
                                        </v-col>
                                        <v-col sm="4" class="align-start pb-4">
                                            <v-menu
                                                ref="menuFrom"
                                                v-model="menuFrom"
                                                :close-on-content-click="false"
                                                :return-value.sync="from"
                                                transition="scale-transition"
                                                offset-y
                                                bottom
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        v-model="from"
                                                        :label="`${$t('modules.benefits.update.academic_performance.from')} *`"
                                                        append-icon="mdi-calendar"
                                                        readonly
                                                        outlined
                                                        v-on="on"
                                                        class="no-message"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    v-model="from"
                                                    no-title color="primary" scrollable
                                                >
                                                    <v-spacer></v-spacer>
                                                    <v-btn text color="primary" @click="menuFrom = false">{{ $t('actions.cancel') }}</v-btn>
                                                    <v-btn text color="primary" @click="$refs.menuFrom.save(from)">OK</v-btn>
                                                </v-date-picker>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col sm="4" class="d-flex align-start pb-0 pt-4">
                                            <span class="caption text-uppercase">{{ $t('modules.benefits.update.labels.to') }}</span>
                                        </v-col>
                                        <v-col sm="4" class="align-start pb-4">
                                            <v-menu
                                                ref="menuTo"
                                                v-model="menuTo"
                                                :close-on-content-click="false"
                                                :return-value.sync="to"
                                                transition="scale-transition"
                                                offset-y
                                                bottom
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        v-model="to"
                                                        :label="`${$t('modules.benefits.update.academic_performance.to')} *`"
                                                        append-icon="mdi-calendar"
                                                        readonly
                                                        outlined
                                                        v-on="on"
                                                        class="no-message"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    v-model="to"
                                                    no-title color="primary" scrollable
                                                >
                                                    <v-spacer></v-spacer>
                                                    <v-btn text color="primary" @click="menuTo = false">{{ $t('actions.cancel') }}</v-btn>
                                                    <v-btn text color="primary" @click="$refs.menuTo.save(to)">OK</v-btn>
                                                </v-date-picker>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col sm="4" class="d-flex align-start pb-0 pt-4">
                                            <span class="caption text-uppercase">{{ $t('modules.benefits.update.labels.benefit_type') }}</span>
                                        </v-col>
                                        <v-col sm="4" class="align-start pb-4">
                                            <span>{{ selectedBenefitType.meaning }}</span>
                                        </v-col>
                                    </v-row>
                                    <v-divider class="mt-6 mb-8"></v-divider>
                                    <AcademicPerformance
                                        v-if="selectedBenefitType.value === this.benefitType.academic"
                                        :billingProducts="billingProducts"
                                        :academic="$v.academic"
                                        @removeGroup="(index) => removeGroup(academic.groups, index)"
                                        @addGroup="(mockup) => addGroup(academic.groups, mockup)"
                                    ></AcademicPerformance>
                                    <Community
                                        v-else-if="[this.benefitType.community, this.benefitType.referredCompany, this.benefitType.other].includes(selectedBenefitType.value)"
                                        :billingProducts="billingProducts"
                                        :productSaving="$v.productSaving"
                                        @removeGroup="(index) => removeGroup(productSaving.groups, index)"
                                        @addGroup="(mockup) => addGroup(productSaving.groups, mockup)"
                                    ></Community>
                                    <UniqueDiscount
                                        v-else-if="selectedBenefitType.value === this.benefitType.uniqueDiscount"
                                        :friend="$v.uniqueDiscount"
                                    ></UniqueDiscount>
                                    <ReferredFriend
                                        v-else-if="selectedBenefitType.value === this.benefitType.referredFriend"
                                        :friend="$v.referredFriend"
                                    ></ReferredFriend>
                                </v-container>
                            </OutlinedCard>
                            <AcademicPerformanceRules
                                v-if="selectedBenefitType.value === this.benefitType.academic"
                                :rules="$v.academic_rules"
                                @removeGroup="(index) => removeGroup(academic_rules.groups, index)"
                                @addGroup="(mockup) => addGroup(academic_rules.groups, mockup)"
                            ></AcademicPerformanceRules>
                            <OutlinedCard
                                :title="$t('modules.benefits.create.sectionDocumentation.title')"
                                :subtitle="$t('modules.benefits.create.sectionDocumentation.subtitle')"
                                class="mb-6"
                            >
                                <TransferList
                                :avatar="false"
                                :loading="loadingSearchDocs"
                                :firstListTitle="$t('modules.benefits.create.sectionDocumentation.transferList.firstTitle')"
                                :secondListTitle="$t('modules.benefits.create.sectionDocumentation.transferList.secondTitle')"
                                :availableItems.sync="docs"
                                :added-items.sync="docsAdded"
                                areEquals="id"
                                @searchItemLeftOnBackend="searchDocs"
                                @searchItemRightOnBackend="searchDocsAdded"
                                ></TransferList>
                                 <!-- <v-alert
                                      text
                                      type="warning"
                                      class="mt-4"
                                      v-show="docsAdded.length === 0"
                                    >{{ $t('modules.benefits.create.sectionDocumentation.no_select') }}</v-alert> -->
                            </OutlinedCard>
                            <OutlinedCard
                                :title="$t('modules.scholarships.create.sectionRestriction.title')"
                                class="mb-6"
                            >
                                <v-radio-group v-model="limited" class="no-message ma-0">
                                <v-radio
                                    :label="$t('modules.scholarships.create.sectionRestriction.items.radioFirst')"
                                    color="primary"
                                    :value="0"
                                ></v-radio>
                                <div class="d-flex">
                                    <v-radio
                                        :label="$t('modules.scholarships.create.sectionRestriction.items.radioSecond')"
                                        color="primary"
                                        :value="1"
                                        ></v-radio>
                                        <v-text-field
                                        :error-messages="($v.limit.$invalid && $v.limit.$dirty) ? $t('modules.scholarships.create.errors.limit') : ''"
                                        outlined
                                        v-model.number="$v.limit.$model"
                                        type="text"
                                        @paste="onPaste"
                                        @keypress="$validateIntegerInputNumber($event, $v.limit.$model, null, 6)"
                                        placeholder="0"
                                        @blur="$v.limit.$touch()"
                                        :disabled="!limited"
                                    ></v-text-field>
                                </div>
                                </v-radio-group>
                            </OutlinedCard>
                            <div class="d-flex justify-end">
                                <Button
                                    white
                                    :text="$t('actions.cancel')"
                                    @clicked="$router.replace('/benefits')"
                                    @end="resetButtonValues()"
                                    class="mr-4"
                                >
                                </Button>

                                <Button
                                    :loading="saveButton.loading"
                                    :success="saveButton.success"
                                    :error="saveButton.error"
                                    :text="$t('actions.save')"
                                    :successText="$t('actions.saved')"
                                    :errorText="'Error'"
                                    :disabled="!canUpdateBenefit  || $v.formValid.$anyError || !dataValid"
                                    @end="resetButtonValues()"
                                    @clicked="updateBenefitPopUp"
                                >
                                </Button>
                            </div>
                        </v-col>
                    </v-row>
                </template>
                <SuccessMessage v-else
                    :type="successMessage.type"
                    :title="successMessage.title"
                    :actionPrimary="successMessage.actionPrimary"
                />
            </v-container>
        </v-container>
    </div>
</template>

<script>
    import AcademicPerformance from '../Components/AcademicPerformance/AcademicPerformance';
    import AcademicPerformanceRules from '../Components/AcademicPerformance/AcademicPerformanceRules';
    import Community from '../Components/Community/Community';
    import ReferredFriend from '../Components/ReferredFriend/ReferredFriend';
    import UniqueDiscount from '../Components/UniqueDiscount/UniqueDiscount';
    import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
    import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
    import Button from '@/components/Button/Button';
    import TransferList from '@/components/TransferList/TransferList';
    import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
    import { $documentations, $benefits, $products } from '../Services';
    import { required, requiredUnless, alphaNum, maxLength, maxValue, minLength, minValue, helpers } from 'vuelidate/lib/validators';
    import { mapGetters } from 'vuex';
    import moment from 'moment';
    

    export default {
        name: 'BenefitsUpdate',
        components: {
            Breadcrumbs,
            OutlinedCard,
            Button,
            SuccessMessage,
            AcademicPerformance,
            AcademicPerformanceRules,
            Community,
            ReferredFriend,
            UniqueDiscount,
            TransferList,
        },
        data() {
            return {
                loadingSearchDocs: false,
                docs: [],
                docsAdded:[],
                limited: 0,
                limit: 1,
                updateBenefitStatus: false,
                loadingCode: false,
                codeUnique: null,
                originalCode: null,
                saveButton: {
                    loading: false,
                    success: false,
                    error: false
                },
                successMessage: {
                    type: 'success',
                    title: this.$t('modules.benefits.update.success_message.title'),
                    actionPrimary: {text: this.$t('modules.benefits.update.success_message.actions.primary_text'), callback: ()=>{this.$router.push('/benefits')}},
                },

                benefitType: {
                    referredFriend: 10,
                    community: 47,
                    academic: 48,
                    referredCompany: 292,
                    other: 357,
                    uniqueDiscount: 410
                },

                billingProducts: [],

                description: null,
                code: null,
                selectedBenefitType: {meaning: ''},
                status: true,
                menuFrom: false,
                menuTo: false, 
                from: null, //new Date().toISOString().substr(0, 10),
                to: null, //new Date().toISOString().substr(0, 10),
                

                academic: {
                    groups: [
                        {
                            selectedProduct: [],
                            percentage: null,
                            amount: null,
                        }
                    ],
                    average: null,
                    subjects: null,
                },
                academic_rules: {
                    groups: [
                        {
                            dayListFrom: this.$t('modules.benefits.create.academic_performance.rules.dayList.31'),
                            dayListTo: this.$t('modules.benefits.create.academic_performance.rules.dayList.31'),
                            dayFrom: null,
                            monthFrom: { id : null},
                            dayTo: null,
                            monthTo: {id : null},
                        }
                    ]
                },
                productSaving: {
                    groups: [
                        {
                            selectedProduct: [],
                            percentage: null,
                            amount: null,
                        }
                    ],
                },
                referredFriend: {
                    referredAmount: null,
                    referAmount: null,
                },
                uniqueDiscount: {
                    referredAmount: null,
                },
            }
        },
        validations() {
          return {
            description: {
              required,
              alphaNumWithSpaces: helpers.regex('withSpace', /^[a-z0-9]+(?:[a-z0-9]+\s[a-z0-9]*)*$/i),
              maxLength: maxLength(40),
              minLength: minLength(3)
            },
            code: {
              required,
              alphaNum,
              maxLength: maxLength(10),
              minLength: minLength(3),
              unique() {
                return this.codeUnique !== false;
              },
            },
            academic: {
                groups: {
                    $each: {
                        selectedProduct: {required},
                        percentage: { required: requiredUnless('amount'), maxValue: maxValue(100), minValue: minValue(0)},
                        amount: { required: requiredUnless('percentage'),  maxValue: maxValue(10000000000000),  minValue: minValue(0)}
                    }
                },
                average: { required,  maxValue: maxValue(10), minValue: minValue(1) },
                subjects: { required, maxValue: maxValue(1000), minValue: minValue(1)},
            },
            limit: {},
            academic_rules: {
              groups: {
                $each: {
                  dayFrom: {required},
                  monthFrom: {required},
                  dayTo: {required},
                  monthTo: {
                    required,
                    validateDate() {
                      const result = this.academic_rules.groups.map(element => {
                        if (element.monthFrom.id < element.monthTo.id) {
                          return true
                        } else if (element.monthFrom.id == element.monthTo.id && parseInt(element.dayFrom) < parseInt(element.dayTo)) {
                          return true
                        }
                        return false
                      })
                      return !result.includes(false)
                    },
                  },
                }
              }
            },
            productSaving: {
                groups: {
                    $each: {
                        selectedProduct: {required},
                        percentage: { required: requiredUnless('amount'), maxValue: maxValue(100), minValue: minValue(0)},
                        amount: { required: requiredUnless('percentage'), maxLength: maxLength(10),minValue: minValue(0)}
                    }
                },
            },
            referredFriend: {
                referredAmount: { required, maxValue: maxValue(1000000000), minValue: minValue(0)},
                referAmount: { required, maxValue: maxValue(1000000000), minValue: minValue(0)},
            },
            uniqueDiscount: {  
                referredAmount: { required, minValue: minValue(0)},
            },
            formValid: ['description', 'code', 'academic_rules', 'referredFriend', 'productSaving', 'academic', 'uniqueDiscount']
          }
        },
        computed: {
            canUpdateBenefit() {
                return this.$permissions.portaladministrativo.prices.benefits.UPDATE
            },
            ...mapGetters({userId: 'commons/getUserID',}),
            dataValid() {
                if ((this.selectedBenefitType.value === this.benefitType.academic) && !this.$v.academic.$anyError && !this.$v.academic_rules.$anyError ) {
                    return true;
                } else if (([this.benefitType.community, this.benefitType.referredCompany, this.benefitType.other].includes(this.selectedBenefitType.value)) && !this.$v.productSaving.$anyError) {
                    return true;
                } else if ((this.selectedBenefitType.value === this.benefitType.referredFriend) && !this.$v.referredFriend.$anyError) {
                    return true;
                } else if ((this.selectedBenefitType.value === this.benefitType.uniqueDiscount) && !this.$v.uniqueDiscount.$anyError) {
                    return true;
                }
                 else {
                    return false;
                }
            },
        },
        methods: {

            searchDocs (event) {
                this.getBenefitsDocs(event);
            },
            searchDocsAdded (event) {
                this.docsAdded = event
            },
            onPaste () {
                event.preventDefault();
            },
            async getBenefitsDocs (doc) {
                this.loadingSearchDocs = true
                let documentations = [];
                if (doc) {
                    documentations = await $documentations.find(null, null, {
                    params: { name: doc, length: this.paginationLimit }
                    });
                } else {
                    documentations = await $documentations.find(null, null, {
                    params: { length: this.paginationLimit }
                    });
                }
                if (documentations.data && documentations.data.length) {
                    documentations = documentations.data.map(item => {
                    return {
                        id: item.id,
                        name: item.name
                    };
                    });
                    this.docs = documentations;
                }
                this.loadingSearchDocs = false
            },

            async validateCode() {
                this.$v.code.$touch();
                if (this.$v.code.$model) {
                    if (this.originalCode === this.code) {
                        this.codeUnique = true;
                        return;
                    }

                    this.codeUnique = null;
                    this.loadingCode = true;
                    try {
                        const response = await $benefits.find('exists', null, {params: {code: this.$v.code.$model}});
                        this.codeUnique = !response.data.exists;
                        this.loadingCode = false;
                    } catch (error) {
                        this.codeUnique = null;
                        throw error
                    } finally {
                        this.$v.code.$touch();
                    }
                }
            },
            removeGroup(group, index) {
                group.splice(index, 1)
            },
            addGroup(group, mockup) {
                group.push({
                    ...mockup
                });
            },
            updateBenefitPopUp() {
                if (!this.status) {
                    this.$store.dispatch('commons/openPopUp', {
                        title: this.$t('alert'),
                        content: [{ value: this.$t('disabledMessage') }],
                        actionPrimary: { text: this.$t('actions.accept'), callback: () => this.updateBenefit() },
                        actionSecondary: { text: this.$t('actions.cancel'), callback() {} },
                        icon: { color: 'warning', name: 'mdi-alert' },
                        color: 'primary',
                    });
                } else {
                    this.updateBenefit();
                }
            },
            async updateBenefit() {
                if (!this.canUpdateBenefit) return false;
                this.saveButton.loading = true;

                const data = {
                    status: this.status,
                    description: this.description,
                    code: this.code,
                    from: new Date(this.from).toISOString(),
                    to: moment(this.to),
                    limit: this.limit,
                    type: {value: this.selectedBenefitType.value},
                    details: [],
                    coursesCount: null,
                    average: null,
                    rules: [],
                    documentations: this.docsAdded.map(doc=>{
                        return{
                            documentation:{
                                id: doc.id
                            }
                        }
                    }),
                    benefitReferrerAmount: null,
                    benefitReferredAmount: null,
                    userId: this.userId,
                }

                switch (this.selectedBenefitType.value) {
                    case this.benefitType.academic:
                        this.academic.groups.forEach(group => {
                            data.details.push({
                                billingProduct: {id: group.selectedProduct.id},
                                amount: group.amount,
                                percentage: group.percentage,
                            })
                        });
                        data.coursesCount = this.academic.subjects;
                        data.average = this.academic.average;
                        this.academic_rules.groups.forEach(group => {
                            data.rules.push({
                                dayFrom: group.dayFrom,
                                monthFrom: group.monthFrom.id,
                                dayTo: group.dayTo,
                                monthTo: group.monthTo.id,
                            })
                        });
                        break;
                    case this.benefitType.referredFriend:
                        data.benefitReferrerAmount = this.referredFriend.referAmount;
                        data.benefitReferredAmount = this.referredFriend.referredAmount;
                        break;
                    case this.benefitType.uniqueDiscount:
                        data.benefitReferredAmount = this.uniqueDiscount.referredAmount;
                        break;
                    default:
                      this.productSaving.groups.forEach(group => {
                        data.details.push({
                          billingProduct: {id: group.selectedProduct.id},
                          amount: group.amount,
                          percentage: group.percentage,
                        })
                      });
                      break;
                }

                try {
                    await $benefits.update(this.$route.params.id, data);

                    this.successMessage.type = 'success';
                    this.successMessage.title = this.$t('modules.benefits.update.success_message.title');
                    this.successMessage.actionPrimary = { text: this.$t('modules.benefits.update.success_message.actions.primary_text'), callback: () => { this.$router.push('/benefits') } };

                    this.updateBenefitStatus = true;
                    this.saveButton.success = true;
                }catch (error) { // eslint-disable-line
                    this.successMessage.type = 'error';
                    const mappedErrors = {
                        E203:'repeated',
                        E210:'assigned',
                    }
                    const finalMessage = this.$t(`modules.benefits.update.error_message.${mappedErrors[error.code] || 'title'}`)
                    this.successMessage.title = finalMessage
                    this.successMessage.actionPrimary = { text: this.$t('modules.benefits.update.success_message.actions.back'), callback: () => { this.updateBenefitStatus = false } }
                    this.updateBenefitStatus = true;
                    this.saveButton.loading = false;
                    this.saveButton.error = true;
                    this.resetButtonValues();
                }
                },
            resetButtonValues(){
                this.saveButton.loading = false;
                this.saveButton.success = false;
                this.saveButton.error = false;
            },
            async fetchData() {
                await this.getBenefitsDocs();
                const allData = Promise.all([
                    $products.find(null, null, { params: { page: 0, length: 500 } }),
                    $benefits.findById(this.$route.params.id),
                    $benefits.findById(this.$route.params.id, 'documentations'),
                    $benefits.findById(this.$route.params.id, 'rules'),
                ]);

                try {
                    const [billingProducts, benefit, documentation, rules] = await allData;
                    this.billingProducts = billingProducts.data.content;
                    this.status = benefit.data.status;
                    this.description = benefit.data.description;
                    this.code = benefit.data.code;
                    this.originalCode = benefit.data.code;
                    this.selectedBenefitType = benefit.data.type;
                    this.from = new Date(benefit.data.from).toISOString().substring(0, 10);
                    this.to = new Date(benefit.data.to).toISOString().substring(0, 10);
                    this.limit = benefit.data.limit
                    this.referredAmount = benefit.data.referredAmount
                    this.docsAdded = documentation.data.documentation.map(doc=>{
                        return {
                            name: doc.documentation.name,
                            id: doc.documentation.id
                        }
                    })
                    this.docs = this.docs.filter(
                        doc=>
                            !this.docsAdded.map(item => item.id).includes(doc.id)
                    ).map(item => ({ name: item.name, id: item.id }))
                    switch (benefit.data.type.value) {
                        case this.benefitType.academic:
                            this.academic.groups = [];
                            benefit.data.details.forEach(detail => {
                                this.productSaving.groups.push({
                                    selectedProduct: detail.billingProduct,
                                    percentage: detail.percentage,
                                    amount: detail.amount,
                                })
                            });
                            this.academic.average = benefit.data.average;
                            this.academic.subjects = benefit.data.coursesCount;

                            this.academic_rules.groups = [];
                            rules.data.rules.forEach(rule => {
                                const monthList = this.$t('modules.benefits.create.academic_performance.rules.monthList');
                                const monthFrom = monthList.find(month => month.id == rule.monthFrom);
                                const monthTo = monthList.find(month => month.id == rule.monthTo);
                                this.academic_rules.groups.push({
                                    dayListFrom: this.$t(`modules.benefits.create.academic_performance.rules.dayList.${monthFrom.days}`),
                                    dayListTo: this.$t(`modules.benefits.create.academic_performance.rules.dayList.${monthTo.days}`),
                                    dayFrom: String(rule.dayFrom),
                                    monthFrom,
                                    dayTo: String(rule.dayTo),
                                    monthTo,
                                });
                            });
                            break;
                        case this.benefitType.referredFriend:
                            this.referredFriend.referredAmount = benefit.data.benefitReferredAmount;
                            this.referredFriend.referAmount = benefit.data.benefitReferrerAmount;
                            break;
                        case this.benefitType.uniqueDiscount:
                            this.uniqueDiscount.referredAmount = benefit.data.benefitReferredAmount;
                            break;
                    default:
                        this.productSaving.groups = [];
                        benefit.data.details.forEach(detail => {
                            this.productSaving.groups.push({
                                selectedProduct: detail.billingProduct,
                                percentage: detail.percentage,
                                amount: detail.amount,
                            })
                        });
                        break;
                }
            } catch {
                this.saveButton.error = false;
            }
        },
        },
        mounted() {
            this.fetchData()
        }
    }
</script>
