<template>
    <v-container fluid class="pa-0 pb-8">
        <v-container class="container-custom">
            <Breadcrumbs :title="$t('modules.portalalumno.procedures.proceduresInital.breadcrumbs.title')" :description="$t('modules.portalalumno.procedures.proceduresInital.breadcrumbs.description')"></Breadcrumbs>
            <v-row justify="center">
                <v-col class="viewSpaces" cols="12">
                    <v-row class="fullHeight d-flex align-center justify-center">
                        <v-col sm="11" class="container-custom d-flex flex-column  mx-auto">
                            <v-row>
                                <v-col cols="12" v-if="loading" class="d-flex mt-6">
                                    <v-progress-circular class="mx-auto" indeterminate color="primary"></v-progress-circular>
                                </v-col>
                                <v-col sm="4" cols="12" v-if="!loading && procedures.length">
                                    <v-card-title class="card-chips-title d-block d-sm-flex text-center font-weight-bold ma-0 ml-2 px-0 mb-2 pt-1">{{ $t('modules.portalalumno.procedures.proceduresInital.proceduresTitle') }}</v-card-title>
                                    <div class='grey-group'>
                                        <MultipleCustomCardsChips
                                            :loading="proceduresLoading"
                                            :items="procedures"
                                            :showChips="false"
                                            @chipClick="onTramiteChipClick"
                                        ></MultipleCustomCardsChips>
                                    </div>
                                </v-col>
                                <v-col sm=4 cols="12" v-if="!loading && (certificatesAcademic.length)">
                                    <template v-if="certificatesAcademic.length">
                                        <v-card-title class="card-chips-title  d-block d-sm-flex text-center font-weight-bold ma-0 ml-2 px-0 mb-2 pt-1">{{$t('modules.portalalumno.procedures.proceduresInital.academicCertificatesTitle')}}</v-card-title>
                                        <div class='grey-group'>
                                            <MultipleCustomCardsChips
                                                :items="certificatesAcademic"
                                                :showChips="false"
                                                @chipClick="onChipClick"
                                            ></MultipleCustomCardsChips>
                                        </div>
                                    </template>
                                </v-col>
                                <v-col sm=4 cols="12" v-if="!loading">
                                    <v-card-title  class="card-chips-title  d-block d-sm-flex text-center font-weight-bold ma-0 ml-2 px-0 mb-2 pt-1">{{ $t('modules.portalalumno.procedures.proceduresInital.history') }}</v-card-title>
                                    <div class='grey-group'>
                                        <MultipleCustomCardsChips
                                            showChips
                                            :items="history"
                                            @chipClick="onHistoryDetailClick"
                                        ></MultipleCustomCardsChips>
                                        <v-row>
                                            <v-col class="d-flex justify-center ">
                                                <v-btn
                                                    :loading="loading"
                                                    text
                                                    block
                                                    color="primary"
                                                    @click="onHistoryTableClick"
                                                    :disabled="!history.length"
                                                    class="text-history"
                                                >
                                                    {{ history.length ? $t('modules.portalalumno.procedures.proceduresInital.seeAll') : $t('modules.portalalumno.procedures.proceduresInital.noHistory') }}
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
        <TemporaryRightDrawer
            @tmpDrawerOpen="openDrawer"
            :open="procedureDetailDrawer.open"
            :title="procedureDetailDrawer.title"
            :description="procedureDetailDrawer.description"
            :width="75"
        >
            <ProcedureHistoryDetails :procedureId="procedureDetailDrawer.id"></ProcedureHistoryDetails>
        </TemporaryRightDrawer>
    </v-container>
</template>

<style lang="sass" scoped>
    .card-chips-title
        font-size: 20px
        margin: 10px
    .grey-group
        background-color: #eaeaea
        border-radius: 10px
        padding: 10px

    .container-custom
        margin-bottom: 100px
    .text-history
        font-size: 0.8rem
</style>

<script>
    import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
    import MultipleCustomCardsChips from '../Components/MultipleCustomCardsChips/MultipleCustomCardsChips';
    import TemporaryRightDrawer from '@/components/TemporaryRightDrawer/TemporaryRightDrawer'
    import ProcedureHistoryDetails from './ProcedureHistoryDetails'
    import { $processings, $studentProcessing, $studentAccount } from '../services';
    import moment from 'moment'
    import { mapGetters } from 'vuex'

export default {
    name: 'ProceduresInit',
    components: {
        Breadcrumbs,
        MultipleCustomCardsChips,
        TemporaryRightDrawer,
        ProcedureHistoryDetails,
    },
    data() {
        return {
            debt: false,
            launchAlertModal: false,
            alertModal: null,

            proceduresLoading: false,
            loading: false,
            fetchedStudentProcedures: [],
            fetchedProcedures: [],
            reEnrollmentOptions: null,

            history: [],
            statusColor: {
                'Pendiente': '#419bf9',
                'Rechazado': 'error',
                'Aprobado': 'success',
                'Observado': 'orange',
            },

             title: 'Gestión TNE',
            procedures: [],
            certificatesAcademic: [],
            procedureDetailDrawer: {
                open: false,
                title: '',
                description: '',
                id: null,
            },
        }
    },
    computed: {
        ...mapGetters({
            studentRegister: 'commons/getStudentActualRegister',
            studentId: 'commons/getStudentId',
        }),
    },
    mounted() {
        this.fetchProcedures()
    },

    methods: {
        openDrawer() {
            this.procedureDetailDrawer.open = !this.procedureDetailDrawer.open
            if (!this.procedureDetailDrawer.open) this.procedureDetailDrawer.id = null
        },
        async fetchProcedures() {
          this.loading = true;
                if(!this.$isTeclab) {
                    this.reEnrollmentOptions = (await $studentAccount.find(`reEnrollmentStatus/${this.studentRegister}`))?.data
                }else{
                    this.reEnrollmentOptions = null
                }
            const fetchedProcessings = await $processings.find(null, null, {params: {idStudent: this.studentId, studentRecord: this.studentRegister, tariff: this.reEnrollmentOptions?.tariff}})
            const fetchedStudentProcessings = await $studentProcessing.find(null, null, {params: {idStudent: this.studentId, page: 0, length:100}})
            this.fetchedStudentProcedures = fetchedStudentProcessings.data.content
            this.proceduresEnabler(fetchedProcessings.data);

            fetchedStudentProcessings.data.content.reverse().forEach((proc, index) => {
                if (proc.processingCategory.id !== 3 && index < 5) this.history.push({
                    name: proc.processingCategory.name,
                    processingCategory: proc.processingCategory.id,
                    description: `Solicitado: ${moment(proc.created, 'YYYY-MM-DD').format('DD/MM/YYYY')}`,
                    status: this.setProcessingStatus(proc.processingStatus.meaning, 'description'),
                    value: this.setProcessingStatus(proc.processingStatus.meaning, 'color'),
                    id: proc.id,
                })
            })
            this.fetchedProcedures = fetchedProcessings.data
            this.loading = false;

        },
        async proceduresEnabler(processings){
            const obligatoryDocFound = this.fetchedStudentProcedures.find(procedure => procedure.id === 16);
            const procedures = processings.procedure.filter(process => {
            if (this.$permissions.portalalumno.procedures[process.permission]  && (!obligatoryDocFound || process.id !== 16) ) {
                return true
            }
            return false
            })

            this.procedures =  procedures

            this.certificatesAcademic = processings.certificates
            if (this.$permissions.portalalumno.procedures.REENROLLMENT && !this.$isTeclab && this.studentData?.careerStatus?.value != 51) this.addReEnrollment()

        },
        setProcessingStatus (status, param) {
            if (param === 'description') return (status === 'Pendiente') ? 'Iniciado' : status
            else return (this.statusColor[status]) ? this.statusColor[status] : 'primary'
        },
      async onChipClick ({item}) {

            if (item.id == 'LEGALIZADO') {
                this.$router.push({ name: item.name});
            }else{
                const selectedCertificate = item
                const careers =  await this.getCarrerStudent(item.key)

                this.$router.push({ name: 'CertificateDownload', params: { certificateId: selectedCertificate.id, careers, description: item.description, certificateKey: item.key }});
            }
        },
        async onTramiteChipClick ({item}) {
            if (item.key === 'TNE_PAYMENT') { // id del tramite TNE
                this.$router.push({ name: 'ProcedureTNE' })
            } else if (item.id === 'renewalStatus') {
                if (this.launchAlertModal){
                    this.$store.dispatch('commons/openPopUp', {
                        title: this.$t('modules.portalalumno.procedures.proceduresInital.pendingPaymentsTitle'),
                        content: [{value: this.$t('modules.portalalumno.procedures.proceduresInital.pendingPaymentsRenewal')}],
                        actionPrimary: { text: 'Ir a pagos', callback: () => this.$router.push('/payments') },
                        actionSecondary: { text: this.$t('actions.cancel'), callback() {} },
                        icon: {color: 'warning', name: 'mdi-alert'},
                        color: 'primary'
                    });
                } else {
                    this.$router.push('/payments')
                }
            } else if (item.name === 'procedureExamCharge') {
                if (this.debt){
                    this.$store.dispatch('commons/openPopUp', {
                        title: this.$t('modules.portalalumno.procedures.proceduresInital.pendingPaymentsTitle'),
                        content: [{value: this.$t('modules.portalalumno.procedures.proceduresInital.pendingPaymentsExamTitle')}],
                        actionPrimary: { text: 'Ir a pagos', callback: () => this.$router.push('/payments') },
                        actionSecondary: { text: this.$t('actions.cancel'), callback() {} },
                        icon: {color: 'warning', name: 'mdi-alert'},
                        color: 'primary'
                    });
                } else {
                    this.$router.push({ path: item.path, params: {procedureId: item.id, needToPay: item.needToPaid }})
                }
            }else if(item.category.id === 11){ //docuemntacion obligatoria
                this.$router.push({ name: 'DocumentationUpload', params: {dataDocumentation: item }})
            }else if(item.key === 'REINCORPORATION'){
                const careers = await this.getCarrerStudent(item.key)
                this.$router.push({ name: 'reinstatement', params: {careers}});
            }else {
                if (item.isRefaundable && item.key != 'CAREER_SWITCH' ) {
                  this.$router.push({ name: item.path, params: {procedureId: 'REFUND'}})
                }else{
                    this.$router.push({ path: `procedures/${item.path}`, params: {procedureId: item.id}})
                }
            }
            this.track(item.name, 'tramites');
        },
        track(name, column) {
            if(column == 'tramites') {
                if(name == 'Rematrícula' && this.$isIPP) {
                this.$trackEvent('Tramites', 'Clk_Rematri_Tram', 'Tramites');
                } else  if(name == 'Documentacion obligatoria' && this.$isIPP) {
                this.$trackEvent('Tramites', 'Clk_DocumObli_Tram', 'Tramites');
                } else  if(name == 'Baja transitoria' && this.$isTeclab) {
                this.$trackEvent('Tramites', 'Clk_BajTrans_Tram', 'BajaTrans');
                } else  if(name == 'Baja definitiva' && this.$isTeclab) {
                this.$trackEvent('Tramites', 'Clk_BajDefino_Tram', 'BajaDefin');
                }
            } else if (column == 'historial') {
                if(name == 'Documentacion' && this.$isTeclab) {
                    this.$trackEvent('Tramites', 'Clk_DocumObli_Tram', 'Tramites');
                }
            }
        },
            onHistoryDetailClick({item}) {
                if(item.processingCategory === 11) {
                    this.$router.push({name: 'DocumentationUpload', params: {dataDocumentation: item }})
                }else{
                    this.procedureDetailDrawer.title = item.title
                    this.procedureDetailDrawer.description = `Tramite № ${item.id} - ${item.description}`
                    this.procedureDetailDrawer.open = true
                    this.procedureDetailDrawer.id = item.id
                }
                this.track(item.name, 'historial');
            },
            onHistoryTableClick(){
                this.$router.push({ name: 'ProcedureHistory'});
            },
            async addReEnrollment() {
                this.proceduresLoading = true
                const reEnrollmentObject = {
                    title: 'Rematrícula',
                    description: '',
                    path: 'RenewalStatus',
                    name: 'Rematrícula',
                    backendName: 'renewal',
                    id: 'renewalStatus',
                    specialCard: true,
                }
                const MATRICULAID = this.$sisConstants('modules.products.matricula')
                const ARANCELID = this.$sisConstants('modules.products.arancel')
                let optionStatus = {generateTicket: false}
                if (this.reEnrollmentOptions.tariff) {
                    const tariffData = await $studentAccount.find(`paymentStatus/${this.studentRegister}?idProduct=${ARANCELID}`)
                    optionStatus = tariffData.data
                } else if (this.reEnrollmentOptions.reEnrollment) {
                    const reEnrollmentData = await $studentAccount.find(`paymentStatus/${this.studentRegister}?idProduct=${MATRICULAID}`)
                    optionStatus = reEnrollmentData.data
                }
                const { generateTicket } = optionStatus
                const { reEnrollment, tariff, debt } = this.reEnrollmentOptions
                this.debt = debt
                this.launchAlertModal = tariff && debt && !reEnrollment
                if (tariff ? !generateTicket : reEnrollment) {
                    this.procedures.unshift(reEnrollmentObject)
                }
                this.proceduresLoading = false
            },
            async getCarrerStudent(id){
                try {
                    const response =  await $processings.find(`studentCareersInformation/${this.studentId}`, null, {params: {processing: id}})
                    return response.data
                } catch (error) {
                     //empty
                }
            }
        }
    }

</script>
