<template>
  <v-container fluid class="fullHeight scrollableContent">
    <v-container>
      <v-row>
        <v-col cols="12" sm="8" lg="6" class="offset-sm-2 offset-lg-3">

          <div class="py-6 d-flex flex-column">
            <div class="d-flex justify-start">
              <v-img
                max-width="100px" height="auto" contain
                :src="logoPath"
              ></v-img>
            </div>
            <div class="mt-3">
              <h4 class="display-1 primary--text">{{ $t('modules.validator.validator.title') }}</h4>
            </div>
          </div>
          <OutlinedCard>
              <v-row>
                <v-col cols="12">
                  <p class="body-2 pa-0 ma-2">{{$t('modules.validator.validator.inputRut')}}</p>
                  <v-text-field
                    :label="$t('modules.validator.validator.rut')"
                    v-model.trim="rut"
                    :error-messages="this.$v.rut.$anyDirty && this.$v.rut.$anyError ? $t('modules.validator.validator.rutRequired') : ''"
                    outlined
                    clearable
                    @keyup.enter="validateCode"
                    @keyup.esc="() => {this.rut = null}"
                    @input="$v.rut.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <p class="body-2 pa-0 ma-2">{{$t('modules.validator.validator.inputCode')}}</p>
                  <v-text-field
                    :label="$t('modules.validator.validator.code')"
                    v-model.trim="code"
                    :error-messages="showError ? $t('modules.validator.validator.errorMessage') : ''"
                    outlined
                    clearable
                    @keyup.enter="validateCode"
                    @keyup.esc="() => {this.code = null}"
                    @input="$v.code.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <Button
                    :loading="validateButton.loading"
                    :success="validateButton.success"
                    :error="validateButton.error"
                    :text="$t('modules.validator.validator.button')"
                    :successText="$t('modules.validator.validator.validated')"
                    :errorText="$t('actions.error')"
                    :disabled="!canVerifyCode"
                    @end="resetButtonValues"
                    @clicked="validateCode"
                  ></Button>
                </v-col>
              </v-row>
          </OutlinedCard>
          <div v-if="showInfo">
            <h3 class="display-0 mt-10 mb-5 borderLine">{{ info.processingName ? info.processingName : $t('modules.validator.validator.certificateTitle') }}</h3>

            <div class="display-0"><strong>{{ $t('modules.validator.validator.rut') }}:</strong> {{ info.rut }}</div>
            <div class="display-0"><strong>{{ $t('modules.validator.validator.name') }}:</strong> {{ info.student }}</div>
            <div class="display-0"><strong>{{ $t('modules.validator.validator.career') }}:</strong> {{ info.career }}</div>
            <div class="display-0" v-if="info.title "><strong>{{ $t('modules.validator.validator.titleName') }}:</strong> {{ info.title }} </div>
            <div class="display-0" v-if="info.ceo"><strong>{{ $t('modules.validator.validator.ceo') }}:</strong> {{ info.ceo }}</div>
            <div class="display-0" v-if="info.enrollmentTerm"><strong>{{ $t('modules.validator.validator.period') }}:</strong> {{ info.enrollmentTerm }}</div>
            <div class="display-0" v-if="info.status"><strong>{{ $t('modules.validator.validator.status') }}:</strong> {{ info.status }} </div>
            <div class="display-0" v-if="info.careerType"><strong>{{ $t('modules.validator.validator.careerType') }}:</strong> {{ info.careerType }}</div>
            <div class="display-0" v-if="info.dateTitle"><strong>{{ $t('modules.validator.validator.dateTitle') }}:</strong> {{ info.dateTitle }}</div>
            <div class="display-0" v-if="info.egressDate"><strong>{{ $t('modules.validator.validator.egressDate') }}:</strong> {{ info.egressDate }} </div>
          </div>

        </v-col>
      </v-row>
    </v-container>
  </v-container>

</template>

<script>
import Constants from '@/plugins/constants'
import Button from '@/components/Button/Button'
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard'
import { required, minLength } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex';

export default {
  name: 'ValidatorView',
  components:{
    Button,
    OutlinedCard
  },
  data () {
    return {
      url_data_server: Constants.URL_DATA_SERVER,
      code: null,
      rut: null,
      info: {
        rut: null,
        student: null,
        career: null,
        ceo: null,
        enrollmentTerm: null,
        dateTitle: null,
        title: null,
        status: null,
        careerType: null,
        egressDate: null,
        processingName: null,
      },
      showInfo: false,
      showError: false,
      validateButton: {
        loading: false,
        success: false,
        error: false
      }
    }
  },
  computed: {
    canVerifyCode() {
      return this.$v.code.$anyDirty && !this.$v.code.$anyError && this.$v.rut.$anyDirty && !this.$v.rut.$anyError
    },
    ...mapGetters({
      logoPath: 'commons/getLogoImageUrl',
    }),
  },
  methods: {
    resetButtonValues() {
      this.validateButton.loading = false
      this.validateButton.success = false
      this.validateButton.error = false
    },
    async validateCode(){
      this.showInfo = false
      this.showError = false
      if(this.canVerifyCode) {
        this.validateButton.loading = true
        fetch(`${Constants.URL_STUDENT_SERVER}/studentProcessing/validate-code/${this.rut}?registro=${this.code}`, { headers: {
          Authorization: 'Basic cG9ydGFsLXRlY2xhYjpUaDEkSXNNeVN1cGVyU2VjcjN0'
        }}).then(async resp => {
          if (resp.status == 200) {
            this.info = await resp.json()
            this.info.dateTitle = this.info.dateTitle? new Date(this.info.dateTitle).getFullYear() : null ;
            this.info.egressDate = this.info.egressDate? new Date(this.info.egressDate).getFullYear() : null ;
            this.showInfo = true
            this.validateButton.success = true
           }
          else {
            this.showInfo = false
            this.showError = true
            this.validateButton.error = true
          }
        }).finally(() => {
          this.resetButtonValues()
        })
      }
    }
  },
  validations: {
    code: {
      required,
      minLength: minLength(5),
    },
    rut: {
      required,
      minLength: minLength(6),
    },
  },
}
</script>

<style lang="sass" scoped>
  .borderLine
      border-bottom: 2px solid #014c84

  .borderLine:after
      content: ''
      display: block
      border-bottom: 2px solid #ff9d0b
      margin-bottom: -2px
      max-width: 85%
</style>
