<template>
  <v-container fluid class="pa-0 pb-8">
    <v-container fluid>
      <Breadcrumbs :title="$t('modules.portalalumno.payments.paymentsContracts.view.contract.breadcrumbs.title')"
        :description="$t('modules.portalalumno.payments.paymentsContracts.view.contract.breadcrumbs.description')">
      </Breadcrumbs>
      <NoContractsAlert v-if="!loading && !error && data.length === 0"
        :message="$t('modules.portalalumno.payments.paymentsContracts.view.contract.message')" />
      <ContractTable v-if="!loading && !error && data.length > 0" :contracts="data" />
    </v-container>
  </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import NoContractsAlert from '../NoContractsAlert/NoContractsAlert.vue';
import ContractTable from '../ContractTable/ContractTable.vue';
import { $studentListContracts } from '../../Services';
import { mapGetters } from 'vuex';

export default {
  components: {
    Breadcrumbs,
    NoContractsAlert,
    ContractTable,
  },
  data() {
    return {
      data: [],
      loading: false,
      error: false,
    };
  },
  computed: {
    ...mapGetters({
      studentId: 'commons/getStudentId',
    }),
  },
  methods: {
    async fetchContract() {
      this.loading = true;
      this.error = false;
      try {
        const response = await $studentListContracts.findById(this.studentId);
        this.data = response.data || [];
      } catch (err) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.fetchContract();
  },
};
</script>
