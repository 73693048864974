<template>
  <v-container fluid class="d-flex pa-0 fullHeight">
    <AsideBar :items="asidebar.items" :title="asidebar.title" @changed="fetchData"></AsideBar>
    <v-container fluid class="scrollableContent pa-0">
      <v-container class="container-custom px-6">
      <v-row>
                <v-col>
                    <Breadcrumbs
                        :title="$t('modules.academic.sanctions.sanctionAssign.table.title')"
                        :description="$t('modules.academic.sanctions.sanctionAssign.table.description')"
                    >
                    <Button
                      icon=""
                      :text="$t('modules.academic.sanctions.sanctionAssign.table.btnAssign')"
                      depressed
                      @clicked="assignSanction"
                    />
                    </Breadcrumbs>
                </v-col>
      </v-row>
      <v-row justify="center">
        <v-col sm="12" class="viewSpaces">
          <SuperTable
          :sanctions="sanctionsAssigned"
          :headers="headers"
          :permissions="{ update: canUpdate, delete: canDelete }"
          :query="query"
          :sortBy.sync="sort.sortBy"
          :sortDesc.sync="sort.sortDesc"
          :searchBlur="true"
          :deleteTitle="$t('modules.academic.sanctions.table.bulkDelete')"
          :page-count="pagination.pagesAmount"
          :page="pagination.page"
          :total-items="totalItems"
          @openDetails="(id)=>{openDetailsSanction(id)}"
          @deleteUser="(id) => deleteSanctionPopUp(id)"
          @searchAction="searchAction"
          @input-pagination="onInputPagination"
          @length-pagination-change="onLengthPaginationChange"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-container>
  </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import AsideBar from '@/components/AsideBar/AsideBar';
import Button from '@/components/Button/Button';
import SuperTable from '../Components/Supertable/Supertable';
import { $sanctionStudents, $types } from '../Services'
export default {
  name: 'SanctionAssignedTable',
  components: {
    Breadcrumbs,
    Button,
    AsideBar,
    SuperTable
  },
  data(){
    return{
      loading: false,
      sanctionsAssigned: [],
      sanctionType: null,
      sanctionLevelType: null,
      query: '',
      asidebar: {
        title: 'Refinar búsqueda',
        items: [
          {
            name: 'Area',
            filters: []
          },
          {
            name: 'Tipo',
            filters: []
          },
          {
            name: this.$t('modules.users.table.headers.status'),
            filters: [
              {
                name: this.$t('modules.users.table.status.actives'),
                type: 'check',
                value: true,
                id: true,
                dataFiltered: ''
              },
              {
                name: this.$t('modules.users.table.status.inactives'),
                type: 'check',
                value: true,
                id:false,
                dataFiltered: ''
              },
            ]
          },
        ],
      },
      headers: [
                    {
                      text: this.$t('modules.academic.sanctions.sanctionAssign.table.headers.column_1'),
                      value: 'userCreate.name',
                    },
                    {
                      text: this.$t('modules.academic.sanctions.sanctionAssign.table.headers.column_2'),
                      value: 'createDate',
                    },
                    {
                      text: this.$t('modules.academic.sanctions.sanctionAssign.table.headers.column_3'),
                      value: 'sanction.sanctionType.meaning',
                    },
                    {
                      text: this.$t('modules.academic.sanctions.sanctionAssign.table.headers.column_4'),
                      value: 'sanction.sanctionLevelType.meaning',
                    },
                    {
                      text: this.$t('modules.academic.sanctions.sanctionAssign.table.headers.column_5'),
                      value: 'student.name',
                    },
                    {
                      text: '',
                      value: 'status'
                    },
                    {
                      text: this.$t('modules.academic.sanctions.sanctionAssign.table.headers.column_7'),
                      value: 'dateEndLetter',
                    },
                    {
                      text: '',
                      value: 'action'
                    }
                ],
      sort: {
        sortBy: 'name',
        sortDesc: false,
      },
      pagination: {
          limit: 20,
          page: 1,
          pagesAmount: 1
        },
      page: 1,
      identification: null,
      totalItems: null,

    }
  },
  mounted() {
    this.getInitialData();
  },
  methods: {
    async getInitialData() {
      this.getSanctionTypes();
      this.getSanctionLevel();
      this.getAssignedSanctions();
    },
    async getAssignedSanctions() {
      const params = {
                        page: this.pagination.page - 1,
                        pageLength: this.pagination.limit,
                        orientation: this.sort.sortDesc ? 'desc' : 'asc',
                        ...(this.identification ? {identification:this.identification} : {})
                    };
      const { data } = await $sanctionStudents.find('', null, { params });
      if(data){
        data.content.forEach(el => {
          el.dateEnd ? el.dateEndLetter = el.dateEnd : el.dateEndLetter = 'Permanente';
        })
        this.sanctionsAssigned = data.content;
        this.totalItems = data.totalElements;
        this.pagination.pagesAmount = data.totalPages;
      }
    },
    assignSanction() {
      this.$router.push('assigned/create');
    },
    searchAction(data) {
                this.pagination.page = 1;
                this.identification = data;
                this.getAssignedSanctions();
    },
    onInputPagination(event) {
      this.pagination.page = event;
      this.fetchData();
    },
    onLengthPaginationChange(event) {
      this.pagination = {
            page: 1,
            limit: event,
            pagesAmount: 1
        };
        this.fetchData();
      },

    async fetchData() {
      if (this.loading) return false
      this.loading = true
      try {
        const body = {
          ...(this.query ? {identification:this.query} : {}),
          page: this.pagination.page,
          sanctionType: this.selectedAreas,
          sanctionLevel: this.selectedTypes,
          status: this.selectedStatus
        };
        const params = {
                        page: this.pagination.page - 1,
                        pageLength: this.pagination.limit,
                    };
        const { data } = await $sanctionStudents.filter(body,{params});
        data.content.forEach(el => {
          el.dateEnd ? el.dateEndLetter = el.dateEnd : el.dateEndLetter = 'Permanente';
        })
        this.sanctionsAssigned = data.content;
        this.totalItems = data.totalElements;
        this.pagination.pagesAmount = data.totalPages;
      } catch (error) {
          this.ranges = []
          this.totalItems = 0
          throw error
      } finally {
          this.loading = false
      }
    },

    openDetailsSanction(value) {
      this.$router.push({
        path: `assigned/update/${value}`,
        params: {
          id: value,
        }

      })
    },
    async getSanctionTypes() {
      try{
        const { data } = await  $types.find('', null, {params:{type:'SANCTION_TYPE'}})
        this.sanctionType = data.map(el => {
            el.type='check';
            el.id = el.value;
            el.value= true;
            el.name = el.meaning
          return {
          ...el
          }
        })
        this.asidebar.items[0].filters = this.sanctionType;
      }catch{
        this.sanctionType = [];
      }
    },
    async getSanctionLevel() {
      try{
      const { data } = await $types.find('', null, {params:{type:'SANCTION_LEVEL_TYPE'}})
        this.sanctionLevelType = data.map(el => {
          el.type='check';
            el.id = el.value;
            el.value= true;
            el.name = el.meaning
            return{
              ...el
            };
        })
        this.asidebar.items[1].filters = this.sanctionLevelType;
      }catch{
        this.sanctionLevelType = [];
      }
    },
    deleteSanctionPopUp (id) {
      const sanction = this.sanctionsAssigned.find(sanction => sanction.id == id);
      this.$store.dispatch('commons/openPopUp', {
          title: this.$t('delete_popup_title'),
          content: [{ value: sanction.description }],
          actionPrimary: { text: this.$t('actions.accept'), callback: () => this.deleteSanctionAssigned(id) },
          actionSecondary: { text: this.$t('actions.cancel'), callback () { } },
          icon: { color: 'error', name: 'mdi-delete' },
          color: 'primary',
      })
    },
    async deleteSanctionAssigned(id) {
      const idToDelete = id;
      try{
        await $sanctionStudents.delete(idToDelete);
        this.getInitialData();
      }
      catch{
        this.getInitialData()
      }
    }
  },
  computed: {
    selectedAreas() {
      const filterArray = this.asidebar.items[0].filters.filter(el => el.value === true)
      return filterArray.map(el => el.id);
    },
    selectedTypes() {
      const filterArray = this.asidebar.items[1].filters.filter(el => el.value === true);
      return filterArray.map(el => el.id);
    },
    selectedStatus() {
      const filterArray = this.asidebar.items[2].filters.filter(el => el.value === true)
      return filterArray.map(el => el.id);

    },
    canDelete() {
                return this.$permissions.portaladministrativo.academic.sanctions.ASSIGNED_DELETE
    },
    canUpdate() {
                return this.$permissions.portaladministrativo.academic.sanctions.ASSIGNED_UPDATE
    },

  }
}
</script>
