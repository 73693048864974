
const alumnosDatosGenerales = {
    title: 'Datos Generales Alumnos',
    description: 'Este reporte retorna un listado de alumnos con información general de los mismos.',
    detail: [
        { label: 'Archivo', value: 'Reporte alumnosDatosGenerales.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span><span class="country-chip argentina">Argentina</span>' },
        { label: 'Filtros', value: 'General' },
        { label: 'Criterios', value: 'Actualmente trae estudiantes pre matriculados, cursando o sean baja.' },
    ],
    example: {
        header: [
            'Id usuario',
            'Nombre',
            'Apellido',
            'Nro Doc',
            'Legajo',
            'Email',
            'Fecha Nac',
            'Carrera',
            'Ceo',
            'Localidad',
            'Provincia',
            'Periodo Inicio',
            'Aprobadas',
            'Regulares',
            'Pedidos exámen',
            'PPs',
            'Teléfono',
            'Usuario',
            'Canvas id',
            'Último per abonado',
            'Genero',
            'Zona comercial',
            'Dirección',
            'Número',
            'CP',
            'Estado Carrera',
            'Plan de Estudio'
        ],
        data: [
            [
                '123456',
                'ALUMNO',
                'PRUEBA',
                '12345678',
                '1234LEGAJO',
                'prueba@gmail.com',
                '28/07/2000',
                'Carrera Prueba',
                'PRUEBA',
                'PRUEBA',
                'PREUBA',
                '2A-2000',
                '1',
                '1',
                'null',
                'null',
                '+123456798',
                '12346789',
                '12345',
                '2B 2222',
                'Femenino',
                'Zona A',
                'Prueba 12',
                '111',
                '12345',
                'Cursando',
                '1234',
            ],
            [
                '555555',
                'ALUMNO',
                'PROBANDO',
                '55555555',
                '5555LEGAJO',
                'prueba@gmail.com',
                '28/07/2000',
                'Carrera Prueba',
                'PROBANDO',
                'PROBANDO',
                'PROBANDO',
                '2A-5555',
                '5',
                '5',
                'null',
                'null',
                '+555555555',
                '55555555',
                '55555',
                '2B 5555',
                'Femenino',
                'Zona A',
                'Prueba 55',
                '555',
                '55555',
                'Baja',
                '5555',
            ],
        ],
    },
}

// documentations[this.reports.service][this.reports.url]
const admissions = {
    title: 'Admisión / Admisiones',
    description: 'Este reporte muestra los nuevos ingresantes para cada período.',
    detail: [
        { label: 'Archivo', value: 'Reporte Admisión.xlsx' },
        { label: 'Países', value: '<span class="country-chip argentina">Argentina</span> <span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'El estado del estudiante debe indicar "Cursando"' },
        { label: 'Criterios', value: 'Este reporte actualmente muestra pre-inscripciones.' },
    ],
    example: {
        header: [
            'Nombre',
            'Apellido',
            'dni',
            'Mail',
            'Localidad',
            'Provincia',
            'Carrera',
            'Código agente',
            'Localidad agente',
            'Prov. agente',
            'Fecha PreIns',
            'Per. PreIns',
            'Usr PreIns',
            'Fecha Matri',
            'Per. Matri',
            'Usuario Tomo Matri',
            'Fecha Pago Arancel',
            'Per. Arancel',
            'Usuario Tomo Aran',
            'Legajo',
            'Celular',
            'Fecha pre Real',
            'Contacto Hubspot',
        ],
        data: [
            [
                'Alumno ',
                'Prueba',
                '12345678',
                'prueba@hotmail.com',
                'PRUEBA',
                'PRUEBA',
                'Carrera Prueba',
                '1234',
                'CIUDAD PRUEBA',
                'PRUEBA',
                '18/10/2022',
                '2B 2022',
                'prueba@teclab.edu.ar',
                '18/10/2022',
                '2B 2022',
                'prueba@teclab.edu.ar',
                '18/10/2022',
                '2B 2022',
                'prueba@teclab.edu.ar',
                '12345678',
                '+54 11 1234-5678',
                '18/10/2022',
                '12345678',
            ],
            [
                'Alumno ',
                'Probando',
                '77777777',
                'probando@hotmail.com',
                'PROBANDO',
                'PROBANDO',
                'Carrera Prueba',
                '7777',
                'CIUDAD PRUEBA',
                'PROBANDO',
                '18/10/2022',
                '2B 2022',
                'probando@teclab.edu.ar',
                '18/10/2022',
                '2B 2022',
                'probando@teclab.edu.ar',
                '18/10/2022',
                '2B 2022',
                'probando@teclab.edu.ar',
                '77777777',
                '+54 11 7777-7777',
                '18/10/2022',
                '77777777',
            ],
        ],
    },
}

const performance = {
    title: 'Reinscriptos',
    description: 'Este reporte muestra a los estudiantes que cumplen la condición de ser nuevos inscriptos (NI) para determinado período.',
    detail: [
        { label: 'Archivo', value: 'Reporte Admisión.xlsx' },
        { label: 'Países', value: '<span class="country-chip argentina">Argentina</span>' },
        { label: 'Filtros', value: 'No deben haber realizado un pago de arancel por un período anterior, independientemente si fue en la carrera actual u otra.' },
        { label: 'Criterios', value: 'Se consideran NI a los estudiantes que habiendo pagado matrícula pagan por primera vez un arancel.' },
    ],
    example: {
        header: [
            'Nombre',
            'Apellido',
            'dni',
            'Mail',
            'Localidad',
            'Provincia',
            'Carrera',
            'Código agente',
            'Localidad agente',
            'Prov. agente',
            'Fecha PreIns',
            'Per. PreIns',
            'Usr PreIns',
            'Fecha Matri',
            'Per. Matri',
            'Usuario Tomo Matri',
            'Fecha Pago Arancel',
            'Per. Arancel',
            'Usuario Tomo Aran',
            'Legajo',
            'Celular',
            'Fecha pre Real',
            'Contacto Hubspot',
        ],
        data: [
            [
                'Alumno',
                'Prueba',
                '123456789',
                'prueba@outlook.es',
                'CIUDAD PRUEBA',
                'CIUDAD PRUEBA',
                'Carrera',
                '1',
                'CIUDAD PRUEBA',
                'CIUDAD PRUEBA',
                '07/01/2023',
                '1A 2023',
                'prueba@teclab.edu.ar',
                '06/01/2023',
                '1A 2023',
                '2',
                '09/01/2023',
                '1A 2023',
                '3',
                '1234LEGAJO',
                '+54 123 1234-5678',
                '07/01/2023',
                '1',
            ],
            [
                'Alumno',
                'Probando',
                '333333333',
                'probando@outlook.es',
                'CIUDAD PROBANDO',
                'CIUDAD PROBANDO',
                'Carrera',
                '3',
                'CIUDAD PROBANDO',
                'CIUDAD PROBANDO',
                '03/03/2023',
                '3A 2023',
                'probando@teclab.edu.ar',
                '03/03/2023',
                '3A 2023',
                '3',
                '03/03/2023',
                '3A 2023',
                '3',
                '3333LEGAJO',
                '+54 123 3333-3333',
                '03/03/2023',
                '3',
            ],
        ],
    },
}

const performanceRi = {
    title: 'Reinscriptos',
    description: 'Este reporte muestra a los estudiantes que cumplen la condición de ser RI para determinado período.',
    detail: [
        { label: 'Archivo', value: 'Reporte Reinscriptos.xlsx' },
        { label: 'Países', value: '<span class="country-chip argentina">Argentina</span>' },
        { label: 'Filtros', value: 'Deben haber realizado un pago por un período anterior, independientemente si fue en la carrera actual u otra.' },
        { label: 'Criterios', value: 'No se consideran como RI los alumnos que habiendose egresado de una carrera comienzan otra.' },
    ],
    example: {
        header: [
            'Nombre',
            'Apellido',
            'Nro Doc',
            'Email',
            'Legajo',
            'Carrera',
            'Código Agente',
            'Localidad Agente',
            'Prov. Agente',
            'Fecha Matri',
            'Hora Matri',
            'Per. Matri',
            'Fecha Pago Arancel A',
            'Hora Pago Arancel A',
            'Per. Arancel A',
            'Fecha Pago Arancel B',
            'Hora Pago Arancel B',
            'Per. Arancel B',
        ],
        data: [
            [
                'ALUMNO',
                'PRUEBA',
                '12345678',
                'prueba@gmail.com',
                '1234LEGAJO',
                'Carrera Prueba',
                '1234',
                'PRUEBA',
                'PRUEBA',
                '12345',
                '2022-10-25 08:47:58.792',
                '2B 2022',
                'null',
                'null',
                'null',
                '12346',
                '2022-10-25 08:47:58.794',
                '2B 2022',
            ],
            [
                'ALUMNO',
                'PROBANDO',
                '66666666',
                'probando@gmail.com',
                '6666LEGAJO',
                'Carrera Probando',
                '6666',
                'PROBANDO',
                'PROBANDO',
                '66666',
                '2022-10-25 08:47:58.792',
                '6B 2022',
                'null',
                'null',
                'null',
                '66666',
                '2022-10-25 08:47:58.794',
                '6B 2022',
            ],
        ],
    },
}

const graduate = {
    title: 'Graduados y Titulados',
    description: 'Este reporte muestra a los estudiantes que se hayan graduado en IPP y si dieron el examen de titulación. Con datos de fechas y notas.',
    detail: [
        { label: 'Archivo', value: 'Reporte generateGraduateAndTitleReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'El estudiante debe tener fecha de graduación o graduación intermedia o acta final o intermedia.' },
        { label: 'Criterios', value: 'Los titulados deben contar con acta de titulación.' },
    ],
    example: {
        header: [
            'RUT',
            'Nombre',
            'Apellido',
            'Correo',
            'Celular',
            'Género',
            'Nacionalidad',
            'Región',
            'Ciudad',
            'Dirección',
            'Carrera',
            'Legajo',
            'Estado Carrera',
            'Fecha Inicio Carrera',
            'Fecha Egreso Intermedio',
            'Fecha Egreso Final',
            'Cant. Actas',
            'Acta Tit. Intermedio',
            'Título Intermedio',
            'Fecha Tit. Intermedio',
            'Nota Examen Tit. Intermedio',
            'Acta Tit. Final',
            'Título Final',
            'Fecha Tit. Final',
            'Nota Examen Tit. Final',
            'Promedio Asignaturas',
            'Nota Final',
        ],
        data: [
            [
                '12345678',
                'ALUMNO',
                'PROBANDO',
                'probando@hotmail.com',
                '12345678',
                'Masculino',
                'Chilena',
                'REGION DE PROBANDO',
                'PROBANDO',
                'VILLA PROBANDO 1234',
                'CARRERA PROBANDO',
                '1234',
                'Egresado',
                '01/01/2022',
                '02/02/2022',
                '03/03/2022',
                '4',
                '567890',
                'CARRERA PROBANDO',
                '05/05/2022',
                '6.7',
                '123456',
                'ASISTENTE PROBANDO',
                '07/07/2022',
                '8.9',
                '10.11',
                '12.13',
            ],
            [
                '11111111',
                'ALUMNO',
                'PRUEBA',
                'prueba@hotmail.com',
                '11111111',
                'Masculino',
                'Chilena',
                'REGION DE PRUEBA',
                'PRUEBA',
                'VILLA PRUEBA 1111',
                'CARRERA PRUEBA',
                '1111',
                'Egresado',
                '01/01/2022',
                '02/02/2022',
                '03/03/2022',
                '4',
                '111111',
                'CARRERA PRUEBA',
                '01/01/2022',
                '6.7',
                '111111',
                'ASISTENTE PRUEBA',
                '01/01/2022',
                '8.9',
                '10.11',
                '12.13',
            ],
        ],
    },
}

const alumnosDatosEgresados = {
    title: 'Egresados',
    description: 'Este reporte muestra el listado total de los estudiantes que egresaron con los datos de contacto.',
    detail: [
        { label: 'Archivo', value: 'Reporte alumnosDatosEgresados.xlsx' },
        { label: 'Países', value: '<span class="country-chip argentina">Argentina</span>' },
        { label: 'Filtros', value: 'El estado de la carrera del estudiante debe ser egresado o debe tener cargada una fecha de egreso.' },
        { label: 'Criterios', value: 'En este reporte se agregaron los campos de cantidad de materias aprobadas y el estado de la documentación obligatoria para realizar un chequeo de cada estudiante.' },
    ],
    example: {
        header: [
            'Id Estudiante',
            'Nro Doc',
            'Nombre',
            'Apellido',
            'Fecha inicio',
            'Legajo',
            'Periodo',
            'Carrera',
            'Provincia',
            'Código carrera',
            'Tipo',
            'Teléfono',
            'Mail',
            'Fecha Egresado',
            'Localidad',
            'Fecha último examen',
            'Estado documentación obligatoria',
            'Cantidad materias aprobadas',
        ],
        data: [
            [
                '123456',
                '12345678',
                'ALUMNO',
                'PRUEBA',
                '1234-56-78 90:12:34.5',
                '1234567890',
                '12/3456',
                'Carrera Prueba',
                'CIUDAD PRUEBA',
                '1234',
                'EGRESADO',
                '',
                'prueba@gmail.com',
                '12-34-5678',
                'CIUDAD PRUEBA',
                '12-34-5678',
                'Cursando',
                '12',
            ],
            [
                '444444',
                '44444444',
                'ALUMNO',
                'PROBANDO',
                '4444-44-44 44:44:44.4',
                '4444444444',
                '44/4444',
                'Carrera Prueba',
                'CIUDAD PROBANDO',
                '4444',
                'EGRESADO',
                '',
                'probando@gmail.com',
                '44-44-4444',
                'CIUDAD PROBANDO',
                '44-44-4444',
                'Cursando',
                '44',
            ],
        ],
    },
}

const curricularAdvancement = {
    title: 'Avance curricular SIES',
    description: 'Se utiliza para informar al Servicio de Información de Educación Superior: sus siglas “SIES” a mitad de año un reporte de avance académico semestral correspondiente al año anterior de la solicitud.',
    detail: [
        { label: 'Archivo', value: 'Reporte generateCurricularAdvancementReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'Todos los estudiantes.' },
        { label: 'Criterios', value: 'Las columnas importantes además de los datos personales, legajo, rut y carrera, son el número de asignaturas cursadas, aprobadas, lo ideal es que incluyera columnas con información semestral, porque así lo solicita la entidad.' },
    ],
    example: {
        header: [
            'LEGAJO',
            'RUT',
            'DIGITO VERIFICADOR',
            'APELLIDO MATERNO',
            'APELLIDO PATERNO',
            'NOMBRES',
            'SEXO',
            'FECHA NACIMIENTO',
            'PERÍODO INGRESO',
            'CÓDIGO CARRERA',
            'NOMBRE CARRERA',
            'CÓDIGO PLAN ESTUDIO',
            'ESTADO CARRERA',
            'ÚLTIMA MATRÍCULA',
            'CURSADAS PRIMER SEMESTRE',
            'CURSADAS SEGUNDO SEMESTRE',
            'CURSADAS',
            'APROBADAS',
            'CURSADAS CARRERA',
            'APROBADAS CARRERA',
            'ASIGNATURAS RESTANTES EGRESO',
            'ASIGNATURAS RESTANTES TÍTULO',
        ],
        data: [
            [
                '12345LEGAJO',
                '12345678',
                '1',
                'PRUEBA',
                'PRUEBA',
                'ALUMNO',
                'Femenino',
                '12/34/5678',
                '1A/2345',
                '1234',
                'CARRERA PRUEBA',
                '1234PE/5678-1',
                'Cursando',
                '1B/2345',
                '0',
                '1',
                '2',
                '3',
                '12',
                '11',
                '12',
                '13',
            ],
            [
                '77777LEGAJO',
                '77777777',
                '7',
                'PROBANDO',
                'PROBANDO',
                'ALUMNO',
                'Femenino',
                '77/77/7777',
                '7B/7777',
                '7777',
                'CARRERA PROBANDO',
                '7777PE/7777-7',
                'Egresado',
                '7B/7777',
                '7',
                '7',
                '7',
                '7',
                '77',
                '77',
                '77',
                '77',
            ],
        ],
    },
}

const academic = {
    title: 'Carga académica',
    description: 'Este reporte muestra la cantidad de asignaturas cargadas para cada período por carrera y estudiante.',
    detail: [
        { label: 'Archivo', value: 'Reporte generateCurricularAdvancementReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'El estudiante debe tener cargada al menos una asignatura.' },
        { label: 'Criterios', value: 'No se consideran las asignaturas deshabilitadas, anuladas ni los exámenes de título.' },
    ],
    example: {
        header: [
            'LEGAJO',
            'RUT',
            'DIGITO VERIFICADOR',
            'ESTUDIANTE',
            'SEDE',
            'CARRERA',
            'PLAN DE ESTUDIO',
            'ESTADO_CARRERA',
            'INGRESO',
            'ULTIMA REMATRICULA',
            'PERIODO',
            'CANTIDAD DE ASIGNATURAS',
        ],
        data: [
            [
                '1234LEGAJO',
                '123456',
                '1',
                'ALUMNO PRUEBA',
                '123 - CENTRO PRUEBA',
                '1234P - CARRERA PRUEBA',
                '1234P/5678',
                '12 - Cursando',
                '123 - 1A/2345',
                '123 - 1A/2345',
                '1A/2345',
                '1',
            ],
            [
                '8888LEGAJO',
                '888888',
                '8',
                'ALUMNO PROBANDO',
                '888 - CENTRO PROBANDO',
                '8888P - CARRERA PROBANDO',
                '8888P/8888',
                '88 - Egresado',
                '888 - 8A/8888',
                '888 - 8A/8888',
                '8A/8888',
                '8',
            ],
        ],
    },
}

const inscriptions = {
    title: 'Inscripciones',
    description: 'Este reporte muestra las inscripciones de cada estudiante a cada asignatura.',
    detail: [
        { label: 'Archivo', value: 'Reporte generateInscriptionsReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'El estado de la asignatura para el estudiante debe ser Aprobada, Regular, En curso o Libre.' },
        { label: 'Criterios', value: 'Se muestra el avance de cada estudiante en cada asignatura, mostrando las notas y fechas de los módulos.' },
    ],
    example: {
        header: [
            'LEGAJO',
            'RUT',
            'DV',
            'NOMBRE',
            'ESTADO ESTUDIANTE',
            'REGLAMENTO',
            'SEDE',
            'CARRERA',
            'PLAN DE ESTUDIO',
            'ESTADO CARRERA',
            'INGRESO',
            'ULTIMA MATRICULA',
            'CODIGO MATERIA',
            'MATERIA',
            'CODIGO SECCION',
            'SECCION',
            'DURACION MATERIA',
            'ESTADO MATERIA',
            'FECHA ESTADO MATERIA',
            'LMS ID',
            'TIPO EXAMEN',
            'OBSERVACION',
            'MOD1',
            'MOD2',
            'MOD3',
            'MOD4E1',
            'MOD4E2',
            'MOD4E3',
            'INTENTOS MOD1',
            'INTENTOS MOD2',
            'INTENTOS MOD3',
            'INTENTOS MOD4E1',
            'INTENTOS MOD4E2',
            'INTENTOS MOD4E3',
            'FECHA MOD1',
            'FECHA MOD2',
            'FECHA MOD3',
            'FECHA MOD4E1',
            'FECHA MOD4E2',
            'FECHA MOD4E3',
            'NOTA EXAMEN',
            'NOTA FINAL',
            'RUT PROFESOR',
            'NOMBRE PROFESOR',
        ],
        data: [
            [
                '1234LEGAJO',
                '12345678',
                '1',
                'ALUMNO PRUEBA',
                '12 Vigente',
                'R20',
                '123 - CENTRO PRUEBA',
                '1234P - CARRERA PRUEBA',
                '1234P/5678',
                '12 - Cursando',
                '123 - 1A/2345',
                '123 - 1A/2345',
                'TRA123',
                'MATERIA PRUEBA',
                '1234',
                'TRA123-MATERIA PRUEBA 1',
                'null',
                '12 - En curso',
                '1234-56-78',
                '123456',
                'Sin Examen',
                'null',
                '1.23',
                '1.23',
                'null',
                'null',
                'null',
                'null',
                '1',
                '1',
                'null',
                'null',
                'null',
                'null',
                '1234-56-78 90:12:34.5',
                '1234-56-78 90:12:34.5',
                'null',
                'null',
                'null',
                'null',
                'null',
                'null',
                '12345678-1',
                'PROFESOR PRUEBA',
            ],
            [
                '8888LEGAJO',
                '88888888',
                '8',
                'ALUMNO PROBANDO',
                '88 Vigente',
                'R20',
                '888 - CENTRO PROBANDO',
                '8888P - CARRERA PROBANDO',
                '8888P/8888',
                '88 - Cursando',
                '888 - 8B/8888',
                '888 - 8B/8888',
                'TRA888',
                'MATERIA PROBANDO',
                '8888',
                'TRA888-MATERIA PROBANDO 8',
                'null',
                '88 - En curso',
                '8888-88-88',
                '888888',
                'Sin Examen',
                'null',
                '8.88',
                '8.88',
                'null',
                'null',
                'null',
                'null',
                '8',
                '8',
                'null',
                'null',
                'null',
                'null',
                '8888-88-88 88:88:88.8',
                '8888-88-88 88:88:88.8',
                'null',
                'null',
                'null',
                'null',
                'null',
                'null',
                '88888888-8',
                'PROFESOR PROBANDO',
            ],
        ],
    },
}

const confirmedSubjects = {
    title: 'Asignaturas confirmadas',
    description: 'Este reporte entrega un listado de estudiantes con las materias que tiene confirmadas para determinado período.',
    detail: [
        { label: 'Archivo', value: 'Reporte generateConfirmedSubjectsReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'Se muestran seminarios y trabajos de título, inglés y prácticas profesional, laboral y laboral educativa. ' },
        { label: 'Criterios', value: 'Las prácticas profesional, laboral y laboral educativa deben estar "en curso" para el estudiante. Las asignaturas de inglés son todos los períodos y los seminarios y trabajos de títulos del período selecionado.' },
    ],
    example: {
        header: [
            'NOMBRES',
            'APELLIDOS',
            'RUT',
            'LEGAJO',
            'EMAIL',
            'TELEFONO',
            'CARRERA',
            'ASIGNATURA',
            'FECHA CONFIRMACIÓN',
            'PERÍODO',
        ],
        data: [
            [
                'ALUMNO',
                'PRUEBA',
                '12345678-9',
                '1234LEGAJO',
                'prueba@gmail.com',
                '+12 3 4567 8910',
                '1234P-CARRERA PRUEBA',
                '1234P12A-MATERIA PRUEBA',
                '1234-56-78',
                '1A/2345',
            ],
            [
                'ALUMNO',
                'PROBANDO',
                '88888888-8',
                '8888LEGAJO',
                'probando@gmail.com',
                '+88 8 8888 8888',
                '8888P-CARRERA PROBANDO',
                '8888P88A-MATERIA PROBANDO',
                '8888-88-88',
                '8A/8888',
            ],
        ],
    },
}

const historialAlumnosMateriasStatus = {
    title: 'Historial materias por alumno',
    description: 'Este reporte muestra un listado de todas las materias aprobadas o regulares de cada estudiante.',
    detail: [
        { label: 'Archivo', value: 'Reporte historialAlumnosMateriasStatus.xlsx' },
        { label: 'Países', value: '<span class="country-chip argentina">Argentina</span>' },
        { label: 'Filtros', value: 'La asignatura debe estar aprobada o regularizada.' },
        { label: 'Criterios', value: 'Se muestran todas las asignaturas históricas.' },
    ],
    example: {
        header: [
            'Periodo',
            'Periodo inicio',
            'Nombre',
            'Apellido',
            'Nro Doc',
            'Teléfono',
            'Email',
            'Carrera',
            'Materia',
            'Estado',
            'Zona Comercial',
        ],
        data: [
            [
                '1A-1234',
                '2A-1234',
                'ALUMNO',
                'PRUEBA',
                '12345678',
                '+12 3456 789012',
                'prueba@hotmail.com',
                'Prueba',
                'Materia Prueba 1',
                'Regular',
                'Zona C',
            ],
            [
                '8B-8888',
                '8B-8888',
                'ALUMNO',
                'PROBANDO',
                '88888888',
                '+888888888888',
                'probando@hotmail.com',
                'Probando',
                'Materia Probando 8',
                'Regular',
                'Zona C',
            ],
        ],
    },
}

const english = {
    title: 'Inglés',
    description: 'Este reporte muestra los datos de los estudiantes que se encuentran cursando alguna de las asignaturas correspondientes a Inglés.',
    detail: [
        { label: 'Archivo', value: 'Reporte generateEnglishReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'Sólo se muestran las asignaturas que se encuentran en curso.' },
        { label: 'Criterios', value: 'Al mostrar las asignaturas en curso este reporte sólo sirve para el período actual.' },
    ],
    example: {
        header: [
            'NOMBRES',
            'APELLIDOS',
            'RUT',
            'EMAIL',
            'TELEFONO',
            'CARRERA',
            'ASIGNATURA',
            'FECHA CONFIRMACIÓN',
        ],
        data: [
            [
                'ALUMNO',
                'PRUEBA',
                '12345678',
                'prueba@ipp.cl',
                '+56 9 1234 5678',
                '0410P-CARRERA PRUEBA',
                'MATERIA PRUEBA I',
                '2022-12-20',
            ],
            [
                'ALUMNO',
                'PROBANDO',
                '12345678',
                'probando@ipp.cl',
                '+56 9 2222 2222',
                '0410P-CARRERA PROBANDO',
                'MATERIA PROBANDO I',
                '2022-12-20',
            ],
        ],
    },
}

const alumnosInscripcionPps = {
    title: 'Inscripción a prácticas',
    description: 'Reporte con datos de los alumnos inscriptos en prácticas y los datos de la organización y el referente.',
    detail: [
        { label: 'Archivo', value: 'Reporte generateLaborPracticeReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'Las prácticas profesionalizantes corresponden al período seleccionado.' },
        { label: 'Criterios', value: 'Se muestran todos los registros.' },
    ],
    example: {
        header: [
            'IDENTIFICACION',
            'NOMBRE Y APELLIDO',
            'CARRERA',
            'CODIGO ASIGNATURA',
            'ASIGNATURA',
            'EMPRESA',
            'DIRECCION EMPRESA',
            'RUT EMPRESA',
            'REFERENTE',
            'CARGO REFERENTE',
            'EMAIL',
            'TELEFONO',
            'ESTADO',
        ],
        data: [
            [
                '12345678',
                'ALUMNO PRUEBA',
                'CARRERA PRUEBA',
                '1234',
                'MATERIA PRUEBA',
                'Empresa Prueba',
                'Prueba 123',
                '12345678-9',
                'Dir. Prueba',
                'Agente',
                'prueba@hotmail.cl',
                '+12 3 4567 8901',
                'Inicializado',
            ],
            [
                '88888888',
                'ALUMNO PROBANDO',
                'CARRERA PROBANDO',
                '8888',
                'MATERIA PROBANDO',
                'Empresa Probando',
                'Probando 888',
                '88888888-8',
                'Dir. Probando',
                'Agente',
                'probando@hotmail.cl',
                '+88 8 8888 8888',
                'Inicializado',
            ],
        ],
    },
}

const alumnosPpsInscriptos = {
    title: 'Prácticas inscriptas',
    description: 'Reporte con datos de los alumnos inscriptos en prácticas, el estado y los datos de la organización y el referente.',
    detail: [
        { label: 'Archivo', value: 'Reporte alumnosPpsInscriptos.xlsx' },
        { label: 'Países', value: '<span class="country-chip argentina">Argentina</span>' },
        { label: 'Filtros', value: 'Las prácticas profesionalizantes corresponden al período seleccionado.' },
        { label: 'Criterios', value: 'Se muestran todos los registros.' },
    ],
    example: {
        header: [
            'Nombre',
            'Apellido',
            'DNI',
            'Legajo',
            'email',
            'Telefono',
            'Carrera',
            'Asignatura',
            'Nombre Empresa',
            'CUIT Empresa',
            'DIRECCION Empresa',
            'Ciudad Empresa',
            'Referente Empresa',
            'Cargo Referente',
            'email Referente',
            'Telefono Referente',
            'Estado Practica',
            'Validado Practica',
            'Dias en el estado',
        ],
        data: [
            [
                'Alumno',
                'Prueba',
                '12345678',
                '1234LEGAJO',
                'prueba@gmail.com',
                '+12 345 678-9012',
                'Carrera Prueba',
                '1234 PRUEBA I',
                'Prueba S.A.',
                '12-34567890-1',
                'Ruta Nacional Nº 123',
                'PRUEBA',
                'Prueba',
                'Cargo Prueba',
                'prueba@gmail.com.ar',
                '+12 345 678-9012',
                'Firmado',
                'no',
                '12 día/s',
            ],
            [
                'Alumno',
                'Probando',
                '44444444',
                '1234LEGAJO',
                'probando@gmail.com',
                '+54 358 444-4444',
                'Carrera Probando',
                '4444 PROBANDO I',
                'Probando S.A.',
                '44-44444444-4',
                'Ruta Nacional Nº 444',
                'PROBANDO',
                'Probando',
                'Cargo Prueba',
                'prueba@gmail.com.ar',
                '+54 358 444-4444',
                'Firmado',
                'no',
                '44 día/s',
            ],
        ],
    },
}

const practice = {
    title: 'Prácticas',
    description: 'Reporte con datos de los alumnos anotados en prácticas y la fecha de confirmación.',
    detail: [
        { label: 'Archivo', value: 'Reporte generateLaborPracticeReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'La práctica debe estar en curso.' },
        { label: 'Criterios', value: 'Debe seleccionarse un período del tipo PP.' },
    ],
    example: {
        header: [
            'NOMBRES',
            'APELLIDOS',
            'RUT',
            'EMAIL',
            'TELEFONO',
            'CARRERA',
            'ASIGNATURA',
            'FECHA CONFIRMACIÓN',
        ],
        data: [
            [
                'ALUMNO',
                'PRUEBA',
                '12345678',
                'prueba@gmail.com',
                '+12 3 4567 8901',
                '1234PE-CARRERA PRUEBA (PLAN ESPECIAL)',
                '1234P56R-ASIGNATURA PRUEBA',
                '1234-56-78',
            ],
            [
                'ALUMNO',
                'PROBANDO',
                '22222222',
                'probando@gmail.com',
                '+22 2 2222 2222',
                '0222PE-CARRERA PROBANDO (PLAN ESPECIAL)',
                '0222P22R-ASIGNATURA PROBANDO',
                '2222-22-22',
            ],
        ],
    },
}

const alumnosPpsAProbadas = {
    title: 'PPs aprobadas',
    description: 'Reporte con datos de los alumnos con prácticas aprobadas.',
    detail: [
        { label: 'Archivo', value: 'Reporte alumnosPpsAProbadas.xlsx' },
        { label: 'Países', value: '<span class="country-chip argentina">Argentina</span>' },
        { label: 'Filtros', value: 'Se muestran las prácticas profesionalizantes 1 y 2 que estén aprobadas.' },
        { label: 'Criterios', value: '' },
    ],
    example: {
        header: [
            'Nombre',
            'Apellido',
            'Nro Doc',
            'Legajo',
            'Email',
            'Carrera',
            'Materia',
            'Perído',
        ],
        data: [
            [
                'ALUMNO',
                'PRUEBA',
                '12345678',
                '1234LEGAJO',
                'prueba@gmail.com',
                'Carrera Prueba',
                'MATERIA PRUEBA I',
                'PP-Período Verano 1234',
            ],
            [
                'ALUMNO',
                'PROBANDO',
                '66666666',
                '1234LEGAJO',
                'probando@gmail.com',
                'Carrera Probando',
                'MATERIA PROBANDO I',
                'PP-Período Verano 6666',
            ],
        ],
    },
}

const alumnosEstadosPps = {
    title: 'Estados PP',
    description: 'Este reporte muestra el estado de aprobación de cada Práctica Profecionalizante de cada alumno, con los datos del mismo y el período en el que la llevó a cabo.',
    detail: [
        { label: 'Archivo', value: 'Reporte alumnosEstadosPps.xlsx' },
        { label: 'Países', value: '<span class="country-chip argentina">Argentina</span>' },
        { label: 'Filtros', value: 'El estudiante debe haber cursado la práctica profecionalizante 1 o 2.' },
        { label: 'Criterios', value: 'Muestra todos los estudiantes que hayan pasado por la Práctica Profecionalizante.' },
    ],
    example: {
        header: [
            'Nombre',
            'Apellido',
            'Nro Doc',
            'Legajo',
            'Email',
            'Carrera',
            'Materia',
            'Estado',
            'Periodo',
        ],
        data: [
            [
                'ALUMNO',
                'PRUEBA',
                '12345678',
                'ABCDLEGAJO',
                'prueba@gmail.com',
                'Carrera Prueba',
                'MATERIA PRUEBA I',
                'Aprobada',
                '1B-1234',
            ],
            [
                'ALUMNO',
                'PROBANDO',
                '55555555',
                'ABCDLEGAJO',
                'probando@gmail.com',
                'Carrera Probando',
                'MATERIA PROBANDO I',
                'Aprobada',
                '5B-5555',
            ],
        ],
    },
}

const alumnosPps = {
    title: 'Detalle habilitaciones PP',
    description: 'Este reporte muestra si cada estudiante se encuentra habilitado para realizar la Práctica Profecionalizante o no y el estado de aprobación de cada una.',
    detail: [
        { label: 'Archivo', value: 'Reporte alumnosPps.xlsx' },
        { label: 'Países', value: '<span class="country-chip argentina">Argentina</span>' },
        { label: 'Filtros', value: 'Sólo se muestran los estudiantes que se encuentran cursando.' },
        { label: 'Criterios', value: 'Si tiene 4 finales aprobados y no aproba la PP1, entonces puede hacerla. Si tiene 4 finales de primer año, 4 finales de segundo y la PP1 aprobada, puede hace la PP2.' },
    ],
    example: {
        header: [
            'Nombre',
            'Apellido',
            'Nro Doc',
            'Carrera',
            'Periodo Ingreso',
            'Último Período Abonado',
            'Total aprobadas',
            'Cant ex ap Primer año',
            'Cant ex ap Segundo año',
            'PP1 aprobada',
            'PP2 aprobada',
            'Puede cursar PP1',
            'Puede cursar PP2',
            'Fecha Nac',
        ],
        data: [
            [
                'ALUMNO',
                'PROBANDO',
                '12345678',
                'Carrera Probando',
                '1A-1234',
                '2B 1234',
                '12',
                '8',
                '6',
                'Si',
                'No',
                'No - Aprobada',
                'Si',
                '12-34-5678',
            ],
            [
                'ALUMNO',
                'PROBANDO',
                '55555555',
                'Carrera Probando',
                '5A-5555',
                '5B 5555',
                '55',
                '5',
                '5',
                'Si',
                'No',
                'No - Aprobada',
                'Si',
                '55-55-5555',
            ],
        ],
    },
}

const seminar = {
    title: 'Seminarios',
    description: 'Este reporte muestra los datos de los estudiantes que se encuentran cursando los seminarios o trabajos de título.',
    detail: [
        { label: 'Archivo', value: 'Reporte generateSeminarReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'Sólo se muestran los seminarios y trabajos que se encuentran en curso.' },
        { label: 'Criterios', value: 'Al mostrar los seminarios y trabajos en curso este reporte sólo sirve para el período actual.' },
    ],
    example: {
        header: [
            'NOMBRES',
            'APELLIDOS',
            'RUT',
            'EMAIL',
            'TELEFONO',
            'CARRERA',
            'ASIGNATURA',
            'FECHA CONFIRMACIÓN',
        ],
        data: [
            [
                'ALUMNO',
                'PRUEBA ',
                '12579302',
                'prueba@gmail.com',
                '+56 9 8674 1586',
                '0340P-CARRERA PRUEBA',
                '0340P53R-ASIGNATURA PRUEBA',
                '2022-12-21',
            ],
            [
                'ALUMNO',
                'PROBANDO',
                '55555555',
                'probando@gmail.com',
                '+55 5 5555 5555',
                '0555P-CARRERA PROBANDO',
                '0555P55R-ASIGNATURA PROBANDO',
                '5555-55-55',
            ],
        ],
    },
}

const levels = {
    title: 'Niveles',
    description: 'Este reporte muestra el nivel de cada estudiante y la cantidad de asignaturas del plan de estudio, de aprobadas, en curso y regulares.',
    detail: [
        { label: 'Archivo', value: 'Reporte generateLevelsReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'Se muestran sólo las asignaturas obligatorias.' },
        { label: 'Criterios', value: 'El nivel se obtiene de analizar la cantidad de asignaturas semestrales aprobadas del plan de estudio.' },
    ],
    example: {
        header: [
            'LEGAJO',
            'RUT',
            'NOMBRE',
            'APELLIDO',
            'CARRERA',
            'PLAN ESTUDIO',
            'PERIODO INGRESO',
            'ESTADO ESTUDIANTE',
            'ESTADO CARRERA',
            'RAMOS PLAN',
            'APROBADAS',
            'EN CURSO',
            'REGULARES',
            'NIVEL',
        ],
        data: [
            [
                '1234LEGAJO',
                '12345678',
                'ALUMNO',
                'PRUEBA',
                '1234P - CARRERA PRUEBA',
                '1234P/56789A',
                '1A/1234',
                '12 - Titulado',
                '123 - Titulado',
                '12',
                '12',
                '0',
                '0',
                '8',
            ],
            [
                '1234LEGAJO',
                '44444444',
                'ALUMNO',
                'PROBANDO',
                '0410P - CARRERA PROBANDO',
                '0410P/44444A',
                '4B/4444',
                '44 - Titulado',
                '144 - Titulado',
                '44',
                '44',
                '4',
                '4',
                '4',
            ],
        ],
    },
}

const nps = {
    title: 'NPS',
    description: 'Este reporte muestra los datos generales del estudiante y datos particulares para utilizar en los pulsos de NPS.',
    detail: [
        { label: 'Archivo', value: 'Reporte nps.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'El estudiante debe estar en estado "cursando", debe tener contrato válido para el período solicitado con fecha de firma y las asignaturas deben estar en estado "cursando", "aprobada", "regular" o "libre".' },
        { label: 'Criterios', value: 'Si bien es el mismo reporte para los dos países, presenta algunas diferencias como si cursa o no cierta asignatura.' },
    ],
    example: {
        header: [
            'EMAIL',
            'NOMBRE',
            'APELLIDO',
            'TELÉFONO',
            'LEGAJO',
            'IDENTIFICACION',
            'FECHA_NACIMIENTO',
            'CARRERA',
            'CEO',
            'COMUNA',
            'CIUDAD / LOCALIDAD',
            'REGION / PROVINCIA',
            'PERIODO_INGRESO',
            'EXAMENES_INSCRIPTOS',
            'EXAMENES_RENDIDOS',
            'EXAMENES_APROBADOS',
            'MATERIAS_PRÁCTICAS',
            'REALIZO_TEST_ONB',
            'AGENDO_ONB',
            'REALIZO_ONB',
            'APROBO_TEST_ONB',
            'PERIODO_ONB',
            'MVP BASE DE DATOS',
            'MVP FUNDAMENTOS DE CONTABILIDAD',
            'MVP LOGICA DE PROGRAMACION',
        ],
        data: [
            [
                'prueba@gmail.com',
                'Alumno',
                'Prueba',
                '+12 3456 78-9012',
                'ABCDLEGAJO',
                '12345678',
                '01/23/4567',
                'Prueba',
                'CEO PRUEBA',
                '',
                'TOKIO',
                'PRUEBA',
                '1A/1234',
                '1',
                '1',
                '1',
                '1',
                'No',
                'No',
                'No',
                'No',
                '1A/1234',
                'No',
                'No',
                'No',
            ],
            [
                'probando@gmail.com',
                'Alumno',
                'Probando',
                '+99 9999 99-9999',
                'ABCDLEGAJO',
                '99999999',
                '99/99/9999',
                'Probando',
                'CEO PROBANDO',
                '',
                'TOKIO',
                'PROBANDO',
                '9A/9999',
                '9',
                '9',
                '9',
                '9',
                'No',
                'No',
                'No',
                'No',
                '9A/9999',
                'No',
                'No',
                'No',
            ],
        ],
    },
}

const processings = {
    title: 'Trámites',
    description: 'Este reporte muestra los trámites y el estado en el que se encuentran para cada estudiante en un determinado período.',
    detail: [
        { label: 'Archivo', value: 'Reporte generateProcessingsReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span><span class="country-chip argentina">Argentina</span>' },
        { label: 'Filtros', value: 'No se muestran los trámites de cambio de sede ni certificados.' },
        { label: 'Criterios', value: 'Para los trámites de documentación obligatoria se considera que el período de inicio del mismo es el ingreso del estudiante a la carrera.' },
    ],
    example: {
        header: [
            'N° trámite',
            'RUT',
            'Dígito verificador',
            'Nombre',
            'Legajo',
            'Carrera',
            'Trámite',
            'Fecha creación',
            'Fecha actualización',
            'Estado',
            'Periodo inicio',
            'Última matrícula',
            'Email',
            'Celular',
            'Razón',
            'Comentario',
            'Fecha pago',
            'Monto',
            'Categoria',
            'Usuario solicito',
        ],
        data: [
            [
                '123456',
                '12345678',
                '1',
                'ALUMNO PRUEBA',
                '1234LEGAJO',
                'CARRERA PRUEBA',
                '12 - Tramite Prueba',
                '12345',
                'null',
                '12 - Denegado',
                '123 - 1A/1234',
                '234 - 1A/1234',
                'prueba@gmail.com',
                '12345678',
                'null',
                'null',
                '1234-56-78 12:34:56.789',
                '123456.0',
                'null',
                '12345678',
            ],
            [
                '999999',
                '99999999',
                '9',
                'ALUMNO PROBANDO',
                '99999A0999P999',
                'CARRERA PROBANDO',
                '99 - Tramite Probando',
                '99999',
                'null',
                '99 - Denegado',
                '999 - 9A/9999',
                '999 - 9A/9999',
                'probando@gmail.com',
                '99999999',
                'null',
                'null',
                '9999-99-99 99:99:99.999',
                '999999.0',
                'null',
                '99999999',
            ],
        ],
    },
}

const mandatoryDocumentation = {
    title: 'Documentación obligatoria',
    description: 'Este reporte muestra el estado del trámite de documentación obligatoria para cada estudiante y los preinscriptos del período seleccionado.',
    detail: [
        { label: 'Archivo', value: 'Reporte generateMandatoryDocumentationReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span><span class="country-chip argentina">Argentina</span>' },
        { label: 'Filtros', value: 'La carrera del estudiante debe encontrarse en condición Cursando, Egresado, Titulado o Egresado sin opción a Título (o Baja para Chile). O puede ser una preinscripción correspondiente al período seleccionado.'},
        { label: 'Criterios', value: 'Para que un trámite de documentación obligatoria sea aprobado debe contener al menos un archivo de DNI y uno de analítico aprobado.' },
    ],
    example: {
        header: [
            'DNI','Nombre','Email','Celular','Carrera','Estado carrera','Legajo',
            'Inicio legajo','Última matrícula','Último período abonado','Número de trámite',
            'Estado trámite','Materias aprobadas','Creación trámite','Modificación trámite',
            'Usuario','Estado DNI','Estado Analítico'
        ],
        data: [
            [
                '12345678',
                'ALUMNO',
                'prueba@hotmail.com',
                '+12 345 678-9012',
                'Carrera Prueba',
                'Egresado',
                'ABCDLEGAJO',
                '1234-56-78 12:34:56.0',
                '1A/1234',
                '2B/5678',
                '1234',
                'Aprobado',
                '12',
                '1234-56-78 12:34:56.0',
                '1234-56-78 12:34:57.0',
                '123456',
                'Aprobado',
                'Aprobado',
            ],
            [
                '33333333',
                'ALUMNO',
                'probando@hotmail.com',
                '+33 333 333-3333',
                'Carrera Probando',
                'Egresado',
                'ABCDLEGAJO',
                '3333-33-33 33:33:33.3',
                '3A/3333',
                '3B/3333',
                '3333',
                'Aprobado',
                '33',
                '3333-33-33 33:33:33.3',
                '3333-33-33 33:33:33.3',
                '333333',
                'Aprobado',
                'Aprobado',
            ],
        ],
    },
}

const oldModality = {
    title: 'Modalidad 2019',
    description: 'Este reporte muestra un listado de todos los estudiantes registrados con la modalidad 2019 que todavía están cursando.',
    detail: [
        { label: 'Archivo', value: 'Reporte generateOldModalityReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'La carrera del estudiante debe estar en estado "cursando".' },
        { label: 'Criterios', value: '' },
    ],
    example: {
        header: [
            'RUT',
            'NOMBRE',
            'APELLIDO',
            'FECHA NACIMIENTO',
            'ESTADO',
            'TELÉFONO 1',
            'TELÉFONO 2',
            'CORREO',
            'REGION',
            'CARRERA',

        ],
        data: [
            [
                '12345678',
                'ALUMNO',
                'PRUEBA',
                '1234-56-78',
                'Cursando',
                '+12 3 4567 8901',
                '+12 3 4567 8901',
                'prueba@gmail.com',
                'CALAMA',
                '0123P/4567 - CARRERA PRUEBA',
            ],
            [
                '33333333',
                'ALUMNO',
                'PROBANDO',
                '3333-33-33',
                'Cursando',
                '+33 3 3333 3333',
                '+33 3 3333 3333',
                'probando@gmail.com',
                'CALAMA',
                '0333P/3333 - CARRERA PROBANDO',
            ],
        ],
    },
}

const enrollments = {
    title: 'Registro de matrículas',
    description: 'Reporte que muestra los datos de los estudiantes matriculados.',
    detail: [
        { label: 'Archivo', value: 'Reporte generateEnrollmentsReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'Se muestran todos los registros.' },
        { label: 'Criterios', value: 'Se duplican algunes estudiantes que tienen más de un legajo.' },
    ],
    example: {
        header: [
            'STUDENT_ID',
            'LEGAJO',
            'RUT',
            'NOMBRES ESTUDIANTE',
            'APELLIDOS ESTUDIANTE',
            'INGRESO',
            'ULTIMA MATRÍCULA',
            'Periodo Contrato',
            'Fecha Contrato',
            'ESTADO ESTUDIANTE',
            'TIPO ESTUDIANTE',
            'CARRERA',
            'CEO',
            'ESTADO CARRERA',
            'FECHA ESTADO CARRERA',
            'CONFIRMACIÓN CARGA',
            'MATERIAS CARRERA',
            'MATERIAS CARRERA APROBADAS',
            'TELEFONO',
            'CELULAR',
            'EMAIL',
            'PLAN ESTUDIO',
        ],
        data: [
            [
                '123456',
                'ABCDLEGAJO',
                '12345678',
                'ALUMNO',
                'PRUEBA',
                '1A/1234',
                '2A/5678',
                '1A/1234',
                '1234-56-78',
                'Vigente',
                'Reingresante',
                '1234P - CARRERA PRUEBA',
                '123 - CENTRO PRUEBA',
                '12 - Cursando',
                '1234-56-78 12:34:56.789',
                'SI',
                '12',
                '12',
                '12345678',
                '+12 3 4567 8901',
                'prueba@gmail.com',
                '1234P/1234',
            ],
            [
                '333333',
                'ABCDLEGAJO',
                '33333333',
                'ALUMNO',
                'PROBANDO',
                '3B/3333',
                '3B/3333',
                '3B/3333',
                '3333-33-33',
                'Vigente',
                'Reingresante',
                '0330P - CARRERA PROBANDO',
                '333 - CENTRO PROBANDO',
                '33 - Cursando',
                '3333-33-33 33:33:33.333',
                'SI',
                '33',
                '33',
                '33333333',
                '+33 3 3333 3333',
                'PROBANDO@gmail.com',
                '0330P/3333',
            ],
        ],
    },
}

const reenrollments = {
    title: 'Rematrículas',
    description: 'Reporte que muestra los datos de los estudiantes rematriculados.',
    detail: [
        { label: 'Archivo', value: 'Reporte generateReenrollmentsReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'Sólo se pueden mostrar períodos que no hayan iniciado todavía.' },
        { label: 'Criterios', value: 'Se muestran datos de contacto, financieros y académicos.' },
    ],
    example: {
        header: [
            'STUDENT_ID',
            'LEGAJO',
            'RUT',
            'NOMBRES ESTUDIANTE',
            'APELLIDOS ESTUDIANTE',
            'INGRESO',
            'ULTIMA MATRÍCULA',
            'ESTADO ESTUDIANTE',
            'TIPO ESTUDIANTE',
            'CARRERA',
            'CEO',
            'ESTADO CARRERA',
            'FECHA ESTADO CARRERA',
            'CUOTA',
            'FECHA PAGO CUOTA',
            'ARANCEL',
            'FECHA CONTRATO',
            'BECA',
            'CONFIRMACIÓN CARGA',
            'MATERIAS CARRERA',
            'MATERIAS CARRERA APROBADAS',
            'CUOTAS VENCIDAS(1 DIA O MAS)',
            'MONTO VENDIO(1 DIA O MAS)',
            'REPACTACIÓN',
            'FECHA REPACTACIÓN',
            'DEUDA REPACTACION',
            'TELEFONO',
            'CELULAR',
            'EMAIL',
            'PLAN ESTUDIO',
            'CONVENIO',
            'FECHA NACIMIENTO',
            'SEXO',
            'NACIONALIDAD',
        ],
        data: [
            [
                '123456',
                'ABCDLEGAJO',
                '12345678-K',
                'ALUMNO',
                'PRUEBA',
                '1A/1234',
                '2A/5678',
                '12 - Vigente',
                '8 - Reingresante',
                '0123PE - CARRERA PRUEBA (PLAN ESPECIAL)',
                '123 - PRUEBA',
                '12 - Cursando',
                '1234-56-78',
                'null',
                'null',
                'null',
                'null',
                'NO',
                '1234-56-78',
                '12',
                '34',
                '5',
                '123456.789012',
                'SI',
                '1234-56-78',
                '123456.789012',
                'null',
                '12345678',
                'prueba@gmail.com',
                '0123PE/1234A',
                'null',
                '1234-56-78',
                'Masculino',
                'Chilena',
            ],
            [
                '888888',
                'ABCDLEGAJO',
                '88888888-K',
                'ALUMNO',
                'PROBANDO',
                '8B/8888',
                '8B/8888',
                '88 - Vigente',
                '8 - Reingresante',
                '8888PE - CARRERA PROBANDO (PLAN ESPECIAL)',
                '888 - PROBANDO',
                '88 - Cursando',
                '8888-88-88',
                'null',
                'null',
                'null',
                'null',
                'NO',
                '8888-88-88',
                '88',
                '88',
                '8',
                '888888.888888',
                'SI',
                '8888-88-88',
                '888888.888888',
                'null',
                '88888888',
                'probando@gmail.com',
                '8888PE/8888A',
                'null',
                '8888-88-88',
                'Femenino',
                'Chilena',
            ],
        ],
    },
}

export default {alumnosPpsInscriptos, performance, enrollments, reenrollments, oldModality, mandatoryDocumentation, processings, nps, levels, seminar, alumnosPps, alumnosPpsAProbadas, alumnosEstadosPps, inscriptions, alumnosDatosGenerales, admissions, performanceRi, graduate, alumnosDatosEgresados, academic, historialAlumnosMateriasStatus, english, confirmedSubjects, curricularAdvancement, alumnosInscripcionPps, practice,};

