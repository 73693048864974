<template>
  <v-container fluid class="superTable pa-0">
    <v-row no-gutters>
      <v-col sm="4">
        <v-text-field v-model="search" prepend-inner-icon="mdi-magnify" :placeholder="$t('actions.search')" outlined
          clearable class="superTable__search" />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col sm="12">
        <v-data-table :show-select="true" :headers="headers" :items="students" :search="search" :page.sync="page"
          :items-per-page="itemsPerPage" hide-default-footer item-key="id" class="superTable__table"
          style="table-layout: fixed">
          <template v-slot:header.data-table-select="{ on, props }">
            <v-simple-checkbox class="superTable__table__item__checkbox" color="primary" v-bind="props" v-on="on" />
          </template>

          <template v-slot:item.data-table-select="{ isSelected, select }">
            <v-simple-checkbox class="superTable__table__item__checkbox" color="primary" :value="isSelected"
              @input="select($event)" />
          </template>

          <template v-slot:item.settingsValue="{ item }">
            <span>{{ item.settingsValue }}</span>
          </template>

          <template v-slot:item.action="{ item }">
            <v-menu bottom left>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" class="superTable__table__item__action">mdi-dots-vertical</v-icon>
              </template>
              <v-list class="pa-0">
                <v-list-item @click="editItem(item.id)" :disabled="!canUpdate">
                  <v-list-item-title>
                    <v-icon>mdi-pencil</v-icon> {{ $t('actions.edit') }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
        <v-divider class="mt-4 mb-8"></v-divider>
      </v-col>
    </v-row>
    <v-row no-gutters align="center">
      <v-col sm="3">
        <span class="caption d-inline-block">
          {{ itemsCount.startItem }} - {{ itemsCount.endItem }} de {{ itemsCount.totalItems }}
        </span>
        <v-select v-model="itemsPerPage" :items="itemsPerPageAvailable" :placeholder="itemsPerPage.toString()" outlined
          class="superTable__paginationSelect d-inline-block ml-4" />
      </v-col>
      <v-col sm="6" class="text-center">
        <v-pagination v-model="page" :length="pageCount" class="superTable__pagination" />
      </v-col>
      <v-col sm="3" class="text-right">
        <v-text-field v-model="page" :max="pageCount" outlined
          class="superTable__paginationInput d-inline-block mr-4" />
        <span class="caption d-inline-block">{{ $t('actions.goToPage') }}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'SuperTable',
  props: {
    students: {
      type: Array,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      search: '',
      page: 1,
      itemsPerPage: 20,
      itemsPerPageAvailable: [20, 50, 100, 200],
    };
  },
  computed: {
    pageCount() {
      return Math.ceil(this.students.length / this.itemsPerPage) || 1;
    },
    itemsCount() {
      const startItem = (this.page - 1) * this.itemsPerPage + 1;
      const endItem = Math.min(this.page * this.itemsPerPage, this.students.length);
      return {
        totalItems: this.students.length,
        startItem,
        endItem,
      };
    },
    canUpdate() {
      return this.$permissions.settings?.students?.UPDATE || false 
    },
  },
  methods: {
    editItem(id) {
      this.$emit('updateStudent', id);
    },
  },
  watch: {
    page(val) {
      if (val > this.pageCount) this.page = this.pageCount;
      if (val < 1) this.page = 1;
    },
  },
};
</script>