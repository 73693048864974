<template>
  <v-container fluid class="d-flex pa-0 fullHeight">
    <v-container fluid class="px-0">
      <div class="px-md-10">
        <div class="pl-3 pr-3" v-if="!coupon.payment">
          <Feedback
            :status="getStatus"
            :title="feedback.title"
            :description="feedback.description"
            :message="feedback.message"
            :actions="feedback.actions"
            :titleVerification="feedbackVerification.title"
            :subtitleVerification="feedbackVerification.subtitle"
            :descriptionVerification="feedbackVerification.description"
            :messageVerification="feedbackVerification.message"
            :actionsVerification="feedbackVerification.actions"
            :showDownloadButton="true"
            :haveContractPendingValidation="haveContractPendingValidation"
            :systemConfig="systemConfig"
          >
          </Feedback>
        </div>

        <div v-if="coupon.payment" id="printTicket">
          <div class="coupon-head">
            {{ $t('modules.portalalumno.payments.paymentsStudents.view.coupons.nextSteps', { service: paymentTitle }) }}
            {{ (resumen.items.adminExpenses.monto || resumen.total) | $currency }}
          </div>
          <div class="servipag pl-3 pr-3 mt-2 mb-2">
            <v-row class="pt-2 pb-2">
              <v-col class="text-left pl-10" cols="6">
                <img width="auto" height="50" :src="logoPath" :alt="`${institute} Logo`" />
              </v-col>
              <v-col class="text-right pr-10" cols="6">
                <img v-if="coupon.payment.id == 'servipag'" :src="require('@/assets/Servipag.png')" width="126"
                  alt="Servipag" />
                <img v-if="coupon.payment.id == 'khipu'" :src="require('@/assets/kiphu.png')" width="126" alt="Kiphu" />
              </v-col>
            </v-row>
            <v-row class="text-center">
              <v-col class="pt-0 mt-0" cols="12" sm="12">
                <div class="coupon-info-ctc pt-0 mt-0">
                  <v-row class="pt-0 mt-0">
                    <v-col class="text-left cp-number pl-7" cols="6">
                      <br /><br />
                      {{ `${$t('modules.portalalumno.payments.paymentsStudents.view.coupons.couponNumber')}
                      ${coupon.number}` }}
                    </v-col>
                    <v-col class="text-right" cols="6">
                      <div class="cp-total pr-5">
                        {{ $t('modules.portalalumno.payments.paymentsStudents.view.summary.total') }}</div>
                      <div class="cp-amount pr-5 pt-2">{{ (resumen.items.adminExpenses.monto || resumen.total) |
          $currency }}</div>
                    </v-col>
                  </v-row>
                  <v-row class="pl-7 pr-7 pt-0 pb-0">
                    <v-col cols="12" class="px-0 py-0">
                      <div class="separator"></div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="text-left cp-sent pl-7" cols="12" sm="12">
                      <span>{{ `${$t('modules.portalalumno.payments.paymentsStudents.view.coupons.sentOn')}
                        ${coupon.creationDate}` }}</span>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="text-center" cols="12" sm="12">
                <p class="cp-instructions pt-0 mt-0">
                  {{ $t('modules.portalalumno.payments.paymentsStudents.view.coupons.instructions') }}</p>
                <p class="cp-instructions-numbers mb-0 pb-0">{{ coupon.manualOpNumber }}</p>
                <img class="barcode mt-4" :src="barcodeImage" alt="Barcode">
                <p class="cp-info mt-4">{{ $t('modules.portalalumno.payments.paymentsStudents.view.coupons.info',
          { service: $isTeclab ? paymentTitle : 'Servipag.com' }) }}</p>
                <p class="cp-reminder">{{ $t('modules.portalalumno.payments.paymentsStudents.view.coupons.reminder') }}
                </p>
              </v-col>
            </v-row>
          </div>


          <p class="text-center cp-disclaimer pt-5">{{
          $t('modules.portalalumno.payments.paymentsStudents.view.coupons.disclaimer', { org: institute }) }}</p>
          <div class="d-flex flex-row btn-container justify-center mb-12 mt-10">
            <Button class="mx-4 my-2" :text="$t('modules.portalalumno.payments.paymentsStudents.view.coupons.print')"
              @clicked="printCoupon"></Button>
            <Button class="mx-4 my-2" text="Ir a inicio" @clicked="goToHome"></Button>
          </div>
        </div>
      </div>
    </v-container>
  </v-container>
</template>

<script>
import Button from '@/components/Button/Button';
import Feedback from '@/components/Feedback/Feedback.vue'
import { $students } from '../Services';
import { mapGetters } from 'vuex'
export default {
  name: 'PaymentsCompletedView',
  props: {
    resumen: Object,
    status: Object,
    coupon: Object,
    selectPayFT: String,
    paymentTerms: Array,
    isMandatory: { type: Boolean, default: null },
    systemConfig: Object
    },
  components: {
    Button,
    Feedback
  },
  data() {
    return {
      title: null,
      description: null,
      feedback: {
        title: '',
        description: '',
        message: '',
        actions: {
          primary: {
            label: this.$t('actions.backToTop'),
            visible:true,
            callback: () => {
              this.$router.replace('/')
            }
          },
          secondary:{
            label: 'Descargar Contrato',
            callback: ()=>{
              this.downloadContract()

            }
          }
        }
      },
      timeOut: null,
      feedbackVerification: {
        title: this.$t('modules.portalalumno.payments.paymentsStudents.view.feedbackVerification.title'),
        subtitle:this.$t('modules.portalalumno.payments.paymentsStudents.view.feedbackVerification.subtitle'),
        description: this.$t('modules.portalalumno.payments.paymentsStudents.view.feedbackVerification.description'),
        message:this.$t('modules.portalalumno.payments.paymentsStudents.view.feedbackVerification.message'),
        actions: {
          primary: {
            label: 'Verificar Identidad',
            visible:true,
            callback:async() => {
              clearTimeout(this.timeOut)
              try {
                const resp = await $students.signContract('ecert/validateUserData', {
                  rut: this.studentUserData.identification,
                  legajo: this.studentRegister
                })
                window.open(`https://serviciovalidacionremota.ecertchile.cl/${resp.data.url}?token=${resp.data.token}`,'_blank');

              } catch{
                 //empty
              }
            }
          },
        }
      },
    }
  },
  methods: {
    async downloadContract() {
  try {
    const params = {
      terms: this.paymentTerms?.join(',')
    };

    const response = await $students.find(`getUrlContract/${this.legajo}`, null, { params: { terms: params.terms } });

    if (!response || !response.data) {
      this.$store.dispatch('commons/openPopUp', {
        title: this.$t('modules.portalalumno.payments.paymentsStudents.view.contract.popups.error.title'),
        actionPrimary: { text: this.$t('actions.accept'), callback(){} },
        icon: { color: 'error', name: 'mdi-close' },
        color: 'primary',
      });
      return;
    }
    const contractUrls = response.data;
      contractUrls.forEach(contractUrl => {
      const link = document.createElement('a');
      link.href = contractUrl;
      link.download = 'contract.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  });

  } catch (error) {
    this.$emit('showError', 'Hubo un error al descargar el contrato. Por favor, inténtalo de nuevo.');
  }
},
    goToHome() {
      this.$router.push('/')
      if (this.$isTeclab) {
        if (!this.selectPayFT == 'rapipago' && !this.selectPayFT == 'pagofacil') this.$trackEvent('Pago', 'Clk_BtnRedireccMerPag_Pagos', 'PagoArancel') //Google Analytic - Caso exito teclab
        if (this.selectPayFT == 'rapipago') this.$trackEvent('Pago', 'Clk_BtnIniRapPag_Pagos', 'PagoArancel')
        if (this.selectPayFT == 'pagofacil') this.$trackEvent('Pago', 'Clk_BtnIniPagFac_Pagos', 'PagoArancel')
      }
    },
    setMessage() {
      if (this.status.accountKey && this.status.accountKey === 'MERCADOPAGO') {
        const { status, status_detail} = this.status
        this.feedback.title = this.$t(`modules.portalalumno.payments.paymentsStudents.gatewayMPFeedback.${status}.${status_detail}.title`, this.status)
        this.feedback.description = this.$t(`modules.portalalumno.payments.paymentsStudents.gatewayMPFeedback.${status}.${status_detail}.description`, this.status)
        this.feedback.message = this.$t(`modules.portalalumno.payments.paymentsStudents.gatewayMPFeedback.${status}.${status_detail}.message`, this.status)
      }else{
        switch (this.status.status) {
        case 'approved':
          this.feedback.title = this.$t('modules.portalalumno.payments.paymentsStudents.finalView.successMessage.title')
          this.feedback.description = ''
          this.feedback.message = ''
          break
        case 'in_process':
          this.feedback.title = this.$t('modules.portalalumno.payments.paymentsStudents.finalView.processMessage.title')
          this.feedback.description = this.$t('modules.portalalumno.payments.paymentsStudents.finalView.processMessage.description')
          this.feedback.message = ''
          break
        case 'rejected':
          this.feedback.title = this.$t('modules.portalalumno.payments.paymentsStudents.finalView.rejectedMessage.title')
          this.feedback.description = this.$t('modules.portalalumno.payments.paymentsStudents.finalView.rejectedMessage.description')
          this.feedback.message = ''
          break
        case 'debitOk':
          this.feedback.title = this.$t('modules.portalalumno.payments.paymentsStudents.finalView.successDebitMessage.title')
          this.feedback.description = this.$t('modules.portalalumno.payments.paymentsStudents.finalView.successDebitMessage.description')
          this.feedback.message = ''
          break
        case 'debitError':
          this.feedback.title = this.$t('modules.portalalumno.payments.paymentsStudents.finalView.rejectedDebitMessage.title')
          this.feedback.description = this.$t('modules.portalalumno.payments.paymentsStudents.finalView.rejectedDebitMessage.description')
          this.feedback.message = ''
          break
        case 'suscriptionOK':
          this.feedback.title = this.$t('modules.portalalumno.payments.paymentsStudents.finalView.successSuscriptionMessage.title')
          this.feedback.description = ''
          this.feedback.message = ''
          break
        case 'suscriptionError':
          this.feedback.title = this.$t('modules.portalalumno.payments.paymentsStudents.finalView.subscriptionErrorMessage.title')
          this.feedback.description = ''
          this.feedback.message = ''
          break
        default:
          break
      }
      }
    },
    toCurrencyFormat(value) {
      const val = value ? (value / 1).toFixed(0).replace('.', ',') : '0.1'
      return `$ ${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`
    },
    printCoupon() {
      this.$htmlToPaper('printTicket');
      if (this.$isTeclab) {
        if (this.selectPayFT == 'rapipago') this.$trackEvent('Pago', 'Clk_BtnImpRapPag_Pagos', 'PagoArancel')
        if (this.selectPayFT == 'pagofacil') this.$trackEvent('Pago', 'Clk_BtnImpPagFac_Pagos', 'PagoArancel')
      }
    },
  },
  computed: {
    barcodeImage() {
      if (this.coupon.barcode.type && this.coupon.barcode.codeNumber) return `https://barcode.tec-it.com/barcode.ashx?data=${this.coupon.barcode.codeNumber}&code=${this.coupon.barcode.type}&dpi=96&color=000`
      else return require('@/assets/barcode.png')
    },
    paymentTitle() {
      const title = (this.coupon.payment) ? this.coupon.payment.id : ''
      return title.charAt(0).toUpperCase() + title.slice(1)
    },
    institute() {
      if (this.$isIPP) return 'IPP'
      return 'Teclab'
    },
    getStatus() {
      const approved = ['approved', 'debitOk', 'suscriptionOK']
      const inProcess = ['in_process']
      const rejected = ['rejected', 'debitError', 'suscriptionError']
      if (this.status && approved.includes(this.status.status)) return 'success'
      if (this.status && inProcess.includes(this.status.status)) return 'warning'
      if (this.status && rejected.includes(this.status.status)) return 'error'
      return 'info'
    },
    ...mapGetters({
      logoPath: 'commons/getLogoImageUrl',
      legajo: 'commons/getStudentActualRegister',
      studentUserData: 'commons/getStudentUserData',
      studentRegister: 'commons/getStudentActualRegister',
      haveContractPendingValidation: 'commons/getContractPendingValidation'
    }),
  },
   watch: {
    haveContractPendingValidation:{
      handler(haveContractPendingValidation){
        if(haveContractPendingValidation && this.systemConfig?.contract){
          this.timeOut = setTimeout(()=>{
          this.$router.replace('/admission')
          }, 5000)
        }
    },
  immediate:true
  },
    status(status) {
      if(!status) return
      this.setMessage()
    },
    getStatus(newStatus) {
      if (newStatus === 'success' && this.isMandatory && this.systemConfig?.contract) {
        this.feedback.actions.secondary.visible = true;
      } else {
        this.feedback.actions.secondary.visible = false;
      }
    },
  },

};
</script>

<style scoped lang="sass">
  .icon-wrapper
    padding: 25px
    border-radius: 50%
    height: 148px!important
    width: 148px!important
    margin: 0 auto
    margin-top: 30px
    &.approved
      background-color: rgba(59, 192, 0, 0.1) !important
    &.suscriptionOK
      background-color: rgba(59, 192, 0, 0.1) !important
    &.in_process
      background-color: rgba(59, 192, 0, 0.1) !important
    &.rejected
      background-color: rgba(244, 67, 54, 0.1) !important

  h4
    font-family: "DM Sans", sans-serif
    font-size: 24px
    font-weight: bold
    font-stretch: normal
    font-style: normal
    line-height: 1.29
    letter-spacing: -0.72px
    text-align: center

  .message_description
    font-family: "Roboto", sans-serif
    font-size: 14px
    font-weight: normal
    font-stretch: normal
    font-style: normal
    line-height: 1.43
    letter-spacing: 0.25px
    text-align: left
    color: rgba(0, 0, 0, 0.87)
    width: 290px
    margin: 0 auto
    padding-bottom: 20px
    padding-top: 5px

  .outside-container
    width: 534px
    margin: 0 auto




  .servipag
    max-width: 100%
    margin: 0 auto
    background-color: white
    border-radius: 8px
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16)

  .coupon-info-ctc
    border-radius: 4px
    background-color: #eeeeee
    width: 85%
    margin: 0 auto

    .cp-number
      font-family: 'DM Sans'
      font-size: 12px
      font-weight: normal
      font-stretch: normal
      font-style: normal
      line-height: 0.5
      letter-spacing: -0.3px
      text-align: left
      color: rgba(33, 33, 33, 0.75)

    .cp-amount
      font-family: 'Roboto'
      font-size: 24px
      font-weight: bold
      font-stretch: normal
      font-style: normal
      line-height: 0.67
      letter-spacing: -0.72px
      color: rgba(33, 33, 33, 0.75)

    .cp-total
      font-family: 'DM Sans'
      font-size: 10px
      font-weight: normal
      font-stretch: normal
      font-style: normal
      line-height: 1.5
      letter-spacing: -0.3px
      color: rgba(33, 33, 33, 0.75)

    .cp-sent
      font-family: 'DM Sans'
      font-size: 7px
      font-weight: normal
      font-stretch: normal
      font-style: normal
      line-height: 1.71
      letter-spacing: -0.21px
      color: rgba(33, 33, 33, 0.75)

    .separator
      height: 2px
      background-color: #999999

  .coupon-head
    font-family: 'DM Sans'
    font-size: 18px
    font-weight: normal
    font-stretch: normal
    font-style: normal
    letter-spacing: -0.45px
    text-align: left
    color: #676767
    text-align: center

  .cp-instructions
    font-family: 'Roboto'
    font-size: 10px
    font-weight: normal
    font-stretch: normal
    font-style: normal
    line-height: 1.8
    letter-spacing: -0.3px
    color: rgba(33, 33, 33, 0.75)
    text-align: center

  .cp-instructions-numbers
    font-family: 'Roboto'
    font-size: 24px
    font-weight: bold
    font-stretch: normal
    font-style: normal
    line-height: 0.67
    letter-spacing: -0.72px
    color: rgba(33, 33, 33, 0.75)
    text-align: center

  img.barcode
    width: 70%

  .cp-info
    font-family: 'Roboto'
    font-size: 10px
    font-weight: normal
    font-stretch: normal
    font-style: normal
    line-height: 2.29
    letter-spacing: normal
    color: rgba(33, 33, 33, 0.75)
    text-align: center

  .cp-reminder
    font-family: 'Roboto'
    font-size: 12px
    font-weight: normal
    font-stretch: normal
    font-style: normal
    line-height: 1.8
    letter-spacing: -0.3px
    color: rgba(33, 33, 33, 0.75)
    text-align: center

  .cp-disclaimer
    max-width: 100%
    margin-left: auto
    margin-right: auto
    font-family: 'Roboto'
    font-size: 12px
    font-weight: normal
    font-stretch: normal
    font-style: normal
    line-height: 1.67
    letter-spacing: 0.22px
    color: rgba(0, 0, 0, 0.87)

  @media only screen and (max-width: 600px)
    .btn-container
      flex-direction: column !important

  @media (min-width: 600px) and (max-width: 1100px)
    .servipag, .cp-disclaimer
      max-width: 70% !important

  @media only screen and (min-width: 1100px)
    .servipag, .cp-disclaimer
      max-width: 50% !important
</style>
