<template>
    <div>
      <v-container fluid class="pa-0 pb-8 overflow-y-auto">
          <v-container class="container-custom" v-if="!editMessage">
              <template>
                  <Breadcrumbs
                      :title="$t('modules.academic.processings.processingsCreate.breadcrumbs.title')"
                      :description="$t('modules.academic.processings.processingsEdit.breadcrumbs.description')"
                  ></Breadcrumbs>
              </template>
              <template>
                  <v-row justify="center">
                      <v-col class="viewSpaces" sm="12">
                          <OutlineCard
                          switcher
                          :switchValue="procesingData.status"
                          :switchLabel="$t('modules.academic.processings.processingsCreate.form.status')"
                          @switchChange="procesingData.status = !procesingData.status"
                          title="Trámites"
                          class="mb-6"
                          >
                          <v-row>
                              <v-col cols="12" lg="12" class="pb-0">
                                  <v-text-field
                                      :label="$t('modules.academic.processings.processingsCreate.form.name')"
                                      required
                                      outlined
                                      class="mb-8"
                                      v-model="$v.procesingData.name.$model"
                                      @blur="$v.procesingData.name.$touch()"
                                      ref="name"
                                      :error-messages="($v.procesingData.name.$dirty && $v.procesingData.name.$invalid) 
                                      ? ((!$v.procesingData.name.required) 
                                      ? $t('modules.academic.processings.processingsEdit.validations.name.required') 
                                      : (!$v.procesingData.name.maxLength) 
                                      ? $t('modules.academic.processings.processingsEdit.validations.name.maxLength') 
                                      : '') : ''"
                                  ></v-text-field>
                                  <v-text-field
                                      :label="$t('modules.academic.processings.processingsCreate.form.description')"
                                      required
                                      outlined
                                      class="mb-8"
                                      v-model="$v.procesingData.description.$model"
                                      @blur="$v.procesingData.description.$touch()"
                                      ref="description"
                                      :error-messages="($v.procesingData.description.$dirty && $v.procesingData.description.$invalid)
                                      ? ((!$v.procesingData.description.required)
                                      ? $t('modules.academic.processings.processingsEdit.validations.description.required')
                                      : (!$v.procesingData.description.maxLength)
                                      ? $t('modules.academic.processings.processingsEdit.validations.description.maxLength')
                                      : '') : ''"
                                  ></v-text-field>
                                  <v-text-field
                                      :label="$t('modules.academic.processings.processingsCreate.form.processingKey')"
                                      required
                                      outlined
                                      class="mb-8"
                                      v-model="$v.procesingData.key.$model"
                                      @blur="$v.procesingData.key.$touch()"
                                      ref="processingKey"
                                      :error-messages="($v.procesingData.key.$dirty && $v.procesingData.key.$invalid)
                                      ? ((!$v.procesingData.key.required)
                                      ? $t('modules.academic.processings.processingsEdit.validations.processingKey.required')
                                      : (!$v.procesingData.key.maxLength)
                                      ? $t('modules.academic.processings.processingsEdit.validations.processingKey.maxLength')
                                      : '') : ''"
                                  ></v-text-field>
                                  <v-select
                                    class="mb-8"
                                    label="Categorias"
                                    outlined
                                    disabled
                                    :items="categoriesData"
                                    :item-text="item => item.name"
                                    :item-value="item => item.id"
                                    v-model="selectedCategory"
                                ></v-select>
                                <OutlineCard class="mb-6" :class="{ 'required': $v.procesingData.processingHTML.$error }">
                                    <h2 class="mb-6 body-1">
                                        {{ $t('modules.academic.processings.processingsCreate.form.titleHtmlForm') }}
                                    </h2>
                                    <v-textarea class="mb-6"
                                        v-model="$v.procesingData.processingHTML.$model"
                                        @blur="$v.procesingData.processingHTML.$touch()"                                        
                                        ref="processingHTML">
                                    </v-textarea>
                                    <div v-if ="$v.procesingData.processingHTML.$dirty && !this.procesingData.processingHTML" class="error-message">{{$t('modules.academic.processings.processingsEdit.validations.processingHTML.required') }}</div>
                                </OutlineCard>
                                <v-text-field
                                    :label="$t('modules.academic.processings.processingsCreate.form.path')"
                                    outlined
                                    class="mb-8"
                                    @blur="$v.procesingData.path.$touch()"
                                    v-model="$v.procesingData.path.$model"                                    
                                    :error-messages="($v.procesingData.path.$dirty && $v.procesingData.path.$invalid)
                                      ? ((!$v.procesingData.path.maxLength)
                                      ? $t('modules.academic.processings.processingsEdit.validations.path.maxLength')
                                      : '') : ''"
                                    ref="path"
                                ></v-text-field>                                     
                                <v-text-field
                                    :label="$t('modules.academic.processings.processingsCreate.form.permission')"
                                    outlined
                                    class="mb-8"
                                    @blur="$v.procesingData.permission.$touch()"
                                    v-model="$v.procesingData.permission.$model"                                    
                                    :error-messages="($v.procesingData.permission.$dirty && $v.procesingData.permission.$invalid)
                                      ? ((!$v.procesingData.permission.maxLength)
                                      ? $t('modules.academic.processings.processingsEdit.validations.permission.maxLength')
                                      : '') : ''"
                                    ref="permission"
                                ></v-text-field>
                                <v-text-field
                                    :label="$t('modules.academic.processings.processingsEdit.form.validationCode')"
                                    outlined
                                    disabled
                                    class="mb-8"
                                    v-model="procesingData.validationCode"
                                ></v-text-field>
                                <v-row justify="space-between"
                                        outlined>
                                    <v-col>
                                        <v-checkbox
                                            label="Horizontal"
                                            outlined
                                            v-model="procesingData.horizontalSheet"
                                            ref="horizontalSheet"
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col>
                                        <v-checkbox
                                            label="Contiene Anexo"
                                            outlined
                                            v-model="procesingData.containsAnexo"
                                            ref="containsAnexo"
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col>
                                        <v-checkbox
                                            label="Requiere Pago"
                                            outlined
                                            v-model="procesingData.paymentRequired"
                                            ref="paymentRequired"
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>
                              </v-col>
                          </v-row>
  
                          </OutlineCard>
                          <div class="d-flex justify-end mb-4">
                            <v-container v-if="canDelete">
                                <Button
                                :text="$t('actions.delete')"
                                :disabled="!canDelete"
                                :loading="createButton.loading"
                                :success="createButton.success"
                                :error="createButton.error"
                                @end="resetButtonValues"
                                @clicked="deleteProcessing"   
                                ></Button>
                            </v-container>
                            <v-container class="d-flex justify-end mb-4">
                                <Button
                                depressed
                                white
                                :text="$t('actions.cancel')"
                                class="mr-4"
                                @clicked="$router.go(-1)"
                                @end="resetButtonValues"
                                ></Button>
                                <Button
                                :loading="createButton.loading"
                                :success="createButton.success"
                                :error="createButton.error"
                                :text="$t('actions.save')"
                                :successText="$t('actions.created')"
                                :errorText="'Error'"
                                :disabled="!canUpdate"
                                color="primary"
                                @end="resetButtonValues"
                                @clicked="validateProcessing"
                                ></Button>
                            </v-container>
                          </div>
                      </v-col>
                  </v-row>
              </template>
          </v-container>
          <successMessage
              v-else
              :type="successMessage.type"
              :title="successMessage.title"
              :actionPrimary="successMessage.actionPrimary"
              :actionSecondary="successMessage.actionSecondary"
        ></successMessage>
      </v-container>
    </div>
  </template>
  
  <script>
  import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
  import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
  import OutlineCard from '@/components/OutlinedCard/OutlinedCard';
  import Button from '@/components/Button/Button';
  import { $processings } from '../Services/index'
  import { required, maxLength, } from 'vuelidate/lib/validators';
  
  export default {
      name: 'ProcessingsCreate',
      components: {
          Breadcrumbs,
          SuccessMessage,
          OutlineCard,
          Button,
      },
      props: {
  
      },
      computed: {
      canDelete() {
        return this.$permissions.portaladministrativo.academic.processings.DELETE;
      },
      canUpdate() {
        return this.$permissions.portaladministrativo.academic.processings.UPDATE;
      },
      },
       data () {
          return {
              loading: false,
              successMessage: {
                type: null,
                title: null,
                actionPrimary: null,
                actionSecondary: null
              },
              createButton: {
                  loading: false,
                  success: false,
                  error: false
              },
              editMessage: false,
              procesingData: {
                name: '',
                description: '',
                paymentRequired: false,
                status: true,                
                category: {},
                containsAnexo: false,
                horizontalSheet: false,
                processingHTML: '',
                key: '',
                path: '',
                permission: '',
                validationCode: ''
              },
              categoriesData: [],
              selectedCategory: null,
          }
      },
      methods: {
        async fetchData(processingId) {
        if (this.loading) return false;
        try{
            const { data } = await $processings.find('categories', null, null);
            this.categoriesData = data;
            const response = await $processings.findById(processingId);
            this.procesingData.name = response.data.name;
            this.procesingData.description = response.data.description;
            this.procesingData.paymentRequired = response.data.paymentRequired;
            this.procesingData.status = response.data.enabled;
            this.procesingData.processingHTML = response.data.processingHtml;
            this.procesingData.category = response.data.category;
            this.selectedCategory = response.data.category;
            this.procesingData.containsAnexo = response.data.containsAnexo;
            this.procesingData.horizontalSheet = response.data.horizontalSheet;
            this.procesingData.key = response.data.key;
            this.procesingData.path = response.data.path;
            this.procesingData.permission = response.data.permission;
            this.procesingData.validationCode = response.data.validationCode;
            
        } catch(error) {
            this.procesingData.name = '';
            this.procesingData.description = '';
            this.procesingData.paymentRequired = false;
            this.procesingData.status = true;
            this.procesingData.processingHTML = '';
            this.procesingData.containsAnexo = false;
            this.procesingData.horizontalSheet = false;
            this.procesingData.path = '';
            this.procesingData.permission = '';
            this.procesingData.key = '';
            throw error;
        }
      },
      resetButtonValues() {
        this.createButton.loading = false;
        this.createButton.success = false;
        this.createButton.error = false;
      },
      validateProcessing() {
        this.$v.$touch();
        if (!this.$v.$invalid) {
            this.editProcessing();
        }
      },
      async deleteProcessing(){
        try{
            if(this.canDelete){
                const processingId = this.$route.params.id;
                await $processings.delete(processingId);
                this.successMessage.type = 'success';
                this.successMessage.title = this.$t('modules.academic.processings.processingsDelete.success.tittle');
                this.successMessage.actionPrimary = { 
                text: this.$t('modules.academic.processings.processingsDelete.actions.primary_text'), 
                callback: () => {
                    this.$router.push('/academic/processings') 
                } };
                this.editMessage = true;
            }
        }catch(error){
            this.successMessage.type = 'error';
            this.successMessage.title = this.$t('modules.academic.processings.processingsDelete.error.tittle');
            this.successMessage.actionPrimary = { 
                text: this.$t('modules.academic.processings.processingsDelete.actions.primary_text'), 
                callback: () => {
                    this.$router.push('/academic/processings') 
                } };
            this.successMessage.actionSecondary = {
                text: this.$t('modules.academic.processings.processingsDelete.actions.continue_editing'), 
                callback: () => {
                    this.editMessage = false;
                } };
            this.editMessage = true;
        }
      },
      async editProcessing() {
        try{
            if(this.canUpdate){
            const processingId = this.$route.params.id;
            const dataToSend = {
                name: this.procesingData.name,
                description: this.procesingData.description,
                paymentRequired: this.procesingData.paymentRequired,
                enabled: this.procesingData.status,
                processingHtml: this.procesingData.processingHTML,
                category: this.procesingData.category,
                containsAnexo: this.procesingData.containsAnexo,
                horizontalSheet: this.procesingData.horizontalSheet,
                key: this.procesingData.key,
                path: this.procesingData.path,
                permission: this.procesingData.permission
            }
            await $processings.update(processingId, dataToSend);
            this.successMessage.type = 'success';
            this.successMessage.title = this.$t('modules.academic.processings.processingsEdit.success.tittle');
            this.successMessage.actionPrimary = { 
                text: this.$t('modules.academic.processings.processingsEdit.actions.primary_text'), 
                callback: () => {
                    this.$router.push('/academic/processings') 
                } };
            this.editMessage = true; 
            }
        }catch (error) {
            this.successMessage.type = 'error';
            this.successMessage.title = this.$t('modules.academic.processings.processingsEdit.error.tittle');
            this.successMessage.actionPrimary = { 
                text: this.$t('modules.academic.processings.processingsEdit.actions.primary_text'), 
                callback: () => {
                    this.$router.push('/academic/processings') 
                } };
            this.successMessage.actionSecondary = {
                text: this.$t('modules.academic.processings.processingsEdit.actions.continue_editing'), 
                callback: () => {
                    this.editMessage = false;
                } };
            this.editMessage = true;
        }
      }
      },
      mounted() {
        const processingId = this.$route.params.id;
        this.fetchData(processingId);
      },
      validations: {
        procesingData:{
            name:{
                required,
                maxLength: maxLength(50),
            },
            description:{
                required,
                maxLength: maxLength(100),
            },
            processingHTML:{
                required
            },
            key: {
                required,
                maxLength: maxLength(30)
            },
            path: {     
                maxLength: maxLength(50),
            },
            permission: {             
                maxLength: maxLength(20),
            }
      }
    },
  }
  
  </script>
  
  <style lang="css" scoped>
    .error-message {
    color: red; 
    font-size: small;
    padding-left: 10px;
    }
  
  </style>