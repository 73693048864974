<template>
  <div>
    <v-container class="pa-0 pb-8" fluid>
      <v-container class="container-custom" v-if="!updateStatus">
        <template v-if="!insideDrawer">
          <Breadcrumbs
            :title="$t('modules.communities.update.breadcrumbs.title') + ' ' + companyName"
            :description="$t('modules.communities.update.breadcrumbs.subtitle')"
          >
          </Breadcrumbs>
        </template>
        <template>
        <v-row justify="center">
          <v-col class="viewSpaces" sm="12">
            <OutlinedCard
              :title="$t('modules.communities.update.card.title')"
              :switchLabel="community.status? $t('modules.communities.update.card.enabled') : $t('modules.communities.update.card.disabled')"
              switcher
              :switchValue="community.status"
              @switchChange="val => {community.status = val}"
              class="mb-6"
            >
              <v-row>
                <v-col sm="6">
                  <v-text-field
                    :error="$v.community.name.$anyError"
                    @blur="$v.community.name.$touch()"
                    v-model="$v.community.name.$model"
                    :label="`${$t('modules.communities.update.card.businessname')} *`"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col sm="6">
                  <v-text-field
                    :error="$v.community.activity.$anyError"
                    v-model="$v.community.activity.$model"
                    @blur="$v.community.activity.$touch()"
                    :label="`${$t('modules.communities.update.card.activity')} *`"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="6">
                  <!-- <IdentificationInput
                    v-model="community.identification"
                    :error-messages="(community.identification.$dirty && community.identification.$invalid) ? (!community.identification.unique ? $t('modules.users.create.validation_errors.personalForm.identification_unique') : $t('modules.users.create.validation_errors.personalForm.identification')) : ''"
                    :loading="loadingCode"
                    :mask="identificationMask"
                    @keyup="initTimer"
                    @validateRut="(status) => {this.validRut = status}"
                    :label="$t('modules.users.create.personalForm.identificationNumber') + ' *'">
                  </IdentificationInput> -->

                  <IdentificationInput
                    v-model="$v.community.identification.$model"
                    :error-messages="($v.community.identification.$dirty && $v.community.identification.$invalid) ? (!$v.community.identification.unique ? $t('modules.users.create.validation_errors.personalForm.identification_unique') : $t('modules.users.create.validation_errors.personalForm.identification')) : ''"
                    :loading="loadingCode"
                    @preTimer="() => {this.codeUnique = null}"
                    @posTimer="() => {this.validateCode()}"
                    @validateRut="(status) => {this.validRut = status}"
                    :label="$t('modules.users.create.personalForm.identificationNumber') + ' *'"
                    @blur="$v.community.identification.$touch()"
                  ></IdentificationInput>
                </v-col>
                <v-col sm="6">
                  <v-select
                    :error="$v.community.benefit.id.$anyError"
                    v-model="$v.community.benefit.id.$model"
                    @blur="$v.community.benefit.id.$touch()"
                    :label="`${$t('modules.communities.update.card.benefit')} *`"
                    :items="benefits"
                    item-text="description"
                    item-value="id"
                    outlined
                  ></v-select>
                </v-col>
                <v-col sm="6">
                  <v-text-field
                    :error="$v.community.token.$anyError"
                    v-model="$v.community.token.$model"
                    @blur="$v.community.token.$touch()"
                    :label="`${$t('modules.communities.update.card.token')} `"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col sm="3">
                  <v-switch
                    v-model="community.commision"
                    :label="$t('modules.communities.update.card.comission')"
                    @change="() => {community.amount = ''; community.percentage = '';}"
                    switcher
                    color="primary"
                    class="ma-0"
                  ></v-switch>
                </v-col>
                <v-col sm="6">
                  <v-row no-gutters>
                    <v-col sm="4">
                      <div @click="() => {community.amount = ''; $nextTick(() => $refs.percentage.focus());}">
                        <v-text-field
                          type="number"
                          :label="$t('modules.communities.update.card.percent')"
                          outlined
                          ref="percentage"
                          min="0"
                          max="100"
                          prepend-inner-icon="mdi-percent"
                          :error="$v.community.percentage.$anyError"
                          v-model.number="$v.community.percentage.$model"
                          @keypress="$validateDecimalInputNumber($event, $v.community.percentage.$model, 100)"
                          @blur="$v.community.percentage.$touch()"
                          @focus="() => community.amount = ''"
                          :disabled="community.amount > 0 || !community.commision"
                        ></v-text-field>
                      </div>
                    </v-col>
                    <v-subheader :or="$t('modules.communities.update.card.or')" class="mx-1">
                      {{$t('modules.communities.update.card.or')}}
                    </v-subheader>
                    <v-col sm="4">
                      <div @click="() => {community.percentage = ''; $nextTick(() => $refs.amount.focus());}">
                        <v-text-field
                          type="number"
                          :label="$t('modules.communities.update.card.amount')"
                          outlined
                          ref="amount"
                          min="0"
                          :error="$v.community.amount.$anyError"
                          v-model.number="$v.community.amount.$model"
                          @keypress="$validateDecimalInputNumber($event, $v.community.amount.$model)"
                          @blur="$v.community.amount.$touch()"
                          :disabled="community.percentage > 0 || !community.commision"
                        >
                          <template v-slot:prepend>
                            <span>{{ $countryConstants.currency }}</span>
                          </template>
                        </v-text-field>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </OutlinedCard>
            <div class="d-flex justify-end">
              <Button
                white
                :text="$t('actions.cancel')"
                @clicked="$router.push('/communities')"
                @end="resetButtonValues()"
                class="mr-4"
              >
              </Button>

              <Button
                :loading="updateButton.loading"
                :success="updateButton.success"
                :error="updateButton.error"
                :text="$t('actions.save')"
                :successText="$t('actions.saved')"
                :errorText="'Error'"
                :disabled="!canUpdate"
                @end="resetButtonValues()"
                @clicked="checkStatus()"
              >
              </Button>
            </div>
          </v-col>
        </v-row>
      </template>
      </v-container>
      <SuccessMessage
        v-else
        :type="successMessage.type"
        :title="successMessage.title"
        :actionPrimary="successMessage.actionPrimary"
        :actionSecondary="successMessage.actionSecondary"
      />
    </v-container>
  </div>
</template>

<script>
  import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
  import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
  import {required} from 'vuelidate/lib/validators';
  import Button from '@/components/Button/Button';
  import IdentificationInput from '@/components/IdentificationInput/IdentificationInput'
  import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
  import { $communities, $benefits, $users } from '../Services'

  export default {
    name: 'CommunitiesUpdate',
    components: {
      OutlinedCard,
      Breadcrumbs,
      Button,
      IdentificationInput,
      SuccessMessage
    },
    data: () => ({
      timeout: null,
      loadingCode: false,
      validRut: true,
      codeUnique: true,
      updateStatus: false,
      insideDrawer: false,
      community: {
        name: '',
        identification: '',
        activity: '',
        status: true,
        commision: false,
        percentage: '',
        amount: '',
        token: '',
        benefit: { id: '' }
      },
      benefits: [],
      updateButton: {
        loading: false,
        success: false,
        error: false
      },
      successMessage: {
        type: 'error',
        title: '',
        actionPrimary: null,
        actionSecondary: null
      },
    }),
    computed: {
      identificationMask () {
          return ['#######-X', '########-X'];
      },
      canUpdateCommunity() {
        return this.$permissions.portaladministrativo.prices.communities.UPDATE
      },
      canUpdate() {
        let invalidFields = false;
        let validCommision = true;

        const fields = [
          'name',
          'identification',
          'activity',
          'benefit'
        ];

        fields.forEach(item => {
          if(item === 'benefit'){
            if(this.$v.community[item].id.$anyError) invalidFields = true;
          }
          else {
            if(this.$v.community[item].$anyError) invalidFields = true;
          }
        });

        if(this.community.commision) {
          if(this.community.percentage <= 0 && this.community.amount <= 0)
            validCommision = false
        }

        return this.canUpdateCommunity && !invalidFields && validCommision && this.validRut && this.codeUnique;
      },

      companyName() {
        return `"${this.community.name}"`;
      },
    },
    methods: {
      checkStatus() {
        if(this.community.status){
          this.updateCommunity();
        }else{
          this.$store.dispatch('commons/openPopUp', {
            title: this.$t('modules.communities.update.warning_popup.title'),
            content: this.$t('modules.communities.update.warning_popup.content'),
            actionPrimary: {
              text: this.$t('modules.communities.update.warning_popup.update'),
              callback: this.updateCommunity
            },
            actionSecondary: {
              text: this.$t('modules.communities.update.warning_popup.cancel'),
              callback() {}
            },
            icon: { color: 'warning', name: 'mdi-eye-off-outline' },
            color: 'primary',
          })
        }
      },
      async validateCode() {
          this.$v.community.identification.$touch();
          if (this.$v.community.identification.$model) {
              this.codeUnique = null;
              this.loadingCode = true;
              try {
                  const response = await $users.find('exists', null, {params: {identification: this.$v.community.identification.$model}});
                  this.codeUnique = !response.data.exists;
                  this.loadingCode = false;
              } catch (error) {
                  this.codeUnique = null;
                  throw error
              } finally {
                  this.$v.community.identification.$touch();
              }
          }
      },
      updateCommunity() {
        if(!this.canUpdateCommunity) return false
        this.updateButton.loading = true;

        if(this.community.percentage == '') this.community.percentage = 0;
        if(this.community.amount == '') this.community.amount = 0;

        $communities.update(this.$route.params.id, this.community)
          .then(() => {
            this.successMessage.type = 'success';
            this.successMessage.title = this.$t('modules.communities.update.success_message.title');
            this.successMessage.actionPrimary = { text: this.$t('modules.communities.update.success_message.actions.primary_text'), callback: () => { this.$router.push('/communities') } }
            this.updateStatus = true;
          })
          .catch(() => {
            this.successMessage.type = 'error';
            this.successMessage.title = this.$t('modules.communities.update.error_message.title');
            this.successMessage.actionPrimary = { text: this.$t('modules.communities.update.success_message.actions.back'), callback: () => { this.updateStatus = false } }
            this.updateStatus = true;
            this.resetButtonValues()
          })
      },

      async getBenefits() {
        this.benefits = (await $benefits.find(null, null, {params: {page:0, length: 500, orderBy: 'id', orientation: 'desc'}})).data.content;
      },

      async getData() {
        let resp = await $communities.findById(this.$route.params.id);
        resp = resp.data;
        this.community.name = resp.name,
        this.community.identification =`${resp.identification.slice(0, -1)}-${resp.identification.slice(-1)}`
        this.community.activity = resp.activity,
        this.community.status = resp.status,
        this.community.commision = resp.commision,
        this.community.percentage = resp.percentage == 0? '' : resp.percentage,
        this.community.amount = resp.amount == 0? '' : resp.amount,
        this.community.benefit = { id: resp.benefit.id },
        this.community.token =resp.token
      },

      clearData() {
        this.resetButtonValues();
        this.$v.$reset();
        this.community = {
          name: '',
          identification: '',
          activity: '',
          status: true,
          commision: false,
          percentage: '',
          amount: '',
          benefit: { id: '' }
        };
      },

      resetButtonValues(){
        this.updateButton.loading = false;
        this.updateButton.success = false;
        this.updateButton.error = false;
      },

      async initialize() {
        await this.getBenefits();
        this.getData();
      }
    },
    validations: {
      community: {
        name: {required},
        identification: {
          required,
          unique () {
            return this.codeUnique !== false;
          }
        },
        activity: {required},
        token: {}, 
        percentage: {required},
        amount: {required},
        benefit: { id: {required} }
      },
    },
    mounted() {
      this.initialize();
    }
  }
</script>
