<template>
    <div id="dashboard-canvas" class="dashboard" :class="{ 'tour-active': tourActive }" v-if="($country === 'argentina' || $country === 'chile') && activeSubjects.length">
        <v-tour name="dashboard-tour" :steps="tourSteps" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>
        <div class="d-flex">
            <div id="dashboard-canvas-header" class="d-flex align-center pa-6" style="border-radius: 8px; background: #E0EBFF;">
                <h6 class="dashboard-title mr-2">{{ $t('modules.portalalumno.dashboard.banners.dashboard.title') }}</h6>
                <span class="dashboard-subtitle pa-1" v-html="$t('modules.portalalumno.dashboard.banners.dashboard.subtitle', { term, startClass, endClass })"></span>
            </div>
        </div>
        <div class="subDashboard d-flex px-6 pb-6">
            <div :id="`dashboard-canvas-subject-${i}`" class="card pa-3" v-for="(card, i) in cards" :key="`lms_subject_card_${i}`" :class="{'card-highlight': card.highlight}">
                <div class="d-flex flex-column">
                    <h6 class="card-subtitle mx-2">{{ $t('modules.portalalumno.dashboard.banners.dashboard.card.subtitle') }}</h6>
                    <h3 class="card-title mx-2">{{ card.title }}</h3>
                    <ul class="pa-0 mt-3" v-if="card.links.length">
                        <li class="d-flex align-center" v-for="(link, j) in card.links" :key="`lms_subject_link_${i}_${j}`">
                            <button
                                class="card-option text-left d-flex align-center"
                                :class="{ 'card-option-highlight': link.highlight, 'card-option-link': link.button && link.button.url }"
                                @click="openUrl(link.button)"
                            >
                                {{ link.text }}
                                <v-icon v-if="link.rightIcon" class="ml-2" :color="card.highlight ? 'white' : 'primary'" size="20px">{{ link.rightIcon }}</v-icon>
                                <span v-if="link.subText" class="card-option-due-date ml-2 pa-1">{{ link.subText }}</span>
                                <v-icon v-if="card.status != 85 && link.button && link.button.url" class="arrow ml-1" size="20px">mdi-chevron-right</v-icon>
                            </button>
                        </li>
                    </ul>
                    <template v-else-if="card.status == 85">
                        <h4 class="mx-2 mb-2 mt-5">{{ $t('modules.portalalumno.dashboard.banners.dashboard.card.regular.title') }}</h4>
                        <p class="mx-2">{{ $t('modules.portalalumno.dashboard.banners.dashboard.card.regular.subtitle') }}</p>
                        <v-btn class="mx-2 my-4" rounded color="primary" @click="redirectToExams(card)">{{ $t('modules.portalalumno.dashboard.banners.dashboard.card.regular.button') }}</v-btn>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { $studentSubject } from '../../Services';
import { mapGetters } from 'vuex';
import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

export default {
    name: 'CanvasDashboard',
    props: {
        subjects: { type: Array, default: () => [] }
    },
    data() {
        return {
            tourActive: false,
            redirecting: false,
            cards: [],
            tourOptions: {
                labels: {
                    buttonSkip: this.$t('modules.portalalumno.dashboard.banners.dashboard.tour.buttons.skip'),
                    buttonPrevious: this.$t('modules.portalalumno.dashboard.banners.dashboard.tour.buttons.previous'),
                    buttonNext: this.$t('modules.portalalumno.dashboard.banners.dashboard.tour.buttons.next'),
                    buttonStop: this.$t('modules.portalalumno.dashboard.banners.dashboard.tour.buttons.finish'),
                },
            },
            tourSteps: [
                { target: '#dashboard-canvas', offset: -60, content: this.$t('modules.portalalumno.dashboard.banners.dashboard.tour.steps.1') },
                { target: '#dashboard-canvas-subject-0', offset: -60, content: this.$t('modules.portalalumno.dashboard.banners.dashboard.tour.steps.2') },
                { target: '#dashboard-canvas-header', offset: -60, content: this.$t('modules.portalalumno.dashboard.banners.dashboard.tour.steps.3') },
            ],
            tourCallbacks: {
                onStart: this.tourStartCallback,
                onPreviousStep: this.tourPreviousCallback,
                onNextStep: this.tourNextCallback,
                onStop: this.tourStopCallback,
                onFinish: this.tourFinishCallback,
            }
        }
    },
    computed: {
        startClass() {
            return this.activeSubjects.length ? moment(this.activeSubjects[0].term.classStartDate).format('DD [de] MMMM') : ''
        },
        endClass() {
            return this.activeSubjects.length ? moment(this.activeSubjects[0].term.classEndDate).format('DD [de] MMMM') : ''
        },
        term() {
            return this.activeSubjects.length ? this.activeSubjects[0].term.code.split('/')[0] : ''
        },
        activeSubjects() {
            return this.subjects.filter(subject => [85, 86].includes(subject.status))
        },
        ...mapGetters({
            canvasId: 'commons/getCanvasId',
            studentData: 'commons/getStudentData',
        }),
    },
    mounted() {
        this.initializeCards()
    },
    beforeDestroy() {
        this.tourStopCallback()
    },
    methods: {
        initializeCards() {
            const cards = this.activeSubjects.map(subject => {
                const isHighlighted = subject.status == 86 && subject.classes && moment().format('YYYY-MM-DD') == moment(subject.classes.startAt).format('YYYY-MM-DD')
                const canvasBaseUrl = this.$countryConstants.canvas.url
                const card = {
                    title: subject.name ? subject.name.charAt(0).toUpperCase() + subject.name.slice(1).toLowerCase() : '',
                    highlight: isHighlighted,
                    status: subject.status,
                    code: subject.code,
                    links: []
                }

                if (subject.status == 86) {
                    if (isHighlighted && this.isCurrentDateBetween(subject.classes.startAt, subject.classes.endAt)) {
                        const urlPattern = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&=]*)/g
                        const courseHomeUrl = subject.api.lmsCourse ? `${canvasBaseUrl}courses/${subject.api.lmsCourse}` : null
                        card.links.push({
                            text: this.$t('modules.portalalumno.dashboard.banners.dashboard.card.links.clase_vivo'),
                            rightIcon: 'mdi-video',
                            highlight: true,
                            button: {
                                url: subject.classes && subject.classes.location && subject.classes.location.match(urlPattern) ? subject.classes.location : courseHomeUrl,
                                subjectId: subject.id,
                                event: {
                                    category: 'Home',
                                    label: 'Direct_Home',
                                    action: `Clk_UrlClasVivo_DirectHome_${subject.code}`,
                                }
                            }
                        })
                    } else if (subject.classes && subject.classes.startAt) {
                        const start = moment(subject.classes.startAt)
                        const daysUntilNextClass = start.diff(moment(), 'days')
                        const hoursUntilNextClass = start.diff(moment(), 'hours')
                        const minutesUntilNextClass = start.diff(moment(), 'minutes')

                        if (daysUntilNextClass >= 0) {
                            let nextClass = ''
                            if (daysUntilNextClass > 1) nextClass = this.$t('modules.portalalumno.dashboard.banners.dashboard.card.links.days', { days: daysUntilNextClass })
                            else if (daysUntilNextClass == 1) nextClass = this.$t('modules.portalalumno.dashboard.banners.dashboard.card.links.tomorrow')
                            else if (hoursUntilNextClass > 1) nextClass = this.$t('modules.portalalumno.dashboard.banners.dashboard.card.links.hours', { hours: hoursUntilNextClass })
                            else if (minutesUntilNextClass > 1) nextClass = this.$t('modules.portalalumno.dashboard.banners.dashboard.card.links.minutes', { minutes: minutesUntilNextClass })

                            card.links.push({
                                text: `${this.$t('modules.portalalumno.dashboard.banners.dashboard.card.links.next_class')} ${nextClass}`,
                                subText: daysUntilNextClass >= 1 ? start.format('DD/MM - ha') : start.format('ha'),
                                highlight: isHighlighted,
                            })
                        }
                    }

                    if (subject.api) {
                        let dueDate = null
                        if (subject.api.dueDate) {
                            const due = moment(subject.api.dueDate)
                            if (due.format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')) dueDate = `${this.$t('modules.portalalumno.dashboard.banners.dashboard.card.links.expires')} ${this.$t('modules.portalalumno.dashboard.banners.dashboard.card.links.today')}`
                            else if (due >= moment()) dueDate = `${this.$t('modules.portalalumno.dashboard.banners.dashboard.card.links.expires')} ${due.format('DD/MM')}`
                            else dueDate = this.$t('modules.portalalumno.dashboard.banners.dashboard.card.links.expired')
                        }

                        card.links.push({
                            text: this.$t('modules.portalalumno.dashboard.banners.dashboard.card.links.api'),
                            subText: dueDate,
                            button: {
                                url: subject.api && subject.api.lmsCourse 
                                    ? `${canvasBaseUrl}courses/${subject.api.lmsCourse}/assignments${subject.api.idApi ? `/${subject.api.idApi}` : ''}` 
                                    : null,
                                subjectId: subject.id,
                                event: {
                                    category: 'Home',
                                    label: 'Direct_Home',
                                    action: `Clk_UrlSendApi_DirectHome_${subject.code}`,
                                }
                            }
                        })
                        card.links.push({
                            text: this.$t('modules.portalalumno.dashboard.banners.dashboard.card.links.ae'),
                            button: {
                                // url: subject.api.lmsCourse ? `${canvasBaseUrl}courses/${subject.api.lmsCourse}/quizzes/${subject.api.typeApi ? subject.api.typeApi : ''}` : null,
                                url: subject.api.lmsCourse ? `${canvasBaseUrl}courses/${subject.api.lmsCourse}/quizzes` : null,
                                subjectId: subject.id,
                                event: {
                                    category: 'Home',
                                    label: 'Direct_Home',
                                    action: `Clk_UrlRealAutoEval_DirectHome_${subject.code}`,
                                }
                            }
                        })
                        card.links.push({
                            text: this.$t('modules.portalalumno.dashboard.banners.dashboard.card.links.recorded_class'),
                            button: {
                                url: subject.api.lmsCourse ? `${canvasBaseUrl}courses/${subject.api.lmsCourse}/external_tools/195` : null,
                                subjectId: subject.id,
                                event: {
                                    category: 'Home',
                                    label: 'Direct_Home',
                                    action: `Clk_UrlClasGrab_DirectHome_${subject.code}`,
                                }
                            }
                        })
                        card.links.push({
                            text: this.$t('modules.portalalumno.dashboard.banners.dashboard.card.links.study_material'),
                            button: {
                                url: subject.api.lmsCourse ? `${canvasBaseUrl}courses/${subject.api.lmsCourse}` : null,
                                subjectId: subject.id,
                                event: {
                                    category: 'Home',
                                    label: 'Direct_Home',
                                    action: `Clk_UrlVerMatEstud_DirectHome_${subject.code}`,
                                }
                            }
                        })
                    }

                } else {
                    
                    if (subject.api && subject.api.examsInscriptionDate) {
                        const inscriptionDate = moment(subject.api.examsInscriptionDate)
                        const days = inscriptionDate.clone().startOf('day').diff(moment().startOf('day'), 'days')

                        if (days >= 0) {
                            let date = ''
                            if (days > 1) date = this.$t('modules.portalalumno.dashboard.banners.dashboard.card.links.days', { days })
                            else if (days == 1) date = this.$t('modules.portalalumno.dashboard.banners.dashboard.card.links.tomorrow')
                            else date = this.$t('modules.portalalumno.dashboard.banners.dashboard.card.links.today')

                            card.links.push({
                                text: this.$t('modules.portalalumno.dashboard.banners.dashboard.card.links.inscription_date', { date }),
                                subText: days >= 1 ? inscriptionDate.format('DD/MM - ha') : inscriptionDate.format('ha'),
                                highlight: days < 1,
                            })
                        }
                    }
                }

                return card
            })

            this.cards = cards.sort((a, b) => (a.title > b.title) ? 1 : -1)
            if (this.cards.length) this.initTour()
        },
        redirectToExams(card) {
            this.$trackEvent('Home', `Clk_UrlInscribExam_DirectHome_${card.code}`, 'Direct_Home')
            this.$router.push('/exams')
        },
        isCurrentDateBetween(startAt, endAt) {
            return moment().isBetween(moment(startAt), moment(endAt), null, '[]')
        },
        async openUrl(button) {
            if (button && button.url && !this.redirecting) {
                if (button.event) this.$trackEvent(button.event.category, button.event.action, button.event.label)
                if (button.url.includes('instructure.com')) {
                    try {
                        this.redirecting = true
                        await $studentSubject.create({ lmsUserId: this.canvasId, studentSubjectId: button.subjectId, url: button.url }, {}, 'lms/redirect')
                    } finally {
                        this.$redirectToCanvas(this.canvasId, this.studentData.student.user.name)
                        this.redirecting = false
                    }
                } else {
                    window.open(button.url, '_blank')
                }
            }
        },
        initTour() {
            const tourTaken = JSON.parse(localStorage.getItem('tourTakenDashboardCanvas'));
            if (!tourTaken && !this.$isMobile) this.$tours['dashboard-tour'].start()
        },
        tourStartCallback() {
            this.$nextTick(() => {
                const appDom = document.querySelector('#app')
                if (appDom) appDom.classList.add('v-tour--active')
            })
            this.tourActive = true
            this.setActiveTourStep(0)
        },
        tourPreviousCallback(currentStep) {
            this.setActiveTourStep(currentStep - 1)
        },
        tourNextCallback(currentStep) {
            this.setActiveTourStep(currentStep + 1)
        },
        tourStopCallback() {
            this.tourActive = false
            this.setActiveTourStep()
            this.$nextTick(() => {
                const appDom = document.querySelector('#app')
                if (appDom) appDom.classList.remove('v-tour--active')
            })
        },
        tourFinishCallback() {
            localStorage.setItem('tourTakenDashboardCanvas', JSON.stringify(true));
        },
        setActiveTourStep(step = null) {
            this.$nextTick(() => {
                const activeStepDoms = document.querySelectorAll('.active-tour-step')
                activeStepDoms.forEach(dom => dom.classList.remove('active-tour-step'))
    
                if (step >= 0 && this.tourSteps[step]) {
                    const stepDom = document.querySelector(this.tourSteps[step].target)
                    if (stepDom) stepDom.classList.add('active-tour-step')
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

.dashboard {
    background: #E0EBFF;
    border-radius: 8px;
    font-size: 14px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
    font-family: 'Poppins', sans-serif;
    
    .dashboard-title { font-size: 20px; line-height: 16px; }
    .dashboard-subtitle {
        font-size: 12px;
        color: #111A29;
        font-weight: 400;
        line-height: 14px;
        background-color: #42E6EB;
        border-radius: 4px;
    }

    .subDashboard {
        gap: 24px; flex-wrap: wrap;

        .card {
            background: #ffffff;
            border: 4px;
            border-radius: 4px;
            width: 260px; /* Ancho fijo */
            height: auto;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            position: relative;

            .card-subtitle {
                font-size: 10px;
                color: #585F69;
                line-height: 16px;
            }

            .card-title {
                font-size: 16px;
                color: #111A29;
                height: 40px; 
                line-height: 20px; 
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
            }

            .card-option {
                font-size: 14px;
                padding: 10px 8px;
                border-radius: 4px;
                color: var(--v-primary-base);
                width: 100%;

                .arrow { color: var(--v-primary-base); }
                &:not(.card-option-link) { cursor: default; }
                &:hover.card-option-link,
                &.card-option-highlight {
                    background: #0056FE;
                    color: white;

                    .arrow { color: white; }
                }
                .card-option-due-date {
                    font-size: 12px;
                    color: #111A29;
                    line-height: 14px;
                    background-color: #C6FDFF;
                    border-radius: 4px;
                    padding-top: 5px !important;
                    text-wrap: nowrap;
                }
            }

            &.card-highlight {
                background: #111A29;

                .card-option {
                    .arrow { color: white; }
                    &:not(.card-option-link) { cursor: default; }
                    &:hover.card-option-link { background: #0056FE; color: white; }
                }
                .card-subtitle { color: #B3CCFF; }
                .card-title, .card-option { color: #E2E4E5; }
                .card-option-highlight {
                    background: #FE3D00; 
                    padding: 12px 8px;
                    margin: 4px 0;

                    &:not(.card-option-link) { cursor: default; }
                    &:hover.card-option-link { background: #DE3803; }
                }
            }
        }
    }
}

::v-deep .v-tour {
    .v-step {
        background: white;
        color: #111A29;
        border-radius: 8px;
        margin-top: 5px !important;

        .v-step__content {
            text-align: left;
        }
    }

    .v-step__button-skip {
        color: #888D94;
        border-color: #888D94;
        border-radius: 4px;
    }
    .v-step__button-previous {
        color: var(--v-primary-base);
        border-color: var(--v-primary-base);
        border-radius: 4px;
    }
    .v-step__button-next,
    .v-step__button-stop,
    .v-step__button-next:hover,
    .v-step__button-stop:hover {
        color: white;
        border-color: var(--v-primary-base);
        background-color: var(--v-primary-base);
        border-radius: 4px;
    }
}
.tour-active.active-tour-step,
.tour-active .active-tour-step {
    // box-shadow: 0 0 0 999em rgba(0,0,0,.5) !important;
    position: relative !important;
    z-index: 1 !important;
}

@media only screen and (max-width: 600px) {
    .card {
        width: 100%;
    }
}
</style>
