import Vue from 'vue'
import VueI18n from 'vue-i18n'

import modulesTranslations from '../../modules/main.i18n'
import componentsTranslations from '../components/main.i18n'

import Configuration from '../constants/configuration';

Vue.use(VueI18n);

const messages = {
  es_CL:{
    components: { ...componentsTranslations.es_CL },
    modules: { ...modulesTranslations.es_CL },
    responseCodeMeaning: {
      E200: 'Entidad creada correctamente.',
      E201: 'Entidad actualizada correctamente.',
      E202: 'Entidad eliminada correctamente.',
      E203: 'Ya existe un registro con los datos cargados.',
      E204: 'Los parámetros de consulta son inválidos.',
      E205: 'Los parámetros de consulta no pueden estar vacíos.',
      E206: 'No se encontró entidad con los parámetros provistos.',
      E207: 'Ocurrió un error al obtener listado.',
      E208: 'Ocurrió un error al obtener la entidad.',
      E209: 'Ocurrió un error al crear la entidad.',
      E210: 'Ocurrió un error al actualizar la entidad.',
      E211: 'Ocurrió un error al eliminar la entidad.',
      E212: 'No existe entidad por defecto para los párametros enviados.',
      E213: 'El código de validación es incorrecto.',
      E214: 'Correo enviado correctamente.',
      E215: 'SMS enviado correctamente.',
      E216: 'Ocurrió un error inesperado.',
      E218: 'No se puede dar de baja ya que el alumno realizó un pago.',
      E222: 'Error en hoja: {hoja}, columna: {columna}, fila: {fila}',
      default: 'Error.'
    },
  },
  es_PE: {
    modules: { ...modulesTranslations.es_PE },
  },
  es_MX: {
    modules: { ...modulesTranslations.es_MX },
    components: { ...componentsTranslations.es_MX },
  },
  es: {
    components: { ...componentsTranslations.es },
    modules: { ...modulesTranslations.es },
    responseCodeMeaning: {
      E200: 'Entidad creada correctamente.',
      E201: 'Entidad actualizada correctamente.',
      E202: 'Entidad eliminada correctamente.',
      E203: 'Ya existe un registro con los datos cargados.',
      E204: 'Los parámetros de consulta son inválidos.',
      E205: 'Los parámetros de consulta no pueden estar vacíos.',
      E206: 'No se encontró entidad con los parámetros provistos.',
      E207: 'Ocurrió un error al obtener listado.',
      E208: 'Ocurrió un error al obtener la entidad.',
      E209: 'Ocurrió un error al crear la entidad.',
      E210: 'Ocurrió un error al actualizar la entidad.',
      E211: 'Ocurrió un error al eliminar la entidad.',
      E212: 'No existe entidad por defecto para los párametros enviados.',
      E213: 'El código de validación es incorrecto.',
      E214: 'Correo enviado correctamente.',
      E215: 'SMS enviado correctamente.',
      E216: 'Ocurrió un error inesperado.',
      E218: 'No se puede dar de baja, ya que el alumno realizó un pago.',
      E222: 'Error en hoja: {hoja}, columna: {columna}, fila: {fila}',
      default: 'Error.'
    },
    actions: {
      accept: 'Aceptar',
      manage: 'Gestionar',
      finish: 'Finalizar',
      notAccept: 'No Aceptar',
      accepted: 'Aceptado',
      add: 'Agregar',
      apply: 'Solicitar',
      added: 'Agregado',
      annul: 'Anular',
      approve: 'Aprobar',
      back: 'Atrás',
      cancel: 'Cancelar',
      close: 'Cerrar',
      confirm: 'Confirmar',
      confirmation_delete: '¿Estás seguro de eliminar?',
      create: 'Crear',
      create_another: 'Crear otro',
      created: 'Creado',
      delete: 'Eliminar',
      drop: 'Dar de baja',
      duplicate: 'Duplicar',
      edit: 'Editar',
      error: 'Error',
      forgotUser: '¿Olvidaste tu usuario?',
      forgotPass: '¿Olvidaste tu contraseña?',
      goToHome: 'Ir al Inicio',
      goToPage: 'Ir a página',
      inscribe: 'Inscribir',
      load: 'Cargar',
      loaded: 'Cargado',
      new: 'Nuevo',
      new_female: 'Nueva',
      next: 'Siguiente',
      no: 'No',
      not_save: 'No guardar',
      pay: 'Pagar',
      reject: 'Rechazar',
      return: 'Volver',
      save: 'Guardar',
      saved: 'Guardado',
      see: 'Ver',
      search: 'Buscar',
      select: 'Seleccionar',
      send: 'Enviar',
      continue: 'Continuar',
      sent: 'Enviado',
      success: 'Éxito',
      warning: 'Atención',
      yes: 'Si',
      import_file: 'Importar archivo',
      download_template: 'Descargar modelo',
      details:'Detalles',
      logout:'Cerrar Sesión',
      enter: 'Ingresar',
      goBack: 'Volver',
      backToTop: 'Volver al inicio',
      watch: 'Ver secciones',
      watchSubjects: 'Ver materias',
      export: 'Exportar',
      toPrint: 'Imprimir'

    },
    accessDenied: 'No tienes permisos para acceder a los datos solicitados',
    enabled: 'Habilitado',
    disabled: 'Deshabilitado',
    delete_popup_title: '¿Estás seguro de eliminar?',
    disabledMessage: 'Estás cargando el registro de manera deshabilitada. ¿Deseas continuar?',
    disabledMessageUpdate: 'Estás guardando el registro de manera deshabilitada. ¿Deseas continuar?',
    invalidMessageUpdate: 'Estás guardando el registro con campos vacios',
    invalidMessage: 'Estás cargando el registro con campos vacios',
    cancelMessage: 'Si cancelas, se perderán los cambios realizados. ¿Deseas continuar?',
    errorMessage: 'Por favor, completar el formulario correctamente para continuar.',
    alert: 'Atención',
    home: {
      welcome_message: {
        title: 'Estamos creando contenido',
        description: 'Mientras, puedes navegar por el resto de la aplicación'
      }
    },
    login: {
      welcomeMessage: 'Iniciar sesión',
      subMessage: 'Ingresa tu Correo Electrónico y Contraseña',
      serverError: 'Error en el servidor. Intenta nuevamente más tarde.',
      authError: 'Error de autenticación. Se ha ingresado un usuario y/o contraseña incorrecto.',
      items: {
        email: 'Correo Electrónico',
        password: 'Contraseña',
      },
      errors: {
        invalidEmail: 'El Correo Electrónico es inválido',
        requiredEmail: 'El Correo Electrónico es requerido',
        requiredPassword: 'La contraseña es requerida'
      }
    },
    helpMenu: {
      title: 'Ayuda',
      helpSection: {
        title: 'AYUDA',
        items: {
          documentation: 'Documentación',
          news: 'Novedades',
          about: 'Acerca de SIS',
          shortcuts: 'Atajos de teclado',
        }
      },
      legalSection: {
        title: 'ASUNTOS LEGALES',
        items: {
          privacyPolicy: 'Política de privacidad',
          termsConditions: 'Términos y condiciones',
        }
      }
    },

  },
  en: {
    components: { ...componentsTranslations.en },
    modules: { ...modulesTranslations.en },
    responseCodeMeaning: {
      E200: 'Entity created successfully.',
      E201: 'Entity updated successfully.',
      E202: 'Entity deleted successfully.',
      E203: 'The entity already exists on the system.',
      E204: 'Invalid query parameters.',
      E205: 'Query parameters cannot be empty.',
      E206: 'No matching entity for given parameters.',
      E207: 'An error ocurred while getting the list.',
      E208: 'An error ocurred while getting the entity.',
      E209: 'An error ocurred while creating the entity.',
      E210: 'An error ocurred while updating the entity.',
      E211: 'An error ocurred while deleting the entity.',
      E212: 'No default entity for given parameters.',
      E213: 'Invalid validation code.',
      E214: 'Email successfully sent.',
      E215: 'SMS successfully sent.',
      E216: 'An unexpected error ocurred.',
      default: 'Error.'
    },
    actions: {
      add: 'Add',
      send: 'Send',
      sent: 'Sent',
      save: 'Save',
      saved: 'Saved',
      create: 'Create',
      created: 'Created',
      duplicate: 'Duplicate',
      new: 'New',
      accept: 'Accept',
      accepted: 'Accepted',
      search: 'Search',
      next: 'Next',
      forgot: 'You forgot it?',
      error: 'Error'
    },
  }
};


export const i18n = new VueI18n({
  locale: Configuration.value('lang') || 'es',
  fallbackLocale: 'es',
  silentFallbackWarn: true,
  messages,
});
