const paymentsContractsTranslations = {
  es_CL: {
    view: {
      contract: {
        breadcrumbs: {
          title: 'Contratos',
          description: 'Acá podrás ver tus contratos vigentes.',
        },
        title: 'Contratos',
        message:'No tienes contratos vigentes en este momento.',
        contractTable:{
          dataSection:{
            sign_date:'Fecha de firma',
            period:'Periodo',
            download:'Descargar Contrato',
          },
        },
      },
    },
  },
  es_PE: {
    view: {
      contract: {
        breadcrumbs: {
          title: 'Contratos',
          description: 'Acá podrás ver tus contratos vigentes.',
        },
        title: 'Contratos',
        message:'No tienes contratos vigentes en este momento.',
        contractTable:{
          dataSection:{
            sign_date:'Fecha de firma',
            period:'Periodo',
            download:'Descargar Contrato',
          },
        },
      },
    },
  },
  es_MX: {
    view: {
      contract: {
        breadcrumbs: {
          title: 'Contratos',
          description: 'Acá podrás ver tus contratos vigentes.',
        },
        title: 'Contratos',
        message:'No tienes contratos vigentes en este momento.',
        contractTable:{
          dataSection:{
            sign_date:'Fecha de firma',
            period:'Periodo',
            download:'Descargar Contrato',
          },
        },
      },
    },
  },
  es: {
    view: {
      contract: {
        breadcrumbs: {
          title: 'Contratos',
          description: 'Acá podrás ver tus contratos vigentes.',
        },
        title: 'Contratos',
        message:'No tienes contratos vigentes en este momento.',
        contractTable:{
          dataSection:{
            sign_date:'Fecha de firma',
            period:'Periodo',
            download:'Descargar Contrato',
          },
        },
      },
    },
  },
  en: {
    view: {
      contract: {
        breadcrumbs: {
          title: 'Contracts',
          description: 'Here you can view your active contracts.',
        },
        title: 'Contracts',
        message: 'You have no active contracts at this moment.',
        contractTable: {
          dataSection: {
            sign_date: 'Signature Date',
            period: 'Period',
            download: 'Download Contract',
          },
        },
      },      
    },
  },
};

export default paymentsContractsTranslations
