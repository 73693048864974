var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',{staticClass:"pa-0 pb-8 overflow-y-auto",attrs:{"fluid":""}},[(!_vm.createProcessingMessage)?_c('v-container',{staticClass:"container-custom"},[[_c('Breadcrumbs',{attrs:{"title":_vm.$t('modules.academic.processings.processingsCreate.breadcrumbs.title'),"description":_vm.$t('modules.academic.processings.processingsCreate.breadcrumbs.description')}})],[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"viewSpaces",attrs:{"sm":"12"}},[_c('OutlineCard',{staticClass:"mb-6",attrs:{"switcher":"","switchValue":_vm.procesingData.status,"switchLabel":_vm.$t('modules.academic.processings.processingsCreate.form.status'),"title":"Trámites"},on:{"switchChange":function($event){_vm.procesingData.status = !_vm.procesingData.status}}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","lg":"12"}},[_c('v-text-field',{ref:"name",staticClass:"mb-8",attrs:{"label":_vm.$t('modules.academic.processings.processingsCreate.form.name'),"required":"","outlined":"","error-messages":(_vm.$v.procesingData.name.$dirty && _vm.$v.procesingData.name.$invalid) 
                                    ? ((!_vm.$v.procesingData.name.required) 
                                    ? _vm.$t('modules.academic.processings.processingsEdit.validations.name.required') 
                                    : (!_vm.$v.procesingData.name.maxLength) 
                                    ? _vm.$t('modules.academic.processings.processingsEdit.validations.name.maxLength') 
                                    : '') : ''},on:{"blur":function($event){return _vm.$v.procesingData.name.$touch()}},model:{value:(_vm.$v.procesingData.name.$model),callback:function ($$v) {_vm.$set(_vm.$v.procesingData.name, "$model", $$v)},expression:"$v.procesingData.name.$model"}}),_c('v-text-field',{ref:"description",staticClass:"mb-8",attrs:{"label":_vm.$t('modules.academic.processings.processingsCreate.form.description'),"required":"","outlined":"","error-messages":(_vm.$v.procesingData.description.$dirty && _vm.$v.procesingData.description.$invalid)
                                    ? ((!_vm.$v.procesingData.description.required)
                                    ? _vm.$t('modules.academic.processings.processingsCreate.validations.description.required')
                                    : (!_vm.$v.procesingData.description.maxLength)
                                    ? _vm.$t('modules.academic.processings.processingsCreate.validations.description.maxLength')
                                    : '') : ''},on:{"blur":function($event){return _vm.$v.procesingData.description.$touch()}},model:{value:(_vm.$v.procesingData.description.$model),callback:function ($$v) {_vm.$set(_vm.$v.procesingData.description, "$model", $$v)},expression:"$v.procesingData.description.$model"}}),_c('v-text-field',{ref:"processingKey",staticClass:"mb-8",attrs:{"label":_vm.$t('modules.academic.processings.processingsCreate.form.processingKey'),"required":"","outlined":"","error-messages":(_vm.$v.procesingData.key.$dirty && _vm.$v.procesingData.key.$invalid)
                                    ? ((!_vm.$v.procesingData.key.required)
                                    ? _vm.$t('modules.academic.processings.processingsCreate.validations.processingKey.required')
                                    : (!_vm.$v.procesingData.key.maxLength)
                                    ? _vm.$t('modules.academic.processings.processingsCreate.validations.processingKey.maxLength')
                                    : '') : ''},on:{"blur":function($event){return _vm.$v.procesingData.key.$touch()}},model:{value:(_vm.$v.procesingData.key.$model),callback:function ($$v) {_vm.$set(_vm.$v.procesingData.key, "$model", $$v)},expression:"$v.procesingData.key.$model"}}),_c('v-select',{staticClass:"mb-8",attrs:{"label":"Categorias","outlined":"","items":_vm.categoriesData,"item-text":item => item.name,"item-value":item => item.id},model:{value:(_vm.selectedCategory),callback:function ($$v) {_vm.selectedCategory=$$v},expression:"selectedCategory"}}),_c('OutlineCard',{staticClass:"mb-6",class:{ 'required': _vm.$v.procesingData.processingHTML.$error }},[_c('h2',{staticClass:"mb-6 body-1"},[_vm._v(" "+_vm._s(_vm.$t('modules.academic.processings.processingsCreate.form.titleHtmlForm'))+" ")]),_c('v-textarea',{ref:"processingHTML",staticClass:"mb-6",on:{"blur":function($event){return _vm.$v.procesingData.processingHTML.$touch()}},model:{value:(_vm.$v.procesingData.processingHTML.$model),callback:function ($$v) {_vm.$set(_vm.$v.procesingData.processingHTML, "$model", $$v)},expression:"$v.procesingData.processingHTML.$model"}}),(_vm.$v.procesingData.processingHTML.$dirty && !this.procesingData.processingHTML)?_c('div',{staticClass:"error-message"},[_vm._v(_vm._s(_vm.$t('modules.academic.processings.processingsEdit.validations.processingHTML.required')))]):_vm._e()],1),_c('v-text-field',{ref:"path",staticClass:"mb-8",attrs:{"label":_vm.$t('modules.academic.processings.processingsCreate.form.path'),"outlined":"","error-messages":(_vm.$v.procesingData.path.$dirty && _vm.$v.procesingData.path.$invalid)
                                    ? ((!_vm.$v.procesingData.path.maxLength)
                                    ? _vm.$t('modules.academic.processings.processingsEdit.validations.path.maxLength')
                                    : '') : ''},on:{"blur":function($event){return _vm.$v.procesingData.path.$touch()}},model:{value:(_vm.$v.procesingData.path.$model),callback:function ($$v) {_vm.$set(_vm.$v.procesingData.path, "$model", $$v)},expression:"$v.procesingData.path.$model"}}),_c('v-text-field',{ref:"permission",staticClass:"mb-8",attrs:{"label":_vm.$t('modules.academic.processings.processingsCreate.form.permission'),"outlined":"","error-messages":(_vm.$v.procesingData.permission.$dirty && _vm.$v.procesingData.permission.$invalid)
                                    ? ((!_vm.$v.procesingData.permission.maxLength)
                                    ? _vm.$t('modules.academic.processings.processingsEdit.validations.permission.maxLength')
                                    : '') : ''},on:{"blur":function($event){return _vm.$v.procesingData.permission.$touch()}},model:{value:(_vm.$v.procesingData.permission.$model),callback:function ($$v) {_vm.$set(_vm.$v.procesingData.permission, "$model", $$v)},expression:"$v.procesingData.permission.$model"}}),_c('v-row',[_c('v-col',[_c('v-checkbox',{ref:"horizontalSheet",attrs:{"label":"Horizontal","outlined":""},model:{value:(_vm.procesingData.horizontalSheet),callback:function ($$v) {_vm.$set(_vm.procesingData, "horizontalSheet", $$v)},expression:"procesingData.horizontalSheet"}})],1),_c('v-col',[_c('v-checkbox',{ref:"containsAnexo",attrs:{"label":"Contiene Anexo","outlined":""},model:{value:(_vm.procesingData.containsAnexo),callback:function ($$v) {_vm.$set(_vm.procesingData, "containsAnexo", $$v)},expression:"procesingData.containsAnexo"}})],1),_c('v-col',[_c('v-checkbox',{ref:"paymentRequired",attrs:{"label":"Requiere Pago","outlined":""},model:{value:(_vm.procesingData.paymentRequired),callback:function ($$v) {_vm.$set(_vm.procesingData, "paymentRequired", $$v)},expression:"procesingData.paymentRequired"}})],1)],1)],1)],1)],1),_c('div',{staticClass:"d-flex justify-end mb-4"},[_c('Button',{staticClass:"mr-4",attrs:{"depressed":"","white":"","text":_vm.$t('actions.cancel')},on:{"clicked":function($event){return _vm.$router.go(-1)},"end":_vm.resetButtonValues}}),_c('Button',{attrs:{"loading":_vm.createButton.loading,"success":_vm.createButton.success,"error":_vm.createButton.error,"text":_vm.$t('actions.create'),"successText":_vm.$t('actions.created'),"errorText":'Error',"disabled":!_vm.canCreate,"color":"primary"},on:{"end":_vm.resetButtonValues,"clicked":_vm.validateProcessing}})],1)],1)],1)]],2):_c('successMessage',{attrs:{"type":_vm.successMessage.type,"title":_vm.successMessage.title,"actionPrimary":_vm.successMessage.actionPrimary,"actionSecondary":_vm.successMessage.actionSecondary}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }