<template>
  <div>
    <v-container fluid class="pa-0 pb-8">
      <v-container class="container-custom">
        <Breadcrumbs :title="$t('modules.settings.student.breadcrumbs.title')"
          :description="$t('modules.settings.student.breadcrumbs.description')"></Breadcrumbs>
        <template>
          <v-row justify="center">
            <v-col class="viewSpaces" sm="12">
              <StudentsForm :formData="students" class="mb-6"></StudentsForm>

              <div class="d-flex justify-end">
                <Button white :text="$t('actions.cancel')" @clicked="$router.replace('/settings/students')"
                  class="mr-4"></Button>

                <Button :loading="saveButton.loading" :success="saveButton.success" :error="saveButton.error"
                  :text="$t('actions.save')" :successText="$t('actions.saved')" :errorText="'Error'"
                  :disabled="isSaveButtonDisabled"  @clicked="updateStudent" @end="resetButtonValues()"></Button>
              </div>
            </v-col>
          </v-row>
        </template>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import Button from '@/components/Button/Button'
import StudentsForm from '../Components/StudentsForm/StudentsForm.vue';
import { $settingsStudents } from '../services'

export default {
  components: {
    StudentsForm,
    Breadcrumbs,
    Button
  },
  data() {
    return {
      students: {
        value: '',
        description: '',
      },
      saveButton: {
        loading: false,
        success: false,
        error: false
      },
      successMessage: {
        title: this.$t('modules.settings.student.update.messages.updateSuccess'),
        actionPrimary: { text: 'Aceptar', callback: () => { this.$router.push('/settings/students') } }
      },
      forceRerender: 0,
      loading: false,
    }
  },
  computed: {
    canUpdate() {
      return this.$permissions.settings.students.UPDATE
    },
    isSaveButtonDisabled() {
    return !this.canUpdate || this.loading || !this.students.value.trim() || !this.students.description.trim();
  },
  },
  methods: {

    async updateStudent() {
      if (!this.canUpdate) return;

      this.saveButton.loading = true;

      try {
        const { id } = this.$route.params;
        const studentData = {
          value: this.students.value,
          description: this.students.description
        };

        const response = await $settingsStudents.update(id, { ...studentData });

        if (response.status === 200) {
          this.saveButton.success = true;
          this.$router.push('/settings/students');
        } else {
          throw new Error('Failed to update student');
        }
      } catch (error) {
        this.saveButton.error = true;
      } finally {
        this.saveButton.loading = false;
      }
    },
    async fetchData() {
      this.loading = true;

      try {
        const { id } = this.$route.params;
        const response = await $settingsStudents.find();
        const student = response.data.find(item => parseInt(item.id) === parseInt(id));

        if (student) {
          this.students = {
            value: student.value || '',
            description: student.description || ''
          };
        }
      } catch (error) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },

    resetSaveButtonValues() {
      this.saveButton.loading = false;
      this.saveButton.success = false;
      this.saveButton.error = false;
    },
  },
  mounted() {
    this.fetchData()
  }
}
</script>