
const validatorTranslations = {
  es_PE: {
      validator: {
          certificateTitle: 'CERTIFICADO DE ESTUDIANTE REGULAR'
      }
  },  
  es: {
    validator: {
      code: 'Código',
      title: 'Validación de certificados emitidos',
      inputRut: 'El RUT debe ser ingresado sin guion ni dígito verificador',
      inputCode: 'Ingresa el código de validación',
      button: 'Validar',
      validated: 'Validado',
      certificateTitle: 'CERTIFICADO DE ALUMNO REGULAR',
      rut: 'Rut estudiante',
      name: 'Nombre de estudiante',
      career: 'Carrera',
      careerType: 'Nivel de carrera',
      titleName: 'Nombre de título',
      status: 'Estado',
      ceo: 'Sede',
      period: 'Periodo',
      dateTitle: 'Año de título',
      egressDate: 'Año de Egreso',
      presentationTo: 'Para ser presentado',
      errorMessage: 'El código ingresado es inválido',
      rutRequired: 'Ingrese el rut',
    }
  },
  es_MX: {
    validator: {
      code: 'Código',
      title: 'Validación de certificados emitidos',
      inputRut: 'El CURP debe ser ingresado sin guion ni dígito verificador',
      inputCode: 'Ingresa el código de validación',
      button: 'Validar',
      validated: 'Validado',
      certificateTitle: 'CERTIFICADO DE ALUMNO REGULAR',
      rut: 'CURP estudiante',
      name: 'Nombre de estudiante',
      career: 'Carrera',
      careerType: 'Nivel de carrera',
      titleName: 'Nombre de título',
      status: 'Estado',
      ceo: 'Sede',
      period: 'Periodo',
      dateTitle: 'Año de título',
      egressDate: 'Año de Egreso',
      presentationTo: 'Para ser presentado',
      errorMessage: 'El código ingresado es inválido',
      rutRequired: 'Ingrese el CURP',
    }
  },
  en: {
  }
};


export default validatorTranslations
