<template>
  <v-container fluid class="d-flex pa-0 fullHeight">
    <AsideBar :items="asidebar.items" :title="asidebar.title"></AsideBar>

    <v-container fluid class="scrollableContent pa-0">
      <v-container class="container-custom px-6">
        <Breadcrumbs :title="$t('modules.prepaids.table.breadcrumbs.title')"
          :description="$t('modules.prepaids.table.breadcrumbs.description')">
          <Button v-if="canCreate" @clicked="newPrepaid" :icon="'mdi-plus'" :text="$t('actions.new')"
            depressed></Button>
        </Breadcrumbs>
        <v-row justify="center">
          <v-col sm="12" class="viewSpaces">
            <SuperTable :headers="headers" :data="prepaids" :clear-selected="clearSelected"
              :page-count="pagination.pagesAmount" :page="pagination.page" :total-items="totalItems"
              :deleteTitle="$t('modules.prepaids.table.actions.bulkDelete')"
              :permissions="{ update: canUpdate, delete: canDelete }" :sortBy.sync="sort.sortBy"
              :sortDesc.sync="sort.sortDesc" @searchAction="searchAction" @bulk-delete="bulkDeletePopUp"
              @deleteData="deletePopUp" @updateData="updatePrepaid" @input-pagination="onInputPagination"
              @length-pagination-change="onLengthPaginationChange" />

          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </v-container>
</template>

<script>
import SuperTable from '../Components/SuperTable';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import Button from '@/components/Button/Button';
import { $prepaids, $careers, $zones } from '../Services';
import AsideBar from '@/components/AsideBar/AsideBar'
export default {
  name: 'PrepaidsTable',
  components: {
    SuperTable,
    Breadcrumbs,
    Button,
    AsideBar
  },
  data() {
    return {
      loading: false,
      search: null,
      page: 1,
      totalItems: null,
      clearSelected: null,
      prepaids: [],
      asidebar: {
        title: 'Refinar búsqueda',
        pagination: {
          limit: 20,
          page: 1,
          currentPage: 1,
          pagesAmount: 1
        },
        items: [
          {
            name: this.$t('modules.prepaids.table.headers.zone'),
            filters: null
          },
          {
            name: this.$t('modules.prepaids.table.headers.status'),
            filters: [
              {
                name: this.$t('modules.prepaids.table.status.actives'),
                type: 'check',
                value: true,
                dataFiltered: ''
              },
              {
                name: this.$t('modules.prepaids.table.status.inactives'),
                type: 'check',
                value: false,
                dataFiltered: ''
              },
            ]
          },
          {
            name: this.$t('modules.prepaids.table.headers.nameCareer'),
            filters: null
          },
          {
            name: this.$t('modules.prepaids.table.headers.studentType'),
            filters: [
              {
                name: this.$t('modules.prepaids.table.studentType.ri'),
                type: 'check',
                value: true,
                dataFiltered: ''
              },
              {
                name: this.$t('modules.prepaids.table.studentType.ni'),
                type: 'check',
                value: true,
                dataFiltered: ''
              },
            ]
          },
        ]
      },
      sort: {
        sortBy: 'code',
        sortDesc: false,
      },
      headers: [
        {
          text: this.$t('modules.prepaids.table.headers.code'),
          value: 'code'
        }, {
          text: this.$t('modules.prepaids.table.headers.description'),
          value: 'description'
        }, {
          text: this.$t('modules.prepaids.table.headers.dayRange'),
          value: 'detail',
          sortable: false,
          align: 'center'
        }, {
          text: this.$t('modules.prepaids.table.headers.percentage'),
          value: 'percentage',
          sortable: false,
        }, {
          text: this.$t('modules.prepaids.table.headers.studentType'),
          value: 'studentType',
        }, {
          text: this.$t('modules.prepaids.table.headers.scholarship'),
          value: 'scholarship',
        }, {
          text: this.$t('modules.prepaids.table.headers.average'),
          value: 'average',
        }, {
          text: this.$t('modules.prepaids.table.headers.status'),
          value: 'status'
        }, {
          text: '',
          value: 'action',
          sortable: false,
        },
      ],
      pagination: {
        limit: 20,
        page: 1,
        pagesAmount: 1
      },
    };
  },
  async mounted() {
    await this.getCareers();
    await this.getZones()
    this.fetchData();

  },
  computed: {
    canCreate() {
      return this.$permissions.portaladministrativo.prices.prepaids.CREATE;
    },
    canUpdate() {
      return this.$permissions.portaladministrativo.prices.prepaids.READ || this.$permissions.portaladministrativo.prices.prepaids.UPDATE;
    },
    canDelete() {
      return this.$permissions.portaladministrativo.prices.prepaids.DELETE;
    },
    selectedCareers() {
      if (this.asidebar.items[2].filters) {
        const filtered = this.asidebar.items[2].filters.filter(item => item.value == true);
        filtered.forEach(item => item.disabled = false);
        return filtered.map(item => item.id);
      }
      return [];
    },
    selectedZones() {
      if (this.asidebar.items[0].filters) {
        const filtered = this.asidebar.items[0].filters.filter(item => item.value == true);
        filtered.forEach(item => item.disabled = false);
        return filtered.map(item => item.id);
      }
      return [];
    },
    selectedStatus() {
      const ret = [];
      if (this.asidebar.items[1].filters) {
        const filtered = this.asidebar.items[1].filters.filter(item => item.value == true);
        if (filtered.length == 1) filtered[0].disabled = true;
        else filtered.forEach(item => item.disabled = false);
        if (this.asidebar.items[1].filters[0].value) ret.push('active');
        if (this.asidebar.items[1].filters[1].value) ret.push('inactive')
      }
      return ret;
    },
    selectedStudentType() {
      const ret = [];
      if (this.asidebar.items[3].filters) {
        const filtered = this.asidebar.items[3].filters.filter(item => item.value == true);
        if (filtered.length == 1) filtered[0].disabled = true;
        else filtered.forEach(item => item.disabled = false);
        if (this.asidebar.items[3].filters[0].value) ret.push(8);
        if (this.asidebar.items[3].filters[1].value) ret.push(7)
      }
      return ret;
    },
  },
  methods: {
    async getCareers() {
      const careers = (await $careers.find(null, null, { params: { page: 0, length: 500 } })).data;
      careers.content.sort((a, b) => a.description.localeCompare(b.description));
      const careerId = this.$route.query.rol
      this.asidebar.items[2].filters = careers.content.map(item => ({
        id: item.id,
        name: item.description,
        type: 'check',
        value: item.id == careerId,
        dataFiltered: ''
      }));
    },

    async getZones() {
      const zones = (await $zones.find(null, null, { params: { page: 0, length: 500 } })).data.content;
      const zonesId = this.$route.query.rol
      zones.sort((a, b) => a.zoneDescription.localeCompare(b.zoneDescription));
      this.asidebar.items[0].filters = zones.map(item => ({
        id: item.id,
        name: item.zoneDescription,
        type: 'check',
        value: item.id == zonesId,
        dataFiltered: ''
      }));

    },
    deletePopUp(id) {
      const prepaid = this.prepaids.find(prepaid => prepaid.id == id);
      this.$store.dispatch('commons/openPopUp', {
        title: this.$t('delete_popup_title'),
        content: [{ value: prepaid.description }],
        actionPrimary: { text: this.$t('actions.delete'), callback: () => this.deletePrepaid(id) },
        actionSecondary: {
          text: this.$t('actions.cancel'), callback() {
          }
        },
        icon: { color: 'error', name: 'mdi-delete' },
        color: 'primary',
      });
    },
    async fetchData() {
      if (this.loading) return false;
      this.clearSelected = new Date().getMilliseconds().toString();
      this.loading = true;
      try {
        const reqBody = {
        studentTypes: this.selectedStudentType,
        status: this.selectedStatus,
        zoneIds: this.selectedZones,
        careerIds: this.selectedCareers,
        search: this.search || ''
        }
        const params = {
          page: this.pagination.page - 1,
          length: this.pagination.limit,
          orderBy: this.sort.sortBy,
          orientation: this.sort.sortDesc ? 'desc' : 'asc',
          search: this.search
        };
        const prepaids = await $prepaids.findFiltered('list', null, reqBody, { params });
        this.prepaids = prepaids.data.content.map(item => {
          item.percentage = item.detail ? item.detail.filter(e => e.percentage) : [];
          return item;
        });
        this.totalItems = prepaids.data.totalElements;
        this.pagination.pagesAmount = prepaids.data.totalPages;
      } catch (err) {
        this.prepaids = [];
        this.pagination.page = 1
        this.pagination.pagesAmount = 1
        this.totalItems = 0;
        throw err;
      } finally {
        this.loading = false;
      }
    },
    bulkDeletePopUp(prepaids) {
      this.$store.dispatch('commons/openPopUp', {
        title: this.$t('delete_popup_title'),
        content: prepaids.map(prepaid => ({ value: prepaid.description })),
        actionPrimary: { text: this.$t('actions.accept'), callback: () => this.bulkDelete(prepaids) },
        actionSecondary: {
          text: this.$t('actions.cancel'), callback() {
          }
        },
        icon: { color: 'error', name: 'mdi-delete' },
        color: 'primary',
      });
    },
    async bulkDelete(prepaids) {
      const itemsForDelete = {
        ids: prepaids.map((prepaid) => {
          return prepaid.id;
        })
      };
      try {
        await $prepaids.bulkDelete(itemsForDelete);
        this.fetchData();
      } catch (err) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('actions.error'),
          content: [{ value: this.$t('modules.prepaids.table.messages.bulkDeleteError') }],
          actionPrimary: {
            text: this.$t('actions.accept'), callback() {
            }
          },
          icon: { color: 'error', name: 'mdi-close' },
          color: 'primary',
        });
        throw err;
      }
    },
    newPrepaid() {
      if (this.canCreate) this.$router.push('/prepaids/create');
    },
    updatePrepaid(id) {
      if (this.canUpdate) this.$router.push(`/prepaids/update/${id}`);
    },
    async deletePrepaid(id) {
      try {
        await $prepaids.delete(id);
        this.prepaids = this.prepaids.filter(prepaid => prepaid.id !== id);
      } catch (error) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('actions.error'),
          content: [{ value: (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.commissions.ranges.table.messages.deleteError') }],
          actionPrimary: {
            text: this.$t('actions.accept'), callback() {
            }
          },
          icon: { color: 'error', name: 'mdi-close' },
          color: 'primary',
        });
        throw error;
      }
    },
    searchAction(data) {
      this.pagination.page = 1;
      this.search = data;
      this.fetchData();
    },
    onInputPagination(event) {
      this.pagination.page = event;
      this.fetchData();
    },
    onLengthPaginationChange(event) {
      this.pagination = {
        page: 1,
        limit: event,
        pagesAmount: 1
      };
      this.fetchData();
    },
  },
  watch: {
    sort: {
      handler() {
        this.fetchData();
      },
      deep: true
    },
    selectedCareers() {
      this.fetchData();
    },
    selectedZones() {
      this.fetchData();
    },
    selectedStatus() {
      this.fetchData();
    },
    selectedStudentType() {
      this.fetchData();
    }
  }
};
</script>
