import Constants from '@/plugins/constants'
import Configuration from '../../core/constants/configuration';

export default {

  // common mutations

  SET_LOADING_DRAWER (state, data) {
    state.loading.drawer = data
  },
  INCREASE_LOADING (state) {
    state.loading.counter += 1
  },
  DECREASE_LOADING (state) {
    if (state.loading.counter > 0) state.loading.counter -= 1
  },
  REFRESH_NOTIFICATIONS(state) {
    state.notificationsKey++
  },

  OPEN_POPUP (state, object) {
    state.alertPopUp = object
  },
  OPEN_PASSWORD_POPUP (state) {
    state.changePasswordPopUp = true
  },
  CLOSE_PASSWORD_POPUP (state) {
    state.changePasswordPopUp = false
  },
  UPDATE_CHECK_TERMS(state, data) {
    state.checkTerms = data;
  },
  SET_HAS_EXPIRED (state, data) {
    state.hasExpired = data
  },
  SET_INPROGRESS_SUBJECTS (state, subjects) {
    state.subjects.inProgress = subjects
  },
  SET_REGULARISED_SUBJECTS (state, subjects) {
    state.subjects.regularised = subjects
  },
  SET_APPROVED_SUBJECTS (state, subjects) {
    state.subjects.approved = subjects
  },
  CLOSE_POPUP (state) {
    state.alertPopUp = null
  },
  SET_USERDATA (state, userData) {
    state.userData = userData
  },
  SET_LOGGED_USERDATA (state, userData) {
    state.loggedUserData = userData
  },
  CLEAR_USERDATA (state) {
    state.userData = {}
  },
  UPDATE_AVATAR (state) {
    state.loggedUserData.userData.avatar = `${Constants.URL_DATA_SERVER}/users/${state.loggedUserData.id}/avatar?${new Date().getTime()}`
  },
  SET_MP_CONFIG (state, data) {
    state.mpConfig = data
  },
  UPDATE_CURRENT_USER_ROLES (state, data) {
    state.loggedUserData.roles[data.roleToDelete] = data;
  },
  /* eslint-disable */
  SET_USER_PERMISSIONS(state, userData) {
    const data = {};
    const app = Configuration.value('app') || 'portaladministrativo'
    let isAlumno = false

    if (userData.roles && Array.isArray(userData.roles)) {
      userData.roles.forEach(role => {

        if (role.rolType.meaning == 'Alumno') isAlumno = true

        if(!Array.isArray(role.applications)) return;
        role.applications.forEach(application => {
          if ((application.name == 'Administrativo' && app == 'portaladministrativo') ||
              (application.name == 'Comisiones' && app == 'comisiones') ||
              (application.name == 'Alumno' && app == 'portalalumno')) {

            if(!Array.isArray(application.modules)) return;
            application.modules.forEach(module => {
              const moduleKey = module.code && module.code != '' ? module.code : module.id
              if (data[moduleKey] === undefined) data[moduleKey] = {NAME: module.name, STATUS: module.status}
              else if (!data[moduleKey].STATUS) data[moduleKey].STATUS = module.status;

              if(!Array.isArray(module.permissions)) return;
              module.permissions.forEach(permission => {
                if (data[moduleKey][permission.name] !== true) data[moduleKey][permission.name] = permission.status
              });

              if(module.children && module.children.length){
                module.children.forEach(subModule => {
                  const submoduleKey = subModule.code && subModule.code != '' ? subModule.code : subModule.id
                  if (data[submoduleKey] === undefined) data[submoduleKey] = {NAME: subModule.name, STATUS: module.status && subModule.status}
                  else if (!data[submoduleKey].STATUS) data[submoduleKey].STATUS = module.status && subModule.status;

                  if(!Array.isArray(subModule.permissions)) return;
                  subModule.permissions.forEach(permission => {
                    if (data[submoduleKey][permission.name] !== true) data[submoduleKey][permission.name] = permission.status
                  });
                });
              }
            });
          }
        });
      });

      state.isGestor = !isAlumno
    }

    state.permissions = data
  },
  SET_SIGNED_USERDATA(state, signed) {
      state.userData.signed = signed
  },
  SET_CONTRACT_PENDING_VALIDATION(state, payload){
    state.contractPendingValidation = payload
  }
}
