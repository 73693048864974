<template>
  <div>
    <v-container fluid class="pa-0 pb-8 overflow-y-auto">
        <v-container class="container-custom" v-if="!createProcessingMessage">
            <template>
                <Breadcrumbs
                    :title="$t('modules.academic.processings.processingsCreate.breadcrumbs.title')"
                    :description="$t('modules.academic.processings.processingsCreate.breadcrumbs.description')"
                ></Breadcrumbs>
            </template>
            <template>
                <v-row justify="center">
                    <v-col class="viewSpaces" sm="12">
                        <OutlineCard
                        switcher
                        :switchValue="procesingData.status"
                        :switchLabel="$t('modules.academic.processings.processingsCreate.form.status')"
                        @switchChange="procesingData.status = !procesingData.status"
                        title="Trámites"
                        class="mb-6"
                        >
                        <v-row>
                            <v-col cols="12" lg="12" class="pb-0">
                                <v-text-field
                                    :label="$t('modules.academic.processings.processingsCreate.form.name')"
                                    required
                                    outlined
                                    class="mb-8"
                                    @blur="$v.procesingData.name.$touch()"
                                    v-model="$v.procesingData.name.$model"
                                    :error-messages="($v.procesingData.name.$dirty && $v.procesingData.name.$invalid) 
                                      ? ((!$v.procesingData.name.required) 
                                      ? $t('modules.academic.processings.processingsEdit.validations.name.required') 
                                      : (!$v.procesingData.name.maxLength) 
                                      ? $t('modules.academic.processings.processingsEdit.validations.name.maxLength') 
                                      : '') : ''"
                                    ref="name"
                                ></v-text-field>
                                <v-text-field
                                    :label="$t('modules.academic.processings.processingsCreate.form.description')"
                                    required
                                    outlined
                                    class="mb-8"
                                    v-model="$v.procesingData.description.$model"
                                    @blur="$v.procesingData.description.$touch()"
                                    :error-messages="($v.procesingData.description.$dirty && $v.procesingData.description.$invalid)
                                      ? ((!$v.procesingData.description.required)
                                      ? $t('modules.academic.processings.processingsCreate.validations.description.required')
                                      : (!$v.procesingData.description.maxLength)
                                      ? $t('modules.academic.processings.processingsCreate.validations.description.maxLength')
                                      : '') : ''"
                                    ref="description"
                                ></v-text-field>
                                <v-text-field
                                    :label="$t('modules.academic.processings.processingsCreate.form.processingKey')"
                                    required
                                    outlined
                                    class="mb-8"
                                    v-model="$v.procesingData.key.$model"
                                    @blur="$v.procesingData.key.$touch()"
                                    :error-messages="($v.procesingData.key.$dirty && $v.procesingData.key.$invalid)
                                      ? ((!$v.procesingData.key.required)
                                      ? $t('modules.academic.processings.processingsCreate.validations.processingKey.required')
                                      : (!$v.procesingData.key.maxLength)
                                      ? $t('modules.academic.processings.processingsCreate.validations.processingKey.maxLength')
                                      : '') : ''"
                                    ref="processingKey"
                                ></v-text-field>
                                <v-select
                                    class="mb-8"
                                    label="Categorias"
                                    outlined
                                    :items="categoriesData"
                                    :item-text="item => item.name"
                                    :item-value="item => item.id"
                                    v-model="selectedCategory"
                                ></v-select>
                                <OutlineCard class="mb-6" :class="{ 'required': $v.procesingData.processingHTML.$error }">
                                    <h2 class="mb-6 body-1">
                                        {{ $t('modules.academic.processings.processingsCreate.form.titleHtmlForm') }}
                                    </h2>
                                    <v-textarea class="mb-6"
                                        v-model="$v.procesingData.processingHTML.$model"
                                        @blur="$v.procesingData.processingHTML.$touch()"                                        
                                        ref="processingHTML">
                                    </v-textarea>
                                    <div v-if ="$v.procesingData.processingHTML.$dirty && !this.procesingData.processingHTML" class="error-message">{{$t('modules.academic.processings.processingsEdit.validations.processingHTML.required') }}</div>
                                </OutlineCard>
                                <v-text-field
                                    :label="$t('modules.academic.processings.processingsCreate.form.path')"
                                    outlined
                                    class="mb-8"
                                    @blur="$v.procesingData.path.$touch()"
                                    v-model="$v.procesingData.path.$model"                                    
                                    :error-messages="($v.procesingData.path.$dirty && $v.procesingData.path.$invalid)
                                      ? ((!$v.procesingData.path.maxLength)
                                      ? $t('modules.academic.processings.processingsEdit.validations.path.maxLength')
                                      : '') : ''"
                                    ref="path"
                                ></v-text-field>                                     
                                <v-text-field
                                    :label="$t('modules.academic.processings.processingsCreate.form.permission')"
                                    outlined
                                    class="mb-8"
                                    @blur="$v.procesingData.permission.$touch()"
                                    v-model="$v.procesingData.permission.$model"                                    
                                    :error-messages="($v.procesingData.permission.$dirty && $v.procesingData.permission.$invalid)
                                      ? ((!$v.procesingData.permission.maxLength)
                                      ? $t('modules.academic.processings.processingsEdit.validations.permission.maxLength')
                                      : '') : ''"
                                    ref="permission"
                                ></v-text-field>
                                <v-row>
                                    <v-col>
                                        <v-checkbox
                                            label="Horizontal"
                                            outlined
                                            v-model="procesingData.horizontalSheet"
                                            ref="horizontalSheet"
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col>
                                        <v-checkbox
                                            label="Contiene Anexo"
                                            outlined
                                            v-model="procesingData.containsAnexo"
                                            ref="containsAnexo"
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col>
                                        <v-checkbox
                                            label="Requiere Pago"
                                            outlined
                                            v-model="procesingData.paymentRequired"
                                            ref="paymentRequired"
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>

                        </OutlineCard>
                        <div class="d-flex justify-end mb-4">
                            <Button
                            depressed
                            white
                            :text="$t('actions.cancel')"
                            class="mr-4"
                            @clicked="$router.go(-1)"
                            @end="resetButtonValues"
                            ></Button>
                            <Button
                            :loading="createButton.loading"
                            :success="createButton.success"
                            :error="createButton.error"
                            :text="$t('actions.create')"
                            :successText="$t('actions.created')"
                            :errorText="'Error'"
                            :disabled="!canCreate"
                            color="primary"
                            @end="resetButtonValues"
                            @clicked="validateProcessing"
                            ></Button>
                        </div>
                    </v-col>
                </v-row>
            </template>
        </v-container>
        <successMessage
            v-else
            :type="successMessage.type"
            :title="successMessage.title"
            :actionPrimary="successMessage.actionPrimary"
            :actionSecondary="successMessage.actionSecondary"
      ></successMessage>
    </v-container>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import OutlineCard from '@/components/OutlinedCard/OutlinedCard';
import Button from '@/components/Button/Button';
import { required, maxLength } from 'vuelidate/lib/validators';
import { $processings } from '../Services/index';


export default {
    name: 'ProcessingsCreate',
    components: {
        Breadcrumbs,
        SuccessMessage,
        OutlineCard,
        Button,
    },
    props: {

    },
    computed: {
    canCreate() {
      return this.$permissions.portaladministrativo.academic.processings.CREATE;
    },
    },
     data () {
        return {
            successMessage: {
                type: null,
                title: null,
                actionPrimary: null,
                actionSecondary: null
              },
            createButton: {
                loading: false,
                success: false,
                error: false
            },
            createProcessingMessage: false,
            procesingData: {
                name: '',
                description: '',
                htmlForm: '',                
                status: true,
                paymentRequired: false,
                category: {
                    id: null
                },
                containsAnexo: false,
                horizontalSheet: false,
                processingHTML: '',
                path: '',
                permission: '',
                key: null,
            },
            categoriesData: [],
            selectedCategory: null,
        }
    },
    mounted () {
        this.fetchData();
    },
    methods: {
    async fetchData(){
        
        try {
            const { data } = await $processings.find('categories', null, null);
            this.categoriesData = data;
        } catch (error) {
            this.categoriesData = [];
            throw error;
        } 
    },
    resetButtonValues() {
      this.createButton.loading = false;
      this.createButton.success = false;
      this.createButton.error = false;
    },
    validateProcessing() {
        this.$v.$touch();
        if (!this.$v.$invalid) {
            this.createProcessing();
        }
    },
    async createProcessing(){
        this.createButton.loading = true;
        try {
           if(this.canCreate){
            const dataToSend = {
                name: this.procesingData.name,
                description: this.procesingData.description,
                paymentRequired: this.procesingData.paymentRequired,
                enabled: this.procesingData.status,
                processingHtml: this.procesingData.processingHTML,
                category: {
                    id: this.selectedCategory
                },
                containsAnexo: this.procesingData.containsAnexo,
                horizontalSheet: this.procesingData.horizontalSheet,
                key: this.procesingData.key,
                path: this.procesingData.path,
                permission: this.procesingData.permission
            }
            await $processings.create(dataToSend);
            this.successMessage.type = 'success';
            this.successMessage.title = this.$t('modules.academic.processings.processingsCreate.success.tittle');
            this.successMessage.actionPrimary = { 
                text: this.$t('modules.academic.processings.processingsCreate.actions.primary_text'), 
                callback: () => {
                    this.$router.push('/academic/processings') 
                } };
            this.createProcessingMessage = true;
           } 
        } catch (error) {
            this.successMessage.type = 'error';
            this.successMessage.title = this.$t('modules.academic.processings.processingsCreate.error.tittle');
            this.successMessage.actionPrimary = { 
                text: this.$t('modules.academic.processings.processingsCreate.actions.primary_text'), 
                callback: () => {
                    this.$router.push('/academic/processings') 
                } };
            this.successMessage.actionSecondary = {
                text: this.$t('modules.academic.processings.processingsCreate.actions.continue_editing'), 
                callback: () => {
                    this.createProcessingMessage = false;
                    this.createButton.loading = false;
                } };
            this.createProcessingMessage = true;
        }
    },
    },
    validations: {
    procesingData: {
      name: {
        required,
        maxLength: maxLength(50),
      },
      description: {
        required,
        maxLength: maxLength(100),
      },
      processingHTML: {
        required
      },
      key: {
        required,
        maxLength: maxLength(30)
      },
      path: {     
        maxLength: maxLength(50),
      },
      permission: {             
        maxLength: maxLength(20),
      }
    },
    }
}
</script>


<style lang="css" scoped>
.error-message {
  color: red; 
  font-size: small;
  padding-left: 10px;
}

</style>