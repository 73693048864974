import Dashboard from '../Dashboard'
import DashboardView from '../Views/DashboardView'


const DashboardRoute = {
    path: '/dashboard',
    meta: {
        name: {
            en: 'Dashboard',
            es: 'Dashboard'
        }
    },
    component: Dashboard,
    children: [
        {
            path: '',
            component: DashboardView,
            meta: {name: 'Dashboard report', path: 'dashboard', permission: 'READ'}
        }
    ]
};

export default DashboardRoute
