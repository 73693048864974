const processings ={
    es_CL: {
    },
    es: {
        processingsView: {
            breadcrumbs: {
                title: 'Trámites',
                description: 'Configuración de trámites',
            },
            headers: {
                name: 'Nombre',
                description: 'Descripción',
            }
        },
        processingsCreate: {
            breadcrumbs: {
                title: 'Trámites',
                description: 'Nuevo trámite',
            },
            success: {
                tittle: 'Trámite creado exitosamente',
            },
            actions: {
                primary_text: 'Cerrar',
                secondary_text: 'Crear otro',
            },
            form:{
                name: 'Nombre',
                description: 'Descripción',
                titleHtmlForm: 'Cuerpo del trámite',
                status: 'Estado',
                processingKey: 'Identificador',
                path: 'Dirección/path',
                permission: 'Permisos'
            },
            validations: {
                name: {
                    required: 'El nombre es requerido',
                    maxLength: 'El nombre no puede tener más de 30 caracteres',
                },
                description: {
                    required: 'La descripción es requerida',
                    maxLength: 'La descripción no puede tener más de 100 caracteres',
                },
                processingKey: {
                    required: 'El identificador es requerido',
                    maxLength: 'El identificador no puede tener más de 30 caracteres',
                },
                processingHTML: {
                    required: 'El proceso es requerido',
                    maxLength: 'El proceso no puede tener más de 100 caracteres',
                },
                path: {        
                    required: 'El path es requerido',            
                    maxLength: 'El path no puede tener más de 50 caracteres',
                },
                permission: {    
                    required: 'El permiso es requerido',                
                    maxLength: 'El permiso no puede tener más de 20 caracteres',
                }
            },
            error: {
                tittle: 'No se pudo crear'
            }
        },
        processingsEdit: {
            breadcrumbs: {
                title: 'Trámites',
                description: 'Editar trámite',
            },
            success: {
                tittle: 'Trámite modificado exitosamente',
            },
            error: {
                tittle: 'Error al modificar el trámite seleccionado',
            },
            actions: {
                primary_text: 'Cerrar',
                continue_editing: 'Continuar editando',
            },
            form:{
                name: 'Nombre',
                description: 'Descripción',
                titleHtmlForm: 'Cuerpo del trámite',
                path: 'Dirección/path',
                permission: 'Permisos',
                validationCode: 'Código de validación',
            },
            validations: {
                name: {
                    required: 'El nombre es requerido',
                    maxLength: 'El nombre no puede tener más de 30 caracteres',
                },
                description: {
                    required: 'La descripción es requerida',
                    maxLength: 'La descripción no puede tener más de 100 caracteres',
                },
                processingKey: {
                    required: 'El identificador es requerido',
                    maxLength: 'El identificador no puede tener más de 30 caracteres',
                },
                processingHTML: {
                    required: 'El cuerpo del trámite es requerido',                    
                },
                path: {                 
                    required: 'El path es requerido',   
                    maxLength: 'La Dirección/path no puede tener más de 50 caracteres',
                },
                permission: {   
                    required: 'El permiso es requerido',                 
                    maxLength: 'El permiso no puede tener más de 20 caracteres',
                }
            }
        },
        processingsDelete: {
            success: {
                tittle: 'Trámite eliminado exitosamente',
            },
            error: {
                tittle: 'Error al eliminar el trámite seleccionado',
            },
            actions: {
                primary_text: 'Cerrar',
            },
        }
    },
    en: {
    }
}

export default processings