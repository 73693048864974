<template>
  <OutlinedCard v-if="!show" disabled>
    <h2>{{ $t('modules.admission.preregistration.create.form.finance') }}</h2>
  </OutlinedCard>
  <OutlinedCard v-else>
    <h2>{{ $t('modules.admission.preregistration.create.form.finance') }}</h2>
    <v-sheet v-if="extraBenefit" color="#5ca1ff" class="info-container white--text py-2 mt-1">
      <v-row>
        <v-col class="pa-0 text-center align-self-center" cols=2>
          <img class="img-coin" :src="require(`@/assets/icons/coins.png`)">
        </v-col>
        <v-col class="pl-0 text-left align-self-center" cols=10>
          {{ $t('modules.admission.preregistration.create.form.bonificationWarning1') }}<b>{{
            $t('modules.admission.preregistration.create.form.bonificationWarning2', {
              discount:
            extraBenefit})}}</b>{{ $t('modules.admission.preregistration.create.form.bonificationWarning3') }}
        </v-col>
      </v-row>
    </v-sheet>

    <div class="section-grey pa-4 my-4">
      <v-row v-if="extraBenefit">
        <v-col sm="6" class="pt-0 d-flex justify-center align-center">
          <div>{{ extraBenefit }}% extra por pago total de cuotas</div>
        </v-col>
        <v-col sm="2" class="pt-0 d-flex justify-center align-center">
          <v-switch color="primary" class="ma-0 pa-0 switch" v-model="extraOff" @change="enabledExtraOff"></v-switch>
          <!--v-model="extraOff" @change="enabledExtraOff"-->
        </v-col>
      </v-row>
      <div>
      </div>
      <div class="align-center" v-if="$isIPP">
        <v-row v-for="due in selectedSubscriptionDues?.value" :key="due">
          <v-col cols="12">
            <v-card outlined class="py-2 px-2">
              <v-row justify="space-between" class="payment-container " no-gutters>
                <v-col class="d-flex justify-star align-center text-center mr-5 ml-5">
                  <h4 class="font-weight-bold black--text ">{{ $t('modules.admission.preregistration.create.form.due') }}
                    {{ due }}</h4>
                </v-col>
                <v-col class="text-right border-row" 
                       v-for="(prepaid, index) in prepaids" 
                       :key="prepaid.id + index" 
                       style="min-width: 200px;">
                  <div class="mx-2 d-flex flex-column align-end justify-center fill-height rounded">
                    <small class="font-weight-bold grey--text">
                      {{ prepaid.dayTo ? `Hasta el ${prepaid.dayTo}/${month}` : ' ' }}
                    </small>
                    <h4 :class="[
                          (prepaid.off > 0 || prepaid.percentage > 0) ? 'primary--text' : 'black--text',
                            {
                              'mt-6': !(prepaid.off > 0 || prepaid.percentage > 0)
                            }
                          ]">
                      {{ $formatPrice(selectedSubscriptionDues.amount - (selectedSubscriptionDues.amount *
                        (prepaid.percentage) / 100))}}
                    </h4>

                    <small v-if="prepaid.percentage > 0" class="font-weight-bold success--text text-center">
                      {{ prepaid.percentage }}% Off
                    </small>
                    <small v-else-if="prepaid.off > 0 && discounts.length"
                      class="font-weight-bold success--text text-center">
                      {{ prepaid.off }}% Off
                    </small>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div v-else-if="$isMexico">
        <v-row v-for="payment in filteredPayments" :key="payment.id">
          <v-col cols="12">
            <v-card outlined class="py-4 px-4">
              <v-row justify="space-between" class="payment-container " no-gutters>
                <v-col class="d-flex justify-start align-center text-center">
                  <h4 class="font-weight-bold black--text">{{ payment.title }}</h4>
                </v-col>
                <v-col class="text-right border-row" v-for="(prepaid, index) in payment.options"
                  :key="prepaid.id + index">
                  <div v-if="prepaid.off >= 100 && index == payment.options.length - 1"
                    class="mx-2 d-flex flex-column align-end justify-center fill-height rounded">
                    <h1 :class="(prepaid.off > 0) ? 'primary--text' : 'black--text'">{{ $formatPrice(0) }}</h1>
                  </div>
                  <div v-else-if="prepaid.off < 100"
                    class="mx-2 d-flex flex-column align-end justify-center fill-height rounded"
                    :class="{ 'border-row': payment.options.length > 1 }">
                    <div v-if="payment.accumulativeDiscount">
                      <small class="font-weight-bold grey--text">{{ prepaid.expiration }}</small>
                      <h4 :class="(prepaid.off > 0 || prepaid.percentage > 0) ? 'primary--text' : 'black--text'">
                        {{ $formatPrice(payment.amount - (payment.amount * (prepaid.off + prepaid.percentage) / 100)) }}
                      </h4>
                      <small v-if="(prepaid.off > 0 || prepaid.percentage > 0)"
                        class="font-weight-bold success--text">{{ prepaid.off + prepaid.percentage }}% Off</small>
                      <small v-else-if="(prepaid.off != 0 && discounts.length)"
                        class="font-weight-bold success--text">{{ prepaid.off + prepaid.percentage }}% Off</small>
                    </div>
                    <div v-else>
                      <small class="font-weight-bold grey--text">{{ prepaid.expiration }}</small>
                      <h4 :class="(prepaid.totalOff > 0 || prepaid.percentage > 0) ? 'primary--text' : 'black--text'">
                        {{ $formatPrice(payment.amount - (payment.amount * (prepaid.totalOff) / 100)) }}</h4>
                      <small v-if="(prepaid.totalOff > 0 || prepaid.percentage > 0)"
                        class="font-weight-bold success--text">{{ prepaid.totalOff }}% Off</small>
                      <small v-else-if="(prepaid.totalOff != 0 && discounts.length)"
                        class="font-weight-bold success--text">{{ prepaid.totalOff }}% Off</small>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

      </div>
    </div>
  </OutlinedCard>
</template>

<script>
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import moment from 'moment';
export default {
  name: 'SimulatePrice',
  props: {
    payments: Array,
    prepaids: Array,
    extraBenefit: Number,
    show: { type: Boolean, default: false },
    dues: { type: Object, default: null },
    selectedSubscriptionDues: { type: Object, default: null },
    total: Object,
    discounts: Array,
    benefits: { type: Array, default: () => [] },
    scholarships: { type: Array, default: () => [] },
  },
  components: {
    OutlinedCard
  },
  data: () => ({
    extraOff: false,

  }),
  computed: {
    filteredPayments() {
      return this.payments.filter(payment => payment.billingProductId === 80 || payment.billingProductId === 81);
    },
    month() {
      return moment().month() + 1
    }
  },
  mounted() {
  },
  methods: {
    enabledExtraOff() {
      this.$emit('enabledExtraOff', this.extraOff)
    },

  },
  watch: {
  },

};
</script>

<style lang="sass">
.img-coin
    width: 70%

.border-row
    border-left: 1px solid rgba(0, 0, 0, 0.12)
    height: 100%
</style>
