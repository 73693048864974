<template>
    <v-container class="superTable pa-0" fluid>
        <v-row no-gutters>
            <v-col sm="4">
                <v-text-field
                    :placeholder="$t('actions.search')"
                    class="superTable__search"
                    clearable
                    outlined
                    prepend-inner-icon="mdi-magnify"
                    v-model="search"
                    @keyup.esc="search = ''"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col sm="12">
                <v-data-table
                    :search="search"
                    :headers="headers"
                    :items="items"
                    :totalItems="items.length"
                    :items-per-page="itemsPerPage"
                    :page.sync="page"
                    :show-select="false"
                    class="superTable__table"
                    hide-default-footer
                    item-key="id"
                    style="table-layout: fixed"
                    :sort-by="sortBy"
                    :sort-desc="sortDesc"
                >
                    <template v-slot:[`item.accountTerm`]="{ item }">
                        {{ item.term?.code ? item.term?.code : '-'}}
                    </template>
                    <template v-slot:[`item.paymentDate`]="{ item }">
                        {{ dateFormat(item.paymentDate) }}
                    </template>
                    <template v-slot:[`item.paymentAmount`]="{ item }">
                        {{ item.paymentAmount | $currency }}
                    </template>
                    <template v-slot:[`item.paymentStatus`]="{ item }">
                        <v-chip color="green" dark>{{ item.paymentStatus.meaning }}</v-chip>
                    </template>
                    <template v-slot:[`item.urlPdf`]="{ item }">
                        <a v-if="item.urlPdf" :href="item.urlPdf" target="_blank"><v-icon>mdi-download</v-icon></a>
                    </template>
                </v-data-table>
                <v-divider class="mt-4 mb-8"></v-divider>
            </v-col>
        </v-row>
        <v-row align="center" no-gutters>
            <v-col sm="3">
                <span class="caption d-inline-block">{{ `${itemsCount.startItem} - ${itemsCount.endItem} de ${itemsCount.totalItems}` }}</span>
                <v-select
                    :items="itemsPerPageAvailable"
                    :placeholder="itemsPerPage.toString()"
                    class="superTable__paginationSelect d-inline-block ml-4"
                    outlined
                    v-model="itemsPerPage"
                ></v-select>
            </v-col>
            <v-col class="text-center" sm="6">
                <v-pagination v-model="page" :length="pageCount" class="superTable__pagination"></v-pagination>
            </v-col>
            <v-col align="right" sm="3">
                <v-text-field
                    class="superTable__paginationInput d-inline-block mr-4"
                    :max="pageCount"
                    min="1"
                    outlined
                    v-model.number="page"
                ></v-text-field>
                <span class="caption d-inline-block">{{ $t('actions.goToPage') }}</span>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

export default {
    name: 'SuperTable',
    props: {
        items: Array,
        headers: Array,
        sortBy: String,
        sortDesc: Boolean,
    },
    data() {
        return {
            search: '',
            page: 1,
            itemsPerPage: 20,
            itemsPerPageAvailable: [20, 50, 100, 200],
        }
    },
    methods: {
        dateFormat(dateString) {
            if (!dateString) return '-'
            const date = new Date(dateString)
            return `${date.getUTCDate()}/${date.getUTCMonth() + 1}/${date.getUTCFullYear().toString()}`
        },
    },
    watch: {
        page (val) {
            if (val > this.pageCount) this.page = this.pageCount;
            if (val < 1) this.page = 1;
        },
    },
    computed: {
        pageCount() {
            let counter = this.items.length / this.itemsPerPage
            if (counter < 1) counter = 1
            return Math.ceil(counter)
        },
        itemsCount() {
            const counter = {
                totalItems: this.items.length,
                startItem: ((this.page * this.itemsPerPage) - this.itemsPerPage) + 1,
                endItem: this.page * this.itemsPerPage
            };

            if (counter.endItem > this.items.length) counter.endItem = this.items.length
            return counter
        }
    },
};
</script>
