import Home from '../Home'
import HomeView from '../Views/HomeView'
import AccessDenied from '@/components/AccessDenied/AccessDenied'
import ModuleInactive from '@/components/ModuleInactive/ModuleInactive'

const HomeRoute =
{
    path: '/',
    meta: {
        name: {
            en: 'Index',
            es: 'Inicio'
        }
    },
    component: Home,
    children: [
        {
            path: '/',
            component: HomeView,
            meta: { name: 'Home' }
        },{
            path: '/access-denied',
            component: AccessDenied,
            meta: { name: 'Access denied' }
        },{
            path: '/module-inactive',
            component: ModuleInactive,
            meta: { name: 'Module inactive' }
        },
    ]
};

export default HomeRoute
