
<template>
    <div>
        <DueBanner v-if="dueBanner"></DueBanner>
        <template v-else-if="onboardingStatus.showBanners && !classStarted">
            <OnboardingCentralPrimaryBanner v-if="!!currentOnboardingCentralPrimaryBanner" :banner="currentOnboardingCentralPrimaryBanner" :class-start="onboardingStatus.classStart"></OnboardingCentralPrimaryBanner>
            <OnboardingCentralSecondaryBanner v-if="!!currentOnboardingCentralSecondaryBanner && !onboardingStatus.subjectM0 && $country != 'mexico'" :banner="currentOnboardingCentralSecondaryBanner"></OnboardingCentralSecondaryBanner>
            <OnboardingCentralTerceryBanner v-if="!!currentOnboardingCentralTerceryBanner" :banner="currentOnboardingCentralTerceryBanner"></OnboardingCentralTerceryBanner>
        </template>
        <WelcomeBanner v-else></WelcomeBanner>
    </div>
</template>

<script>
    import WelcomeBanner from './WelcomeBanner';
    import DueBanner from './DueBanner';
    import OnboardingCentralPrimaryBanner from './OnboardingCentralPrimaryBanner';
    import OnboardingCentralSecondaryBanner from './OnboardingCentralSecondaryBanner';
    import OnboardingCentralTerceryBanner from './OnboardingCentralTerceryBanner';
    import moment from 'moment';
    import { mapGetters } from 'vuex';

    export default {
        name: 'PrincipalBanner',
        props: {
            classStarted: Boolean,
            onboardingStatus: Object
        },
        components: {
            WelcomeBanner,
            DueBanner,
            OnboardingCentralPrimaryBanner,
            OnboardingCentralSecondaryBanner,
            OnboardingCentralTerceryBanner
        },
        data() {
            return {
                systemConfig: null,
                onboardingCentralPrimaryBanners: [],
                onboardingCentralSecondaryBanners: [],
                onboardingCentralTerceryBanners: []
            }
        },
        created() {
            this.setOnboardingBanners()
        },
        computed: {
            onboardingHabilidadesUrl() {
              switch(this.$country) {
                case 'chile':
                  return 'https://instituto.ipp.cl/test-de-habilidades-final';
                case 'argentina':
                  return 'https://onboarding.teclab.edu.ar/habilidades-teclab'
                case 'peru':
                  return 'https://onboarding.teclab.edu.pe/habilidades-teclab'
                case 'mexico':
                  return 'https://onboarding.onmex.mx/es-mx/habilidades-onmex'

                default:
                  return 'https://onboarding.teclab.edu.ar/habilidades-teclab'
              }
            },
            onboardingCharlaBienvenidaUrl() {
              switch(this.$country) {
                case 'chile':
                  if (this.onboardingStatus?.aproboTest) return 'https://instituto.ipp.cl/onboarding-2'
                  return 'https://instituto.ipp.cl/onboarding-1'
                case 'argentina':
                  return 'https://onboarding.teclab.edu.ar/charla-de-bienvenida'
                case 'peru':
                  return 'https://onboarding.teclab.edu.pe/charla-de-bienvenida'
                case 'mexico':
                  return 'https://onboarding.onmex.mx/es-mx/charla-de-bienvenida'
                default:
                  return 'https://onboarding.teclab.edu.ar/charla-de-bienvenida'
              }
            },
            onboardingSubjectZero(){
                return 'https://onboarding.onmex.mx/es-mx/asistencia-0'
            },
            dueBanner() {
                if(this.$isTeclab) return this.studentData.studentDue?.firstAlertDue
                return this.studentData.studentDue ? this.studentData.studentDue?.countPaymentsDebt > 1 : false
            }, 
            currentOnboardingCentralPrimaryBanner() {
                return this.onboardingCentralPrimaryBanners.find(banner => banner.visible)
            },
            currentOnboardingCentralSecondaryBanner() {
                return this.onboardingCentralSecondaryBanners.find(banner => banner.visible)
            },
            currentOnboardingCentralTerceryBanner() {
                return this.onboardingCentralTerceryBanners.find(banner => banner.visible)
            },
            ...mapGetters({
                studentData: 'commons/getStudentData',
            }),
        },
        methods: {
            getOnboardingDate() {
                const horario = this.onboardingStatus.horario ? this.onboardingStatus.horario.substr(0, 5) : ''
                if (this.onboardingStatus.onbordingDate) return moment(`${this.onboardingStatus.onbordingDate.substr(0, 10)} ${horario}`).locale('es').format('D [de] MMMM [a las] H[hs]')
                else return moment().locale('es').format('D [de] MMMM [a las] H[hs]')
            },
            setOnboardingBanners() {
                if (!this.onboardingStatus.showBanners || this.classStarted) return false
                const [name] = this.studentData.student.user.userData.name.split(' ')
                const studentName = this.$options.filters.$capitalizeAll(name)
                this.onboardingCentralPrimaryBanners = [
                    {
                        visible: !this.onboardingStatus.test, 
                        backgroundColor: 'var(--v-secondary-base)',
                        image: require('@/assets/dashboard-assets/onmex/welcome-banner.png'),
                        text: {
                            caption: null,
                            title: this.$t('modules.portalalumno.dashboard.onboarding.centralPrimaryBanner.test.text.title'),
                            comment: this.$t('modules.portalalumno.dashboard.onboarding.centralPrimaryBanner.test.text.comment'),
                            textColor: 'var(--v-primary-lighten5)'
                        },
                        buttons: [
                            {
                                outline: true,
                                text: this.$t('modules.portalalumno.dashboard.onboarding.centralPrimaryBanner.test.buttons.first.text'),
                                size: 'lg',
                                color: 'var(--v-primary-base)',
                                textColor: 'var(--v-primary-lighten5)',
                                action: this.onboardingHabilidadesUrl,
                                icon: null,
                                analytics: {
                                    category: 'Home',
                                    label: 'Onboarding',
                                    action: 'Clk_BnrTstHacer_HomeOnb'
                                }
                            }, {
                                outline: true,
                                text: this.$t('modules.portalalumno.dashboard.onboarding.centralPrimaryBanner.test.buttons.second.text'),
                                size: 'lg',
                                color: 'var(--v-secondary-base)',
                                textColor: 'var(--v-primary-lighten4)',
                                action: this.onboardingStatus?.paraQueSirve,
                                icon: 'mdi-play-circle',
                                analytics: {
                                    category: 'Home',
                                    label: 'Onboarding',
                                    action: 'Clk_BnrTstvideo_HomeOnb'
                                }
                            }
                        ]
                    }, 
                    {
                        visible: this.onboardingStatus.test && !this.onboardingStatus.schedule ,
                        backgroundColor: 'var(--v-primary-base)',
                        image: require('@/assets/dashboard-assets/onmex/welcome-banner.png'),
                        text: {
                            caption: null,
                            title: this.$t('modules.portalalumno.dashboard.onboarding.centralPrimaryBanner.schedule.text.title', { studentName }),
                            comment: this.$t('modules.portalalumno.dashboard.onboarding.centralPrimaryBanner.schedule.text.comment'),
                            textColor: 'var(--v-primary-lighten5)'
                        },
                        buttons: [
                            {
                                outline: true,
                                text: this.$t('modules.portalalumno.dashboard.onboarding.centralPrimaryBanner.schedule.buttons.first.text'),
                                color: 'var(--v-secondary-base)',
                                textColor: 'var(--v-primary-lighten5)',
                                action: this.onboardingCharlaBienvenidaUrl,
                                icon: null,
                                analytics: {
                                    category: 'Home',
                                    label: 'Onboarding',
                                    action: 'Clk_BnrInsChar_HomeOnb'
                                }
                            }
                        ]
                    }, 
                    {
                        visible: this.onboardingStatus.test && this.onboardingStatus.schedule && (moment(this.onboardingStatus.onbordingDate).isSameOrAfter((new Date()).setHours(0,0,0,0))),
                        backgroundColor: '#42E6EB',
                        image: require('@/assets/dashboard-assets/onmex/welcome-banner.png'),
                        text: {
                            caption: null,
                            title: this.$t('modules.portalalumno.dashboard.onboarding.centralPrimaryBanner.scheduled.text.title', { studentName, onboardingDate: this.getOnboardingDate() }),
                            comment: this.$t('modules.portalalumno.dashboard.onboarding.centralPrimaryBanner.scheduled.text.comment', { ref: this.$isTeclab ? 'http://bit.ly/CoachAcadémico' : 'mailto:inducciones@ipp.cl' })
                        }
                    }, 
                    {
                        visible: this.onboardingStatus.test && this.onboardingStatus.schedule && (moment(this.onboardingStatus.onbordingDate).isBefore((new Date()).setHours(0,0,0,0))) && !this.onboardingStatus.onbording,  
                        backgroundColor: 'var(--v-secondary-base)',
                        image: require('@/assets/dashboard-assets/onmex/welcome-banner.png'),
                        text: {
                            caption: null,
                            title: this.$t('modules.portalalumno.dashboard.onboarding.centralPrimaryBanner.reschedule.text.title'),
                            comment: this.$t('modules.portalalumno.dashboard.onboarding.centralPrimaryBanner.reschedule.text.comment'),
                            textColor: 'var(--v-primary-lighten5)'
                        },
                        buttons: [
                            {
                                outline: true,
                                text: this.$t('modules.portalalumno.dashboard.onboarding.centralPrimaryBanner.reschedule.buttons.first.text'),
                                size: 'x-lg',
                                color: 'var(--v-primary-base)',
                                textColor: 'var(--v-primary-lighten5)',
                                action: this.onboardingCharlaBienvenidaUrl,
                                icon: null,
                                analytics: {
                                    category: 'Home',
                                    label: 'Onboarding',
                                    action: 'Clk_BnrNAsistChar_HomeOnb'
                                }
                            }
                        ]
                    }, 
                    {
                        visible: this.onboardingStatus.test && this.onboardingStatus.onbording,
                        backgroundColor: '#42E6EB',
                        image: require('@/assets/dashboard-assets/onmex/welcome-banner.png'),
                        text: {
                            caption: null,
                            title: this.$t('modules.portalalumno.dashboard.onboarding.centralPrimaryBanner.onboarded.text.title'),
                            comment: this.$t('modules.portalalumno.dashboard.onboarding.centralPrimaryBanner.onboarded.text.comment')
                        },
                        buttons: [
                            {
                                outline: true,
                                text: this.$t('modules.portalalumno.dashboard.onboarding.centralPrimaryBanner.onboarded.buttons.first.text'),
                                color: '#42E6EB',
                                textColor: 'var(--v-secondary-base)',
                                action: this.onboardingStatus?.comoUsarPortal,
                                icon: 'mdi-play-box',
                                analytics: {
                                    category: 'Home',
                                    label: 'Onboarding',
                                    action: 'Clk_BnrGuiaUsar_HomeOnb'
                                }
                            }, {
                                outline: true,
                                text: this.$t('modules.portalalumno.dashboard.onboarding.centralPrimaryBanner.onboarded.buttons.second.text'),
                                color: '#42E6EB',
                                textColor: 'var(--v-secondary-base)',
                                action: this.onboardingStatus?.comoEstudiar,
                                icon: 'mdi-play-box',
                                analytics: {
                                    category: 'Home',
                                    label: 'Onboarding',
                                    action: 'Clk_BnrGuiaEst_HomeOnb'
                                }
                            }, {
                                outline: true,
                                text: this.$t('modules.portalalumno.dashboard.onboarding.centralPrimaryBanner.onboarded.buttons.third.text'),
                                color: '#42E6EB',
                                textColor: 'var(--v-secondary-base)',
                                action: this.onboardingStatus?.comoAprobar,
                                icon: 'mdi-play-box',
                                analytics: {
                                    category: 'Home',
                                    label: 'Onboarding',
                                    action: 'Clk_BnrGuiaAprob_HomeOnb'
                                }
                            }
                        ]
                    },
                    {
                        visible: this.onboardingStatus.subjectM0 && this.$country === 'mexico', 
                        isSubjectZero: this.onboardingStatus.subjectM0,
                        backgroundColor: 'var(--v-secondary-base)',
                        image: require('@/assets/dashboard-assets/onmex/welcome-banner.png'),
                        text: {
                            caption: null,
                            title:  this.$t('modules.portalalumno.dashboard.onboarding.centralPrimaryBanner.firstSubject.text.title'), 
                            comment: this.$t('modules.portalalumno.dashboard.onboarding.centralPrimaryBanner.firstSubject.text.comment'),
                            textColor: 'var(--v-primary-lighten5)'
                        },
                        buttons: [
                            {
                                outline: true,
                                text:  this.$t('modules.portalalumno.dashboard.onboarding.centralPrimaryBanner.firstSubject.buttons.first.text'),
                                size: 'lg',
                                color: 'var(--v-primary-base)',
                                textColor: 'var(--v-primary-lighten5)',
                                action: this.onboardingSubjectZero,
                                icon: null,
                                analytics: {
                                    category: 'Home',
                                    label: 'Banner M-0',
                                    action: 'banner-materia-cero-central'
                                }
                            }, 
                        ]
                    }
                ]

                this.onboardingCentralSecondaryBanners = [
                    {
                        visible: this.onboardingStatus.test && this.onboardingStatus.habilidades,
                        image: require('@/assets/dashboard-assets/onboarding/before-class-start/03.svg'),
                        text: {
                            title: this.$t('modules.portalalumno.dashboard.onboarding.centralSecondaryBanner.lackSkills.text.title'),
                            comment: this.$t('modules.portalalumno.dashboard.onboarding.centralSecondaryBanner.lackSkills.text.comment')
                        },
                        button: {
                            text: this.$t('modules.portalalumno.dashboard.onboarding.centralSecondaryBanner.lackSkills.button.text'),
                            analytics: {
                                category: 'Home',
                                label: 'Onboarding',
                                action: 'Clk_BnrPoteHab_HomeOnb'
                            }
                        }
                    }, {
                        visible: this.onboardingStatus.test && !this.onboardingStatus.habilidades ,
                        image: require('@/assets/dashboard-assets/onboarding/before-class-start/07.svg'),
                        text: {
                            title: this.$t('modules.portalalumno.dashboard.onboarding.centralSecondaryBanner.enoughSkills.text.title'),
                            comment: this.$t('modules.portalalumno.dashboard.onboarding.centralSecondaryBanner.enoughSkills.text.comment'),
                        },
                        button: {
                            text: this.$t('modules.portalalumno.dashboard.onboarding.centralSecondaryBanner.enoughSkills.button.text'),
                            analytics: {
                                category: 'Home',
                                label: 'Onboarding',
                                action: 'Clk_BnrTecEst_HomeOnb'
                            }
                        }
                    }
                ],

                this.onboardingCentralTerceryBanners = [
                    {
                        visible: this.$country === 'mexico',
                        image: require('@/assets/money.png'),
                        text: {
                            title: this.$t('modules.portalalumno.dashboard.onboarding.centralTerceryBanner.remember.text.title'),
                        }
                    }
                ]
            }
        }
    }
</script>