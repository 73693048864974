<template>
  <v-card class="pa-0" height="min-content">
    <v-toolbar dark class="px-2" color="primary">
      <v-toolbar-title class="font-weight-bold">
        {{ summaryUpdate ? $t('modules.admission.preregistration.update.summary.title', { admissionTerm }) :
          $t('modules.admission.preregistration.create.summary.title') }}
      </v-toolbar-title>
    </v-toolbar>
    <v-container fluid class="pa-4">
      <div class="px-2" v-if="selectedPaymentMethod === 'credit'">
        <template v-if="total?.prices.length">
          <div class="d-flex justify-space-between py-1 text-body-2" v-for="price in unificationPrice"
            :key="`summary-${price.key}`">
            <span>{{ price.name }}</span>
            <span>{{ price?.amount | $currency }}</span>
          </div>
        </template>
        <template v-else>
          <div class="d-flex justify-space-between py-1 text-body-2">
            <span>{{ $t('modules.admission.preregistration.create.summary.enrollmentPlaceholder') }}</span>
            <span>— — —</span>
          </div>
          <div class="d-flex justify-space-between py-1 text-body-2">
            <span>{{ $t('modules.admission.preregistration.create.summary.tariffPlaceholder') }}</span>
            <span>— — —</span>
          </div>
        </template>
        <template v-if="total?.saving">
          <template v-for="saving in total?.savings">
            <template v-if="saving.referredFriend">
              <div class="primary--text d-flex justify-space-between py-1 text-body-2" :key="`summary-${saving.key}`">
                <span v-if="saving?.amount" class="saving-max-width">{{ saving.name }}</span>
                <span v-if="saving?.amount" class="text-no-wrap">{{ saving?.amount | $currency }}</span>
              </div>
            </template>
            <template v-else-if="saving?.amount">
              <div class="success--text d-flex justify-space-between py-1 text-body-2"
                v-for="(detail, index) in saving.details" :key="`${index}summary-${saving.key}`">
                <span class="saving-max-width">{{ `${detail.name} ${detail.percentage ? `${detail.percentage}% Off` :
                  ''}` }}</span>

                <span class="text-no-wrap">{{ detail?.amount | $currency }}</span>

              </div>
            </template>
          </template>
        </template>
        <div class="success--text d-flex justify-space-between py-1 text-body-2" v-if="isSwitchChecked">
          <span class="saving-max-width">{{ $t('modules.admission.preregistration.create.summary.extraBenefit') }} {{
            percentage
          }}%</span>
          <span class="text-no-wrap">{{ discountAmount | $currency }}</span>
        </div>
      </div>
      <template v-else-if="selectedPaymentMethod === 'subscription'">
        <div v-if="dues && dues.value">
          <div v-for="i in dues.value" :key="i" >
            <div>
              <div class="black--text d-flex justify-space-between py-1 text-body-2">
                <span class="saving-max-width"> {{ $t('modules.admission.preregistration.create.summary.due') }} {{ i }}
                </span>
                <span class="text-no-wrap"> {{ dues?.amount | $currency }}</span>
              </div>
              <div class="success--text d-flex justify-space-between py-1 text-body-2">
                <span class="saving-max-width">{{ $t('modules.admission.preregistration.create.summary.discount') }}
                  {{ prepaids[0]?.percentage }}%</span>
                <span class="text-no-wrap">{{ (dues?.amount * prepaids[0]?.percentage) / 100 | $currency }}</span>
              </div>
            </div>
          </div>
        </div>


      </template>
      <div v-if="selectedPaymentMethod">
        <v-alert dense dark color="#77AEFF" class="px-2 my-3">
          <div v-if="selectedPaymentMethod === 'credit'">
            <div class="d-flex justify-space-between text-h6">
              <span>{{ $t('modules.admission.preregistration.create.summary.total') }}</span>
              <span v-if="total?.cost">{{ (total?.cost - total?.saving - discountAmount) > 0
                  ? (total?.cost - total?.saving - discountAmount)
                  : 0 | $currency }} </span>
              <span v-else>— — —</span>
            </div>
          </div>

          <div v-else-if="selectedPaymentMethod === 'subscription' && dues">
            <div class="d-flex justify-space-between text-h6">
              <span>{{ $t('modules.admission.preregistration.create.summary.total') }}</span>
              <span v-if="total?.cost">{{ (dues.amount * dues.value) - ((dues?.amount * prepaids[0]?.percentage ?? 0) / 100 * dues.value) |
                $currency}}</span>
              <span v-else>— — —</span>
            </div>

          </div>
          <div v-else>
            <h3>{{ $t('modules.admission.preregistration.create.summary.empty') }} </h3>
          </div>
        </v-alert>

      </div>
      <v-alert v-if="total?.saving && selectedPaymentMethod === 'credit'" dense text color="success" class="px-2 my-3">
        <div class="d-flex justify-space-between text-body-2">
          <span>{{ $t('modules.admission.preregistration.create.summary.totalSaving') }}</span>
          <span>{{ total?.saving + discountAmount | $currency }}</span>
        </div>
      </v-alert>
      <div>
        <v-sheet v-if="((($isTeclab || $isMexico) && prepaids.length != 0) || dues) && !summaryUpdate" outlined rounded
          dark :color="$isMexico ? '#42A5F5' : '#67C1B8'" class="pa-4 text-center text-body-2 line-break">
          <span v-if="dues">{{ $t('modules.admission.preregistration.create.summary.dues', {
            cardName: dues.creditCard ?
              dues.creditCard.name : (dues.type.meaning || 'Pronto Pago'), installments: dues.due ? dues.due.installments
                :
                dues.value, installment_amount: dues.due ? $options.filters.$currency(dues.due.installment_amount) :
                  $options.filters.$currency(dues?.amount)}) }}
            <br> {{ dues.adminExpenses ? $t('modules.admission.preregistration.create.summary.duesAdminExpenses',
              { adminExpenses: $options.filters.$currency(dues.adminExpenses) }) : '' }} <br></span>
            <div v-if="selectedPaymentMethod === 'subscription'">
            <v-divider v-if="prepaids.length || ($isTeclab && total?.dues)" class="ma-2"></v-divider>
            <template v-if="prepaids.length && !total?.prices[1]?.accumulativeDiscount">
            <div v-for="(prepaid, i) in prepaids" :key="`summary-${prepaid.dayFrom}.${prepaid.dayTo}.${prepaid.id}`">
            <span v-if="prepaids.length > 1 && i < 3">
                {{ $t('modules.admission.preregistration.create.summary.prepaids', {
                  dayFrom: prepaid.dayFrom,
                  dayTo: prepaid.dayTo,
                  saving: $options.filters.$currency(dues?.amount - ((dues?.amount * prepaid.percentage) / 100)) + '\n'
                }) }}
              </span>
            </div>
          </template>
          <template v-else>
            <div v-for="(prepaid) in prepaids.slice(0, 1)" :key="`summary-${prepaid.dayFrom}.${prepaid.dayTo}.${prepaid.id}`">
              <span>{{ $t('modules.admission.preregistration.create.summary.prepaids',
                {dayFrom: prepaid.dayFrom, dayTo: prepaid.dayTo, saving:
                  $options.filters.$currency((dues?.amount)-(dues?.amount - ((dues?.amount * prepaid.percentage) / 100))) +'\n'})}}
              </span>
            </div>
          </template>

          </div>
          <span v-if="$isTeclab && total?.saving">{{
            $t('modules.admission.preregistration.create.summary.totalSavingToday',
              { totalSaving: $options.filters.$currency(-total?.saving) }) }}</span>
        </v-sheet>
      </div>

      <div v-if="total?.prices.length && !summaryUpdate" class="d-flex flex-column pa-2">
        <span v-if="dues" class="text-caption pb-1">{{ `CFT_${dues.resumen ? dues.resumen.cft : '0%'} TEA_${dues.resumen
          ?
          dues.resumen.tea : '0%'}` }} </span>
        <span class="text-body-2">{{ $t('modules.admission.preregistration.create.summary.approval', {
          theme: !$isTeclab
            ?
            'IPP' : $country === 'mexico' ? 'Onmex' : 'Teclab'
        }) }}</span>
        <span class="text-body-2">{{ $t('modules.admission.preregistration.create.summary.validity', { todayDate })
          }}</span>
      </div>
      <div>
        <Button :text="$t('modules.admission.preregistration.create.summary.buttons.sendSummary')" icon="mdi-email"
          :disabled="!total?.prices.length" block class="py-2" @clicked="$emit('showEmailSummary')"></Button>
        <Button :text="$t('modules.admission.preregistration.create.summary.buttons.sendPaymentLink')" icon="mdi-send"
          :disabled="!isCreated" :loading="paymentLinkButton.loading" :success="paymentLinkButton.success"
          :error="paymentLinkButton.error" :successText="$t('actions.created')" :errorText="$t('actions.error')" block
          class="py-2" @clicked="$emit('sendPaymentLink')"></Button>
      </div>
    </v-container>
  </v-card>
</template>

<script>
import Button from '@/components/Button/Button';
import moment from 'moment';

export default {
  name: 'Summary',
  components: {
    Button
  },
  props: {
    prepaids: Array,
    dues: Object,
    total: Object,
    summaryUpdate: { type: Boolean, default: false },
    admissionTerm: String,
    isCreated: { type: Boolean, default: false },
    paymentLinkButton: { type: Object, default: null },
    isSwitchChecked: { type: Boolean, default: false },
    percentage: { type: Number, required: true, default: 0 },
    discountAmount: { type: Number, required: true },
    selectedPaymentMethod: { type: String },

  },
  data() {
    return {
      todayDate: moment().format('DD/MM/YYYY')
    }
  },


  computed: {
    unificationPrice() {
      const modifiedPrices = [];
      this.total.prices.forEach(price => {
        if (![80, 81].includes(price.id)) return
        modifiedPrices.push(price);
      });
      return modifiedPrices;
    },
  },
}


</script>

<style lang="sass" scoped>

.line-break
  white-space: pre-line
  line-height: 1.7

.saving-max-width
  max-width: 65%

</style>
