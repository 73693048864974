<template>
    <v-alert style="background: --v-info-base" type="info" prominent class="text-center mt-10 mx-11 ">
        {{ message }}
    </v-alert>
</template>

<script>
export default {
    props: {
        message: { type: String },
    },
};
</script>