
const usersTranslations = {
  es_PE: {
      table: {
          student: 'Estudiante',
      },
      create: {
          validation_errors: {
              contactForm: {
                  city: 'Ingresa mínimo 3 caracteres para filtrar el distrito'
              },
          },
          contactForm: {
              city: 'Distrito'
          }
      },
      update: {
          sectionPersonal: {
              validation_errors: {
                  birthPlace: 'Ingresa mínimo 3 caracteres para filtrar el distrito',
              }
          },
          sectionPreviousStudies: {
              fields: {
                  city: 'Distrito',
              },
          },
          sectionContact: {
              fields: {
                  city: 'Distrito'
              },
              validation_errors: {
                  city: 'Ingresa mínimo 3 caracteres para filtrar el distrito'
              }
          },
      },
      details: {
          dataSection: {
              city: 'Distrito domicilio',
              secundaryDegree: 'Titulo secundario',
              previousStudiesInstitutionCity: 'Distrito del colegio',
          },
      } 
  },
  es_MX:{
    table: {
      student: 'Estudiante',
  },
  create: {
      validation_errors: {
          contactForm: {
              city: 'Ingresa mínimo 3 caracteres para filtrar el distrito'
          },
      },
      contactForm: {
          city: 'Distrito'
      }
  },
  update: {
      sectionPersonal: {
          validation_errors: {
              birthPlace: 'Ingresa mínimo 3 caracteres para filtrar el municipio',
          }
      },
      sectionPreviousStudies: {
          fields: {
              city: 'Municipio',
          },
      },
      sectionContact: {
          fields: {
              city: 'Municipio'
          },
          validation_errors: {
              city: 'Ingresa mínimo 3 caracteres para filtrar el municipio'
          }
      },
  },
  details: {
      dataSection: {
          city: 'Municipio domicilio',
          secundaryDegree: 'Titulo de bachiller',
          previousStudiesInstitutionCity: 'Municipio del colegio',
      },
  } 
  },
  es: {
    tooltips: {
      select_image: 'selecciona una imagen',
      actions: {
        add: 'Asignar',
        remove: 'Quitar',
        title: 'Acciones'
      }
    },
    enable: 'Habilitado',
    disable: 'Deshabilitado',
    noData: '-',
    table: {
      breadcrumbs: {
        title: 'Usuarios',
        description: 'Administración de usuarios',
        actions: {
          loadMassive: 'Estudiantes SIES'
        }
      },
      bulk_delete: 'Eliminar usuarios',
      bulk_delete_error: 'Los usuarios seleccionados no se pudieron eliminar',
      delete_error: 'Los usuarios seleccionados no se pudieron eliminar',
      headers: {
        name: 'Nombres',
        roles: 'Roles',
        status: 'Estado',
        careerCode: 'Código Carrera',
        lastActivity: 'Última actividad',
        nameCareer: 'Nombre de carrera',
        academicStatus: 'Estado Académico'
      },
      status: {
        actives: 'Activos',
        inactives: 'Inactivos'
      },
      message: {
        delete: '¿Estás seguro de eliminar?'
      },
      details: 'Detalles',
      force_change_password: {
        action: 'Forzar cambio de clave',
        error: 'No se pudo forzar el cambio de clave para el usuario seleccionado',
        success: 'Se forzó cambio de clave para el usuario seleccionado',
      },
      student: 'Alumno',
      massImportDrawer: {
        breadcrumbs: {
          title: 'Carga masiva',
          description: 'Cargar datos laborales a diversos estudiantes',
          descriptionSIES: 'Carga de estudiantes a SIES'
        },
        title: 'Cargue el listado de estudiantes',
        messages: {
          noFiles: 'No se detectaron archivos para enviar',
          sendConfirmation: '¿Desea enviar el siguiente archivo? No se podrá revertir',
          pendingFiles: 'Hay un archivo pendiente. ¿Desea salir?',
          success: 'Se cargaron correctamente los datos',
          error: 'Ocurrió un error cargando los datos',
        }
      }
    },
    create: {
      breadcrumbs: {
        title: 'Nuevo usuario',
        description: 'Crea un nuevo usuario'
      },
      success_message: {
        title: 'Usuario creado correctamente',
        actions: {
          primary_text: 'Cerrar',
          secondary_text: 'Crear otro',
          back: 'Volver'
        }
      },
      error_message: {
        title: 'Se produjo un error al crear el usuario',
        existing_user: 'El usuario ingresado ya existe'
      },
      tmpDrawer: {
        title: 'Nuevo rol',
        description:
          'Define acceso y control a las diferentes aplicaciones y módulos del sistema'
      },
      message: {
        successCreation: '¡Usuario creado con éxito!'
      },
      stepper: {
        personal: {
          title: 'Información personal',
          button: 'Siguiente'
        },
        contact: {
          title: 'Información de contacto',
          button: 'Siguiente'
        },
        roles: {
          title: 'Asignación de roles',
          button: 'Crear usuario'
        }
      },
      createRol: 'Crear nuevo rol',
      transferList: {
        firstTitle: 'Roles disponibles',
        secondTitle: 'Roles asignados'
      },
      validation_errors: {
        personalForm: {
          identification: 'Ingresa un número de identificacion válido.',
          identification_unique: 'Identificación ya existente',
          identification_format: 'El formato es incorrecto',
          gender: 'selecciona un género',
          name: 'Ingresa los nombres',
          lastname: 'Ingresa los apellidos',
          identificationType: 'selecciona un tipo de identificación',
        },
        contactForm: {
          email: 'Ingresa un Email válido',
          address: 'Ingresa una dirección',
          cellphone: 'Ingresa un teléfono válido',
          city: 'Ingresa mínimo 3 caracteres para filtrar la ciudad'
        },
        roles: 'selecciona al menos un rol'
      },
      personalForm: {
        avatar: 'Subir imagen',
        name: 'Nombres',
        lastname: 'Apellidos',
        birthDate: 'Fecha de nacimiento',
        genre: 'Género',
        identification: 'Identificación',
        identificationNumber: 'Número de identificación'
      },
      contactForm: {
        email: 'Correo electronico',
        description:
          'El usuario recibirá un correo electrónico de bienvenida con instrucciones de configuración.',
        cellphone: 'Teléfono móvil',
        phone: 'Teléfono Fijo',
        addressName: 'Calle',
        addressNumber: 'Número',
        addressFloor: 'Piso',
        addressApartment: 'Departamento',
        addressZipCode: 'Código Postal',
        city: 'Ciudad'
      }
    },
    update: {
      breadcrumbs: {
        title: 'Editar usuario',
        description: 'Modifica el usuario seleccionado'
      },
      success_message: {
        title: 'Usuario modificado correctamente',
        actions: {
          primary_text: 'Cerrar',
          back: 'Volver'
        }
      },
      error_message: {
        title: 'Se produjo un error al modificar el usuario'
      },
      sectionPersonal: {
        title: 'Información personal',
        fields: {
          avatar: 'Subir imagen',
          name: 'Nombres',
          lastname: 'Apellidos',
          birthDate: 'Fecha de nacimiento',
          birthPlace: 'Lugar de nacimiento',
          nationality: 'Nacionalidad',
          genre: 'Sexo',
          identification: 'Identificación',
          identificationNumber: 'Número de identificación',
          serialNumber: 'Número de serie',
          studentSIES: 'Estudiante SIES'
        },
        validation_errors: {
          identificationNumber: 'Ingresa el número de identificación',
          identification_unique: 'Identificación ya existente',
          gender: 'selecciona un género',
          name: 'Ingresa nombres',
          lastname: 'Ingresa apellidos',
          identification: 'Selecciona un tipo de identificación',
          user: 'Ingresa un Email válido',
          serialNumber:'Ingresa un numero de serie válido',
          birthPlace: 'Ingresa mínimo 3 caracteres para filtrar la ciudad',
          nationality: 'Ingresa nacionalidad'
        }
      },
      sectionPreviousStudies: {
        title: 'Estudios previos',
        fields: {
          level: 'Nivel de estudios alcanzados',
          city: 'Ciudad',
          institution: 'Nombre de la institución'
        },
        validation_errors: {
          level: 'Selecciona un nivel',
          institution: 'Selecciona una institución'
        }
      },
      sectionCognito: {
        title: 'Cognito',
        fields: {
          enabledDisabled: 'Usuario habilitado'
        }
      },
      sectionContact: {
        title: 'Información de contacto',
        fields: {
          email: 'Correo electrónico',
          password: 'Contraseña',
          resetPassword: 'Blanquear',
          resetedPassword: 'Blanqueada',
          resetPasswordTitle: 'Blanqueo de clave',
          resetPasswordWarning: '¿Seguro que desea blanquear la contraseña?',
          resetPasswordMessage: 'Correo enviado correctamente',
          resetPasswordMessageError: 'No se pudo enviar el correo',
          cellphone: 'Teléfono móvil',
          phone: 'Teléfono Fijo',
          addressName: 'Calle',
          addressNumber: 'Número',
          addressFloor: 'Piso',
          addressApartment: 'Departamento',
          addressZipCode: 'Código Postal',
          city: 'Ciudad'
        },
        validation_errors: {
          email: 'Ingresa un Email válido',
          addressName: 'Ingresa una dirección',
          city: 'Ingresa mínimo 3 caracteres para filtrar la ciudad'
        }
      },
      sectionAcademic: {
        title: 'Información académica',
        fields: {
          fileNumber: 'Número de legajo',
          userProfile: 'Perfil de usuario'
        }
      },
      sectionRoles: {
        title: 'Asignación de roles',
        validation_errors: {
          roles: 'Selecciona al menos un rol'
        }
      },
      transferList: {
        firstTitle: 'Roles disponibles',
        secondTitle: 'Roles asignados'
      },
      createRol: 'Crear nuevo rol',
      tmpDrawer: {
        title: 'Nuevo rol',
        description:
          'Define acceso y control a las diferentes aplicaciones y módulos del sistema'
      },
      messages: {
        successUpdate: 'Usuario modificado correctamente',
        studentFetchError: 'Ocurrió un error al obtener los datos del estudiante'
      }
    },
    details: {
      breadcrumbs: {
        title: 'Detalle de usuario',
        description: 'Vea los detalles del usuario'
      },
      dataSection: {
        title: 'Datos personales',
        buttonEdit: 'Editar',
        docType: 'Tipo de Documento',
        docNumber: 'Número de Documento',
        serialNumber: 'Numero de serie',
        name: 'Nombres',
        lastname: 'Apellido',
        maritalStatus: 'Estado civil',
        birthDate: 'Fecha de nacimiento',
        gender: 'Género',
        birthPlace: 'Lugar de nacimiento',
        nationality: 'Nacionalidad',
        street: 'Calle',
        number: 'Número',
        floor: 'Piso',
        department: 'Dto.',
        city: 'Ciudad domicilio',
        zipCode: 'Código Postal',
        previousStudiesLevel: 'Nivel de estudios alcanzados',
        previousStudiesInstitutionCity: 'Ciudad del colegio',
        previousStudiesInstitutionName: 'Nombre del colegio',
        studentSIES: {
          userSIES: 'Estudiantes SIES',
          userTrue: 'Si',
          userFalse: 'No',
        },
        phone: 'Teléfono fijo',
        cellphone: 'Teléfono móvil',
        secundaryDegree: 'Titulo secundario',
        sign_date: 'Fecha de firma',
        period: 'Periodo',
        download: 'Descargar contrato',
      },
      workSection: {
        drawer: {
          title: 'Datos laborales',
          description: 'Modifica los datos laborales',
          form: {
            title: 'Datos laborales',
            workingStatus: 'Situación laboral',
            workersQuantity: 'Número de trabajadores de la empresa',
            companyName: 'Nombre empresa de trabajo',
            bossName: 'Nombre y apellido del jefe directo',
            bossEmail: 'Mail del jefe directo',
            bossTelephone: 'Teléfono del jefe directo',
            validations: {
              workingStatusRequired: 'Debe seleccionar una situación laboral',
              workersQuantityRequired: 'Debe seleccionar el número de trabajadores',
              companyNameRequired: 'Ingrese el nombre de la compañía',
              companyNameAlphanumericWithSpaces: 'Solo alfanuméricos',
              companyNameMaxLength: 'Máximo 60 caracteres',
              bossNameAlphabeticWithSpaces: 'Solo alfabéticos',
              bossNameMaxLength: 'Máximo 60 caracteres',
              bossEmailFormat: 'Formato de email inválido'
            },
            messages: {
              success: 'Datos laborales actualizados correctamente',
              error: 'Ocurrió un error al actualizar los datos laborales'
            }
          }
        },
        title: 'Datos laborales',
        workingStatus: 'Situación laboral',
        workersQuantity: 'Número de trabajadores de la empresa',
        companyName: 'Nombre empresa',
        bossName: 'Nombre y apellido del jefe directo',
        bossEmail: 'Mail del jefe directo',
        bossTelephone: 'Teléfono del jefe directo'
      },
      historySection: {
        initialTerm: 'Periodo de ingreso',
        startDate: 'Fecha de inicio',
        degreeDate: 'Fecha de titulación',
        graduationDate : 'Fecha de graduacion',
        benefitsTitle: 'Beneficio',
        scholarshipsTitle: 'Beca',
        contractTitle: 'Contratos',
        modality: 'Reglamento',
        downloadContract: 'Descargar contrato',
        documentationTitle: 'Documentación',
        studentCareer: 'Legajo',
        studyPlan: 'Plan de estudio'
      },
      summarySection: {
        resetPassword: 'Blanquear contraseña',
        career: 'Carrera',
        personalInfo: 'Datos personales',
        rut: 'Rut',
        email: 'Email',
        obligatoryDocumentation: 'Documentación obligatoria',
        viewDetails: 'Ver detalle',
        uploadedDocuments: 'documentos cargados',
        uploadedDocument: 'documento cargado',
        noDocuments: 'Ningún documento cargado',
        course: 'En curso'
      },
      status: {
        curse: 'Cursando',
        curseEquivalent: 'Cursando',
        anulated: 'Baja',
        anulatedEquivalent: 'RAIC',
        freeze: 'Baja Definitiva',
        freezeEquivalent: 'Baja Transitoria',
        preinscripted: 'Preinscrito',
        preinscriptedEquivalent: 'Preinscrito',
        graduate: 'Egresado',
        graduateEquivalent: 'Egresado',
        certificated: 'Titulado',
        certificatedEquivalent: 'Titulado',
        deleted_no_subject: 'Eliminado por no carga académica',
        deleted_no_enrollment: 'Eliminado por no matricula',
      },
      changePassword: {
        error: 'No se pudo blanquear la contraseña.',
        success: 'La nueva contraseña del usuario es: {newPass}',
        cognitoSuccess: 'Se ha enviado a su casilla de correo su nueva contraseña temporal de Cognito'
      }
    }
  },
  en: {
    tooltips: {
      select_image: 'Select an image',
      actions: {
        add: 'Assign',
        remove: 'Remove',
        title: 'Accions'
      }
    }
  }
};


export default usersTranslations
