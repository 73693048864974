<template>
  <v-container fluid class="d-flex pa-0 fullHeight">
    <v-container fluid class="scrollableContent pa-0">
      <v-container class="container-custom px-6">
        <Breadcrumbs
          :title="$t('modules.pricing.subscription_rules.table.breadcrumbs.title')"
          :description="$t('modules.pricing.subscription_rules.table.breadcrumbs.description')"
        >
          <Button
            v-if="canCreate"
            @clicked="newSubscription"
            :icon="'mdi-plus'"
            :text="$t('actions.new')"
            depressed
          ></Button>
        </Breadcrumbs>
        <v-row justify="center">
          <v-col sm="12" class="viewSpaces">
            <SuperTable
              :headers="headers"
              :data="subscriptions"
              :permissions="{update: canUpdate, delete: canDelete}"
              :sortBy.sync="sort.sortBy"
              :sortDesc.sync="sort.sortDesc"
              @deleteData="deletePopUp"
              @updateData="updateSubscription"
              @input-pagination="fetchData"
              @length-pagination-change="fetchData"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </v-container>
</template>

<script>
import SuperTable from '../Components/SuperTable';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import Button from '@/components/Button/Button';
import {$suscriptions} from '../Services';

export default {
  name: 'SubscriptionRulesTable',
  components: {
    SuperTable,
    Breadcrumbs,
    Button,
  },
  data() {
    return {
      loading: false,
      subscriptions: [],
      sort: {
        sortBy: 'code',
        sortDesc: false,
      },
      headers: [
        {
          text: this.$t('modules.pricing.subscription_rules.table.headers.code'),
          value: 'code',
          align: 'center'
        }, {
          text: this.$t('modules.pricing.subscription_rules.table.headers.description'),
          value: 'description'
        }, {
          text: this.$t('modules.pricing.subscription_rules.table.headers.adminExpenses'),
          value: 'adminExpenses',
          align: 'center'
        }, {
          text: this.$t('modules.pricing.subscription_rules.table.headers.installments'),
          value: 'installments',
          align: 'center'
        }, {
          text: this.$t('modules.pricing.subscription_rules.table.headers.payDay'),
          value: 'payDay',
          align: 'center'
        }, {
          text: this.$t('modules.pricing.subscription_rules.table.headers.paymentMethod'),
          value: 'suscriptionPaymentType',
          align: 'center'
        },
        {
          text: this.$t('modules.pricing.subscription_rules.table.headers.studentType'),
          value: 'studentType.meaning',
          align: 'center'
        }, {
          text: this.$t('modules.prepaids.table.headers.status'),
          value: 'status',
          align: 'center'
        }, {
          text: '',
          value: 'action',
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    canCreate() {
      return this.$permissions.portaladministrativo.prices.subscription_rules.CREATE;
    },
    canUpdate() {
      return this.$permissions.portaladministrativo.prices.subscription_rules.READ || this.$permissions.portaladministrativo.prices.subscription_rules.UPDATE;
    },
    canDelete() {
      return this.$permissions.portaladministrativo.prices.subscription_rules.DELETE;
    },
  },
  methods: {
    deletePopUp(id) {
      const subscription = this.subscriptions.find(subscription => subscription.id == id);
      this.$store.dispatch('commons/openPopUp', {
        title: this.$t('delete_popup_title'),
        content: [{value: subscription.description}],
        actionPrimary: {text: this.$t('actions.delete'), callback: () => this.deleteSubscription(id)},
        actionSecondary: {
          text: this.$t('actions.cancel'), callback() {
          }
        },
        icon: {color: 'error', name: 'mdi-delete'},
        color: 'primary',
      });
    },
    async fetchData() {
      if (this.loading) return false;
      this.loading = true;
      try {
        const {data} = await $suscriptions.find();
        this.subscriptions = data;
      } catch (err) {
        this.subscriptions = [];
        throw err;
      } finally {
        this.loading = false;
      }
    },
    newSubscription() {
      if (this.canCreate) this.$router.push('/pricing/subscription-rules/create');
    },
    updateSubscription(id) {
      if (this.canUpdate) this.$router.push(`/pricing/subscription-rules/update/${id}`);
    },
    async deleteSubscription(id) {
      try {
        await $suscriptions.delete(id);
        this.fetchData()
      } catch (error) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('actions.error'),
          content: [{value: (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.commissions.ranges.table.messages.deleteError')}],
          actionPrimary: {
            text: this.$t('actions.accept'), callback() {
            }
          },
          icon: {color: 'error', name: 'mdi-close'},
          color: 'primary',
        });
        throw error;
      }
    },
  },
  watch: {
    sort: {
      handler() {
        this.fetchData();
      },
      deep: true
    }
  }
};
</script>
