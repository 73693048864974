<template>
  <v-container fluid class="pa-0 pb-8">
    <v-container class="container-custom" v-if="!updateExtraBenefitStatus">
      <Breadcrumbs
        :title="$t('modules.extraBenefit.update.breadcrumbs.title')"
        :description="$t('modules.extraBenefit.update.breadcrumbs.description')"
      ></Breadcrumbs>
      <v-row justify="center">
        <v-col class="viewSpaces" sm="12">
          <OutlinedCard
            :title="$t('modules.extraBenefit.update.codeSection.title')"
            :subtitle="$t('modules.extraBenefit.update.codeSection.subtitle')"
            :switch-label="(status) ? $t('enabled') : $t('disabled')"
            :switch-value="status"
            @switchChange="(val) => {this.status = val;}"
            switcher
            class="mb-6"
          >
            <v-row>
              <v-col sm="6">
                <v-text-field
                    :error-messages="($v.description.$dirty && $v.description.$invalid) ?
                    ((!$v.description.required) ? $t('modules.extraBenefit.validations.nameRequired') :
                    (!$v.description.alphaNumWithSpaces) ? $t('modules.extraBenefit.validations.nameAlphanumericWithSpaces') :
                    $t('modules.extraBenefit.validations.nameMaxLength')) :
                    ''"
                    @blur="$v.description.$touch()"
                    @keypress="$validateAlphanumeric($event, $v.description.$model,60, true)"
                    v-model="$v.description.$model"
                    ref="description"
                    :label="`${$t('modules.extraBenefit.update.codeSection.labels.name')} *`"
                    outlined
                ></v-text-field>
              </v-col>
              <v-col sm="3">
                <v-text-field
                    :error-messages="($v.code.$dirty && $v.code.$invalid) ?
                    ((!$v.code.required) ? $t('modules.extraBenefit.validations.codeRequired') :
                    (!$v.code.alphaNum) ? $t('modules.extraBenefit.validations.codeAlphanumeric') :
                    (!$v.code.maxLength) ? $t('modules.extraBenefit.validations.codeMaxLength') :
                    $t('modules.extraBenefit.validations.codeUnique')) :
                    ''"
                    @blur="validateCode"
                    @keypress="$validateAlphanumeric($event, $v.code.$model,10)"
                    :loading="loadingCode"
                    v-model="$v.code.$model"
                    ref="code"
                    :label="`${$t('modules.extraBenefit.update.codeSection.labels.code')} *`"
                    outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </OutlinedCard>
          <OutlinedCard :title="$t('modules.extraBenefit.update.studentSection.title')" class="mb-6">
            <v-radio-group class="mt-0" v-model="$v.selectedStudentType.$model" row>
              <v-radio
                v-for="(studentType, index) in studentTypes"
                color="primary"
                ref="studentType"
                :key="index"
                :label="studentType.meaning"
                :value="studentType.value"
              ></v-radio>
              <v-alert
                text
                type="error"
                v-if="$v.selectedStudentType.$anyError"
              >{{ $t('modules.extraBenefit.validations.studentType') }}
              </v-alert>
            </v-radio-group>
          </OutlinedCard>
          <OutlinedCard
            :title="$t('modules.extraBenefit.update.termSection.title')"
            :subtitle="$t('modules.extraBenefit.update.termSection.subtitle')"
            class="mb-6"
          >
            <TransferList
              :firstListTitle="$t('modules.extraBenefit.update.termSection.transferList.firstTitle')"
              :secondListTitle="$t('modules.extraBenefit.update.termSection.transferList.secondTitle')"
              :availableItems.sync="terms"
              :added-items.sync="$v.termsAdded.$model"
            ></TransferList>
            <v-alert
              text
              type="error"
              class="mt-4"
              v-if="$v.termsAdded.$dirty && $v.termsAdded.$anyError"
            >{{ $t('modules.extraBenefit.validations.noSelectedTerms') }}
            </v-alert>
          </OutlinedCard>
          <OutlinedCard
            :title="$t('modules.extraBenefit.update.scholarship.title')"
            :subtitle="$t('modules.extraBenefit.update.scholarship.subtitle')"
            class="mb-6"
            :disabled="!!benefitsAdded.length"
          >
            <TransferList
              :firstListTitle="$t('modules.extraBenefit.update.termSection.transferList.firstTitle')"
              :secondListTitle="$t('modules.extraBenefit.update.termSection.transferList.secondTitle')"
              :availableItems.sync="scholarships"
              :added-items.sync="$v.scholarshipsAdded.$model"
            ></TransferList>
          </OutlinedCard>
          <OutlinedCard
            :title="$t('modules.extraBenefit.update.benefits.title')"
            :subtitle="$t('modules.extraBenefit.update.benefits.subtitle')"
            class="mb-6"
            :disabled="!!scholarshipsAdded.length"
          >
            <TransferList
              :firstListTitle="$t('modules.extraBenefit.update.termSection.transferList.firstTitle')"
              :secondListTitle="$t('modules.extraBenefit.update.termSection.transferList.secondTitle')"
              :availableItems.sync="benefits"
              :added-items.sync="$v.benefitsAdded.$model"
            ></TransferList>
          </OutlinedCard>
          <OutlinedCard :title="$t('modules.extraBenefit.update.dayRangeSection.title')" class="mb-6">
            <v-row no-gutters>
              <v-col sm="16">
                <MultipleFormGroup
                  ref="groups"
                  :items="$v.groups.$each.$iter"
                  :length="groups.length"
                  @addGroup="addGroup"
                  @removeGroup="removeGroup"
                  :canAddGroup= "false"
                >
                  <template v-slot:default="{item}">
                    <v-container fluid class="pa-0">
                      <v-row>
                        <v-col class="pb-0" sm="3">
                          <v-text-field
                            :error-messages="(item.dayFrom.$invalid && item.dayFrom.$dirty) ?
                            ((!item.dayFrom.required) ? $t('modules.extraBenefit.validations.dayFromRequired') :
                            (!item.dayFrom.isUniqueRange) ? $t('modules.extraBenefit.validations.uniqueRange') :
                            (!item.dayFrom.isValidRange) ? $t('modules.extraBenefit.validations.validRange') :
                            $t('modules.extraBenefit.validations.betweenValues'))
                            : '' "
                            :label="$t('modules.extraBenefit.update.dayRangeSection.labels.dayFrom')"
                            outlined
                            @keypress="$validateIntegerInputNumber($event, item.dayFrom.$model, 31, null, 1)"
                            @blur="item.dayFrom.$touch()"
                            ref="dayFrom"
                            v-model="item.dayFrom.$model"
                          ></v-text-field>
                        </v-col>
                        <v-col class="pb-0" sm="3">
                          <v-text-field
                            :error-messages="(item.dayTo.$invalid && item.dayTo.$dirty) ?
                            ((!item.dayTo.required) ? $t('modules.extraBenefit.validations.dayToRequired') :
                            (!item.dayTo.isUniqueRange) ? $t('modules.extraBenefit.validations.uniqueRange') :
                            (!item.dayTo.isValidRange) ? $t('modules.extraBenefit.validations.validRange') :
                            $t('modules.extraBenefit.validations.betweenValues'))
                            : '' "
                            :label="$t('modules.extraBenefit.update.dayRangeSection.labels.dayTo')"
                            @keypress="$validateIntegerInputNumber($event, item.dayTo.$model, 31, null, 1)"
                            @blur="item.dayTo.$touch()"
                            ref="dayTo"
                            outlined
                            v-model="item.dayTo.$model"
                          ></v-text-field>
                        </v-col>
                        <v-col class="pb-0" sm="3">
                          <v-text-field
                            :error-messages="(item.percentageBimester.$invalid && item.percentageBimester.$dirty) ?
                            ((!item.percentageBimester.required) ? $t('modules.extraBenefit.validations.bimesterRequired') :
                            $t('modules.extraBenefit.validations.percentageBetweenValues'))
                            :''"
                            :label="$t('modules.extraBenefit.create.dayRangeSection.labels.percentageBimester')"
                            :min="0"
                            outlined
                            ref="percentage"
                            append-icon="mdi-percent"
                            @keypress="$validateDecimalInputNumber($event, item.percentageBimester.$model, 100, null, 0)"
                            v-model="item.percentageBimester.$model"
                          ></v-text-field>
                        </v-col>
                        <v-col class="pb-0" sm="3">
                          <v-text-field
                          :error-messages="(item.percentageSemester.$invalid && item.percentageSemester.$dirty) ? 
                          ((!item.percentageSemester.required) ? $t('modules.extraBenefit.validations.semesterRequired') : 
                          $t('modules.extraBenefit.validations.percentageBetweenValues')) 
                          : ''"
                          :label="$t('modules.extraBenefit.create.dayRangeSection.labels.percentageSemester')"
                          outlined
                          type="number"
                          :min="0"
                          ref="percentageSemester"
                          append-icon="mdi-percent"
                          @keypress="$validateDecimalInputNumber($event, item.percentageSemester.$model, 100, null, 0)"
                          @blur="item.percentageSemester.$touch()"
                          v-model="item.percentageSemester.$model"
                        ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </template>
                </MultipleFormGroup>
              </v-col>
            </v-row>
          </OutlinedCard>
          <OutlinedCard :title="$t('modules.extraBenefit.update.zoneSection.title')" class="mb-6">
            <v-checkbox
              :key="index"
              :label="zone.zoneDescription"
              :value="zone.id"
              class="mr-8 mt-0 no-message"
              color="primary"
              ref="selectedZone"
              v-for="(zone, index) in zones"
              v-model="$v.selectedZone.$model"
            ></v-checkbox>
            <v-alert
              text
              type="error"
              v-if="$v.selectedZone.$anyError"
            >{{ $t('modules.extraBenefit.validations.zone') }}
            </v-alert>
          </OutlinedCard>
          <OutlinedCard
            :title="$t('modules.extraBenefit.update.careersSection.title')"
            :subtitle="$t('modules.extraBenefit.update.careersSection.subtitle')"
            class="mb-6"
          >
          <TransferList
              :firstListTitle="$t('modules.extraBenefit.update.careersSection.transferList.firstTitle')"
              :secondListTitle="$t('modules.extraBenefit.update.careersSection.transferList.secondTitle')"
              :availableItems.sync="availableCareers"
              :addedItems.sync="$v.selectedCareers.$model"
          ></TransferList>
            <v-alert
              text
              type="error"
              class="mt-4"
              v-if="$v.selectedCareers.$dirty && $v.selectedCareers.$anyError"
            >{{ $t('modules.extraBenefit.validations.noSelectedCareers') }}
            </v-alert>
          </OutlinedCard>
          <div class="d-flex justify-end">
            <Button
              white
              :text="$t('actions.cancel')"
              @clicked="$router.replace('/extra-benefits')"
              @end="resetButtonValues"
              class="mr-4"
            ></Button>
            <Button
              :loading="updateButton.loading"
              :success="updateButton.success"
              :error="updateButton.error"
              :text="$t('actions.save')"
              :successText="$t('actions.created')"
              :errorText="$t('actions.error')"
              :disabled="!canUpdate"
              @end="resetButtonValues"
              @clicked="updateExtraBenefit"
            ></Button>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <SuccessMessage
      v-else
      :type="successMessage.type"
      :title="successMessage.title"
      :actionPrimary="successMessage.actionPrimary"
      :actionSecondary="successMessage.actionSecondary"
    />
  </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import Button from '@/components/Button/Button';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import TransferList from '@/components/TransferList/TransferListNew'
import MultipleFormGroup from '@/components/MultipleFormGroup/MultipleFormGroup';
import {required, alphaNum, maxLength, helpers, between} from 'vuelidate/lib/validators';
import {$extraBenefit, $types, $terms, $careers, $zones, $benefits, $scholarships } from '../Services';

export default {
  name: 'ExtraBenefitUpdate',
  components: {
    Breadcrumbs,
    OutlinedCard,
    Button,
    SuccessMessage,
    TransferList,
    MultipleFormGroup
  },
  data() {
    return {
      description: '',
      code: null,
      originalCode: null,
      codeUnique: true,
      studentTypes: [],
      selectedStudentType: 7,
      zones: [],
      selectedZone: [],
      availableCareers: [],
      selectedCareers: [],
      terms: [],
      termsAdded: [],
      loadingCode: false,
      status: true,
      scholarships: [],
      scholarshipsAdded: [],
      benefits: [],
      benefitsAdded: [],
      groups: [
        {
          dayFrom: null,
          dayTo: null,
          percentageBimester: null,
          percentageSemester: null,
        }
      ],
      updateExtraBenefitStatus: false,
      updateButton: {
        loading: false,
        success: false,
        error: false
      },
      successMessage: {
        type: null,
        title: null,
        actionPrimary: null,
        actionSecondary: null
      }
    };
  },
  computed: {
    canUpdate() {
      return this.$permissions.portaladministrativo.prices.extraBenefits.UPDATE;
    },
  },
  async mounted() {
    await this.getStudentType()
    await this.getTerms()
    await this.getScholarships()
    await this.getBenefits()
    await this.getZones()
    await this.getCareers()
    this.fetchExtraBenefit()
  },
  methods: {
    async fetchExtraBenefit() {
      const { data } = await $extraBenefit.findById(this.$route.params.id);
      this.description = data.description;
      this.code = data.code;
      this.originalCode = data.code;
      this.selectedStudentType = data.studentType.value;
      this.termsAdded = data.terms?.map(item => {
        item.name = `(${item.code}) ${item.description}`;
        return item;
      });
      this.selectedStudentTypes = data.studentType.value;
      this.status = data.status;
      this.selectedZone = [...new Set(data.careers.map(item => item.zone.id))];
      this.selectedCareers = Object.values(data.careers.reduce((r, item) => (r[`${item.career.id}`] = {
        code: item.career.code,
        description: item.career.description,
        id: item.career.id,
        name: `(${item.career.code}) ${item.career.description}`
      }, r), {}));
      this.scholarshipsAdded = data.scholarships && data.scholarships.length ? data.scholarships.map(item => ({ ...item.scholarship, name: item.scholarship.scholarshipDescription })) : []
      this.groups = [];
      data.details.forEach(detail => {
        this.groups.push({
          percentageBimester: detail.percentageBimester,
          percentageSemester: detail.percentageSemester,
          dayTo: detail.dayTo,
          dayFrom: detail.dayFrom
        });
      });
      this.benefitsAdded = data.benefits && data.benefits.length ? data.benefits.map(item => ({ ...item.benefit, name: item.benefit.benefitDescription })) : []
    },
    async getTerms() {
      try {
        const { data } = await $terms.find(null, null, {
          params: {
            page: 0,
            length: 100,
            orderBy: 'classStartDate',
            orientation: 'desc',
          }
        });
        this.terms = data.content.map(item => {
          item.name = `(${item.code}) ${item.description}`;
          return item;
        });
      } catch (error) {
        this.terms = [];
        throw error;
      }
    },
    async getCareers() {
      try {
        const { data } = await $careers.find(null, null, {
          params: {
            page: 0,
            length: 100,
            orderBy: 'id',
            orientation: 'desc'
          }
        });
        this.availableCareers = data.content.map(item => {
          item.name = `(${item.code}) ${item.description}`;
          return item;
        });
      } catch (err) {
        this.availableCareers = [];
        throw err;
      }
    },
    async getZones() {
      try {
        const { data } = await $zones.find(null, null, {
          params: {
            page: 0,
            length: 100,
            orderBy: 'id',
            orientation: 'desc'
          }
        });
        this.zones = data.content;
      } catch (err) {
        this.zones = [];
        throw err;
      }
    },
    async getStudentType() {
      try {
        const { data } = await $types.find(null, null, {params: {type: 'STUDENT_TYPE'}});
        this.studentTypes = data
      } catch (err) {
        this.studentTypes = [];
        throw err;
      }
    },
    async validateCode() {
      this.$v.code.$touch();
      if (this.$v.code.$model) {
        if (this.originalCode === this.code) {
          this.codeUnique = true;
          return;
        }
        this.codeUnique = null;
        this.loadingCode = true;
        try {
          const response = await $extraBenefit.find('exists', null, {params: {code: this.$v.code.$model}});
          this.codeUnique = !response.data.exists;
          this.loadingCode = false;
        } catch (error) {
          this.codeUnique = null;
          throw error;
        } finally {
          this.$v.code.$touch();
        }
      }
    },
    async getScholarships() {
      try {
        const { data } = await $scholarships.find(null, null, {
          params: {
            page: 0,
            length: 100,
            orderBy: 'id',
            orientation: 'desc'
          }
        });
        this.scholarships = data.content.map(item => {
          item.name = `(${item.code}) ${item.description}`;
          return item;
        });
      } catch (error) {
        this.scholarships = [];
        throw error;
      }
    },
    async getBenefits() {
      try {
        const { data } = await $benefits.find(null, null, {
          params: {
            page: 0,
            length: 100,
            orderBy: 'id',
            orientation: 'desc'
          }
        });
        this.benefits = data.content.map(item => {
          item.name = `(${item.code}) ${item.description}`;
          return item;
        });
      } catch (error) {
        this.benefits = [];
        throw error;
      }
    },
    addGroup() {
      const mockup =
        {
          dayFrom: null,
          dayTo: null,
          percentageBimester: null,
          percentageSemester: null,
        };
      this.groups.push({
        ...mockup
      });
    },
    removeGroup(index) {
      this.groups.splice(index, 1);
    },
    async updateExtraBenefit() {
      this.updateButton.loading = true;
      try {
        if (this.$v.$invalid || !this.codeUnique) {
          this.$v.$touch();
          if(!this.codeUnique) {
            this.$refs.code.$el.scrollIntoView({block: 'center', behavior: 'smooth'})
          } else {
            Object.keys(this.$v).some(input => {
              if (input.includes('$')) return false;
              if (this.$v[input].$error) {
                this.$refs[input][0]
                  ? this.$refs[input][0].$el.scrollIntoView({block: 'center', behavior: 'smooth'})
                  : this.$refs[input].$el.scrollIntoView({block: 'center', behavior: 'smooth'});
                return true
              }
            })
          }
          this.updateButton.error = true;
          this.updateButton.loading = false;
        } else {
          const newExtraBenefit = {
            description: this.description.trim(),
            code: this.code,
            status: this.status,
            terms: this.termsAdded.map(term => {
              return {
                id: term.id
              };
            }),
            details: this.groups.map(detail => {
              return {
                percentageBimester: detail.percentageBimester,
                percentageSemester: detail.percentageSemester,
                dayFrom: detail.dayFrom,
                dayTo: detail.dayTo,
              };
            }),
            studentType: {value: this.selectedStudentType},
            careers: this.selectedCareers.reduce((a, career) =>
                [...a, ...this.selectedZone.map(zoneId => {
                  return {
                    career: {
                      id: career.id
                    },
                    zone: {
                      id: zoneId
                    }
                  };
                })],[]
            ),
            scholarships: this.scholarshipsAdded.map(scholarship =>{
              return {scholarship: {id: scholarship.id}}}
            ),
            benefits: this.benefitsAdded.map(benefit =>{
              return {benefit: {id: benefit.id}}}
            )
          };
          await $extraBenefit.update(this.$route.params.id, newExtraBenefit);
          this.successMessage.type = 'success';
          this.successMessage.title = this.$t('modules.extraBenefit.update.successMessage.title');
          this.successMessage.actionPrimary = {
            text: this.$t('actions.close'), callback: () => {
              this.$router.push('/extra-benefits');
            }
          };

          this.updateExtraBenefitStatus = true;
          this.updateButton.success = true;
          this.updateButton.loading = false;
          this.clearData();
        }
      }catch (error) {
        this.successMessage.type = 'error';
        this.successMessage.title = (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.extraBenefit.update.errorMessage.title');
        this.successMessage.actionPrimary = {
          text: this.$t('actions.return'), callback: () => {
            this.updateExtraBenefitStatus = false;
          }
        };
        this.successMessage.actionSecondary = null;
        this.updateExtraBenefitStatus = true;
        this.updateButton.loading = false;
        this.updateButton.error = true;
        throw error;
      }
    },
    clearData() {
      this.resetButtonValues();
      this.description = null;
      this.code = null;
      this.status = true;
      this.termsAdded = [];
      this.selectedStudentType = 7;
      this.selectedZone = [];
      this.selectedCareers = [];
      this.$v.$reset();
    },
    resetButtonValues() {
      this.updateButton.loading = false;
      this.updateButton.success = false;
      this.updateButton.error = false;
    },
  },
  validations: {
    description: {
      required,
      maxLength: maxLength(60),
      alphaNumWithSpaces: helpers.regex('withSpace', /^[a-z0-9]+(?:[a-z0-9]+\s[a-z0-9]*)*$/i)
    },
    code: {
      required,
      alphaNum,
      maxLength: maxLength(10),
      unique() {
        return this.codeUnique != false;
      },
    },
    selectedStudentType: {required},
    termsAdded: {required},
    scholarshipsAdded: {},
    benefitsAdded: {},
    groups: {
      $each:
        {
          dayFrom: {
            required,
            between: between(1, 31),
            isUniqueRange(value) {
              return this.groups.filter(e =>
                (e.dayFrom ? parseInt(e.dayFrom) <= parseInt(value) : false)
                && e.dayTo ? parseInt(e.dayTo) >= parseInt(value) : false
              ).length <= 1;
            },
            isValidRange(value, thisGroup) {
              if (thisGroup.dayTo) return parseInt(value) < parseInt(thisGroup.dayTo);
              return true;
            }
          },
          dayTo: {
            required,
            between: between(1, 31),
            isUniqueRange(value) {
              return this.groups.filter(e =>
                (e.dayFrom ? parseInt(e.dayFrom) <= parseInt(value) : false)
                && e.dayTo ? parseInt(e.dayTo) >= parseInt(value) : false
              ).length <= 1;
            },
            isValidRange(value, thisGroup) {
              if (thisGroup.dayFrom) return parseInt(value) > parseInt(thisGroup.dayFrom);
              return true;
            }
          },
          percentageBimester: {
            required,
            between: between(0, 100),
          },
          percentageSemester: {
            required,
            between: between(0, 100),
          },
        }
    },
    selectedZone: {required},
    selectedCareers: {required}
  }
};
</script>
