<template>
  <div>
    <v-container fluid class="d-flex pa-0 fullHeight" v-if="!selectedInstallments">
      <v-container fluid class="px-0">
        <div class="px-md-10">
          <div class="d-flex">
            <v-btn icon @click="$emit('goBackPayment')"><v-icon>mdi-chevron-left</v-icon></v-btn>
            <h4 class="d-flex align-center primary--text ml-2">{{ $t('modules.portalalumno.payments.paymentsStudents.view.subscription.payment', { org: $theme.toUpperCase() }) }}</h4>
          </div>
         <template v-if="payments && hasSubscriptionPayments">
           <PaymentComplex
              v-for="(payment, index) in payments"
              :payment="payment"
              :key="payment.id"
              @updatepaymentmethod = "updatepaymentmethod($event, index)"
              >
            </PaymentComplex>
         </template>
        </div>
      </v-container>
    </v-container>

  </div>
</template>

<script>
import moment from 'moment'
import _ from 'lodash'
import {$payments} from '../Services';
import {mapGetters} from 'vuex';
import PaymentComplex from '../Components/PaymentComplex/PaymentComplex';
export default {
  name: 'PaymentsSubscriptionView',
  components: {
    PaymentComplex
  },
  props: {
    subscriptions: Array,
    subscriptionPaymentTypes: Array,
    selectedMethod: Object,
    coupon: Object,
    resumen: Object,
    summaryButtons: Object,
    // unselectedReenrollmentsAccountIds: { type: Array, default() { return [] } },
    payments: Array,
    pendingPaymentsIdForSubscription: Array
  },
  data() {
    return {
      data:[],
      // paymentsSelected: [],
      selectedInstallments: false,
      selectedSubscription: null,
      adminExpenses: null,
      filteredSubscriptions: [],
      headers: [
        { text: this.$t('modules.portalalumno.payments.paymentsStudents.view.subscription.description'), value: 'description' },
        { text: this.$t('modules.portalalumno.payments.paymentsStudents.view.subscription.expiration'), value: 'expiration' },
        { text: this.$t('modules.portalalumno.payments.paymentsStudents.view.subscription.amount'), value: 'amount' }
      ],
      items: []
    }
  },
  computed: {
    ...mapGetters({
      loggedUserId: 'commons/getUserID'
    }),
    hasSubscriptionPayments(){
      if(!this.payments.length) return false
      return this.payments.every(payment => {
        if ([87, 89].includes(payment.billingProduct)) {
        payment.suscriptionView = true; // Cambia suscriptionView a true
        return true; // Mantiene la lógica de filtro
      }
      return false; // Si no es 87 o 89, retorna false
      });
    }
  },
  watch: {
    selectedSubscription(value) {
      this.summaryButtons.primary.disabled = !value
      const totalWithoutAdminExpenses = this.resumen.items.subtotal.monto - this.resumen.items.discounts.monto - this.resumen.items.scholarships.monto - this.resumen.items.benefits.monto
      this.adminExpenses = ((totalWithoutAdminExpenses * (this.selectedSubscription?.adminExpenses || 0)) / 100).toFixed(2)
      this.resumen.items.adminExpenses.monto = this.adminExpenses
      this.resumen.items.adminExpenses.percentage = this.selectedSubscription?.adminExpenses
      this.items = []
      for (let due = 0; due <= this.selectedSubscription?.installments; due++) {
        this.items.push({
          description: due ? `${this.$t('modules.portalalumno.payments.paymentsStudents.view.subscription.installment')} N°${due}` : this.$t('modules.portalalumno.payments.paymentsStudents.view.subscription.adminExpenses'),
          expiration: due ? moment(this.selectedSubscription.payDay, 'DD').add(due, 'months').format('D/M/YYYY') : moment().format('D/M/YYYY'),
          amount: due ? (totalWithoutAdminExpenses / this.selectedSubscription.installments).toFixed(2) : this.adminExpenses
        })
      }
      this.resumen.total = totalWithoutAdminExpenses + parseFloat(this.resumen.items.adminExpenses.monto)
    },
  },
  mounted() {
    this.selectedSubscriptionPaymentType();
    this.createSubscription(this.resumen)
  },

  methods: {
    selectedSubscriptionPaymentType() {
      this.selectedSubscription = []
      if(!this.subscriptions.length) return
      let filteredDues = this.subscriptions.filter(subscription => subscription.suscriptionPaymentType.value == 243)
      if (this.resumen.items.subtotal.items.filter(price => price.title.toUpperCase().includes('ARANCEL')).length < 2 && this.$country === 'argentina') {
        filteredDues = filteredDues.length ? [filteredDues.shift()] : []
      }else if (this.resumen.items.subtotal.items.filter(price => price.billingProduct === 81).length < 2 && this.$country === 'chile') {
        filteredDues = filteredDues.filter(subscription => subscription.installments === 3);
      } else if(this.resumen.items.subtotal.items.filter(price => price.billingProduct === 81).length === 2 && this.$country === 'chile'){
        filteredDues = filteredDues.filter(subscription => subscription.installments >= 6);
      }
      this.filteredSubscriptions = filteredDues
      this.selectedSubscription = filteredDues.at(-1)
    },
    updatepaymentmethod(value, index){
      this.payments.forEach((payment, idx)=>{
        if(idx < index) payment.selected = true
        if(idx === index) payment.selected = value
        if(idx > index) payment.selected = false
      })
      this.$emit('updateSelectedPayments')
    },
    async createSubscription() {
      const allOptions = this.payments.flatMap(payment => payment.options);
      const alumnoSeleccionado = JSON.parse(localStorage.getItem('alumnoSeleccionado'))
      const suscripcionData = {
        studentId: alumnoSeleccionado.student.id,
        email: alumnoSeleccionado.student.user.userData.email,
        transaction_amount: ((this.resumen.total - this.resumen.items.adminExpenses.monto) / this.selectedSubscription?.installments ).toFixed(2),
        userType: 'ADVISOR',
        legajo: alumnoSeleccionado.academicRegister,
        userId: this.loggedUserId,
        intencion_de_pago_id: this.resumen.paymentIntent,
        setup_fee: this.resumen.items.adminExpenses.monto,
        repetitions: this.selectedSubscription?.installments,
        first_debit_date: moment(this.selectedSubscription.payDay, 'DD').add(1, 'months'),
        description: 'GASTO_ADMINISTRATIVO',
        efectivo: this.selectedSubscription.suscriptionPaymentType.meaning?.toUpperCase() === 'EFECTIVO',
        termId: allOptions[0].term.id || null,
        idsPending: this.pendingPaymentsIdForSubscription
      }

      try {
        const { data} = await $payments.create(suscripcionData, {}, 'subscription');
        this.$emit('changePaymenstBySubscription', _.cloneDeep(data) )
        this.$emit('intentodepagoid', suscripcionData.intencion_de_pago_id);
      } catch {
        this.summaryButtons.primary.loading = false
        this.summaryButtons.primary.error = true
        this.summaryButtons.primary.error = false
      }
    },
    // setDue(due) {
    //   this.$emit('setDue', due)
    // }
  }

}
</script>

<style scoped>

</style>
