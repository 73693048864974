import syncCoursesTranslations from './SyncCourses/Translations'
import syncStudentTranslations from './SyncStudent/Translations'
import syncTeacherTranslations from './SyncTeacher/Translations'

const canvasTranslations = {
    es: {
        syncCourses: { ...syncCoursesTranslations.es },
        syncStudent: {...syncStudentTranslations.es},
        syncTeacher: {...syncTeacherTranslations.es}
    },
    es_CL: {
      syncCourses: { ...syncCoursesTranslations.es_CL},
      syncStudent: {...syncStudentTranslations.es_CL},
      syncTeacher: {...syncTeacherTranslations.es_CL}
    },
    en: {}
}

export default canvasTranslations
