import { render, staticRenderFns } from "./GenericBanner.vue?vue&type=template&id=44a8c53c&scoped=true&"
import script from "./GenericBanner.vue?vue&type=script&lang=js&"
export * from "./GenericBanner.vue?vue&type=script&lang=js&"
import style0 from "./GenericBanner.vue?vue&type=style&index=0&id=44a8c53c&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44a8c53c",
  null
  
)

export default component.exports