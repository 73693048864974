import Configuration from '../../core/constants/configuration';

export default {
  getApplicationImageUrl () {
    return `https://${Configuration.value('awsPublicUrl')}.${Configuration.value('url')}/core/applicationImage_${Configuration.value('app')}.png`
  },
  getLogoImageUrl () {
    return `https://${Configuration.value('awsPublicUrl')}.${Configuration.value('url')}/core/logoImage.png`
  },
  getAvatarImageUrl () {
    return `https://${Configuration.value('awsPublicUrl')}.${Configuration.value('url')}/core/defaultAvatar.png`
  },
  getBannerReferido () {
    return `https://${Configuration.value('awsPublicUrl')}.${Configuration.value('url')}/banners/bannerReferido.gif`
  },
  getApplicationImageUrlBase () {
    return `https://${Configuration.value('awsPublicUrl')}.${Configuration.value('url')}/core/applicationImage_`
  },
  getRulesImage () {
    return `https://${Configuration.value('awsPublicUrl')}.${Configuration.value('url')}/rules-image/`
  },
  getAssetsURL () {
    return `https://${Configuration.value('awsPublicUrl')}.${Configuration.value('url')}`
  },
  getUserID (state) {
    return state.loggedUserData.id;
  },
  getUserName (state) {
    return state.loggedUserData.name;
  },
  getUserData (state) {
    return state.loggedUserData.userData ? state.loggedUserData.userData : {}
  },
  getStudentUserID (state) {
    return state.userData && state.userData.userData ? state.userData.userData.userId : null;
  },
  getStudentUserData(state) {
    return state.userData && state.userData.userData ? state.userData.userData : null
  },
  getStudentId(state) {
    return state.userData && state.userData.student ? state.userData.student.id : null;
  },
  getStudentUser(state) {
    return state.userData && state.userData.student && state.userData.student.user ? state.userData.student.user.id : null;
  },
  getStudentRegisters(state) {
    return state.userData && state.userData.legajos ? state.userData.legajos : []
  },
  getStudentActualRegister(state) {
    return state.userData ? state.userData.academicRegister : null
  },
  getStudentCareer(state) {
    return state.userData ? state.userData.career : null
  },
  getStudentData(state) {
    return state.userData
  },
  getStudentInProcessSubjects(state) {
    return state.subjects.inProgress
  },
  getStudentRegularisedSubjects(state) {
    return state.subjects.regularised
  },
  getStudentApprovedSubjects(state) {
    return state.subjects.approved
  },
  getCheckTerms(state) {
    return state.checkTerms;
  },
  getCanvasId(state) {
    return state.userData && state.userData.student ? state.userData.student.lmsId : null
  },
  getStudentCeo(state) {
    return state.userData ? state.userData.ceo : null
  },
  getNotificationsKey(state) {
    return state.notificationsKey
  },
  isUserGestor(state) {
    return state.isGestor;
  },
  getUserCeos(state) {
    return state.loggedUserData.ceos;
  },
  getLoadingState(state) {
    return {
      status: state.loading.counter > 0,
      drawer: state.loading.drawer
    };
  },
  getMpConfig(state) {
    return state.mpConfig
  },
  getUserRoles (state) {
    const roles = state.loggedUserData.roles?.map(rol => {
      return {
        id: rol.id,
        name: rol.name,
        description: rol.description,
        rolType: rol.rolType,
        application: rol.applications
      }
    })
    return roles;
  },
  getProp: () => (keypath, object) => {
    keypath = keypath.split('.');
    return keypath.reduce((obj, key) => (obj && obj[key]) ? obj[key] : null, object);
  },

  getApplicationModules (state) {
    const {loggedUserData} = state;
    const app = Configuration.value('app') || 'portaladministrativo'
    const applications = [];
    const getSubModulesPermissions = (children, loadedChildren = []) => {
      if (loadedChildren.length) {
        children.forEach(child => {
          if (child.permissions) {
            const modPermission = child.permissions.find(item => {return item.name == 'READ' && item.status})
            if (child.status && modPermission) {
              const exists = loadedChildren.find(item => {return item.name == child.name})
              if (!exists) {
                loadedChildren.push({
                  status: child.status,
                  code: child.code,
                  name: child.name,
                  path: child.path,
                  order: child.order,
                  imagePath: child.imagePath,
                  children: []
                })
              }
            }
          } else console.warn(`El modulo "${child.name}" no tiene permiso de lectura asociado`)  // eslint-disable-line
        });
        return loadedChildren
      } else {
        if (children) {
          children.forEach(subModule => {
            if (subModule.permissions) {
              const modPermission = subModule.permissions.find(item => {return item.name == 'READ' && item.status})
              if (subModule.status && modPermission) {
                loadedChildren.push({
                  status: subModule.status,
                  code: subModule.code,
                  name: subModule.name,
                  path: subModule.path,
                  order: subModule.order,
                  imagePath: subModule.imagePath,
                  children: []
                })
              }
            } else console.warn(`El submodulo "${subModule.name}" no tiene permiso de lectura asociado`)  // eslint-disable-line
          })
        }
        return loadedChildren
      }
    }

    if (loggedUserData.roles) {
      loggedUserData.roles.forEach(role => {

        if(!Array.isArray(role.applications)) return;
        role.applications.forEach(application => {
          if ((application.name == 'Administrativo' && app == 'portaladministrativo') ||
              (application.name == 'Comisiones' && app == 'comisiones') ||
              (application.name == 'Alumno' && app == 'portalalumno')) {

            if(!Array.isArray(application.modules)) return;
            application.modules.forEach(module => {
              const loadedModule = applications.find((item) => {return item.name == module.name})
              if (loadedModule) {
                // Modulo ya cargado por otro rol
                loadedModule.children = getSubModulesPermissions(module.children, loadedModule.children)
              } else if (module.id !== 86) {
                if (module.permissions) {
                  const modPermission = module.permissions.find(item => {return item.name == 'READ' && item.status})
                  if (module.status && modPermission) {
                    // Modulo no cargado
                    const moduleData = {
                      status: module.status,
                      code: module.code,
                      name: module.name,
                      path: module.path,
                      order: module.order,
                      imagePath: module.imagePath,
                      children: getSubModulesPermissions(module.children)
                    };
                    applications.push(moduleData);
                  }
                } else console.warn(`El modulo "${module.name}" no tiene permiso de lectura asociado`)  // eslint-disable-line
              }
            });
          }
        });
      });
    }

    return applications;
  },

  getPermissions (state) {
    const {loggedUserData} = state;
    const app = Configuration.value('app') || 'portaladministrativo'
    const permissions = [];

    if (loggedUserData.roles && Array.isArray(loggedUserData.roles)) {
      loggedUserData.roles.forEach(role => {

        if(!Array.isArray(role.applications)) return;

        role.applications.forEach(application => {
          if ((application.name == 'Administrativo' && app == 'portaladministrativo') ||
              (application.name == 'Comisiones' && app == 'comisiones') ||
              (application.name == 'Alumno' && app == 'portalalumno')) {

            if(!Array.isArray(application.modules)) return;

            application.modules.forEach(module => {
              let readPermission = null
              if (module.permissions) {
                readPermission = module.permissions.find(permission=>{
                  return permission.name === 'READ' && permission.status
                })
              } else {
                console && console.warn && console.warn(`El modulo "${module.name}" no tiene permiso de lectura asociado`)  // eslint-disable-line
              }
              const moduleData = {
                name: module.name,
                permission: module.status && readPermission,
                children: []
              };

              if(module.children && module.children.length) {
                module.children.forEach(subModule => {
                  moduleData.children.push({
                    name: subModule.name,
                    permission: subModule.status
                  });
                });
              }

              permissions.push(moduleData);
            });
          }
        });
      });
    }

    return permissions;
  },

  getCRUDPermissions(state) {
    return state.permissions
  },

  getDuesAccountPayload(state) {
      return { studentCareer: `${state.userData.academicRegister}`, user: state.userData.student.id, terms: [] }
  },
  getContractPendingValidation(state){
    return state.contractPendingValidation
  }
};
