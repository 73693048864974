<template>
  <div>
    <v-container fluid class="d-flex pa-0 fullHeight">
      <v-container class="container-custom px-6" fluid>
        <Breadcrumbs :title="$t('modules.settings.student.breadcrumbs.title')"
          :description="$t('modules.settings.student.breadcrumbs.description')" />
        <v-row justify="center">
          <v-col sm="12" class="viewSpaces">
            <SuperTable :headers="headers" :students="students" @updateStudent="updateStudent" />
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import SuperTable from '../Components/SuperTable/SuperTable'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
import { $settingsStudents } from '../services'

export default {
  name: 'SettingsStudent',
  components: {
    SuperTable,
    Breadcrumbs,
  },
  data() {
    return {
      loading: false,
      success: false,
      error: false,
      headers: [
        {
          text: this.$t('modules.settings.student.table.headers.description'),
          value: 'description',
        },
        {
          text: this.$t('modules.settings.student.table.headers.settingsValue'),
          value: 'value',
        },
        {
          text: '',
          value: 'action',
          sortable: false,
        },
      ],
      students: [],
    };
  },

  computed: {
    sortedStudents() {
      return [...this.students].sort((a, b) => a.id - b.id);
    },
    canUpdate() {
      return this.$permissions.settings.students.UPDATE
    },

    permissions() {
      return {
        students: { update: this.canUpdate },
      };
    },
  },
  mounted() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      try {
        this.loading = true;
        const response = await $settingsStudents.find();

        if (Array.isArray(response.data)) {
          this.students = response.data.sort((a, b) => a.id - b.id);
        }
      } catch (error) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },

    updateStudent(id) {
      if (this.canUpdate) {
        this.$router.push(`/settings/students/update/${id}`);
      }
    },
  },
};
</script>
