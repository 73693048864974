
import DashboardRoute from './Dashboard/Routes';
import HomeRoute from './Home/Routes';
import LiquidationsRoute from './Liquidations/Routes';
import StudentsRoute from './Students/Routes';

const CommissionsRoute = [
    DashboardRoute,
    HomeRoute,
    LiquidationsRoute,
    StudentsRoute
]

export default CommissionsRoute
