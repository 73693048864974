<template>
  <v-sheet class="appBar">
    <v-app-bar elevation="0" height="64" class="appBar__component">
      <v-btn icon class="px-4 ml-0 mr-3" v-if="$isMobile" @click="$emit('toggle-nav')">
        <div class="hamburger-icon" :class="{open: drawerOpen}">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </v-btn>
      <img width="auto" height="32" :src="logoPath" />
      <div v-if="studentData && !$isMobile" class="mt-1 ml-5">
        <h4 v-if="studentData.career" class="primary--text" style="font-size: 1rem; line-height: 1rem; font-weight: 500;">{{ studentData.career.description }}</h4>
        <span v-if="studentData.careerStatus" class="caption">{{ studentData.careerStatus.meaning }}</span>
      </div>
      <div class="flex-grow-1"></div>

      <div class="searchStudentInput mx-5" v-if="$isPortalAlumno && $isGestor">
        <v-text-field
          class="ma-1"
          v-model="search"
          placeholder="Buscar alumno..."
          prepend-inner-icon="mdi-magnify"
          rounded
          outlined
          dense
          hide-details
          clearable
          @click:clear="clearStudent"
          @keyup.enter="getStudent(true)"
        ></v-text-field>
      </div>

      <Notifications></Notifications>

      <v-btn v-if="showNavigationDrawerButton" icon @click.stop="navigationDrawerApps.open = !navigationDrawerApps.open" class="mr-4">
        <v-icon>mdi-apps</v-icon>
      </v-btn>

      <v-avatar
        color="#f5f5f5"
        size="32"
        class="cursor-pointer"
        @click.stop="profileDropdown.open = !profileDropdown.open"
      >
        <img :src="userData.avatar" />
      </v-avatar>
    </v-app-bar>
    <v-progress-linear absolute color="primary" indeterminate v-if="loading.status && !loading.drawer"></v-progress-linear>

    <NavigationDrawerApps v-if="userDataRoles.length > 0" :navigationDrawerApps="navigationDrawerApps" :userDataRoles="userDataRoles" @update:shouldShowDrawer="showNavigationDrawerButton = $event"></NavigationDrawerApps>
    <ProfileDropdown :profileDropdown="profileDropdown"></ProfileDropdown>
  </v-sheet>
</template>

<style lang="sass">
    .appBar
        z-index: 5
        position: relative
        .v-overlay
            .v-overlay__scrim
                background: transparent !important
        &__component
            border-bottom: 1px solid #ddd !important

        .searchStudentInput
            max-widht: 300px
</style>

<script>
import NavigationDrawerApps from './NavigationDrawerApps'
import ProfileDropdown from './ProfileDropdown'
import Notifications from './Components/Notifications'


import { mapGetters } from 'vuex'

export default {
  name: 'AppBar',
  props: {
    drawerOpen: { type: Boolean, default: false },
  },
  components: {
    NavigationDrawerApps,
    ProfileDropdown,
    Notifications,
  },
  data() {
    return {
      search: '',
      showNavigationDrawerButton: false,
      navigationDrawerApps: {
        open: false,
        title: this.$t('components.appBar.navigationDrawerApps.title'),
      },
      profileDropdown: {
        open: false,
      },
    };
  },
  mounted() {
    
    const student = JSON.parse(localStorage.getItem('alumnoSeleccionado'));
    if (student) {
      this.search = `${student.userData.username} -- ${student.userData.name}`
      this.getStudent(false)
    }
  },
  methods: {
    async clearStudent() {
      this.search = ''
      await this.$store.dispatch('commons/setStudentData', null);
      window.location.reload()
    },

    async getStudent(reload) {
      if (this.$isGestor) {
        let data = null
        if (this.search != '') data = this.search.toLowerCase().split(' ')[0]   // eslint-disable-line
        try {
          await this.$store.dispatch('commons/setStudentData', data.toLowerCase());
          if (reload) window.location.reload()
        } catch (error) {
          this.$store.dispatch('commons/openPopUp', {
            title: this.$t('alert'),
            content: [{ value: this.$t('components.appBar.no_student', {identification: data}) }],
            actionPrimary: { text: this.$t('actions.accept'), callback() {} },
            icon: { color: 'warning', name: 'mdi-alert' },
            color: 'primary',
          });
          throw error
        }
      }
    },
    logout() {
      this.$auth.logout();
    },
  },
  computed: {
    loading() {
      return this['commons/getLoadingState']
    },
    studentData() {
      return this['commons/getStudentData']
    },
    userData() {
      return this['commons/getStudentUserData'] ? this['commons/getStudentUserData'] : this['commons/getUserData']
    },
    logoPath() {
      return this['commons/getLogoImageUrl']
    },
    userDataRoles() {
      const roles = this['commons/getUserRoles'];
      return Array.isArray(roles) ? roles : [];
    },
    ...mapGetters([
      'commons/getStudentUserData',
      'commons/getUserData',
      'commons/getLoadingState',
      'commons/getStudentData',
      'commons/getLogoImageUrl',
      'commons/getUserRoles'
    ])
  },
}
</script>

<style lang="sass" scoped>
  .hamburger-icon
    //width: 60px
    //height: 45px
    width: 18px
    height: 12px
    position: relative
    //margin: 50px auto
    -webkit-transform: rotate(0deg)
    -moz-transform: rotate(0deg)
    -o-transform: rotate(0deg)
    transform: rotate(0deg)
    -webkit-transition: .5s ease-in-out
    -moz-transition: .5s ease-in-out
    -o-transition: .5s ease-in-out
    transition: .5s ease-in-out
    cursor: pointer

    span
      display: block
      position: absolute
      height: 2px
      width: 100%
      background: #707070
      border-radius: 9px
      opacity: 1
      left: 0
      -webkit-transform: rotate(0deg)
      -moz-transform: rotate(0deg)
      -o-transform: rotate(0deg)
      transform: rotate(0deg)
      -webkit-transition: .25s ease-in-out
      -moz-transition: .25s ease-in-out
      -o-transition: .25s ease-in-out
      transition: .25s ease-in-out

      &:nth-child(1)
        top: 0px

      &:nth-child(2),
      &:nth-child(3)
        top: 5px

      &:nth-child(4)
        top: 10px

    &.open
      span:nth-child(1)
        top: 5px
        width: 0%
        left: 50%

      span:nth-child(2)
        -webkit-transform: rotate(45deg)
        -moz-transform: rotate(45deg)
        -o-transform: rotate(45deg)
        transform: rotate(45deg)

      span:nth-child(3)
        -webkit-transform: rotate(-45deg)
        -moz-transform: rotate(-45deg)
        -o-transform: rotate(-45deg)
        transform: rotate(-45deg)

      span:nth-child(4)
        top: 5px
        width: 0%
        left: 50%

</style>
