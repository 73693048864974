
<template>
    <v-container fluid class="d-flex pa-0 fullHeight">
        <AsideBar
            :title="$t('modules.comisiones.students.asideBar.title')"
            :items="asidebar.items"
            @changed="changed"
        ></AsideBar>
        <v-container fluid class="scrollableContent pa-0">
            <v-container class="px-6">
                <Breadcrumbs
                    :title="$t('modules.comisiones.students.table.breadcrumbs.title')"
                    :description="$t('modules.comisiones.students.table.breadcrumbs.description')"
                />
                <v-row>
                    <v-col sm="5" class="pt-5 pb-0">
                        <v-autocomplete
                            v-model="selectedTerm"
                            :items="periods"
                            :label="$t('modules.comisiones.students.table.period')"
                            class="my-2"
                            item-text="description"
                            item-value="id"
                            outlined
                            @change="getStudents"
                        ></v-autocomplete>
                    </v-col>
                    <v-col sm="12" class="viewSpaces">
                        <SuperTable
                            :headers="headers"
                            :students="students"
                            :page.sync="pagination.page"
                            :search.sync="search"
                            :totalElements="pagination.totalItems"
                            :itemsPerPage.sync="pagination.itemsPerPage"
                            :contactStatusTypes="contactStatusTypes"
                            @fetchData="getStudents"
                            @updateStudentStatus="updateStudentStatus"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </v-container>
    </v-container>
</template>
<script>
    import SuperTable from '../Components/SuperTable/SuperTable'
    import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
    import AsideBar from '@/components/AsideBar/AsideBar';
    import { $terms, $salesBySeller, $sellers, $careers, $contact, $types } from '../Services';
    import { mapGetters } from 'vuex'

    export default {
        name: 'StudentsView',
        components: {
            SuperTable,
            Breadcrumbs,
            AsideBar,
        },
        data() {
            return {
                periods: [],
                students: [],
                contactStatusTypes: [],
                selectedTerm: null,
                search: null,
                pagination: {
                    page: 1,
                    totalItems: 0,
                    itemsPerPage: 20,
                },
                asidebar: {
                    items: [
                        {
                            id: 'sellerId',
                            name: this.$t('modules.comisiones.students.asideBar.agent'),
                            filters: [
                                {
                                    name: 'Agente',
                                    clearable: true,
                                    items: [],
                                    value: null,
                                    type: 'autocomplete',
                                }
                            ]
                        }, {
                            id: 'careerId',
                            name: this.$t('modules.comisiones.students.asideBar.career'),
                            filters: [
                                {
                                    name: 'Carrera',
                                    clearable: true,
                                    items: [],
                                    value: null,
                                    type: 'autocomplete',
                                }
                            ]
                        // }, {
                        //     id: 'isNI',
                        //     name: this.$t('modules.comisiones.students.asideBar.type'),
                        //     filters: [
                        //         {
                        //             type: 'radio',
                        //             value: false,
                        //             radios: [
                        //                 { name: 'NI', value: true },
                        //                 { name: 'RI', value: false },
                        //             ],
                        //         }
                        //     ]
                        }, {
                            id: 'paid',
                            name: this.$t('modules.comisiones.students.asideBar.state'),
                            filters: [
                                { id: 'paid', name: 'Pagó', type: 'check', value: false },
                                { id: 'notPaid', name: 'No pagó', type: 'check', value: true }
                            ]
                        }, {
                            id: 'status',
                            name: this.$t('modules.comisiones.students.asideBar.status'),
                            filters: [
                                {
                                    name: 'Estado',
                                    clearable: true,
                                    items: [],
                                    value: null,
                                    type: 'autocomplete',
                                }
                            ]
                        },
                    ]
                },
                headers: [
                    { text: this.$t('modules.comisiones.students.table.headers.identification'), value: 'studentDni' },
                    { text: this.$t('modules.comisiones.students.table.headers.name'), value: 'completeName' },
                    { text: this.$t('modules.comisiones.students.table.headers.phone'), value: 'phone' },
                    { text: this.$t('modules.comisiones.students.table.headers.email'), value: 'email' },
                    { text: this.$t('modules.comisiones.students.table.headers.career'), value: 'careerName' },
                    { text: this.$t('modules.comisiones.students.table.headers.period'), value: 'lastPaidTerm', align: 'center' },
                    { text: this.$t('modules.comisiones.students.table.headers.academicAdvancement'), value: 'academicAdvancement', align: 'center' },
                    { text: this.$t('modules.comisiones.students.table.headers.status'), value: 'status', align: 'center' },
                    { text: 'Pagó', value: 'paid' }
                ],
            }
        },
        computed: {
            ...mapGetters({
                userRoles: 'commons/getUserRoles',
                loggedUser: 'commons/getUserID'
            }),
            isAdmin(){
                return !!this.userRoles.find(rol => rol.rolType.meaning == 'Administrador');
            },
        },
        async mounted() {
            await this.getPeriods()
            await this.getSellersCode()
            await this.getContactTypes()
            this.getCareersCode()
        },
        methods: {
            changed(index, i, value) {
                this.asidebar.items[index].filters[i].value = value
                this.getStudents();
            },
            async getStudents() {
                const seller = this.asidebar.items.find(item => item.id == 'sellerId')
                if (!this.selectedTerm || (!this.isAdmin && !seller.filters[0].value)) return false

                try {
                    const body = { userId: this.loggedUser, termId: this.selectedTerm, search: this.search, isNI: false }
                    this.asidebar.items.forEach(filter => {
                        if (filter.id == 'paid') {
                            const selectedValues = filter.filters.filter(f => f.value)
                            if (selectedValues.length == 1) body.paid = selectedValues[0].id
                        } else if (filter.filters[0].value) body[filter.id] = filter.filters[0].value
                    })

                    const { data } = await $salesBySeller.filter(body, { params: { page: this.pagination.page - 1, length: this.pagination.itemsPerPage }});
                    const sinGestion = this.contactStatusTypes.find(status => status.text == 'Sin gestión')
                    this.students = data.content.map(student => {
                        if (!student.status && sinGestion) student.status = sinGestion.value
                        return student
                    })

                    this.pagination.totalItems = data.totalElements
                } catch (error) {
                    this.students = []
                    this.pagination.page = 1
                    this.pagination.pagesAmount = 1
                    this.pagination.totalItems = 0
                    throw error
                }
            },
            async getPeriods() {
                try {
                    const { data } = await $terms.find(null, null, { params: { page: 0, length: 10, orderBy: 'classStartDate', orientation: 'desc', termType: 193 }})
                    this.periods = data.content.filter(period => period.id >= 6771)
                } catch (error) {
                    this.periods = []
                    throw error
                }
            },
            async getSellersCode() {
                const response = await $sellers.find('users', null, { params: { user: this.loggedUser, status: true }})
                const sellerFilter = this.asidebar.items.find(item => item.id == 'sellerId')
                const sellers = response.data.map(seller => ({ text: `${seller.sellerCode} - ${seller.sellerName}`, value: seller.id }))

                sellerFilter.filters[0].items = sellers
                if (sellers.length == 1) sellerFilter.filters[0].value = sellers[0].value
            },
            async getCareersCode() {
                const { data } = await $careers.find('list', null, { params: { page: 0, length: 500, orderBy: 'description', orientation: 'asc', status: true } })
                const careerFilter = this.asidebar.items.find(item => item.id == 'careerId')
                careerFilter.filters[0].items = data.content.map(career => ({ text: career.name, value: career.id }))
            },
            async getContactTypes() {
                const { data } = await $types.find(null, null, { params: { type: 'CONTACT_STATUS_TYPE' } })
                const statusFilter = this.asidebar.items.find(item => item.id == 'status')
                const contactStatusList = data.map(type => ({ value: type.value, text: type.meaning }))

                this.contactStatusTypes = contactStatusList
                statusFilter.filters[0].items = contactStatusList
            },
            async updateStudentStatus(student, selectedState){
                try {
                    await $contact.update(student.studentCareerId, null, { params: { term: student.term.id, status: selectedState }})
                } catch (error) {
                    this.$store.dispatch('commons/openPopUp', {
                        title: this.$t('modules.comisiones.students.managementStatusPopup.error.title'),
                        actionPrimary: { text: this.$t('actions.accept'), callback(){} },
                        icon: { color: 'error', name: 'mdi-close' },
                        color: 'primary',
                    });
                } finally {
                    this.getStudents()
                }
            },
        },
    }
</script>
