<template>
  <v-container fluid class="pt-0">
    <v-container fluid class="container-custom">
      <v-row no-gutters justify="center">
        <v-col sm="10">
          <Breadcrumbs :title="$t('modules.settings.pricing.breadcrumbs.title')"
            :description="$t('modules.settings.pricing.breadcrumbs.description')"></Breadcrumbs>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col sm="10" class="viewSpaces">
          <template>
            <OutlinedCard :section="$t('modules.settings.pricing.sectionPricing.title')" class="mb-6">
              <OutlinedCardContent :label="$t('modules.settings.pricing.sectionPricing.dues')" sm="4">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-switch v-bind="attrs" v-on="on" class="v-switch" v-model="form.dues"
                      @change="changeValue('dues', form.dues)"></v-switch>
                  </template>
                  <span v-if="form.dues">
                    {{ $t('modules.settings.pricing.sectionPricing.disable') }}
                  </span>
                  <span v-else>
                    {{ $t('modules.settings.pricing.sectionPricing.enable') }}
                  </span>
                </v-tooltip>
              </OutlinedCardContent>

              <OutlinedCardContent :label="$t('modules.settings.pricing.sectionPricing.countDues')">
                <v-text-field v-model="form.countDues" type="number" min="1" step="1"
                  :rules="[v => !!v || $t('modules.settings.pricing.validations.sectionSecurity.countDuesRequired')]"
                  @change="changeValue('countDues', form.countDues)" outlined></v-text-field>
              </OutlinedCardContent>

              <OutlinedCardContent :label="$t('modules.settings.pricing.sectionPricing.preSale')">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-switch v-bind="attrs" v-on="on" class="v-switch" v-model="form.preSale"
                      @change="changeValue('preSale', form.preSale)"></v-switch>
                  </template>
                  <span v-if="form.preSale">
                    {{ $t('modules.settings.pricing.sectionPricing.disable') }}
                  </span>
                  <span v-else>
                    {{ $t('modules.settings.pricing.sectionPricing.enable') }}
                  </span>
                </v-tooltip>
              </OutlinedCardContent>

              <OutlinedCardContent :label="$t('modules.settings.pricing.sectionPricing.halfTicket')">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-switch v-bind="attrs" v-on="on" class="v-switch" v-model="form.halfTicket"
                      @change="changeValue('halfTicket', form.halfTicket)"></v-switch>
                  </template>
                  <span v-if="form.halfTicket">
                    {{ $t('modules.settings.pricing.sectionPricing.disable') }}
                  </span>
                  <span v-else>
                    {{ $t('modules.settings.pricing.sectionPricing.enable') }}
                  </span> </v-tooltip>
              </OutlinedCardContent>

              <OutlinedCardContent :label="$t('modules.settings.pricing.sectionPricing.contract')">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-switch v-bind="attrs" v-on="on" class="v-switch" v-model="form.contract"
                      @change="changeValue('contract', form.contract)"></v-switch>
                  </template>
                  <span v-if="form.contract">
                    {{ $t('modules.settings.pricing.sectionPricing.disable') }}
                  </span>
                  <span v-else>
                    {{ $t('modules.settings.pricing.sectionPricing.enable') }}
                  </span> </v-tooltip>
              </OutlinedCardContent>

              <OutlinedCardContent :label="$t('modules.settings.pricing.sectionPricing.adaptationInsurance')">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-switch v-bind="attrs" v-on="on" class="v-switch" v-model="form.adaptationInsurance"
                      @change="changeValue('adaptationInsurance', form.adaptationInsurance)"></v-switch>
                  </template>
                  <span v-if="form.adaptationInsurance">
                    {{ $t('modules.settings.pricing.sectionPricing.disable') }}
                  </span>
                  <span v-else>
                    {{ $t('modules.settings.pricing.sectionPricing.enable') }}
                  </span>
                </v-tooltip>
              </OutlinedCardContent>

              <OutlinedCardContent :label="$t('modules.settings.pricing.sectionPricing.tariff')">
                <v-text-field v-model="form.tariff" type="number" min="1" step="1"
                  :rules="[v => !!v || $t('modules.settings.pricing.validations.sectionSecurity.tariffRequired')]"
                  @change="changeValue('tariff', form.tariff)" outlined></v-text-field>
              </OutlinedCardContent>

              <OutlinedCardContent :label="$t('modules.settings.pricing.sectionPricing.status')">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-switch v-bind="attrs" v-on="on" class="v-switch" v-model="form.status"
                      @change="changeValue('status', form.status)"></v-switch>
                  </template>
                  <span v-if="form.status">
                    {{ $t('modules.settings.pricing.sectionPricing.disable') }}
                  </span>
                  <span v-else>
                    {{ $t('modules.settings.pricing.sectionPricing.enable') }}
                  </span>
                </v-tooltip>
              </OutlinedCardContent>

              <OutlinedCardContent :label="$t('modules.settings.pricing.sectionPricing.repayment')">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-switch v-bind="attrs" v-on="on" class="v-switch" v-model="form.repayment"
                      @change="changeValue('repayment', form.repayment)"></v-switch>
                  </template>
                  <span v-if="form.repayment">
                    {{ $t('modules.settings.pricing.sectionPricing.disable') }}
                  </span>
                  <span v-else>
                    {{ $t('modules.settings.pricing.sectionPricing.enable') }}
                  </span>
                </v-tooltip>
              </OutlinedCardContent>

              <OutlinedCardContent :label="$t('modules.settings.pricing.sectionPricing.expirationDay')">
                <v-text-field v-model="form.expirationDay" type="number" min="1" step="1"
                  :rules="[v => !!v || $t('modules.settings.pricing.validations.sectionSecurity.expirationDayRequired')]"
                  @change="changeValue('expirationDay', form.expirationDay)" outlined></v-text-field>
              </OutlinedCardContent>

            </OutlinedCard>

            <div class="text-sm-right mt-4">
              <Button :loading="loading" :success="success" :error="error" :text="$t('actions.save')"
                :successText="$t('actions.saved')" :errorText="$t('actions.error')" @clicked="fetchDataUpdate"
                @end="resetButtonValues" :disabled="isSaveButtonDisabled"></Button>
            </div>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import OutlinedCardContent from '@/components/OutlinedCardContent/OutlinedCardContent';
import Button from '@/components/Button/Button';
import { $settingsPricing } from '../services';

export default {
  components: {
    Breadcrumbs,
    OutlinedCard,
    OutlinedCardContent,
    Button
  },
  data() {
    return {
      loading: false,
      success: false,
      error: false,
      form: {
        dues: false,
        countDues: null,
        preSale: false,
        halfTicket: false,
        contract: false,
        adaptationInsurance: false,
        tariff: null,
        status: true,
        repayment: false,
        expirationDay: null,
      },
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true;
        const { data } = await $settingsPricing.find();
        this.form = {
          dues: data.dues,
          countDues: data.countDues,
          preSale: data.preSale,
          halfTicket: data.halfTicket,
          contract: data.contract,
          adaptationInsurance: data.adaptationInsurance,
          tariff: data.tariff,
          status: data.status,
          repayment: data.repayment,
          expirationDay: data.expirationDay,
        };
      } catch (error) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
    async fetchDataUpdate() {
      try {
        this.loading = true;
        const updatedData = {
          dues: this.form.dues,
          countDues: this.form.countDues,
          preSale: this.form.preSale,
          halfTicket: this.form.halfTicket,
          contract: this.form.contract,
          adaptationInsurance: this.form.adaptationInsurance,
          tariff: this.form.tariff,
          status: this.form.status,
          repayment: this.form.repayment,
          expirationDay: this.form.expirationDay,
        };

        await $settingsPricing.update(this.$route.params.id, updatedData, null);
        this.success = true;
      } catch (error) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
    resetButtonValues() {
      this.loading = false;
      this.success = false;
      this.error = false;
    },
    changeValue(key, value) {
      this.form[key] = value;
    },
  },
  computed: {
    isSaveButtonDisabled() {
      return !this.form.countDues || !this.form.tariff || !this.form.expirationDay;
    },
  },
};
</script>