<template>
    <div class="welcome d-flex justify-space-between pa-6">
        <div class="welcome--left">
            <span class="welcome--headline font-weight-medium" v-html="$t('modules.portalalumno.dashboard.banners.welcome.greeting', { studentName: $options.filters.$capitalizeAll(studentUserData.name) })"></span>
            <p class="mt-2">{{ $t('modules.portalalumno.dashboard.banners.welcome.message') }}</p>
            <!-- <img v-if="isCountry" :src="require('@/assets/dashboard-assets/onmex/micelaneas.png')" alt="misceláneas"/> -->
        </div>
        <div class="welcome--right">
            <img :src="require('@/assets/dashboard-assets/onmex/welcome-banner.png')" alt="welcome"/>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'WelcomeBanner',
        data() {
            return {}
        },
        computed: {
            ...mapGetters({
                studentUserData: 'commons/getStudentUserData',
            }),
            isCountry(){
                return this.$country === 'mexico' ? true : false
            }
        }
    }
</script>

<style lang="sass" scoped>
    /* Uses cases */
    .welcome
        background: var(--v-secondary-base)
        border-radius: 5px
        width: 100%
        height: 200px
        color: var(--v-secondary-lighten5)
        font-size: 16px
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08)
        &--headline
            font-size: 35px
        &--left
            width: 65%
        &--right
            width: 35%
            img
                position: relative
                botom:0
                height: auto
                max-width: 100%
  
    @media only screen and (max-width: 600px) 
        .welcome
            &--left
                width: 100%
            &--right
                display: none
</style>