
const extraBenefitTranslation = {
  es: {
    table: {
      breadcrumbs:{
        title: 'Configuracion Beneficio Extra',
        description:'Administracion Beneficio Extra'
      },
      headers: {
        description: 'Nombre',
        code: 'Código',
        status: {
          title:'Estado',
          actives: 'Activos',
          inactives: 'Inactivos'
        },
        nameCareer: 'Nombre de carrera',
        zones: 'Zonas',
        studentType:  {
          title:'Tipo de Estudiante',
          ni: 'Ingresante',
          ri: 'Reingresante',
        },
        percentage: 'Porcentaje',
        scholarship: 'Beca',
        average: 'Promedio',
      },
      actions: {
        errorDelete: 'Error al eliminar',
        bulkDelete: 'Eliminar beneficio extra',
      },
      messages: {
        bulkDeleteError: 'No se pudieron eliminar los beneficios extra seleccionados',
        deleteError: 'No se pudo eliminar el beneficio extra seleccionado',
      },

    },
    create: {
      breadcrumbs:{
        title: 'Nuevo Beneficio Extra',
        description:'Crea un nuevo Beneficio Extra'
      },
      studentSection: {
        title: 'Tipo de estudiantes',
      },
      codeSection:{
        title:'Datos del beneficio extra',
        subtitle:'Asigna la información básica del beneficio extra que estás creando. Recuerda que estos datos deben ser lo más descriptivos posible ya que serán utilizados para futuros filtros de búsqueda.',
        labels: {
          name: 'Nombre',
          code: 'Código'
        }
      },
      termSection: {
        title: 'Periodos',
        subtitle: 'Selecciona en esta sección los periodos donde este beneficio extra puede ser aplicado',
        transferList: {
          firstTitle: 'Disponibles',
          secondTitle: 'Seleccionados'
        }
      },
      dayRangeSection: {
        title: 'Días y porcentajes',
        labels: {
          percentageBimester: 'Bimestre',
          percentageSemester: 'Semestre',
          dayFrom: 'Día Inicio',
          dayTo: 'Día Fin'
        }
      },
      zoneSection: {
        title: 'Zonas'
      },
      scholarship: {
        title: 'Becas' ,
        subtitle: 'Selecciona en esta sección las becas donde este beneficio extra puede ser aplicado',
        transferList: {
          firstTitle: 'Disponibles',
          secondTitle: 'Seleccionados'
        }
      },
      benefits: {
        title: 'Beneficios' ,
        subtitle: 'Selecciona en esta sección los beneficios donde este beneficio extra puede ser aplicado',
        transferList: {
          firstTitle: 'Disponibles',
          secondTitle: 'Seleccionados'
        }
      },
      careersSection: {
        title: 'Carreras',
        subtitle: 'Selecciona las carreras a las que quiere asignarles el beneficio extra',
        transferList: {
          firstTitle: 'Disponibles',
          secondTitle: 'Seleccionadas'
        }
      },
      successMessage: {
        title: 'Beneficio extra creado correctamente',
      },
      errorMessage: {
        title: 'Ocurrió un error al crear el beneficio extra',
      }
    },
    update: {
      breadcrumbs: {
        title: 'Editar Beneficio Extra',
        description:'Modifica el beneficio extra seleccionado',
      },
      codeSection: {
        title: 'Datos del beneficio extra',
        subtitle: 'Modifica la información básica del beneficio extra que estás modificando. Recuerda que estos datos deben ser lo más descriptivos posible ya que serán utilizados para futuros filtros de búsqueda.',
        labels: {
          name: 'Nombre',
          code: 'Código'
        },
      },
      studentSection: {
        title: 'Tipo de estudiantes',
      },
      conditionSection: {
        title: 'Aplica por',
        labels: {
          scholarship: 'Beca',
          average: 'Promedio'
        }
      },
      termSection: {
        title: 'Periodos',
        subtitle: 'Selecciona en esta sección los periodos donde este beneficio extra puede ser aplicado',
        transferList: {
          firstTitle: 'Disponibles',
          secondTitle: 'Seleccionados'
        }
      },
      scholarship: {
        title: 'Becas' ,
        subtitle: 'Selecciona en esta sección las becas donde este beneficio extra puede ser aplicado',
        transferList: {
          firstTitle: 'Disponibles',
          secondTitle: 'Seleccionados'
        }
      },
      benefits: {
        title: 'Beneficios' ,
        subtitle: 'Selecciona en esta sección los beneficios donde este beneficio extra puede ser aplicado',
        transferList: {
          firstTitle: 'Disponibles',
          secondTitle: 'Seleccionados'
        }
      },
      dayRangeSection: {
        title: 'Días y porcentajes',
        labels: {
          percentage: 'Porcentaje',
          dayFrom: 'Día Inicio',
          dayTo: 'Día Fin'
        }
      },
      zoneSection: {
        title: 'Zonas'
      },
      careersSection: {
        title: 'Carreras',
        subtitle: 'Selecciona las carreras a las que quiere asignarles el beneficio extra',
        transferList: {
          firstTitle: 'Disponibles',
          secondTitle: 'Seleccionadas'
        }
      },
      successMessage: {
        title: 'Beneficio extra modificado correctamente',
      },
      errorMessage: {
        title: 'Ocurrió un error al modificar el beneficio extra',
      }
    },
    validations: {
      nameRequired: 'Ingresa el nombre del beneficio extra',
      nameAlphanumericWithSpaces: 'Solo alfanuméricos',
      nameMaxLength: 'Máximo 60 caracteres',
      codeRequired: 'Ingresa el código del beneficio extra',
      codeAlphanumeric: 'Solo alfanuméricos',
      codeMaxLength: 'Máximo 10 caracteres',
      codeUnique: 'Código ya existente',
      studentType: 'Debe seleccionar un tipo de estudiante',
      term: 'Seleccione un periodo',
      dayFromRequired: 'Debe ingresar un día de inicio',
      dayToRequired: 'Debe ingresar un día de fin',
      uniqueRange: 'Rango de días ya existente',
      validRange: 'Debe ingresar un rango de días válido',
      betweenValues: 'Debe ser entre 1 y 30',
      bimesterRequired: 'Debe ingresar un porcentaje',
      semesterRequired: 'Debe ingresar un porcentaje',
      percentageBetweenValues: 'Debe ser entre 1 y 100',
      zone: 'Debe seleccionar al menos una zona',
      noSelectedTerms: 'Debes seleccionar al menos un periodo',
      noSelectedCareers: 'Debes seleccionar al menos una carrera',
      noSelectedScholarships:'Debes seleccionar al menos una beca',
      noSelectedBenefits:'Debes seleccionar al menos un beneficio',
    }
  },
  es_CL: {},
  en: {}
}

export default extraBenefitTranslation
