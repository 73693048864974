<template>
  <v-simple-table v-if="contracts.length > 0" class="mt-10">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-center primary--text font-weight-bold">{{
            $t('modules.portalalumno.payments.paymentsContracts.view.contract.contractTable.dataSection.sign_date') }}
          </th>
          <th class="text-center primary--text font-weight-bold">{{
            $t('modules.portalalumno.payments.paymentsContracts.view.contract.contractTable.dataSection.period') }}</th>
          <th class="text-center primary--text font-weight-bold">{{
            $t('modules.portalalumno.payments.paymentsContracts.view.contract.contractTable.dataSection.download') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="contract in contracts" :key="contract.id">
          <td class="text-center">{{ contract.signatureDate }}</td>
          <td class="text-center">{{ contract.termCode }}</td>
          <td class="text-center">
            <v-btn color="primary" rounded icon @click="downloadContract(contract.id)">
              <v-icon>mdi-cloud-download</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { $studentDownloadContract } from '../../Services';
import download from 'downloadjs';

export default {
  props: {
    contracts: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      error: false,
    };
  },
  methods: {
    async downloadContract(id) {
      this.loading = true;
      this.error = false;
      try {
        const response = await $studentDownloadContract.find(id);
        download(response.data, `Contrato_${id}.pdf`);
      } catch (err) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>