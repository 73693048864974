
const proceduresTranslations = {
  es: {
    alert: {
      note: 'Una vez solicitado, podrás revisar el estado de tu trámite en 7 días hábiles.',
      noteSuccess: 'Vuelve a revisar el estado de tu trámite en 7 días hábiles.'
    },
    success_message: {
      title: 'Trámite creado con éxito',
      actions: {
        back: 'Regresar',
        accept: 'Aceptar'
      }
    },
    download: {
      download: 'Descargar',
      breadcrumbs: {
        title: 'Descarga de certificado',
      },
      autocompleteCareer:{
        label:'Carrera'
      },
      autocompleteSubjet:{
        label:'Materia'
      },
      autocompleteExamn:{
        label:'Examen'
      },
      certificateLabels:{
        alumnoRegular:'Certificado de Alumno Regular',
        analitico:'Certificado Analítico',
        matricula:'Certificado de Matrícula',
        planStudy:'Certificado de Plan de Estudio',
        notas:'Certificado de Notas',
        examfinal:'Certificado de Examen final',
        tituloTrami:'Certificado de Título en trámite',
        tituloInterm:'Certificado de Título Intermedio',
        default:'Certificados'
      }
    },
    title: {
      breadcrumbs : {
        title: 'Trámites'
      }
    },
    buttonBack: {
      buttonBack: 'Aceptar'
    },
    successTitle: {
      successTitle: 'Trámite creado con éxito'
    },
    documentation: {
      help: '¿Tienes dudas sobre la documentación a presentar?',
      rules: 'Ver reglamento'
    },
    procedureTNE:{
      title: 'Gestión de tarjeta Nacional Estudiantil',
      description: 'Aquí podrás gestionar la TNE y obtener descuentos en tus pasajes.',
      cardTitle: 'Selecciona el item correspondiente.',
      text:'Si no estás seguro qué trámite debes realizar, verifica el estado de tu TNE haciendo ',
      click:'clic aquí',
      text_2:'Si tienes una TNE de educación superior anterior al 2015 o extraviaste tu TNE debes realizar el trámite directamente con TNE. Revisa la información ',
      aqui:'aquí'
    },
    proceduresInital:{
      breadcrumbs: {
        title: 'Trámites',
        description: 'Gestiona aquí tus trámites y certificados',
      },
      pendingPaymentsTitle: 'Tienes pagos pendientes',
      pendingPaymentsRenewal: 'Para continuar con la rematriculación debes estar al día.',
      pendingPaymentsExamTitle: 'Para solicitar el examen de título, debes estar al día.',
      seeAll: 'Ver todos',
      noHistory: 'Aún no gestionaste trámites',
      proceduresTitle: 'Trámites',
      academicCertificatesTitle: 'Certificados Académicos',
      financeCertificatesTitle: 'Certificados Financieros',
      history: 'Historial',
      procedures: {
        '4': {
          title: 'Alumno regular',
          description: 'Documento que cerifica que es alumno regular en el periodo solicitado y que cumple con lo descrito en el art. 16° del Reglamento Académico.',
          downloadTitle: 'Certificado de Alumno Regular'
        },
        '132': {
          title: 'Materia 0',
          description: 'Documento que cerifica que realizó la Materia 0.',
          downloadTitle: 'Certificado de Asistencia Autoliderazgo'
        },
        '1': {
          title: 'Alumno regular',
          description: 'Documento que cerifica que es alumno regular en el periodo solicitado y que cumple con lo descrito en el art. 16° del Reglamento Académico.',
          downloadTitle: 'Certificado de Alumno Regular'
        },
        // '4': {
        //   title: 'Egreso',
        //   description: 'Documento que certifica que el estudiante ha cumplido con todos los requisitos académicos establecidos en el art. 40° del Reglamento.',
        //   downloadTitle: 'Certificado de Egreso'
        // },
        // '5': {
        //   title: 'Horas / Horas semestre',
        //   description: 'Documento que detalla la cantidad de horas y semestres que tiene de duración un plan de estudios determinado.',
        //   downloadTitle: 'Certificado de Horas'
        // },
        '6': {
          title: 'Matrícula',
          description: 'Documento que certifica que un estudiante se encuentra matriculado en una carrera de IPP.',
          downloadTitle: 'Certificado de Matricula'
        },
        '9': {
          title: 'Plan de estudio',
          description: 'Documento que detalla las asignaturas que componen un plan de estudio/malla.',
          downloadTitle: 'Certificado de Plan de Estudio'
        },
        '10': {
          title: 'Examen Final',
          description: 'Documento certificado de examen final',
          downloadTitle: 'Certificado de Examen final'
        },
        // '10': {
        //   title: 'Notas',
        //   description: 'Documento que detalla las calificaciones obtenidas en las asignaturas cursadas hasta un periodo determinado.',
        //   downloadTitle: 'Certificado de Notas'
        // },
        '11': {
          title: 'Título',
          description: 'Documento que certifica que el estudiante ha cumplido con todos los requisitos académicos establecidos en el art. 50° del Reglamento Académico. Obteniendo Estado Académico de Titulado',
          downloadTitle: 'Certificado Título'
        },
        '12': {
          title: 'Examen final',
          description: 'Constancia de la fecha y materia del examen en el cual rendiste.',
          downloadTitle: 'Certificado Examen final '
        },
        '13': {
          title: 'Título en trámite',
          description: ' Documento que consta que finalizaste el Certificado Intermedio o Título Final.',
          downloadTitle: 'Certificado Título en trámite'
        },
        '14': {
          title: 'Certificado de egreso.',
          description: '',
          downloadTitle: 'Certificado de egreso'
        },
        '5': {
          title: 'Analítico',
          description: 'Detalle del total de materias y su carga horaria correspondiente y por año total.',
          downloadTitle: 'Certificado Analítico Digital'
        },
        '95': {
          title: 'Título en trámite',
          description: 'Detalle del título en trámite',
          downloadTitle: 'Certificado Título en Trámite Digital'
        },
        '94': {
          title: 'Título Intermedio',
          description: 'Detalle del título intermedio',
          downloadTitle: 'Certificado Título intermedio'
        },
        '88': {
          title: 'Certificado',
          description: 'Detalle del certificado de primer año',
          downloadTitle: 'Certificado de primer año'
        },
        '99': {
          title: 'Certificado de asistencia',
          description: 'Certificado de asistencia al taller de autoliderazgo',
          downloadTitle: 'Certificado Taller de autoliderazgo'
        }
      },
      dataSection: {
        procedures: {
          retirament: {
            title: 'Retiro',
            description: 'Procedimiento mediante el cual, un estudiante decide en forma voluntaria Retirarse definitivamente de su carrera actual.',
          },
          careerFreeze: {
            title: 'Congela carrera',
            description: 'Procedimiento mediante el cual un estudiante decide de forma voluntaria realizar una interrupción temporal de sus estudios.',
          },
          reinstatement: {
            title: 'Reincorporación',
            description: 'Procedimiento mediante el cual un estudiante en estado académico congelado, eliminado o retirado, solicita reincorporarse a su plan de estudios.'
          },
          careerChange: {
            title: 'Cambio de carrera',
            description: 'Procedimiento mediante el cual un estudiante de forma voluntaria solicita realizar un cambio a otra carrera dentro de la Oferta de carreras de IPP.'
          },
          procedureCeoChange: {
            title: 'Cambio CSE',
            description: 'Procedimiento mediante el cual un estudiante de forma voluntaria solicita realizar un cambio de ciudad (Centro de Servicio al Estudiante).'
          },
          procedureChange: {
            title: 'Modificación de carga',
            description: 'Es el procedimiento mediante el cual un estudiante de forma voluntaria solicita inscribir o eliminar una asignatura de su Carga Académica.'
          },
          procedureExamCharge: {
            title: 'Examen de título',
            description: 'Es el procedimiento mediante el cual un egresado de forma voluntaria manifiesta intención de rendir su Examen de Título.',
            autocompleteSubjet:{
              label:'Carrera'
            }
          }
        },
        certificatesAcademic: {
          enrollment: {
              title: 'Matrícula',
              description: 'Documento que certifica que un estudiante se encuentra matriculado en una carrera de IPP.',
              downloadTitle: 'Certificado de Matricula'
          },
          regular: {
              title: 'Alumno regular',
              description: 'Documento que cerifica que es alumno regular en el periodo solicitado y que cumple con lo descrito en el art. 16° del Reglamento Académico.',
              downloadTitle: 'Certificado de Alumno Regular'
          },
          egress: {
              title: 'Egreso',
              description: 'Documento que certifica que el estudiante ha cumplido con todos los requisitos académicos establecidos en el art. 40° del Reglamento.',
              downloadTitle: 'Certificado de Egreso'
          },
          notes: {
              title: 'Notas',
              description: 'Documento que detalla las calificaciones obtenidas en las asignaturas cursadas hasta un periodo determinado.',
              downloadTitle: 'Certificado de Notas'
          },
          hours: {
              title: 'Horas / Horas semestre',
              description: 'Documento que detalla la cantidad de horas y semestres que tiene de duración un plan de estudios determinado.',
              downloadTitle: 'Certificado de Horas'
          },
          subjectsPlan: {
              title: 'Asignaturas',
              description: 'Declara los resultados de aprendizaje que el estudiante adquiere en el desarollo de cada asignatura. Sólo accederá a las asignaturas aprobadas.',
              downloadTitle: 'Certificado de Asignaturas'
          },
          studyPlan: {
              title: 'Plan de estudio',
              description: 'Documento que detalla las asignaturas que componen un plan de estudio/malla.',
              downloadTitle: 'Certificado de Plan de Estudio'
          },
          diploma: {
              title: 'Titulo',
              description: 'Documento que certifica que el estudiante ha cumplido con todos los requisitos académicos establecidos en el art. 50° del Reglamento Académico. Obteniendo Estado Académico de Titulado',
              downloadTitle: 'Título Digital'
          },
          analitic: {
              title: 'Analitico',
              description: 'Documento que certifica que el estudiante ha cumplido con todos los requisitos académicos establecidos en el art. 50° del Reglamento Académico. Obteniendo Estado Académico de Titulado',
              downloadTitle: 'Título Digital'
          },
          pending: {
              title: 'Titulo en tramite',
              description: 'Documento que consta que finalizaste el Certificado Intermedio o Título FInal.',
              downloadTitle: 'Título en tramite'
          },
          final: {
              title: 'Examen final',
              description: 'Constancia de la fecha y materia del examen en el cual rendiste.',
              downloadTitle: 'Examen final'
          },
          studyPlanHour: {
              title: 'Plan de estudio y carga horaria',
              description: 'Detalle del total de materias y su carga horaria correspondiente y por año total.',
              downloadTitle: 'Plan de estudio y carga horaria'
          },
          legalizedProgram: {
              title: 'Programas legalizados',
              description: 'Detalle del programa de las materias aprobadas, con la carga horaria..',
              downloadTitle: 'Plan de estudio y carga horaria'
          },
          intermidiateTitle: {
              title: 'Titulo intermedio',
              description: 'Detalle del titulo intermedio',
              downloadTitle: 'Certificado de titulo intermedio'
          },
        },
        certificatesFinance: {
          debt: {
              title: 'Deuda',
              description: 'Documento que indica la situación financiera con IPP, puede ser con o sin deuda. Si es con deuda se detallan las cuotas pendientes de pago.',
              downloadTitle: 'Certificado de Deuda'
          },
          debtCAE: {
              title: 'Deuda CAE',
              description: 'Documento que indica la situación financiera con IPP respecto al crédito CAE, generalmente por garantías pagadas a los bancos.',
              downloadTitle: 'Certificado de Deuda CAE'
          },
          payments: {
              title: 'Pagos',
              description: 'Documento que indica el detalle de los pagos en un determinado periodo.',
              downloadTitle: 'Certificado de Pagos'
          },
          price: {
              title: 'Aranceles',
              description: 'Documento que indica el detalle de los valores de arancel y matrícula.',
              downloadTitle: 'Certificado de Arancel'
          }
        }
      }
    },
    reEnrollment: {
      breadcrumb: {
        title: 'Rematriculación',
      },
      header: {
        title: 'Rematriculacion: Periodo',
        description: 'Estas por comenzar el proceso de rematriculación y te guiaremos para que realices la simulación de pago, luego si estas de acuerdo puedes confirmar la simulación y realizar el pago.'
      },
      itemsToPay: {
        description: 'Estos son los ítems que deberás pagar, selecciona cada uno.'
      },
      applyDiscount: {
        title: 'Aplica descuento',
      },
      createError: 'No pudo crearse la intención de pago de la matrícula.',
      createSuccess: 'Matricula confirmada correctamente',
      createSuccessButtonText: 'Continuar trámite',
    },
    reEnrollmentStatus: {
      breadcrumb: {
        title: 'Solicitar beneficio'
      },
      startReEnrollment: 'Comenzar rematriculacion',
      startArancel: 'Ver anexo',
      goToPay: 'Ir a pagarla',
      isMatriculaGenerated: 'Ya has generado una intención de pago correspondiente a la rematriculación: {period}',
      scholarshipWarning: 'Antes de ver tu contrato, podrás revisar si aplicas a alguna beca o beneficio y obtener descuentos.' ,
      cardBenefit: {
        title: 'Beneficios',
        description: 'Tienes un beneficio asignado y para renovarlo deberás subir la documentación obligatoria actualizada aquí.',
        actionTitle: 'Renovar beneficio',
        actionWarningTitle: 'Verificar documentación'
      },
      cardScholarship: {
        title: 'Becas',
        description: 'Para poder aspirar a la renovación de una beca, deberás tener un promedio ponderado igual o mayor a cinco punto cinco (5.5), y no haber tenido asignaturas reprobadas.',
        actionTitle: 'Renovar beca'
      },
      subtitle: 'Te damos la bienvenida a la rematriculación: {period}',
      breadcrumbs: {
        title: 'Rematriculación'
      },
      scholarshipRenewal: {
        title: 'Becas',
        description: 'Aquí podrás renovar tu beca para lo cual deberás adjuntar documentación obligatoria para poder continuar.',
        box: {
          title: 'Beca asignada',
          noValue: 'No posee una beca asignada'
        },
        bottomSection: {
          title: 'Documentacion obligatoria',
          description: 'Adjunta uno de los siguientes documentos en formato jpg, pdf, Word o Excel.',
          items: [
            'Foto/imagen de tu RUT con tu domicilio actualizado',
            'Foto/imagen de tu recibo de sueldo, que acredite que eres empleado de una empresa en convenio con IPP'
          ]
        }
      },
      anexo:{
        title: 'Anexo de servicios educacionales',
        sign: 'Confirmar Anexo'
      },
      benefitRenewal: {
        breadcrumbs: {
          title: 'Solicitar convenio'
        },
        commentary: 'Observación',
        title: 'Convenio',
        description: 'Aquí podrás renovar tu convenio para lo cual deberás adjuntar documentación obligatoria para poder continuar.',
        box: {
          title: 'Convenio asignado',
          noValue: 'No posee un beneficio asignado'
        },
        revisedDocumentation: 'Documentación revisada:',
        bottomSection: {
          title: 'Documentacion obligatoria',
          description: 'Adjunta uno de los siguientes documentos en formato jpg, pdf, Word o Excel.',
          items: [
            'Foto/imagen de tu RUT con tu domicilio actualizado',
            'Foto/imagen de tu recibo de sueldo, que acredite que eres empleado de una empresa en convenio con IPP'
          ]
        }
      }
    },
    procedureExam: {
      title: 'Examen de título',
      description: 'Selecciona las opciones correspondientes para solicitar tu examen de titulo',
      dataSection: {
        title: 'Datos del examen',
        subtitle: 'Ingresa el nombre del trabajo de titulo o seminario de titulo, junto con la fecha y el centro de servicio al cual deseas ir a rendir.',
        name: 'Nombre',
        period: 'Periodo',
        quarter: 'Centro de servicio',
        type: 'Tipo'
      },
      warning: {
        title: 'Se enviará un correo electrónico a los integrantes de tu equipo y deberán confirmar la solicitud para unirse. Ten en cuenta que tu inscripción al examen de título no queda afectada por la situación en la que esta el resto de los miembros de tu equipo.'
      },
      teamMatesSection: {
        title: 'Tu equipo de trabajo',
        states: {
          pending: 'Pendiente',
          aprove: 'Inscripto'
        }
      }
    },
    retirement: {
      title: 'Retiro',
      description: 'Puedes solicitar el Retiro definitivo de la carrera que estás cursando actualmente. Recuerda que tienes un contrato de prestación de servicios educacionales por el periodo pactado con IPP, cuyas obligaciones pecuniarias se mantienen vigentes.',
      dataSection: {
        title: 'Motivo',
        subtitle: 'Selecciona el motivo por el cual quieres retirarte de la carrera.',
        reason: 'Motivo',
        reasonType: 'Categoria',
        reasonPlaceholder: 'Motivo por el cual el alumno desea retirarse de la carrera y no esta en la lista.'
      },
      confirmation: {
        title: 'Retiro',
        subtitle: 'Recuerda que si realizas el trámite de retiro de la carrera y te encuentras cursando asignaturas debes considerar lo siguiente:',
        rule_1: 'El retiro corresponde a un estado definitivo de la carrera.',
        rule_2: 'Se borrarán las asignaturas que estabas cursando en el periodo actual de tu plan de estudios.',
        rule_3: 'Se eliminarán las asignaturas del periodo en curso desde la plataforma CANVAS.',
        rule_4: 'Si tienes asignaturas habilitadas recuerda rendir tus exámenes previo al trámite, de lo contrario, quedarán reprobadas.',
      },
    },
    careerFreeze: {
      title: 'Congelamiento de carrera',
      description: 'Puedes solicitar Congelamiento de estudios un máximo de una vez en el transcurso de tu carrera, pudiendo ser por un periodo de 1 bimestre o 1 semestre, a contar del segundo bimestre de una carrera. Recuerda que tienes un contrato de prestación de servicios educacionales por el periodo pactado con IPP, cuyas obligaciones pecuniarias se mantienen vigentes. Finalizado el plazo debes reincorporarte a tu Plan de Estudios. En caso de que no te matricules al término del periodo de congelación para el periodo académico siguiente, quedarás en estado de eliminación e IPP no se encontrará obligado a reincorporarte.',
      descriptionBold: 'Deberás pagar al menos 2 (dos) cuotas del bimestre en curso y solamente podrás realizar dos congelamientos en la totalidad de tu carrera, si no retomas la condición de alumno vigente a partir del segundo congelamiento, serás eliminado',
      dataSection: {
        title: 'Motivo',
        subtitle: 'Seleccione el motivo por el cual quieres solicitar el congelamiento de carrera.',
        reason: 'Motivo',
        reasonType: 'Categoria',
        reasonPlaceholder: 'Justificar porque se solicita el cambio en caso de seleccionar "otros".'
      },
      confirmation: {
        title: 'Congelamiento de carrera',
        subtitle: 'Recuerda que si realizas el trámite de congela carrera y te encuentras cursando asignaturas debes considerar lo siguiente:',
        rule_1: 'Se borrarán las asignaturas que estabas cursando en el periodo actual de tu plan de estudios.',
        rule_2: 'Se eliminarán las asignaturas del periodo en curso desde la plataforma CANVAS.',
        rule_3: 'Si tienes asignaturas habilitadas recuerda rendir tus exámenes previo al trámite, de lo contrario, quedarán reprobadas.',
      }
    },
    careerChange: {
      title: 'Cambio de carrera',
      description: 'Podrás solicitar el cambio de tu carrera a otra de tu preferencia por diversos motivos hasta 7(siete) dias corridos despúes del inicio de cursado o una vez finalizado tu período de cursada actual.',
      dataSection: {
        currentlyStudying: 'Actualmente estás cursando',
        currentlyCareer: '{career} - PLAN: {studyPlan}',
        selectCareer: 'Selecciona tu nueva carrera',
        studyPlan: 'Selecciona un nuevo plan de estudios',
        reason: 'Motivo',
        reasonSubtitle: 'Selecciona el motivo por el cual quieres solicitar el cambio de carrera.',
        reasonField: 'Motivo',
        reasonType: 'Categoria',
        reasonPlaceholder: 'Justificar porque se solicita el cambio en caso de seleccionar "otros".'
      },
      validations: {
        careerRequired: 'Debe seleccionar una carrera',
        studyPlanRequired: 'Debe seleccionar un plan de estudio',
        processingReasonRequired: 'Debe seleccionar un motivo',
        justificationRequired: 'Debe ingresar un motivo'
      }
    },
    careerChangeStep2: {
      title: 'Cambio de carrera',
      dataSection: {
        noItems: 'No se han encontrado materias',
        subjectsAproved: 'Asignaturas de origen',
        subjectsHomologated: 'Asignaturas de destino',
        notes: 'Notas',
        classStart: 'Comienzo de clases',
        cost: 'Diferencia de costos',
        concept: 'CONCEPTO',
        oldCareer: 'CARRERA ANTERIOR',
        newCareer: 'NUEVA CARRERA',
        diference: 'DIFERENCIA',
        total: 'TOTAL',
        warning: 'Al realizar el cambio de una carrera a otra, se genera un nuevo contrato, el cual deberá ser firmado y quedará registrado en el sistema.'
      },
      errorMessage: {
        title: 'Occurió un error al realizar el cambio de carrera'
      }
    },
    careerChangeStep3: {
      title: 'Cambio de carrera',
      description: 'Lee el documento y firma a continuación. Ten en cuenta que se abrirá en una pestaña nueva para continuar el proceso.',
      dataSection: {
        header: 'Contrato de presentacion de servicios educacionales.'
      }
    },
    careerChangeStep4: {
      title: 'Cambio de carrera',
      dataSection: {
        header: 'Cambio de carrera solicitado',
        description: 'Ten en cuenta que el cambio deberá ser aprobado por la institución. Pronto nos pondremos en contacto.',
      }
    },
    reinstatement: {
      title: 'Reincorporación',
      description: 'Procedimiento mediante el cual un estudiante en estado académico Congelado, Eliminado o Retirado, solicita reincoporarse a su plan de estudios.',
      dataSection: {
        title: 'Reincorporación',
        subtitle: 'Puedes solicitar reincorporación a tu misma carrera y plan de estudios si este se encuentra vigente, de lo contrario, serás reincorporado al plan de estudios actual. Si deseas volver a la institución y estudiar otra carrera deberás realizar la solicitud de "cambio de carrera".'
      }
    },
    periodChange: {
      dataSection: {
        title: 'Cambio de periódo',
        subtitle: 'El trámite será cargado automáticamente una vez que presiones "solicitar". Recuerda que tienes hasta 14 días posteriores al inicio de clases para solicitar el cambio de periodo.'
      }
    },
    homologation: {
      dataSection: {
        title: 'Homologación',
        subtitle: 'El trámite será cargado automáticamente una vez que presiones "solicitar". Recuerda que debes estar matriculado en la carrera y tienes hasta 14 días posteriores al inicio de clases para solicitar la homologación de asignaturas.'
      }
    },
    raicProcedure: {
      title: 'RAIC',
      description: 'Pódras solicitar el retiro antes del inicio de clases de la carrera.',
      descriptionBold: 'Recuerda que: hasta 10 dias a partir de la fecha de matriculación, la devolucioń es al 100%, posterior a este tiempo será del 95%',
      dataSection: {
        title: 'Motivo',
        subtitle: 'Ingresa el motivo por el cual quieres solicitar el RAIC.',
        reason: 'Motivo'
      }
    },
    procedureExamCharge: {
      title: 'Examen de título',
      description: 'Puedes realizar este procedimiento cuando te encuentras en Estado Académico Egresado y tienes tu situación financiera al día. Si deseas rendir el Examen de Título de forma grupal, asegúrate de que tus compañeros se encuentren al día en el proceso. El egresado tendrá hasta dos años de plazo para cumplir con su proceso de Titulación; posterior a ello quedará como "Egresado Sin Opción a Título".',
      dataSection: {
        examTax: 'Arancel de título'
      },
      autocompleteSubject: {
        label: 'Carrera'
      }
    },
    procedureCeoChange: {
      title: 'Cambio de centro de servicio',
      description: 'Puedes solicitar un Cambio de CSE después de haber cursado, al menos, un semestre académico en la última carrera en que fuiste aceptado.',
      dataSection: {
        currentlyCeo: 'Actualmente estas en el centro de servicio',
        selectCeo: 'Selecciona tu nuevo centro de servicio',
        city: 'Ciudad',
        state: 'Región',
        ceo: 'Centro de servicios',
        reasonField: 'Motivo del cambio'
      },
      documentationSection: {
        title: 'Documentación obligatoria',
        subtitle: 'Adjunta uno de los siguientes documentos en formato jpg, pdf, Word o Excel.',
        item1: 'Foto/Imagen de tu RUT con domicilio actualizado.',
        item2: 'Foto/Imagen de un servicio o impuesto a tu nombre donde figure tu nuevo domicilio.',
        item3: 'Cualquier otra foto o imagen que compruebe lo que expresaste como motivo.',
        uploadFiles: 'Cargar archivos',
        placeholderImage: 'Click aquí para agregar imagen.',
        warning: {
          image: 'Debe seleccionar una imagen'
        },
      }
    },
    procedureHistory: {
      title: 'Historial de tramites',
      description: 'Aqui encontrarás los tramites que has realizado a lo largo de tu carrera',
      table: {
        headers: {
          number: 'Numero',
          name: 'Nombre',
          date: 'Fecha de solicitud',
          status: 'Estado',
        },
        status: {
          aproved: 'Aprobado',
          observed: 'Observado',
          rejected: 'Rechazado',
          init: 'Iniciado',
        },
        goToPage: 'Ir a la página',
      },
      processingStatus:{
        pendiente:'Pendiente',
        iniciado:'Iniciado'
      },
      tramite:'Tramite',
      fechaSolicitud:'Fecha de solicitud:'
    },
    procedureHistoryDetails: {
      dataSection: {
       reason: 'Motivo',
       request: 'Solicitud de reembolso',
       documentation: 'Documentación',
       comments: 'Comentarios',
       requested: 'Solicitado el',
       emptyReason: 'No hay motivo para mostrar.',
       emptyRequest: 'No hay pedido para mostrar.',
       emptyComments: 'No hay comentarios para mostrar.',
       emptyDocumentations: 'No hay documentación para mostrar.',
      },
      status:{
        iniciado:'Iniciado'
      }
    },
    procedureBenefit:{
      breadcrumb:{
        title:'Beneficios',
        description:'Procedimiento mediante el cual organizaciones o colaboradores pueden solicitar un beneficio'
      },
      OutlineCard:{
        title:'Empresa',
        autocompleteEmpresa:{
          label:'Empresa',
          placeholder:'Comenzá a escribir y te mostramos resultados'
        },
        dropfile:{
          title:'Documentación requerida',
          subtitle:'Adjuntá recibo de sueldo en formato jpg, png o pdf.',
        },
        seccionPropietario:{
          title:'Propietario del documento',
          subtitle:'Seleccioná el vinculo con la persona que registra el documento',
        }
      },
      successMessage:{
        title:'Solicitaste un beneficio',
        subtitle:'En breve tendrás una respuesta. Recordá que podrás ver el estado del trámite desde tu portal',
      },
      errorMessage: {
        uploadDocument: 'Ocurrió un error al cargar los archivos',
      },
      validations: {
        communityRequired: 'Debe seleccionar una empresa',
        documentOwnerRequired: 'Debe seleccionar un propietario del documento'
      }
    },
    procedureDefiniteLow:{
      breadcrumb:{
        title:'Baja definitiva',
        description:'Para solicitar este trámite debes cumplir con las condiciones en la sección Normas Arancelarias del reglamento.'
      },
      autocomplete:{
        label:'Carrera'
      },
      alert: 'La devolución por fuerza mayor es una gestión que queda derivada y sujeta a análisis por parte del rectorado. Te avisaremos la resolución por mail o WhatsApp.',
      OutlineCard:{
        title:'Motivo',
        subtitle:'Seleccioná el motivo por el cual querés solicitar la baja.',
        autocompleteMotivo:{
          label:'Motivo'
        },
        textarea:{
          label:'Comentarios',
          placeholder:'Podés dejarnos acá lo que quieras contarnos',
          placeholderNotOwnAccount:'Especifica la causa del uso de una cuenta no propia.'
        },
        outlineCardDatosBancarios:{
          title:'Datos bancarios',
          subtitle:'Adjuntá la constancia de CBU con los datos bancarios de la cuenta en donde efectuar el reembolso. En caso de no ser el titular de la cuenta, deberás justificar porqué utilizás otra cuenta.',
          switch:'Soy titular de la cuenta',
          inputCBU:{
            label:'CBU',
            placeholder:'Digita el número de CBU'
          },
          inputCuit:{
            label:'Cuil del titular',
            placeholder:'00-000000000-5'
          },
          inputBanco:{
            label:'Banco',
            placeholder:'A que banco pertenece'
          },
          inputDomicilio:'Domicilio legal',
          seccionContacto:{
            title:'Contacto',
            inputContacto:'Contacto',
            inputHora:{
              title:'Seleccioná una hora.',
              placeholder:'Seleccioná una hora.'
            }
          }
        },
        dropfile:{
          title:'Documentación requerida',
          subtitleRefund:'Adjuntá constancia de CBU y archivo respaldatorio que permita al Instituto evaluar la excepción en formato jpg, png o pdf.',
          subtitlePending: 'Subir carta o documento real con motivo de la baja en formato jpg, png o pdf.'
        }
      },
      successMessage:{
        title:'Solicitaste la baja definitiva',
        subtitle:'Podrás ver el estado desde tu portal en la opción "Trámites".\n\n Ten en cuenta que una vez que finalice el trámite, tu legajo se dará de baja automáticamente y no podrás acceder nuevamente al portal.',
      },
      errorMessage: {
        uploadDocument: 'Ocurrió un error al cargar los archivos',
      },
      validations: {
        careerRequired: 'Debe seleccionar una carrera',
        processingReasonRequired: 'Debe seleccionar un motivo',
        observationRequired: 'Debe ingresar un comentario',
        accountHolderRequired: 'Debe ingresar una identificación',
        accountHolderFormat: 'Formato inválido de identificación',
        accountCBURequired: 'Debe ingresar un CBU',
        accountCBUFormat: 'Formato inválido de CBU',
        bankRequired: 'Debe ingresar un banco',
        bankMaxLength: 'Máximo 30 caracteres',
        timeRequired: 'Debe seleccionar una hora'
      }
    },
    procedureDocument:{
      OutlineCard:{
        title:'Documentación obligatoria',
        subtitle:'Adjuntanos los siguientes documentos en formato jpg, png o pdf.'
      },
      dropfileDni:{
        title:'DNI',
        subtitle:'Frente y dorso de tu DNI.'
      },
      dropfileAnalitico:{
        title:'ANALITICO',
        subtitle:'Certificado Analitico de Nivel Secundario completo.'
      },
      information:{
        title:'¿Tenes dudas sobre la documentación a presentar?',
        buttonText:'Ver reglamento'
      },
      successMessage:{
        title:'¡Ya cargaste tu Documentación Obligatoria!',
        subtitle:'Tus archivos estan en revisión. Pronto recibirás noticias.',
      }
    },
    procedureScholarship:{
      breadcrumb:{
        title:'Becas',
        description:'Podés solicitar una beca ingresando la documentación solicitada.'
      },
      OutlineCard:{
        title:'Beca',
        subtitle:'Seleccioná el tipo de beca que querés solicitar',
        autocompleteTipo:{
          label:'Tipo',
          placeholder:'Seleccionar beca'
        },
        textBeca_1:'Si estás atravesando una situación socio-económica desfavorable y necesitás que te facilitemos el acceso y/ prosecución de tus estudios con nosotros, ésta es la beca para vos.',
        textBeca_2:'Recordá que tiene una validez cuatrimestral y su renovación es automática, no debes tener sanciones disciplinarias y deberás cumplir con las actividades asignadas como becario.',
        formBeca:{
          title:'Descarga el Formulario de solicitud de Beca para completarlo ',
          descarga:'Descargar'
        },
        motivo:{
          title:'Motivo',
          subtitle:'Seleccioná el motivo  por el cual querés solicitar la beca.',
          inputMotivo:{
            label:'Motivo',
            placeholder:'Seleccionar'
          },
          textarea:{
            label:'Comentarios',
            placeholder:'Podés dejarnos acá lo que quieras contarnos sobre el motivo de la solicitud...'
          }
        },
        porcetaje:{
          title:'Porcentaje',
          subtitle:'Si bien el porcentaje de otorgamiento surgirá de un análisis de diversos factores, creemos importante que nos indiques porcentaje con el que más cómodo te sentirías.',
          inputPorcentaje:{
            label:'Seleccioná el porcentaje de beca',
            label_1:'%',
            placeholder:'Seleccionar'
          },
          textarea:{
            label:'Comentarios',
            placeholder:'Podés dejarnos acá lo que quieras contarnos sobre el motivo de la solicitud...'
          }
        },
        dropfile:{
          title:'Documentación requerida',
          subtitle:'Después de completar el formulario de solicitud de beca, podés cargarlo junto con los archivos requeridos.Recordá cargar la documentación en formato jpg, png o pdf con un tamaño no mayor a 25MB.'
        },
        information:{
          title:'Si tenés alguna duda consultá el reglamento.',
          buttonText:'Ver reglamento'
        },

      },
      successMessage:{
        title: 'Solicitaste una beca',
        subtitle: 'Podrás ver el estado desde tu portal en la opción "Trámites"',
      },
      errorMessage: {
        uploadDocument: 'Ocurrió un error al cargar los archivos',
      },
      validations: {
        scholarshipRequired: 'Debe seleccionar una beca',
        processingReasonRequired: 'Debe seleccionar un motivo',
        scholarshipProductRequired: 'Debe seleccionar un producto',
        observationRequired: 'Debe ingresar un comentario',
      }
    },
    procedureTemporaryLow:{
      breadcrumb:{
        title:'Baja transitoria',
        description:'Para solicitar este trámite debes cumplir con las condiciones en la sección Normas Arancelarias del reglamento.'
      },
      autocomplete:{
        label:'Carrera'
      },
      OutlineCard:{
        title:'Motivo',
        subtitle:'Seleccioná el motivo por el cual querés solicitar la baja.',
        autocompleteMotivo:{
          label:'Motivo'
        },
        textarea:{
          label:'Comentarios',
          placeholder:'Podés dejarnos acá lo que quieras contarnos...'
        },
        contacto:{
          title:'Contacto',
          inputContacto:'Contacto',
          inputTime:{
            title:'Seleccioná una hora.',
            select:'Seleccioná una hora.'
          }
        },
      },
      successMessage:{
        title:'Solicitaste la baja transitoria',
        subtitle:'Podrás ver el estado desde tu portal en la opción “Trámites”.\n\nPodrás reestablecer tu condición de alumno regular al realizar una reinscripción, se ajustarán las nuevas normas arancelarias y/o modificaciones del plan de estudios si las hubiese.',
      },
      errorMessage: {
        title: 'Ocurrió un error al crear el trámite'
      },
      validations: {
        careerRequired: 'Debe seleccionar una carrera',
        processingReasonRequired: 'Debe seleccionar un motivo',
        observationRequired: 'Debe ingresar un comentario',
        timeRequired: 'Debe seleccionar una hora'
      }
    },
    certificateProgram:{
        breadcrumbs:{
          title:'Programas Legalizados',
          description:'Detalle del programa de las materias aprobadas, con la carga horaria correspondiente.Una vez solicitado, debemos enviarlo al Ministerio De Educación de Ciudad de Buenos Aires para ser legalizado.'
        },
        autocompleteCareer:{
          label:'Carrera'
        },
        alertInfo:{
          title:'Tené en cuenta que este trámite puede tener una demora de 2 meses aproximadamente'
        },
        button:{
          text:'Solicitar'
        },
        labelsCertificates:{
          alumnoRegular:{
            title:'Certificado de Alumno Regular'
          },
          matricula:{
            title:'Certificado de Matricula'
          },
          planStudio:{
            title:'Certificado de Plan de Estudio'
          },
          notas:{
            title:'Certificado de Notas'
          },
          default:{
            title:'Certificados'
          },
        }

    },
    gestionBanner:{
      title:'Gestioná tus trámites y certificados',
      subtitle:'Podrás realizar trámites académicos y generales.'
    },
    status:{
      canceled :'Anulado',
      low :'Baja',
      studying :'Cursando',
      graduated :'Egresado',
      titled :'Titulado',
      graduatedWithoutDegree:'Egresado sin opción a Título',
      notAcademicLoad:'Eliminado por no carga academica',
      notEnrollment: 'Eliminado por no matricula',
    }
  },
  en: {

    welcome_message: {
      title: 'Work in progress',
      description: 'Meanwhile, you can navigate through the app'
    },

  },
  es_CL:{
    proceduresInital:{
      procedures: {
        '4': {
          title: 'Certificado de egreso',
          downloadTitle: 'Certificado de egreso'
        },
        '10': {
          title: 'Concentracion de notas',
          description: 'Documento certificado de Concentracion de notas',
          downloadTitle: 'Certificado Concentracion de notas'
        },
      }
    }
  }
};


export default proceduresTranslations
