const reservations = {
    title: 'Pedido de examen',
    description: 'Este reporte muestra todos los pedidos de examen de todas las asignaturas correspondientes al período seleccionado con su respectivo proctoring.',
    detail: [
        { label: 'Archivo', value: 'Reporte generateReservationsReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip argentina">Argentina</span> <span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'Se muestran sólo los exámenes en estado de "pedido".' },
        { label: 'Criterios', value: 'El período seleccionado corresponde al de cursada de la asignatura. Se muestra el proctoring original y si hubo algún cambio.' },
    ],
    example: {
        header: [
            'Legajo',
            'DNI',
            'Estudiante',
            'Email',
            'Carrera',
            'Asignatura',
            'Período',
            'Fecha reserva',
            'Bloque reserva',
            'Hora inicio',
            'Hora fin',
            'Estado',
            'Nota',
            'Aprobado',
            'Cant. Intentos',
            'Tipo excepción',
            'Motivo excepción',
            'Proctoring pedido',
            'Proctoring mesa',
        ],
        data: [
            [
                '2000LEGAJO',
                '99999999',
                'Alumno Prueba',
                'prueba@gmail.com',
                '1234 - Carrera Prueba',
                'ABDC - Asignatura Prueba',
                '1A/2000',
                '2000-01-01',
                '2000-01-01',
                '20:00:00',
                '21:00:00',
                '61 - Pedido',
                'null',
                '',
                '1',
                'null',
                'null',
                'Klarway',
                'Klarway',
            ],
            [
                '1234LEGAJO',
                '123456789',
                'Alumno Probando',
                'probando@gmail.com',
                'ABCD - Carrera Prueba',
                '1234 - Asignatura Prueba',
                '1A/2000',
                '2000-01-01',
                '2000-01-01',
                '20:00:00',
                '21:00:00',
                '61 - Pedido',
                '7.0',
                'SI',
                '1',
                'null',
                'null',
                'Klarway',
                'Klarway',
            ],
        ],
    },
}

const nonreservations = {
    title: 'Sin pedido de examen',
    description: 'Este reporte muestra todos las materias de los estudiantes que no tienen pedido de examen activo.',
    detail: [
        { label: 'Archivo', value: 'Reporte generateNonReservationsReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip argentina">Argentina</span> <span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'Para la asignatura mostrada no deben existir pedidos de examen con fecha posterior a la actual. El tipo de examen puede ser normal de 22 o 30 preguntas o complejo de 9. Si la modalidad del estudiante es actual, la asignatura debe estar en estado "regular", pero si la modalidad es vieja puede ser "regular", "en curso" o "desaprobada". El estudiante no debe estar en nunguno de los estados: "baja", "anulado", "eliminado por no matricula", "eliminado por no carga académica".' },
        { label: 'Criterios', value: 'El período seleccionado corresponde al de cursada de la asignatura. ' },
    ],
    example: {
        header: [
            'Estudiante',
            'DNI',
            'Email',
            'Legajo',
            'Estado',
            'Codigo Asignatura',
            'Asignatura',
            'STUDENT_SUBJECT_ID',
            'Período',
            'Intentos',
            'Telefono',
        ],
        data: [
            [
                'Alumno Prueba',
                '99999999',
                'prueba@gmail.com',
                '9999LEGAJO',
                'Regular',
                'ABCD',
                'CARRERA PRUEBA',
                '99999',
                '1A/2000',
                '0',
                '+54 11 3451-9999',
            ],
            [
                'Alumno Probando',
                '99999999',
                'probando@gmail.com',
                '7777LEGAJO',
                'Regular',
                'ABCD',
                'CARRERA PRUEBA',
                '12345',
                '1A/2222',
                '1',
                '+54 11 3451-9999',
            ],
        ],
    },
}

const examenesInfo = {
    title: 'Datos de mesas de examen / Exámenes',
    description: 'Este reporte muestra todos los exámenes rendidos o por rendir con fecha no mayor a 1 mes antes o después de la fecha actual.',
    detail: [
        { label: 'Archivo', value: 'Reporte examenesinfo.xlsx' },
        { label: 'Países', value: '<span class="country-chip argentina">Argentina</span> <span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'Los exámenes rendidos deben tener hora de inicio y los exámenes no rendidos no deben tener nota.' },
        { label: 'Criterios', value: 'Este reporte muestra los exámenes con fecha dentro del período 1 mes antes y 1 mes después de la fecha actual.' },
    ],
    example: {
        header: [
            'Nombre',
            'Apellido',
            'Nro Doc',
            'Carrera',
            'Inicio carrera',
            'Materia',
            'Periodo Materia',
            'Fecha pedido exámen',
            'Fecha mesa',
            'Mesa hora inicio',
            'Fecha exámen',
            'Estado',
            'Nota',
            'Examen Hora inicio',
            'Examen Hora Fin',
            'Proctoring actual',
            'Proctoring original',
            'Legajo',
            'Email',
            'Celular',
            'Teléfono',
            'Localidad',
            'Razón anulación',
            'Tipo de anulación',
            'Usuario anuló',
            'Fecha anulación',
            'Último periodo abonado',
        ],
        data: [
            [
                'ALUMNO',
                'PRUEBA',
                '99999999',
                'CARRERA PRUEBA',
                'PRIMER BIMESTRE ACADÉMICO 2000',
                'MATERIA PRUEBA',
                'CUARTO BIMESTRE ACADÉMICO 2222',
                '1/1/2000 19:28',
                '1/1/2222',
                '20:00:00',
                '1/1/2022',
                'Aprobado',
                '7.0',
                '1/1/2000 20:21',
                '1/1/2222 20:28',
                'Klarway',
                'null',
                '9999LEGAJO',
                'prueba@gmail.com',
                '541135199999',
                'null',
                'ARGENTINA',
                'null',
                'null',
                'null',
                'null',
                'PRIMER BIMESTRE ACADÉMICO 2000',
            ],
            [
                'ALUMNO',
                'PROBANDO',
                '12345678',
                'CARRERA PRUEBA',
                'PRIMER BIMESTRE ACADÉMICO 1234',
                'MATERIA PRUEBA',
                'CUARTO BIMESTRE ACADÉMICO 4321',
                '1/1/2000 19:28',
                '1/1/2222',
                '20:00:00',
                '1/1/1234',
                'Anulado',
                '',
                '',
                '',
                'Klarway',
                'null',
                '1234LEGAJO',
                'probando@gmail.com',
                '541135199999',
                'null',
                'PARIS',
                'Porque estoy lejos de casa',
                'null',
                'null',
                'null',
                'PRIMER BIMESTRE ACADÉMICO 2000',
            ],
        ],
    },
}

const consolidatedExams = {
    title: 'Consolidado Calificaciones',
    description: 'Este reporte muestra las notas de examen de cada materia de cada estudiante con las notas de módulos y actividades correspondientes.',
    detail: [
        { label: 'Archivo', value: 'Reporte generateConsolidatedExamsReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'Muestra asignaturas con y sin examen.' },
        { label: 'Criterios', value: 'Muestra a los estudiantes con modalidad 19. Y las asignaturas convalidadas u homologadas.' },
    ],
    example: {
        header: [
            'RUT',
            'Estudiante',
            'Carrera',
            'Asignatura',
            'Período asignatura',
            'Nota final',
            'Nota examen',
            'M1',
            'M2',
            'M3',
            'M4 - E1',
            'M4 - E2',
            'M4 - E3',
            'R19',
            'Aprobado',
            'Concepto',
        ],
        data: [
            [
                '12345678-9',
                'ALUMNO PRUEBA',
                '12345 - CARRERA PRUEBA',
                '54321 - MATERIA PRUEBA',
                '1A/2000',
                '7.0',
                '',
                '7',
                '7',
                '7',
                '7',
                '7',
                '6.7',
                'NO',
                'SI',
                'APROBADO',
            ],
            [
                '999999-9',
                'ALUMNO PRUEBA',
                'ABCD - CARRERA PRUEBA',
                'DCBA - MATERIA PRUEBA',
                '1A/2000',
                '3.5',
                '',
                '5.56',
                '1',
                '6.28',
                '1',
                '-',
                '-',
                'NO',
                'NO',
                'DESAPROBADO',
            ],
        ],
    },
}


// documentations[this.reports.service][this.reports.url]

export default {reservations, nonreservations, examenesInfo, consolidatedExams  };
