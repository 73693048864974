<template>
  <div>
    <table ref="emailSummary" align="center" border="0" cellpadding="0" cellspacing="0" height="100%" width="100%"
      style="border-collapse:collapse;height:100%;margin:0;padding:0;width:100%">
      <tbody>
        <tr>
          <td align="center" valign="top" style="height:100%;margin:0;padding:0;width:100%">
            <table border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse:collapse">
              <tbody>
                <tr>
                  <td align="center" valign="top"
                    style="background:#ffffff none no-repeat center/cover;background-color:#ffffff;background-image:none;background-repeat:no-repeat;background-position:center;background-size:cover;border-top:0;border-bottom:0;padding-top:36px;padding-bottom:54px">

                    <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%"
                      style="border-collapse:collapse;max-width:800px!important">
                      <tbody>
                        <tr>
                          <td valign="top"
                            style="background:transparent none no-repeat center/cover;background-color:transparent;background-image:none;background-repeat:no-repeat;background-position:center;background-size:cover;padding-top:0;padding-bottom:0;border-radius: 4px;border: 1px solid #E4E4E4">
                            <table border="0" cellpadding="0" cellspacing="0" width="100%"
                              :style="$isTeclab ? 'min-width:100%;border-collapse:collapse;background: linear-gradient(90deg, rgba(0,126,186,1) 0%, rgba(0,33,105,1) 100%)' : 'background:#0056fe;'">
                              <tbody>
                                <tr>
                                  <td valign="top" style="padding:9px">
                                    <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0"
                                      style="min-width:100%;border-collapse:collapse">
                                      <tbody>
                                        <tr>
                                          <td valign="top"
                                            style="padding-right:9px;padding-left:9px;padding-top:0;padding-bottom:0;text-align:center">

                                            <img id="logo" align="center" :alt="$isTeclab ? 'teclab-logo' : 'ipp-logo'"
                                              :src="require(`@/assets/admission/logo-${$theme}.png`)" width="120"
                                              style="max-width:255px;margin-top:47px;padding-bottom:47px;display:inline!important;vertical-align:bottom;border:0;height:auto;outline:none;text-decoration:none"
                                              tabindex="0">

                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <!--  <table border="0" cellpadding="0" cellspacing="0" width="100%"
                              style="min-width:100%;border-collapse:collapse;table-layout:fixed!important">
                              <tbody>
                                <tr>
                                  <td style="min-width:100%;padding-bottom:18px">
                                    <table border="0" cellpadding="0" cellspacing="0" width="100%" height="auto"
                                      style="min-width:100%;border-top:1px solid #eaeaea;border-collapse:collapse">
                                      <tbody>
                                        <tr>
                                          <td>
                                            <span>aca</span>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>

                                  </td>
                                </tr>
                              </tbody>
                            </table> -->
                            <table border="0" cellpadding="0" cellspacing="0" width="100%"
                              style="min-width:100%;border-collapse:collapse">
                              <tbody>
                                <tr>
                                  <td valign="top" style="padding: 9px 24px 20px">
                                    <table align="left" border="0" cellpadding="0" cellspacing="0"
                                      style="max-width:75%;border-collapse:collapse" width="100%">
                                      <tbody>
                                        <tr>

                                          <td valign="top"
                                            style="padding:0 9px 9px 0;color:#0000af;font-family:Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;word-break:break-word;font-size:16px;line-height:150%;text-align:left">

                                            <div :style="`color: var(--v-primary-base);
                                              text-align: left;
                                              font-size: 1.25rem;
                                              font-weight: 700;
                                              line-height: 2rem;
                                              letter-spacing: 0.0125em;
                                              font-family: DM Sans, Roboto, sans-serif !important;`">
                                              {{
                                                $t('modules.admission.preregistration.create.emailSummary.greeting.title',
                                                  {
                                                    name: preregStudentData.lastname ? `${preregStudentData.name}
                                              ${preregStudentData.lastname}` : preregStudentData.name
                                                  })
                                              }}
                                            </div>
                                            <div style="color: rgba(0, 0, 0, 0.6);
                                            text-align: left;
                                            font-size: 0.875rem;
                                            font-weight: 500;
                                            line-height: 1.375rem;
                                            letter-spacing: 0.0071428571em;">
                                              {{
                                                $t('modules.admission.preregistration.create.emailSummary.greeting.subtitle')
                                              }}
                                            </div>

                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <table align="left" border="0" cellpadding="0" cellspacing="0"
                                      style="max-width:25%;border-collapse:collapse" width="100%">
                                      <tbody>
                                        <tr>

                                          <td valign="top"
                                            style="padding:0 0 9px 9px;font-family:Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;word-break:break-word;font-size:16px;line-height:150%;text-align:right">
                                            <div style="color: rgba(0, 0, 0, 0.6);
                                            text-align: right;
                                            font-size: 0.875rem;
                                            font-weight: 500;
                                            line-height: 1.375rem;
                                            letter-spacing: 0.0071428571em;">
                                              {{
                                                $t('modules.admission.preregistration.create.emailSummary.greeting.date')
                                              }}
                                            </div>
                                            <div style="color: rgba(0, 0, 0, 0.6);
                                            text-align: right;
                                            font-size: 0.875rem;
                                            font-weight: 700;
                                            line-height: 1.375rem;
                                            letter-spacing: 0.0071428571em;">
                                              {{ todayDate }}
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>


                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <table border="0" cellpadding="0" cellspacing="0" width="100%"
                              style="min-width:100%;border-collapse:collapse;">

                              <tbody>
                                <tr>
                                  <td valign="top" style="padding: 0 24px">


                                    <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%"
                                      height="160px"
                                      style="max-width:50%;border-collapse:collapse;background-color: #F7F7F7;">
                                      <tbody>
                                        <tr>

                                          <td
                                            style="padding-top:9px;padding-right:18px;padding-bottom:9px;padding-left:18px">

                                            <table border="0" cellspacing="0" width="100%"
                                              style="min-width:100%!important;border-collapse:collapse">
                                              <tbody>
                                                <tr>
                                                  <td valign="top"
                                                    style="padding:18px 9px 18px 18px;font-family:Helvetica;font-size:14px;font-weight:normal;text-align:center;word-break:break-word;line-height:150%">
                                                    <div style="text-align: left;
                                                  color: #000000B3;
                                                  font-size: 0.75rem;
                                                  font-weight: 400;
                                                  line-height: 1.25rem;
                                                  letter-spacing: 0.0333333333em;">
                                                      {{
                                                        $t('modules.admission.preregistration.create.emailSummary.studentData.career')
                                                      }}
                                                    </div>
                                                    <div style="color: rgba(0, 0, 0, 0.6);
                                                  text-align: left;
                                                  font-size: 0.875rem;
                                                  font-weight: 700;
                                                  line-height: 1.375rem;
                                                  letter-spacing: 0.0071428571em;">
                                                      {{ !$isTeclab ?
                                                        preregStudentData.selectedCareer.studyPlans[0].hubspotReference :
                                                        preregStudentData.selectedCareer.description }}
                                                    </div>
                                                    <div style="text-align: left;
                                                  color: #000000B3;
                                                  font-size: 0.75rem;
                                                  font-weight: 400;
                                                  line-height: 1.25rem;
                                                  letter-spacing: 0.0333333333em;">
                                                      {{
                                                        $t('modules.admission.preregistration.create.emailSummary.studentData.term')
                                                      }}
                                                    </div>
                                                    <div style="color: rgba(0, 0, 0, 0.6);
                                                  text-align: left;
                                                  font-size: 0.875rem;
                                                  font-weight: 700;
                                                  line-height: 1.375rem;
                                                  letter-spacing: 0.0071428571em;">
                                                      {{ `${preregStudentData.selectedSaleTerm.code.replace('/', ' -')}`
                                                      }}
                                                    </div>

                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>


                                    <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%"
                                      height="160px"
                                      style="max-width:50%;border-collapse:collapse;background-color: #F7F7F7;">
                                      <tbody>
                                        <tr>

                                          <td
                                            style="padding-top:9px;padding-right:18px;padding-bottom:9px;padding-left:18px">

                                            <table border="0" cellspacing="0" width="100%"
                                              style="min-width:100%!important;border-collapse:collapse;">
                                              <tbody>
                                                <tr>
                                                  <td valign="top"
                                                    style="padding:18px 18px 18px 9px;font-family:Helvetica;font-size:14px;font-weight:normal;text-align:center;word-break:break-word;line-height:150%">

                                                    <div style="text-align: left;
                                                  color: #000000B3;
                                                  font-size: 0.75rem;
                                                  font-weight: 400;
                                                  line-height: 1.25rem;
                                                  letter-spacing: 0.0333333333em;">
                                                      {{
                                                        $t('modules.admission.preregistration.create.emailSummary.studentData.city')
                                                      }}
                                                    </div>
                                                    <div style="color: rgba(0, 0, 0, 0.6);
                                                  text-align: left;
                                                  font-size: 0.875rem;
                                                  font-weight: 700;
                                                  line-height: 1.375rem;
                                                  letter-spacing: 0.0071428571em;
                                                  text-transform: capitalize">
                                                      {{ city.cityState | $capitalize }}
                                                    </div>
                                                    <div style="text-align: left;
                                                  color: #000000B3;
                                                  font-size: 0.75rem;
                                                  font-weight: 400;
                                                  line-height: 1.25rem;
                                                  letter-spacing: 0.0333333333em;">
                                                      {{
                                                        $t('modules.admission.preregistration.create.emailSummary.studentData.adviser')
                                                      }}
                                                    </div>
                                                    <div style="color: rgba(0, 0, 0, 0.6);
                                                  text-align: left;
                                                  font-size: 0.875rem;
                                                  font-weight: 700;
                                                  line-height: 1.375rem;
                                                  text-transform: capitalize;
                                                  letter-spacing: 0.0071428571em;">
                                                      {{ getAdviserName() }}
                                                    </div>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>


                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <template v-if="selectedPaymentMethod === 'credit'">
                              <table border="0" cellpadding="0" cellspacing="0" width="100%"
                                style="min-width:100%;border-collapse:collapse">
                                <tbody>
                                  <tr>
                                    <td valign="top" style="padding:29px 42px 10px;">
                                      <table align="left" border="0" cellpadding="0" cellspacing="0"
                                        style="max-width:50%;border-collapse:collapse" width="100%">
                                        <tbody>
                                          <tr>

                                            <td valign="top"
                                              style="padding:0 9px 9px 0;font-family:Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;word-break:break-word;font-size:15px;line-height:150%;text-align:left">
                                              <template v-if="total.prices.length">
                                                <div v-for="price in total.prices" :key="`emailSummary-${price.key}`"
                                                  style="text-align: left;
                                              color: #000000B3;
                                              padding: 4px 0;
                                              font-weight: bold">
                                                  <span>{{ price.name }}</span>

                                                </div>
                                              </template>

                                              <template v-if="total.saving">
                                                <template v-for="saving in total.savings">
                                                  <template v-if="saving.referredFriend">
                                                    <div :key="`emailSummary-${saving.key}`" :style="`color: color: var(--v-primary-base);
                                                    text-align: left;
                                                    padding: 4px 0;
                                                    font-weight: bold`">
                                                      <span v-if="saving.amount">{{ saving.name }}</span>
                                                    </div>
                                                  </template>
                                                  <template v-else>
                                                    <div v-for="(detail, index) in saving.details"
                                                      :key="`${index}emailSummary-${saving.key}`" style="color: #4caf50;
                                                  text-align: left;
                                                  padding: 4px 0;
                                                  font-weight: bold">
                                                      <span v-if="saving.amount">{{ `${detail.name} ${detail.percentage
                                                        ?
                                                        `${detail.percentage}% Off`: ''}` }}</span>

                                                    </div>
                                                  </template>
                                                </template>
                                              </template>
                                              <template>
                                                <div>
                                                  <span style="color: #4caf50; font-weight: bold">{{
                                                    $t('modules.admission.preregistration.create.summary.extraBenefit')
                                                    }} {{
                                                      percentage }}%</span>
                                                </div>
                                              </template>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table align="left" border="0" cellpadding="0" cellspacing="0"
                                        style="max-width:50%;border-collapse:collapse" width="100%">
                                        <tbody>
                                          <tr>
                                            <td valign="top"
                                              style="padding:0 0 9px 9px;font-family:Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;word-break:break-word;font-size:15px;line-height:150%;text-align:left">

                                              <template v-if="total.prices.length">
                                                <div v-for="price in total.prices" :key="`emailSummary-${price.key}`"
                                                  style="text-align: right;
                                              color: #000000B3;
                                              padding: 4px 0;
                                              font-weight: bold">
                                                  <span>{{ price.amount | $currency }}</span>
                                                </div>
                                              </template>
                                              <template v-if="total.saving">
                                                <template v-for="saving in total.savings">
                                                  <template v-if="saving.referredFriend">
                                                    <div :key="`emailSummaryAmount-${saving.key}`" :style="`color: var(--v-primary-base);
                                                    text-align: right;
                                                    padding: 4px 0;
                                                    font-weight: bold`">
                                                      <span v-if="saving.amount">{{ saving.amount | $currency }}</span>
                                                    </div>
                                                  </template>
                                                  <template v-else>
                                                    <div v-for="(detail, index) in saving.details"
                                                      :key="`${index}emailSummaryAmount-${saving.key}`" style="color: #4caf50;
                                                    text-align: right;
                                                    padding: 4px 0;
                                                    font-weight: bold">
                                                      <span v-if="saving.amount">{{ detail.amount | $currency }}</span>
                                                    </div>
                                                  </template>

                                                </template>
                                                <template>
                                                  <div
                                                    style="text-align: right; padding: 4px 0; font-weight: bold;color: #4caf50">
                                                    <span><span>{{ discountAmount | $currency }}</span></span>
                                                  </div>
                                                </template>
                                              </template>


                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </template>
                            <template v-if="selectedPaymentMethod === 'subscription'">
                              <table border="0" cellpadding="0" cellspacing="0" width="100%"
                                style="min-width:100%;border-collapse:collapse">
                                <tbody>
                                  <tr>
                                    <td valign="top" style="padding:29px 42px 10px;">
                                      <table align="left" border="0" cellpadding="0" cellspacing="0"
                                        style="max-width:50%;border-collapse:collapse" width="100%">
                                        <tbody>
                                          <tr>
                                            <td valign="top"
                                              style="padding:0 9px 9px 0;font-family:Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;word-break:break-word;font-size:15px;line-height:150%;text-align:left">
                                              <div v-if="dues && dues.value">
                                                <div v-for="i in dues.value" :key="i">
                                                  <div>
                                                    <div style="font-weight: bold"
                                                      class="black--text d-flex justify-space-between py-1 text-body-2">
                                                      <span class="saving-max-width"> {{
                                                        $t('modules.admission.preregistration.create.summary.due') }} {{
                                                        i }}
                                                      </span>

                                                    </div>
                                                    <div style="font-weight: bold"
                                                      class="success--text d-flex justify-space-between py-1 text-body-2">
                                                      <span class="saving-max-width">{{
                                                        $t('modules.admission.preregistration.create.summary.discount')
                                                        }}
                                                        {{ prepaids[0]?.percentage }}%</span>

                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table align="left" border="0" cellpadding="0" cellspacing="0"
                                        style="max-width:50%;border-collapse:collapse" width="100%">
                                        <tbody>
                                          <tr>
                                            <td valign="top"
                                              style="padding:0 9px 9px 0;font-family:Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;word-break:break-word;font-size:15px;line-height:150%;text-align:left">
                                              <div v-if="dues && dues.value">
                                                <div v-for="i in dues.value" :key="i">
                                                  <div>
                                                    <div
                                                    style=" text-align: right;
                                                    color: #000000B3;
                                                    padding: 4px 0;
                                                    font-weight: bold">
                                                    <span>{{ dues?.amount | $currency }}</span>
                                              </div>
                                              <div style="
                                                    text-align: right;
                                                    color:#4caf50;
                                                    padding: 4px 0;
                                                    font-weight: bold">

                                                <span >{{ (dues?.amount * prepaids[0]?.percentage) / 100 | $currency }}</span>
                                              </div>
                                              </div>
                                              </div>
                                              </div>

                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>

                                  </tr>
                                  </tbody>
                              </table>
                            </template>
                              <table border="0" cellpadding="0" cellspacing="0" width="100%"
                                style="min-width:100%;border-collapse:collapse;color: #FFFFFF;">
                                <tbody>
                                  <tr>
                                    <td valign="top" style="padding: 0 24px 16px">
                                      <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%"
                                        style="max-width:50%;border-collapse:collapse">
                                        <tbody>
                                          <tr>
                                            <td>
                                              <table border="0" cellspacing="0" width="100%"
                                                style="border-collapse:collapse;background-color: #77AEFF;border-radius: 4px 0 0 4px;">
                                                <tbody>
                                                  <tr>
                                                    <td valign="top"
                                                      style="padding:8px 9px 8px 18px;font-family:Helvetica;font-size:14px;font-weight:normal;text-align:center;word-break:break-word;line-height:150%">
                                                      <div style="text-align: left;
                                                                            font-size: 1.25rem;
                                                                            font-weight: 700;
                                                                            line-height: 2rem;
                                                                            letter-spacing: 0.0125em;
                                                                            font-family: DM Sans, Roboto, sans-serif !important;">
                                                        TOTAL
                                                      </div>

                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                            </table>


        <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%"
          style="max-width:50%;border-collapse:collapse">
          <tbody>
            <tr>

              <td>

                <table border="0" cellspacing="0" width="100%"
                  style="border-collapse:collapse;background-color: #77AEFF;border-radius: 0 4px 4px 0;">
                  <tbody>
                    <tr>
                      <td valign="top"
                        style="padding:8px 18px 8px 9px;font-family:Helvetica;font-size:14px;font-weight:normal;text-align:center;word-break:break-word;line-height:150%">
                        <div v-if="total.cost && selectedPaymentMethod == 'credit'" style="text-align: right;
                                                      font-size: 1.25rem;
                                                      font-weight: 700;
                                                      line-height: 2rem;
                                                      letter-spacing: 0.0125em;
                                                      font-family: DM Sans, Roboto, sans-serif !important;">
                          {{ total.cost - (total.saving + discountAmount) | $currency }}
                        </div>
                        <div v-if="total.cost && selectedPaymentMethod == 'subscription'" style="text-align: right;
                                                      font-size: 1.25rem;
                                                      font-weight: 700;
                                                      line-height: 2rem;
                                                      letter-spacing: 0.0125em;
                                                      font-family: DM Sans, Roboto, sans-serif !important;">
                          {{  dues?.amount - ((dues?.amount * prepaids[0]?.percentage) / 100 ) | $currency }}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>


      </td>
    </tr>
  </tbody>
</table>

<table v-if="total.saving && !$isIPP" border="0" cellpadding="0" cellspacing="0" width="100%"
  style="min-width:100%;border-collapse:collapse;color: #FFFFFF;">
  <tbody>
    <tr>
      <td valign="top" style="padding: 0 24px 24px">


        <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%"
          style="max-width:50%;border-collapse:collapse">
          <tbody>
            <tr>

              <td>

                <table border="0" cellspacing="0" width="100%"
                  style="border-collapse:collapse;background-color:#4caf501f;border-radius: 4px 0 0 4px;">
                  <tbody>
                    <tr>
                      <td valign="top"
                        style="padding:8px 9px 8px 18px;color:#4caf50;text-align:left;font-family:Helvetica;font-size:14px;font-weight:normal;word-break:break-word;line-height:150%">
                        {{
                          $t('modules.admission.preregistration.create.emailSummary.totalSaving')
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>


        <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%"
          style="max-width:50%;border-collapse:collapse">
          <tbody>
            <tr>

              <td>

                <table border="0" cellspacing="0" v-if="!isIPP" width="100%"
                  style="border-collapse:collapse;background-color:#4caf501f;border-radius: 0 4px 4px 0;">
                  <tbody>
                    <tr>

                      <td valign="top"
                        style="padding:8px 18px 8px 9px;color:#4caf50;text-align:right;font-family:Helvetica;font-size:14px;font-weight:normal;word-break:break-word;line-height:150%">
                        {{ -total.saving | $currency }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>


      </td>
    </tr>
  </tbody>
</table>

<table border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width:100%;border-collapse:collapse">

  <tbody>
    <tr>
      <td valign="top" style="padding: 0 24px">

        <table v-if="$isTeclab" align="left" border="0" cellpadding="0" cellspacing="0" width="100%"
          style="max-width:40%;border-collapse:collapse">
          <tbody>
            <tr>

              <td style="padding-top:9px;padding-bottom:33px;padding-left:9px">

                <table border="0" cellspacing="0" width="100%"
                  style="min-width:100%!important;border-collapse:collapse">
                  <tbody>
                    <tr>
                      <td valign="top"
                        :style="`padding:18px;color: var(--v-primary-base);font-family:Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;font-size:14px;font-weight:normal;text-align:center;word-break:break-word;line-height:150%`"
                        v-html="$t('modules.admission.preregistration.create.emailSummary.validity', { todayDate })">
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>

        <table v-if="($isTeclab && total.saving) || dues" align="left" border="0" cellpadding="0" cellspacing="0"
          width="100%" :style="`max-width:${!$isTeclab ? '40%' : '60%'};border-collapse:collapse`">
          <tbody>
            <tr>

              <td style="padding-top:9px;padding-bottom:33px;padding-right:9px">
                <table v-if="$isTeclab" border="0" cellspacing="0" width="100%"
                  style="min-width:100%!important;background-color:#67C1B8;border-radius:4px;border-collapse:collapse">
                  <tbody>
                    <tr>
                      <td valign="top"
                        style="padding:18px;color:#FFFFFF;font-family:Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;font-size:14px;font-weight:normal;text-align:center;word-break:break-word;line-height:200%">
                        <span v-if="dues">{{
                          $t('modules.admission.preregistration.create.summary.dues',
                            {
                              cardName:
                                dues?.creditCard ? dues?.creditCard.name : (dues?.type.meaning ||
                                  'Pronto Pago'),
                              installments: dues?.due ? dues?.due?.installments : dues?.value,
                              installment_amount: dues?.due
                                ? $options.filters.$currency(dues?.due.installment_amount) :
                                $options.filters.$currency(dues?.amount)
                            }) }} <br> {{
                            dues?.adminExpenses ? `más gastos
                          administrativos de
                          ${$options.filters.$currency(dues?.adminExpenses)}` : '' }}</span>
                        <br v-if="dues">
                        <span v-if="$isTeclab && total.saving">{{
                          $t('modules.admission.preregistration.create.emailSummary.totalSavingToday',
                            {
                              totalSaving:
                                $options.filters.$currency(total.saving)
                            }) }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>

        <table v-if="$isIPP && dues || total.saving" align="left" border="0" cellpadding="0" width="100%"
          style="margin-bottom:24px">
          <td
            style=" height: 125px; padding:18px;color:#ffffff;font-family:Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;font-size:14px;font-weight:normal;text-align:center;word-break:break-word;line-height:150%;background-color:#4caf50c7; width:30%;border-radius:4px">
            <span>{{ $t('modules.admission.preregistration.create.summaryEmail.totalSaving')
              }}
            </span><br><br>
            <span v-if="selectedPaymentMethod === 'credit'" style="font-size: 28px;text-align: center;"><b>{{ total.saving + discountAmount |
              $currency }}</b></span>
            <span v-if="selectedPaymentMethod === 'subscription'" style="font-size: 28px;text-align: center;"><b>  {{ ((dues.value) * ((dues?.amount * prepaids[0]?.percentage) / 100)) | $currency}}</b></span>
          </td>

          <td
            style=" height: 125px;padding:18px;color:#002169;font-family:Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;font-size:14px;font-weight:normal;text-align:center;word-break:break-word;line-height:150%;background-color: #ffffff;width: 20%;border-radius:4px">
            <span>{{
              $t('modules.admission.preregistration.create.summaryEmail.dateDiscount')
            }}<br>{{
                total.savings[0].to }}</span>
          </td>
          <td style="
                                          display: flex;
                                          flex-direction: column;
                                          align-content: center;
                                          justify-content: center;
                                          padding:13px;
                                          color:#002169;
                                          font-family:Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;
                                          font-size:14px;
                                          font-weight:normal;
                                          text-align:center;
                                          word-break:break-word;
                                          line-height:90%;
                                          background-color:#b3ccff;
                                          width:100%;
                                          height: 125px;
                                          border-radius:4px">
            <span v-if="dues && selectedPaymentMethod === 'credit'" >{{
              $t('modules.admission.preregistration.create.summary.dues',
                {
                  cardName:dues?.creditCard.name,
                  installments: dues?.due ? dues?.due?.installments : dues?.value,
                  installment_amount: dues?.due
                    ? $options.filters.$currency(dues?.due.installment_amount) :
                    $options.filters.$currency(dues?.amount)
                }) }}</span><br>
                <span v-if="dues && selectedPaymentMethod === 'subscription'" >{{
              $t('modules.admission.preregistration.create.summary.dues',
                {
                  cardName:dues?.type.meaning,
                  installments: dues?.due ? dues?.due?.installments : dues?.value,
                  installment_amount:
                     $options.filters.$currency(dues?.amount - ((dues?.amount * prepaids[0]?.percentage) / 100 ))

                }) }}</span><br>

            <span v-if="total.saving && selectedPaymentMethod === 'credit'">{{
              $t('modules.admission.preregistration.create.emailSummary.totalSavingToday',
                {
                  totalSaving:
                    $options.filters.$currency(total.saving + discountAmount)
                }) }}</span>
                <span v-if=" selectedPaymentMethod === 'subscription'">{{
              $t('modules.admission.preregistration.create.emailSummary.totalSavingToday',
                {
                  totalSaving:
                    $options.filters.$currency((dues?.amount * prepaids[0]?.percentage) / 100)
                }) }}</span>

          </td>
        </table>
      </td>
    </tr>
  </tbody>
</table>
<table v-if="$isIPP" border="0" cellpadding="0" cellspacing="0" width="100%" height="40px"
  class="d-flex justify-center align-center " style="min-width:100%;border-collapse:collapse;background-color:#0056fe">
  <img height="25px" src="../../../../../core/assets/ippbysociallearning.png" alt="logo-ipp-social">
</table>
<table border="0" cellpadding="0" cellspacing="0" width="100%" v-else
  style="min-width:100%;border-collapse:collapse;background-color:#002169;">

  <tbody>
    <tr>
      <td valign="top">


        <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%"
          style="max-width:70%;border-collapse:collapse">
          <tbody>
            <tr>

              <td style="padding-top:9px;padding-right:18px;padding-bottom:9px;padding-left:18px">

                <table border="0" cellspacing="0" width="100%"
                  style="min-width:100%!important;border-collapse:collapse">
                  <tbody>
                    <tr>
                      <td valign="top"
                        style="padding:18px 0;font-family:Helvetica;font-size:14px;font-weight:normal;text-align:center;word-break:break-word;line-height:150%">
                        <a :href="`https://www.${getUrl}/`" target="_blank">
                          <img id="emailFooter" :src="require(`@/assets/admission/footer-${$theme}.png`)"
                            alt="email-footer" style="max-width: 500px">
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <table align="right" border="0" cellpadding="0" cellspacing="0" width="100%"
          style="max-width:30%;border-collapse:collapse">
          <tbody>
            <tr>

              <td style="padding-top:9px;padding-right:18px">

                <table border="0" cellspacing="0" width="100%"
                  style="min-width:100%!important;border-collapse:collapse">
                  <tbody>
                    <tr>
                      <td valign="top"
                        style="padding:18px 18px 18px 9px;font-family:Helvetica;font-size:14px;font-weight:normal;text-align:center;word-break:break-word;line-height:150%">
                        <div style="text-align: right;
                                                  color: white;
                                                  font-size: 0.875rem;
                                                  font-weight: 500;
                                                  line-height: 1.375rem;
                                                  letter-spacing: 0.0071428571em;">
                          <img v-if="!$isTeclab" align="center" alt="telephone"
                            :src="require('@/assets/admission/telephone.png')" width="15"
                            style="max-width:15px;padding-bottom:0;display:inline!important;vertical-align:text-top;border:0;height:auto;outline:none;text-decoration:none">
                          {{
                            $t(`modules.admission.preregistration.create.emailSummary.footer.${!$isTeclab
                              ?
                              'telephoneIPP' : 'telephoneTeclab'}`) }}
                          <img v-if="!$isTeclab" align="center" alt="mobile"
                            :src="require('@/assets/admission/mobile.png')" height="15"
                            style="max-height:15px;margin-left:16px;padding-bottom:0;display:inline!important;vertical-align:text-top;border:0;height:auto;outline:none;text-decoration:none">
                          {{ !$isTeclab ?
                            $t('modules.admission.preregistration.create.emailSummary.footer.cellphoneIPP')
                            : '' }}
                        </div>


                        <table border="0" cellpadding="0" cellspacing="0" width="100%"
                          style="min-width:100%;border-collapse:collapse">
                          <tbody>
                            <tr>
                              <td align="center" valign="top">
                                <table border="0" cellpadding="0" cellspacing="0" width="100%"
                                  style="min-width:100%;border-collapse:collapse">
                                  <tbody>
                                    <tr>
                                      <td align="center" style="padding-left:9px">
                                        <table border="0" cellpadding="0" cellspacing="0" width="100%"
                                          style="min-width:100%;border-collapse:collapse">
                                          <tbody>
                                            <tr>
                                              <td align="center" valign="top">
                                                <table align="right" border="0" cellpadding="0" cellspacing="0"
                                                  style="border-collapse:collapse">
                                                  <tbody>
                                                    <tr>
                                                      <td align="center" valign="top">


                                                        <table align="left" border="0" cellpadding="0" cellspacing="0"
                                                          style="display:inline;border-collapse:collapse">
                                                          <tbody>
                                                            <tr>
                                                              <td valign="top" style="padding-bottom:9px">
                                                                <table border="0" cellpadding="0" cellspacing="0"
                                                                  width="100%" style="border-collapse:collapse">
                                                                  <tbody>
                                                                    <tr>
                                                                      <td align="left" valign="middle"
                                                                        style="padding-top:5px;padding-right:5px;padding-bottom:5px;padding-left:5px">
                                                                        <table align="left" border="0" cellpadding="0"
                                                                          cellspacing="0" width=""
                                                                          style="border-collapse:collapse">
                                                                          <tbody>
                                                                            <tr>

                                                                              <td align="center" valign="middle"
                                                                                width="24">
                                                                                <a :href="$countryConstants.social_networks.facebook"
                                                                                  target="_blank"
                                                                                  :data-saferedirecturl="`https://www.google.com/url?q=${$countryConstants.social_networks.facebook}&amp;source=gmail&amp;ust=1618081426907000&amp;usg=AFQjCNG-eWDwWyHebV8G7AO4CtaDlDRLIg`"><img
                                                                                    style="display:block;border:0;height:auto;outline:none;text-decoration:none">
                                                                                  <img id="facebookLogo"
                                                                                    :src="require('@/assets/admission/facebook.png')"
                                                                                    alt="Facebook" height="24"
                                                                                    width="24">
                                                                                </a>
                                                                              </td>


                                                                            </tr>
                                                                          </tbody>
                                                                        </table>
                                                                      </td>
                                                                    </tr>
                                                                  </tbody>
                                                                </table>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>


                                                        <table align="left" border="0" cellpadding="0" cellspacing="0"
                                                          style="display:inline;border-collapse:collapse">
                                                          <tbody>
                                                            <tr>
                                                              <td valign="top" style="padding-bottom:9px">
                                                                <table border="0" cellpadding="0" cellspacing="0"
                                                                  width="100%" style="border-collapse:collapse">
                                                                  <tbody>
                                                                    <tr>
                                                                      <td align="left" valign="middle"
                                                                        style="padding-top:5px;padding-right:5px;padding-bottom:5px;padding-left:5px">
                                                                        <table align="left" border="0" cellpadding="0"
                                                                          cellspacing="0" width=""
                                                                          style="border-collapse:collapse">
                                                                          <tbody>
                                                                            <tr>

                                                                              <td align="center" valign="middle"
                                                                                width="24">
                                                                                <a :href="$countryConstants.social_networks.instagram"
                                                                                  target="_blank"
                                                                                  :data-saferedirecturl="`https://www.google.com/url?q=${$countryConstants.social_networks.instagram}&amp;source=gmail&amp;ust=1618081426907000&amp;usg=AFQjCNElip5gKH13Ja299s6M6RlP5xG2-A`"><img
                                                                                    style="display:block;border:0;height:auto;outline:none;text-decoration:none">
                                                                                  <img id="instagramLogo"
                                                                                    :src="require('@/assets/admission/instagram.png')"
                                                                                    alt="Instagram" height="24"
                                                                                    width="24">
                                                                                </a>
                                                                              </td>


                                                                            </tr>
                                                                          </tbody>
                                                                        </table>
                                                                      </td>
                                                                    </tr>
                                                                  </tbody>
                                                                </table>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>


                                                        <table align="left" border="0" cellpadding="0" cellspacing="0"
                                                          style="display:inline;border-collapse:collapse">
                                                          <tbody>
                                                            <tr>
                                                              <td valign="top"
                                                                style="padding-right:0;padding-bottom:9px">
                                                                <table border="0" cellpadding="0" cellspacing="0"
                                                                  width="100%" style="border-collapse:collapse">
                                                                  <tbody>
                                                                    <tr>
                                                                      <td align="left" valign="middle"
                                                                        style="padding-top:5px;padding-bottom:5px;padding-left:5px">
                                                                        <table align="left" border="0" cellpadding="0"
                                                                          cellspacing="0" width=""
                                                                          style="border-collapse:collapse">
                                                                          <tbody>
                                                                            <tr>

                                                                              <td align="center" valign="middle"
                                                                                width="24">
                                                                                <a :href="$countryConstants.social_networks.linkedin"
                                                                                  target="_blank"
                                                                                  :data-saferedirecturl="`https://www.google.com/url?q=${$countryConstants.social_networks.linkedin}&amp;source=gmail&amp;ust=1618081426907000&amp;usg=AFQjCNFmvXwgInKTrvqUZmaMYdQmejuwIg`"><img
                                                                                    style="display:block;border:0;height:auto;outline:none;text-decoration:none">
                                                                                  <img id="linkedinLogo"
                                                                                    :src="require('@/assets/admission/linkedin.png')"
                                                                                    alt="LinkedIn" height="24"
                                                                                    width="24">
                                                                                </a>
                                                                              </td>


                                                                            </tr>
                                                                          </tbody>
                                                                        </table>
                                                                      </td>
                                                                    </tr>
                                                                  </tbody>
                                                                </table>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>


                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>

                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </tbody>
</table>


</td>
</tr>
</tbody>
</table>

</td>
</tr>
</tbody>
</table>

</td>
</tr>
</tbody>
</table>
<div class="d-flex justify-end pa-3">
  <Button white :text="$t('actions.cancel')" @clicked="$emit('showForm')" class="mr-4"></Button>
  <Button :loading="loading" :text="$t('modules.admission.preregistration.create.emailSummary.buttons.sendSummary')"
    icon="mdi-email" @clicked="sendEmail"></Button>
</div>
</div>
</template>

<script>

import Button from '@/components/Button/Button';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { $sendEmailHtml } from '../Services';
import Configuration from '../../../../../core/constants/configuration';

export default {
  name: 'EmailSummary',
  components: {
    Button
  },
  props: {
    prepaids: Array,
    percentage: { type: Number, default: 0 },
    dues: Object,
    total: Object,
    city: Object,
    preregStudentData: Object,
    discountAmount: { type: Number, default: 0 },
    selectedPaymentMethod: { type: String },

  },
  data() {
    return {
      loading: false,
      todayDate: moment().format('DD/MM/YYYY'),
    };
  },
  computed: {
    ...mapGetters([
      'commons/getUserData'
    ]),
  },
  methods: {
    getUrl() {
      const url = Configuration.value('url')
      return url
    },

    async sendEmail() {
      this.loading = true;
      try {
        if (this.loading) {
          const html = this.$refs.emailSummary.cloneNode(true)
          html.querySelector('#logo')?.setAttribute('src', 'cid:logo')
          html.querySelector('#emailFooter')?.setAttribute('src', 'cid:logoFooter')
          html.querySelector('#facebookLogo')?.setAttribute('src', 'cid:logoFacebook')
          html.querySelector('#instagramLogo')?.setAttribute('src', 'cid:logoInstagram')
          html.querySelector('#linkedinLogo')?.setAttribute('src', 'cid:logoLinkedin')
          await $sendEmailHtml.create({
            emailDestination: this.preregStudentData.email,
            subjectEmail: this.$t('modules.admission.preregistration.create.emailSummary.subject'),
            html: html.outerHTML
          });
          this.$store.dispatch('commons/openPopUp', {
            title: this.$t('actions.success'),
            content: [{ value: this.$t('modules.admission.preregistration.create.successMessage.emailSummary') }],
            actionPrimary: {
              text: this.$t('actions.accept'), callback: () => {
                this.$emit('showForm')
              }
            },
            icon: { color: 'success', name: 'mdi-check' },
            color: 'primary',
          });
        }
      } catch (err) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('actions.error'),
          content: [{ value: this.$t('modules.admission.preregistration.create.errorMessage.emailSummary') }],
          actionPrimary: {
            text: this.$t('actions.accept'), callback() {
            }
          },
          icon: { color: 'error', name: 'mdi-close' },
          color: 'primary',
        });
        throw err;
      } finally {
        this.loading = false;
      }
    },
    getAdviserName() {
      const { name, lastname } = this['commons/getUserData'];
      return this.$options.filters.$capitalize(`${lastname}, ${name}`);
    }
  }
};
</script>

<style scoped></style>
