var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{ref:"validationpopup",staticClass:"validation-popup",attrs:{"persistent":_vm.$store.state.commons.cognitoDataValidate,"scrollable":"","max-width":"900px"},on:{"click:outside":_vm.handleClickOutside},model:{value:(_vm.renderPopUp),callback:function ($$v) {_vm.renderPopUp=$$v},expression:"renderPopUp"}},[(!_vm.statusOk)?_c('OutlinedCard',{staticClass:"border-validate-email-phone",attrs:{"title":_vm.$t("components.validateEmailPhoneAlert.title"),"subtitle":_vm.$t("components.validateEmailPhoneAlert.subtittle")}},[_c('br'),_c('v-row',[(!_vm.emailValidated)?_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('OutlinedCard',{attrs:{"title":_vm.$t(
              "components.validateEmailPhoneAlert.emailSendVerification.title"
            )}},[_c('Button',{attrs:{"text":_vm.$t(
                "components.validateEmailPhoneAlert.emailSendVerification.buttonText"
              ),"error":_vm.sendEmailButton.error,"loading":_vm.sendEmailButton.loading,"success":_vm.sendEmailButton.success,"errorText":_vm.$t(
                "components.validateEmailPhoneAlert.emailSendVerification.buttonErrorText"
              ),"successText":_vm.$t(
                "components.validateEmailPhoneAlert.emailSendVerification.sendButtonSuccessText"
              )},on:{"clicked":_vm.sendVerificationEmail}}),(_vm.sendEmailButton.errorMessage)?_c('h5',{staticStyle:{"color":"red","margin-top":"5px","margin-left":"10px"}},[_vm._v(" "+_vm._s(_vm.sendEmailButton.errorMessage)+" ")]):_vm._e()],1),_c('OutlinedCard',[_c('v-text-field',{attrs:{"error-messages":"","label":_vm.$t(
                "components.validateEmailPhoneAlert.emailCodeVerification.textFieldLabel"
              ),"outlined":""},model:{value:(_vm.emailValidationCode),callback:function ($$v) {_vm.emailValidationCode=$$v},expression:"emailValidationCode"}}),_c('Button',{attrs:{"text":_vm.$t(
                "components.validateEmailPhoneAlert.emailCodeVerification.buttonText"
              ),"error":_vm.validateEmailButton.error,"loading":_vm.validateEmailButton.loading,"success":_vm.validateEmailButton.success,"errorText":_vm.$t(
                "components.validateEmailPhoneAlert.emailSendVerification.buttonErrorText"
              ),"successText":_vm.$t(
                "components.validateEmailPhoneAlert.emailSendVerification.validateButtonSuccessText"
              )},on:{"clicked":_vm.validateEmail}}),(_vm.validateEmailButton.errorMessage)?_c('h5',{staticStyle:{"color":"red","margin-top":"5px","margin-left":"10px"}},[_vm._v(" "+_vm._s(_vm.validateEmailButton.errorMessage)+" ")]):_vm._e()],1)],1):_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"mx-auto d-flex flex-column justify-center"},[_c('h3',[_vm._v("Mail verificado")]),_c('v-icon',{attrs:{"color":"success","size":"100px"}},[_vm._v("mdi-check-circle")])],1)]),(!_vm.phoneNumberValidated)?_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('OutlinedCard',{attrs:{"title":_vm.$t(
              "components.validateEmailPhoneAlert.phoneSendVerification.title"
            )}},[_c('Button',{attrs:{"text":_vm.$t(
                "components.validateEmailPhoneAlert.phoneSendVerification.buttonText"
              ),"error":_vm.sendSmsButton.error,"loading":_vm.sendSmsButton.loading,"success":_vm.sendSmsButton.success,"errorText":_vm.$t(
                "components.validateEmailPhoneAlert.emailSendVerification.buttonErrorText"
              ),"successText":_vm.$t(
                "components.validateEmailPhoneAlert.emailSendVerification.sendButtonSuccessText"
              )},on:{"clicked":_vm.sendVerificationSms}}),(_vm.sendSmsButton.errorMessage)?_c('h5',{staticStyle:{"color":"red","margin-top":"5px","margin-left":"10px"}},[_vm._v(" "+_vm._s(_vm.sendSmsButton.errorMessage)+" ")]):_vm._e()],1),_c('OutlinedCard',[_c('v-text-field',{attrs:{"error-messages":"","label":_vm.$t(
                "components.validateEmailPhoneAlert.phoneCodeVerification.textFieldLabel"
              ),"outlined":""},model:{value:(_vm.phoneValidationCode),callback:function ($$v) {_vm.phoneValidationCode=$$v},expression:"phoneValidationCode"}}),_c('Button',{attrs:{"text":_vm.$t(
                "components.validateEmailPhoneAlert.phoneCodeVerification.buttonText"
              ),"error":_vm.validateSmsButton.error,"loading":_vm.validateSmsButton.loading,"success":_vm.validateSmsButton.success,"errorText":_vm.$t(
                "components.validateEmailPhoneAlert.emailSendVerification.buttonErrorText"
              ),"successText":_vm.$t(
                "components.validateEmailPhoneAlert.emailSendVerification.validateButtonSuccessText"
              )},on:{"clicked":_vm.validatePhoneNumber}}),(_vm.validateSmsButton.errorMessage)?_c('h5',{staticStyle:{"color":"red","margin-top":"5px","margin-left":"10px"}},[_vm._v(" "+_vm._s(_vm.validateSmsButton.errorMessage)+" ")]):_vm._e()],1)],1):_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"mx-auto d-flex flex-column justify-center"},[_c('h3',[_vm._v("Telefono verificado")]),_c('v-icon',{attrs:{"color":"success","size":"100px"}},[_vm._v("mdi-check-circle")])],1)])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }