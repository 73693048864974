<template>
  <div fluid :class="[{'v-card--disabled': payment.options.disabled}, 'pl-3 pr-3']" @click="selectPaymentMethod">
    <v-row class="payment-single" :class="{'active-payments': payment.selected, 'active-reprogram': activeReprogram}">
      <v-col :cols="(showPrepaid) ? 1 : 2" lg="1">
        <v-checkbox color="primary" :disabled="payment.options.disabled || seleccionDisabled" :input-value="payment.selected"></v-checkbox>
      </v-col>

      <v-col class="text-left description" cols="5" :sm="(showPrepaid) ? 3 : 5">
        <span>{{ payment.title }}</span>
        <span
        v-if = "(!$isMexico && systemConfig?.dues)" class="subdescription">{{ `${$t('modules.portalalumno.payments.paymentsStudents.view.labels.due')}  ${payment.options.paymentInstallment}/${payment.options.paymentInstallments}` }}</span>
        <span v-if = "$isMexico" class="subdescription">{{ `${payment.options.subdescription}` }}</span>
        <span v-if="payment.billingProduct === 81 && payment.options.term && studentData.studentType.value == 8" :style="{ fontWeight: 'normal', fontSize: '12px'}">
          {{ `Bimestre ${payment.options.term?.description}` }}
        </span>
        <span v-if="payment.billingProduct === 89 && payment.options?.term?.code" class="subdescription">{{ `${payment.options?.term?.code}` }}</span>
        <span v-if="payment.billingProduct === 87 && payment.subscription?.termCodes" class="subdescription">{{ this.$t('modules.portalalumno.payments.paymentsStudents.view.subscription.term') }} {{ payment.subscription.termCodes }}</span>
        <span v-if="payment.billingProduct === 87 && payment.subscription?.installments" class="subdescription">{{
          this.$t('modules.portalalumno.payments.paymentsStudents.view.subscription.info', { installments: payment.subscription.installments, amount: payment.subscription.amount }) }}
        </span>
        <small class="itemMandatory" v-if="$isMexico && payment.mandatory && studentData.studentType.value == 7 && payment.options.paymentInstallment === 1">*Obligatorio para matriculación</small>
        <small class="itemMandatory" v-if="$isMexico && payment.mandatory && studentData.studentType.value == 8 && payment.options.paymentInstallment === 1">*Obligatorio para re-matriculación</small>
        <small class="itemMandatory"
          v-if="(payment.billingProduct === 87 || (payment.billingProduct === 89 && payment.options.paymentInstallment === 1)) && studentData.studentType.value === 7">
          *Obligatorio para matriculación
        </small>
        <small class="itemMandatory"
          v-if="(payment.billingProduct === 87 || (payment.billingProduct === 89 && payment.options.paymentInstallment === 1)) && studentData.studentType.value === 8">
          *Obligatorio para re-matriculación
        </small>
      </v-col>

      <v-col class="amount  d-none d-md-flex"  lg="3" :style="(showPrepaid) ? 'display: none !important' : ''">
        <div class="option-bkg justify-center" v-if="payment.options && (!payment.reenrollmentId || $isIPP)">
          <div
            v-if="((payment.billingProduct === 87 || (payment.billingProduct === 89 && payment.options.paymentInstallment === 1)) && payment.suscriptionView === true)"
            class="text-center limit-date-red d-flex justify-center">{{ '¡VÁLIDO POR HOY!' }}
          </div>
          <div v-if ="payment.billingProduct != 81 && payment.billingProduct != 80 && payment.options.paymentInstallment != 1 || !$isIPP" class="text-center limit-date">{{ dueMessage }}</div>
        </div>
      </v-col>

      <v-col v-if="!showPrepaid" class="text-right amount " cols="5"  lg="3" md="3">
        <div class="option-payment pr-3" v-if="payment.options.off + payment.options.addedExtraOff > 0">
          <div class="old-price">{{ payment.options.amount | $currency }}</div>
          <div class="money-value">{{ savingAmount | $currency }}</div>
          <div class="discount-value">{{`${savingOff}% off`}}</div>
        </div>
        <div class="option-payment pr-3" v-else>
          <div class="money-value" :class="{'red-text': activeReprogram}">{{ payment.options.amount | $currency }}</div>
        </div>
      </v-col>

      <template v-else>
        <v-col class="text-right py-0 px-0 my-3" :class="{'d-none': !prepaidValid}" cols="5" sm="2"  v-for="(prepaid, index) in adjustedPrepaids"  :key="`${payment.id}-${index}`">
          <div
            class="option-payment px-3"
            :class="{'prepaid': prepaid, 'active': adjustedPrepaidsActive[filteredPrepaids.length == 3 ? index : index-1]}"
            >
            <div v-if="prepaid && !isFirstPayment" class="old-price" >{{ `Hasta el ${prepaid.dayTo}/${payment.options.month}` }}</div>
            <div v-if="prepaid" class="money-value">{{ payment.options.amount - (prepaid.percentage * payment.options.amount / 100) | $currency }}</div>
            <div v-if="prepaid" class="discount-value">{{`${prepaid.percentage}% off`}} </div>
            <div v-else class="placeholder" style="width: 100%; height: 100%;"></div> <!-- Placeholder para los elementos nulos -->
          </div>
        </v-col>

        <v-col v-if="!prepaidValid" class="amount d-sm-none d-md-flex" cols="1" sm="5">
          <div class="option-bkg" v-if="payment.options && (!payment.reenrollmentId || $isIPP )">
            <div class="text-center limit-date">{{ dueMessage }}</div>
          </div>
        </v-col>

        <v-col class="text-right amount px-0" :cols="prepaidValid ? 5 : 6" :sm="prepaidValid ? 2 : 8" :md="prepaidValid ? 2 : 3" >
          <div class="option-payment px-3" :class="{'active': prepaidsActive[3] === true}" v-if="prepaidsActive.length > 3" >
            <div class="money-value text--dark" :class="{'red-text': activeReprogram}">{{ payment.options.amount - (duePrepaidPercentage * payment.options.amount / 100) | $currency }}</div>
            <div class="discount-value">{{`${duePrepaidPercentage}% off`}} </div>
          </div>
          <div class="option-payment px-3 mr-3" v-else>
            <div class="money-value " :class="{'red-text': activeReprogram}">{{ payment.options.amount | $currency }}</div>
          </div>
        </v-col>
      </template>

    </v-row>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    payment: Object,
    systemConfig: Object,
    canReprogram: {type: Boolean, default: false},
    seleccionDisabled: {type: Boolean, default: false},
  },
  data() {
    return {
      now: moment(),
      dueDate: moment(this.payment.options.dueDate),
      prepaidsActive: [],
      prepaidValid: false,
    }
  },
  mounted() {
    this.setActivePrepaid()
  },
  computed: {
    adjustedPrepaids() { //METODO PARA COMPLETAR LOS 3 OBJETOS DE ARRAY
      const prepaids = this.filteredPrepaids?.slice();
      const nullCount = 3 - prepaids?.length;
      const nulls = Array(nullCount).fill(null);
      return [...nulls, ...prepaids];
    },
    duePrepaidPercentage() {
      let termId = this.payment.options ? this.payment.options.term?.id : null
      if (termId == 894 && this.payment.options.paymentInstallments == 6 && this.payment.options.paymentInstallment >= 4) termId = 696
      else if (termId == 960 && this.payment.options.paymentInstallments == 6 && this.payment.options.paymentInstallment >= 4) termId = 894

      if (!termId) return 0
      else if ([ 597, 630 ].includes(termId)) return 10.87  // 1A-2024 y 1B-2024
      else if ([ 663, 696 ].includes(termId)) return 8.37   // 2A-2024 y 2B-2024
      else if ([ 894, 960 ].includes(termId)) return 10.5   // 1A-2025 y 1B-2025
      return 0
    },
    filteredPrepaids() {
      if (!this.payment.options?.prepaids || !this.payment.options?.prepaids.length) return []
      return this.payment.options.prepaids
    },
    dueMessage() {
      return this.payment.status == 117 ? this.payment.options.expiration.replace('Hasta', 'Vencido') : this.payment.options.expiration
    },
    showPrepaid() {
      return (!!this.filteredPrepaids?.length) && new Date(this.payment.options.dueDate) > new Date() && !this.isFirstPayment
    },
    isArancel() {
      return !!this.payment.options.prepaids
    },
    isFirstPayment() {
      return (this.payment.options.paymentInstallment == 1 && !this.payment.options.inscriptionDate)
       || (this.payment.billingProduct == 89 && this.payment.options.paymentInstallment == 1)
       || (this.$isMexico && this.payment.billingProduct == 81 && this.payment.options.paymentInstallment == 1)
            || (
              // Alumnos inscriptos al 1A/2024 entre el 12/10 y 31/10, se muestra pronto pago sin maximo descuento en duro
              this.$isIPP &&
              this.payment.options.paymentInstallment == 1 &&
              (this.payment.options.term?.id != 597 ||
              moment(this.payment.options.inscriptionDate) < moment('2023-10-12') ||
              moment(this.payment.options.inscriptionDate) > moment('2023-10-31'))
            ) || (
              // Alumnos de mexico inscriptos del 16 al 31 de diciembre, se otorga dos cuotas con maximo descuento
              this.$isMexico &&
              [1, 2].includes(this.payment.options.paymentInstallment) &&
              this.payment.options.paymentInstallments >= 4 &&
              moment(this.payment.options.inscriptionDate).date() >= 16 &&
              moment(this.payment.options.inscriptionDate).date() <= 31
            ) || (
              this.$isIPP &&
              this.payment.options.inscriptionDate &&
              this.payment.options.term?.id == 498 &&
              [1, 2].includes(this.payment.options.paymentInstallment) &&
              moment(this.payment.options.inscriptionDate) >= moment('2023-08-08') &&
              moment(this.payment.options.inscriptionDate) <= moment('2023-08-24')
            )
    },
    activeReprogram() {
      return this.canReprogram && this.payment.selected && this.payment.repaymentAvailable
    },
    savingAmount() {
      if(!this.payment.options.accumulativeDiscount){
        return this.payment.options.amount - ((this.payment.options.off + (this.payment.options.addedExtraOff * (100 - this.payment.options.off) / 100)) * this.payment.options.amount / 100)
      }else {
        return this.payment.options.amount - ((this.payment.options.off + this.payment.options.addedExtraOff) * this.payment.options.amount / 100) < 0 ? 0 : this.payment.options.amount - ((this.payment.options.off + this.payment.options.addedExtraOff) * this.payment.options.amount / 100)
      }
    },
    savingOff() {
      if(!this.payment.options.accumulativeDiscount){
        return this.payment.options.off + (this.payment.options.addedExtraOff * (100 - this.payment.options.off) / 100)
      }else{
        return (this.payment.options?.off + this.payment.options?.addedExtraOff) > 100 ? 100 : this.payment.options?.off + this.payment.options?.addedExtraOff
      }
    },
    adjustedPrepaidsActive() {
      const activeStates = [];
      let activeFound = false

      const now = moment();

      this.filteredPrepaids.forEach((prep) => {
        const prepaidDate = this.dueDate.set('date', prep.dayTo + 1);
        const diff = prepaidDate.diff(now, 'seconds');

        if(diff > 0 && !activeFound){
          activeStates.push(true);
          activeFound = true
        }{
          activeStates.push(false);
        }
      });

      while (activeStates.length < 3) {
        activeStates.unshift(false);
      }

      return activeStates;
    },
  },
  methods: {
    setActivePrepaid() {
      if (this.filteredPrepaids?.length) {
        let activeFound = false

        this.filteredPrepaids?.forEach((prep, index) => {
          if (this.isFirstPayment) {
            if (index == 0) {
              this.prepaidsActive.push(true)
              this.payment.options.off = (this.isArancel) ? prep.percentage : 0
              this.payment.options.prepaidIndex = index
              this.payment.options.activePrepaid = true
              activeFound = true
            } else {
              this.prepaidsActive.push(false)
            }
          } else {
            const prepaidDate = this.dueDate.set('date', prep.dayTo + 1)
            const diff = prepaidDate.diff(this.now, 'seconds')

            if (diff > 0 && !activeFound) {
              this.prepaidsActive.push(true)
              this.payment.options.off = (this.isArancel) ? prep.percentage : 0
              this.payment.options.prepaidIndex = index
              this.payment.options.activePrepaid = true
              activeFound = true
            } else {
              this.prepaidsActive.push(false)
            }
          }
        })

        // Descuento excepcional aplicado a todo RI a partir del 1A/2024 ingresado previo al 2024
        if (this.$isIPP && this.isArancel && moment(this.payment.options.inscriptionDate) < moment('2023-11-01') && this.duePrepaidPercentage > 0) {
          this.prepaidsActive.push(!activeFound)
          if (!activeFound) this.payment.options.off = this.duePrepaidPercentage
          activeFound = true
        }

        this.prepaidValid = activeFound && this.isArancel
      }
    },
    selectPaymentMethod() {
      if (!this.payment.options.disabled && !this.seleccionDisabled) {
        this.$emit('updatepaymentmethod', !this.payment.selected);
      }
    },
    selectAllFullDiscounts() {
      if (this.savingAmount == 0) {
        this.$emit('disableAmountZero', this.seleccionDisabled);
      }
    },
    getFormattedDate(date, format = 'DD-MM-YYYY') {
      return moment(date).format(format)
    },
  }
};
</script>

<style scoped  lang="sass">

  .payment-single
    font-family: 'Roboto'
    font-size: 12px
    font-weight: bold
    height: 90px
    border-radius: 5px
    box-shadow: 0 2px 2px 0 rgba(1, 33, 105, 0)
    border: solid 1px #d9d9d9
    background-color: white
    margin-bottom: 10px !important

    &.active-reprogram
      border: solid 1px #ff5252

    &:hover
      box-shadow: 0 8px 14px 0 rgba(0, 44, 142, 0.15)
      border: solid 1px  var(--v-primary-base)
      cursor: pointer

    &.active-payments, &:hover

      .active
        background-color: #d9e2fa

  .description
    display: flex
    flex-direction: column
    justify-content: space-around
    span
      font-family: 'DM Sans'
      font-size: 16px
      line-height: 1.2
      letter-spacing: -0.48px
      text-align: left
      color: #212121
      margin-bottom: 0

      &.subdescription
        font-family: 'Roboto'
        font-size: 12px
        color: rgba(33, 33, 33, 0.5)
        line-height: 1.2
        letter-spacing: -0.48px
        text-align: left
        mergin-top: -0.48px
        margin-bottom: 0

  .limit-date
    line-height: 1.33
    letter-spacing: -0.54px
    color: rgba(33, 33, 33, 0.5)
    width: 100%

  .limit-date-red
    line-height: 1.33
    letter-spacing: -0.54px
    color: #2d9400
    background: #E8F5E9
    width: auto
    padding: 10px
    border-radius: 5px

  .itemMandatory
    color:  var(--v-primary-base)

  .option-payment
    height: 100%
    display: flex
    flex-direction: column
    justify-content: space-around

  .old-price
    line-height: 1.21
    color: rgba(33, 33, 33, 0.5)
    text-decoration: line-through

  .money-value
    font-family: 'DM Sans'
    font-size: 18px
    line-height: 1.33
    letter-spacing: -0.54px
    color: var(--v-primary-base)

    &.red-text
      color: #f72b2b !important

  .discount-value
    line-height: 1.17
    color: #2d9400

  .option-bkg
    width: 100%
    height: 100%
    display: flex
    align-items: center

  .prepaid
    border-right: 1px solid #d9d9d9

    .old-price
      text-decoration: none

  .text--dark
    color: #212121 !important

</style>
