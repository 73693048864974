import { Api } from '../api';
import { createUrl } from '../../helpers/uri-factory';

const url = createUrl('pricing');

export class Prepaids extends Api {
  constructor(http, config = {}) {
    super(http, url, 'prepaids' , config);
  }
   /**
   * @param  {string} apiMethod=null - api method
   * @param  {object} filter=null - filter object
   * @param  {object} parameters=null - parameters object
   * @param  {object} config={} - axios' configuration object
   * @return {Promise<resource>}
   */
   async findFiltered(apiMethod = null, filter = null, parameters, config = {}) {
    config = { ...this._config, ...config };
    const uri = this._uriConstructor(null, apiMethod, filter);
    return this._http.post(uri, parameters, config);
  }
}
