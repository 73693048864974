import ExtraBenefit from '../ExtraBenefit.vue'
import ExtraBenefitTable from '../Views/ExtraBenefitTable.vue'
import ExtraBenefitCreate from '../Views/ExtraBenefitCreate.vue'
import ExtrabeBenefitUpdate from '../Views/ExtraBenefitUpdate.vue'

const extraBenefitRoutes = {
  path: '/extra-benefits',
  meta: {
    name: {
      es: 'Beneficio Extra',
      en: 'Extra Benefit'
    }
  },
  component:  ExtraBenefit,
  children: [
    {
      path: '',
      component: ExtraBenefitTable,
      meta: { name: 'Index', group: 'portaladministrativo.prices', path: 'extraBenefits', permission: 'READ' }
    }, {
      path: 'create',
      component: ExtraBenefitCreate,
      meta: { name: 'ExtraBenefitCreate', group: 'portaladministrativo.prices', path: 'extraBenefits', permission: 'CREATE' }
    }, {
      path: 'update/:id',
      component: ExtrabeBenefitUpdate,
      meta: { name: 'ExtraBenefitUpdate', group: 'portaladministrativo.prices', path: 'extraBenefits', permission: 'UPDATE' }
    }
  ]
}

export default extraBenefitRoutes
