import ProcessingsView from '../Views/ProcessingsView'
import ProcessingsCreate from '../Views/ProcessingsCreate'
import ProcessingsUpdate from '../Views/ProcessingsUpdate'

const ProcessingsRoute = [
    {
        path: '/',
        component: ProcessingsView,
        meta: { name: 'Index', group: 'portaladministrativo.academic', path: 'processings', permission: 'READ' }
    }, {
        path: 'create',
        component: ProcessingsCreate,
        meta: { name: 'Processings Create', group: 'portaladministrativo.academic', path: 'processings', permission: 'CREATE' }
    }, {
        path: 'update/:id',
        component: ProcessingsUpdate,
        meta: { name: 'Processings Update', group: 'portaladministrativo.academic', path: 'processings', permission: 'READ' }
    }
]

export default ProcessingsRoute