<template>
  <v-container fluid class="superTable">
    <v-row no-gutters>
      <v-col sm="4">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          :placeholder="$t('actions.search')"
          outlined
          clearable
          class="superTable__search"
          @click:clear="search = '';keyup()"
          @keyup="keyup"
          @keyup.esc="search = ''"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col sm="12">
        <v-data-table
          v-model="selectedItems"
          :loading="loading"
          :show-select="true"
          :headers="headers"
          :items="users"
          :page="page"
          :items-per-page="itemsPerPage"
          :multi-sort="false"
          :totalItems="totalItems"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          @update:sort-by="$emit('update:sortBy', Array.isArray($event) ? $event[0] : $event)"
          @update:sort-desc="$emit('update:sortDesc', Array.isArray($event) ? $event[0] : $event)"
          @click:row="(val) => $emit('click:row', val.id)"
          hide-default-footer
          item-key="id"
          class="superTable__table Scholarships-supertable"
          style="table-layout: fixed"
        >
          <template v-slot:header.data-table-select="">
           <div></div>
          </template>

          <template v-slot:item.data-table-select="{ item, isSelected, select }">
            <v-simple-checkbox
              v-show="(canSelect || isSelected) && showSelect(item)"
              class="superTable__table__item__checkbox"
              color="primary"
              :value="item.isSelected"
              @input="selectRow($event, item, select)"
            ></v-simple-checkbox>
          </template>

          <template v-slot:item.name="{item}">
            <div class="superTable__table__item__name">
              <v-avatar size="32" class="mr-4">
                <img :src="item.avatar" />
              </v-avatar>
              <div>{{ item.name }} {{ item.lastname }}</div>
            </div>
          </template>

          <template v-slot:item.action="{item}">
            <v-menu bottom left>
              <template v-slot:activator="{ on: menu }">
                <v-tooltip top>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-icon
                      v-on="{ ...tooltip, ...menu }"
                      class="superTable__table__item__action"
                    >mdi-dots-vertical</v-icon>
                  </template>
                  <span>{{ $t('modules.scholarships.table.message.actions') }}</span>
                </v-tooltip>
              </template>
              <v-list class="pa-0">
                <v-list-item @click="$emit('removeScholarship', item.id)">
                  <v-list-item-title>
                    <v-icon>mdi-cancel</v-icon>
                    {{$t('modules.scholarships.details.table.delete')}}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>

          <template v-slot:item.average="{item}">
            <v-text-field
              :label="item.value"
              type="number"
              placeholder="0"
              min="0"
              :max="$isIPP ? $countryConstants.maxAverage : '10'"
              single-line
              outlined
              hide-details
              @keypress="$validateDecimalInputNumber($event, item.average, $countryConstants.maxAverage)"
              :error-messages="(item.average < 0 || item.average > $countryConstants.maxAverage)? 'Error' : ''"
              class="my-2"
              v-model="item.average"
              ref="textfield"
            ></v-text-field>
          </template>

        </v-data-table>
        <v-divider class="mt-0 mb-8"></v-divider>
      </v-col>
    </v-row>
    <v-row no-gutters align="center">
      <v-col sm="3">
        <span
          class="caption d-inline-block"
        >{{ itemsCount.startItem + ' - ' + itemsCount.endItem + ' de ' + itemsCount.totalItems }}</span>
        <v-select
          v-model="itemsPerPage"
          :items="itemsPerPageAvailable"
          :placeholder="itemsPerPage.toString()"
          outlined
          @change="$emit('lengthPaginationChange', itemsPerPage)"
          class="superTable__paginationSelect d-inline-block ml-4"
        ></v-select>
      </v-col>
      <v-col sm="6" class="text-center">
        <v-pagination
          @next="$emit('nextPagination', page)"
          @previous="$emit('previousPagination', page)"
          @input="$emit('inputPagination', $event)"
          v-model="page"
          :length="pageCount"
          class="superTable__pagination"
        ></v-pagination>
      </v-col>
      <v-col sm="3" align="right">
        <v-text-field
          :value="page"
          ref="directPage"
          :max="pageCount"
          outlined
          @change="directPageInput($event)"
          class="superTable__paginationInput d-inline-block mr-4"
        ></v-text-field>
        <span class="caption d-inline-block">{{$t('modules.scholarships.table.search.gotopage')}}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Constants from '@/plugins/constants'

export default {
  name: 'SuperTableAssign',
  props: {
    users: Array,
    headers: Array,
    loading: Boolean,
    clearSelected: String,
    canSelect: Boolean,
    totalItems: {
      type: Number,
      default: 0
    },
    pageCount: Number,
    sortBy: String,
    sortDesc: Boolean,
    allowItemDeletion: {
      type: Boolean,
      default: true
    },
    withAverage: {
      type: Boolean,
      default: () => false,
    },
    minAverage: {
      type: Number,
      default: () => false,
    }
  },
  data() {
    return {
      selectedItems: [],
      search: '',
      items: [],
      page: 1,
      itemsPerPage: 20,
      itemsPerPageAvailable: [20, 50, 100, 200],
      markedUsers: []
    };
  },
  methods: {
    keyup () {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.searchAction()
        clearTimeout(this.timeout);
      },1000)
    },
    searchAction() {
      this.$emit('searchAction', this.search)
    },
    showSelect(item) {
      if (this.withAverage) return item.average != null && item.average >= 0 && item.average <= this.$countryConstants.maxAverage
      else return true
    },
    getAvatar(id) {
      return `${Constants.URL_DATA_SERVER}/users/${id}/avatar`;
    },
    itemsToDelete() {
      this.$emit('bulk-delete', [...this.selectedItems]);
      this.selectedItems = [];
    },
    openDetails(id) {
      this.$router.push(`scholarships/details/${id}`);
    },
    selectRow(event, item, callback) {
      item.isSelected = !item.isSelected;
      this.$emit('toggleItem', item);
      callback(event);
    },
    completeAverage(item) {
      if (item.average != null) {
        // item.isSelected = true
        // this.$emit('toggleItem', item);
      }
    },
    directPageInput(event) {
      let newPage = +event;

      if(newPage > +this.pageCount) newPage = +this.pageCount;
      else if(newPage < 1 || +this.pageCount == 1) newPage = 1;

      this.page = newPage;
      this.$refs.directPage.value = this.page;
      this.$emit('inputPagination', this.page);
    }
  },
  watch: {
    page(val) {
      if (val > this.pageCount) this.page = this.pageCount;
      if (val <= 1) this.page = 1;
    },
    clearSelected() {
      this.selectedItems = [];
    },
  },
  computed: {
    areItemsSelected() {
      return !!this.selectedItems.length;
    },
    itemsCount() {
      const counter = {
        totalItems: this.totalItems,
        startItem: this.page * this.itemsPerPage - this.itemsPerPage + 1,
        endItem: this.page * this.itemsPerPage
      };
      if (counter.endItem > this.totalItems) {
        counter.endItem = this.totalItems;
      }
      return counter;
    }
  },
};
</script>

<style lang="sass">
  .Scholarships-supertable
    table tr
      // cursor: pointer !important
</style>
