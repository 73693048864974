<template>
  <div>
    <v-container fluid class="pa-0 pb-8">
      <template>
        <v-container class="container-custom" >
          <Breadcrumbs v-if="!newPriceSuccess && !newPriceError"
            :title="$t('modules.prices.create.breadcrumbs.title')"
            :description="$t('modules.prices.create.breadcrumbs.subtitle')"
          ></Breadcrumbs>
        </v-container>
      </template>
      <template v-if="!newPriceSuccess && !newPriceError">
      <v-container class="container-custom ">
        <template>
          <v-row justify="center">
            <v-col class="viewSpaces" sm="12">
              <OutlineCard
                :title="$t('modules.prices.create.section_product.title')"
                :subtitle="$t('modules.prices.create.section_product.subtitle')"
                :switchLabel="formattedPriceStatus"
                switcher
                :switchValue="status"
                @switchChange="status = !status"
                class="mb-6"
              >
                <v-row>
                  <v-col sm="4" class="py-0">
                    <v-select
                      :error-messages="$v.selectedBillingProduct.$dirty && $v.selectedBillingProduct.$invalid ? $t('modules.prices.create.section_product.product_error'):''"
                      :items="billingProducts"
                      @blur="$v.selectedBillingProduct.$touch()"
                      class="mt-3"
                      item-text="name"
                      :label="$t('modules.prices.create.section_product.product')"
                      outlined
                      return-object
                      v-model="$v.selectedBillingProduct.$model"
                    ></v-select>
                  </v-col>
                  <v-col sm="4">
                    <v-menu
                      :close-on-content-click="false"
                      :return-value.sync="$v.date.$model"
                      min-width="290px"
                      offset-y
                      ref="menu"
                      transition="scale-transition"
                      v-model="menu"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :error-messages="$v.date.$invalid && $v.date.$dirty ? $t('modules.prices.create.section_zone.items.date_error'):''"
                          :label="$t('modules.prices.create.section_product.from')"
                          append-icon="mdi-calendar"
                          outlined
                          readonly
                          v-model="$v.date.$model"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :min="(()=>(new Date().toISOString().substr(0, 10)))()"
                        color="primary"
                        no-title
                        scrollable
                        v-model="$v.date.$model"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                        <v-btn @click="$refs.menu.save($v.date.$model)" color="primary" text>OK</v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <div class="d-flex justify-end" v-if="canCreateProducts">
                  <Button
                    @clicked="productsDrawer.open = true"
                    :text="$t('modules.prices.create.section_product.new_product')"
                    depressed
                    outlined
                    class="px-6 body-2"
                  ></Button>
                </div>
              </OutlineCard>

              <!-- CAREERS -->
              <OutlineCard
                :title="$t('modules.prices.create.section_career.title')"
                :subtitle="$t('modules.prices.create.section_career.subtitle')"
                class="mb-6"
              >
                <v-col class="d-flex px-0" cols="12" sm="6">
                  <v-select
                    :items="careersFilters"
                    item-text="meaning"
                    :label="$t('modules.prices.create.section_career.study_type')"
                    outlined
                    return-object
                    v-model="selectedCareerFilter"
                  ></v-select>
                </v-col>
                <TransferList
                  :avatar="false"
                  :loading="loadingSearchCareers"
                  :availableItems.sync="filteredCareers"
                  :added-items.sync="careersAdded"
                  :firstListTitle="$t('modules.prices.create.section_career.transferList.firstTitle')"
                  :secondListTitle="$t('modules.prices.create.section_career.transferList.secondTitle')"
                  areEquals="id"
                  @searchItemLeftOnBackend="searchCareers"
                  @searchItemRightOnBackend="searchCareersAdded"
                ></TransferList>
                <v-alert
                  text
                  type="warning"
                  class="mt-4"
                  v-if="careersAdded.length === 0"
                >{{ $t('modules.prices.create.section_career.no-selected-warning') }}</v-alert>
              </OutlineCard>

              <!--TIPO ESTUDIANTE-->
              <OutlineCard :title="$t('modules.prices.create.section_student.title')" class="mb-6">
                <div>
                  <div class="d-flex">
                    <v-checkbox
                      :key="index"
                      :label="studentType.meaning"
                      :value="studentType"
                      class="mr-8 mt-0 no-message"
                      color="primary"
                      v-for="(studentType, index) in studentTypes"
                      v-model="$v.selectedStudentType.$model"
                    ></v-checkbox>
                  </div>
                  <v-alert
                    text
                    type="error"
                    v-if="$v.selectedStudentType.$anyError"
                  >{{$t('modules.prices.create.section_student.error')}}</v-alert>
                </div>
              </OutlineCard>

              <!--MODALIDADES-->
              <OutlineCard :title="$t('modules.prices.create.section_modality.title')" class="mb-6">
                <div>
                  <div class="d-flex">
                    <v-checkbox
                      :key="index"
                      :label="modalityType.meaning"
                      :value="modalityType"
                      class="mr-8 mt-0"
                      color="primary"
                      v-for="(modalityType, index) in modalityTypes"
                      v-model="$v.selectedModalityTypes.$model"
                    ></v-checkbox>
                  </div>
                  <v-alert
                    text
                    type="error"
                    v-if="$v.selectedModalityTypes.$anyError"
                  >{{$t('modules.prices.create.section_modality.error')}}</v-alert>
                </div>
              </OutlineCard>

              <!--REGIONES Y PRECIOS-->
              <OutlineCard :title="$t('modules.prices.create.section_zone.title')" class="mb-6">
                <v-row no-gutters>
                  <v-col sm="8">
                    <MultipleFormGroup
                      :items="$v.groups.$each.$iter"
                      :length="groups.length"
                      @addGroup="addGroup"
                      @removeGroup="removeGroup"
                      canAddGroup
                    >
                      <template v-slot:default="{item}">
                        <v-container fluid class="pa-0">
                          <v-row>
                            <v-col class="pb-0" sm="6">
                              <v-select
                                :error-messages="(item.zone.$invalid && item.zone.$dirty && $t('modules.prices.create.section_zone.items.zone_error')) || '' "
                                :items="zonesAvailable(item.zone.$model ? item.zone.$model.id : null)"
                                :label="$t('modules.prices.create.section_zone.items.zone')"
                                @blur="item.zone.$touch()"
                                item-text="zoneDescription"
                                outlined
                                return-object
                                v-model="item.zone.$model"
                              ></v-select>
                            </v-col>
                            <v-col class="pb-0" sm="4">
                              <v-text-field
                                :error-messages="(item.price.$invalid && item.price.$dirty && $t('modules.prices.create.section_zone.items.price_error')) || ''"
                                :label="$t('modules.prices.create.section_zone.items.price')"
                                :min="0"
                                outlined
                                type="number"
                                @keypress="$validateDecimalInputNumber($event, item.price.$model)"
                                v-model="item.price.$model"
                              >
                                <template v-slot:prepend>
                                  <span>{{ $countryConstants.currency }}</span>
                                </template>
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>
                      </template>
                    </MultipleFormGroup>
                  </v-col>
                </v-row>
                <div class="d-flex justify-end" v-if="canCreateZones">
                  <Button
                    @clicked="regionDrawer.open = true"
                    :text="$t('modules.prices.create.section_zone.new_zone')"
                    depressed
                    outlined
                    class="px-6 body-2"
                  ></Button>
                </div>
              </OutlineCard>
              <div class="d-flex justify-end">
                <v-btn text rounded large class="cancelbutton mr-3" @click="goBack">{{$t('modules.prices.create.buttons.cancel')}}</v-btn>
                <Button
                  :disabled="!canCreate || $v.formValid.$anyError"
                  :loading="sendingNewPrice"
                  :success="newPriceSuccess"
                  :error="newPriceError"
                  :errorText="$t('modules.prices.create.buttons.error')"
                  :text="$t('modules.prices.create.buttons.create')"
                  :successText="$t('modules.prices.create.buttons.created')"
                  @clicked="createPrice"
                  class="crearbutton"
                  large
                  rounded
                  primary
                ></Button>
              </div>
            </v-col>
          </v-row>
        </template>
      </v-container>
      </template>
      <SuccessMessage
        v-else
        :title="successMessage.title"
        :type="successMessage.type"
        :actionPrimary="successMessage.actionPrimary"
      />
      <TemporaryRightDrawer
        v-if="canCreateZones"
        @tmpDrawerOpen="regionDrawerClose"
        :open="regionDrawer.open"
        :width="80"
        :title="$t('modules.prices.create.section_zone.new_zone')"
        :description="$t('modules.prices.create.section_zone.new_zone_description')"
      >
        <ZonesCreate
          v-if="regionDrawer.open"
          :insideDrawer="true"
          @closeDrawer="regionDrawerClose"
        ></ZonesCreate>
      </TemporaryRightDrawer>
      <TemporaryRightDrawer
        v-if="canCreateProducts"
        @tmpDrawerOpen="productsDrawerClose"
        :open="productsDrawer.open"
        :title="$t('modules.prices.create.section_product.new_product')"
        :description="$t('modules.prices.create.section_product.new_product_description')"
      >
        <ProductsCreate
          v-if="productsDrawer.open"
          :insideDrawer="true"
          @closeDrawer="productsDrawerClose"
        ></ProductsCreate>
      </TemporaryRightDrawer>
    </v-container>
  </div>
</template>


<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import OutlineCard from '@/components/OutlinedCard/OutlinedCard';
import TransferList from '@/components/TransferList/TransferList';
import MultipleFormGroup from '@/components/MultipleFormGroup/MultipleFormGroup';
import Button from '@/components/Button/Button';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import ZonesCreate from '#/portaladministrativo/Zones/views/ZonesCreate'
import ProductsCreate from '#/portaladministrativo/Products/views/ProductsCreate'
import TemporaryRightDrawer from '@/components/TemporaryRightDrawer/TemporaryRightDrawer'
import { $careers, $prices, $products, $types, $zones } from '../Services';
import { required } from 'vuelidate/lib/validators'

const arrayNotEmpty = (array) => {
  return array.length
};
export default {
  components: {
    Breadcrumbs,
    OutlineCard,
    TransferList,
    MultipleFormGroup,
    Button,
    SuccessMessage,
    ZonesCreate,
    ProductsCreate,
    TemporaryRightDrawer
  },
  data () {
    return {

      regionDrawer: {
        open: false,
      },
      productsDrawer: {
        open: false,
      },
      userAccept: false,

      // INFO VARS
      
      loading: false,
      saving: false,
      errorOnFetch: false,
      sendingNewPrice: false,
      newPriceError: false,
      newPriceSuccess: false,
      
      // SELECTABLE DATA VARS

      billingProducts: [],
      selectedCareerFilter: {
            meaning: 'Todos',
            type: 'CAREER_TYPE',
            value: 0
          },
      careersFilters: [],
      availableCareers: [],
      filteredCareers: [],
      studentTypes: {},
      modalityTypes: [],

      zones: [],
      zonesSelected: [],
      careers: {},
      careersAdded: [],

      // NEW OBJECT DATA

      paginationLimit: 150,
      loadingSearchCareers: false,
      status: true,
      selectedBillingProduct: {},
      date: new Date().toISOString().substr(0, 10),
      selectedCareers: [],
      selectedModalityTypes: [],
      selectedStudentType: [],
      groups: [
        {
          zone: null,
          price: null,
        }
      ],
      //OTHERS ?

      menu: null,
      
      successMessage: {
        title: '',
        type: '',
        subtitle: '',
        actionPrimary: { text: '', callback: () => { this.$router.push('/prices') } },
      },
    }
  },
  validations: {
    selectedBillingProduct: { required },
    date: { required },
    selectedCareers: { arrayNotEmpty },
    selectedModalityTypes: { arrayNotEmpty }, 
    selectedStudentType: { arrayNotEmpty },
    groups: {
      $each:
      {
        zone: { required },
        price: { required },
      }
    },

    formValid: ['selectedBillingProduct', 'selectedModalityTypes', 'selectedStudentType', 'groups', 'date']
  },
  methods: {
    
    zonesAvailable (idZoneSelected) {

      const zones = this.zones.filter((zone)=>{
        let found = false;
        this.groups.forEach((group)=>{
          if (group.zone && zone.zoneDescription === group.zone.zoneDescription && group.zone.id !== idZoneSelected){
            found = true;
            return null;
          }
        });
        if(found){
          return null;
        }else{
          return zone;
        }
        
      });
      return zones  
    },

    async regionDrawerClose () {
      
      this.loading = true;
      this.regionDrawer.open = !this.regionDrawer.open;

      const allData = Promise.all([
        $zones.find(null, null, { params: { page: 0, length: 500 } }),
      ]);

      try {
        const [zones] = await allData;

        this.zones = zones.data.content;
      } catch (error) {
        this.errorOnFetch = true;
        throw error;
      }

    },
    async productsDrawerClose () {

      this.loading = true;
      this.productsDrawer.open = false;

      const allData = Promise.all([
        $products.find(null, null, { params: { page: 0, length: 500 } }),
      ]);

      try {
        const [products] = await allData;
        this.billingProducts = products.data.content;
      } catch (error) {
        this.errorOnFetch = true;
        throw error;
      }

    },
    goBack () {
      if (window.history.length > 1) {
        const getCurrentRoute = this.$route.matched[this.$route.matched.length - 1];
        if (getCurrentRoute.meta.name !== 'Index') {
          this.$router.go(-1)
        } else {
          this.$router.push('/')
        }
      }
    },
    removeItems (removedItems) {
      removedItems.forEach(removedItem => {
        const index = this.selectedItems.indexOf(removedItem);
        this.selectedItems.splice(index, 1);
      })
    },
    removeGroup (index) {
      this.groups.splice(index, 1)
    },
    addGroup () {
      const mockup =
      {
        zone: null,
        price: null,
      };
      this.groups.push({
        ...mockup
      })
    },
    async createPrice () {
      if (!this.canCreate) return false
      if (this.status || this.userAccept) {
        this.sendingNewPrice = true;
        const array = [];
        this.selectedStudentType.forEach(studentType => {
          this.selectedModalityTypes.forEach(modalityType => {
            this.groups.forEach(group => {
              array.push({
                zone: { id: group.zone.id },
                amount: Number(group.price),
                studentType: { value: studentType.value },
                modalityType: { value: modalityType.value }
              })
            })
          })
        });
        const newPrice = {
          billingProducts: { id: this.selectedBillingProduct.id },
          from: this.date,
          status: this.status,
          careers: this.careersAdded.map(career => ({ id: career.id })),
          priceDetails: array,
        };

        try {
          await $prices.create(newPrice)
          this.successMessage.title = this.$t('modules.prices.create.success_title');
          this.successMessage.type = 'success';
          this.successMessage.actionPrimary = { 
            text: this.$t('modules.prices.create.accept'),
            callback: ()=>{this.$router.push('/prices')}
          };
          this.newPriceSuccess = true;
          
        } catch (error) {
          this.successMessage.title = this.$t('modules.prices.create.error_title');
          this.successMessage.type = 'error';
          this.successMessage.actionPrimary.text = this.$t('modules.prices.create.go_to_back');
          this.successMessage.actionPrimary.callback = () => {this.newPriceError = false;}
          this.newPriceError = true;
        } finally {
          this.sendingNewPrice = false;
        }
      } else {
        this.$store.dispatch('commons/openPopUp', {

              title: this.$t('modules.prices.create.warning_popup.title'),
              content: [
                {
                  value: this.$t('modules.prices.create.warning_popup.content')
                }
              ],
              actionPrimary: {
                text: this.$t('modules.prices.create.buttons.create'),
                callback: () => {
                  this.userAccept = true;
                  this.createPrice()
                }
              },
              actionSecondary: {
                text: this.$t('modules.prices.create.buttons.cancel'),
                callback() {}
              },
              icon: { color: 'warning', name: 'mdi-eye-off-outline' },
              color: 'primary',
            })
      }
    },
    searchCareers (event) {
      this.getCareers(event);
    },
    searchCareersAdded (event) {
      this.careersAdded = event
    },
    async getCareers (career) {
      this.loadingSearchCareers = true
      const params = { length: this.paginationLimit }
      if (career) params.name = career
      const { data } = await $careers.find(null, null, { params })

      if (data && data.content.length) {
        const careers = data.content.map(item => {
          return {
            id: item.id,
            name: item.legalDescription,
            careerType: item.careerType
          }
        })

        // CAREERS CATEGORIES
        this.careersFilters.forEach(filter => {
          this.careers[filter.meaning] = careers.filter(item => filter.meaning == 'Todos' || item.careerType.value == filter.value)
        })

        this.filteredCareers = careers
        this.availableCareers = careers
      }
      
      this.loadingSearchCareers = false;
    },

    async fetchData () {
      const allData = Promise.all([
        $zones.find(null, null, { params: { page: 0, length: 500,orderBy: 'id', orientation: 'desc' } }),
        $products.find(null, null, { params: { page: 0, length: 500,orderBy: 'id', orientation: 'desc' } }),
        $types.find(null, null, { params: { type: 'CAREER_TYPE' } }),
        $types.find(null, null, { params: { type: 'STUDENT_TYPE' } }),
        $types.find(null, null, { params: { type: 'MODALITY_TYPE' } }),
      ]);

      try {
        const [zones, products, careerType, studentType, modalityType] = await allData;
        this.zones = zones.data.content;
        this.billingProducts = products.data.content;
        this.careersFilters = careerType.data;
        this.careersFilters.push(
          {
            meaning: 'Todos',
            type: 'CAREER_TYPE',
            value: 0
          }
        );
        this.studentTypes = studentType.data;
        this.modalityTypes = modalityType.data;
        this.getCareers()
      } catch (error) {
        this.errorOnFetch = true;
        throw error;
      }
    }
  },
  watch: {
    'selectedCareerFilter': function (filter) { 

      this.filteredCareers = this.careers[filter.meaning].filter(career=>{
        return !this.careersAdded.find(added=>{
          return added.id === career.id;
        })
      });

    },
    'careersAdded': function () {

      this.selectedCareerFilter = {
            meaning: 'Todos',
            type: 'CAREER_TYPE',
            value: 0
          };
    }
  },
  mounted () {
    this.fetchData();
  },
  computed: {
    canCreate() {
      return this.$permissions.portaladministrativo.prices.prices.CREATE
    },
    canCreateProducts() {
      return this.$permissions.portaladministrativo.prices.products.CREATE
    },
    canCreateZones() {
      return this.$permissions.portaladministrativo.prices.zones.CREATE
    },
    formattedPriceStatus () {
      return this.status ? this.$t('modules.prices.update.section_product.enabled') : this.$t('modules.prices.update.section_product.disabled')
    },
    getZonesAvailable () {
      const zones = this.zones.filter((zone)=>{
        this.groups.forEach((group)=>{
          if(group.zone && zone.zoneDescription === group.zone.zoneDescription){
            return null;
          }
        });
        return zone;
      });

      return zones
    },
  }
}
</script>
