const CommunitiesTranslations = {
    es: {
        create: {
            breadcrumbs: {
                title: 'Nueva empresa',
                subtitle:'Crea una nueva empresa',
                button: {
                    icon: 'mdi-pencil',
                    name: 'Editar',
                    color: 'primary',
                }
            },
            formErrors: {
                bussinessname: 'Ingresa una razón social',
                activity: 'Ingresa un giro comercial',
                token: 'Ingresa un token',
                cuit: 'Ingresa un RUT válido',
                benefit: 'Selecciona un beneficio'
            },
            card: {
                title: 'Datos de la empresa',
                subtitle: 'Agrega la información básica de la nueva empresa. Recuerda que estos datos deben ser lo más descriptivos posible ya que serán utilizados para futuros filtros de búsqueda.',
                businessname: 'Razón social',
                activity: 'Giro comercial',
                token: 'Token landing',
                cuit: 'RUT',
                benefit: 'Beneficio',
                comission: 'Paga comisión',
                percent: 'Porcentaje',
                amount: 'Monto',
                client: 'Cliente empresa',
                enabled: 'Habilitada',
                disabled: 'Deshabilitada',
                or: 'ó',
            },
            warning_popup: {
                title: 'Se está creando la empresa deshabilitada',
                content: '',
                create: 'Crear',
                cancel: 'Cancelar'
            },
            success_message: {
                title: 'Empresa creada correctamente',
                actions: {
                    primary_text: 'Cerrar',
                    secondary_text: 'Crear otro',
                    back: 'Volver'
                },
            },
            error_message: {
                title: 'Error al crear la empresa',
            }
        },
        update: {
            breadcrumbs: {
                title: 'Editar empresa',
                subtitle:'Modifica la empresa seleccionada',
                button: {
                    icon: 'mdi-pencil',
                    name: 'Editar',
                    color: 'primary',
                }
            },
            formErrors: {
                bussinessname: 'Ingresa una Razón Social',
                activity: 'Ingresa un giro comercial',
                cuit: 'Ingresa un RUT válido',
                benefit: 'Selecciona un beneficio'
            },
            card: {
                title: 'Datos de la empresa',
                businessname: 'Razón Social',
                activity: 'Giro Comercial',
                token: 'Token landing',
                cuit: 'RUT',
                benefit: 'Beneficio',
                comission: 'Paga comisión',
                percent: 'Porcentaje',
                amount: 'Monto',
                client: 'Cliente Empresa',
                enabled: 'Habilitado',
                disabled: 'Deshabilitado',
                or: 'ó'
            },
            warning_popup: {
                title: 'Se está guardando la empresa deshabilitada',
                content: '',
                update: 'Guardar',
                cancel: 'Cancelar'
            },
            success_message: {
                title: 'Empresa actualizada correctamente',
                actions: {
                    primary_text: 'Cerrar',
                    back: 'Volver'
                },
            },
            error_message: {
                title: 'Error al actualizar la empresa',
            }
        },
        table: {
            breadcrumbs: {
                title: 'Comunidad',
                subtitle:'Administración de empresas',
                button: {
                    icon: 'mdi-pencil',
                    name: 'Editar',
                    color: 'primary',
                }
            },
            headers: {
                company: 'Razón Social',
                activity: 'Giro Comercial',
                status: 'Estado',
                benefit: 'Beneficio'
            },
            actions: {
                delete: '¿Estás seguro de eliminar?',
                errorDelete: 'Error al eliminar'
            },
            bulkdelete: 'Eliminar empresas',
            delete: 'Eliminar',
            edit: 'Editar',
            search: 'Buscar',
            goToPage: 'Ir a página'
        }
    },
    en: {
        table: {
            breadcrumbs: {
                title: 'Community',
                subtitle: 'Manage companies',
                button: {
                    icon: 'mdi-pencil',
                    name: 'Edit',
                    color: 'primary',
                }
            },
            bulkdelete: 'Delete companies',
            delete: 'Delete',
            edit: 'Edit',
            search: 'Search'
        }
    }
};


export default CommunitiesTranslations
