import PaymentStudents from './PaymentsStudents/Payments.vue';
import PaymentsStudentsRoutes from './PaymentsStudents/Routes'
import CheckingAccount from './CheckingAccount/CheckingAccount.vue'
import CheckingAccountRoute from './CheckingAccount/Routes';
import PaymentsView from './Payments.vue';
import ContractsView from './PaymentsContracts/View/ContractsView.vue';
import DisplayContract from './PaymentsContracts/Components/DisplayContract/DisplayContract.vue';

const paymentRoutes = {
  path: 'payments',
  meta: {
    name: {
      en: 'Payments',
      es: 'Pagos'
    },
  },
  component: PaymentsView,
  children: [
        {
        path: '/',
        meta: {name: {es: 'Pagos', en: 'Payments'}},
        component: PaymentStudents,
        children: PaymentsStudentsRoutes
      }, {
        path: 'checking-account',
        meta: {name: {es: 'Cuenta corriente', en: 'Checking account'}},
        component: CheckingAccount,
        children: CheckingAccountRoute,
      },
      {
        path:'contracts',
        component: ContractsView,
        name:'Contratos',
        children:[
          {
            path:'/',
            component: DisplayContract,
            meta: { name: 'Contratos', group: 'portalalumno.payments', path: 'payments_students' },
          }
        ]
      }
  ]
};

export default paymentRoutes
