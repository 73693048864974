<template>
    <div>
        <v-container fluid class="d-flex pa-0 fullHeight">
            <v-container fluid class="px-0">
                <div class="px-md-10">
                    <h4 class="primary--text mt-0">
                        <v-btn icon class="btn-back" @click="$emit('goBackPayment')"><v-icon>mdi-chevron-left</v-icon></v-btn>
                        {{ $t('modules.portalalumno.payments.paymentsStudents.view.labels.billingTitle') }}
                    </h4>
                    <Modal v-show="showGetnet" @close="close">
                        <div id="iframe-section"></div>
                    </Modal>
                    <template v-if="$isTeclab">
                        <PaymentBillingDataTeclab
                            ref="billingData"
                            @update="billingData = $event"
                            @validate="billingDataValid = $event"
                        ></PaymentBillingDataTeclab>
                    </template>
                    <template v-else>
                        <PaymentBillingData
                            ref="billingData"
                            @update="billingData = $event"
                            @validate="billingDataValid = $event"
                        ></PaymentBillingData>
                    </template>
                </div>
            </v-container>
        </v-container>
    </div>
</template>

<script>
    import PaymentBillingData from '../Components/PaymentBillingData/PaymentBillingData'
    import PaymentBillingDataTeclab from '../Components/PaymentBillingData/PaymentBillingDataTeclab'
    import { $payments, $accounts } from '../Services';
    import { mapGetters } from 'vuex'
    import commons from '../Mixins/commons';
    import Modal from '../Components/Gateways/Modal'

 

    export default {
        name: 'PaymentsCheckoutGetnet',
        mixins: [ commons ],
        props: {
            resumen: Object,
            selectedMethod: Object,
            summaryButtons: Object,
        },
        components: {
            PaymentBillingDataTeclab,
            PaymentBillingData,
            Modal
        },
        data() {
            return {
                billingDataValid: false,
                billingData: null,
                secondAmount: null,
                token: null,
                showGetnet: false,
            };
        },
        computed: {
            ...mapGetters({
                studentRegister: 'commons/getStudentActualRegister',
                studentUserData: 'commons/getStudentUserData',
                loggedUsername: 'commons/getUserName',
                mpconfig: 'commons/getMpConfig',
            }),
            description() {
                let description = ''
                this.resumen.items.subtotal.items.forEach((concepto, index) => {
                    if (index === 0) description = concepto.title
                    else description  += ` - ${concepto.title}`
                })
                return description
                },
        },
        methods: {
            close(){
                this.summaryButtons.primary.loading = false
                this.summaryButtons.secondary.callback()

            },
            async getToken() {
                const {data} = await $accounts.find()
                this.token = data.find(gateway => gateway.id === GATEWAY.GETNET.ID).publicKeyAdvisor
            },
            async initGetnet() {
                this.showGetnet = true
                this.summaryButtons.primary.loading = true
                await this.$refs.billingData.save()

                if(this.secondAmount!==null){this.secondAmount = this.$encrypter(parseFloat(this.secondAmount).toFixed(2));}
    
                const paymentData = {
                    'legajo': this.studentRegister,
                    'amount': this.$encrypter(parseFloat(this.resumen.total).toFixed(2)),
                    'description': this.subscription ? 'GASTO_ADMINISTRATIVO' : this.description,
                    'payment_method_id': 'visa',
                    'token': this.token,
                    'installments': this.selectedInstallment?.installments || 1,
                    'payer_email': this.studentUserData.email,
                    'issuer_id': 3,
                    'usr_portal': this.loggedUsername,
                    'discount_intent': this.resumen.paymentIntent,
                    'intencion_de_pago_id': this.$paymentsIdToArray(this.resumen.items.subtotal.items),
                    'user_type': this.$isGestor ? 'ADVISOR' : 'STUDENT',
                    'doc_number': this.studentData.student.user.userData.username,
                    'doc_type': 'DNI',
                    'device_id': this.deviceId,
                    'firstName': this.studentData.student.user.userData.name,
                    'lastName': this.studentData.student.user.userData.lastname,
                    'capture': true,
                    'pago_una_tarjeta': true,
                    'credit_card_fee': this.selectedInstallment?.credit_card_fee ?? 0,
                    'secondAmount': null,
                    'percentage': '0',
                    'idsPending': [],
                    'bin': 503175,
                    'accountId': GATEWAY.GETNET.ID,
                    'city': this.$isTeclab ? this.billingData.city : this.billingData.city.cityState,
                    'phone': this.$isTeclab ? this.billingData.phone : '',
                    'postal_code': this.$isTeclab ? this.billingData.zipCode : '',
                    'state': this.$isTeclab ? this.billingData.state : '',
                    'address': `${this.billingData.street}-${this.billingData.number}`,
                    'device_unique_identifier': 0
                }
                try {
                    const {data: paymentIntentId} = await $payments.create(paymentData)

                    const config = {
                        'paymentIntentId': paymentIntentId,
                        'checkoutType': 'iframe',
                        'accessToken': `Bearer ${this.token}`,
                    };
                    window.loader.init(config);
                    // const iframe = document.querySelector('iframe');
                    // const iframeSection = document.getElementById('iframe-section');
                    const iframeSection = document.getElementById('iframe-section')
                    const iframe = document.getElementById('digital-checkout-iframe')
                    if (iframe) iframeSection?.appendChild(iframe)
                    // iframeSection.appendChild(iframe);
                } catch (error) {
                    this.summaryButtons.primary.error = true
                    this.selectedMethod.id= null
                    throw error
                } finally {
                    this.summaryButtons.primary.error = false
                    this.summaryButtons.primary.loading = false

                }
            },
        },
        watch: {
            billingDataValid(value) {
                this.summaryButtons.primary.disabled = !value
            }
        },
        mounted() {
            try {
                this.getToken()
            } catch (error) {
                return error
            }
        },
        unmounted() {
            const iframeSection = document.getElementById('iframe-section');
            iframeSection.remove()
            const iframe = document.querySelector('iframe');
            document.body.removeChild(iframe)
        },
    };
    const GATEWAY = {
        GETNET: {
            ID: 5
        }
    }
</script>


