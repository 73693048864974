
const examTitleNotesTranslations = {
  es: {
    table: {
      breadcrumbs: {
        title: 'Exámen de título',
        description: 'Gestión de notas de exámen de título',
        titleIPP: 'Estudiantes Egresados',
        descriptionIPP: 'Gestión de Estudiantes para titulación'
      },
      regulations:{
        select:'Reglamentos',
        button:'Titular'
      },
      headers: {
        name: 'Nombre',
        lastname: 'Apellido',
        career: 'Carrera',
        date: 'Fecha de exámen',
        dateIPP: 'Fecha de Titulación',
        identification: 'RUT',
        file: 'Legajo',
        career_id: 'Código',
        grade: 'Nota',
        act: 'Acta',
      },
      asidebar: {
        notas: 'Notas',
        alumnos_sin_nota: 'Sin Nota',
        alumnos_con_nota: 'Con nota',
        desde: 'Desde',
        hasta: 'Hasta',
      }
    },
    create:{
      breadcrumbs: {
        title: 'Carga de nota',
        description: 'Notas de exámen de título',
        titleIPP: 'Registro de Titulación',
        descriptionIPP: 'Ingreso de datos de titulación',
      },
      labels: {
        name: 'Nombre',
        detail: 'Detalle',
        calificacion: 'Calificación',
        fecha: 'Fecha exámen',
        fechaR23: 'Fecha de aprobación práctica',
        titulacion: 'FECHA TÍTULO',
        titulacionR23: 'FECHA DIPLOMA',
        act: 'Acta',
      },
      validation_errors: {
        calificacion: 'Debe ingresar la calificación',
        fecha: 'Debe ingresar la fecha',
      },
      error_message : {
        nota:{
          title: 'Error al cargar la nota'
        },
        titulacion:{
          title: 'Error al intentar titular'
        }
      },
      success_message : {
        nota:{
          title: 'Nota cargada correctamente'
        },
        titulacion:{
          title: 'Titulación realizada con éxito'
        }
      }
    },
    update:{
      breadcrumbs: {
        title: 'Actualización de nota',
        description: 'Notas de exámen de título',
        titleIPP: 'Registro de Titulación',
        descriptionIPP: 'Actualización de datos de titulación',
      },
      labels: {
        name: 'Nombre',
        detail: 'Detalle',
        calificacion: 'Calificación',
        justification: 'Justificación',
        fecha: 'Fecha exámen',
        fechaR23: 'Fecha de aprobación práctica',
        titulacion: 'FECHA TÍTULO',
        titulacionR23: 'FECHA DIPLOMA',
        act: 'Acta',
      },
      validation_errors: {
        calificacion: 'Debe ingresar la calificación',
        fecha: 'Debe ingresar la fecha',
        justification: 'Ingresa el motivo de la actualización'
      },
      error_message : {
        nota:{
          title: 'Error al actualizar la nota'
        },
        titulacion:{
          title: 'Error al actualizar la titulacion'
        }
      },
      success_message : {
        nota:{
          title: 'Nota actualizada correctamente'
        },
        titulacion:{
          title: 'Titulación actualizada con éxito'
        }
      }
    }
  },
  en: {

  }
};

export default examTitleNotesTranslations
