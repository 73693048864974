
<template>
    <v-dialog v-model="modalOpen" max-width="600px" @click:outside="closeDialog" content-class="modal-content">
        <v-card class="px-5 py-6" v-if="!confirmationStatus">
            <v-card-title v-if="this.payment.status?.value === 115 && this.selectedPayment.paymentBillingProduct === 87" class="headline">{{ $t('modules.exceptions.payments.popup.tittle_card') }}</v-card-title>
            <v-card-title v-else class="headline">{{ selectedPayment.accountDescription }}</v-card-title>
            <v-card-subtitle class="mt-0">{{ `#${selectedPayment.id} - ${selectedPayment.term}` }}</v-card-subtitle>
            <v-card-text v-if="this.payment.status?.value === 115 && this.selectedPayment.paymentBillingProduct === 87 && this.isTransfer" class="py-0">
                <h4 class="headline text-center mt-4 mb-3 primary--text"> {{ $t('modules.exceptions.payments.popup.administrative_expense_exception') }}</h4>
            </v-card-text>

            <v-card-text v-else class="py-0">

                <v-row>
                    <v-col cols="6" class="mx-auto">
                        <v-select
                            v-model="$v.payment.status.$model"
                            @blur="$v.payment.status.$touch()"
                            :items="paymentStatus"
                            :disabled="saveButton.loading"
                            :label="$t('modules.exceptions.payments.popup.status')"
                            item-text="meaning"
                            hide-details
                            return-object
                            outlined
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" class="mx-auto">
                        <v-menu transition="scale-transition" offset-y min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    :value="formattedExpiration"
                                    readonly
                                    :disabled="saveButton.loading"
                                    :label="$t('modules.exceptions.payments.popup.expiration')"
                                    outlined
                                    hide-details
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="$v.payment.expiration.$model"
                                @change="$v.payment.expiration.$touch()"
                                color="primary"
                                min="1990-01-01"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" class="mx-auto">
                        <v-menu transition="scale-transition" offset-y min-width="290px" @input="$v.payment.paymentDate.$touch()">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    :value="formattedPaymentDate"
                                    readonly
                                    :error-messages="$v.payment.paymentDate.$dirty && $v.payment.paymentDate.$invalid ? $t('modules.exceptions.payments.popup.date_required') : ''"
                                    :disabled="saveButton.loading || !cancelledStatus"
                                    :label="`${$t('modules.exceptions.payments.popup.paymentDate')} ${cancelledStatus ? '*' : ''}`"
                                    outlined
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="$v.payment.paymentDate.$model"
                                @change="$v.payment.paymentDate.$touch()"
                                color="primary"
                                min="1990-01-01"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" class="mx-auto">
                        <v-text-field
                            type="number"
                            :label="`${$t('modules.exceptions.payments.popup.amount')} *`"
                            v-model.number="$v.payment.amount.$model"
                            :disabled="saveButton.loading"
                            :error-messages="$v.payment.amount.$dirty && $v.payment.amount.$error ? $t('modules.exceptions.payments.popup.amount_required') : ''"
                            @keypress="$validateIntegerInputNumber($event, $v.payment.amount.$model, null, 7)"
                            @blur="$v.payment.amount.$touch()"
                            outlined>
                          <template v-slot:prepend>
                            <span>
                              {{ $countryConstants.currency }}
                            </span>
                          </template>
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" class="mx-auto">
                        <v-text-field
                            :label="$t('modules.exceptions.payments.popup.comments')"
                            v-model="$v.payment.description.$model"
                            @change="$v.payment.description.$touch()"
                            :disabled="saveButton.loading"
                            outlined>
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions class="d-flex justify-end ma-0 mt-4 px-5">
                <v-spacer></v-spacer>
                <Button
                    white
                    :text="$t('actions.cancel')"
                    @clicked="closeDialog"
                    class="mr-4"
                ></Button>
                <Button
                    v-if="this.payment.status?.value === 115 && this.selectedPayment.paymentBillingProduct === 87 && this.isTransfer "
                    :text="$t('actions.accept')"
                    :loading="saveButton.loading"
                    :success="saveButton.success"
                    :error="saveButton.error"
                    :successText="$t('actions.success')"
                    :errorText="$t('actions.error')"
                    @clicked="confirmPopup"
                    @end="resetButton"
                ></Button>
                <Button
                v-else
                    :text="$t('actions.save')"
                    :loading="saveButton.loading"
                    :success="saveButton.success"
                    :error="saveButton.error"
                    :disabled="!$v.payment.$anyDirty || $v.payment.$invalid"
                    :successText="$t('actions.success')"
                    :errorText="$t('actions.error')"
                    @clicked="confirmPopup"
                    @end="resetButton"
                ></Button>
            </v-card-actions>
        </v-card>
        <v-card class="px-5 py-6" v-else>
            <v-card-text class="pa-0">
                <SuccessMessage
                    full-width
                    :type="successMessage.type"
                    :title="successMessage.title"
                    :actionPrimary="successMessage.actionPrimary"
                    :actionSecondary="successMessage.actionSecondary"
                />
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import Button from '@/components/Button/Button'
    import { required, requiredIf } from 'vuelidate/lib/validators'
    import moment from 'moment';
    import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
    import { $exceptions} from '../../Services';
    import {mapGetters} from 'vuex'

    export default {
        components:{
            Button,
            SuccessMessage,
        },
        name:'PaymentsPopup',
        props:{
            selectedPayment: Object,
            paymentStatus: Array,
            modalOpen: Boolean,
            studentId: String,
            legajo: String,
            isTransfer: Boolean,
        },
        data() {
            return {
                percentage: null,
                firstAmount: null,
                intentDiscount: null,
                billingData: [],
                confirmationStatus: false,
                payment: {
                    amount: null,
                    expiration: null,
                    paymentDate: null,
                    status: null,
                    description: null,
                },
                saveButton: {
                    loading: false,
                    success: false,
                    error: false,
                },
                successMessage: {
                    type: null,
                    title: null,
                    actionPrimary: null,
                    actionSecondary: null,
                },
            }
        },
        mounted() {
            this.payment.amount = this.selectedPayment.paymentAmount
            this.payment.expiration = this.selectedPayment.expiration ? new Date(this.selectedPayment.expiration).toISOString().substr(0, 10) : null
            this.payment.paymentDate = this.selectedPayment.paymentDate ? new Date(this.selectedPayment.paymentDate).toISOString().substr(0, 10) : null
            this.payment.status = this.selectedPayment.paymentStatus,
            this.payment.description = this.selectedPayment.description
        },
        computed: {
            cancelledStatus() {
                return this.payment.status && this.payment.status?.value == 114
            },
            formattedExpiration() {
                return this.payment.expiration ? moment(this.payment.expiration).format('DD/MM/YYYY') : null
            },
            formattedPaymentDate() {
                return this.payment.paymentDate ? moment(this.payment.paymentDate).format('DD/MM/YYYY') : null
            },
            ...mapGetters({
                userId: 'commons/getUserID',
            }),
        },
        methods:{
            resetButton() {
                this.saveButton.loading = false
                this.saveButton.success = false
                this.saveButton.error = false
            },
            closeDialog() {
                this.$emit('close')
            },
            confirmPopup() {
                this.successMessage.type = 'warning';
                this.successMessage.title = this.$t('modules.exceptions.payments.popup.confirmation_title')
                this.successMessage.actionPrimary = {text: this.$t('actions.accept'), callback: () => {this.save()}}
                this.successMessage.actionSecondary = {text: this.$t('actions.cancel'), callback: () => {this.confirmationStatus = false}}
                this.confirmationStatus = true
            },
            async save() {
                if(this.payment.status?.value === 115 && this.selectedPayment.paymentBillingProduct === 87 && this.isTransfer ){
                    try {
                        this.confirmationStatus = false
                        this.saveButton.loading = true
                        this.$emit('createTransfer')
                        this.$emit('close')
                        this.$emit('success')
                        this.saveButton.success = true;
                    } catch (error) {
                        this.successMessage.type = 'error';
                        this.successMessage.title = (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.exceptions.payments.popup.error')
                        this.successMessage.actionPrimary = {text: this.$t('actions.return'), callback: () => {this.confirmationStatus = false}}
                        this.successMessage.actionSecondary = null
                        this.confirmationStatus = true
                        this.saveButton.error = true;
                        throw error
                    } finally {
                        this.saveButton.loading = false;
                    }
                }
                else{
                    try {
                    this.confirmationStatus = false
                    this.saveButton.loading = true
                    const data = [{
                        id: this.selectedPayment.id,
                        status: this.payment.status.value,
                        expiration: this.payment.expiration,
                        paymentDate: this.cancelledStatus ? this.payment.paymentDate : null,
                        amount: this.payment.amount,
                        description: this.payment.description?.trim()
                    }]
                    await $exceptions.update(`accountAdjustment?user=${this.userId}`, data)
                    this.$emit('close')
                    this.$emit('success')
                    this.saveButton.success = true;
                } catch (error) {
                    this.successMessage.type = 'error';
                    this.successMessage.title = (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.exceptions.payments.popup.error')
                    this.successMessage.actionPrimary = {text: this.$t('actions.return'), callback: () => {this.confirmationStatus = false}}
                    this.successMessage.actionSecondary = null
                    this.confirmationStatus = true
                    this.saveButton.error = true;
                    throw error
                } finally {
                    this.saveButton.loading = false;
                    this.$emit('resetSelected');
                }
                }
            },
        },
        validations: {
            payment: {
                amount: { required },
                expiration: { required },
                paymentDate: { required: requiredIf(function() { return this.cancelledStatus }) },
                status: { required },
                description: {required  },
            }
        }
    }
</script>
