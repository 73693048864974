<template>
  <OutlinedCard>
    <v-row no-gutters>
      <v-col sm="6" class="pr-4">
        <v-text-field
          :error="$v.formData.value.$anyError"
          :label="$t('modules.settings.student.form.details.fields.value') + ' *'"
          :error-messages="valueErrors"
          @blur="$v.formData.value.$touch()"
          outlined
          :counter="75"
          v-model="formData.value"
          class="mb-4"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-textarea
      :error="$v.formData.description.$anyError"
      outlined
      :error-messages="descriptionErrors"
      @blur="$v.formData.description.$touch()"
      v-model="formData.description"
      :label="$t('modules.settings.student.form.details.fields.description') + ' *'"
      :counter="200"
    ></v-textarea>
    <slot></slot>
  </OutlinedCard>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard'

export default {
  name: 'StudentsForm',
  props: ['formData'],
  components: {
    OutlinedCard
  },
  validations: {
    formData: {
      value: { required },
      description: { required },
    },
  },
  computed: {
    valueErrors() {
      const errors = [];
      if (!this.$v.formData.value.$anyError) return errors;
      if (!this.$v.formData.value.required) errors.push(this.$t('modules.settings.student.form.details.validations.valueRequired'));
      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.formData.description.$anyError) return errors;
      if (!this.$v.formData.description.required) errors.push(this.$t('modules.settings.student.form.details.validations.descriptionRequired'));
      return errors;
    }
  },
  methods: {
    resetValidations() {
      this.$v.$reset()
    }
  }
}
</script>