
import liquidationsTranslations from './Liquidations/Translations'
import dashboardTranslations from './Dashboard/Translations'
import homeTranslations from './Home/Translations'
import studentsTranslations from './Students/Translations'

const commissionsTranslations = {
    es_PE: {
        dashboard: { ...dashboardTranslations.es_PE },
    },
    es_MX: {
        dashboard: { ...dashboardTranslations.es_MX },
    },
    es: {
        liquidations: { ...liquidationsTranslations.es},
        dashboard: { ...dashboardTranslations.es },
        home: { ...homeTranslations.es },
        students: { ...studentsTranslations.es },
    },
    en: {
        liquidations: { ...liquidationsTranslations.en },
        dashboard: { ...dashboardTranslations.en },
        home: { ...homeTranslations.en },
        students: { ...studentsTranslations.en },
    }
}

export default commissionsTranslations
