<template>
    <v-dialog v-model="modalOpen" max-width="600px" @click:outside="closeDialog" content-class="modal-content">
        <v-card class="px-5 py-6">
            <v-card-title class="headline">{{ `Documentación para validar identidad` }}</v-card-title>
            <v-card-subtitle class="mt-0">{{ `Suba la documentación necesaria` }}</v-card-subtitle>
            <!-- <v-divider class="my-0"></v-divider> -->
            <v-card-text class="py-0">
                <v-row>
                    <v-col cols="12">
                        <v-list-item v-if="!contract && !error" class="px-0">
                            <v-list-item-icon>
                                <v-icon color="">mdi-file-alert</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-subtitle>{{ $t('modules.exceptions.table.noFiles') }}</v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action>
                                <v-btn
                                    outlined
                                    rounded
                                    small
                                    color="primary"
                                    @click="openFilesDialog"
                                >{{$t('modules.exceptions.table.add_file')}}</v-btn>
                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item v-else-if="error" class="px-0">
                            <v-list-item-icon>
                                <v-icon color="error">mdi-alert</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-list-item-title v-on="on">{{ error }}</v-list-item-title>
                                    </template>
                                    <span>{{ $t('modules.exceptions.table.max_size') }}</span>
                                </v-tooltip>
                            </v-list-item-content>

                            <v-list-item-action>
                                <v-btn @click="error = null; contract = null" icon>
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item v-else-if="contract" class="px-0">
                            <v-list-item-icon>
                                <v-icon color="primary">mdi-image</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{ contract.name }}</v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-action>
                                <v-btn @click="error = null; contract = null" icon>
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                        <v-file-input
                            v-model="contract"
                            v-show="false"
                            chips
                            @change="onChange"
                            :id="uniqueId"
                            accept=".png,.jpg,.jpeg,.pdf,.doc,.docx"
                        ></v-file-input>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions class="d-flex justify-end ma-0 mt-4 px-5">
                <v-spacer></v-spacer>
                <Button
                    white
                    :text="$t('actions.cancel')"
                    @clicked="closeDialog"
                    class="mr-4"
                ></Button>
                <Button
                    :text="$t('actions.save')"
                    :loading="saveButton.loading"
                    :success="saveButton.success"
                    :error="saveButton.error"
                    :successText="$t('actions.success')"
                    :errorText="$t('actions.error')"
                    :disabled="!contract || error"
                    @clicked="save"
                    @end="resetButton"
                ></Button>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import Button from '@/components/Button/Button';
    import Constants from '@/plugins/constants'
    import {mapGetters} from 'vuex'

    export default {
        components:{
            Button,
        },
        name:'DocumentUploadPopup',
        props:{
            student: Object,
            modalOpen: Boolean,
        },
        data() {
            return {
                uniqueId: '',
                contract: null,
                error: null,
                saveButton: {
                    loading: false,
                    success: false,
                    error: false,
                }
            }
        },
        created() {
            this.uniqueId = `componentFilesInput-${Date.now()}${Math.round(Math.random())}`;
        },
        computed: {
            ...mapGetters({
              userId: 'commons/getUserID'
            })
          },
          methods:{
            resetButton() {
              this.saveButton.loading = false
              this.saveButton.success = false
              this.saveButton.error = false
            },
            onChange (file) {
              if (file) {
                const max_size = 2097152 //2MB
                if(file.size <= max_size) this.error = null
                else this.error = `${file.name} supera el tamaño máximo (2MB)`
              }
            },
            openFilesDialog() {
              const el = document.querySelector(`#${this.uniqueId}`);
              el.click();
            },
            closeDialog() {
              this.$emit('cancel')
            },
            // confirmPopup() {
              //     this.$store.dispatch('commons/openPopUp', {
                //         title: this.$t('modules.exceptions.table.actions.confirmation_title'),
                //         content: [{ value: this.student.name }],
                //         actionPrimary: { text: this.$t('actions.accept'), callback: () => this.save()},
                //         actionSecondary: { text: this.$t('actions.cancel'), callback () { } },
                //         icon: { color: 'warning', name: 'mdi-exclamation' },
                //         color: 'primary',
                //     })
                // },
                async save() {
                  try {
                    this.saveButton.loading = true
                    const formData = new FormData();
                    formData.append('file', this.contract)
                    formData.append('studentId', this.student.studentId)

                    const options = Constants.getFetchOptions(formData, Constants.HTTP_METHOD_POST, 'multipart/form-data')
                    delete options.headers['Content-Type']
                    options.body = formData;
                    const { status } = await fetch(`${Constants.URL_STUDENT_SERVER}/tools/updateStudentCareerStatus?user=${this.userId}`, options)

                    if (status == 200 || status == 201) {
                        this.$emit('success')
                        this.saveButton.success = true;
                    } else {
                        throw 'Error'
                    }
                } catch (error) {
                    this.saveButton.error = true;
                    throw error
                } finally {
                    this.saveButton.loading = false;
                }
            },
        },
    }
</script>
