<template>
  <v-container fluid class="pa-0 pb-8">
      <v-container fluid>
          <Breadcrumbs :title="$t('modules.portalalumno.payments.paymentsStudents.breadcrumbs.title')"
              :description="$t('modules.portalalumno.payments.paymentsStudents.breadcrumbs.description')"></Breadcrumbs>
          <v-row justify="center">
              <v-col class="viewSpaces" cols="12">
                  <v-row>
                      <v-col cols="12" :md="(step != 3) ? '7' : '12'" :lg="(step != 3) ? '8' : '12'">
                          <v-stepper v-model="step" class='stepper paymentStepper' :alt-labels="$isMobile">
                              <v-stepper-header class="stepper-header">
                                  <v-stepper-step class="stepper-step" :complete="step > 1"
                                      :color="step > 1 ? 'green' : 'primary'" step="1">
                                      {{ $t('modules.portalalumno.payments.paymentsStudents.view.stepper.selection') }}
                                  </v-stepper-step>
                                  <v-stepper-step class="stepper-step" :complete="step > 2"
                                      :color="step > 2 ? 'green' : 'primary'" step="2">
                                      {{ $t('modules.portalalumno.payments.paymentsStudents.view.stepper.payment') }}
                                  </v-stepper-step>
                                  <v-stepper-step step="3" class="stepper-step">
                                      {{ $t('modules.portalalumno.payments.paymentsStudents.view.stepper.confirmation') }}
                                  </v-stepper-step>
                              </v-stepper-header>
                              <v-stepper-items>
                                  <v-stepper-content step="1" class="px-0">

                                      <div v-show="!repayment.selected">
                                          <!-- <PaymentsTeclabBanner v-if="$isTeclab"></PaymentsTeclabBanner> -->
                                          <PaymentsBanner v-if="showBanner" :percentage="extraBenefitPercentage"></PaymentsBanner>

                                          <TeclabReenrollmentBanners v-if="teclabReenrollment"
                                              :reenrollment="teclabReenrollment" :hasDebt="hasDebt"
                                              @open-documentation="documentationDrawerOpen = true">
                                          </TeclabReenrollmentBanners>

                                          <PaymentsSelectionGridView
                                              ref="paymentSelectionGridView"
                                              :systemConfig="systemConfig"
                                              :payments="payments"
                                              :paymentsNext="paymentsNext"
                                              :futurePayments="futurePayments"
                                              :repayment="repayment"
                                              :generatePaymentIds="generatePaymentIds"
                                              :resumen="resumen"
                                              :extraBenefitDiscount="teclabReenrollment?.extraBenefit.length ? teclabReenrollment?.extraBenefit[0].percentageSemester : 0"
                                              @billingchange="onBillingChange($event.payments, $event?.itemsToUpdate)"
                                              :historyDiscounts="productsForDiscountHistory"
                                          ></PaymentsSelectionGridView>
                                      </div>

                                      <Repayment
                                          ref="REP"
                                          v-if="repayment.selected"
                                          :resumen="resumen"
                                          :unselectedReenrollmentsAccountIds="unselectedReenrollmentsAccountIds"
                                          :summaryButtons="summaryButtons"
                                          @continue="()=>{ onPayClick(); step === 1 && step++ }"
                                      ></Repayment>
                                  </v-stepper-content>

                                  <v-stepper-content step="2">
                                      <PaymentsZeroAmount
                                          ref="PZA"
                                          v-if="selectedMethod.id == 'PZA'"
                                          :step="step"
                                          :resumen="resumen"
                                          :summaryButtons="summaryButtons"
                                          :isMandatory="isMandatory"
                                          @changeStep="nextStepChange"
                                          @goBackPayment="goBackPayment"
                                      ></PaymentsZeroAmount>

                                      <PaymentsSelectionView
                                          ref="PSV"
                                          :extraBenefit="extraBenefitBanner"
                                          :showSubcription="showSubcription"
                                          v-if="selectedMethod.id == null"
                                          :unselectedReenrollmentsAccountIds="unselectedReenrollmentsAccountIds"
                                          @paymentSelected="paymentSelected"
                                          :isCheckoutPro="isCheckoutPro"
                                          :isFintoc="isFintoc"
                                          :isGetnet = "isGetnet"
                                          :validateExtraBenefit = "validateExtraBenefit"
                                          :validateExtraBenefitSemester = "validateExtraBenefitSemester"
                                      ></PaymentsSelectionView>

                                      <PaymentsSingleCardView
                                          ref="TC1"
                                          v-if="selectedMethod.id == 'TC1'"
                                          :resumen="resumen"
                                          :unselectedReenrollmentsAccountIds="unselectedReenrollmentsAccountIds"
                                          :summaryButtons="summaryButtons"
                                          :selectedMethod="selectedMethod"
                                          @changeStep="nextStepChange"
                                          @goBackPayment="goBackPayment"
                                          @setDue="setDue"
                                      ></PaymentsSingleCardView>

                                      <PaymentsMultipleView
                                          ref="TC2"
                                          v-if="selectedMethod.id == 'TC2'"
                                          :resumen="resumen"
                                          :unselectedReenrollmentsAccountIds="unselectedReenrollmentsAccountIds"
                                          :summaryButtons="summaryButtons"
                                          :selectedMethod="selectedMethod"
                                          :isRepayment="isRepayment"
                                          :countAnalytics="countAnalytics"
                                          @incrementCountAnalytics="incrementCountAnalytics"
                                          @resetCountAnalytics="resetCountAnalytics"
                                          @changeStep="nextStepChange"
                                          @goBackPayment="goBackPayment"
                                          @setDue="setDue"
                                          @activeCardIndex="(value)=>{activeCardIndex = value}"
                                      ></PaymentsMultipleView>

                                      <PaymentsDebitView
                                          ref="TD"
                                          v-if="selectedMethod.id == 'TD'"
                                          :resumen="resumen"
                                          :unselectedReenrollmentsAccountIds="unselectedReenrollmentsAccountIds"
                                          :summaryButtons="summaryButtons"
                                          :selectedMethod="selectedMethod"
                                          @goBackPayment="goBackPayment"
                                          @changeStep="nextStepChange"
                                      ></PaymentsDebitView>

                                      <PaymentsCheckoutPro
                                          ref="CHECK"
                                          v-if="selectedMethod.id == 'CHECK'"
                                          :resumen="resumen"
                                          :unselectedReenrollmentsAccountIds="unselectedReenrollmentsAccountIds"
                                          :summaryButtons="summaryButtons"
                                          :selectedMethod="selectedMethod"
                                          @goBackPayment="goBackPayment"
                                          @changeStep="nextStepChange"
                                      ></PaymentsCheckoutPro>

                                      <PaymentsFintoc
                                          ref="FINTOC"
                                          v-if="selectedMethod.id == 'FINTOC' && this.$isIPP"
                                          :resumen="resumen"
                                          :unselectedReenrollmentsAccountIds="unselectedReenrollmentsAccountIds"
                                          :summaryButtons="summaryButtons"
                                          :selectedMethod="selectedMethod"
                                          @goBackPayment="goBackPayment"
                                          @changeStep="nextStepChange"
                                      ></PaymentsFintoc>

                                      <PaymentsCheckoutGetnet ref="GETNET" v-if="selectedMethod.id == 'GETNET'"
                                          :resumen="resumen" :summaryButtons="summaryButtons"
                                          :selectedMethod.sync="selectedMethod" @goBackPayment="goBackPayment"
                                          @changeStep="nextStepChange"
                                      ></PaymentsCheckoutGetnet>

                                      <PaymentsCashView
                                          ref="FT"
                                          v-if="(selectedMethod.id == 'FT' || selectedMethod.id == 'ATM') && resumen.total != 0 "
                                          :resumen="resumen"
                                          :unselectedReenrollmentsAccountIds="unselectedReenrollmentsAccountIds"
                                          :coupon="coupon"
                                          :summaryButtons="summaryButtons"
                                          :selectedMethod="selectedMethod"
                                          @functionSelectPayFT="functionSelectPayFT"
                                          @goBackPayment="goBackPayment"
                                          @changeStep="nextStepChange"
                                          @updateCouponExternalInfo="updateCouponExternalInfo"
                                      ></PaymentsCashView>

                                      <PaymentsSubscriptionView
                                        ref="SUS"
                                        v-if="selectedMethod.id == 'SUS'"
                                        @goBackPayment="goBackPayment"
                                        @changeStep="nextStepChange"
                                        @changePaymenstBySubscription="changePaymenstBySubscription"
                                        @updateSelectedPayments = "updateSelectedPayments"
                                        :subscriptions="filteredSubscriptions"
                                        :subscriptionPaymentTypes="filteredSubscriptionsTypes"
                                        :selectedMethod="selectedMethod"
                                        :coupon="coupon"
                                        :resumen="resumen"
                                        :payments="[...payments, ...paymentsNext, ...futurePayments]"
                                        :pendingPaymentsIdForSubscription="pendingPaymentsIdForSubscription"
                                        :unselectedReenrollmentsAccountIds="unselectedReenrollmentsAccountIds"
                                        :summaryButtons="summaryButtons"
                                        @setDue="setDue"
                                        @intentodepagoid="handleIntentoDePagoId"
                                      ></PaymentsSubscriptionView>
                                  </v-stepper-content>
                                  <v-stepper-content step="3">
                                      <PaymentsCompletedView
                                      :resumen="resumen"
                                      :coupon="coupon"
                                      :status="successMessage"
                                      :paymentTerms="idTermPayment"
                                      :isMandatory="isMandatory"
                                      :systemConfig="systemConfig"
                                      >
                                      </PaymentsCompletedView>
                                  </v-stepper-content>
                              </v-stepper-items>
                          </v-stepper>
                      </v-col>
                      <v-col cols="12" md="5" lg="4">
                          <v-card class="mx-6 mx-md-0 margin-bot">
                              <PaymentSummary v-if="step != 3" :isMandatory="isMandatory" :selectedMethod="selectedMethod" :step="step" :activeCardIndex="activeCardIndex" :contractPayment="contractPayment" :summaryButtons="summaryButtons" :resumen="resumen " :checkTerms=" step==2 ? checkTerms : null "
                                  :credit-cards="creditCards" :intentodepagoid="intentodepagoid" ></PaymentSummary>
                          </v-card>
                      </v-col>
                  </v-row>

              </v-col>
          </v-row>
      </v-container>

      <TemporaryRightDrawer v-if="teclabReenrollment" @tmpDrawerOpen="documentationDrawerOpen = false"
          :open="documentationDrawerOpen"
          :title="$t('modules.portalalumno.payments.paymentsStudents.banners.benefit.upload_title')"
          :description="$t('modules.portalalumno.payments.paymentsStudents.banners.benefit.upload_description')">
          <DocumentationUpload
              v-if="teclabReenrollment.benefit && teclabReenrollment.benefit.documentation && teclabReenrollment.benefit.documentation.length"
              :benefit="teclabReenrollment.benefit" @close="closeDocumentationUpload"></DocumentationUpload>
      </TemporaryRightDrawer>
  </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import PaymentsSelectionGridView from './PaymentsSelectionGridView'
import PaymentsSingleCardView from './PaymentsSingleCardView';
import PaymentsCompletedView from './PaymentsCompletedView';
import PaymentsCashView from './PaymentsCashView';
import PaymentsCheckoutPro from './PaymentsCheckoutPro';
import PaymentsFintoc from './PaymentsFintoc.vue';
import PaymentsDebitView from './PaymentsDebitView';
import PaymentsMultipleView from './PaymentsMultipleView';
import PaymentsSubscriptionView from './PaymentsSubscriptionView.vue';
import PaymentsCheckoutGetnet from './PaymentsCheckoutGetnet';
import PaymentsSelectionView from './PaymentsSelectionView';
import PaymentSummary from '../Components/PaymentSummary/PaymentSummary';
import Repayment from '../Components/Repayment/Repayment';
import PaymentsBanner from '../Components/PaymentBanner/PaymentBanner'
import TeclabReenrollmentBanners from '../Components/PaymentBanner/TeclabReenrollmentBanners'
import DocumentationUpload from '../Components/PaymentBanner/DocumentationUpload'
import PaymentsZeroAmount from './PaymentsZeroAmount.vue';
import TemporaryRightDrawer from '@/components/TemporaryRightDrawer/TemporaryRightDrawer'
import { $studentAccount, $discount, $productsWithPricePeriods, $types, $suscriptions, $accounts } from '../Services';
import { mapGetters } from 'vuex'
import _ from 'lodash'

const PENDING_PAYMENT_STATUS = { type: null, value: 115, meaning: 'Vigente' }
let snapshotDiscountsSummary = null

export default {
  name: 'PaymentsView',
  components: {
      PaymentsZeroAmount,
      PaymentsSelectionView,
      PaymentsSelectionGridView,
      PaymentsSingleCardView,
      PaymentsCashView,
      PaymentsMultipleView,
      PaymentsSubscriptionView,
      PaymentsCompletedView,
      PaymentSummary,
      PaymentsBanner,
      TeclabReenrollmentBanners,
      DocumentationUpload,
      TemporaryRightDrawer,
      PaymentsDebitView,
      PaymentsCheckoutPro,
      PaymentsFintoc,
      PaymentsCheckoutGetnet,
      Repayment,
      Breadcrumbs,
  },
  data() {
      return {
          activeCardIndex:0,
          paymentSubscriptionFlow: false,
          pendingPaymentsIdForSubscription: [],
          productsForDiscountHistory: [],
          systemConfig: null,
          repayment: { selected: false, available: false, installments: 3 },
          showExtraBenefit: false,
          showBannerBenefitExtra: false,
          generatePaymentIds: false,
          selectedMethod: { id: null },
          step: 1,
          successMessage: null,
          summaryButtons: {
              primary: {
                  text: this.$t('modules.portalalumno.payments.paymentsStudents.view.labels.seePaymentMethods'),
                  disabled: true,
                  visible: true,
                  callback: () => {
                    if (!this.resumen.total){
                        this.selectedMethod.id = 'PZA'
                      }
                    else {
                      this.onPayClickSuscription()
                      }
                      this.step++ },
                //   callback: () => { this.step++ },
                  loading: false,
                  success: false,
                  error: false,
              },
              secondary: {
                  text: this.$t('modules.portalalumno.payments.paymentsStudents.view.labels.back'),
                  disabled: false,
                  visible: false,
                  callback: () => { this.previousStep() }
              },
              tertiary: {
                  text: this.$t('modules.portalalumno.payments.paymentsStudents.view.labels.repayment'),
                  disabled: false,
                  visible: false,
                  callback: () => { this.goToRepayment() }
              },
          },
          resumen: {
              items: {
                  subtotal: { title: 'Subtotal', items: [], monto: 0 },
                  scholarships: { title: 'Becas', items: [], monto: 0 },
                  benefits: { title: 'Beneficios', items: [], monto: 0 },
                  discounts: { title: 'Descuentos', items: [], monto: 0 },
                  repayments: { title: 'Subtotal', items: [], monto: 0 },
                  adminExpenses: { title: 'Gastos Administrativos', items: [], monto: 0 },
              },
              cards: [
                  { paymentId: null, amount: null },
                  { paymentId: null, amount: null },
              ],
              total: 0,
              tea: '0,00%',
              cft: '0,00%',
              paymentIntent: 0
          },
          allItemsResumen: {
              items: {
              subtotal: { title: 'Subtotal', items: [], monto: 0 },
              scholarships: { title: 'Becas', items: [], monto: 0 },
              benefits: { title: 'Beneficios', items: [], monto: 0 },
              discounts: { title: 'Descuentos', items: [], monto: 0 },
              repayments: { title: 'Subtotal', items: [], monto: 0 },
              adminExpenses: { title: 'Gastos Administrativos', items: [], monto: 0 },
              },
              cards: [
              { paymentId: null, amount: null },
              { paymentId: null, amount: null },
              ],
              total: 0,
              tea: '0,00%',
              cft: '0,00%',
              paymentIntent: 0
          },
          coupon: {
              payment: null,
              number: null,
              manualOpNumber: null,
              creationDate: null,
              barcode: {
                  type: null,
                  codeNumber: null
              },
              externalResourceUrl: null,
              dateOfExpiration: null

          },
          payments: [],
          hiddenPayments: [],
          paymentsNext: [],
          futurePayments: [],
          paymentsSnapshot: [],
          subscriptionPaymentTypes: [],
          filteredSubscriptionsTypes: [],
          subscriptions: [],
          reenrollmentItems: [],
          allReenrollmentItems: [],
          filteredSubscriptions: [],
          paymentsSelected:[],
          paymentsSelectedAll:[],
          isRepayment: Boolean,
          countAnalytics:Number,
          incrementCountAnalytics:Number,
          resetCountAnalytics:Number,
          subscriptionMethod: {
              id: 'SUS',
              type: 'suscripcion',
              name: 'Suscripción',
              icon: 'mdi-account-cash-outline',
              off: 0,
          },
          productsToHide: [],
          documentationDrawerOpen: false,
          hasDebt: false,
          teclabReenrollment: null,
          ippReenrollment: null,
          adaptationInsuranceTerms: [],
          referedCredits: 0,
          creditCards: [],
          iv: 0,
          aux:'',
          isCheckoutPro: false,
          isFintoc: false,
          isGetnet:false,
          intentodepagoid: 0
      };
  },
  computed: {
      ...mapGetters({
          studentRegister: 'commons/getStudentActualRegister',
          studentId: 'commons/getStudentId',
          studentUserId: 'commons/getStudentUserID',
          studentData: 'commons/getStudentData',
          checkTerms:'commons/getCheckTerms',
      }),
      idTermPayment(){
          const isPaymentSelected = [ ...this.payments, ...this.paymentsNext]
            .filter(payment => payment.selected )
          if(!isPaymentSelected.length)return []
          const paymentTerm = []
          isPaymentSelected.forEach(payment => {
            if(!paymentTerm.length){
              paymentTerm.push(payment.options.term?.id)
            }else if(!paymentTerm.includes(payment.options.term?.id)){
              paymentTerm.push(payment.options.term?.id)
            }
          })
          return paymentTerm
              },
      extraBenefitBanner() {
        if (!this.teclabReenrollment || !this.teclabReenrollment.extraBenefit || !this.teclabReenrollment.extraBenefit.length) {
          return { percentage: null };
        }
        return this.teclabReenrollment.extraBenefit[0];
      },
      studentHasPreviousSubscriptionPaid(){
        return this.teclabReenrollment?.hadASubscription
      },
      isMandatory(){
        return [ ...this.payments, ...this.paymentsNext].some((payment)=> payment.mandatory && payment.selected && payment.billingProduct != 80)
      },
      unselectedReenrollmentsAccountIds() {
          const selectedReenrollmentsIds = this.resumen.items.subtotal.items.filter(item => item.reenrollmentId).map(item => item.reenrollmentId)
          const selectedAccountIds = this.resumen.items.subtotal.items.filter(item => item.id).map(item => item.id)
          if(selectedAccountIds) {
            const unselectedAccountIds = this.allItemsResumen.items.subtotal.items.filter(item => !selectedAccountIds.includes(item.id)).map(item => item.id)
            return unselectedAccountIds
          }
          const unselectedReenrollmentsAccountIds = this.allItemsResumen.items.subtotal.items.filter(item => !selectedReenrollmentsIds.includes(item.reenrollmentId)).map(item => item.id)
          return unselectedReenrollmentsAccountIds

        },
      enableRepayment() {
        const overdueInstallments = this.payments.filter(payment => payment.selected && payment.status == 117 && payment.groupId === 2)
        return this.repayment.available && overdueInstallments.length >= this.repayment.installments
      },
      showBanner(){
          return [ ...this.payments, ...this.paymentsNext].filter(p => p.selected).some(p => p.billingProduct == 81 ) && this.systemConfig?.dues
      },
      showSubcription(){
      const haveAdminExpenses = [...this.payments, ...this.paymentsNext].filter(payment=> payment.selected).some(payment=> payment.billingProduct === 87)
      return !haveAdminExpenses || false
    },
      extraBenefitPercentage(){
          return this.teclabReenrollment?.extraBenefit[0]?.percentageSemester
      },
      contractPayment(){
        const valiteStep= this.step != 1 && this.step != 3 && this.selectedMethod.id
        if (this.selectedMethod.id && this.selectedMethod.id !== 'TC2' ) return !!this.systemConfig?.contract && valiteStep
        else if (this.selectedMethod.id && this.selectedMethod.id === 'TC2' ) return  !!this.systemConfig?.contract && valiteStep &&  this.activeCardIndex === 1
        else return false
      },
      selectAllPaymentMexico(){
        return this.$nextTick(() => this.$refs.paymentSelectionGridView.selectAll(this.$isMexico || this.$isIPP ? true : false))
      },
      validateExtraBenefit(){
        return [ ...this.payments, ...this.paymentsNext].filter(p => p.selected).some(p => p.billingProduct == 81 ) && !this.systemConfig?.dues
      },
      validateExtraBenefitForFirstStep(){
        return [ ...this.payments, ...this.paymentsNext].filter(p => p.selected).some(p => p.billingProduct == 81 )
      },
      validateExtraBenefitSemester(){
        const selectedPayments = [...this.payments, ...this.paymentsNext].filter(p => p.selected);
        const uniqueTermPayments = selectedPayments.filter((payment, index, self) =>
            self.findIndex(p => p.options.term.id === payment.options.term.id && p.billingProduct === 81 ) === index
        );
        // const hasPreSale = selectedPayments.some(({ preSale }) => preSale === true);
        const countArancel = uniqueTermPayments.filter(p => p.billingProduct === 81).length;
        if(this.systemConfig?.dues){
          let totalInstallment = countArancel * this.systemConfig.countDues
          if(selectedPayments.some(({ preSale }) => preSale === true)){
            totalInstallment = totalInstallment + 1
            return (selectedPayments.filter(({ billingProduct })=> billingProduct === 81).length == totalInstallment) && countArancel == 2
          }else{
            return (selectedPayments.filter(({ billingProduct })=> billingProduct === 81).length == totalInstallment) && countArancel == 2
          }
        } else{
          return countArancel === 2;
        }
      },
  },
  async mounted() {
      this.getSubscriptionPaymentTypes()
      this.getSubscriptions()
      this.systemConfig = (await this.$getSystemConfig())?.data
      await this.fetchData()
      this.specialPayFlow()
  },
  methods: {
    validateExtraBenefits(){
      return this.validateExtraBenefitForFirstStep
    },
    haveSelectedToPayForSemester(){
      const allArancelPayments = [...this.payments, ...this.paymentsNext].filter(({ billingProduct })=> billingProduct === 81)
      const hasPreSale = allArancelPayments.some(({ preSale }) => preSale === true);
      const lengthArancelSelected =  allArancelPayments.filter(({ selected })=> selected).length
      let lengthGroupByArancelTerms = Object.keys(_.groupBy(allArancelPayments, 'options.term.id')).length;

      if(this.systemConfig?.dues){
        lengthGroupByArancelTerms = lengthGroupByArancelTerms * this.systemConfig.countDues
      }

      if(hasPreSale){
        lengthGroupByArancelTerms = lengthGroupByArancelTerms + 1
      }
      return lengthArancelSelected === lengthGroupByArancelTerms && lengthGroupByArancelTerms > 1
    },
    handleIntentoDePagoId(intentodepagoid) {
      this.intentodepagoid = intentodepagoid;
    },
      closeDocumentationUpload(processingId) {
          if (processingId) this.getProductsWithPrice()
          this.documentationDrawerOpen = false
      },
      async getProductsWithPrice() {
          const params = { studentCareer: this.studentRegister }
          if(this.$route.params?.termIds && this.$route.params?.termIds.length > 0) {
              params.termIds = this.$route.params.termIds
          }
            try {
                const { data } = await $productsWithPricePeriods?.find(null, null, {params})

                if (data.extraBenefit === null) data.extraBenefit = []
                if (data.products === null) data.products = []

                if (data.products.prepaids?.some(product => product.idProduct !== 80) || data.prepaids?.length > 0) {
                    this.productWithPricePrepaids = data.prepaids?.map((prepaid)=>{return {...prepaid, percentage: parseFloat(this.$decryter(prepaid.percentage))}})
                    this.productWithPricePrepaids?.sort((a, b) => (a.dayFrom > b.dayFrom) ? 1 : -1)
                }
                data.benefit?.products.map(item => {item.amount = item.amount? parseFloat(this.$decryter(item.amount)):null;
                                        item.percentage = item.percentage? parseFloat(this.$decryter(item.percentage)):null});
                data.products?.map(item =>{item.amount = item.amount ? parseFloat(this.$decryter(item.amount)):null
                                        item.benefitExtra = item.benefitExtra ? parseFloat(this.$decryter(item.benefitExtra)): null
                                        item.prepaids?.map(prepaid =>{prepaid.percentage = prepaid.percentage = parseFloat(this.$decryter(prepaid.percentage))})});
                data.discount?.details.map(item => {item.biannualAmount= item.biannualAmount? parseFloat(this.$decryter(item.biannualAmount)):null
                                        item.biannualPercentage= item.biannualPercentage? parseFloat(this.$decryter(item.biannualPercentage)):null
                                        item.bimesterAmount= item.bimesterAmount? parseFloat(this.$decryter(item.bimesterAmount)):null
                                        item.bimesterPercentage= item.bimesterPercentage? parseFloat(this.$decryter(item.bimesterPercentage)):null})
                data.referedCredits = data.referedCredits? parseFloat(this.$decryter(data.referedCredits)):null;
                data.scholarship?.products.map(item => {item.amount = item.amount? parseFloat(this.$decryter(item.amount)):null
                                            item.percentage = item.percentage? parseFloat(this.$decryter(item.percentage)):null});
                data.uniqueDiscount = data.uniqueDiscount? parseFloat(this.$decryter(data.uniqueDiscount)):null;
                this.teclabReenrollment = data
                this.showBannerBenefitExtra = data?.products?.find(({benefitExtra}) =>  benefitExtra > 0)
            } catch (error) {
                this.teclabReenrollment = null
                throw error
            }
      },
      async getAdaptationInsurance() {
          try {
              const { data } = await $studentAccount?.find('getAdaptationInsuranceTerms', null, { params: { studentId: this.studentId } })
              this.adaptationInsuranceTerms = data.termIdLst
          } catch (error) {
              this.adaptationInsuranceTerms = []
              throw error
          }
      },
      checkParam(param, array) {
          const getQueryParams = (params, url) => {
              const href = url;
              const reg = new RegExp(`[?&]${params}=([^&#]*)`, 'i');
              const queryString = reg.exec(href);
              return queryString ? queryString[1] : null;
          };
          const payParam = getQueryParams(param, this.$route.hash);
          return array.includes(payParam) ? payParam : null
      },
      specialPayFlow() {
          if (this.$route.params.billingProduct) {
              const specialPays = this.hiddenPayments.filter(payment => payment.billingProduct == this.$route.params.billingProduct)
              const payment = specialPays.slice(-1)
              this.onBillingChange(payment)
              this.onPayClick()
          }
      },
      dateFormat(dateString) {
          const date = new Date(dateString)
          return `${date.getUTCDate()}/${date.getUTCMonth() + 1}/${date.getUTCFullYear().toString().substr(-2)}`
      },
      async fetchData(data) {
              try {
                  this.productsToHide = [`${this.$sisConstants('modules.products.matricula')}`]

                  this.getConfig()
                  let response = {}
                  if(data){
                    response.data = data
                  }else{
                    response = await $studentAccount?.findById(this.studentId)
                  }
                  const payments = []
                  this.ippReenrollment = response.data
                  this.repayment.available = response.data.repaymentAvailable
                  this.repayment.installments = response.data.minInstallmentsRepayment
                  response.data.accounts.forEach((acc, i) => {
                      // Se excluyen pagos con descripcion arancel ya que se duplican en base
                      if ((this.systemConfig?.dues)||(this.systemConfig?.contract) || this.$isMexico) {
                        if (acc.paymentStatus && acc.paymentStatus.value != 114) {
                              if (acc.paymentStatus.value == 117) this.hasDebt = true
                              if (!(acc.billingProduct && acc.billingProduct.id == 83 && acc.paymentInstallment === 0)) payments.push(acc)
                          } else {
                              const dateId = (new Date()).getTime()

                              acc.paymentStatus = PENDING_PAYMENT_STATUS
                              if (!acc.id) this.generatePaymentIds = true
                              acc.reenrollmentId = `${dateId}-${i}`,
                              payments.push(acc)
                          }
                      }
                  })
                    response.data.accounts.map(item =>{item.paymentAmount = parseInt(this.$decryter(item.paymentAmount))
                                            item.benefitExtra = item.benefitExtra ? parseFloat(this.$decryter(item.benefitExtra)): null
                    item.prepaids?.map(prepaid => prepaid.percentage = parseInt(this.$decryter(prepaid.percentage)))})
                                .sort((a, b) => a.dayFrom - b.dayFrom)
                    response.data.uniqueDiscount = response.data.uniqueDiscount? parseFloat(this.$decryter(response.data.uniqueDiscount)):null
                    this.productsToHide = []
                    if(!data){
                      await this.getProductsWithPrice()
                    }

                    if(this.systemConfig?.adaptationInsurance) {
                    await this.getAdaptationInsurance()
                    }
                    if (this.teclabReenrollment && this.teclabReenrollment.products ) {
                      this.teclabReenrollment.products.forEach((prod, prodIndex) => {
                        let total = prod.amount
                        let off = 0
                        let extraOff = 0
                        let benefitAmount = 0
                        let scholarshipAmount = 0
                        const { preSale } = prod;
                        const discount = this.teclabReenrollment.discount?.details?.find(disc => disc.billingProduct.id == prod.idProduct)
                        if (discount) {
                        if (prod.idProduct == this.$sisConstants('modules.products.matricula')) {
                          if (discount.biannualAmount) off += discount.biannualAmount / total * 100
                        else if (discount.biannualPercentage) off += discount.biannualPercentage
                        } else if (prod.idProduct == this.$sisConstants('modules.products.arancel')) {
                          let discountOff = 0
                          if (discount.bimesterAmount) discountOff = discount.bimesterAmount / total * 100
                          else if (discount.bimesterPercentage) discountOff = discount.bimesterPercentage
                          off += discountOff

                          let bianualOff = 0
                          if (discount.biannualAmount) bianualOff = discount.biannualAmount / total * 100
                          else if (discount.biannualPercentage) bianualOff = discount.biannualPercentage
                          if (bianualOff && bianualOff > discountOff) extraOff = bianualOff - discountOff
                        }
                      }

                      if (!this.teclabReenrollment.scholarship?.renew || this.teclabReenrollment.scholarship?.renew.status?.value == 77) {
                        const scholarship = this.teclabReenrollment.scholarship?.products?.find(s => s.billingProduct == prod.idProduct)
                        if (scholarship && (this.$isTeclab || off < 100)) {
                        scholarshipAmount = scholarship.percentage ? total * scholarship.percentage / 100 : scholarship.amount
                        if (scholarshipAmount > total) {
                            scholarshipAmount = total
                        }
                        if (this.$isIPP) {
                            total -= scholarshipAmount
                        }
                        off += this.teclabReenrollment.accumulativeDiscount ? scholarship.percentage || scholarship.amount / total * 100 : scholarship.percentage * (100 - off) / 100
                      }
                    }

                    if (this.teclabReenrollment.benefit?.statusProcessing?.status.value == 93 || !this.teclabReenrollment.benefit?.documentation.length) {
                      const benefit = this.teclabReenrollment.benefit?.products?.find(b => b.billingProduct == prod.idProduct)
                      if (benefit && (this.$isTeclab || off < 100)) {
                        benefitAmount = benefit.percentage ? total * benefit.percentage / 100 : benefit.amount
                        if (benefitAmount > total) {
                            benefitAmount = total
                        }
                        if (this.$isIPP) {
                            total -= benefitAmount
                        }
                        off += this.teclabReenrollment.accumulativeDiscount ? benefitAmount / prod.amount * 100 : benefit.percentage * (100 - off) / 100
                        }
                    }

                    const isAdaptationInsurance = (prod.idProduct == this.$sisConstants('modules.products.arancel')
                            || prod.idProduct == this.$sisConstants('modules.products.matricula'))
                        && this.adaptationInsuranceTerms.includes(prod.idTerm)
                    if (isAdaptationInsurance) {
                        benefitAmount = prod.amount
                        off = 100
                    }

                    if (this.teclabReenrollment.referedCredits) {
                        this.referedCredits = this.teclabReenrollment.referedCredits
                        if (this.$isIPP) {
                        total -= this.referedCredits
                        }
                    }

                    if ((this.$isIPP || this.$isMexico) && off > 100) off = 100
                    const dateId = (new Date()).getTime()
                            if(this.$isPeru || this.$isMexico) {
                            payments.push({
                                accountDescription: prod.name,
                                billingProduct: { id: prod.idProduct },
                                paymentAmount: prod.amount,
                                scholarshipAmount,
                                benefitAmount,
                                preSale,
                                benefitExtra: this.showBannerBenefitExtra?.benefitExtra?? null,
                                id: null,
                                reenrollmentId: `${dateId}-${prodIndex}`,
                                term: { id: prod.idTerm, description: prod.termCode},
                                paymentDueDate: prod.dueDate,
                                paymentInstallment: prod.installment,
                                paymentInstallments: prod.installments,
                                mandatory: prod.mandatory,
                                paymentStatus: PENDING_PAYMENT_STATUS,
                                paymentStudentRecord: this.studentRegister,
                                off,
                                reenrollmentExtraOff: extraOff,
                                adaptationInsurance: isAdaptationInsurance,
                                subdescription: prod.subDescription,
                                prepaids: prod.prepaids
                            })
                            }
                            else {
                            payments.push({
                                accountDescription: prod.name,
                                billingProduct: { id: prod.idProduct },
                                paymentAmount: prod.amount,
                                scholarshipAmount,
                                benefitAmount,
                                preSale,
                                benefitExtra: null,
                                id: null,
                                reenrollmentId: `${dateId}-${prodIndex}`,
                                term: { id: prod.idTerm, description: prod.termCode, classStartDate: prod.classStart, classEndDate: prod.classEnd},
                                paymentDueDate: prod.dueDate,
                                paymentInstallment: 1,
                                paymentInstallments: 1,
                                mandatory: prod.mandatory,
                                paymentStatus: PENDING_PAYMENT_STATUS,
                                paymentStudentRecord: this.studentRegister,
                                off,
                                reenrollmentExtraOff: extraOff,
                                adaptationInsurance: isAdaptationInsurance,
                                prepaids: prod.prepaids
                            })
                            }
                        });
                    }
                    payments.sort((a, b) => {
                      if (b.billingProduct?.id == this.$sisConstants('modules.products.derecho_examen')) return 1
                      const dateA = new Date(a.paymentDueDate)
                      const dateB = new Date(b.paymentDueDate)
                      if (dateA > dateB) return 1
                      if (dateA < dateB) return -1
                      if (a.billingProduct?.id == this.$sisConstants('modules.products.arancel') && b.billingProduct?.id == this.$sisConstants('modules.products.matricula')) return 1
                      if (b.billingProduct?.id == this.$sisConstants('modules.products.arancel') && a.billingProduct?.id == this.$sisConstants('modules.products.matricula')) return -1
                      return 0
                    });

                  const adaptationInsuranceIndex = payments?.findIndex(payment => payment.adaptationInsurance)
                  if (adaptationInsuranceIndex !== -1) {
                      payments.map((payment, index) => {
                          payment.disabled = true
                          if (adaptationInsuranceIndex === 0) {
                            if (payment.adaptationInsurance && (index === 0 || payments[index - 1].adaptationInsurance)) {
                                payment.disabled = false
                            }
                          }
                          if (index < adaptationInsuranceIndex) {
                              payment.disabled = false
                          }
                          return payment
                      })
                  }

                  const now = new Date()
                  const nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1)
                  const groupsId = {
                      arancel: 1,
                      repactacion: 2,
                      independent: 3,
                      rematricula: 4,
                  }
                  payments.forEach(account => {

                      const description = account.accountDescription.toLowerCase()
                      const exp = new Date(account.paymentDueDate)
                      let arr = null
                      let title = null

                      if (description.match(/.*cuota.*/)) title = account.accountDescription
                      else if (account.billingProduct && account.billingProduct.id == 83) title = `Repactación cuota ${account.paymentInstallment}/${account.paymentInstallments}`
                      else title = account.accountDescription
                      let groupId = null

                      if (account.billingProduct && (!account.reenrollmentId || this.$isIPP))  {
                          switch (account.billingProduct.id) {
                              case 80:
                              case 81:
                                  groupId = groupsId.arancel
                                  break;
                              case 82:
                              case 85:
                              case 86:
                                  groupId = groupsId.independent
                                  break;
                              case 83:
                                  groupId = groupsId.repactacion
                                  break;
                              default:
                                  groupId = groupsId.repactacion
                                  break;
                          }
                      } else if (account.reenrollmentId) {
                          groupId = groupsId.rematricula;
                      } else {
                          groupId = groupsId.arancel;
                      }

                      if (account.term && account.benefitExtra > 0) {
                          this.showExtraBenefit = true
                      }

                      let off = 0
                      const scholarships = []
                      const discounts = []
                      const benefits = []
                      let tmpTotal = account.paymentAmount
                      if (groupId == groupsId.independent || this.systemConfig?.contract || !this.systemConfig?.contract) {
                        if ((account.scholarshipAmount && account.scholarship) || this.ippReenrollment?.scholarship) {
                              tmpTotal -= account.scholarshipAmount
                              const fullScholarship = account.scholarshipAmount >= account.paymentAmount
                                off += !fullScholarship ? account.scholarshipAmount / account.paymentAmount * 100 : 100 || Math.floor(this.$decryter(this.ippReenrollment?.scholarship.percentage))

                                scholarships.push({
                                  description: account.scholarship?.description || this.ippReenrollment?.scholarship?.id,
                                  off: (account.scholarship?.percentage || 0) || Math.floor(this.$decryter(this.ippReenrollment?.scholarship.percentage)) || 0,
                                  amount: !fullScholarship ? account.scholarshipAmount : account.paymentAmount || Math.floor(this.$decryter(this.ippReenrollment?.scholarship.percentage)),
                                  id: account.scholarship?.id || this.ippReenrollment?.scholarship?.id
                                })
                          }

                          if(this.ippReenrollment?.benefit && this.ippReenrollment?.benefit.statusProcessingBenefit == true) {
                            if(this.ippReenrollment?.benefit?.amount && this.ippReenrollment?.benefit.statusProcessingBenefit == true ){
                              off += Math.floor(this.$decryter(this.ippReenrollment?.benefit?.amount) / account.paymentAmount * 100)
                            } else {
                              off += Math.floor(this.$decryter(this.ippReenrollment?.benefit.percentage))
                            }
                            benefits.push({
                              description: this.ippReenrollment?.benefit?.description,
                              off: this.ippReenrollment?.benefit?.percentage,
                              amount: this.ippReenrollment?.benefit?.amount,
                              percentage: this.ippReenrollment?.benefit?.percentage,
                              id: this.ippReenrollment?.benefit?.id
                            })
                          }

                          if ((account.discountAmount && account.discount && account.discount.discountDetails) || (account.discountAmount && this.systemConfig?.dues)) {
                              const fullDiscount = account.discountAmount >= tmpTotal
                              if (!fullDiscount) off += (100 - off) * account.discountAmount / tmpTotal
                              else off = 100
                              account.discount
                              discounts.push({
                                  description: account.discount?.description?? null,
                                  // off: !fullDiscount ? (100 - off) * account.discountAmount / tmpTotal : 100 - off,
                                  off: account.discount.discountDetails.biannualPercentage || account.discount.discountDetails.bimesterPercentage ,
                                  amount: !fullDiscount ? account.discountAmount : tmpTotal,
                                  idDetail: account.discount.discountDetails.idDiscountDetail
                              })
                          }
                      }
                      arr = {
                          id: account.id,
                          reenrollmentId: account.reenrollmentId || null,
                          title,
                          groupId,
                          independent: groupId == groupsId.independent,
                          selected: true,
                          mandatory: account.mandatory,
                          repaymentAvailable: this.repayment.available && account.paymentStatus.value == 117 && groupId === groupsId.repactacion,
                          studentRecord: account.paymentStudentRecord,
                          status: account.paymentStatus.value,
                          suscriptionView: false,
                          preSale: account.preSale,
                          // this is required for re-enrollment payment
                          isHiddenProduct: account.billingProduct && this.productsToHide.includes(`${account.billingProduct.id}`),
                          billingProduct: account.billingProduct ? account.billingProduct.id : null,
                          subscription: response.data.subscription,

                          options: {
                            term: account.term,
                            dueDate: account.paymentDueDate,
                            expiration: `Hasta el ${this.dateFormat(account.paymentDueDate)}`,
                            inscriptionDate: response.data.inscriptionDate || this.teclabReenrollment.inscriptionDate,
                            paymentInstallment: account.paymentInstallment,
                            paymentInstallments: account.paymentInstallments,
                            month: exp.getUTCMonth() + 1,
                            scholarshipAmount: account?.scholarshipAmount || 0,
                            benefitAmount: account?.benefitAmount || 0,
                            benefitExtra: this.teclabReenrollment?.products?.benefitExtra || account?.benefitExtra,
                            extraOff: 0,
                            reenrollmentExtraOff: account.reenrollmentExtraOff ? account.reenrollmentExtraOff : 0,
                            addedExtraOff: 0,
                            off: this.$options.filters.$roundNumber(account.off ? account.off : off),
                            discounts,
                            scholarships,
                            amount: account.paymentAmount,
                            adaptationInsurance: account.adaptationInsurance,
                            disabled: !!account?.disabled,
                            subdescription: account.subdescription,
                            accumulativeDiscount: this.teclabReenrollment?.accumulativeDiscount,
                            prepaids: account.prepaids
                          }
                      }

                      const getRepactationPayments = payment=> payment.groupId === groupsId.repactacion
                      if (arr) {
                          if(arr.isHiddenProduct){
                              if(account.discount && account.discountAmount){
                                  arr.options.off = account.discountAmount * 100 / account.paymentAmount;
                              }
                              this.hiddenPayments.push(arr)
                          } else if ((exp < nextMonth && arr.groupId != groupsId.arancel) || (this.$isMexico && exp < nextMonth) ) {
                              this.payments.push(arr)
                              const [repactacionPayments, arancelPayments] = _.partition(this.payments, getRepactationPayments)
                              repactacionPayments.sort((a,b)=>{
                                const dateA = new Date(a.options.dueDate)
                                const dateB = new Date(b.options.dueDate)
                                if (dateA > dateB) return 1
                                if (dateA < dateB) return -1
                              })
                              arancelPayments.sort((a,b) => a.billingProduct - b.billingProduct)
                              this.payments = [...repactacionPayments, ...arancelPayments]
                          } else if (arr.billingProduct == this.$sisConstants('modules.products.derecho_examen')) {
                            this.payments.push(arr)
                          } else if (this.teclabReenrollment && this.teclabReenrollment.products?.length) {
                            if (arr.groupId == groupsId.arancel || this.systemConfig?.dues || !this.systemConfig?.contract) this.paymentsNext.push(arr)
                            else if (!this.systemConfig?.dues) this.futurePayments.push(arr)
                            } else {
                              this.paymentsNext.push(arr)
                              if(!this.systemConfig?.dues){
                                const [repactacionPayments, arancelPayments] = _.partition(this.paymentsNext, getRepactationPayments)
                                repactacionPayments.sort((a,b)=>{
                                  const dateA = new Date(a.options.dueDate)
                                  const dateB = new Date(b.options.dueDate)
                                  if (dateA > dateB) return 1
                                  if (dateA < dateB) return -1
                                })
                                arancelPayments.sort((a,b) => a.billingProduct - b.billingProduct)
                                this.paymentsNext = [...repactacionPayments, ...arancelPayments]
                              }
                          }
                      }
                  });
                  const allPayments = [...this.payments, ...this.paymentsNext]
                  this.onBillingChange(allPayments, 'all')
                  // this.$refs.paymentSelectionGridView.selectAll(true)
                  this.selectAllPaymentMexico
              } catch (error) {
                  this.payments = []
                  this.paymentsNext = []
                  throw error
              }
          },
          async getSubscriptionPaymentTypes() {
            try {
              const {data} = await $types?.find(null, null, { params: { type: 'SUSCRIPTION_PAYMENT_TYPE' } })
              this.subscriptionPaymentTypes = data
            } catch (err) {
              this.subscriptionPaymentTypes = []
              throw err
            }
          },
          async getSubscriptions() {
            try {
              const { data } = await $suscriptions?.find(null, null, { params: { active: true, studentCareerId: this.studentRegister } })
              this.subscriptions = data
            } catch (err) {
              this.subscriptions = []
            }
          },
          async getConfig() {
              try {
                  const response = await $accounts?.find()
                  await this.$store.dispatch('commons/setMpConfig', response.data);
                  this.isCheckoutPro = !!response.data?.find((account) => !!account.checkout)
                  this.isGetnet = !!response.data?.find((account) => account.accountKey === 'GETNET' && account.status === true)
                  this.isFintoc = !!response.data?.find((account) => !!account.fintoc)
              } catch {
                  await this.$store.dispatch('commons/setMpConfig', null);
              }
          },
          goBackPayment() {
              if (!this.resumen.total) this.step--
              this.previousStep()
              this.$store.dispatch('commons/updateCheckTerms', false);
              this.creditCards = []
              this.selectedMethod = {id: null};
              this.$nextTick(function () {
                if (this.$isGestor || this.studentHasPreviousSubscriptionPaid) {
                  this.resumen.items.adminExpenses.monto = 0
                  this.resumen.total = this.resumen.items.subtotal.monto - this.resumen.items.discounts.monto -this.resumen.items.scholarships.monto - this.resumen.items.benefits.monto
                  if (this.resumen.total < 0) this.resumen.total = 0
                  this.showSubscriptionPaymentMethod()
                }
              })
              this.$trackEvent('Pagos', 'Volver', 'Método de pago 2')
          },
          goToRepayment() {
              this.isRepayment = true
              if(this.isRepayment & this.$isIPP) this.$trackEvent('Pago', 'Clk_BtnRepact_Repac', 'Pago repactación')  //Google Analytics - Repactacion 1
              this.repayment.selected = true
              this.summaryButtons.primary.disabled = true
              this.summaryButtons.secondary.visible = true
              this.summaryButtons.tertiary.visible = false

              this.resumen.items.subtotal.items = JSON.parse(JSON.stringify(this.resumen.items.repayments.items))
              this.resumen.items.subtotal.items = this.resumen.items.subtotal.items.map(item => {
                  item.title = `Arancel cuota ${item.title}`
                  return item
              })
              this.resumen.items.subtotal.monto = this.resumen.items.repayments.monto
              this.resumen.items.discounts.items = []
              this.resumen.items.discounts.monto = 0
              this.resumen.items.scholarships.items = []
              this.resumen.items.scholarships.monto = 0
              this.resumen.total = this.resumen.items.subtotal.monto
              if (this.resumen.total < 0) this.resumen.total = 0

              this.$nextTick()
              this.definePrimaryCallback()
          },
          isAllProductByGroupTerms(term){
            const paymentsSelected = [...this.payments, ...this.paymentsNext]
            const groupByTerms = _.groupBy(paymentsSelected, 'options.term.id')
            return groupByTerms[term] && groupByTerms[term]?.length >= this.systemConfig.countDues
          },
          isSelectedAllProductByGroupTerms(term){
            const paymentsSelected = [...this.payments, ...this.paymentsNext]
              .filter(payment => payment.selected)
            const groupByTerms = _.groupBy(paymentsSelected, 'options.term.id')
            return groupByTerms[term] && groupByTerms[term]?.length >= this.systemConfig.countDues
          },
          setResumenForExtraBenefit() {
            let scholarshipAndBenefitAmount = 0
            let discountAmount = 0
            // set discounts resumen
            this.productsForDiscountHistory.forEach((product) => {
              const payment = [...this.payments, ...this.paymentsNext, ...this.futurePayments].find(({ id, reenrollmentId }) => {
                return id && id == product.accountId || reenrollmentId && reenrollmentId == product.reenrollmentId
              })
              if (payment.selected) {

                Object.entries(product).forEach(([key, value]) => {
                  if (key === 'scholarship' || key === 'benefit') {
                    scholarshipAndBenefitAmount += value.amount
                  }
                })
              }

              let amount = 0
              let percentage = 0
              if (payment.selected) {
                ({ amount, percentage } = Object.entries(product)
                  .reduce((acc, [key, value]) => {
                    if (!['discount', 'prePaid'].includes(key)) return acc
                    return acc = {
                      amount: acc.amount += value.amount,
                      percentage: acc.percentage += value.percentage
                    }
                  }, { amount: 0, percentage: 0 }))
              }

              const discount = this.resumen.items.discounts.items
              ?.find(({ id, reenrollmentId }) => id && id === product.accountId || reenrollmentId && reenrollmentId === product.reenrollmentId)
              // eslint-disable-next-line no-prototype-builtins
              const isBenefitExtra = product.hasOwnProperty('extraBenefit')
              if (discount && isBenefitExtra && payment.selected && payment.billingProduct != 80) {
                discountAmount += product.extraBenefit.amount + amount
                discount.monto = product.extraBenefit.amount + amount,
                  discount.title = `${product.extraBenefit.description} - ${this.$options.filters.$roundNumber(percentage + product.extraBenefit.percentage)}% off`
              } else if (discount && payment.selected ) {
                discountAmount += amount
              }
            })

            let total = this.resumen.items.subtotal.monto - scholarshipAndBenefitAmount - discountAmount
            // add uniqueDiscount
            const uniqueDiscount = this.productsForDiscountHistory.reduce((acc, product) => {
              // eslint-disable-next-line no-prototype-builtins
              if (!product.hasOwnProperty('uniqueDiscount')) return acc
              return acc += product.uniqueDiscount.amount
            }, 0)
            total -= uniqueDiscount
            // add referredCredits
            const referredCredits = this.productsForDiscountHistory.reduce((acc, product) => {
              // eslint-disable-next-line no-prototype-builtins
              if (!product.hasOwnProperty('referredCredits')) return acc
              return acc += product.referredCredits.amount
            }, 0)
            total -= referredCredits

            this.resumen.total = total
            if (this.resumen.total < 0) this.resumen.total = 0
            this.resumen.items.discounts.monto = discountAmount
          },
          async paymentSelected(payment) {
            // restore old discounts in summary
            if(payment.id == 'SUS') {
              this.paymentSubscriptionFlow = true
            }
            if(!this.repayment.selected){
              this.resumen.items.discounts = _.cloneDeep(snapshotDiscountsSummary)
            }

            const { extraBenefit } = this.teclabReenrollment
            const { extraBenefits } = this.ippReenrollment
            if(payment.id !== 'SUS' && !this.systemConfig.dues && (extraBenefit || extraBenefits)){
              this.setResumenForExtraBenefit()
            }

            await this.onPayClick()
              // .catch(()=>{
                // this.$store.dispatch('commons/openPopUp', {
                    // title: this.$t('modules.portalalumno.payments.paymentsStudents.view.discount.popups.error.title'),
                    // actionPrimary: { text: this.$t('actions.accept'), callback: ()=> {this.$router.go(-1)} },
                    // actionSecondary: { text: this.$t('actions.close'), callback () {} },
                    // icon: { color: 'error', name: 'mdi-close' },
                    // color: 'primary',
                // })
              // })
              if (payment.id != 'TC2' && payment.id != 'SUS') this.summaryButtons.primary.text = this.$t('modules.portalalumno.payments.paymentsStudents.view.labels.paymentLink')
              else this.summaryButtons.primary.text = this.$t('modules.portalalumno.payments.paymentsStudents.view.labels.continue')
              this.selectedMethod = payment
          },
          functionSelectPayFT(pay){
              this.selectPayFT = pay
          },
          async onPayClickSuscription(){
            if (this.$isGestor || this.studentHasPreviousSubscriptionPaid) {
              this.showSubscriptionPaymentMethod()
            }
          },
          async onPayClick () {
            try {
              if(this.systemConfig?.dues || this.$isIPP ) {
                  const isReenrollmentAvailable = (this.showBanner && !this.systemConfig?.contract) && !this.systemConfig?.dues ;
                  const itemsType = isReenrollmentAvailable ? 'reenrollmentItems' : 'allReenrollmentItems';
                  const summaryType = isReenrollmentAvailable ? 'resumen' : 'allItemsResumen';
                  if(this.$isIPP & !this.isRepayment) this.$trackEvent('Pago', 'CLK_BtnContPag_Pagos', 'Pago cuotas') // Común 1 Ipp
                  const reenrollmentStatus = await this.createReenrollmentItems(itemsType, summaryType);
                  if (!reenrollmentStatus) return false
                  if(isReenrollmentAvailable){
                      try {
                        await this.saveDiscounts(summaryType);
                      } catch{
                        throw {type: 'saveDiscounts'}
                      }
                  }else{
                      this.$trackEvent('Pago', 'CLK_BtnContPag_Pagos', 'PagoArancel')
                      try {
                        await this.saveDiscounts(summaryType);
                      } catch{
                        throw {type: 'saveDiscounts'}
                      }
                  }
              } else {
                const reenrollmentStatus = await this.createReenrollmentItems('reenrollmentItems', 'resumen')
                if (!reenrollmentStatus) return false
                this.$trackEvent('Pago', 'CLK_BtnContPag_Pagos', 'Pago cuotas')
                try {
                        await this.saveDiscounts('resumen');
                      } catch{
                        throw {type: 'saveDiscounts'}
                      }
              }
              // this.step++
              if ((!this.$isIPP && !this.$isMexico) && !this.resumen.total) {
                this.selectedMethod.id = 'FT'
                this.coupon.payment = {id: 'Efectivo'}
                this.summaryButtons.primary.text = this.$t('modules.portalalumno.payments.paymentsStudents.view.labels.continue')
              }

              if ((this.$isIPP || this.$isMexico) && this.selectedMethod.id == 'PZA') {
                this.summaryButtons.primary.disabled = false
              }else {
                this.summaryButtons.primary.disabled = true
                this.summaryButtons.secondary.visible = true
                this.summaryButtons.tertiary.visible = false
              }

              if (this.$isGestor || this.studentHasPreviousSubscriptionPaid) {
                this.showSubscriptionPaymentMethod()
              }
              this.$nextTick()
              this.$trackEvent('Pagos', 'Click - Pago 1', 'Selección de aranceles')
             } catch (error) {
                if( error && error.type === 'saveDiscounts'){
                    this.$store.dispatch('commons/openPopUp', {
                    title: this.$t('modules.portalalumno.payments.paymentsStudents.view.discount.popups.error.title'),
                    actionPrimary: { text: this.$t('actions.accept'), callback: ()=> {this.$router.go(-1)} },
                    actionSecondary: { text: this.$t('actions.close'), callback () {} },
                    icon: { color: 'error', name: 'mdi-close' },
                    color: 'primary',
                })}
              }

          },
          showSubscriptionPaymentMethod() {
            const paymentMethodsIds = this.$refs.PSV && this.$refs.PSV.paymentMethods.map(method => method.id)
            if (this.filteredSubscriptions.length && this.$isIPP &&  !paymentMethodsIds.includes('SUS') ) {
              this.$refs.PSV.paymentMethods.push(_.cloneDeep(this.subscriptionMethod))
            }
          },
          async createReenrollmentItems(reenrollmentItems, resumen) {
              if (this[reenrollmentItems].length) {
                  try {
                      const discountItems = this[resumen].items.discounts.items.map((item, index) => {
                        return item.monto + this[resumen].items.benefits.items[index].monto + this[resumen].items.scholarships.items[index].monto
                      })
                      if (this.referedCredits || this.teclabReenrollment?.uniqueDiscount || this.ippReenrollment?.uniqueDiscount) {
                          let remainingCredits = this.referedCredits + this.teclabReenrollment?.uniqueDiscount ?? 0 + this.ippReenrollment?.uniqueDiscount ?? 0

                          this[resumen].items.subtotal.items.forEach((item, index) => {
                              if (remainingCredits) {
                                  if (item.monto - discountItems[index] >= remainingCredits) {
                                      discountItems[index] += remainingCredits
                                      remainingCredits = 0
                                  } else {
                                      discountItems[index] += item.monto - discountItems[index]
                                      remainingCredits -= item.monto - discountItems[index]
                                  }
                              }
                          })
                      }
                      const reenrollmentItemsParam = this[reenrollmentItems].map((reenrollmentItem, index) => {
                        const amount = this[resumen].items.subtotal.items?.find(subtotalItem => subtotalItem.reenrollment?.billingProduct == reenrollmentItem.product)?.monto
                        reenrollmentItem.amount = this.$encrypter(amount)
                        reenrollmentItem.discount = this.$encrypter(discountItems[index] >= amount ? amount : discountItems[index])
                        //reenrollmentItem.paid = !this.systemConfig?.contract && this.systemConfig?.dues ? false : amount <= discountItems[index]
                        reenrollmentItem.user = this.studentUserId ? this.studentUserId : null
                        return reenrollmentItem
                      })

                      const params = {}
                      const accounts = await $studentAccount.create(reenrollmentItemsParam, { params })

                      accounts.data.forEach(account=>{
                        const product= this.productsForDiscountHistory?.find(product=> product.reenrollmentId === account.reenrollmentId)
                        if(!product) return
                        product.accountId = account.accountId
                      })
                      accounts.data.forEach(acc => {
                          const subtotal = this.resumen.items.subtotal.items?.find(s => s.reenrollmentId == acc.reenrollmentId)
                          if (subtotal) subtotal.id = acc.accountId
                          const subtotalAllItems = this.allItemsResumen.items.subtotal.items?.find(s => s.reenrollmentId == acc.reenrollmentId)
                          if (subtotalAllItems) subtotalAllItems.id = acc.accountId
                          const discount = this.resumen.items.discounts.items?.find(s => s.reenrollmentId == acc.reenrollmentId)
                          if (discount) discount.id = acc.accountId
                          const discountAllItems = this.allItemsResumen.items.discounts.items?.find(s => s.reenrollmentId == acc.reenrollmentId)
                          if (discountAllItems) discountAllItems.id = acc.accountId
                      })
                      if (this.adaptationInsuranceTerms.length && this[resumen].items.subtotal.items?.some(subtotalItem => subtotalItem.reenrollment.options.adaptationInsurance)) {
                          $studentAccount.create({ id: this.studentId }, {}, 'confirmAdaptationInsurance')
                      }
                      return true
                  } catch (error) {
                      this.$store.dispatch('commons/openPopUp', {
                          title: error.codeMeaning && error.codeMeaning != '' ? error.codeMeaning : this.$t('modules.portalalumno.payments.paymentsStudents.view.reenrollment.error_creating'),
                          content: null,
                          actionPrimary: { text: this.$t('actions.accept'), callback() {} },
                          icon: { color: 'error', name: 'mdi-close' },
                          color: 'primary',
                      });
                      return false
                  }
              } else return true
          },
          async saveDiscounts() {
            const discountsList = ['discount','scholarship','benefit','prePaid','referredCredits','uniqueDiscount']
            // add extraBenefit in discountsList

            if(this.selectedMethod.id && this.selectedMethod.id !== 'SUS' || !this.paymentSubscriptionFlow || this.systemConfig?.dues){
              discountsList.push('extraBenefit')
            }
            const items = this.productsForDiscountHistory.map(product=>{
                  const { accountId } = product
                  const { selected } = product;
                  const discounts = {}
                  discountsList.forEach(discount=>{
                    if(!product[discount]) return discounts[discount] = null
                    const {name, amount, percentage} = product[discount]
                    let id
                    if(product[discount].id){
                      id = product[discount].id // eslint-disable-line
                    }
                    discounts[discount] = {
                      accountId,
                      id: id || accountId,
                      name,
                      amount: this.$encrypter(amount),
                      percentage: this.$encrypter(percentage)
                    }
                  })

                  return {
                    ...discounts,
                    accountId,
                    selected
                  }
              })
            const payload = {
              userName: this.studentData?.userData?.username,
              studentCareerId: this.studentRegister,
              paid: !this.resumen.total,
              items
            }
          const { data } = await $discount.create(payload);
          this.resumen.paymentIntent = data.intent;
      },
      previousStep() {
          this.creditCards = []
          this.$store.dispatch('commons/updateCheckTerms', false);
          if((this.step === 2 || this.paymentsSnapshot.length) && !this.repayment.selected){
            this.resetViewStates()
            this.paymentSubscriptionFlow = false
            this.fetchData().then(async ()=>{
              await this.$nextTick()
              this.onBillingChange([...this.payments, ...this.paymentsNext], 'all')
              this.onBillingChange([...this.payments, ...this.paymentsNext])
              this.$refs.paymentSelectionGridView.checkAllSelected()
            })
          }
          if (this.step > 1) {
              this.summaryButtons.primary.callback = ()=>{ this.onPayClickSuscription(); this.step++ }
              this.step = 1
              this.selectedMethod = { id: null }
              if (this.$isGestor || this.studentHasPreviousSubscriptionPaid) {
                  this.resumen.items.adminExpenses.monto = 0
              }
              this.$trackEvent('Pagos', 'Cancelar', 'Step pago')
          } else {
              this.repayment.selected = false
              this.summaryButtons.tertiary.visible = true
          }

          if (!this.repayment.selected) {
              const payments = [...this.payments.filter(payment => payment.selected), ...this.paymentsNext.filter(payment => payment.selected)]
              this.onBillingChange(payments)
          }
          this.resetButtons()
      },
      definePrimaryCallback() {
          if (this.step == 1) {
              if (!this.repayment.selected) this.summaryButtons.primary.callback = () => {
                if (!this.resumen.total){
                    this.selectedMethod.id = 'PZA'
                }
                else {
                  this.onPayClickSuscription()
                } this.step++ }
              else this.summaryButtons.primary.callback = () => { this.$refs.REP.confirm() }
          } else if (this.step == 2 && this.selectedMethod.id == 'TC1') {
              this.summaryButtons.primary.callback = () => { this.analyticsPaymentEvent(); this.$refs.TC1.saveBillingData() }
          } else if (this.step == 2 && this.selectedMethod.id == 'TC2') {
              this.summaryButtons.primary.callback = () => { this.analyticsPaymentEvent(); this.$refs.TC2.paymentCardsInfo() }
          } else if (this.step == 2 && this.selectedMethod.id == 'TD') {
              this.summaryButtons.primary.callback = () => { this.analyticsPaymentEvent(); this.$refs.TD.$refs.debitCard.saveBillingData() }
          } else if (this.step == 2 && ['FT', 'ATM'].includes(this.selectedMethod.id)) {
              this.summaryButtons.primary.callback = () => { this.analyticsPaymentEvent(); this.resumen.total ? this.$refs.FT.generateCoupon() : this.$refs.FT.oneCentReceipt() }
          } else if (this.step == 2 && this.selectedMethod.id == 'SUS') {
              this.summaryButtons.primary.callback = () => {
                this.analyticsPaymentEvent();
                this.paymentSubscriptionFlow = true
                this.selectedMethod.id = null
                //this.$refs.SUS.paymentInstallmentsSelected()
              }
          } else if (this.step == 2 && this.selectedMethod.id == 'CHECK') {
              this.summaryButtons.primary.callback = () => { this.analyticsPaymentEvent(); this.$refs.CHECK.redirectPay() }
          } else if (this.step == 2 && this.selectedMethod.id == 'FINTOC') {
              this.summaryButtons.primary.callback = () => { this.analyticsPaymentEvent(); this.$refs.FINTOC.redirectPay() }
          } else if (this.step == 2 && this.selectedMethod.id == 'GETNET') {
              this.summaryButtons.primary.callback = () => { this.analyticsPaymentEvent(); this.$refs.GETNET.initGetnet() }
          }else if (this.step == 2 && this.selectedMethod.id == 'PZA') {
            (async () => {
                await this.onPayClick();
            })()
              this.summaryButtons.primary.callback = () => { this.$refs.PZA.createPayment() }
          }
        },
      analyticsPaymentEvent() {
          const pay = this.payments?.find(payment => payment.selected &&
              (payment.billingProduct == this.$sisConstants('modules.products.matricula') ||
                  payment.billingProduct == this.$sisConstants('modules.products.examen') ||
                  payment.billingProduct == this.$sisConstants('modules.products.gasto_administrativo') ||
                  payment.billingProduct == this.$sisConstants('modules.products.nueva_tne') ||
                  payment.billingProduct == this.$sisConstants('modules.products.revalidacion_tne')))
              this.$trackEvent('Pagos', `Pago ${pay ? pay.title : 'Arancel'}`, 'Método de pago 2')

              // Google Analytics - BTN Pagar dependiendo el pago
              if(this.$isIPP){
                  if(this.isRepayment){
                      if(this.selectedMethod.id == 'TC1') this.$trackEvent('Pago', 'Clk_BtnPayCR_repac', 'Pago repactación')
                      if(this.selectedMethod.id == 'TD') this.$trackEvent('Pago', 'Clc_BtnPayDeb_repac', 'Pago repactación')
                  }else if (!this.isRepayment){
                      if(this.selectedMethod.id == 'TC1') this.$trackEvent('Pago', 'Clk_BtnPayCR_Pagos', 'Pago cuotas')
                      if(this.selectedMethod.id == 'TD') this.$trackEvent('Pago', 'Clc_BtnPayDeb_Pagos', 'Pago cuotas')
                  }
              }else if(this.$isTeclab){
                  if(this.selectedMethod.id == 'TC1') this.$trackEvent('Pago', 'Clk_BtnPayCR_Pagos', 'PagoArancel')
                  if(this.selectedMethod.id == 'TD') this.$trackEvent('Pago', 'Clc_BtnPayDeb_Pagos', 'PagoArancel')
                  if(this.selectedMethod.id == 'FT' & this.selectPayFT == 'pagofacil') this.$trackEvent('Pago', 'Clk_BtnPayPagFac_Pagos', 'PagoArancel')
                  if(this.selectedMethod.id == 'FT' & this.selectPayFT == 'rapipago') this.$trackEvent('Pago', 'Clk_BtnPayRapPag_Pagos', 'PagoArancel')
                  if(this.selectedMethod.id == 'CHECK') this.$trackEvent('Pago', 'Clk_BtnPayMerPag_Pagos', 'PagoArancel')
              }
          },
          resetButtons() {
              if (this.step == 1) {
                  this.summaryButtons.primary.disabled = !this.payments.length
                  this.summaryButtons.primary.text = this.$t('modules.portalalumno.payments.paymentsStudents.view.labels.seePaymentMethods')
                  this.summaryButtons.primary.loading = false
                  this.summaryButtons.primary.success = false
                  this.summaryButtons.primary.error = false
                  if (this.repayment.selected) {
                      this.summaryButtons.secondary.visible = true
                      this.summaryButtons.tertiary.visible = false
                  } else {
                      this.summaryButtons.secondary.visible = false
                      this.summaryButtons.tertiary.visible = this.enableRepayment
                  }
                  this.definePrimaryCallback()
              }
          },
      onBillingChange(payments, itemsToUpdate='selected') {
              if(itemsToUpdate === 'all'){
                  this.paymentsSelected =  payments
              } else if(itemsToUpdate === 'selected'){
                  this.paymentsSelectedAll = payments
              }
              const resumen = itemsToUpdate === 'all' ? 'allItemsResumen' : 'resumen';
              const reenrollmentItems = itemsToUpdate === 'all' ? 'allReenrollmentItems' : 'reenrollmentItems'
              this.summaryButtons.primary.disabled = !payments.length
              this.summaryButtons.tertiary.visible = !this.repayment.selected && this.enableRepayment
              if (payments?.some(payment => payment.options.adaptationInsurance)) {
                this.summaryButtons.primary.text = this.$t('modules.portalalumno.payments.paymentsStudents.view.labels.applyAdaptationInsurance')
              } else {
                this.summaryButtons.primary.text = this.$t('modules.portalalumno.payments.paymentsStudents.view.labels.seePaymentMethods')
              }

              this[reenrollmentItems] = []
              this[resumen].items.subtotal.items = payments.map(payment => {
                  if (payment.reenrollmentId) {
                    const itemsReenrollment = {
                      reenrollmentId: payment.reenrollmentId,
                      product: payment.billingProduct,
                      term: payment.options.term?.id,
                      student: this.studentId,
                      studentCareer: this.studentRegister,
                      dueDate: payment.options.dueDate,
                      amount: payment.options.amount
                    }
                    if(this.systemConfig.dues && !this.systemConfig.contract) {
                      itemsReenrollment.installment = payment.options.paymentInstallment,
                      itemsReenrollment.installments = payment.options.paymentInstallments
                    }
                    this[reenrollmentItems].push(itemsReenrollment)
                  }
                  return {
                    id: payment.id,
                    reenrollment: payment.id == null ? payment : null,
                    reenrollmentId: payment.reenrollmentId,
                    monto: payment.options.amount,
                    title: payment.title,
                    billingProduct: payment.billingProduct ,
                  }
              })

              const allPayments = [...this.payments, ...this.paymentsNext]
              const products = this[resumen].items.subtotal.items
                .map(product =>{
                  const payment = payments
                    ?.find(({id, reenrollmentId})=>id && product.id === id || reenrollmentId && product.reenrollmentId === reenrollmentId)
                  return {
                    id: product.id ?? null,
                    reenrollmentId: product.reenrollmentId,
                    billingProductId: product.billingProduct,
                    selected: product?.reenrollment?.selected || payment.selected,
                    discounts: discountsManager({
                      amount: product.monto,
                      accumulative: payment.options.accumulativeDiscount
                    })
                  }
                })

              //add discount Matricula for discount manager
              products
                .filter(product => product.billingProductId === 80)
                .map((product)=>{
                  const payment = payments
                  ?.find(({id, reenrollmentId})=>id && product.id === id || reenrollmentId && product.reenrollmentId === reenrollmentId)
                  const { off } = payment.options
                  const {discount} = this.teclabReenrollment
                  const detail = discount?.details?.find(detail => detail.billingProduct.id == 80)
                  if(detail){
                    let offAmount = 0
                    if(detail.bimesterAmount != null || detail.biannualAmount != null){
                       offAmount =((detail.bimesterAmount * 100)/payment.options.amount) || ((detail.biannualAmount * 100)/payment.options.amount)
                    }
                    product.discounts.add({
                      name:'discount',
                      percentage: detail.biannualPercentage || detail.bimesterPercentage || offAmount,
                      amount: 0,
                      id: detail.id
                    })
                  }else if(off) {
                    product.discounts.add({
                      name:'discount',
                      percentage: off < 100 ? off : 100,
                      amount: 0
                    })
                  }
              })
              //add discount Cuota for discount manager
              products
                .filter(product => product.billingProductId === 89)
                .map((product, index)=>{
                  const payment = payments
                  .find(({id, reenrollmentId})=>id && product.id === id || reenrollmentId && product.reenrollmentId === reenrollmentId)
                  const {discount} = this.teclabReenrollment
                  const detail = discount?.details?.find(detail => detail.billingProduct.id === 89)

                  if(detail){
                    let offAmount = 0
                    if(detail.bimesterAmount != null || detail.biannualAmount != null){
                      offAmount =((detail.bimesterAmount * 100)/payment.options.amount) || ((detail.biannualAmount * 100)/payment.options.amount)
                    }
                    product.discounts.add({
                      name:'discount',
                      percentage: detail.biannualPercentage || detail.bimesterPercentage || offAmount,
                      amount: 0,
                      id: detail.id
                    })
                  }else if(payment.options.reenrollmentExtraOff){
                    product.discounts.add({
                      name:'discount',
                      description: payment.title,
                      percentage: payment.options.reenrollmentExtraOff,
                      amount: 0
                    })
                  }else if(payment.options.discounts.length){
                    const [ firstDiscount ] = payment.options.discounts
                    const { amount } = firstDiscount
                    product.discounts.add({
                      name:'discount',
                      description: payment.title,
                      percentage: (amount / payment.options.amount) * 100,
                      amount: 0,
                    })
                  }

                  //add scholarship discount
                  if(payment && payment.options.scholarshipAmount){
                    let id
                    if(this.teclabReenrollment.scholarship){
                      const haveScholarship = this.teclabReenrollment.scholarship.products.find(product => product.billingProduct === 89)

                      if(haveScholarship){
                        id = this.teclabReenrollment.scholarship.id //eslint-disable-line
                      }
                    }
                    product.discounts.add({
                      name:'scholarship',
                      description: payment.title,
                      percentage: (payment.options.scholarshipAmount / payment.options.amount) * 100 || 0, //amount to percentage
                      amount: 0,
                      id

                    })
                  }

                  //add benefit discount
                  if(payment && payment.options.benefitAmount){
                    let id
                    if(this.teclabReenrollment.benefit){
                      const haveBenefit = this.teclabReenrollment.benefit.products.some(product => product.billingProduct === 89)
                      if(haveBenefit){
                        id = this.teclabReenrollment.benefit.id //eslint-disable-line
                      }
                    }

                    product.discounts.add({
                        name:'benefit',
                        description: payment.title,
                        percentage: (payment.options.benefitAmount / payment.options.amount) * 100 || 0, //amount to percentage
                        amount: 0,
                        id

                    })
                  }

                  // //add prepaid discount
                  if(payment.options.activePrepaid){
                    let id
                    let percentage
                    if(payment.options?.prepaids?.length && payment.options.prepaidIndex >= 0){
                      percentage = payment.options?.prepaids?.[payment.options.prepaidIndex]?.percentage // eslint-disable-line
                      id = payment.options?.prepaids?.[payment.options.prepaidIndex]?.id // eslint-disable-line
                    }
                    if(percentage){
                      const scholarship = product.discounts.findByName('scholarship')
                      const benefit = product.discounts.findByName('benefit')

                      if(!(scholarship.percentage && benefit.percentage)) {
                        product.discounts.add({
                            name:'prePaid',
                            description: payment.title,
                            percentage,
                            amount: 0,
                            id
                        })
                      }
                    }
                  }

                  //add referedCredits discount first payment
                  if(this.referedCredits && index === 0) {
                    const payment = payments
                      ?.find(({id, reenrollmentId})=>id && product.id === id || reenrollmentId && product.reenrollmentId === reenrollmentId)

                    if(payment && payment.billingProduct !== 87){
                      const amountOff =  product.discounts.getAmountOff()
                      const percentage = (this.referedCredits / (payment.options.amount - amountOff)) * 100

                      product.discounts.add({
                        name:'referredCredits',
                        description: payment.title,
                        percentage,
                        amount: 0
                      })
                    }
                  }

                  //add uniqueDiscount discount first payment
                  const uniqueDiscount = this.teclabReenrollment?.uniqueDiscount || this.ippReenrollment?.uniqueDiscount
                  if(uniqueDiscount && index === 0) {
                    const payment = payments
                      ?.find(({id, reenrollmentId})=>id && product.id === id || reenrollmentId && product.reenrollmentId === reenrollmentId)
                    //TODO: uniqueDiscount
                    if(payment && payment.billingProduct !== 87){
                      const amountOff =  product.discounts.getAmountOff()
                      const percentage = payment.options.accumulativeDiscount ? (uniqueDiscount / (payment.options.amount)) * 100 : (uniqueDiscount / (payment.options.amount - amountOff)) * 100
                      product.discounts.add({
                        name:'uniqueDiscount',
                        description: payment.title,
                        percentage,
                        amount: 0
                      })
                    }
                  }
                })

              //add discount Arancel for discount manager
              products
                .filter(product => product.billingProductId == 81)
                .map((product, index)=>{
                  const payment = payments
                  .find(({id, reenrollmentId})=>id && product.id === id || reenrollmentId && product.reenrollmentId === reenrollmentId)
                  const {discount} = this.teclabReenrollment

                  const detail = discount?.details?.find(detail => detail.billingProduct.id == 81)
                  if(detail){
                    let offAmount = 0
                    if(detail.bimesterAmount != null || detail.biannualAmount != null){
                      offAmount =((detail.bimesterAmount * 100)/payment.options.amount) || ((detail.biannualAmount * 100)/payment.options.amount)
                    }
                    product.discounts.add({
                      name:'discount',
                      percentage: detail.biannualPercentage || detail.bimesterPercentage || offAmount,
                      amount: 0,
                      id: detail.id
                    })
                  }else if(payment.options.reenrollmentExtraOff){
                    product.discounts.add({
                      name:'discount',
                      description: payment.title,
                      percentage: payment.options.reenrollmentExtraOff,
                      amount: 0,
                    })
                  }else if(payment.options.discounts.length){
                    const [ firstDiscount ] = payment.options.discounts
                    const { amount } = firstDiscount
                    product.discounts.add({
                      name:'discount',
                      description: payment.title,
                      percentage: (amount / payment.options.amount) * 100,
                      amount: 0,
                      id: payment.options.discounts[0].idDetail
                    })
                  }

                  //add scholarship discount
                  if(payment && payment.options.scholarshipAmount){
                    let id
                    let percentage
                    if(this.teclabReenrollment.scholarship || !!payment.options.scholarships){
                      const haveScholarship = this.teclabReenrollment.scholarship?.products?.some(product => product.billingProduct === payment.billingProduct) || !!payment.options.scholarships[0]

                      if(haveScholarship){
                        id = this.teclabReenrollment.scholarship?.id || payment.options.scholarships[0].id //eslint-disable-line
                        percentage = this.teclabReenrollment.scholarship?.products?.find(product => product.billingProduct === 81)?.percentage || payment.options.scholarships[0].percentage
                      }
                    }else if(this.ippReenrollment?.scholarship){
                      const haveScholarship = this.ippReenrollment.products?.some(product => product.billingProduct === payment.billingProduct) || !!payment.options.scholarships[0]
                      if(haveScholarship){
                        id = this.ippReenrollment?.scholarship?.id //eslint-disable-line
                        percentage = this.ippReenrollment?.scholarship?.percentage
                      }
                    }
                    product.discounts.add({
                      name:'scholarship',
                      description: payment.title,
                      percentage,
                      amount: 0,
                      id

                    })
                  }

                  //add benefit discount
                  if((payment && payment.options.benefitAmount && this.teclabReenrollment?.benefit != null) || (this.ippReenrollment?.benefit && this.ippReenrollment?.benefit.statusProcessingBenefit == true)){
                    let percentage
                    let id
                    if(this.teclabReenrollment.benefit){

                      const haveBenefit = this.teclabReenrollment.benefit.products.some(product => product.billingProduct === payment.billingProduct)
                      if(haveBenefit){
                        id = this.teclabReenrollment.benefit.id,//eslint-disable-line
                        percentage = (payment.options.benefitAmount / payment.options.amount) * 100 || 0 //amount to percentage
                      }
                    }else if(this.ippReenrollment?.benefit){
                      id = this.ippReenrollment.benefit.id //eslint-disable-line
                      percentage = this.$decryter(this.ippReenrollment?.benefit?.percentage) //eslint-disable-line
                    }
                    product.discounts.add({
                        name:'benefit',
                        description: payment.title,
                        percentage,
                        amount: 0,
                        id

                    })
                  }

                  //add prepaid discount
                  const paymentComplex = this.$refs.paymentSelectionGridView.$children
                    .filter(children=> children.$vnode.componentOptions.tag === 'PaymentComplex')
                    ?.find(children => payment.id && children.payment.id === payment.id || payment.reenrollmentId && children.payment.reenrollmentId === payment.reenrollmentId)

                  if(paymentComplex){
                    let id
                    let percentage
                    if(paymentComplex.payment.options?.prepaids?.length && paymentComplex.prepaidsActive?.length){
                      const index = paymentComplex.prepaidsActive.indexOf(true)
                      percentage = paymentComplex.payment.options?.prepaids?.[index]?.percentage // eslint-disable-line
                      id = paymentComplex.payment.options.prepaids[index]?.id // eslint-disable-line
                    }
                    if(percentage){
                      const scholarship = product.discounts.findByName('scholarship')
                      const benefit = product.discounts.findByName('benefit')

                      if(!(scholarship.percentage && benefit.percentage)) {
                        product.discounts.add({
                            name:'prePaid',
                            description: paymentComplex.payment.title,
                            percentage,
                            amount: 0,
                            id
                        })
                      }
                    }
                  }

                  //add referedCredits discount first payment
                  if(this.referedCredits && index === 0) {
                    const payment = payments
                      ?.find(({id, reenrollmentId})=>id && product.id === id || reenrollmentId && product.reenrollmentId === reenrollmentId)

                    if(payment && payment.billingProduct !== 87){
                      const amountOff =  product.discounts.getAmountOff()
                      const percentage = (this.referedCredits / (payment.options.amount - amountOff)) * 100

                      product.discounts.add({
                        name:'referredCredits',
                        description: payment.title,
                        percentage,
                        amount: 0
                      })
                    }
                  }

                  //add uniqueDiscount discount first payment
                  const uniqueDiscount = this.teclabReenrollment?.uniqueDiscount || this.ippReenrollment?.uniqueDiscount
                  if(uniqueDiscount && index === 0) {
                    const payment = payments
                      ?.find(({id, reenrollmentId})=>id && product.id === id || reenrollmentId && product.reenrollmentId === reenrollmentId)
                    //TODO: uniqueDiscount
                    if(payment && payment.billingProduct !== 87){
                      const amountOff =  product.discounts.getAmountOff()
                      const percentage = payment.options.accumulativeDiscount ? (uniqueDiscount / (payment.options.amount)) * 100 : (uniqueDiscount / (payment.options.amount - amountOff)) * 100
                      product.discounts.add({
                        name:'uniqueDiscount',
                        description: payment.title,
                        percentage,
                        amount: 0
                      })
                    }
                  }
                  //add extraBenefit discount
                  const currentPayment = allPayments
                    ?.find(({id , reenrollmentId})=> id&&id === product.id ||reenrollmentId && reenrollmentId === product.reenrollmentId )
                  const extraBenefit = this.teclabReenrollment.extraBenefit
                    ?.find(extraBenefit=> extraBenefit.termId === currentPayment.options.term?.id)
                  const isSelectedAllProductByTerms = this.isAllProductByGroupTerms(currentPayment.options.term?.id)
                  if(extraBenefit && isSelectedAllProductByTerms && currentPayment && this.validateExtraBenefits() ){
                    const { percentageBimester, percentageSemester } = extraBenefit
                    const percentage = this.haveSelectedToPayForSemester() && percentageSemester ? percentageSemester : percentageBimester

                    product.discounts.add({
                      id: extraBenefit.id,
                      name:'extraBenefit',
                      description: currentPayment.title,
                      percentage,
                      amount: 0
                    })
                  }

                })

              //set the scholarship items in summary
              this[resumen].items.scholarships.items = products.map((product)=>{
                const payment = payments
                  ?.find(({id, reenrollmentId})=>id && product.id === id || reenrollmentId && product.reenrollmentId === reenrollmentId)

                const scholarship = product.discounts.findByName('scholarship')
                return {
                  id: payment.id,
                  reenrollment: payment.id == null ? payment : null,
                  reenrollmentId: payment.reenrollmentId,
                  monto: scholarship.amount,
                  title: `${ payment.title } - ${ this.$options.filters.$roundNumber(scholarship.percentage) }% off`
                }
              })

              //set the benefits items in summary
              this[resumen].items.benefits.items = products.map((product)=>{
                const payment = payments
                  ?.find(({id, reenrollmentId})=>id && product.id === id || reenrollmentId && product.reenrollmentId === reenrollmentId)

                const benefit = product.discounts.findByName('benefit')
                return {
                  id: payment.id,
                  reenrollment: payment.id == null ? payment : null,
                  reenrollmentId: payment.reenrollmentId,
                  monto: benefit.amount,
                  title: `${ payment.title } - ${ this.$options.filters.$roundNumber(benefit.percentage) }% off`
                }
              })

              //add amount  referedCredits in summary
              if (this.referedCredits) {
                this[resumen].items.benefits.items.push({
                  id: 'referedFriend',
                  monto: this.$options.filters.$roundNumber(this.referedCredits),
                  title: this.$t('modules.portalalumno.payments.paymentsStudents.view.benefit.type.referedFriend')
                })
              }
              //add amount  uniqueDiscount in summary
              const uniqueDiscount = this.teclabReenrollment?.uniqueDiscount || this.ippReenrollment?.uniqueDiscount
              if(uniqueDiscount){
                const uniqueDiscount = products.reduce((discount, product)=>{
                  const { amount, percentage } = product.discounts.findByName('uniqueDiscount')
                  discount.percentage = percentage
                  discount.amount += amount
                  return discount
                },{amount: 0, percentage:0})

                this[resumen].items.benefits.items.push({
                  id: 'uniqueDiscount',
                  monto: this.$options.filters.$roundNumber(uniqueDiscount.amount),
                  title: this.$t('modules.portalalumno.payments.paymentsStudents.view.benefit.type.uniqueDiscount')
                })
              }

              //set the discounts items in summary
              this[resumen].items.discounts.items = products.map(product=>{
                const payment = payments
                  ?.find(({id, reenrollmentId})=>id && product.id === id || reenrollmentId && product.reenrollmentId === reenrollmentId)
                const extraBenefitDiscount = this.teclabReenrollment.extraBenefit
                  ?.find(extraBenefit=> extraBenefit.termId === payment.options.term?.id)
                const isSelectedAllProductByTerms = this.isSelectedAllProductByGroupTerms(payment.options.term?.id)

                const discount = product.discounts.findByName('discount')
                const prepaid = product.discounts.findByName('prePaid')
                let extraBenefit = { percentage: 0, amount: 0}
                if(this.systemConfig.dues && isSelectedAllProductByTerms && extraBenefitDiscount){
                  extraBenefit = product.discounts.findByName('extraBenefit')
                }
                // const extraBenefit = product.discounts.findByName('extraBenefit')
                const { percentage, amount } = [discount, prepaid, extraBenefit]
                  .reduce((acc,discount)=>({
                    percentage: acc.percentage + discount.percentage,
                    amount: acc.amount + discount.amount
                  }),{ percentage: 0, amount: 0})

                return {
                  id: product.id,
                  reenrollment: payment.id == null ? payment : null,
                  reenrollmentId: product.reenrollmentId,
                  monto: amount,
                  title: `${ payment.title } - ${ this.$options.filters.$roundNumber( percentage ) }% off`
                }
              })

              //add discounts to history if all items come
              if(itemsToUpdate === 'all'){
                this.productsForDiscountHistory = products.map(product=>{
                  let selected = false
                  const {id: accountId, reenrollmentId} = product
                  selected = product?.selected
                  const discounts = {}
                  product.discounts
                    .getList()
                    .forEach(discount=>{
                      const { name } = discount
                      discounts[name] = {
                        ...discount
                      }
                    })
                  return {
                    accountId,
                    reenrollmentId,
                    selected,
                    ...discounts
                  }
                })
              }

              // save snapshot discounts resumen

              this[resumen].items.repayments.items = payments.filter(payment => payment.repaymentAvailable && payment.selected).map(payment => ({
                id: payment.id,
                monto: payment.options.amount,
                title: `${payment.options.paymentInstallment}/${payment.options.paymentInstallments}`
              }))
              this[resumen].items.repayments.monto = this[resumen].items.repayments.items.reduce((acc, curr) => {return acc += +curr.monto}, 0)
              if(this.$isMexico){
                this[resumen].items.subtotal.monto = payments.reduce((acc, curr) => { if (curr.billingProduct !== 80) { return acc + (+curr.options.amount || 0); } return acc; }, 0);
                this[resumen].items.discounts.monto = this[resumen].items.discounts.items.reduce((acc, curr) => {if (curr.reenrollment?.billingProduct !== 80) { return acc + (+curr.monto || 0);} return acc;}, 0);
              } else {
                this[resumen].items.subtotal.monto = payments.reduce((acc, curr) => {return acc += +curr.options.amount}, 0)
                this[resumen].items.discounts.monto = this[resumen].items.discounts.items.reduce((acc, curr) => {return acc += +curr.monto}, 0)
              }
              this[resumen].items.benefits.monto = this[resumen].items.benefits.items.reduce((acc, curr) => {return acc += +curr.monto}, 0)
              this[resumen].items.scholarships.monto = this[resumen].items.scholarships.items.reduce((acc, curr) => {return acc += +curr.monto}, 0)
              this[resumen].total = this[resumen].items.subtotal.monto - this[resumen].items.discounts.monto - this[resumen].items.scholarships.monto - this[resumen].items.benefits.monto
              if (this[resumen].total < 0) this[resumen].total = 0
              snapshotDiscountsSummary = _.cloneDeep(this.resumen.items.discounts)
              if ((this.$isTeclab || this.$isIPP) || this.studentHasPreviousSubscriptionPaid) {
                  const paymentsSelectedBillingProductIds = payments.filter(payment => payment.selected).map(payment => payment.billingProduct)
                  const paymentsNextWithoutSelectedBillingProductIds = this.paymentsNext.filter(payment => payment.selected).reduce((acc, payment) => {
                    if (![this.$sisConstants('modules.products.matricula'), this.$sisConstants('modules.products.arancel')].includes(payment.billingProduct)) {
                      acc.push(payment.billingProduct)
                    }
                    return acc
                  }, [])
                  const paymentBillingProductIds = [...new Set([...paymentsSelectedBillingProductIds, ...paymentsNextWithoutSelectedBillingProductIds])]
                  this.filteredSubscriptions = this.subscriptions.filter(subscription => {

                      const subscriptionPaymentBillingProductIds = subscription.suscriptionDetail.map(detail => detail.billingProduct.id)

                      if (paymentBillingProductIds.length !== subscriptionPaymentBillingProductIds.length) return false
                      const uniqueValues = new Set([...paymentBillingProductIds, ...subscriptionPaymentBillingProductIds])
                        for (const v of uniqueValues) {
                            const aCount = paymentBillingProductIds.filter(e => e == v).length
                            const bCount = subscriptionPaymentBillingProductIds.filter(e => e == v).length
                            if (aCount != bCount) return false
                        }
                      return true

                  })
                  this.filteredSubscriptionsTypes = [...this.subscriptionPaymentTypes.filter(type => {
                      let subscriptionTypeApply = false
                      this.filteredSubscriptions.forEach(subscription => {
                          if (type.value == subscription.suscriptionPaymentType.value) {
                              subscriptionTypeApply = true
                          }
                      })
                      return subscriptionTypeApply
                  }), {
                      meaning: 'Crédito',
                      type: 'SUSCRIPTION_PAYMENT_TYPE',
                      value: 244,
                  }, {
                      meaning: 'Débito',
                      type: 'SUSCRIPTION_PAYMENT_TYPE',
                      value: 245,
                  }]
              }
              this.paymentsSnapshot = _.cloneDeep([...this.payments, ...this.paymentsNext, ...this.futurePayments])
              this.$nextTick()
          },
      async nextStepChange(data = null, current) {
          if(data && current){
            // eslint-disable-next-line no-prototype-builtins
            if((data.status === 'approved'|| data === 'approved') && current.hasOwnProperty('contractPendingValidation')){
              const { contractPendingValidation } = current
              this.$store.dispatch('commons/setContractPendingValidation', contractPendingValidation)
              const alumnoSeleccionado = JSON.parse(localStorage.getItem('alumnoSeleccionado'))
              alumnoSeleccionado.contractPendingValidation = contractPendingValidation
              localStorage.setItem('alumnoSeleccionado', JSON.stringify(alumnoSeleccionado))
            }
          }
          if (this.step == 2 && this.studentData && this.studentData.studentType?.value == 7 && !this.studentData.signed) {
              // Actualizamos datos de alumno para saber si pago o no
              await this.$store.dispatch('commons/initData');
          }
          if(data && typeof data === 'string'){
            this.successMessage = {
              status: data
            }
          }else if(data && typeof data === 'object'){
            this.successMessage = data
          }else{
            this.successMessage = {
              status: ''
            }
          }

          this.step = this.step + 1;
      },
      resetViewStates(){
        this.payments = []
        this.paymentsNext = []
        this.futurePayments = []
        this.paymentsSelected = []
        this.paymentsSelectedAll = []
        this.teclabReenrollment = {
          'products': [],
          'discount': null,
          'scholarship': null,
          'benefit': null,
          'subjectQuota': true,
          'referedCredits': null,
          'uniqueDiscount': null,
          'extraBenefit': [],
          'accumulativeDiscount': false,
          'hadASubscription': false,
          'inscriptionDate': '2021-04-05'
        }
        this.resumen = {
          items: {
              subtotal: { title: 'Subtotal', items: [], monto: 0 },
              scholarships: { title: 'Becas', items: [], monto: 0 },
              benefits: { title: 'Beneficios', items: [], monto: 0 },
              discounts: { title: 'Descuentos', items: [], monto: 0 },
              repayments: { title: 'Subtotal', items: [], monto: 0 },
              adminExpenses: { title: 'Gastos Administrativos', items: [], monto: 0 },
          },
          cards: [
              { paymentId: null, amount: null },
              { paymentId: null, amount: null },
          ],
          total: 0,
          tea: '0,00%',
          cft: '0,00%',
          paymentIntent: 0
        }
      },
      async changePaymenstBySubscription(data){
        if(data){
          this.resetViewStates()
          await this.fetchData(data)
          this.$nextTick(()=>{
            this.onBillingChange([...this.payments, ...this.paymentsNext, ...this.futurePayments], 'all')
            const paymentsSelected = [...this.payments, ...this.paymentsNext, ...this.futurePayments]
              .filter(payment => payment.selected)
            this.onBillingChange(paymentsSelected)
          })
        }
      },
      updateSelectedPayments(){
        const paymentsSelected = [...this.payments, ...this.paymentsNext, ...this.futurePayments]
          .filter(payment => payment.selected)
        this.onBillingChange(paymentsSelected)
      },
      setDue(due) {
          if (due) {
          if (Array.isArray(due)) this.creditCards = due
          else this.creditCards = [ due ]
          } else {
          this.creditCards = []
          }
      },
      updateCouponExternalInfo(payload) {
          this.coupon = { ...this.coupon, ...payload }
          }
      },
  watch: {
      selectedMethod: {
        handler(selectedMethod) {
          if(selectedMethod.id && selectedMethod.id !== 'SUS'  && this.step === 2 && this.paymentSubscriptionFlow){
            const payments = [...this.payments, ...this.paymentsNext, ...this.futurePayments].filter(payment => payment.selected)
            this.onBillingChange(payments)
            this.summaryButtons.primary.text = this.$t('modules.portalalumno.payments.paymentsStudents.view.labels.paymentLink')
          }
          this.definePrimaryCallback()
        },
        deep: true
      },
      step(step){
        if(step > 1){
          this.summaryButtons.tertiary.visible = false
        }
        if(step === 2 && !this.selectedMethod.id){
          this.summaryButtons.primary.disabled = true
        }
        if(step == 2){
          this.summaryButtons.secondary.visible = true
        }
      },
      paymentsSnapshot(value){
        if(value){
          this.pendingPaymentsIdForSubscription = value
          .filter(payment => !payment.selected)
          .map(payment => payment.billingProduct)
        }else{
          this.pendingPaymentsIdForSubscription
        }
      },
  },
};

/**
*
* @param { Object } options
* @param { Number } options.amount
* @param { Boolean } options.accumulative
*/
function discountsManager({amount, accumulative}){
let discounts = []
function add(discount){
    discount.originalPercentage = discount.percentage
  if(accumulative){
    discount.amount = amount * discount.percentage / 100
  }else{
    const amountOff = (amount - getAmountOff())* discount.percentage / 100
    const percentageOff = amountOff * 100 / amount
    discount.amount = amountOff
    discount.percentage = percentageOff
  }
  discounts = [...discounts, discount]
}
function getOff(){
  return discounts.reduce((precentageOff, discount)=> precentageOff + discount.percentage ,0)
}
function getAmountOff(){
  return discounts.reduce((amountOff, discount)=> amountOff + (amount*discount.percentage)/ 100,0)
}
function findByName(name){
  const discount = discounts?.find(discount=> discount.name === name)
  if(!discount){
    return{
      amount: 0,
      percentage: 0
    }
  }
  return{
    amount: discount.amount,
    percentage: discount.percentage
  }

}
function getList(){
  return discounts
}
return{
  add,
  getOff,
  getAmountOff,
  findByName,
  getList
}
}
</script>

<style lang="sass">
  @media screen and (min-width: 640px)
      .paymentStepper.v-stepper:not(.v-stepper--vertical) .v-stepper__label
          display: flex
</style>

<style scoped lang="sass">
.redirect-message p
  font-size: 12px

.stepper-col
  background: #ffff
  height: 100%

.stepper
  background: transparent
  border: none
  box-shadow: none !important

.stepper-step
  background: #ffff

.stepper-header
  box-shadow: none
  overflow: hidden
  border: solid 1px #c1c1c1
  height: auto
  border-radius: 6px

.v-stepper__step__step
  display: none !important


#tabs-payment
  width: 100%
  border-radius: 0.25em

  position: relative
  display: inline-block
  vertical-align: top

  &.active
    background: white
    color: var(--v-primary-base)

    + li:before
      background: white

  &.active:before
    background: #ffff

  + li:before
    content: ''
    position: absolute
    top: 0em
    bottom: 0
    border-right: solid 2px #c1c1c1
    border-top: solid 2px #c1c1c1
    pointer-events: none
    box-sizing: border-box

    /* a shadow effect can be added too
    width: 3em
    transform: rotate(30deg) skewy(30deg)
    z-index: 1

  + li:before
    left: -2em

@media only screen and (max-width: 600px)
  li, li + li
    padding: 1em
    font-size: 12px
    width: 30%

  li:nth-child(3)
    width: 40%
  .v-stepper--alt-labels .v-stepper__step
      flex-basis: 0px !important

</style>
